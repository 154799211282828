import { createSelector } from '@ngrx/store';
import * as fromRoot from '../../../../app-state';
import { MuhasebeAkisState } from './muhasebe-akis.reducer';

const getMuhasebeAkisFeatureState = createSelector(
        fromRoot.getOrganizationSelector, (state: fromRoot.OrganizationState) => state?.Module.Muhasebe.MuhasebeAkis,
);

const selectAkis = createSelector(
        getMuhasebeAkisFeatureState, (state: MuhasebeAkisState) => {
                let liste = [];
                Object.values(state.entities).forEach(elm => {liste.push(elm); });
                liste = liste.sort((x, y) => y.created_time - x.created_time);
                return liste;
        },
);

const selectAkisLoading = createSelector(
        getMuhasebeAkisFeatureState,
        (state: MuhasebeAkisState) => state.loading,
);

const selectAkisLoaded = createSelector(
        getMuhasebeAkisFeatureState,
        (state: MuhasebeAkisState) => state.loaded,
);
const selectAkisEnd = createSelector(
        getMuhasebeAkisFeatureState,
        (state: MuhasebeAkisState) => state.end,
);
const selectAkisError = createSelector(
        getMuhasebeAkisFeatureState,
        (state: MuhasebeAkisState) => state.error,
);

const getCurrentAkisId = createSelector(getMuhasebeAkisFeatureState,
        (state: MuhasebeAkisState) => state.selectedAkisId);

const selectAkisItem = createSelector(
        getMuhasebeAkisFeatureState,
        getCurrentAkisId,
        state => state.entities[state.selectedAkisId],
);

const selectGirisActive = createSelector(
        getMuhasebeAkisFeatureState,
        (state: MuhasebeAkisState) => state.active,
);

const connectionStatus = createSelector(
        getMuhasebeAkisFeatureState,
        (state: MuhasebeAkisState) => state.connection,
);

export {
        MuhasebeAkisState,
        getMuhasebeAkisFeatureState,
        selectAkis,
        selectAkisLoading,
        selectAkisLoaded,
        selectAkisEnd,
        selectAkisError,
        getCurrentAkisId,
        selectAkisItem,
        selectGirisActive,
        connectionStatus,
};
