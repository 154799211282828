import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDateService } from '@nebular/theme';
import { HatirlatmaService } from '../../../services/hatirlatma.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';



import * as moment from 'moment';
import 'moment/locale/tr';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Store } from '@ngrx/store';
import { responseKodlari } from '../../../pages/constants';
import { removeAllNull } from '../../../pages/global-functions';


@Component({
  selector: 'hatirlatma-detay',
  templateUrl: './hatirlatma-detay.component.html',
  styleUrls: ['./hatirlatma-detay.component.css'],
  providers: [HatirlatmaService, DatePipe],
})
export class HatirlatmaDetayComponent implements OnInit {
  isSubmitting: boolean = false;
  hatirlatmaID;
  hatirlatmaDetay;
  hatirlatmaDuzenle = false;
  hatirlatmaForm: FormGroup;
  isUpdate = false;
  min: Date = new Date();
  tag: string = 'HatirlatmaDetayComponent --->';
  constructor (private ref: NbDialogRef<HatirlatmaDetayComponent>,
    private _hatirlatmaService: HatirlatmaService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private dateService: NbDateService<Date>,
    private store: Store<{}>) {

    this.min = this.dateService.today();
  }

  ngOnInit() {
    this._hatirlatmaService.getHatirlatmaDetay(this.hatirlatmaID).subscribe(data => {
      this.hatirlatmaDetay = data;
      this.modelToForm(data);
    });

    this.hatirlatmaForm = this.fb.group({
      note: new FormControl(null, Validators.required),
      gun: new FormControl(null, Validators.required),
      saat: new FormControl(null, Validators.required),
    });
  }

  hatirlatmaGuncelle() {
    if (this.hatirlatmaForm.valid) {
      const formData = this.valuesToModel();
      // Swal.fire({
      //   title: 'Hatırlatma Güncelleniyor',
      //   html: '<div class="fa-3x" > <i class="fas fa-circle-notch fa-spin"></i> </div>',
      // });
      this.isSubmitting = true;
      this._hatirlatmaService.patchHatirlatma(this.hatirlatmaID, formData).subscribe(data => {
        this.isSubmitting = false;
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
        this.isUpdate = true;
        this.hatirlatmaDetay = data;
        this.duzenle();
        // this.hatirlatmaForm.reset();
      }, (err: HttpErrorResponse) => {
        this.isSubmitting = false;

        Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);

      });
    } else {
      Swal.fire(responseKodlari.form.unValidForm as SweetAlertOptions);
    }
  }

  modelToForm(data) {
    const _data = { ...data };
    _data.gun = new Date(Number(_data.reminder_time) * 1000);
    _data.saat = this.datePipe.transform(_data.gun, 'HH:mm');
    delete _data.id;
    delete _data.created_time;
    delete _data.reminder_time;
    this.hatirlatmaForm.patchValue(_data);
  }

  valuesToModel() {

    const data = removeAllNull(this.hatirlatmaForm.value);
    const time = new Date(data.gun * 1000);
    const saat = data.saat.split(':');
    const myDate = moment(time.getTime(), 'x');
    myDate.set({ hour: saat[0], minute: saat[1] });
    data['reminder_time'] = myDate.format('X');
    delete data.gun;
    delete data.saat;
    this.hatirlatmaForm.updateValueAndValidity();
    return data;
  }
  closed() {
    this.ref.close();
    // this.ref.close({ isUpdate: this.isUpdate });
  }

  duzenle() {
    if (this.hatirlatmaDuzenle === false) {
      this.hatirlatmaDuzenle = true;
    } else {
      this.hatirlatmaDuzenle = false;
    }
  }

}
