import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { selectAkis } from '../muhasebe-store/muhasebeAkis/muhasebe-akis.connect';

@Injectable()
export class MuhasebeAkisService {
    private readonly API_URL = `${environment.backendurl}api/muhasebe/`;
    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private store: Store<{ Cari }>) { }


    getAkis(filtre?): Observable<any[]> {
        const url: string = `${this.API_URL}akis/`;
        return this.httpClient.post<any[]>(url, filtre);
    }

    public muhasebeAkisList$ = this.store.select(selectAkis);

}
