import { createSelector } from '@ngrx/store';
import * as fromRoot from '../../app-state';
import { JobState } from './jobs.reducer';


const getJobsFeatureState = createSelector(
    fromRoot.getOrganizationSelector, (state: fromRoot.OrganizationState) => state?.Jobs,
);

const selectJobs = createSelector(
    getJobsFeatureState, (state: JobState) => {

        let liste = [];
        Object.values(state.entities).forEach(elm => {
            liste.push(elm);
        });
        liste = liste.sort((x, y) => y.created_time - x.created_time);
        return liste;
    },
);

const selectJobsLoading = createSelector(
    getJobsFeatureState,
    (state: JobState) => state.loading,
);

const selectJobsLoaded = createSelector(
    getJobsFeatureState,
    (state: JobState) => state.loaded,
);

const selectJobsError = createSelector(
    getJobsFeatureState,
    (state: JobState) => state.error,
);

export {
    selectJobs,
    selectJobsLoading,
    selectJobsLoaded,
    selectJobsError,
};

