import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { telefonKodu, responseKodlari } from '../../../pages/constants';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { NbAuthService } from '@nebular/auth';
import { ActivatedRoute } from '@angular/router';
import { sehirAndIlce } from '../../../../assets/resources/il-ilce';

@Component({
  selector: 'firma-ekle',
  templateUrl: './firma-ekle.component.html',
  styleUrls: ['./firma-ekle.component.css'],
  providers: [OrganizasyonService, NbAuthService],
})
export class FirmaEkleComponent implements OnInit {
  selectedKurum = 8;
  // ilceler = Ilceler;
  current_ilceler;
  sehirState: boolean = true;
  kayitFormCompany: FormGroup;
  telefon_kodu = telefonKodu;
  ulkem: FormControl = new FormControl(telefonKodu[0]);
  isLoading: boolean = false;
  sehirAndIlce = sehirAndIlce;
  ilceler;
  tag: string = 'FirmaEkleComponent --->';
  constructor(private ref: NbDialogRef<FirmaEkleComponent>,
    private organizasyonService: OrganizasyonService,
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private authService: NbAuthService) { }

  ngOnInit() {
    this.initForm();
    this.activeRoute.params.subscribe(p => {
      
      this.kayitFormCompany.get('davet_kodu').setValue(p.davet_kodu);
    });
  }
  initForm() {
    this.kayitFormCompany = this.formBuilder.group({
      companyname: [null, [Validators.required]],
      companyphone: [null, Validators.required],
      city: [null, [Validators.required]],
      district: [null, Validators.required], 
      davet_kodu: [],

    });

  }

  kaydetKurum() {
    if (!this.kayitFormCompany.valid) {
      Object.keys(this.kayitFormCompany.controls).forEach(key => {
        this.kayitFormCompany.controls[key].markAsTouched();
      });

      return;
    }
    this.isLoading = true;
    
    const formData = this.kayitFormCompany.value;
    

    this.organizasyonService.addOrganizasyonWithUser(formData).subscribe((response) => {
      this.isLoading = false;

      Swal.fire(responseKodlari.successProgress as SweetAlertOptions).then(() => this.closed(response));
      

      this.kayitFormCompany.reset();
    }, err => {
      this.isLoading = false;
      Swal.fire({ ...responseKodlari.unSuccessProgress } as SweetAlertOptions);
    });
  }


  forceResizeWindow() {
    window.dispatchEvent(new Event('resize'));
  }
  closed(company?) {
    this.ref.close(company);
  }

  
  ilceSec(event) {
    let sehirData = this.sehirAndIlce;
    this.ilceler = sehirData.find(x => x.city === event);
    this.ilceler = this.ilceler.ilceler;
  }
  selectSehir(event) {
    this.kayitFormCompany.get('district').setValue(null);
    this.ilceler = event.ilceler;
  }
}
