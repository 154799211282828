import { createAction, props } from '@ngrx/store';
import { AlisSatisModel } from '../../models/cari_hakeret.model';
import { AlisSatisFormInterface } from './alisSatisInterfaces';

// Alışlar
export const loadAlis = createAction('[Alış] Load Alış', props<{ filter: AlisSatisFormInterface[] }>());

export const loadAlisSuccess =
        createAction('[Alış] Load Alış Success', props<{ data: AlisSatisModel[], isEnd?: boolean }>());


export const loadAlisFail = createAction('[Alış] Load Alış Fail', props<{ error: string, isEnd?: boolean }>());


// Satışlar
export const loadSatis = createAction('[Satış] Load Satış', props<{ filter: AlisSatisFormInterface[] }>());

export const loadSatisSuccess =
        createAction('[Satış] Load Satış Success', props<{ data: AlisSatisModel[], isEnd?: boolean }>());


export const loadSatisFail = createAction('[Satış] Load Satış Fail',
        props<{ error: string, isEnd?: boolean }>());

export const addUpAlisItem = 
createAction('[Alış AddUpdate] AddUpdate Alış', props<{ Alis: AlisSatisModel, decrypte?: boolean }>());
export const addUpAlisSuccess = 
createAction('[Alis AddUpdate Success] AddUpdate Alis Success', props<{ Alis: AlisSatisModel }>());
export const addUpSatisItem = 
createAction('[Satış AddUpdate] AddUpdate Satış', props<{ Satis: AlisSatisModel, decrypte?: boolean }>());
export const addUpSatisSuccess = 
createAction('[Satış AddUpdate Success] AddUpdate Satış Success', props<{ Satis: AlisSatisModel }>());

export const selectSatis = createAction('[Satış Select Item] selectSatis Satış Select', props<{ id: number }>());
export const selectAlis = createAction('[Alış Select Item] selectAlis Alış Select', props<{ id: number }>());
export const deleteAlis = createAction('[Alış Delete Item] selectAlis Alış Delete', props<{ id: number }>());
export const deleteSatis = createAction('[Satış Delete Item] selectAlis Satış Delete', props<{ id: number }>());
export const setSatisFilter = 
createAction('[Set Satis Filter] setSatisFilter', props<{ filter: AlisSatisFormInterface[] }>());

export const clearSatis = createAction('[clear Satis Filter] clearSatisFilter');

export const setAlisFilter = 
createAction('[Set Alis Filter] setAlisFilter', props<{ filter: AlisSatisFormInterface[] }>());

export const clearAlis = createAction('[clear Alis Filter] clearAlisFilter');


