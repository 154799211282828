import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { CariHareketModel } from '../../models/cari_hakeret.model';
import { HesapModel } from '../../muhasebe-main/muhasebe-hesaplar/hesap.model';

export const loadHesap = createAction('[Hesap] Load Hesap');

export const clearHesap = createAction('[Hesap] Clear Hesap');

export const updateHesap = createAction('[Hesap] Update Hesap', props<{ id: number }>());

export const updateCurrentHesap = createAction('[Hesap] Update Hesap');


export const updateHesapSuccess = createAction('[Hesap] Update Hesap Success',
    props<{ hesap: Update<HesapModel> }>());

export const updateHesapFail = createAction('[Hesap] Update Hesap Fail', props<String>());

export const loadHesapSuccess = createAction('[Hesap] Load Hesap Success',
    props<{ hesapItem: HesapModel[], isEnd?: boolean }>());

export const loadHesapFail = createAction('[Hesap] Load Hesap Fail', props<String>());

export const addHesapItem = createAction('[Hesap] Add HesapItem', props<{ hesapItem: HesapModel }>());

export const deleteHesapItem = createAction('[Hesap] Delete HesapItem', props<{id: number}>());

export const setActive = createAction('[Hesap] Set Active', props<{active: boolean}>());

export const setSelectedHesapId = createAction('[Hesap] Set Selected Hesap ID', props<{id: number}>());

/** Hesap Hareket Region */
export const loadHesapHareket = createAction('[Hesap] Load Hareket List', props<{hesapId: number}>());

// tslint:disable-next-line: max-line-length
export const loadHesapHareketSuccess = createAction('[Hesap] Load Hareket List Success', props<{hesapId: number, items: CariHareketModel[]}>());

// tslint:disable-next-line: max-line-length
export const loadHesapHareketFail = createAction('[Hesap] Load Hareket List Fail', props<{hesapId: number, err: string}>());

export const getHesapHareket = createAction('[Hesap] Get Hareket', props<{id: number}>());

export const addHesapHareket = createAction('[Hesap] Add Hesap Hareket', props<{hesapId: number, hareketId: number}>());

// tslint:disable-next-line: max-line-length
export const addHesapHareketSuccess = createAction('[Hesap] Add Hesap Hareket Success', props<{hesapId: number, hareket: CariHareketModel}>());

// tslint:disable-next-line: max-line-length
export const removeHesapHareket = createAction('[Hesap] Remove Hesap Hareket', props<{hesapId: number, hareketId: number}>());

// tslint:disable-next-line: max-line-length
export const removeHesapHareketSuccess = createAction('[Hesap] Remove Hesap Hareket', props<{hesapId: number, hareketId: number}>());

// tslint:disable-next-line: max-line-length
export const updateHesapHareket = createAction('[Hesap] Update Hesap Hareket', props<{hesapId: number, hareketId: number}>());

export const calculateHareketBakiye = createAction('[Hesap] Calculate Bakiye', props<{hesapId: number}>());
/** Hesap Hareket Region */

export const setSelectedHareketId = createAction('[Hesap] Set Selected Hareket', props<{hareketId: number}>());

export const updateHareketItem = createAction('[Hesap] Update Hareket Item', props<{hareket: CariHareketModel}>())