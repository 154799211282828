import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import * as userActions from './user.actions';
import { KullaniciService } from '../../services/kullanici.service';
import { KullaniciModel } from '../../models/kullanici_model';
import { Router } from '@angular/router';
import { asyncLocalStorage } from '../../pages/global-functions';
import { ClearOrgInfo } from '../organization/organization.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class UserEffects {
    redirectToFirst() {
        this.store.dispatch(ClearOrgInfo());
        this.router.navigate(['/first']);
    }

    loadUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.loadUser),
            mergeMap(() => this.userService.getKullanici()
                .pipe(
                    map((user: KullaniciModel) => {
                        return userActions.loadUserSuccess({ user: user });
                    }),
                )),
        );
    });

    logoutUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.logoutUser),
            mergeMap(async () => {
                let token: any = await asyncLocalStorage.getItem('token');
                token = { refresh_token: JSON.parse(token)?.refresh_token };
                return userActions.startLogoutProcess(token);
            }),
        );
    });

    startLogout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.startLogoutProcess),
            mergeMap((token) => this.userService.logout(token)
                .pipe(
                    map(() => {
                        this.redirectToFirst();
                        return userActions.logoutUserSuccess();
                    }),
                    catchError(() => {
                        return of(userActions.loadUserFail('Kullanici bilgisi silinemedi.'));
                    }),
                )),
        );
    });
    // Kullanıcı bilgisi çekilirken bir problem oluşuyorsa kullanıcı verisini sil ve yeniden login olmaya zorla.
    loadUserFail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.loadUserFail),
            map(() => {
                this.redirectToFirst();
                return userActions.logoutUserSuccess();
            }),
        );
    });

    invalidTokenLogout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(userActions.invalidTokenLogout),
            map(() => {
                this.redirectToFirst();
                return userActions.logoutUserSuccess();
            }),
        );
    });



    constructor(
        private actions$: Actions,
        private router: Router,
        private userService: KullaniciService,
        private store: Store<{}>,
    ) {

    }
}
