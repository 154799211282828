import { cariHareketTypes } from "../constants";

export enum musteriType {
        tuzel = 'tuzel',
        gercek = 'gercek'
}

export enum CariTypes {
        tedarikci = 'tedarikci',
        taseron = 'taseron',
        musteri = 'musteri',
        personel = 'personel',
}

export type AlisSatisTypes = cariHareketTypes.alis | cariHareketTypes.satis | cariHareketTypes.alis_iade | cariHareketTypes.satis_iade;
