import { Component, OnInit } from '@angular/core';
import { NbDialogRef, patch } from '@nebular/theme';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { DepoService } from '../../../services/depo.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../pages/constants';
import { DepoModel } from '../../../models/depo.model';

@Component({
  selector: 'depo-ekle',
  templateUrl: './depo-ekle.component.html',
  providers: [DepoService],
  styleUrls: ['./depo-ekle.component.css'],
})
export class DepoEkleComponent implements OnInit {
  editData: DepoModel;
  form: FormGroup;
  constructor(private ref: NbDialogRef<DepoEkleComponent>,
    private fb: FormBuilder,
    private _depoService: DepoService) {
  }


  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      title: [null, Validators.required],
    });

    if (this.editData) {
      console.log('DEPO VAR VE PATCH EDILIYOR ');
      this.form.patchValue(this.editData);
    }
  }
  postForm() {

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this._depoService.addDepo(this.form.value).subscribe(gelen => {
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions).then(() => this.closed(gelen));
    }, err => {
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    });
  }

  deleteDepo() {
    console.log('Depo item', this.editData);
    if (this.editData.can_delete) {
      Swal.fire(
        {
          ...responseKodlari.shureToDelete,
          ...{
            text: `${this.editData.title} Deposunu Silmek İstediğinize Emin misiniz?`,
          },
        } as SweetAlertOptions).then(({ value }) => {
          if (value) {
            this._depoService.deleteDepo(this.editData.id).subscribe((resp) => {
              this.closed({delete:true});
            });
          }
        });
    }
  }

  patchForm() {

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this._depoService.updateDepo(this.form.value, this.editData.id).subscribe(gelen => {
      this.editData = gelen;
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions).then(() => this.closed(gelen));
    }, err => {
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    });

  }

  closed(value?) {
    this.ref.close(value);
  }

}
