import * as bildirimActions from './bildirim.actions';

import {
    createReducer,
    on,
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { BildirimModel } from '../../models/bildirim.model';

interface BildirimState extends EntityState<BildirimModel> {
    selectedBildirimId: number | null;
    unShowedCount: number;
    end: boolean;
    loading: boolean;
    loaded: boolean;
    error: String;
    connection: boolean;
}

const defaultBildirimState: BildirimState = {
    ids: [],
    entities: {},
    unShowedCount: 0,
    end: false,
    selectedBildirimId: null,
    loading: false,
    loaded: false,
    error: '',
    connection: false,
};

const bildirimAdapter: EntityAdapter<BildirimModel> =
    createEntityAdapter<BildirimModel>({
        selectId: (a) => a.bildirim_id,
        sortComparer: (a: BildirimModel, b: BildirimModel) => b.created_time - a.created_time,
    });


const initialState = bildirimAdapter.getInitialState(defaultBildirimState);

const bildirimReducer = createReducer(
    initialState,

    on(bildirimActions.loadBildirimSuccess, (state, { bildirimItem, isEnd }) => {
        return bildirimAdapter.upsertMany(bildirimItem, {
            ...state,
            end: isEnd,
            loading: false,
            loaded: true,
        });
    }),

    on(bildirimActions.loadBildirimFail, (state, props) => {
        return { ...state, end: true, loading: false, error: props };
    }),

    on(bildirimActions.addItem, (state, { bildirimItem }) => {
        return bildirimAdapter.addOne(bildirimItem, {
            ...state,
            unShowedCount: state.unShowedCount + (bildirimItem.is_showed ? 0 : 1),
        });
    }),
    on(bildirimActions.setBildirimReadedSuccess, (state, { bildirim }) => {
        return bildirimAdapter.updateOne(bildirim,
            { ...state });
    }),
    on(bildirimActions.setBildirimReadedFail, (state, { err }) => {
        return {
            ...state, loading: false, error: err,
        };
    }),
    on(bildirimActions.setBildirimShowedSuccess, (state, { bildirim }) => {
        return bildirimAdapter.updateOne(bildirim,
            { ...state, unShowedCount: state.unShowedCount > 0 ? state.unShowedCount - 1 : 0 });
    }),
    on(bildirimActions.setBildirimShowedFail, (state, { err }) => {
        return {
            ...state, loading: false, error: err,
        };
    }),

    on(bildirimActions.setAllBildirimShowed, (state) => {
        return { ...state, unShowedCount: 0 };
    }),

    on(bildirimActions.updateCount, (state, { count }) => {
        return { ...state, unShowedCount: count };
    }),

    on(bildirimActions.setConnection, (state, props) => {
        return { ...state, ...props };
    }),

);

function BildirimReducer(action, state) {
    return bildirimReducer(action, state);
}

export {
    BildirimState,
    defaultBildirimState,
    bildirimAdapter,
    BildirimReducer,
};
