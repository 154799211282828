import { FormArray, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment-timezone';

export class FormatTime {
  hour: number;
  second: number;
  minute: number;

  constructor(hour: number, minute: number, second: number,) {
    this.hour = hour;
    this.minute = minute;
    this.second = second;
  }

  /** Zamanı açıklayıcılarla beraber veririr.
   * @param isShort Kısaltma veya tam bilgi almak için kullanılır.
   */
  format(isShort?: boolean, withoutZero?: boolean): string {
    if (withoutZero) {
      let time: string = '';
      time +=
        this.hour > 0 ? `${this.hour + (isShort ? ' sa ' : ' saat ')}:` : '';
      time +=
        this.minute > 0
          ? `${this.minute + (isShort ? ' da ' : ' dakika ')}:`
          : '';
      time +=
        this.second > 0
          ? ` ${this.second + (isShort ? ' sn ' : ' saniye ')}`
          : '';
      return time;
    }
    return `${this.hour + (isShort ? ' sa ' : ' saat ')}:
          ${this.minute + (isShort ? ' da ' : ' dakika ')}:
          ${this.second + (isShort ? ' sn ' : ' saniye ')}`;
  }
  /**
   * Hesaplanan zamanı formatlar.
   * @param format tarih formatı default 'hh:mm:ss'
   * 'hh:mm:ss'=01:03:45  *  'hh saat mm dakika ss saniye' = 01 saat 02 dakkika 45 saniye
   */
  toString(format?: string) {
    if (typeof format !== 'string' && isAvailable(format)) {
      throw new Error(`"${format}" Geçerli bir tarih formatıdeğil.`);
    }
    format = format
      .trim()
      .toLocaleLowerCase()
      .replace('hh', `${this.hour.toString()} sa`)
      .replace('mm', `${this.minute.toString()} dk`)
      .replace('ss', `${this.second.toString()} sn`)
      .replace(':', ' ');
    return isAvailable(format)
      ? format
      : `${this.hour} sa: ${this.minute}da: ${this.second} sn`;
  }
}
export class GlobalFunction {
  Yuvarla(sayi: number, round_lenght: number) {
    /* Verilen sayıyı, verilen uzunlukta round ederek geri dönderir. */
    return Number(sayi.toFixed(round_lenght));
  }
  /**Date türünden gelen değeri TimeStamp'a çevirir.
   *  @param date string bir tarhi değeri içermelidir.
   *  @param date2 string bir tarhi değeri içermelidir.
   */
  public daysOfTimestamps(date1: number, date2: number) {
    const fark = Math.abs((date2 - date1) / 60 / 60 / 24);
    return fark;
  }
  /**
   * İki linuxTimestamp arasın da kaç saat,dakika,saniye olduğunu veriri.
   * @param from başlangıç timestamp'ı
   * @param to bitiş timestamp'ı
   * @returns Form
   */
  public getTimeBetween(from: number, to: number): FormatTime {
    // Herzaman büyükden küçük olanı çıkarmaya yarar.
    let isAdd = 1;
    // if (to < from) {
    //   // return new FormatTime(0, 0, 0);
    //   isAdd = -1;
    // }
    isAdd = Math.sign(to - from);

    let time = Math.abs(to - from);
    time += isAdd < 0 ? 86400 : 0;
    const second = time % 60;
    const minute = Math.floor(time / 60) % 60;
    const hour = Math.floor(time / 60 / 60);

    // return `${hour}:${minute}:${second}`;
    return new FormatTime(hour, minute, second);
  }
  timestampsToDate(date: string) {
    const _date = Number(date);
    return new Date(_date * 1000);
  }
}
/** Yazı olan saati (18:34) UTC Unix TimeStampe çevirir */
export function clockToTimestamp(clock: string): number {
  if (!(isAvailable(clock) && clock.split(':').length > 1)) {
    return null;
  }

  const saat = Number(clock.split(':')[0]);
  const dakika = Number(clock.split(':')[1]);
  const bugun = dateToUtc(new Date());
  const mm = moment(bugun, 'X');
  const converted = Number(mm.set({ hours: saat, minute: dakika }).format('X'));
  return converted;
}
/** Unitx TimeStamp'i yazı olan saat'e çevirir. */
export function timeStampToClock(ts: number, gmt: string): string {
  const mm = moment(ts, 'X').tz(gmt);
  const converted = `${mm.get('hours')}:${mm.get('minutes')}`;
  return converted;
}
export function getFormData(
  form: FormControl | FormGroup | FormArray,
  callback?: any,
): any | null {
  if (form.valid) {
    return callback
      ? callback(removeAllNull(form.value))
      : removeAllNull(form.value);
  } else {
    form.markAllAsTouched();
    return null;
  }
}

/** Ppuantaj detayını guruplamak için kullanılır. */
export function groupData(data: any[], groupBy: string) {
  // Datayı key'lere göre guruplamak için kullanılan ara değişken
  const returnData = {};
  // retrun edilecek array
  const arr = [];
  /**  dizideki groupBy keyine sahip tüm alanları bir key de
   * toplar ve onların tam ve yarım günlerini 0 dan başlatıp
   * arttırır.
   * ör: dizi =[ {name:'aşçı', yarim_gun:false }, {name:'aşçı', yarim_gun:true }, {name:'Sıvacı', yarim_gun:true }]
   * returnData={
   *  aşçı:{  tam:1, yarım:1 }, Sıvacı:{tam:0, yarim:1}}
   * */
  data.forEach((element) => {
    if (returnData[element[groupBy]]) {
      if (element.yarim_gun) {
        returnData[element[groupBy]].yarim += 1;
      } else {
        returnData[element[groupBy]].tam += 1;
      }
    } else {
      returnData[element[groupBy]] = { ...element, yarim: 0, tam: 0 };
      if (element.yarim_gun) {
        returnData[element[groupBy]].yarim += 1;
      } else {
        returnData[element[groupBy]].tam += 1;
      }
    }
  });

  /** Guruplu objeyi tekrar bir diziye çevirir. */
  Object.keys(returnData).forEach((key) => {
    arr.push({
      ...returnData[key],
      job_name: key,
      value: { tam: returnData[key].tam, yarim: returnData[key].yarim },
    });
  });
  return arr;
}
/**Date türünden gelen değeri TimeStamp'a çevirir.
 *  @param date string bir tarhi değeri içermelidir.
 *  @param date2 string bir tarhi değeri içermelidir.
 */
export function daysOfTimestamps(date1: number, date2: number, isAbs: boolean) {
  let fark: number = (date2 - date1) / 60 / 60 / 24;
  if (isAbs) {
    fark = Math.abs(fark);
  }
  return Math.round(fark);
}
export function testDataText(dataText: string) {
  if (dataText !== undefined || dataText !== null) return dataText;
  else return '-';
}

/**
 * Yazdırılacak olan alanın document.getElementById( id ) kısmı
 */
export function yazdir(document) {
  const printContents = document;

  const doc = printContents;

  const newWindow = window.open();

  newWindow.document.write(`
  <!DOCTYPE html>
  <html lang="en">
  <head>
  <meta charset="UTF-8">
  <title>Toplantı</title>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">

  </head>
  <body>
  ${doc.innerHTML}
  <script>
    window.print();
    setTimeout(() => {
      window.close();
      }, 100);
  </script>

  </body>
  </html>
  `);
  newWindow.document.close();
  newWindow.focus();
}
export interface YazdirTableHead {
  keys: string[];
  titles: string[];
}
/**
 * Kenidisine verilen bir arrayı yazıdır.
 * @param titles Tablonun başlıklarını içeren bir strin dizisi.
 * @param body Yazdırılacak data
 */
export function yazdirArray(head: YazdirTableHead, body: any[], args?) {
  let printContents: string = `<table class="table table-bordered">
                              <thead>`;
  const date = args ? args.date : undefined;
  printContents += '<tr>';
  printContents += `<th style="text-align:center;width:100px">Sıra No</th>`;

  head.titles.forEach((key) => {
    printContents += `<th style="text-align:center"> ${key}</th>`;
  });
  printContents += '</tr>';

  printContents += `</thead>
                              <tbody>`;
  body.forEach((element, index) => {
    printContents += '<tr>';
    printContents += `<td style="text-align:center"> ${index}</td>`;
    head.keys.forEach((key) => {
      let value = element[key] ? element[key] : '';
      if (date) {
        value = args.date.some((s) => s === key)
          ? moment(value, 'X').format('ll HH:mm')
          : value;
      }
      printContents += `<td style="text-align:center"> ${value}</td>`;
    });
    printContents += '</tr>';
  });

  printContents += `  </tbody>
                           </table>`;

  printContents;

  const newWindow = window.open();

  newWindow.document.write(`
  <!DOCTYPE html>
  <html lang="en">
  <head>
  <meta charset="UTF-8">
  <title>Toplantı</title>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">

  </head>
  <body>
  ${printContents}


  <script>
    window.print();
    setTimeout(() => {
      window.close();
      }, 500);
  </script>

  </body>
  </html>
  `);
  newWindow.document.close();
  newWindow.focus();
}
/**
 * Date() türündeki tarihi alıp utcOffset uygulayıp
 * UnixTimeStamp olarak dönderir.
 * @param date Ofsetlenecek tarih
 * @returns UnixTime stamp dönderir.
 */
export function dateToUtc(date: Date): number {
  const baslangicUnixTS = date.getTime();
  const baslangicTimeOffset = date.getTimezoneOffset();
  const returnBaslangic = baslangicUnixTS / 1000 - baslangicTimeOffset * 60;
  return Number(returnBaslangic);
}

/**
 * Resimleri yeniden boyutlandırır.
 * resizeImage(src,genisKenar).then(resized => this.ImageUrl = resized);
 * @param src base64 türünde imge dosyası.
 * @param genisKenar Enyüksek kenarın değerini temsil eder. varsayılan değeri:800px
 */
export function resizeImage(src, genisKenar?) {
  let newX;
  let newY;
  let ratio;
  let genislik = 800;
  if (genisKenar) {
    genislik = genisKenar;
  }
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const elem = document.createElement('canvas');
      // resimin çözünürlüğünü alıyoruz.
      newX = img.width;
      newY = img.height;
      // resimin uzun kenarının 800px olması için gerekli katsayı ratio hesaplanıyor.
      if (newX > newY) {
        ratio = genislik / newX;
      } else {
        ratio = genislik / newY;
      }
      // genişlik ve yükseklik değerlerinin değiştiryoruz.
      newX = img.width * ratio;
      newY = img.height * ratio;

      // resme hesapladığımız genişlik ve yükseklik değerlerini uyguluyoruz.
      img.width = newX;
      img.height = newY;
      // canvasa hesapladığımız genişlik ve yükseklik değerlerini uyguluyoruz.
      elem.width = newX;
      elem.height = newY;

      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, newX, newY);
      const data = ctx.canvas.toDataURL();
      res(data);
    };
    img.onerror = (error) => rej(error);
  });
}
export function Yuvarla(sayi: number, round_lenght: number) {
  /* Verilen sayııyı, verilen uzunlukta round ederek geri dönderir. */
  return Number(sayi.toFixed(round_lenght));
}

/** Bir listeye yeni bir eleman eklemek için kullanılır.
 * Eğer bu eleman zaten var ise  günceller.
 * Eğer bu eleman listede yok ise ekler.
 * @param rows Elemanın ekleneceği liste.
 * @param item Listeye eklenecek eleman.
 * @param key  dataları karşılaştırmak için kullanılacak key'i bildirir.
 * @default id 'dir.
 * @returns Gerekli düzenlemelerin yapıldığı yeni bir dizidir.
 */
 export function addOrUpdate(rows: any[], item: any, key?: string): any[] {
  rows = rows.map((item: any) => Object.assign({}, item, { selected: false }));
  let isEdit: boolean = false;
  // key yok ise default id üzerinden arama yap.
  if (!key) {
    key = 'id';
  }

  // kullanıcı tarafından girilen key datalar da yok ise hata göster.
  if (!item[key] && !rows[0][key]) {
    rows.push(item);
  } else {
    isEdit = rows.some((s) => s[key] === item[key]);
  }

  if (isEdit) {
    rows.map((el, index, arr) => {
      if (el[key] === item[key]) {
        arr[index] = item;
      }
    });
  } else {
    rows = [...rows];
    rows.push(item);
    return rows;
  }
  rows = [...rows];
  return rows;
}
export function toTimestampWithMoment(date: Date): number {
  const now = moment();
  const returnDate = moment(date).hour(now.hour()).minute(now.minute()).second(now.second());

  return returnDate.unix();
}

/**Date türünden gelen değeri TimeStamp'a çevirir.
 *  @param date string bir tarhi değeri içermelidir.
 */
export function toTimestamp(date: string): number {
  const _date: number = Math.round(new Date(date).getTime() / 1000);
  return _date;
}

/** Bir nesnenenin null ve undefined olmadığını kontrol eder
 * eğer nesne varsa true döner
 */
export function isAvailable(value: any): boolean {
  return !(value === null || value === undefined);
}
/**
 * json nesnelerindeki null alanları siler.
 *  @param data null alanlarının silinmesi gereken değişkeni temsil eder.
 */
export function removeNull(data): any {
  const _data = { ...data };
  Object.keys(_data).forEach((key) => {
    if (_data[key] === null) {
      delete _data[key];
    }
  });
  return _data;
}
/**
 *  json nesne dizisinin elemanları veya alt elemanlarındaki null alnaları siler.
 *  @param data null alanlarının silinmesi gereken değişkeni temsil eder.
 */
export function removeNullInArray(data): any {
  const _data = [...data];
  Object.keys(_data).forEach((key) => {
    if (_data[key] === null) {
      delete _data[key];
    } else {
      Object.keys(_data[key]).forEach((key2) => {
        if (_data[key][key2] === null) {
          delete _data[key][key2];
        }
      });
    }
  });
  return _data;
}
/**
 * json nesnelerindeki alt obje ve arraylerdeki null alanları siler.
 *  @param data null alanlarının silinmesi gereken değişkeni temsil eder.
 */
export function removeAllNull(data, callback?): any {
  if (!isAvailable(data)) {
    return {};
  }

  let _data;
  // Gelen data obje ise asıl objeyi değiştirmesin diye objeye seperet et
  if (data instanceof Object) _data = { ...data };

  // Gelen data Array ise asıl objeyi değiştirmesin diye Array'e seperet et
  if (data instanceof Array) {
    _data = [...data];
  }

  // Obje veya dizinin tüm keylerini dolaşır.
  Object.keys(_data).forEach((key) => {
    let callbackValue;

    if (callback) {
      callbackValue = callback(_data[key], key);
      if (callbackValue) {
        _data[key] = callbackValue;
      }
    }

    if (key === 'tags') {
      // Eğer Tag Item Taşıyorsa, Objeden Id'ye transfer eder.
      if (_data[key]) {
        let tagList = _data[key];
        _data[key].forEach((tag, index) => {
          if (tag instanceof Object) {
            tagList = tagList.filter(x => x.id !== tag.id);
            tagList.push(tag.id);
          }
        });
        _data[key] = tagList;

      }
    }

    if (key === 'search' && _data[key]) {
      // Eğer Search Verisi Taşıyorsa Veriyi Türkçe karakterden arındırıyoruz.
      _data[key] = clearCapitalICharacters(_data[key]);
    }

    // Eğer field null ise onu siler
    if (_data[key] instanceof Date) {
      _data[key] = toTimestamp(_data[key]);
    } else if (_data[key] === null || _data[key] === undefined || _data[key] === '') {
      // Key deki field'i sil
      delete _data[key];
    } else if (_data[key] instanceof Array) {
      // Eğer key deki field Array'ise
      // Array boş ise onu sil
      if (_data[key].length <= 0) delete _data[key];
      // Array boş değilse alt nesnelerini tekrar kontrol et
      else _data[key] = removeAllNull(_data[key], callback);
    } else if (_data[key] instanceof Object) {
      // Eğer key deki field Object'ise key'lerini kontrol et
      _data[key] = removeAllNull(_data[key], callback);
    }
  });

  return _data;
}
/**
 * Base64 fromatındaki pdf dosylarını indirmeye yarar.
 * @param data Base64 formatındaki dosya
 * @param fileName Kayıt sırasında kullanılacak dosya adı.uzantısız isim veriniz.
 */
 export function downLoadPdf(data: string, fileName?: string) {
  const linkSource = data;
  const downloadLink = document.createElement('a');
  let _fileName = fileName ? fileName : 'Apas Rapor Dosyası';

  const splited = data.split(';base')[0];
  if (splited === 'data:image/vnd.dwg') {
    _fileName = fileName ? fileName : 'Apas Rapor Dosyası';
    _fileName = fileName + '.dwg';
  } else if (splited === 'data:image/vnd.dxf') {
    _fileName = fileName ? fileName : 'Apas Rapor Dosyası';
    _fileName = fileName + '.dxf';
  }

  downloadLink.href = linkSource;
  downloadLink.download = _fileName;
  downloadLink.click();
}

/**
 * Base64 fromatındaki pdf dosylarını indirmeye yarar.
 * @param data Base64 formatındaki dosya
 * @param fileName Kayıt sırasında kullanılacak dosya adı.uzantısız isim veriniz.
 */
export function downLoadFile(data: string, fileName?: string) {
  let _fileName = fileName ? fileName : 'Apas Rapor Dosyası';
  const splited = data.split(';base')[0];

  const isPdf = splited === 'data:application/pdf';
  if (splited === 'data:image/vnd.dwg') {
    _fileName = fileName ? fileName : 'Apas Rapor Dosyası';
    _fileName = fileName + '.dwg';
  } else if (splited === 'data:image/vnd.dxf') {
    _fileName = fileName ? fileName : 'Apas Rapor Dosyası';
    _fileName = fileName + '.dxf';
  }
  const blob = b64toBlob(data);
  const url = window.URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');

  downloadLink.href = url;
  downloadLink.download = _fileName;
  document.body.appendChild(downloadLink);

  if (isPdf) {// Eğer dosya pdf ise yeni sekmede aç firefox ta sapıttığı için böyle bir önlem aldık

    window.open(url, '_blank');
  } else {
    downloadLink.click();

  }
}

// export function downLoadFile(data: string, fileName?: string) {

//   /**
//   let _fileName = fileName ? fileName : 'Apas Rapor Dosyası';

//   const splited = data.split(';base')[0];
//   const fileType = splited.replace('data:', '');
//   if (splited === 'data:image/vnd.dwg') {
//     _fileName = fileName ? fileName : 'Apas Rapor Dosyası';
//     _fileName = fileName + '.dwg';
//   } else if (splited === 'data:image/vnd.dxf') {
//     _fileName = fileName ? fileName : 'Apas Rapor Dosyası';
//     _fileName = fileName + '.dxf';
//   }

//   const blob = b64toBlob(data, fileType);

//   fs.saveAs(blob, _fileName);

//   */



// }




/*Base64 tipindeki veriyi bloba çevirir*/
export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  b64Data = b64Data.split(',')[1];
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}


/**
 * Base64 türünde ki pdf dosyalarını yeni sekmede gösterir.
 */
export function showPdf(data: string) {
  const windo = window.open('', '');
  let objbuilder: string = '';
  // tslint:disable-next-line: quotemark
  objbuilder += "<embed width='100%' height='100%'  src=\"";
  objbuilder += data;
  objbuilder += '"/>';
  windo.document.write(objbuilder);
}
export function formatLongText(text: string, uzunluk: number = 30) {
  if (text.length > uzunluk) return text.substring(0, 30) + '...';
  return text;
}
export interface ValueToFormOptions {
  dateKeys: string[];
}
/** Parametre olarak verilen 2 nesneyi senkronize eder.
 * Bu fonskiyon data'da olup formda olmayan field leri siler.
 *
 * form da olup data olmayan filed'leri null olarak oluşturur.
 * genelde forma setValue'olarak vermek için kullanlır.
 *
 * Eşitlenen data return edilir.
 *
 * @param data manüpule edilecek data
 * @param form benzetilecek data
 *
 */
export function valueToForm(data, form, options?: ValueToFormOptions): any {
  const _data = removeAllNull({ ...data });
  // eksik alanalrı null olarak oluşturur.
  // formda olup arac da olmayan alanalrı null olarak tanımlar.
  Object.keys(form).forEach((key) => {
    if (_data[key] === undefined) {
      _data[key] = null;
    }
  });
  // fazla alanları siler. :D
  // _data da olup da form da olmayan alanları siler.
  Object.keys(_data).forEach((key) => {
    if (form[key] === undefined) {
      delete _data[key];
    } else {
      if (options) {
        if (options.dateKeys) {
          if (options.dateKeys.some((s) => s === key)) {
            if (_data[key]) {
              _data[key] = new Date(_data[key] * 1000);
            }
          }
        }
      }
    }
  });

  return _data;
}
// formatSecond(seconds: number): string {
//   const now = Number(moment().format('X'));
//   const time = now - seconds;

//   const second = time % 60;
//   const minute = Math.floor(time / 60) % 60;
//   const hour = Math.floor(time / 60 / 60);
//   return `${hour}:${minute}:${second}`;
// }

export function change_to_number(sayi): number {
  return Number(Number(sayi).toFixed(2));
}

export function decrypte(encrypted_text) {
  /**
   * Encrypte edilen bir texti alıp, decrypte eder.
   * @param encrypted_text : şifrelenmiş metin.
   */
  let clear_text;
  if (encrypted_text) {
    // Localstorage'dan firmanın özel anahtarını alır.
    let key = JSON.parse(localStorage.getItem('org')).scrk;
    // Encrypte text'i base64 formatından byte array olarak çözümler.
    const ciphertext = CryptoJS.enc.Base64.parse(encrypted_text);

    // ciphertext'in ilk 16 bitlik alanından iv çözümlenir.
    const iv = ciphertext.clone();
    iv.sigBytes = 16;
    iv.clamp();
    ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
    ciphertext.sigBytes -= 16;

    // key utf8'den byte array'a çevirilir.
    key = CryptoJS.enc.Utf8.parse(key);

    // decryption
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: ciphertext } as CryptoJS.lib.CipherParams,
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      },
    );

    // Elde Edilen decrypte edilen byte array UTF-8 stringe çevirilir.
    clear_text = decrypted.toString(CryptoJS.enc.Utf8);
  }
  return clear_text;
}

export function encrypte(raw_text) {
  /**
   * düz bir metin alınır ve encrypte edilir.
   * @param raw_text : şifrelenmemiş metin.
   */

  // Localstorage'dan firmanın özel anahtarı alınır. CyrptoJs ile UTF8 World Array Cinsine Çevirilir.
  let key = JSON.parse(localStorage.getItem('org')).scrk;
  if (!key) return;
  key = CryptoJS.enc.Utf8.parse(key);

  // 16 bitlik random iv üretilir.
  const iv = CryptoJS.lib.WordArray.random(16);

  // verilen düz text burada şifrelenir.
  const encrypted = CryptoJS.AES.encrypt(raw_text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  }).ciphertext;

  // iv ve ciphertext birleşitirilir.
  const concatenned = CryptoJS.lib.WordArray.create()
    .concat(iv)
    .concat(encrypted);
  const conn = concatenned.toString(CryptoJS.enc.Base64);

  // veri base64 stringe çevirlir ve dönderilir.

  return conn;
}

import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from './constants';

export function exportExcelListe(
  basliklar: any,
  data: any,
  dosyaAdi?: string,
  sayfaAdi?: string,
) {
  const workbook: Workbook = new Workbook();
  const sheet = workbook.addWorksheet(sayfaAdi);
  const headerRow = sheet.addRow(basliklar);
  sheet.getRow(1).font = {
    bold: true,
  };

  for (const x1 of data) {
    const x2 = Object.keys(x1);
    const temp = [];
    for (const y of x2) {
      temp.push(x1[y]);
    }
    sheet.addRow(temp);
  }

  workbook.xlsx
    .writeBuffer()
    .then((veri) => {
      if (!dosyaAdi) dosyaAdi = 'ExcelÇıktı';
      const blob = new Blob([veri], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const filename = dosyaAdi + '.xlsx';
      fs.saveAs(blob, filename);
      Swal.fire('Dosya İndirme İşlemi Tamamlandı!', '', 'success');
    })
    .catch((err) => { });
}


export function findMoneyFormatForExcel(currency?) {
  if (!currency) {
    currency = ' ';
  }
  return `"${currency} "#,##0.00;[Red]\-"${currency} "#,##0.00`;
}

export function alphabeticIndex(num: number) {
  const alphabet = 'abcdefghijklmnopqrstuwxyz';
  return alphabet.charAt(num - 1);
}

export async function exportExcelSheetListeWithHeaders(
  workbook: Workbook,
  basliklar: any[],
  data: any[],
  sayfaAdi?: string,
  anaBaslik?: string,
) {

  /**
   * Başlıklar Liste Şeklinde [{title: Ana Başlık, data_name: 'created_time'}] şeklinde tanımlanır.
   * Listeleme Başlıkların Veriliş şekline Göre Yapılır.
   */
  const sheet = workbook.addWorksheet(sayfaAdi);

  // Önce Başlıklar Satırı Oluşturulur.
  let baslikIndex = 1;

  if (anaBaslik) {
    const anaRow = sheet.getRow(baslikIndex);
    sheet.mergeCells(1, 1, 1, basliklar.length);
    anaRow.height = 30;
    const anaBaslikFont = {
      name: 'Arial Black',
      color: { argb: 'ff7043' },
      family: 2,
      size: 20,
      bold: true,
    };

    const anacell = anaRow.getCell(1);
    anacell.value = anaBaslik;
    anaRow.font = anaBaslikFont;
    anaRow.alignment = { vertical: 'middle', horizontal: 'center' };
    baslikIndex += 1;

  }

  // sheet.columns = basliklar;
  const baslikRow = sheet.getRow(baslikIndex);
  baslikRow.height = 20;
  const font = {
    name: 'Arial Black',
    color: { argb: 'ff704300' },
    family: 2,
    size: 12,
    bold: true,
  };

  basliklar.forEach((elm, i) => {
    const firtCell = baslikRow.getCell(i + 1);
    const currentCol = sheet.getColumn(i + 1);
    currentCol.width = elm.width;
    firtCell.value = elm.header;

    firtCell.font = font;
    firtCell.alignment = { vertical: 'middle', horizontal: 'center' };
  });

  sheet.autoFilter = {
    from: {
      row: baslikIndex,
      column: 1,
    },
    to: {
      row: baslikIndex,
      column: basliklar.length,
    },
  };

  data.forEach((element, i) => {
    const row = sheet.getRow(i + baslikIndex + 1);
    basliklar.forEach((baslik, k) => {
      const cell = row.getCell(k + 1);
      if (baslik.positive && element[baslik.key]) {
        // Eğer Verinin Sürekli + Değer Taşıması gerekiyorsa, başlık alanına positive bilgisi eklenir.
        element[baslik.key] = Math.abs(Number(element[baslik.key]));
      }

      // Eğer Para Formatı Atanacaksa...
      if (baslik.money && element[baslik.key]) {
        const formula = findMoneyFormatForExcel(element.currency);
        cell.numFmt = formula ? formula : '₺#.##0,00;[RED]-₺#.##0,00';
        console.log('Para Birimli Format Almak Lazım ::: ', cell.numFmt);
        element[baslik.key] = element[baslik.key] ? Number(element[baslik.key]) : '';
      }

      if (element[baslik.key] && (element[baslik.key] instanceof Date)) {
        // Eğer Veri Tarih Türündeyse exceljs bug.
        element[baslik.key] = moment(element[baslik.key]).format('DD/MM/YYYY');
      }

      if (baslik.key === 'tags') {
        element[baslik.key] = element[baslik.key].map(x => x.title);
        element[baslik.key] = element[baslik.key].join(', ');
      }

      // Hücre Bilgisinin Atandığı Satır.
      cell.value = element[baslik.key] ? element[baslik.key] : '';
    });
    row.eachCell({ includeEmpty: true }, (cell => {
      cell.alignment = { vertical: 'middle', wrapText: true };
    }));
  });
  const lastRow = sheet.getRow(data.length + 3);
  basliklar.forEach((baslik, k) => {
    if (baslik.toplam) {
      const cell = lastRow.getCell(k + 1);
      const baslikChar = alphabeticIndex(k);
      const toplamCell = sheet.getCell(data.length + 2, k + 1);
      toplamCell.value = 'TOPLAM';

      toplamCell.font = font;
      toplamCell.alignment = { vertical: 'middle', horizontal: 'right' };

      const cellFormula = `=SUM(${baslikChar}2:${baslikChar}${data.length + 1})`;
      cell.value = { formula: cellFormula, result: undefined, date1904: false };
      cell.value.result = undefined;
    }
  });
  return sheet;
}

export function writeExcelFile(workbook: Workbook, dosyaAdi: string) {
  workbook.xlsx
    .writeBuffer()
    .then((exp) => {
      if (!dosyaAdi) dosyaAdi = 'ExcelÇıktı';
      dosyaAdi = `${dosyaAdi}.xlsx`;

      const blob = new Blob([exp], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, dosyaAdi);
      Swal.fire('Dosya İndirme İşlemi Tamamlandı!', '', 'success');
    })
    .catch((err) => console.log('Dosya kaydedilirken hata oluştu ... ', err));
}

export async function exportExcelListeWithHeaders(
  basliklar: any[],
  data: any[],
  dosyaAdi?: string,
  sayfaAdi?: string,
  anaBaslik?: string,
) {

  /**
   * Başlıklar Liste Şeklinde [{title: Ana Başlık, data_name: 'created_time'}] şeklinde tanımlanır.
   * Listeleme Başlıkların Veriliş şekline Göre Yapılır.
   */
  const workbook = new Workbook();
  await exportExcelSheetListeWithHeaders(workbook, basliklar, data, sayfaAdi, anaBaslik);
  writeExcelFile(workbook, dosyaAdi);
}


export function inputMaskTemizle(raw_text) {
  let negatifmi = 0;
  raw_text = String(raw_text);
  const binlikAyrac = JSON.parse(localStorage.getItem('org')).ayrac;
  const floatingAyrac: string = binlikAyrac === '.' ? ',' : '.';
  try {
    const dizi = [...raw_text];
    let donecekDeger = '';
    if (dizi[0] == '-') { dizi.splice(0, 1); negatifmi = 1; } // negatif sayı ise '-' yi sil sonra tekrar koyacaksın

    dizi.forEach((item, index) => {
      // binlik ayraç varsa temizle
      if (item === binlikAyrac) dizi.splice(index, 1);
    });

    // Ayraç , ise . ile değiştirir.
    const floatingAyrac_Index = dizi.indexOf(floatingAyrac);
    if (floatingAyrac_Index !== -1) {
      dizi.splice(floatingAyrac_Index, 1, '.');
      if (floatingAyrac_Index === dizi.length - 1)
        dizi.splice(floatingAyrac_Index, 1);
    }
    if (negatifmi) { donecekDeger += '-'; }
    dizi.forEach((deger) => {
      donecekDeger += deger;
    });
    return donecekDeger;
  } catch (error) {
    Swal.fire(responseKodlari.tutarHatali as SweetAlertOptions);
  }
}

export function dateRangeFormSetter(event) {
  /**
   * nb-rangepicker'dan gelen event'i alıp, startDay ve endDay şeklinde unixTimeStamp verisine çevirir.
   */
  const value: string = event.target.value;
  const days: string[] = value.split('-');
  let startDayMoment;
  let endDayMoment;
  if (days && days.length > 0 && days[0] && days[0] !== '') {
    if (days.length > 0) {
      const st = moment(days[0], 'DD MMM YYYY');
      startDayMoment = moment(days[0], 'll');
      startDayMoment = startDayMoment.hour(0).minute(0).second(0);
      if (!startDayMoment.isValid()) {
        startDayMoment = null;
      } else {
        startDayMoment = startDayMoment.unix();
      }
    } else {
      return null;
    }

    if (days.length > 1) {
      endDayMoment = moment(days[1], 'DD MMM YYYY');

      if (!endDayMoment.isValid()) {
        endDayMoment = null;
      } else {
        endDayMoment = endDayMoment.hour(23).minute(59).second(59);
        endDayMoment = endDayMoment.unix();
      }
    }
  }

  return { startDay: startDayMoment, endDay: endDayMoment };
}


export function dateFormSetter(convertDate, startOrEnd) {
  /**
   * nb-rangepicker'dan gelen event'i alıp, startDay ve endDay şeklinde unixTimeStamp verisine çevirir.
   */

  // 
  let momentDay: any = moment(convertDate, 'X');

  if (startOrEnd === 'start' || startOrEnd === 'end') {
    momentDay = momentDay.hour(0).minute(0).second(0);
  } else {
    momentDay = momentDay.hour(23).minute(59).second(59);

  }
  if (!momentDay.isValid()) {
    momentDay = null;
  } else {
    momentDay = momentDay.unix();
  }
  return momentDay;
}



export function convertDateWithTZ(element, dateField: string) {
  let gmt = 'Europe/Istanbul';
  if (element.gmt) {
    gmt = element.gmt;
  }
  if (!element[dateField]) return;
  return moment(element[dateField], 'X').tz(gmt).toDate();
}
export const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
};
export async function asyncDecrypte(encrypted_text: string) {
  /**
   * Encrypte edilen bir texti alıp, decrypte eder.
   * @param encrypted_text : şifrelenmiş metin.
   */
  let clear_text;
  if (encrypted_text) {
    // Localstorage'dan firmanın özel anahtarını alır.
    let key;
    await asyncLocalStorage.getItem('org').then((val) => {
      key = JSON.parse(val).scrk;
    });
    // Encrypte text'i base64 formatından byte array olarak çözümler.
    const ciphertext = CryptoJS.enc.Base64.parse(encrypted_text);

    // ciphertext'in ilk 16 bitlik alanından iv çözümlenir.
    const iv = ciphertext.clone();
    iv.sigBytes = 16;
    iv.clamp();
    ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
    ciphertext.sigBytes -= 16;

    // key utf8'den byte array'a çevirilir.
    key = CryptoJS.enc.Utf8.parse(key);

    // decryption
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: ciphertext } as CryptoJS.lib.CipherParams,
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      },
    );

    // Elde Edilen decrypte edilen byte array UTF-8 stringe çevirilir.
    clear_text = decrypted.toString(CryptoJS.enc.Utf8);
  }
  return clear_text;
}


export const islemList = [
  {
    label: 'İş Makinesi',
    value: 'motorinfo',
  },
  {
    label: 'Depo',
    value: 'productinandout',
  },
  {
    label: 'Beton Döküm',
    value: 'betondok',
  },
  {
    label: 'Yakıt',
    value: 'fuelhistory',
  },
  {
    label: 'Bakım',
    value: 'maintenancehistory',
  },
  {
    label: 'Hakediş',
    value: 'hakedisgroup',
  },
]
export function convertIslemType(elm) {
  switch (elm.content) {
    case 'motorinfo':
      elm['islem'] = 'İş Makinesi';
      break;
    case 'productinandout':
      elm['islem'] = 'Depo';
      break;
    case 'betondok':
      elm['islem'] = 'Beton Döküm';
      break;
    case 'fuelhistory':
      elm['islem'] = 'Yakıt';
      break;
    case 'maintenancehistory':
      elm['islem'] = 'Bakım';
      break;
    case 'hakedisgroup':
      elm['islem'] = 'Hakediş';
      break;
    default:
      elm['islem'] = 'İşlem';
      break;
  }
}

export const mimeTypes = [
  {
    extantion: 'doc',
    mime: 'application/msword',
  },
  {
    extantion: 'docx',
    mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  {
    extantion: 'xls',
    mime: 'application/vnd.ms-excel',
  },
  {
    extantion: 'xlsx',
    mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  {
    extantion: 'pdf',
    mime: 'application/pdf',
  },
  {
    extantion: 'jpg',
    mime: 'image/jpeg',
  },
  {
    extantion: 'png',
    mime: 'image/png',
  },
  {
    extantion: 'dwg',
    mime: 'image/vnd.dwg',
  },
  {
    extantion: 'dwg',
    mime: 'image/x-dwg',
  },
  {
    extantion: 'dwg',
    mime: 'application/acad',
  },
  {
    extantion: 'dxf',
    mime: 'application/dxf',
  },
  {
    extantion: 'dxf',
    mime: 'image/vnd.dwg',
  },
  {
    extantion: 'dxf',
    mime: 'image/x-dwg',
  },
  {
    extantion: 'dwf',
    mime: 'drawing/x-dwf',
  },
  {
    extantion: 'dwf',
    mime: 'model/vnd.dwf',
  },
  {
    extantion: 'txt',
    mime: 'text/plain',
  },
];

export function findExtantion(mime: string) {
  const found = mimeTypes.find(x => x.mime === mime);
  if (found) return found.extantion;
  return null;
}


export function orderByKey(rows: any[], key?: string, orderType?: boolean) {
  if (!key) key = 'id';
  if (orderType == null || orderType == undefined) orderType = true;
  if (orderType) {
    rows = rows.sort((a, b) => a[key].localeCompare(b[key]));
  } else {
    rows = rows.sort((a, b) => b[key].localeCompare(b[key]));
  }
  return rows;
}


export function formatNumberWithCurrency(num, currency?, decimalPlaces?) {
  let toReturn = num;
  if (!decimalPlaces) decimalPlaces = 2;
  try {
    let binlikAyrac = '.';
    const org = localStorage.getItem('org');

    if (org) {
      binlikAyrac = JSON.parse(org).ayrac;
    }

    let local = 'en-EN';
    if (binlikAyrac === '.') {
      local = 'tr-TR';
    }

    const options = {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    };

    if (currency) {
      if(currency === '%') {
        num = num / 100
        options['style'] = 'percent';
      }
      else {
        options['style'] = 'currency';
        options['currency'] = currency;
      }
    }

    toReturn = num.toLocaleString(local, options);
    return toReturn;

  } catch (error) {
    console.log('Currency Error : ', error)
    return toReturn;
  }

}


export function clearCapitalICharacters(raw_text: string) {
  raw_text = raw_text
    .replace(/I/g, 'i')
    .replace(/İ/g, 'i')
    .replace(/ı/g, 'i');
  raw_text = raw_text.toLowerCase();
  raw_text.replace('ı', 'i');
  return raw_text;
}

export function clearTurkishCharacters(raw_text: string) {
  raw_text = clearCapitalICharacters(raw_text);
  raw_text = raw_text.replace(/ç/g, 'c')
    .replace(/Ç/g, 'C')
    .replace(/ğ/g, 'g')
    .replace(/Ğ/g, 'G')
    .replace(/ö/g, 'o')
    .replace(/Ö/g, 'O')
    .replace(/ş/g, 's')
    .replace(/Ş/g, 'S')
    .replace(/ü/g, 'u')
    .replace(/Ü/g, 'U');
  return raw_text;
}

export function imageTumb(src, newX) {
  let newY;
  let ratio;
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      // resimin çözünürlüğünü alıyoruz. 
      newY = img.height;
      // resimin uzun kenarının 800px olması için gerekli katsayı ratio hesaplanıyor.
      if (newX > newY) {
        ratio = newX / newX;
      } else {
        ratio = newX / newY;
      }
      // genişlik ve yükseklik değerlerinin değiştiryoruz.
      newX = img.width * ratio;
      newY = img.height * ratio;
      const elem = document.createElement('canvas');
      elem.width = newX;
      elem.height = newY;

      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, newX, newY);
      const data = ctx.canvas.toDataURL();
      res(data);
    },
      img.onerror = error => rej(error);
  });
}

export function phoneMask(phone) {
  const x = phone.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,7})/);
  return phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
}

export function dayDiff(end_date: any, start_date: any) {
  const fark = moment(end_date ? end_date : new Date()).diff(moment(start_date), 'days');
  return fark;
}
export function round_two(sayi): number {
  // const sonuc: number = yuvarla(sayi, 4);
  const sonuc: number = Number(sayi);
  return isNaN(sonuc) ? 0 : sonuc;
}

export function yuvarla(sayi: number, decimalPlaces?: number): number {
  if (!decimalPlaces) {
    decimalPlaces = 2;
  }
  const t = Math.pow(10, decimalPlaces);
  // tslint:disable-next-line: max-line-length
  return Math.round(sayi * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
}


export const scroolToTop = () => {
  document.getElementsByClassName('scrollable-container')[0].scrollTo(0, 0);
}
/** subscribe olunan nesneler unsubcribe edilir
  * @param obs array tipinde 
  */
export function unsubscribers(obs) {
  return obs.forEach(element => {
    element.unsubscribe();
  });
}

export function kalanGun(date) {

  const date2 = new Date(); //Anlık zaman
  const timeDiff = Math.abs(date.getTime() - date2.getTime()); //İki tarihin integer farkı
  const diffSecs = Math.ceil(timeDiff / (1000)) % 60; //Sonuç
  return diffSecs;

}

export function error400ObjectKeys(err){
  Object.keys(err.error).forEach(element => {
    Swal.fire({
        ...responseKodlari.unSuccessProgress,
        ...{
          text: err.error[element],
        },
      }  as SweetAlertOptions);
});
}