import {
    Directive,
    ElementRef,
    forwardRef,
    Inject,
    InjectionToken,
    Input,
    OnDestroy,
    ChangeDetectorRef,
    Type,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { NbDateService, NB_DOCUMENT } from '@nebular/theme';
import { fromEvent, Observable, merge, Subject } from 'rxjs';
import { map, takeUntil, filter, take, tap } from 'rxjs/operators';


/**
 * The `NbDatepickerAdapter` instances provide way how to parse, format and validate
 * different date types.
 * */
export abstract class ApasMonthPickerAdapter<D> {
    /**
     * Picker component class.
     * */
    abstract picker: Type<any>;

    /**
     * Parse date string according to the format.
     * */
    abstract parse(value: string, format: string): D;

    /**
     * Format date according to the format.
     * */
    abstract format(value: D, format: string): string;

    /**
     * Validates date string according to the passed format.
     * */
    abstract isValid(value: string, format: string): boolean;
}

/**
 * Validators config that will be used by form control to perform proper validation.
 * */
export interface ApasMonthPickerValidatorConfig<D> {
    /**
     * Minimum date available in picker.
     * */
    min: D;

    /**
     * Maximum date available in picker.
     * */
    max: D;

    /**
     * Predicate that determines is value available for picking.
     * */
    filter: (D) => boolean;
}

/**
 * Datepicker is an control that can pick any values anyway.
 * It has to be bound to the datepicker directive through nbDatepicker input.
 * */
export abstract class ApasMonthPicker<T> {
    /**
     * HTML input element date format.
     * */
    abstract format: string;

    abstract get value(): T;

    abstract set value(value: T);

    abstract get valueChange(): Observable<T>;

    abstract get init(): Observable<void>;

    /**
     * Attaches datepicker to the native input element.
     * */
    abstract attach(hostRef: ElementRef);

    /**
     * Returns validator configuration based on the input properties.
     * */
    abstract getValidatorConfig(): ApasMonthPickerValidatorConfig<T>;

    abstract show();

    abstract hide();

    abstract shouldHide(): boolean;

    abstract get isShown(): boolean;

    abstract get blur(): Observable<void>;
}

export const APAS_MONTH_ADAPTER = new InjectionToken<ApasMonthPickerAdapter<any>>('Monthpicker Adapter');

export const APAS_DATE_SERVICE_OPTIONS = new InjectionToken('Date service options');

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: 'input[apasMonthPicker]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ApasMonthPickerDirective),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ApasMonthPickerDirective),
            multi: true,
        },
    ],
})
export class ApasMonthPickerDirective<D> implements OnDestroy, ControlValueAccessor, Validator {
    /**
     * Provides datepicker component.
     * */
    @Input('apasMonthPicker')
    set setPicker(picker: ApasMonthPicker<D>) {
        this.picker = picker;
        this.setupPicker();
    }

    /**
     * Datepicker adapter.
     * */
    protected datepickerAdapter: ApasMonthPickerAdapter<D>;

    /**
     * Datepicker instance.
     * */
    protected picker: ApasMonthPicker<D>;
    protected destroy$ = new Subject<void>();
    protected isDatepickerReady: boolean = false;
    protected queue: D | undefined;
    protected onChange: (D) => void = () => { };
    protected onTouched: () => void = () => { };

    /**
     * Form control validators will be called in validators context, so, we need to bind them.
     * */
    protected validator: ValidatorFn = Validators.compose([
        this.parseValidator,
        this.minValidator,
        this.maxValidator,
        this.filterValidator,
    ].map(fn => fn.bind(this)));

    constructor(@Inject(NB_DOCUMENT) protected document,
        @Inject(APAS_MONTH_ADAPTER) protected datepickerAdapters: ApasMonthPickerAdapter<D>[],
        protected hostRef: ElementRef,
        protected dateService: NbDateService<D>,
        protected changeDetector: ChangeDetectorRef) {
        this.subscribeOnInputChange();
    }

    /**
     * Returns html input element.
     * */
    get input(): HTMLInputElement {
        return this.hostRef.nativeElement;
    }

    /**
     * Returns host input value.
     * */
    get inputValue(): string {
        return this.input.value;
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /**
     * Writes value in picker and html input element.
     * */
    writeValue(value: D) {
        if (this.isDatepickerReady) {
            this.writePicker(value);
            this.writeInput(value);
        } else {
            this.queue = value;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.input.disabled = isDisabled;
    }

    /**
     * Form control validation based on picker validator config.
     * */
    validate(): ValidationErrors | null {
        return this.validator(null);
    }

    /**
     * Hides picker, focuses the input
     */
    protected hidePicker() {
        this.input.focus();
        this.picker.hide();
    }

    /**
     * Validates that we can parse value correctly.
     * */
    protected parseValidator(): ValidationErrors | null {
        /**
         * Date services treat empty string as invalid date.
         * That's why we're getting invalid formControl in case of empty input which is not required.
         * */
        if (this.inputValue === '') {
            return null;
        }

        const isValid = this.datepickerAdapter.isValid(this.inputValue, this.picker.format);
        return isValid ? null : { nbDatepickerParse: { value: this.inputValue } };
    }

    /**
     * Validates passed value is greater than min.
     * */
    protected minValidator(): ValidationErrors | null {
        const config = this.picker.getValidatorConfig();
        const date = this.datepickerAdapter.parse(this.inputValue, this.picker.format);
        return (!config.min || !date || this.dateService.compareDates(config.min, date) <= 0) ?
            null : { nbDatepickerMin: { min: config.min, actual: date } };
    }

    /**
     * Validates passed value is smaller than max.
     * */
    protected maxValidator(): ValidationErrors | null {
        const config = this.picker.getValidatorConfig();
        const date = this.datepickerAdapter.parse(this.inputValue, this.picker.format);
        return (!config.max || !date || this.dateService.compareDates(config.max, date) >= 0) ?
            null : { nbDatepickerMax: { max: config.max, actual: date } };
    }

    /**
     * Validates passed value satisfy the filter.
     * */
    protected filterValidator(): ValidationErrors | null {
        const config = this.picker.getValidatorConfig();
        const date = this.datepickerAdapter.parse(this.inputValue, this.picker.format);
        return (!config.filter || !date || config.filter(date)) ?
            null : { nbDatepickerFilter: true };
    }

    /**
     * Chooses datepicker adapter based on passed picker component.
     * */
    protected chooseDatepickerAdapter() {
        this.datepickerAdapter = this.datepickerAdapters.find(({ picker }) => this.picker instanceof picker);

        if (this.noDatepickerAdapterProvided()) {
            throw new Error('No datepickerAdapter provided for picker');
        }
    }

    /**
     * Attaches picker to the host input element and subscribes on value changes.
     * */
    protected setupPicker() {
        this.chooseDatepickerAdapter();
        this.picker.attach(this.hostRef);

        if (this.inputValue) {
            this.picker.value = this.datepickerAdapter.parse(this.inputValue, this.picker.format);
        }

        // In case datepicker component placed after the input with datepicker directive,
        // we can't read `this.picker.format` on first change detection run,
        // since it's not bound yet, so we have to wait for datepicker component initialization.
        if (!this.isDatepickerReady) {
            this.picker.init
                .pipe(
                    take(1),
                    tap(() => this.isDatepickerReady = true),
                    filter(() => !!this.queue),
                    takeUntil(this.destroy$),
                )
                .subscribe(() => {
                    this.writeValue(this.queue);
                    this.onChange(this.queue);
                    this.changeDetector.detectChanges();
                    this.queue = undefined;
                });
        }

        this.picker.valueChange
            .pipe(takeUntil(this.destroy$))
            .subscribe((value: D) => {
                this.writePicker(value);
                this.writeInput(value);
                this.onChange(value);

                if (this.picker.shouldHide()) {
                    this.hidePicker();
                }
            });

        merge(
            this.picker.blur,
            fromEvent(this.input, 'blur').pipe(
                filter(() => !this.picker.isShown && this.document.activeElement !== this.input),
            ),
        ).pipe(takeUntil(this.destroy$))
            .subscribe(() => this.onTouched());
    }

    protected writePicker(value: D) {
        this.picker.value = value;
    }

    protected writeInput(value: D) {
        const stringRepresentation = this.datepickerAdapter.format(value, this.picker.format);
        this.hostRef.nativeElement.value = stringRepresentation;
    }

    /**
     * Validates if no datepicker adapter provided.
     * */
    protected noDatepickerAdapterProvided(): boolean {
        return !this.datepickerAdapter || !(this.datepickerAdapter instanceof ApasMonthPickerAdapter);
    }

    protected subscribeOnInputChange() {
        fromEvent(this.input, 'input')
            .pipe(
                map(() => this.inputValue),
                takeUntil(this.destroy$),
            )
            .subscribe((value: string) => this.handleInputChange(value));
    }

    /**
     * Parses input value and write if it isn't null.
     * */
    protected handleInputChange(value: string) {
        const date = this.parseInputValue(value);

        this.onChange(date);
        this.writePicker(date);
    }

    protected parseInputValue(value): D | null {
        if (this.datepickerAdapter.isValid(value, this.picker.format)) {
            return this.datepickerAdapter.parse(value, this.picker.format);
        }

        return null;
    }
}
