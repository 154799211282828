import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kullanim-sartlari',
  templateUrl: './kullanim-sartlari.component.html',
  styleUrls: ['./kullanim-sartlari.component.css']
})
export class KullanimSartlariComponent implements OnInit {

  constructor() {
    let elm = document.getElementById('nb-global-spinner');
    if(elm) elm.style.display = 'none'; 
  }

  ngOnInit() {
  }

}
