<nb-card style="width:25%;border:unset;" class="modalType animated fadeInRight" size="giant">
        <nb-card-header>
          eLogo Entegrasyon 
          <button nbButton (click)="close()" size="tiny" class="heryerdeClose">X</button>
        </nb-card-header>
        <nb-card-body [formGroup]="form">
               <div class="row">
                        
                        <div class="col-md-12">
                                <div class="form-group">
                                        <label class="font-weight-bold">Kullanıcı Adı</label>
                                        <input type="text" nbInput fullWidth apasInvalid formControlName="api_key">
                                </div>
                        </div>

                        <div class="col-md-12">
                                <div class="form-group">
                                        <label class="font-weight-bold">Şifre</label>
                                         <password-input id="'password" formControlName="api_secret"></password-input>

                                </div>
                        </div>

                         
                </div>
               
                <div class="col-md-12 text-center">
                         <button nbButton status="primary" (click)="girisYap()" [disabled]="saveLoad" [nbSpinner]="saveLoad">Giriş Yap</button>

                </div>
        </nb-card-body>
        
</nb-card>