import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'aps-emptylist',
  templateUrl: './emptylist.component.html',
  styleUrls: ['./emptylist.component.scss'],
})
export class EmptylistComponent implements OnInit, OnChanges {
  @Input() message: string = 'Veri Bulunamadı.';
  @Input() text: string = 'Lütfen Filterleri Değiştirerek Tekrar Deneyeniz.';
  @Input() animation = '/assets/dataanimation.json';
  @Input() genislik = '500px';
  @Input() yukseklik = '500px';
  constructor(private cd: ChangeDetectorRef) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.animation) {
      this.options['path'] = this.animation;
      this.cd.detectChanges();
    }
  }
  options: AnimationOptions = {
    path: this.animation,
  };
  ngOnInit() {
  }

}
