import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HttpRequest,
} from '@angular/common/http';
import localetr from '@angular/common/locales/tr';
import localetrcy from '@angular/common/locales/tr-CY';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbAuthModule,
  NbAuthOAuth2JWTToken,
  NbAuthService,
  NbPasswordAuthStrategy,
  NbTokenLocalStorage,
  NbTokenStorage,
  NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
} from '@nebular/auth';
import {
  NbDialogModule,
  NbNativeDateService,
  NbThemeModule,
  NbToastrModule,
} from '@nebular/theme';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment/locale/tr';
import { AkisEffects } from '../app/store/akis/akis.effects';
import { TagEffects } from './store/tags/tags-effects';
import { environment } from '../environments/environment';
import { CoreModule } from './@core/core.module';
import { HttpLoaderFactory } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppEffects } from './app.effects';
import { KullaniciLogoutComponent } from './kullanici/components/kullanici-logout/kullanici-logout.component';
import { KullanimSartlariComponent } from './kullanim-sartlari/kullanim-sartlari.component';
import { WebSocketService } from './services/websocket.service';
import { SharedModule } from './shared/shared.module';
import { BildirimEffects } from './store/bildirim/bildirim.effects';
import * as userActions from './store/user/user.actions';
import { UserEffects } from './store/user/user.effects';

import { CariEffects } from './pages/muhasebe/muhasebe-store/cari/cari.effects';
import { HesapEffects } from './pages/muhasebe/muhasebe-store/hesaplar/hesap.effects';
import { ExchangeEffects } from './pages/muhasebe/muhasebe-store/exchanges/exchanges.effects';
import { LangTranslateService } from './services/lang.service';
import { ApasDecryptePipe } from './directives/apas-decrypte-pipe';
import { ApasEncryptePipe } from './directives/apas-encrypte-pipe';
import { FileSizePipe } from './@theme/pipes/filesize.pipe';
import { RouterExtService } from './services/routerExt.service';
import { TagService } from './services/tags.service';
import { ApasFocusDirective } from './directives/apas-focus.directive';
import { MuhasebeAkisEffects } from './pages/muhasebe/muhasebe-store/muhasebeAkis/muhasebe-akis.effects';
import { JobEffects } from './store/jobs/jobs.effects';
import { SocketTokenService } from './services/socket.token.service';
import { BildirimService } from './services/bildirim.service';
import { OrganizationReducer, UserReducer } from './app-state';
import { AplikasyonReducer } from './store/aplikasyon/aplikasyon.reducer';
import { OrganizasyonEffects } from './store/organization/organization.effects';
import { AlisSatisEffects } from './pages/muhasebe/muhasebe-store/alis-satis/alis-satis.effects';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MuhasebeAkisService } from './pages/muhasebe/muhasebe-service/muhasebe-akis.service';
import { MuhasebeService } from './pages/muhasebe/muhasebe-service/muhasebe.service';

registerLocaleData(localetr);
registerLocaleData(localetrcy);



// import { AngularFireModule } from '@angular/fire';
// import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';

// AngularFireModule.initializeApp(environment.firebase),
// AngularFireAnalyticsModule,
// ScreenTrackingService,
// UserTrackingService,



@NgModule({
  declarations: [
    AppComponent,
    KullaniciLogoutComponent,
    KullanimSartlariComponent,
    ApasFocusDirective,
  ],

  imports: [
    NgxSpinnerModule,
    BrowserModule,
    NbDialogModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    NbThemeModule.forRoot(),
    CoreModule.forRoot(),
    StoreModule.forRoot(
      {
        Aplikasyon: AplikasyonReducer,
        User: UserReducer,
        Organization: OrganizationReducer,
      },
    ),
    StoreDevtoolsModule.instrument({}),
    EffectsModule.forRoot([
      AkisEffects,
      AlisSatisEffects,
      OrganizasyonEffects,
      MuhasebeAkisEffects,
      JobEffects,
      BildirimEffects,
      AppEffects,
      UserEffects,
      CariEffects,
      HesapEffects,
      ExchangeEffects,
      TagEffects,
    ]),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          token: {
            class: NbAuthOAuth2JWTToken,
            key: 'token',
          },
          baseEndpoint: `${environment.backendurl}`,
          login: {
            endpoint: 'api/token2/',
            method: 'post',
            redirect: {
              success: '/karsilama/',
              failure: '/logout/', // stay on the same page
            },
          },
          logout: {
            alwaysFail: false,
            endpoint: 'api/kullanici/logout/',
            method: 'post',
            redirect: {
              success: '/logout/',
              failure: null,
            },
          },
          register: {
            endpoint: 'api/kullanici/register/',
            method: 'post',
            redirect: {
              success: '/karsilama/',
              failure: null, // stay on the same page
            },
          },
          refreshToken: {
            requireValidToken: false,
            endpoint: 'api/token2/refresh/',
            method: 'post',
            redirect: {
              success: null,
              failure: '/logout/',
            },
          },
        }),
      ],
      forms: {
        login: {
          redirectDelay: 0,
          rememberMe: true,
          showMessages: {
            success: false,
          },
        },
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NbToastrModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  providers: [
    WebSocketService,
    BildirimService,
    SocketTokenService,
    MuhasebeService,
    MuhasebeAkisService,
    TagService,
    LangTranslateService,
    ApasEncryptePipe,
    ApasDecryptePipe,
    FileSizePipe,
    RouterExtService,
    { provide: LOCALE_ID, useValue: 'tr-CY' },
    // { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
    {
      provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
      useValue: function (req: HttpRequest<any>) {
        if (req.url === environment.backendurl + `api/token2/`) {
          return true;
        }
        if (req.url === environment.backendurl + `api/token2/refresh/`) {
          return false;
        } else {
          return false;
        }
      },
    },
    { provide: NbTokenStorage, useClass: NbTokenLocalStorage },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  tag: string = 'AppModule --->';
  constructor(
    private nbAuthService: NbAuthService,
    store: Store<{}>,
  ) {
    // token süresi dolmuşsa
    // hatırla beni varsa refresh at
    // yoksa tokenları sil
    this.nbAuthService.isAuthenticatedOrRefresh().subscribe((authenticated) => {
      console.log('app module is auth or refresh');
      if (!authenticated) {
        const rememberMe = localStorage.getItem('rememberMe') === 'true';
        console.log(
          'rememberMe',
          typeof rememberMe,
          localStorage.getItem('rememberMe'),
        );
        if (!rememberMe) {
          localStorage.clear();
        }
      } else {
        store.dispatch(userActions.loadUser());
      }
    });

    // nebular global tarih işlemlerini yapan servisinin
    // parse fonksiyonunu override ediyor.
    // varsayılanda bu servis sadece ingilizce tarih formatını desteklemekte.
    NbNativeDateService.prototype.parse = function (date, format) {
      const mydate = moment(date, 'll');
      mydate.locale('en');
      return new Date(mydate.format('ll'));
    };
  }
}

