import {
	AfterViewInit,
	Component,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from "@angular/core";
import { FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NbDialogService } from "@nebular/theme";
import { NgSelectComponent } from "@ng-select/ng-select";
import { concat, Observable, of, Subject } from "rxjs";
import {
	distinctUntilChanged,
	tap,
	switchMap,
	catchError,
} from "rxjs/operators";
import { Constants } from "../../../pages/constants";
import Swal from "sweetalert2";
import { ProductModel } from "../../../models/product.model";
import { addOrUpdate } from "../../../pages/global-functions";
import { ProductService } from "../../../services/product.service";
import { ControlValueAccessorConnector } from "../../../shared/control-value-accessor-connector";
import { UrunEkleComponent } from "../../depolar/urun-ekle/urun-ekle.component";
import { UrunHareketlerComponent } from "../../depolar/urun-hareketler/urun-hareketler.component";

@Component({
	selector: "apas-product-component",
	template: `
		<div
			class="info_product"
			*ngIf="productInfo"
			[nbPopover]="list"
			nbPopoverPlacement="bottom"
		>
			<nb-icon
				icon="alert-circle-outline"
				class="text-primary "
			></nb-icon>
			<label class="label"> Ürün Bilgisi </label>
		</div>
		<ng-template #list>
			<nb-card size="medium" style="min-width:400px">
				<nb-list>
					<nb-list-item *ngFor="let item of productInfo">
						<div class="col-md-8">
							<b class="ml-2"> {{ item.warehouse_name }}</b>
						</div>
						<div class="col-md">
							<span>Miktar: </span>
							<b class="ml-2">
								<apas-para-item
									[value]="item.total_amount"
									[alacak]="false"
									[negative]="true"
									[decrypt]="false"
									[setColor]="false"
									[currency]="false"
									[animate]="false"
								></apas-para-item>
								{{ c.getBirim(item?.unit) }}</b
							>
							<div>
								<button
									nbButton
									status="info"
									(click)="urunHareket(item)"
									size="tiny"
								>
									Ürün Hareketleri
								</button>
							</div>
						</div>
					</nb-list-item>
				</nb-list>
			</nb-card>
		</ng-template>
		<ng-select
			#productSelect
			class="m-tablo-baslik uzun-select ng-select-without-bottom-margin"
			[items]="productTmp"
			[loading]="productLoading"
			[typeahead]="productInput$"
			bindLabel="name"
			[bindValue]="bindValue"
			appendTo="body"
			[addTag]="false"
			[placeholder]="placeText"
			[searchable]="true"
			[virtualScroll]="true"
			(scrollToEnd)="onScrollToEnd()"
			[formControl]="control"
			(change)="urunChange($event)"
		>
			<ng-template ng-label-tmp let-item="item">
				{{ item.name }}
			</ng-template>

			<ng-template
				ng-option-tmp
				let-item="item"
				let-index="index"
				let-search="searchTerm"
			>
				<div [class]="item?.blue ? 'used' : 'unused'" class="card">
					<div
						class="d-flex alig-items-center justify-content-between"
					>
						<div>
							{{ item?.name }}
						</div>
						<div
							class="linked-div"
							(click)="urunEdit(productSelect, item)"
							*ngIf="!item?.is_public"
						>
							<nb-icon icon="edit-outline"></nb-icon>
						</div>
					</div>
				</div>
			</ng-template>

			<ng-template ng-footer-tmp>
				<div>
					<button
						*ngIf="showHizmetButton"
						fullWidth
						nbButton
						status="warning"
						(click)="hizmetSelect()"
					>
						Hizmet Ekle
					</button>
				</div>
				<div>
					<button
						fullWidth
						nbButton
						status="primary"
						(click)="yeniUrun(productSelect)"
					>
						{{ "DEPO.URUNEKLE" | translate }}
					</button>
				</div>
			</ng-template>
		</ng-select>
	`,
	styleUrls: ["./apas-product-component.component.css"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: ApasProductComponent,
			multi: true,
		},
	],
})
// tslint:disable-next-line: max-line-length
export class ApasProductComponent
	extends ControlValueAccessorConnector
	implements OnInit, AfterViewInit, OnChanges
{
	@Input() formControl!: FormControl;

	@Input() formControlName: string;

	product$: Observable<ProductModel[]>;
	c = Constants.prototype;
	productLoading = false;
	productInput$ = new Subject<any>();
	productTmp: any[] = [];
	productGetEnd: boolean = false;
	terim: any;
	productInfo;
	@Input() bindValue: string = "id";
	@ViewChild("productSelect") productSelect: NgSelectComponent;
	@Input() clear: boolean;
	@Input() showHizmetButton: boolean;
	@Input() placeText: string = "Ürün Ara / Barkod veya Ürün Adı";
	@Output() hizmetClick: EventEmitter<String> = new EventEmitter();
	@Input() public disabled: boolean = false;
	@Output() unitChange: EventEmitter<any> = new EventEmitter();
	@Output() change: EventEmitter<any> = new EventEmitter();
	constructor(
		private nbDialogService: NbDialogService,
		private _productService: ProductService,
		injector: Injector,
	) {
		super(injector);
	}

	ngAfterViewInit(): void {
		this.control?.valueChanges.subscribe((val) => {
			let unit = null;
			if (val) {
				unit = val.unit;
			}

			this.unitChange.emit(unit);
		});
	}
	@Input() forceRefresh: number = 0;
	ngOnChanges(changes: SimpleChanges): void {
		// Force Refresh.
		if (changes?.forceRefresh) {
			this.findOrGetProductById(this.control?.value);
		}
	}

	hizmetSelect() {
		this.productSelect.close();

		Swal.fire({
			title: "Hizmet Tanımı",
			input: "text",
			inputPlaceholder: "Hizmet Adı",
		}).then(({ value }) => {
			this.hizmetClick.emit(value.toString());
		});
	}

	ngOnInit() {
		this.firstList();
		this.loadProduct();

		this.product$.subscribe((data) => {});

		this.productInput$.subscribe((data) => {
			this.terim = data;
			if (data === null || data === undefined) {
				this.resetInfinitNgSelect();
			}
		});

		this.control?.valueChanges.subscribe((val) => {
			this.findOrGetProductById(val);
		});
	}

	findOrGetProductById(instanceId?) {
		// Ahmet Abi yazacağın fonksiyon isimlerini sevsinler.
		// Eğer Id'ye kayıtlı bir ürün elimdeki listede mevcutsa, sıkıntı yok,
		// Eğer mevcut değilse, bi zahmet request atıp elimdeki listeye pushlayayım.

		if (!instanceId) return;
		const found = this.productTmp.find(
			(x) => x[this.bindValue] == instanceId,
		);
		if (!found) {
			this._productService
				.getProduct(instanceId)
				.subscribe((resp: any) => {
					this.productTmp = addOrUpdate(this.productTmp, resp);
					this.control?.setValue(resp.id);
				});
		}
	}

	3;
	clearItems() {
		this.productSelect.clearModel();
	}
	yeniUrun(select: NgSelectComponent) {
		select.close();

		this.nbDialogService
			.open(UrunEkleComponent)
			.onClose.subscribe((resp) => {
				// this.productInput$.next(resp.name);
				this.productTmp = addOrUpdate(this.productTmp, resp);
				this.urunChange(resp);
				this.control?.setValue(resp.id);
			});
	}

	urunEdit(select: NgSelectComponent, row) {
		select.close();

		this.nbDialogService
			.open(UrunEkleComponent, { context: { data: row } })
			.onClose.subscribe((resp) => {
				this.productTmp = addOrUpdate(this.productTmp, resp);
				this.urunChange(resp);
				this.control?.setValue(resp.id);
			});
	}

	onScrollToEnd() {
		if (!this.terim) {
			return;
		}

		if (this.terim.page) {
			if (!this.productGetEnd) {
				this.productInput$.next({
					page: this.terim.page + 1,
					term: this.terim.term,
				});
			}
		} else {
			this.productInput$.next({ page: 1, term: this.terim });
		}
	}

	private loadProduct() {
		this.product$ = concat(
			of([]), // default items
			this.productInput$.pipe(
				distinctUntilChanged(),
				tap(() => (this.productLoading = true)),
				switchMap((term) => {
					let _term = term; // kullanıcının yazdığı
					let page; // undefinid page
					if (term) {
						if (term.page) {
							// eğer terim min icinde sayfa varsa,
							page = term.page;
							_term = term.term;
						} else {
							this.resetInfinitNgSelect();
						}
					}
					if (term === null || term === undefined) {
						this.resetInfinitNgSelect();
					}
					return this._productService.searchProduct(_term, page).pipe(
						catchError(() => of([])), // empty list on error
						tap((data) => {
							if (data.length <= 0) this.productGetEnd = true;
							this.productTmp = [...this.productTmp, ...data];
							this.productLoading = false;
						}),
					);
				}),
			),
		);
	}

	resetInfinitNgSelect() {
		this.productTmp = [];
		this.productGetEnd = false;
	}

	private firstList() {
		this.loadProduct();
		setTimeout(() => {
			this.productInput$.next("a");
		}, 300);
	}

	urunChange(event) {
		this._productService.getUrunDepoInfo(event.id).subscribe((data) => {
			console.log("Ürün Depo Bilgileri", data);
			if (data.length > 0) {
				this.productInfo = data;
			} else {
				this.productInfo = null;
			}
		});
		this.change.emit(event);
	}

	urunHareket(item) {
		this.nbDialogService.open(UrunHareketlerComponent, {
			context: { urun: item, editMod: true },
		});
	}
}
