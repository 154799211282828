<div class="input-group ngBottom" >
    <input nbInput class="moneyInput form-control" paraDirective [encrypt]="encrypt" [decrypt]="decrypt"
        [negative]="negative" [plcholder]="placeholder" [forceRefresh]="forceRefresh" [formControl]="control"
        [maxDecimal]="6"
        >
    <div class="input-group-append">
        <ng-select class="paraBirimSelect" [clearable]="false" appendTo="body" [selectOnTab]="true" [items]="currency"
            bindValue="label" bindLabel="label" [formControl]="currControl">
        </ng-select>
    </div>

</div>
<div class="uyari" *ngIf="currencyControl?.touched && currencyControl?.hasError('required')">
    Lütfen Para Birimi Seçiniz.
</div>
<div class="uyari" *ngIf="control?.touched && control?.hasError('required')">
    Lütfen Gereçli Bir Tutar Giriniz.
</div>