import { KullaniciModel } from './../models/kullanici_model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserInfo } from '../store/user/user.connector';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class KullaniciService {
  private readonly API_URL = `${environment.backendurl}api/kullanici/`;

  constructor(
    private httpClient: HttpClient,
    private store: Store<{}>,
  ) { }

  getKullanici(): Observable<KullaniciModel> {
    return this.httpClient.get<KullaniciModel>(this.API_URL);
  }

  addKullanici(registerItem: any) {
    if (registerItem !== null) {

      return this.httpClient.post(this.API_URL + 'register/', registerItem);
    }
  }
  getDavetUserInfo(value){
    return this.httpClient.post(this.API_URL + 'davetkullanici/', value);

  }

  kullaniciUpdate(data: any): Observable<KullaniciModel> {
    return this.httpClient.patch<KullaniciModel>(this.API_URL + 'update/', data);
  }

  getOrganizationUserInfo() {
    return this.httpClient.get(this.API_URL + 'org_user/');
  }

  activateUser(token: string, code: string) {
    /* TODO API URL OLARAK DEĞİŞİTİRİLECEK */
    // Kullanıcı aktivasyon için post request ile birlikte verileri gönderir.
    return this.httpClient.get(this.API_URL + 'activate/' + code + '/' + token + '/');
  }

  logout(token) {
    /* Kullanıcının token'lerini geçersiz kılar. */
    return this.httpClient.post(this.API_URL + 'logout/', token);
  }

  getMeslekler(): Observable<any> {
    const url: string = `${environment.backendurl}api/ek/jobs/`;
    return this.httpClient.get<any[]>(url);
  }

  /**
   * @param data şifresini sıfırlıyacağımız emaili içerir.
   */
  forgetPassword(data): Observable<any> {
    const url = this.API_URL + 'forget/';
    return this.httpClient.post(url, data);
  }
  /**
 * @param data yeni şifre(password) ve change_code kodunu içerir.
 */
  resetPassword(data): Observable<any> {
    const url = this.API_URL + 'change/';
    return this.httpClient.post(url, data);
  }

  changePassword(data): Observable<any> {
    const url = this.API_URL + 'change2/';
    return this.httpClient.post(url, data);
  }

  resendActivationMail(email: string) {
    const url: string = `${this.API_URL}resend/`;

    return this.httpClient.post(url, { email: email });
  }

  recomend(data: any) {
    const url: string = `${this.API_URL}recomend/`;
    return this.httpClient.post(url, data);
  }

  public user$ = this.store.select(selectUserInfo);

}
