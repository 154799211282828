import { createAction, props } from '@ngrx/store';
import { KullaniciModel } from '../../models/kullanici_model';
// Customers
// Load Actions
const loadUser = createAction('[User] Load User');
const clearUser = createAction('[User] Clear User');

const loadUserSuccess =
    createAction('[User] Load User Success', props<{ user: KullaniciModel }>());
const loadUserFail = createAction('[User] Load User Fail', props<String>());

const logoutUser = createAction('logoutUser');

const logoutUserSuccess = createAction('logoutUserSuccess');
// Interceptor logout isteği attığında çıkış işlemlerini yönetir.
// (logouting true yapar ve logoutusersucccess'i tetikler)
const invalidTokenLogout = createAction('invalidTokenLogout');
const startLogoutProcess = createAction('startLogoutProcess', props<{ token: any }>());
export {
    loadUser,
    clearUser,
    loadUserSuccess,
    loadUserFail,
    logoutUser,
    startLogoutProcess,
    logoutUserSuccess,
    invalidTokenLogout,
};
