import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { Observable, Subject, concat, of } from 'rxjs';
import { BaseModel } from '../../../../models/base.model';
import { OrganizasyonService } from '../../../../services/organizasyon.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { UrunEkleComponent } from '../../urun-ekle/urun-ekle.component';
import { ProductService } from '../../../../services/product.service';
import { birim, Constants, responseKodlari } from '../../../../pages/constants';
import { removeAllNull, unsubscribers } from '../../../../pages/global-functions';
import { DepoService } from '../../../../services/depo.service';
import { TaseronService } from '../../../../services/taseron.service';
import { Store } from '@ngrx/store';
import { selectcariItem } from '@muhasebe/muhasebe-store/cari/cari.connector';
import { CariTypes } from '@muhasebe/muhasebe-enum';

@Component({
  selector: 'ngx-urun-girisi-yap',
  templateUrl: './urun-girisi-yap.component.html',
  styleUrls: ['./urun-girisi-yap.component.scss'],
})
export class UrunGirisiYapComponent implements OnInit, OnDestroy {
  data;
  warehouseId;
  islemTipi;
  form: FormGroup;
  tedarikci$: Observable<BaseModel[]>;
  tedarikci;
  personelList$: Observable<BaseModel[]>;

 
  default_brand_items: any[];
  birim = birim;

  c = Constants.prototype;

  allPersonel: any;
  allLoading = true;

  cariData;
  cariTypes = CariTypes;
  subs = [];
  get subProductArray(): FormArray { return this.form.get('sub_products') as FormArray; }
  get brand(): FormControl { return this.subProductArray.get('brand_id') as FormControl; }
  islemYapildi = false;
  forceRefresh = 0;
  constructor(private ref: NbDialogRef<UrunGirisiYapComponent>,
    private fb: FormBuilder,
    private _organizasyonService: OrganizasyonService,
    private nbDialogService: NbDialogService,
    private _productService: ProductService,
    private cd: ChangeDetectorRef,
    private depoService: DepoService,
    private _taseronService: TaseronService,
    private store: Store


  ) { }
  ngOnDestroy(): void {
    unsubscribers(this.subs);
  }

  ngOnInit(): void {
    this.initForm();

    if (this.data) {
      this.form.get('warehouse_id').setValue(this.data?.warehouseId);
      this.islemTipi = this.data?.type;
      this.urunGirisi(this.data.row, this.data.type);
      if ((this.data.row || this.data.type) && !this.data?.alisOrSatis) {
        this.subProductAdd();
        if (this.data.type === 6) {
          this.form.addControl('warehouse_id_transfer', new FormControl(null, Validators.required));

        }

      }else{
        this.subs[0] = this.store.select(selectcariItem).subscribe((cari:any) => {
          if(cari.turu !== this.cariTypes.personel){
            //  this.form.get('delivery_by_name').setValue(cari.unvan);
            this.form.get('delivery_by_org_apas_id').setValue(cari.apas_id);

          }
          this.cariData = cari;
        });
     

      }
    }else{
      this.subProductAdd();
    }

    this._taseronService.getAllTaseron().subscribe((data) => {
      this.allPersonel = data;
      this.allLoading = false;
    });
    this.loadPersonel();


  }



  initForm() {
    this.form = this.fb.group({
      warehouse_id: [this.warehouseId, Validators.required],
      tedarikci: [],
      product_type: ['1'],
      delivery_by_apas_id: [],
      delivery_by_org_apas_id: [null],
      created_time: [new Date()],
      delivery_by_name: [],
      note: [],
      sub_products: this.fb.array([]),
      warehouse_in_out_type: [2],
      link_to_own_model: [],
      irsaliye_image: [],
    });

    this.form.get('delivery_by_org_apas_id').valueChanges.subscribe(() => {
      this.form.controls.delivery_by_apas_id.reset();
    });
  }


  urunChange(ev, i) {
    this.subProductArray.at(i).get('unit').patchValue(ev?.unit);
  }

  yeniUrun(selectComponent: NgSelectComponent) {
    // this.ngSelects.close()
    selectComponent.close();
    this.nbDialogService.open(UrunEkleComponent, {});
  }

  subProductAdd(data?) {
    const item = this.fb.group({
      product_id: [null, Validators.required],
      brand_id: [null],
      unit: [{ value: null, disabled: true }, Validators.required],
      amount: [null, Validators.required],
    });
    if(data){
      item.patchValue(data);
    }
    this.forceRefresh +1;
    this.subProductArray.push(item);

  }

  subProductDelete(i) {
    if (this.subProductArray.length > 1) this.subProductArray.removeAt(i);
    else this.subProductArray.at(i).reset();
  }





  personelLoading = false;
  personelListInput$ = new Subject<string>();
  private loadPersonel() {

    this.personelList$ = concat(
      of([]), // default items
      this.personelListInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.personelLoading = true),
        switchMap(term => {
          if (term) {
            return this._organizasyonService
              .getOtherOrganizationsPersonels(this.form.get('delivery_by_org_apas_id').value, term).pipe(
                catchError(() => of([])), // empty list on error
                tap(() => this.personelLoading = false),
              );
          } else {
            this.personelLoading = false;
            return of([]);
          }
        }),
      ),
    );
  }

  selectedDeliveryPersonel(event) {
    if (event) {
      this.form.get('delivery_by_name').setValue(event.name);
    } else
      this.form.get('delivery_by_name').setValue(null);
  }


 







  urunGirisi(row, type?: number) {
    console.log('Add Ürün',row);
    let val;
    if(this.data?.alisOrSatis){
      this.data.row.forEach(element => {
        this.subProductAdd(element);
        this.subProductArray.updateValueAndValidity();
      });
    }else{
      setTimeout(() => {
        val = { ...row, brand_id: (type === 1) ? null : row?.brand_id };
        this.subProductArray.updateValueAndValidity();
        this.subProductArray.at(0).patchValue(val);
  
      }, 1000);
    }
 
    this.form.get('warehouse_in_out_type').setValue(type);

  }

  saveLoad = false;
  urunKaydet() {
    this.saveLoad = true;
    this.form.markAsTouched();
    if (this.form.valid) {
      const formValue = removeAllNull(this.form.value);
      let dataDepo;
      this.subs[1] = this.depoService.addInOrOut({ ...formValue, product_type: 1 }).subscribe(data => {
        dataDepo = data;
        // this.filtrele();
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions)
          .then(() => {
            // this.resetDepo();
            this.islemYapildi = true;
            this.close();
            this.saveLoad = false;
          });
      }, err => {
        Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
        this.saveLoad = false;
      });


    } else {
      Swal.fire(responseKodlari.form.unValidForm as SweetAlertOptions).then(() => { });
      this.saveLoad = false;
      this.form.markAllAsTouched();
    }
  }

  close() {
    this.ref.close(this.islemYapildi);
  }

}
