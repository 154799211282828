import {  Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { KullaniciService } from '../../../services/kullanici.service';
import { JobService } from './job.service';
import * as fromJobs from '../../../store/jobs/jobs.connector';
import * as jobsActions from '../../../store/jobs/jobs.actions';
import { ControlValueAccessorConnector } from '../../../shared/control-value-accessor-connector';

@Component({
  selector: 'job-select',
  template: `
  <ng-select
      [items]="jobs$ | async"
      [loading]="isLoading"
      appendTo="body"
      typeToSearchText="Arama yapmak için en az 2 karakter yazınız."
      [minTermLength]="2"
      bindLabel="name"
      bindValue="id"
      [addTag]="addTagPromise"
      [addTagText]="'Yeni Meslek Ekle'"
      [multiple]="false"
      [selectOnTab]="false"
      [formControl]="control"
      [placeholder]="'JBOSELECT'|translate"
      class="uzun-select"
      ngDefaultControl
  >

  </ng-select>`,
  styleUrls: ['./job-select.component.scss'],
  providers: [
    KullaniciService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JobSelectComponent),
      multi: true,
    },
  ],
})
export class JobSelectComponent extends ControlValueAccessorConnector implements OnInit {

  @Input() formControl!: FormControl;

  @Input() formControlName: string;
  
  isLoading: boolean = false;
  isLoaded: boolean = false;
  @Input() addTag: boolean = true;
  jobs$;
  constructor(
    injector: Injector,
    private store: Store<{ Jobs }>,
    private jobService: JobService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.jobs$ = this.jobService.jobs$;

    this.store.pipe(select(fromJobs.selectJobsLoading)).subscribe((durum) => {
      this.isLoading = durum;
    });

    this.store.pipe(select(fromJobs.selectJobsLoaded)).subscribe((durum) => {
      this.isLoaded = durum;
    });


    if (!this.isLoading && !this.isLoaded) {
      this.store.dispatch(jobsActions.loadJobs());
    }
  }



  addTagPromise = (name?) => {
    if (!this.addTag) return false;

    return this.jobService.addJob({ name: name }).subscribe(resp => {
      this.store.dispatch(jobsActions.addJobsItem({ jobItem: resp }));
      this.control.setValue(resp.id);
    });
  }
}

