<nb-card class="modalType animated fadeInRight" size="giant" style="width: 30%;max-width: 30%;">
    <nb-card-header>
        <div class="d-flex justify-content-between align-items-center">
            <span>
                Dosya Ekle
            </span>
            <button nbButton (click)="close()" size="medium" class="heryerdeClose" size="small"> x </button>
        </div>
    </nb-card-header>
    <nb-card-body>
        <div [formGroup]="form" class="form-padding row p-0 m-0">
            <div class="col-md-12 form-group">
                    <label class="label form-label"> Dosya Adı </label>
                    <input [placeholder]="'SANTIYEDOSYALAR.DOSYAADI'|translate" nbInput fullWidth
                        formControlName="title" />
            </div>

            <div class="col-md-12 form-group">
                <label class="label form-label"> Açıklama </label>
                <textarea type="text" nbInput fullWidth placeholder="Açıklama"
                    formControlName="note"></textarea>
            </div>

            <div class="col-md-12 text-right">

                <span *ngIf="!form.get('title').value || !form.get('file').value">
                    <button nbButton status="basic" (click)="openFile(fileInput)"
                        class="m-0">{{'DOSYASEC'|translate}}</button>
                    <input style="height: 40px;" #fileInput type="file" [accept]="acceptFileFormats"
                        style="display:none" (change)="openFileDialog($event)">
                </span>

                <label class="ml-4  pl-2" style="line-break: anywhere;" *ngIf="fileName"> {{fileName}}
                    <nb-icon (click)="fileReset()" icon="close-square-outline"></nb-icon>
                </label>

                <button *ngIf="form.get('file').value" [disabled]="isSubmitting" nbButton status="primary"
                    class="ml-2" size="small" (click)="uploadFile()">
                    <span *ngIf="!row"> {{isSubmitting?'Yükleniyor %'+percentDone:'Yükle'}}</span>
                    <span *ngIf="row"> {{isSubmitting?'Güncelleniyor %'+percentDone:'Güncelle'}}</span>
                </button>
            </div>
        </div>
    </nb-card-body>
</nb-card>