import { createReducer, on } from '@ngrx/store';
import * as socketActions from './sockettoken.action';
interface SocketTokenState {
    loading: boolean;
    loaded: boolean;
    error: String;
    token: string;
}

const defaultSocketTokenState: SocketTokenState = {
    loading: false,
    loaded: false,
    error: null,
    token: null,
};

const socketTokenReducer = createReducer(
    defaultSocketTokenState,
    on(socketActions.clearState, (state) => {
        return { ...state, loading: false, loaded: false, error: null, token: null };
    }),


    on(socketActions.setConnectionToken, (state, props) => {
        return { ...state, ...props };
    }),
);

export function SocketTokenReducer(action, state) {
    return socketTokenReducer(action, state);
}

export {
    SocketTokenState,
    defaultSocketTokenState,
};
