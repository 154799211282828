<nb-card style="width:85%;border:unset;" class="modalType animated fadeInRight" size="giant" [formGroup]="form">
  <nb-card-header [class]="'align-items-center d-flex justify-content-between urunGirisTipHeader-'+islemTipi">
    {{c.setWarehouseInOutText(islemTipi)}}
    <button nbButton (click)="close()" size="tiny" class="heryerdeClose">X</button>
  </nb-card-header>

  <nb-card-body>

    <div class="row form-padding mb-3">

      <div class="col-md-3">
        <label class="form-label label">
          <span *ngIf="islemTipi === 6">Ürünlerin Alınacağı</span>
          Depo
        </label>

        <depo-select class="d-block" formControlName="warehouse_id" apasInvalid></depo-select>
      </div>

      <div class="col-md-3" *ngIf="islemTipi === 6">
        <label class="form-label label">Ürünlerin Transfer Edileceği Depo</label>

        <depo-select class="d-block" formControlName="warehouse_id_transfer" apasInvalid></depo-select>
      </div>

      <!--Tedarikçi-->
      <div class="col-md-2" *ngIf="islemTipi === 2 || islemTipi === 4 || islemTipi === 8">
        <label class="form-label label">Firma</label>

        <organization-select class="d-block" [bindValue]="'apas_id'" formControlName="delivery_by_org_apas_id"
          [showAddButtons]="true" [showTedarikciAdd]="true" [showTaseronAdd]="true" [organizationSearch]="this.cariData?.unvan">
        </organization-select>

      </div>
      <!--//Tedarikçi-->

      <!--Teslim Eden -->
      <div class="col-md-2" *ngIf="islemTipi === 2 || islemTipi === 8">
        <label class="form-label label">{{'DEPO.TESLIMEDEN' | translate}}</label>
        <div class="form-group mb-0">
          <!--Teslim Eden Personel-->
          <ng-select class="ng-select-without-bottom-margin" [typeahead]="personelListInput$"
            [loading]="personelLoading" [addTag]="true" formControlName="delivery_by_apas_id" bindLabel="name"
            bindValue="apas_id" (change)="selectedDeliveryPersonel($event)"
            [placeholder]="'DEPO.TESLIMEDEN' | translate" [items]="personelList$ | async" appendTo="body"
            [selectOnTab]="true">
          </ng-select>
        </div>
        <!--Teslim eden personel-->
      </div>
      <!--//Teslim Eden-->


      <!--Teslim Alan-->
      <div class="col-md-2 form-group" *ngIf="islemTipi === 3  || islemTipi === 8">
        <label class="form-label label">{{ 'DEPO.TESLIMALAN' | translate }}</label>

        <ng-select class="ng-select-without-bottom-margin" (change)="selectedDeliveryPersonel($event)" [addTag]="true"
          formControlName="delivery_by_apas_id" bindLabel="name" bindValue="apas_id"
          [placeholder]="'DEPO.TESLIMALAN' | translate" [loading]="allLoading" [loadingText]="'Yükleniyor'"
          [items]="allPersonel" appendTo="body">
        </ng-select>
      </div>
      <!--Teslim Alan-->



      <!--TArih-->
      <div class="col-md-2">
        <label class="form-label label">{{'TARIH' | translate }}</label>
        <input nbInput [placeholder]="'TARIH' | translate " [nbDatepicker]="startpicker" fullWidth
          formControlName="created_time" dateOnly>
        <nb-datepicker #startpicker></nb-datepicker>


      </div>
      <!--TArih-->




      <!--DOSYA-->

      <div class="col-md-3 pt-4">
        <dosya-ekle-input formControlName="irsaliye_image" [dosyaTip]="'jpg,jpeg,png'"></dosya-ekle-input>
      </div>
      <!-- DOSYA -->

      <!--Açıklama-->
      <div class="col-md-12 mt-2">
        <label class="form-label label">{{'HATIRLATMALAR.ACIKLAMA'|translate}}</label>
        <textarea formControlName="note" [placeholder]="'HATIRLATMALAR.ACIKLAMA'|translate" type="text" nbInput
          fullWidth></textarea>


      </div>
      <!--Açıklama-->


    </div>


    <ng-container formArrayName="sub_products" *ngFor="let subProducts of subProductArray.controls; let i = index ;">
      <ng-container [formGroupName]="i">
        <div class="row urunGurubu form-padding">

          <!--Ürün Adı-->
          <div class="col-md-3">
            <label class="form-label label">{{'DEPO.URUN' | translate}}</label>
            <apas-product-component class="d-block" formControlName="product_id" (change)="urunChange($event,i)" [forceRefresh]="forceRefresh"
              [showHizmetButton]="false" apasInvalid></apas-product-component>
          </div>
          <!--//Ürün Adı-->
          <!--Marka-->
          <div class="col-md-3">
            <label class="form-label label">{{'DEPO.MARKA' | translate}}</label>
            <div class="form-group">
              <marka-select formControlName="brand_id"></marka-select>
            </div>

          </div>
          <!--//Marka-->
          <!--Birim-->
          <div class="col-md-2">
            <label class="form-label label">{{'DEPO.BIRIM' | translate}}</label>
            <div class="floating">
              <ng-select class="ng-select-without-bottom-margin" formControlName="unit" bindLabel="label"
                bindValue="value" [placeholder]="'DEPO.BIRIM' | translate" [items]="birim" appendTo="body"
                [clearable]="false">
              </ng-select>
            </div>

          </div>
          <!-- // Birim -->

          <!--Miktar-->
          <div class="col-md-2">
            <label class="form-label label">{{'HAKEDISYAPILANISLER.MIKTAR'|translate}}</label>
            <input paraDirective [encrypt]="false" [negative]="false"
              [plcholder]="'HAKEDISYAPILANISLER.MIKTAR'|translate" formControlName="amount" type="text" nbInput
              fullWidth apasInvalid />
          </div>
          <!--//Miktar-->
          <div class="col-md-2 pt-4">
            <button nbButton status="danger" class="m-0" (click)="subProductDelete(i)">- Satırı Sil</button>
          </div>
        </div>

      </ng-container>

    </ng-container>

    <div class="row">
      <div class="col-md-10"></div>
      <div class="col-md p-0">
        <button nbButton status="primary" (click)="subProductAdd()">+ Satır Ekle</button>

      </div>
    </div>







  </nb-card-body>

  <nb-card-footer class="text-center">
    <button nbButton status="success" class="m-0" (click)="urunKaydet()" [nbSpinner]="saveLoad"
      nbSpinnerStatus="control" [disabled]="saveLoad">
      {{ 'DEPO.BUTTONKAYDET' | translate }}
    </button>
  </nb-card-footer>

</nb-card>