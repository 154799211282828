
<div class="customTab" style="padding-top: 0px !important;" mainView>
    <ng-container *ngIf="permision">
        <div class="row pl-1 pr-1">
            <div class="col-md mobildeGizle">
                <h6>{{bugun | date:'d MMMM y EEEE'}}</h6>
            </div>
    
    
            <div class="col-md text-right">
                <button status="warning" nbButton (click)="openCheckList()"
                nbTooltipPlacement="top" nbTooltipStatus="success" size="small">
                <nb-icon icon="checkmark-circle-outline"></nb-icon>
                ChekcList
            </button> 
                <button nbButton *ngIf="basvuruStatus && basvuruStatus !== basvuruTypes.entegre"
                    [status]="basvuruStatus == basvuruTypes.basvuruYap ? 'danger': 'success'" size="small" hero
                    (click)="openEntegrasyon()">
                    {{basvuruStatus == basvuruTypes.basvuruYap ? 'E-Fatura Entegrasyon' : 'Entegrasyon Durumu'}}
                </button>
                <span id="EUR" style="padding-left: 1rem; font-size: 12px; font-weight: bold;"
                    [nbTooltip]="'Son Güncelleme:' + kurGuncellemeSaati1 ">
                    {{kurOne}}: {{kur1Rate}}
                </span>
                <span id="USD" style=" padding-left: 1rem; font-size: 12px; font-weight: bold;"
                    [nbTooltip]="'Son Güncelleme:' + kurGuncellemeSaati1 ">
                    {{kurTwo}}: {{kur2Rate}}
                </span>
                <span id="GBP" style=" padding-left: 1rem; font-size: 12px; font-weight: bold;"
                    [nbTooltip]="'Son Güncelleme:' + kurGuncellemeSaati1 ">
                    {{kurTree}}: {{kur3Rate}}
                </span>
            </div>
        </div>
    
        <div class="mobilMenu mobildeGoster">
            <div class="d-flex align-items-center justify-content-between" (click)="menuShowHide()">
                <div>
                    {{selectedTab}}
                </div>
                <div>
                    <nb-icon icon="arrow-ios-downward-outline" style="color:#ff7043;" *ngIf="!menuAcikmi"></nb-icon>
                    <nb-icon icon="arrow-ios-upward-outline" style="color:#ff7043;" *ngIf="menuAcikmi"></nb-icon>
                </div>
            </div>
        </div>
        <div class="mobildeGizle" id="myMenu">
            <nb-tabset #muhasebeTab (changeTab)="tabChange($event)">
    
                <nb-tab [tabTitle]="muhasebeTapType.Dashboard" style="padding:0"
                    [active]="selectedTab == muhasebeTapType.Dashboard ? true : false" class="ilkMenu">
                </nb-tab>
                <nb-tab [tabTitle]="muhasebeTapType.Hesap" style="padding:0"
                    [active]="selectedTab == muhasebeTapType.Hesap ? true : false">
                </nb-tab>
                <nb-tab [tabTitle]="muhasebeTapType.Cari" style="padding:0"
                    [active]="selectedTab == muhasebeTapType.Cari ? true : false">
                </nb-tab>
                <nb-tab [tabTitle]="muhasebeTapType.Masraf" style="padding:0"
                    [active]="selectedTab == muhasebeTapType.Masraf ? true : false">
                </nb-tab>
                <nb-tab [tabTitle]="muhasebeTapType.Alis" style="padding:0"
                    [active]="selectedTab == muhasebeTapType.Alis ? true : false">
                </nb-tab>
                <nb-tab [tabTitle]="muhasebeTapType.Satis" style="padding:0"
                    [active]="selectedTab == muhasebeTapType.Satis ? true : false">
                </nb-tab>
                <nb-tab [tabTitle]="muhasebeTapType.Ceksenet" style="padding:0"
                    [active]="selectedTab == muhasebeTapType.Ceksenet ? true : false">
                </nb-tab>
                <nb-tab [tabTitle]="muhasebeTapType.Rapor" style="padding:0"
                    [active]="selectedTab == muhasebeTapType.Rapor ? true : false">
                </nb-tab>
    
    
    
            </nb-tabset>
        </div>
    </ng-container>
    <muhasebe-dashboard *ngIf="selectedTab == muhasebeTapType.Dashboard ? true : false"></muhasebe-dashboard>
    <muhasebe-hesaplar *ngIf="selectedTab == muhasebeTapType.Hesap ? true : false"></muhasebe-hesaplar>
    <muhasebe-cari *ngIf="selectedTab == muhasebeTapType.Cari ? true : false"></muhasebe-cari>
    <muhasebe-masraflar *ngIf="selectedTab == muhasebeTapType.Masraf ? true : false"></muhasebe-masraflar>
    <muhasebe-alislar [alis_or_satis]="'alis'" *ngIf="selectedTab == muhasebeTapType.Alis ? true : false">
    </muhasebe-alislar>
    <muhasebe-alislar [alis_or_satis]="'satis'" *ngIf="selectedTab == muhasebeTapType.Satis ? true : false">
    </muhasebe-alislar>
    <!-- <muhasebe-e-fatura *ngIf="selectedTab == muhasebeTapType.Efatura ? true : false"></muhasebe-e-fatura> -->
    <cek-senet *ngIf="selectedTab == muhasebeTapType.Ceksenet ? true : false"></cek-senet>
    <muhasebe-raporlar *ngIf="selectedTab == muhasebeTapType.Rapor ? true : false"></muhasebe-raporlar>

</div>