import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { interval} from 'rxjs';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import * as orgAction from '../../../store/organization/organization.actions';
import { selectUyari } from '../../../store/organization/organization.connector';

@Component({
  selector: 'uyarilar',
  templateUrl: './uyarilar.component.html',
  styleUrls: ['./uyarilar.component.scss'],
  providers: [OrganizasyonService],
})
export class UyarilarComponent implements OnInit {
  @ViewChild('uyari1') uyari1: ElementRef;
  @ViewChild('uyari2') uyari2: ElementRef;
  private alive = true;
  uyarilist = [];
  is_first: boolean = false;
  currentUyari: any;
  oldUyari: any;
  uyarilar$;
  uyariClose$;
  gizleGoster = true;
  constructor(private organizationService: OrganizasyonService,
    private router: Router,
    private store: Store) { 
      this.uyariClose$ = this.store.pipe(select(selectUyari));
      this.uyariClose$.subscribe((data) => {
        this.gizleGoster = data;
      });


    if (!this.is_first) {

      this.organizationService.getUyarilar().subscribe(uyari => {
        this.uyarilist = uyari;
        this.is_first = true;
        this.setAnimation();
      });
    }
  }

  ngOnInit(): void {
  }



  close_alert() {
    this.uyarilar$.unsubscribe();
    if (this.uyarilist.length > 1) {
      this.uyarilist = this.uyarilist.filter(f => f.error_code !== this.currentUyari.error_code);
      this.setAnimation();

    } else {
      this.uyari1.nativeElement.classList.add('fadeOutRight');
      setTimeout(() => {
        this.uyarilist = [];
        this.currentUyari = undefined;
        this.oldUyari = undefined;
        this.store.dispatch(orgAction.uyariClose());
      }, 1000);
    }
  }

  changeUyari() {
    if (this.uyarilist.length > 0) {
      this.uyarilist.forEach(uyari => {
        this.currentUyari = uyari;
      });
    }
  }


  setAnimation() {
    if (this.uyarilist.length > 0) {
      this.currentUyari = this.uyarilist[0];
    }
    this.uyarilar$ = interval(10000).subscribe(val => {
      // 

      if (this.uyarilist.length > 0) {
        const index = val % this.uyarilist.length;
        this.oldUyari = { ...this.currentUyari };
        this.currentUyari = undefined;
        setTimeout(() => {
          this.currentUyari = this.uyarilist[index];
          this.oldUyari = undefined;
        }, 200);
        // 
      } else {
        this.uyarilar$.unsubscribe();
        // 
      }
    });
  }

  gotoUyari(error_code: string) {

    this.router.navigateByUrl('/profil/firma-profil');
    this.close_alert();
    // switch (error_code) {

    //   case 'UM001':
    //     this.router.navigateByUrl('/profil/firma-profil', {
    //       state: {
    //         hesapGoster: true,
    //       },
    //     });
    //     this.close_alert();
    //     break;
    //   case 'UM002':
    //     this.router.navigateByUrl('/profil/firma-profil', {
    //       state: {
    //         hesapGoster: true,
    //       },
    //     });
    //     this.close_alert();


    //   case 'UM003':
    //     this.router.navigateByUrl('/profil/firma-profil', {
    //       state: {
    //         hesapGoster: false,
    //         iletisimEdit: true,
    //       },
    //     });
    //     break;

    //   case 'UM004':
    //     this.router.navigateByUrl('/profil/kullanici-profil', {
    //       state: {
    //         iletisimEdit: true,
    //       },
    //     });
    //     this.close_alert();

    //   break;
    //   case 'UM005':
    //     this.router.navigateByUrl('/profil/firma-profil', {
    //       state: {
    //         hesapGoster: true,
    //       },
    //     });
    //     this.close_alert();

    //   break;

    //   default:
    //     break;

    // }
  }



}
