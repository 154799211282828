<nb-card  style="border-top: 5px solid #1ABC9C !important;">
    <nb-card-header>
        E-Fatura Ayarları 
    </nb-card-header>
    <nb-card-body *ngIf="entegrasyonInfo?.api_key">
        <div class="row d-flex pb-3">
            <div class="col-md-9">
                <label for="" class="label form-label">eLogo Entegrasyon</label>
                <div fullWidth style="font-weight: bold;">{{entegrasyonInfo?.api_key}}</div>
            </div>
            <div class="col-md-3 d-flex text-right">
                <button style="margin-top: auto !important; margin-left: auto !important;" nbButton size="small"
                    status="info" (click)="entegrasyonGiris()">
                    <nb-icon icon='edit-outline'></nb-icon>
                </button>
                <button style="margin-top: auto !important;" nbButton size="small" status="danger"
                    (click)="deleteEntegrasyon()">
                    <nb-icon icon='trash-outline'></nb-icon>
                </button>
            </div>
        </div>
        <div class="row  pb-3">
            <div class="col-md-9">
                <label for="" class="form-label label"
                    [nbTooltip]="'E-Fatura Belge Numarasının önüne eklenecek 3 Harften oluşan kısaltama. Örneğin (ABC) : ABC0000000195'">E-Fatura
                    Ön Eki</label>
                <input type="text" [formControl]="faturaTagControl" nbInput fullWidth apasInvalid
                    placeholder="E-Fatura Ön Eki">
            </div>
            <div class="col d-flex text-right">
                <button nbButton style="margin-top: auto !important; margin-left: auto !important;" nbButton
                    size="small" status="info" (click)="setFaturaPrefix()">
                    <nb-icon icon="checkmark-circle-outline"></nb-icon>
                    Kaydet
                </button>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col-md-9">
                <label for="" class="form-label label"
                    [nbTooltip]="'E-Arşiv Belge Numarasının önüne eklenecek 3 Harften oluşan kısaltama. Örneğin (EBC) : EBC0000000195'">E-Arşiv
                    Ön Eki</label>
                <input [formControl]="earsivTagControl" type="text" nbInput fullWidth apasInvalid
                    placeholder="E-Arşiv Ön Eki">
            </div>
            <div class="col d-flex text-right">
                <button nbButton style="margin-top: auto !important; margin-left: auto !important;" nbButton
                    size="small" status="info" (click)="setEArsivPrefix()">
                    <nb-icon icon="checkmark-circle-outline"></nb-icon>
                    Kaydet
                </button>
            </div>
        </div>

        <div class="row form-group">
            <div class="col-md-3">
                <label class="label">Firma Logosu</label>
                <dosya-ekle-input [formControl]="firmaLogo" [dosyaTip]="imgFormatter"></dosya-ekle-input>
            </div>
            <div class="col-md-6 text-center">
                <img [src]="firmaLogo.value | safe" *ngIf="firmaLogo.value" width="100px">
            </div>
            <div class="col-md d-flex text-right align-items-center">
                <button nbButton style="margin-top: auto !important; margin-left: auto !important;" nbButton
                    size="small" status="info" (click)="postFirmaLogo()">
                    <nb-icon icon="checkmark-circle-outline"></nb-icon>
                    Kaydet
                </button>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-md-3">
                <label class="label">İmza</label>
                <dosya-ekle-input [buttonStatus]="'info'" [formControl]="firmaImza" [dosyaTip]="imgFormatter">
                </dosya-ekle-input>

            </div>
            <div class="col-md-6 text-center">
                <img [src]="firmaImza.value | safe" *ngIf="firmaImza.value" style="width: 100px;">

            </div>
            <div class="col-md d-flex text-right align-items-center">
                <button nbButton style="margin-top: auto !important; margin-left: auto !important;" nbButton
                    size="small" status="info" (click)="postImza()">
                    <nb-icon icon="checkmark-circle-outline"></nb-icon>
                    Kaydet
                </button>

            </div>

        </div>
    </nb-card-body>
    <nb-card-body *ngIf="!entegrasyonInfo?.api_key">
        <div class="text-center p-3">
            Eğer hali hazırda e-fatura mükellefi ve eLogo üyesi iseniz, aşağıda bulunan <b>Giriş Yap</b> butonuna
            tıklayarak giriş yapabilir ve e-fatura modülünü kullanabilirsiniz.
            <div>
                <button nbButton status="primary" size="small" (click)="entegrasyonGiris()">Giriş Yap</button>
            </div>
        </div>

    </nb-card-body>
</nb-card>