 export const sehirAndIlce =  [
          {
            "city": "Adana",
            "plaka_kodu": "01",
            "alan_kodu": "0322",
            "nufus": "2.220.125",
            "bolge": "Akdeniz",
            "yuzolcumu": "13.844",
            "erkek_nufus_yuzde": "49.15%",
            "erkek_nufus": "1.106.811",
            "kadin_nufus_yuzde": "50.15%",
            "kadin_nufus": "1.113.314",
            "nufus_yuzdesi_genel": "2,71%",
            "ilceler": [
              {
                "district": "Aladağ",
                "nufus": "16.653",
                "erkek_nufus": "8.561",
                "kadin_nufus": "8.092",
                "yuzolcumu": "1.340"
              },
              {
                "district": "Seyhan",
                "nufus": "793.480",
                "erkek_nufus": "393.872",
                "kadin_nufus": "399.608",
                "yuzolcumu": "444"
              },
              {
                "district": "Yüreğir",
                "nufus": "415.198",
                "erkek_nufus": "208.709",
                "kadin_nufus": "206.489",
                "yuzolcumu": "835"
              },
              {
                "district": "Çukurova",
                "nufus": "365.735",
                "erkek_nufus": "176.561",
                "kadin_nufus": "189.174",
                "yuzolcumu": "250"
              },
              {
                "district": "Sarıçam",
                "nufus": "173.154",
                "erkek_nufus": "88.404",
                "kadin_nufus": "84.750",
                "yuzolcumu": "770"
              },
              {
                "district": "Ceyhan",
                "nufus": "160.474",
                "erkek_nufus": "80.485",
                "kadin_nufus": "79.989",
                "yuzolcumu": "1.426"
              },
              {
                "district": "Kozan",
                "nufus": "130.495",
                "erkek_nufus": "65.644",
                "kadin_nufus": "64.851",
                "yuzolcumu": "1.903"
              },
              {
                "district": "İmamoğlu",
                "nufus": "28.239",
                "erkek_nufus": "14.140",
                "kadin_nufus": "14.099",
                "yuzolcumu": "445"
              },
              {
                "district": "Karataş",
                "nufus": "24.559",
                "erkek_nufus": "12.516",
                "kadin_nufus": "12.043",
                "yuzolcumu": "862"
              },
              {
                "district": "Karaisalı",
                "nufus": "22.308",
                "erkek_nufus": "11.397",
                "kadin_nufus": "10.911",
                "yuzolcumu": "1.165"
              },
              {
                "district": "Pozantı",
                "nufus": "20.683",
                "erkek_nufus": "10.820",
                "kadin_nufus": "9.863",
                "yuzolcumu": "899"
              },
              {
                "district": "Yumurtalık",
                "nufus": "18.587",
                "erkek_nufus": "9.420",
                "kadin_nufus": "9.167",
                "yuzolcumu": "447"
              },
              {
                "district": "Tufanbeyli",
                "nufus": "17.667",
                "erkek_nufus": "9.123",
                "kadin_nufus": "8.544",
                "yuzolcumu": "851"
              },
              {
                "district": "Feke",
                "nufus": "17.555",
                "erkek_nufus": "9.151",
                "kadin_nufus": "8.404",
                "yuzolcumu": "1.218"
              },
              {
                "district": "Saimbeyli",
                "nufus": "15.338",
                "erkek_nufus": "8.008",
                "kadin_nufus": "7.330",
                "yuzolcumu": "989"
              }
            ],
            "kisa_bilgi": "Ülkenin en büyük şehirlerinden biri olan Adana, Toros dağlarının güneyinde bulunan ve Mersin, Osmaniye, Hatay illerini içine alan Çukurova'nın merkezinde, Seyhan nehri üzerinde yer alır"
          },
          {
            "city": "Adıyaman",
            "plaka_kodu": "02",
            "alan_kodu": "416",
            "nufus": "624.513",
            "bolge": "Güneydoğu Anadolu",
            "yuzolcumu": "7.337",
            "erkek_nufus_yuzde": "50,44%",
            "erkek_nufus": "314.998",
            "kadin_nufus_yuzde": "49,56%",
            "kadin_nufus": "309.515",
            "nufus_yuzdesi_genel": "0,76%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "304.615",
                "erkek_nufus": "153.042",
                "kadin_nufus": "151.573",
                "yuzolcumu": "1.814"
              },
              {
                "district": "Kahta",
                "nufus": "122.774",
                "erkek_nufus": "62.360",
                "kadin_nufus": "60.414",
                "yuzolcumu": "1.274"
              },
              {
                "district": "Besni",
                "nufus": "77.301",
                "erkek_nufus": "39.035",
                "kadin_nufus": "38.266",
                "yuzolcumu": "1.235"
              },
              {
                "district": "Gölbaşı",
                "nufus": "49.255",
                "erkek_nufus": "24.790",
                "kadin_nufus": "24.465",
                "yuzolcumu": "800"
              },
              {
                "district": "Gerger",
                "nufus": "19.035",
                "erkek_nufus": "9.527",
                "kadin_nufus": "9.508",
                "yuzolcumu": "668"
              },
              {
                "district": "Sincik",
                "nufus": "17.414",
                "erkek_nufus": "8.653",
                "kadin_nufus": "8.761",
                "yuzolcumu": "495"
              },
              {
                "district": "Çelikhan",
                "nufus": "16.065",
                "erkek_nufus": "8.292",
                "kadin_nufus": "7.773",
                "yuzolcumu": "444"
              },
              {
                "district": "Tut",
                "nufus": "10.161",
                "erkek_nufus": "5.255",
                "kadin_nufus": "4.906",
                "yuzolcumu": "290"
              },
              {
                "district": "Samsat",
                "nufus": "7.893",
                "erkek_nufus": "4.044",
                "kadin_nufus": "3.849",
                "yuzolcumu": "319"
              }
            ],
            "kisa_bilgi": "Güneydoğu Anadolu Bölgesinin Batısında Akdeniz Bölgesiyle birleşen noktada yer alan Adıyaman, 16 farklı medeniyete ev sahipliği yapmış, tarihin bilinen en eski yerleşim yerlerinden biri."
          },
          {
            "city": "Afyonkarahisar",
            "plaka_kodu": "03",
            "alan_kodu": "272",
            "nufus": "624.513",
            "bolge": "Ege",
            "yuzolcumu": "14.016",
            "erkek_nufus_yuzde": "49,64%",
            "erkek_nufus": "360.152",
            "kadin_nufus_yuzde": "50,36%",
            "kadin_nufus": "365.416",
            "nufus_yuzdesi_genel": "0,88%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "299.673",
                "erkek_nufus": "148.430",
                "kadin_nufus": "151.243",
                "yuzolcumu": "1.025"
              },
              {
                "district": "Sandıklı",
                "nufus": "56.182",
                "erkek_nufus": "27.488",
                "kadin_nufus": "28.694",
                "yuzolcumu": "1.238"
              },
              {
                "district": "Dinar",
                "nufus": "47.304",
                "erkek_nufus": "23.589",
                "kadin_nufus": "23.715",
                "yuzolcumu": "1.254"
              },
              {
                "district": "Bolvadin",
                "nufus": "43.398",
                "erkek_nufus": "21.962",
                "kadin_nufus": "21.436",
                "yuzolcumu": "944"
              },
              {
                "district": "Sinanpaşa",
                "nufus": "41.468",
                "erkek_nufus": "20.558",
                "kadin_nufus": "20.910",
                "yuzolcumu": "889"
              },
              {
                "district": "Emirdağ",
                "nufus": "37.817",
                "erkek_nufus": "19.065",
                "kadin_nufus": "18.752",
                "yuzolcumu": "2.103"
              },
              {
                "district": "Şuhut",
                "nufus": "36.947",
                "erkek_nufus": "18.355",
                "kadin_nufus": "18.592",
                "yuzolcumu": "1.044"
              },
              {
                "district": "Çay",
                "nufus": "31.412",
                "erkek_nufus": "15.372",
                "kadin_nufus": "16.040",
                "yuzolcumu": "803"
              },
              {
                "district": "İhsaniye",
                "nufus": "28.603",
                "erkek_nufus": "14.355",
                "kadin_nufus": "14.248",
                "yuzolcumu": "849"
              },
              {
                "district": "İscehisar",
                "nufus": "24.534",
                "erkek_nufus": "12.341",
                "kadin_nufus": "12.193",
                "yuzolcumu": "489"
              },
              {
                "district": "Sultandağı",
                "nufus": "15.076",
                "erkek_nufus": "7.234",
                "kadin_nufus": "7.842",
                "yuzolcumu": "928"
              },
              {
                "district": "Çobanlar",
                "nufus": "14.503",
                "erkek_nufus": "7.288",
                "kadin_nufus": "7.215",
                "yuzolcumu": "165"
              },
              {
                "district": "Dazkırı",
                "nufus": "11.173",
                "erkek_nufus": "5.611",
                "kadin_nufus": "5.562",
                "yuzolcumu": "408"
              },
              {
                "district": "Başmakçı",
                "nufus": "10.031",
                "erkek_nufus": "5.002",
                "kadin_nufus": "5.029",
                "yuzolcumu": "1254"
              },
              {
                "district": "Hocalar",
                "nufus": "9.597",
                "erkek_nufus": "4.732",
                "kadin_nufus": "4.865",
                "yuzolcumu": "506"
              },
              {
                "district": "Bayat",
                "nufus": "7.813",
                "erkek_nufus": "3.821",
                "kadin_nufus": "3.992",
                "yuzolcumu": "bilnmiyor"
              },
              {
                "district": "Evciler",
                "nufus": "7.574",
                "erkek_nufus": "3.779",
                "kadin_nufus": "13.795",
                "yuzolcumu": "252"
              },
              {
                "district": "Kızılören",
                "nufus": "2.463",
                "erkek_nufus": "1.170",
                "kadin_nufus": "1.293",
                "yuzolcumu": "111"
              }
            ],
            "kisa_bilgi": "Afyonkarahisar ın büyük bir bölümü Ege Bölgesinin iç batı olarak adlandırılan kesiminde yer alır. Türkiye'de kaplıca ve ılıca yönünden en önemli şehirlerden biri olan Afyonkarahisar'da, dört tane termal turizm merkezi var."
          },
          {
            "city": "Ağrı",
            "plaka_kodu": "04",
            "alan_kodu": "472",
            "nufus": "539.657",
            "bolge": "Doğu Anadolu",
            "yuzolcumu": "11.099",
            "erkek_nufus_yuzde": "52,06%",
            "erkek_nufus": "280.923",
            "kadin_nufus_yuzde": "47,94%",
            "kadin_nufus": "258.734",
            "nufus_yuzdesi_genel": "0,66%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "149.188",
                "erkek_nufus": "76.4162",
                "kadin_nufus": "72.772",
                "yuzolcumu": "1.695"
              },
              {
                "district": "Patnos",
                "nufus": "122.833",
                "erkek_nufus": "64.732",
                "kadin_nufus": "58.101",
                "yuzolcumu": "1.394"
              },
              {
                "district": "Doğubayazıt",
                "nufus": "121.263",
                "erkek_nufus": "63.952",
                "kadin_nufus": "57.311",
                "yuzolcumu": "2.250"
              },
              {
                "district": "Diyadin",
                "nufus": "42.123",
                "erkek_nufus": "21.970",
                "kadin_nufus": "20.153",
                "yuzolcumu": "1.351"
              },
              {
                "district": "Eleşkirt",
                "nufus": "34.180",
                "erkek_nufus": "17.723",
                "kadin_nufus": "16.457",
                "yuzolcumu": "1.307"
              },
              {
                "district": "Tutak",
                "nufus": "30.505",
                "erkek_nufus": "15.690",
                "kadin_nufus": "14.815",
                "yuzolcumu": "1.407"
              },
              {
                "district": "Taşlıçay",
                "nufus": "20.450",
                "erkek_nufus": "10.469",
                "kadin_nufus": "9.981",
                "yuzolcumu": "822"
              },
              {
                "district": "Hamur",
                "nufus": "19.115",
                "erkek_nufus": "9.971",
                "kadin_nufus": "9.144",
                "yuzolcumu": "873"
              }
            ],
            "kisa_bilgi": "Ağrı, Türkiye'nin Doğu Anadolu Bölgesi'nde bulunan bir ildir. Adını kısmen sınırları içerisinde bulunan Ağrı Dağı'ndan almıştır."
          },
          {
            "city": "Amasya",
            "plaka_kodu": "05",
            "alan_kodu": "358",
            "nufus": "337.508",
            "bolge": "Karadeniz",
            "yuzolcumu": "5.628",
            "erkek_nufus_yuzde": "50,35%",
            "erkek_nufus": "169.920",
            "kadin_nufus_yuzde": "49,65%",
            "kadin_nufus": "167.588",
            "nufus_yuzdesi_genel": "0,41%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "149.084",
                "erkek_nufus": "76.418",
                "kadin_nufus": "72.666",
                "yuzolcumu": "1.889"
              },
              {
                "district": "Merzifon",
                "nufus": "71.916",
                "erkek_nufus": "35.768",
                "kadin_nufus": "36.148",
                "yuzolcumu": "888"
              },
              {
                "district": "Suluova",
                "nufus": "46.608",
                "erkek_nufus": "23.150",
                "kadin_nufus": "23.458",
                "yuzolcumu": "456"
              },
              {
                "district": "Taşova",
                "nufus": "31.508",
                "erkek_nufus": "15.418",
                "kadin_nufus": "16.090",
                "yuzolcumu": "971"
              },
              {
                "district": "Göynücek",
                "nufus": "10.703",
                "erkek_nufus": "5.357",
                "kadin_nufus": "5.346",
                "yuzolcumu": "591"
              },
              {
                "district": "Hamamözü",
                "nufus": "3.955",
                "erkek_nufus": "1.942",
                "kadin_nufus": "2.013",
                "yuzolcumu": "204"
              }
            ],
            "kisa_bilgi": "7500 yıllık bir geçmişe sahip, ülkemizin önemli kültür, turizm ve tarih merkezlerinden biri olan şehir, bilindiği gibi misket elmasıyla meşhur. Sert, küçük ve tatlı olan, kendine has bir koku ve lezzete sahip bu elma oldukça dayanıklıdır."
          },
          {
            "city": "Ankara",
            "plaka_kodu": "06",
            "alan_kodu": "312",
            "nufus": "5.503.985",
            "bolge": "İç Anadolu",
            "yuzolcumu": "25.632",
            "erkek_nufus_yuzde": "49,58%",
            "erkek_nufus": "2.728.900",
            "kadin_nufus_yuzde": "50,42%",
            "kadin_nufus": "2.775.085",
            "nufus_yuzdesi_genel": "6,71%",
            "ilceler": [
              {
                "district": "Çankaya",
                "nufus": "920.890",
                "erkek_nufus": "442.513",
                "kadin_nufus": "478.377",
                "yuzolcumu": "483"
              },
              {
                "district": "Keçiören",
                "nufus": "909.787",
                "erkek_nufus": "446.190",
                "kadin_nufus": "463.597",
                "yuzolcumu": "159"
              },
              {
                "district": "Yenimahalle",
                "nufus": "663.580",
                "erkek_nufus": "323.562",
                "kadin_nufus": "340.018",
                "yuzolcumu": "219"
              },
              {
                "district": "Mamak",
                "nufus": "647.252",
                "erkek_nufus": "323.710",
                "kadin_nufus": "323.542",
                "yuzolcumu": "321"
              },
              {
                "district": "Etimesgut",
                "nufus": "570.727",
                "erkek_nufus": "285.797",
                "kadin_nufus": "284.930",
                "yuzolcumu": "273"
              },
              {
                "district": "Sincan",
                "nufus": "518.893",
                "erkek_nufus": "264.482",
                "kadin_nufus": "254.411",
                "yuzolcumu": "880"
              },
              {
                "district": "Altındağ",
                "nufus": "370.024",
                "erkek_nufus": "186.440",
                "kadin_nufus": "183.584",
                "yuzolcumu": "123"
              },
              {
                "district": "Pursaklar",
                "nufus": "143.055",
                "erkek_nufus": "71.571",
                "kadin_nufus": "71.484",
                "yuzolcumu": "169"
              },
              {
                "district": "Gölbaşı",
                "nufus": "134.378",
                "erkek_nufus": "67.726",
                "kadin_nufus": "66.652",
                "yuzolcumu": "1.364"
              },
              {
                "district": "Polatlı",
                "nufus": "122.287",
                "erkek_nufus": "61.597",
                "kadin_nufus": "60.690",
                "yuzolcumu": "3.618"
              },
              {
                "district": "Çubuk",
                "nufus": "89.046",
                "erkek_nufus": "44.498",
                "kadin_nufus": "44.548",
                "yuzolcumu": "1.198"
              },
              {
                "district": "Kahramankazan",
                "nufus": "53.522",
                "erkek_nufus": "27.319",
                "kadin_nufus": "26.203",
                "yuzolcumu": "547"
              },
              {
                "district": "Beypazarı",
                "nufus": "48.274",
                "erkek_nufus": "24.062",
                "kadin_nufus": "24.212",
                "yuzolcumu": "1.697"
              },
              {
                "district": "Haymana",
                "nufus": "45.931",
                "erkek_nufus": "23.866",
                "kadin_nufus": "22.065",
                "yuzolcumu": "2.164"
              },
              {
                "district": "Elmadağ",
                "nufus": "45.349",
                "erkek_nufus": "23.062",
                "kadin_nufus": "22.287",
                "yuzolcumu": "647"
              },
              {
                "district": "Akyurt",
                "nufus": "34.588",
                "erkek_nufus": "17.555",
                "kadin_nufus": "17.033",
                "yuzolcumu": "369"
              },
              {
                "district": "Şereflikoçhisar",
                "nufus": "34.202",
                "erkek_nufus": "17.353",
                "kadin_nufus": "16.849",
                "yuzolcumu": "2.155"
              },
              {
                "district": "Bala",
                "nufus": "33.644",
                "erkek_nufus": "17.446",
                "kadin_nufus": "16.198",
                "yuzolcumu": "1.851"
              },
              {
                "district": "Kızılcahamam",
                "nufus": "32.647",
                "erkek_nufus": "16.531",
                "kadin_nufus": "16.116",
                "yuzolcumu": "1.623"
              },
              {
                "district": "Nallıhan",
                "nufus": "28.091",
                "erkek_nufus": "13.888",
                "kadin_nufus": "14.203",
                "yuzolcumu": "2.079"
              },
              {
                "district": "Ayaş",
                "nufus": "15.540",
                "erkek_nufus": "8.101",
                "kadin_nufus": "7.439",
                "yuzolcumu": "1.041"
              },
              {
                "district": "Çamlıdere",
                "nufus": "15.148",
                "erkek_nufus": "7.920",
                "kadin_nufus": "7.228",
                "yuzolcumu": "782"
              },
              {
                "district": "Kalecik",
                "nufus": "13.450",
                "erkek_nufus": "6.956",
                "kadin_nufus": "6.494",
                "yuzolcumu": "1.110"
              },
              {
                "district": "Güdül",
                "nufus": "10.074",
                "erkek_nufus": "4.979",
                "kadin_nufus": "5.095",
                "yuzolcumu": "540"
              },
              {
                "district": "Evren",
                "nufus": "3.606",
                "erkek_nufus": "1.776",
                "kadin_nufus": "1.830",
                "yuzolcumu": "222"
              }
            ],
            "kisa_bilgi": "Bolu, Çankırı, Kırıkkale, Kırşehir, Aksaray, Konya ve Eskişehir illeri ile çevrili ve Türkiye'nin başkenti Ankara, Cumhuriyetin kuruluşundan bu yana hızla gelişme göstermiştir. Şehrin amblemi, Hitit uygarlığının da simgesi olan Hitit Güneş Kursu'dur."
          },
          {
            "city": "Antalya",
            "plaka_kodu": "07",
            "alan_kodu": "242",
            "nufus": "2.426.356",
            "bolge": "Akdeniz",
            "yuzolcumu": "20.177",
            "erkek_nufus_yuzde": "50,37%",
            "erkek_nufus": "1.222.086",
            "kadin_nufus_yuzde": "49,63%",
            "kadin_nufus": "1.204.270",
            "nufus_yuzdesi_genel": "2,96%",
            "ilceler": [
              {
                "district": "Kepez",
                "nufus": "531.619",
                "erkek_nufus": "270.248",
                "kadin_nufus": "261.371",
                "yuzolcumu": "292"
              },
              {
                "district": "Muratpaşa",
                "nufus": "495.688",
                "erkek_nufus": "242.924",
                "kadin_nufus": "252.764",
                "yuzolcumu": "96"
              },
              {
                "district": "Alanya",
                "nufus": "312.319",
                "erkek_nufus": "159.308",
                "kadin_nufus": "153.011",
                "yuzolcumu": "1.577"
              },
              {
                "district": "Manavgat",
                "nufus": "230.597",
                "erkek_nufus": "118.336",
                "kadin_nufus": "112.261",
                "yuzolcumu": "2.351"
              },
              {
                "district": "Konyaaltı",
                "nufus": "182.112",
                "erkek_nufus": "87.688",
                "kadin_nufus": "94.424",
                "yuzolcumu": "546"
              },
              {
                "district": "Serik",
                "nufus": "124.335",
                "erkek_nufus": "63.703",
                "kadin_nufus": "60.632",
                "yuzolcumu": "1.263"
              },
              {
                "district": "Aksu",
                "nufus": "71.643",
                "erkek_nufus": "36.495",
                "kadin_nufus": "35.148",
                "yuzolcumu": "406"
              },
              {
                "district": "Kumluca",
                "nufus": "68.610",
                "erkek_nufus": "34.659",
                "kadin_nufus": "33.951",
                "yuzolcumu": "1.225"
              },
              {
                "district": "Döşemealtı",
                "nufus": "63.186",
                "erkek_nufus": "32.288",
                "kadin_nufus": "30.898",
                "yuzolcumu": "687"
              },
              {
                "district": "Kaş",
                "nufus": "58.600",
                "erkek_nufus": "30.185",
                "kadin_nufus": "28.415",
                "yuzolcumu": "1.750"
              },
              {
                "district": "Korkuteli",
                "nufus": "55.712",
                "erkek_nufus": "27.989",
                "kadin_nufus": "27.723",
                "yuzolcumu": "2.433"
              },
              {
                "district": "Gazipaşa",
                "nufus": "50.003",
                "erkek_nufus": "25.306",
                "kadin_nufus": "24.697",
                "yuzolcumu": "1.111"
              },
              {
                "district": " Finike",
                "nufus": "48.131",
                "erkek_nufus": "24.263",
                "kadin_nufus": "23.868",
                "yuzolcumu": "768"
              },
              {
                "district": "Kemer",
                "nufus": "43.226",
                "erkek_nufus": "22.800",
                "kadin_nufus": "20.426",
                "yuzolcumu": "412"
              },
              {
                "district": "Elmalı",
                "nufus": "39.254",
                "erkek_nufus": "19.782",
                "kadin_nufus": "19.472",
                "yuzolcumu": "1.433"
              },
              {
                "district": "Demre",
                "nufus": "25.893",
                "erkek_nufus": "13.286",
                "kadin_nufus": "12.607",
                "yuzolcumu": "329"
              },
              {
                "district": "Akseki",
                "nufus": "13.084",
                "erkek_nufus": "6.682",
                "kadin_nufus": "6.402",
                "yuzolcumu": "bilinmiyor"
              },
              {
                "district": "Gündoğmuş",
                "nufus": "8.726",
                "erkek_nufus": "4.300",
                "kadin_nufus": "4.426",
                "yuzolcumu": "1.175"
              },
              {
                "district": "İbradi",
                "nufus": "3.618",
                "erkek_nufus": "1.844",
                "kadin_nufus": "1.774",
                "yuzolcumu": "778"
              }
            ],
            "kisa_bilgi": "Akdeniz bölgesinin batısında ve Antalya Körfezi ile Batı Toroslar arasında yer alan Antalya, özellikle turizmin etkisiyle hızla nüfus alan ve gelişen, en güzel şehirlerimizden biridir."
          },
          {
            "city": "Artvin",
            "plaka_kodu": "08",
            "alan_kodu": "466",
            "nufus": "174.010",
            "bolge": "Karadeniz",
            "yuzolcumu": "7.393",
            "erkek_nufus_yuzde": "50,41%",
            "erkek_nufus": "87.715",
            "kadin_nufus_yuzde": "49,59%",
            "kadin_nufus": "86.295",
            "nufus_yuzdesi_genel": "0,21%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "35.081",
                "erkek_nufus": "17.435",
                "kadin_nufus": "17.646",
                "yuzolcumu": "1.141"
              },
              {
                "district": "Hopa",
                "nufus": "25.775",
                "erkek_nufus": "12.831",
                "kadin_nufus": "12.944",
                "yuzolcumu": "130"
              },
              {
                "district": "Borçka",
                "nufus": "25.500",
                "erkek_nufus": "13.096",
                "kadin_nufus": "12.404",
                "yuzolcumu": "805"
              },
              {
                "district": "Arhavi",
                "nufus": "21.003",
                "erkek_nufus": "10.469",
                "kadin_nufus": "10.534",
                "yuzolcumu": "407"
              },
              {
                "district": "Yusufeli",
                "nufus": "20.794",
                "erkek_nufus": "10.472",
                "kadin_nufus": "10.322",
                "yuzolcumu": "2.261"
              },
              {
                "district": "Şavşat",
                "nufus": "17.606",
                "erkek_nufus": "8.873",
                "kadin_nufus": "8.733",
                "yuzolcumu": "1316"
              },
              {
                "district": "Aksu",
                "nufus": "71.643",
                "erkek_nufus": "36.495",
                "kadin_nufus": "35.148",
                "yuzolcumu": "406"
              },
              {
                "district": "Kumluca",
                "nufus": "68.610",
                "erkek_nufus": "34.659",
                "kadin_nufus": "33.951",
                "yuzolcumu": "1.225"
              },
              {
                "district": "Ardanuç",
                "nufus": "12.056",
                "erkek_nufus": "6.228",
                "kadin_nufus": "5.828",
                "yuzolcumu": "958"
              },
              {
                "district": "Kemalpaşa",
                "nufus": "9.537",
                "erkek_nufus": "4.842",
                "kadin_nufus": "4.695",
                "yuzolcumu": "74"
              },
              {
                "district": "Murgul",
                "nufus": "6.658",
                "erkek_nufus": "3.469",
                "kadin_nufus": "3.189",
                "yuzolcumu": "301"
              }
            ],
            "kisa_bilgi": "Ülkemizin Gürcistan ile sınırında bulunan, Doğu Karadeniz bölümündeki bu yeşil şehir, kültürel olarak rengarenk. Nüfus Hemşinliler, Gürcüler, Lazlar ile Ahıska ve Kıpçak Türklerinden meydana geliyor."
          },
          {
            "city": "Aydın",
            "plaka_kodu": "09",
            "alan_kodu": "256",
            "nufus": "1.097.746",
            "bolge": "Ege",
            "yuzolcumu": "8.116",
            "erkek_nufus_yuzde": "49,75%",
            "erkek_nufus": "546.092",
            "kadin_nufus_yuzde": "50,25%",
            "kadin_nufus": "551.654",
            "nufus_yuzdesi_genel": "1,34%",
            "ilceler": [
              {
                "district": "Efeler",
                "nufus": "289.248",
                "erkek_nufus": "143.459",
                "kadin_nufus": "145.789",
                "yuzolcumu": "624"
              },
              {
                "district": "Nazilli",
                "nufus": "156.748",
                "erkek_nufus": "77.124",
                "kadin_nufus": "79.624",
                "yuzolcumu": "691"
              },
              {
                "district": "Söke",
                "nufus": "120.217",
                "erkek_nufus": "60.225",
                "kadin_nufus": "59.992",
                "yuzolcumu": "1.064"
              },
              {
                "district": "Kuşadası",
                "nufus": "113.580",
                "erkek_nufus": "56.701",
                "kadin_nufus": "56.879",
                "yuzolcumu": "265"
              },
              {
                "district": "Didim",
                "nufus": "85.055",
                "erkek_nufus": "43.293",
                "kadin_nufus": "41.762",
                "yuzolcumu": "424"
              },
              {
                "district": "İncirliova",
                "nufus": "51.526",
                "erkek_nufus": "25.708",
                "kadin_nufus": "25.818",
                "yuzolcumu": "188"
              },
              {
                "district": "Çine",
                "nufus": "49.760",
                "erkek_nufus": "24.686",
                "kadin_nufus": "25.074",
                "yuzolcumu": "820"
              },
              {
                "district": "Germencik",
                "nufus": "43.913",
                "erkek_nufus": "21.761",
                "kadin_nufus": "22.152",
                "yuzolcumu": "394"
              },
              {
                "district": "Bozdoğan",
                "nufus": "33.843",
                "erkek_nufus": "16.964",
                "kadin_nufus": "16.879",
                "yuzolcumu": "859"
              },
              {
                "district": "Köşk",
                "nufus": "27.517",
                "erkek_nufus": "13.930",
                "kadin_nufus": "13.587",
                "yuzolcumu": "187"
              },
              {
                "district": "Kuyucak",
                "nufus": "26.975",
                "erkek_nufus": "13.376",
                "kadin_nufus": "13.599",
                "yuzolcumu": "544"
              },
              {
                "district": "Koçarlı",
                "nufus": "23.716",
                "erkek_nufus": "11.773",
                "kadin_nufus": "11.943",
                "yuzolcumu": "455"
              },
              {
                "district": "Sultanhisar",
                "nufus": "20.533",
                "erkek_nufus": "9.977",
                "kadin_nufus": "10.556",
                "yuzolcumu": "220"
              },
              {
                "district": "Karacasu",
                "nufus": "18.706",
                "erkek_nufus": "9.242",
                "kadin_nufus": "9.464",
                "yuzolcumu": "727"
              },
              {
                "district": "Buharkent",
                "nufus": "12.688",
                "erkek_nufus": "6.279",
                "kadin_nufus": "6.409",
                "yuzolcumu": "124"
              },
              {
                "district": "Yenipazar",
                "nufus": "12.608",
                "erkek_nufus": "6.075",
                "kadin_nufus": "6.533",
                "yuzolcumu": "245"
              },
              {
                "district": "Karpuzlu",
                "nufus": "11.113",
                "erkek_nufus": "5.519",
                "kadin_nufus": "5.594",
                "yuzolcumu": "286"
              }
            ],
            "kisa_bilgi": "Turizm ve tarım sektörlerinde ülkenin önemli illerinden biri olan Aydın, aynı zamanda Türkiye'nin ilk demiryolu kurulan şehridir. Kuzeyinde İzmir, Manisa, doğusunda Denizli, güneyinde Muğla ile çevrili il, çok önemli turistik mekanlara, plajlara, antik kentlere evsahipliği yapar."
          },
          {
            "city": "Balıkesir",
            "plaka_kodu": "10",
            "alan_kodu": "266",
            "nufus": "1.226.575",
            "bolge": "Marmara",
            "yuzolcumu": "14.583",
            "erkek_nufus_yuzde": "49,96%",
            "erkek_nufus": "612.738",
            "kadin_nufus_yuzde": "50,04%",
            "kadin_nufus": "613.837",
            "nufus_yuzdesi_genel": "1,50%",
            "ilceler": [
              {
                "district": "Altıeylül",
                "nufus": "181.209",
                "erkek_nufus": "91.968",
                "kadin_nufus": "89.241",
                "yuzolcumu": "956"
              },
              {
                "district": "Karesi",
                "nufus": "181.013",
                "erkek_nufus": "87.914",
                "kadin_nufus": "93.099",
                "yuzolcumu": "695"
              },
              {
                "district": "Edremit",
                "nufus": "154.487",
                "erkek_nufus": "76.398",
                "kadin_nufus": "78.089",
                "yuzolcumu": "682"
              },
              {
                "district": "Bandırma",
                "nufus": "154.359",
                "erkek_nufus": "77.986",
                "kadin_nufus": "76.373",
                "yuzolcumu": "755"
              },
              {
                "district": "Gönen",
                "nufus": "73.829",
                "erkek_nufus": "36.756",
                "kadin_nufus": "37.073",
                "yuzolcumu": "1.162"
              },
              {
                "district": "Ayvalık",
                "nufus": "71.063",
                "erkek_nufus": "35.063",
                "kadin_nufus": "36.000",
                "yuzolcumu": "305"
              },
              {
                "district": "Burhaniye",
                "nufus": "60.799",
                "erkek_nufus": "31.158",
                "kadin_nufus": "29.641",
                "yuzolcumu": "420"
              },
              {
                "district": "Bigadiç",
                "nufus": "49.887",
                "erkek_nufus": "25.488",
                "kadin_nufus": "24.399",
                "yuzolcumu": "1108"
              },
              {
                "district": "Susurluk",
                "nufus": "39.058",
                "erkek_nufus": "19.295",
                "kadin_nufus": "19.763",
                "yuzolcumu": "652"
              },
              {
                "district": "Dursunbey",
                "nufus": "35.875",
                "erkek_nufus": "17.541",
                "kadin_nufus": "18.334",
                "yuzolcumu": "1.719"
              },
              {
                "district": "Sındırgı",
                "nufus": "33.924",
                "erkek_nufus": "16.343",
                "kadin_nufus": "17.581",
                "yuzolcumu": "1.387"
              },
              {
                "district": "Erdek",
                "nufus": "32.963",
                "erkek_nufus": "16.724",
                "kadin_nufus": "16.239",
                "yuzolcumu": "307"
              },
              {
                "district": "İvrindi",
                "nufus": "32.758",
                "erkek_nufus": "16.341",
                "kadin_nufus": "16.417",
                "yuzolcumu": "818"
              },
              {
                "district": "Havran",
                "nufus": "27.741",
                "erkek_nufus": "13.859",
                "kadin_nufus": "13.882",
                "yuzolcumu": "565"
              },
              {
                "district": "Kepsut",
                "nufus": "23.123",
                "erkek_nufus": "12.495",
                "kadin_nufus": "10.628",
                "yuzolcumu": "889"
              },
              {
                "district": "Manyas",
                "nufus": "19.339",
                "erkek_nufus": "9.554",
                "kadin_nufus": "9.785",
                "yuzolcumu": "634"
              },
              {
                "district": "Savaştepe",
                "nufus": "18.243",
                "erkek_nufus": "9.085",
                "kadin_nufus": "9.158",
                "yuzolcumu": "427"
              },
              {
                "district": "Gömeç",
                "nufus": "13.894",
                "erkek_nufus": "6.952",
                "kadin_nufus": "6.942",
                "yuzolcumu": "171"
              },
              {
                "district": "Balya",
                "nufus": "13.141",
                "erkek_nufus": "6.545",
                "kadin_nufus": "6.596",
                "yuzolcumu": "797"
              },
              {
                "district": "Marmara",
                "nufus": "9.870",
                "erkek_nufus": "5.273",
                "kadin_nufus": "4.597",
                "yuzolcumu": "134"
              }
            ],
            "kisa_bilgi": "Hem Marmara hem de Ege Denizi'ne kıyısı olan, topraklarının büyük kısmı Güney Marmara bölümünde yer alan Balıkesir, Ayvalık, Altınoluk, Avşa Adası, Cunda, Gönen, Edremit, Manyas, Susurluk gibi turistik mekânlara sahiptir."
          },
          {
            "city": "Bilecik",
            "plaka_kodu": "11",
            "alan_kodu": "228",
            "nufus": "223.448",
            "bolge": "Marmara",
            "yuzolcumu": "4.179",
            "erkek_nufus_yuzde": "52,33%",
            "erkek_nufus": "116.923",
            "kadin_nufus_yuzde": "47,67%",
            "kadin_nufus": "106.525",
            "nufus_yuzdesi_genel": "0,27%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "44.722",
                "erkek_nufus": "44.722",
                "kadin_nufus": "37.001",
                "yuzolcumu": "793"
              },
              {
                "district": "Bozüyük",
                "nufus": "74.441",
                "erkek_nufus": "37.581",
                "kadin_nufus": "36.860",
                "yuzolcumu": "860"
              },
              {
                "district": "Osmaneli",
                "nufus": "20.976",
                "erkek_nufus": "10.461",
                "kadin_nufus": "10.515",
                "yuzolcumu": "490"
              },
              {
                "district": "Söğüt",
                "nufus": "19.244",
                "erkek_nufus": "10.267",
                "kadin_nufus": "8.977",
                "yuzolcumu": "523"
              },
              {
                "district": "Gölpazarı",
                "nufus": "10.591",
                "erkek_nufus": "5.786",
                "kadin_nufus": "4.805",
                "yuzolcumu": "670"
              },
              {
                "district": "Pazaryeri",
                "nufus": "10.527",
                "erkek_nufus": "5.166",
                "kadin_nufus": "5.361",
                "yuzolcumu": "324"
              },
              {
                "district": "İnhisar",
                "nufus": "2.981",
                "erkek_nufus": "1.493",
                "kadin_nufus": "1.488",
                "yuzolcumu": "244"
              },
              {
                "district": "Yenipazar",
                "nufus": "2.965",
                "erkek_nufus": "1.447",
                "kadin_nufus": "1.518",
                "yuzolcumu": "273"
              }
            ],
            "kisa_bilgi": "Marmara Bölgesi’nin güneydoğusunda yer alan ve ilk yerleşimin M.Ö. 3 bin yılına dek uzandığı Bilecik, aynı zamanda Osmanlı’nın doğduğu topraklardır. "
          },
          {
            "city": "Bingöl",
            "plaka_kodu": "12",
            "alan_kodu": "426",
            "nufus": "281.205",
            "bolge": "Doğu Anadolu",
            "yuzolcumu": "8.004",
            "erkek_nufus_yuzde": "51,04%",
            "erkek_nufus": "143.524",
            "kadin_nufus_yuzde": "48,96%",
            "kadin_nufus": "137.681",
            "nufus_yuzdesi_genel": "0,34%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "160.165",
                "erkek_nufus": "80.875",
                "kadin_nufus": "79.290",
                "yuzolcumu": "825"
              },
              {
                "district": "Genç",
                "nufus": "38.428",
                "erkek_nufus": "19.923",
                "kadin_nufus": "18.505",
                "yuzolcumu": "1.443"
              },
              {
                "district": "Solhan",
                "nufus": "33.618",
                "erkek_nufus": "16.919",
                "kadin_nufus": "16.699",
                "yuzolcumu": "1.033"
              },
              {
                "district": "Karlıova",
                "nufus": "29.650",
                "erkek_nufus": "14.990",
                "kadin_nufus": "14.660",
                "yuzolcumu": "1.566"
              },
              {
                "district": "Adaklı",
                "nufus": "9.315",
                "erkek_nufus": "4.947",
                "kadin_nufus": "4.368",
                "yuzolcumu": "825"
              },
              {
                "district": "Kiğı",
                "nufus": "5.013",
                "erkek_nufus": "2.911",
                "kadin_nufus": "2.102",
                "yuzolcumu": "483"
              },
              {
                "district": "Yedisu",
                "nufus": "2.877",
                "erkek_nufus": "1.623",
                "kadin_nufus": "1.254",
                "yuzolcumu": "423"
              },
              {
                "district": "Yayladere",
                "nufus": "2.139",
                "erkek_nufus": "1.336",
                "kadin_nufus": "803",
                "yuzolcumu": "417"
              }
            ],
            "kisa_bilgi": "Doğu Anadolu bölgesindeki ilimiz Bingöl, doğal ve tarihi güzellikleriyle yerli ve yabancı turistlerin ilgisini çekecek potansiyele sahip."
          },
          {
            "city": "Bitlis",
            "plaka_kodu": "13",
            "alan_kodu": "434",
            "nufus": "349.396",
            "bolge": "Doğu Anadolu",
            "yuzolcumu": "8.294",
            "erkek_nufus_yuzde": "52,08%",
            "erkek_nufus": "181.970",
            "kadin_nufus_yuzde": "47,92%",
            "kadin_nufus": "167.426",
            "nufus_yuzdesi_genel": "0,38%",
            "ilceler": [
              {
                "district": "Tatvan",
                "nufus": "92.695",
                "erkek_nufus": "48.775",
                "kadin_nufus": "43.920",
                "yuzolcumu": "1.885"
              },
              {
                "district": "Merkez",
                "nufus": "71.501",
                "erkek_nufus": "37.500",
                "kadin_nufus": "34.001",
                "yuzolcumu": "1.064"
              },
              {
                "district": "Güroymak",
                "nufus": "47.742",
                "erkek_nufus": "24.564",
                "kadin_nufus": "23.178",
                "yuzolcumu": "515"
              },
              {
                "district": "Ahlat",
                "nufus": "40.806",
                "erkek_nufus": "21.381",
                "kadin_nufus": "19.425",
                "yuzolcumu": "1.153"
              },
              {
                "district": "Hizan",
                "nufus": "34.186",
                "erkek_nufus": "17.666",
                "kadin_nufus": "16.520",
                "yuzolcumu": "1.021"
              },
              {
                "district": "Mutki",
                "nufus": "16.583",
                "erkek_nufus": "16.583",
                "kadin_nufus": "15.507",
                "yuzolcumu": "1069"
              },
              {
                "district": "Adilcevaz",
                "nufus": "30.376",
                "erkek_nufus": "15.501",
                "kadin_nufus": "14.875",
                "yuzolcumu": "1586"
              }
            ],
            "kisa_bilgi": "Tarihte Urartuların yerleşim yeri olmuş, 1514 yılında Osmanlı egemenliğine giren Bitlis, en çok Tatvan’dan ulaşılabilecek Nemrut Yanardağı ve Nemrut Krater Gölü ile turistlerin ilgisini çekiyor. "
          },
          {
            "city": "Bolu",
            "plaka_kodu": "14",
            "alan_kodu": "374",
            "nufus": "311.810",
            "bolge": "Karadeniz",
            "yuzolcumu": "8.313",
            "erkek_nufus_yuzde": "49,62%",
            "erkek_nufus": "154.711",
            "kadin_nufus_yuzde": "50,38%",
            "kadin_nufus": "157.099",
            "nufus_yuzdesi_genel": "0,38%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "205.525",
                "erkek_nufus": "101.710",
                "kadin_nufus": "103.815",
                "yuzolcumu": "1.616"
              },
              {
                "district": "Gerede",
                "nufus": "34.277",
                "erkek_nufus": "17.023",
                "kadin_nufus": "17.254",
                "yuzolcumu": "1.060"
              },
              {
                "district": "Mudurnu",
                "nufus": "19.327",
                "erkek_nufus": "9.734",
                "kadin_nufus": "9.593",
                "yuzolcumu": "1314"
              },
              {
                "district": "Göynük",
                "nufus": "15.423",
                "erkek_nufus": "7.771",
                "kadin_nufus": "7.652",
                "yuzolcumu": "1.407"
              },
              {
                "district": "Mengen",
                "nufus": "14.355",
                "erkek_nufus": "7.228",
                "kadin_nufus": "7.127",
                "yuzolcumu": "874"
              },
              {
                "district": "Yeniçağa",
                "nufus": "7.294",
                "erkek_nufus": "3.669",
                "kadin_nufus": "3.625",
                "yuzolcumu": "163"
              },
              {
                "district": "Dörtdivan",
                "nufus": "7.139",
                "erkek_nufus": "3.466",
                "kadin_nufus": "3.673",
                "yuzolcumu": "634"
              },
              {
                "district": "Seben",
                "nufus": "5.250",
                "erkek_nufus": "2.504",
                "kadin_nufus": "2.746",
                "yuzolcumu": "683"
              },
              {
                "district": "Kıbrıscık",
                "nufus": "3.220",
                "erkek_nufus": "1.606",
                "kadin_nufus": "1.614",
                "yuzolcumu": "562"
              }
            ],
            "kisa_bilgi": "Batı Karadeniz’de bulunan yemyeşil doğası, tertemiz havası ve su kaynaklarıyla dikkat çeken Bolu’da, Abant Gölü ve Yedigöller Milli Parkı en turistik yerler arasında."
          },
          {
            "city": "Burdur",
            "plaka_kodu": "15",
            "alan_kodu": "248",
            "nufus": "269.926",
            "bolge": "Akdeniz",
            "yuzolcumu": "7.175",
            "erkek_nufus_yuzde": "50,10%",
            "erkek_nufus": "135.240",
            "kadin_nufus_yuzde": "49,90%",
            "kadin_nufus": "134.686",
            "nufus_yuzdesi_genel": "0,33%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "113.077",
                "erkek_nufus": "57.643",
                "kadin_nufus": "55.434",
                "yuzolcumu": "1.567"
              },
              {
                "district": "Bucak",
                "nufus": "64.943",
                "erkek_nufus": "32.279",
                "kadin_nufus": "32.664",
                "yuzolcumu": "1511"
              },
              {
                "district": "Gölhisar",
                "nufus": "22.519",
                "erkek_nufus": "11.224",
                "kadin_nufus": "11.295",
                "yuzolcumu": "494"
              },
              {
                "district": "Yeşilova",
                "nufus": "15.457",
                "erkek_nufus": "7.459",
                "kadin_nufus": "7.998",
                "yuzolcumu": "1108"
              },
              {
                "district": "Çavdır",
                "nufus": "13.077",
                "erkek_nufus": "6.480",
                "kadin_nufus": "6.597",
                "yuzolcumu": "482"
              },
              {
                "district": "Tefenni",
                "nufus": "10.201",
                "erkek_nufus": "5.008",
                "kadin_nufus": "5.193",
                "yuzolcumu": "582"
              },
              {
                "district": "Ağlasun",
                "nufus": "8.537",
                "erkek_nufus": "4.278",
                "kadin_nufus": "4.259",
                "yuzolcumu": "305"
              },
              {
                "district": "Karamanlı",
                "nufus": "8.006",
                "erkek_nufus": "3.933",
                "kadin_nufus": "4.073",
                "yuzolcumu": "372"
              },
              {
                "district": "Altınyayla",
                "nufus": "5.591",
                "erkek_nufus": "2.827",
                "kadin_nufus": "2.764",
                "yuzolcumu": "221"
              },
              {
                "district": "Çeltikçi",
                "nufus": "5.237",
                "erkek_nufus": "2.511",
                "kadin_nufus": "2.726",
                "yuzolcumu": "161"
              },
              {
                "district": "Kemer",
                "nufus": "3.281",
                "erkek_nufus": "1.598",
                "kadin_nufus": "1.683",
                "yuzolcumu": "373"
              }
            ],
            "kisa_bilgi": "Akdeniz Bölgesinde, Orta ve Batı Anadolu’yu Akdeniz’e bağlayan yolların kavşağında yer alan Burdur, bu özelliğiyle tarih boyunca çeşitli uygarlıkların da kavşağı konumunda olmuştur. "
          },
          {
            "city": "Bursa",
            "plaka_kodu": "16",
            "alan_kodu": "224",
            "nufus": "2.994.521",
            "bolge": "Marmara",
            "yuzolcumu": "10.813",
            "erkek_nufus_yuzde": "50,03%",
            "erkek_nufus": "1.498.219",
            "kadin_nufus_yuzde": "49,97%",
            "kadin_nufus": "1.496.302",
            "nufus_yuzdesi_genel": "3,65%",
            "ilceler": [
              {
                "district": "Osmangazi",
                "nufus": "862.516",
                "erkek_nufus": "432.431",
                "kadin_nufus": "430.085",
                "yuzolcumu": "621"
              },
              {
                "district": "Yıldırım",
                "nufus": "653.004",
                "erkek_nufus": "328.585",
                "kadin_nufus": "324.419",
                "yuzolcumu": "110"
              },
              {
                "district": "Nilüfer",
                "nufus": "441.299",
                "erkek_nufus": "218.848",
                "kadin_nufus": "222.451",
                "yuzolcumu": "552"
              },
              {
                "district": "İnegöl",
                "nufus": "268.155",
                "erkek_nufus": "134.942",
                "kadin_nufus": "133.213",
                "yuzolcumu": "1.118"
              },
              {
                "district": "Gemlik",
                "nufus": "111.488",
                "erkek_nufus": "56.295",
                "kadin_nufus": "55.193",
                "yuzolcumu": "401"
              },
              {
                "district": "Mustafakemalpa",
                "nufus": "100.696",
                "erkek_nufus": "50.061",
                "kadin_nufus": "50.635",
                "yuzolcumu": "1.641"
              },
              {
                "district": "Mudanya",
                "nufus": "93.707",
                "erkek_nufus": "45.724",
                "kadin_nufus": "47.983",
                "yuzolcumu": "369"
              },
              {
                "district": "Gürsu",
                "nufus": "91.339",
                "erkek_nufus": "46.065",
                "kadin_nufus": "45.274",
                "yuzolcumu": "106"
              },
              {
                "district": "Karacabey",
                "nufus": "83.115",
                "erkek_nufus": "41.596",
                "kadin_nufus": "41.519",
                "yuzolcumu": "1.158"
              },
              {
                "district": "Orhangazi",
                "nufus": "78.447",
                "erkek_nufus": "39.339",
                "kadin_nufus": "39.108",
                "yuzolcumu": "506"
              },
              {
                "district": "Kestel",
                "nufus": "65.256",
                "erkek_nufus": "32.568",
                "kadin_nufus": "32.688",
                "yuzolcumu": "396"
              },
              {
                "district": "Yenişehir",
                "nufus": "53.704",
                "erkek_nufus": "26.594",
                "kadin_nufus": "27.110",
                "yuzolcumu": "720"
              },
              {
                "district": "İznik",
                "nufus": "43.330",
                "erkek_nufus": "21.438",
                "kadin_nufus": "21.892",
                "yuzolcumu": "753"
              },
              {
                "district": "Orhaneli",
                "nufus": "19.492",
                "erkek_nufus": "9.521",
                "kadin_nufus": "9.971",
                "yuzolcumu": "838"
              },
              {
                "district": "Keles",
                "nufus": "12.355",
                "erkek_nufus": "6.082",
                "kadin_nufus": "6.273",
                "yuzolcumu": "617"
              },
              {
                "district": "Büyükorhan",
                "nufus": "10.042",
                "erkek_nufus": "4.935",
                "kadin_nufus": "5.107",
                "yuzolcumu": "505"
              },
              {
                "district": "Harmancık",
                "nufus": "6.576",
                "erkek_nufus": "3.195",
                "kadin_nufus": "3.381",
                "yuzolcumu": "400"
              }
            ],
            "kisa_bilgi": "Akdeniz Bölgesinde, Orta ve Batı Anadolu’yu Akdeniz’e bağlayan yolların kavşağında yer alan Burdur, bu özelliğiyle tarih boyunca çeşitli uygarlıkların da kavşağı konumunda olmuştur. "
          },
          {
            "city": "Çanakkale",
            "plaka_kodu": "17",
            "alan_kodu": "286",
            "nufus": "540.662",
            "bolge": "Ege",
            "yuzolcumu": "9.817",
            "erkek_nufus_yuzde": "50,51%",
            "erkek_nufus": "273.092",
            "kadin_nufus_yuzde": "49,49%",
            "kadin_nufus": "267.570",
            "nufus_yuzdesi_genel": "0,66%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "180.823",
                "erkek_nufus": "90.261",
                "kadin_nufus": "90.562",
                "yuzolcumu": "1.016"
              },
              {
                "district": "Biga",
                "nufus": "90.576",
                "erkek_nufus": "45.235",
                "kadin_nufus": "45.341",
                "yuzolcumu": "1.310"
              },
              {
                "district": "Çan",
                "nufus": "48.215",
                "erkek_nufus": "24.198",
                "kadin_nufus": "24.017",
                "yuzolcumu": "905"
              },
              {
                "district": "Gelibolu",
                "nufus": "44.809",
                "erkek_nufus": "24.176",
                "kadin_nufus": "20.633",
                "yuzolcumu": "823"
              },
              {
                "district": "Ayvacık",
                "nufus": "33.568",
                "erkek_nufus": "16.789",
                "kadin_nufus": "16.779",
                "yuzolcumu": "880"
              },
              {
                "district": "Ezine",
                "nufus": "32.003",
                "erkek_nufus": "16.368",
                "kadin_nufus": "15.635",
                "yuzolcumu": "727"
              },
              {
                "district": "Yenice",
                "nufus": "31.907",
                "erkek_nufus": "15.775",
                "kadin_nufus": "16.132",
                "yuzolcumu": "1.381"
              },
              {
                "district": "Bayramiç",
                "nufus": "29.716",
                "erkek_nufus": "14.718",
                "kadin_nufus": "14.998",
                "yuzolcumu": "1.204"
              },
              {
                "district": "Lapseki",
                "nufus": "27.327",
                "erkek_nufus": "13.658",
                "kadin_nufus": "13.669",
                "yuzolcumu": "821"
              },
              {
                "district": "Gökçeada",
                "nufus": "9.783",
                "erkek_nufus": "5.701",
                "kadin_nufus": "4.082",
                "yuzolcumu": "282"
              },
              {
                "district": "Eceabat",
                "nufus": "8.912",
                "erkek_nufus": "4.528",
                "kadin_nufus": "4.384",
                "yuzolcumu": "430"
              },
              {
                "district": "Bozcaada",
                "nufus": "3.023",
                "erkek_nufus": "1.685",
                "kadin_nufus": "1.338",
                "yuzolcumu": "37"
              }
            ],
            "kisa_bilgi": "Kuzeybatı Anadolu’da, Troya kalıntılarının yer aldığı, binlerce yıllık tarihi mirası, doğal güzellikleriyle en güzel şehirlerimizden biri olan Çanakkale’de, 1963 yılından bu yana her ağustos ayında Uluslararası Troia Festivali düzenleniyor. Truva filminin setinde kullanılan ve şehre hediye edilen Truva Atı, merkezde görülebilir."
          },
          {
            "city": "Çankırı",
            "plaka_kodu": "18",
            "alan_kodu": "376",
            "nufus": "216.362",
            "bolge": "İç Anadolu",
            "yuzolcumu": "7.542",
            "erkek_nufus_yuzde": "50,62%",
            "erkek_nufus": "109.512",
            "kadin_nufus_yuzde": "49,38%",
            "kadin_nufus": "106.850",
            "nufus_yuzdesi_genel": "0,26%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "96.025",
                "erkek_nufus": "48.571",
                "kadin_nufus": "47.454",
                "yuzolcumu": "1.440"
              },
              {
                "district": "Orta",
                "nufus": "20.439",
                "erkek_nufus": "10.570",
                "kadin_nufus": "9.869",
                "yuzolcumu": "706"
              },
              {
                "district": "Çerkeş",
                "nufus": "18.694",
                "erkek_nufus": "9.504",
                "kadin_nufus": "9.190",
                "yuzolcumu": "947"
              },
              {
                "district": "Ilgaz",
                "nufus": "14.891",
                "erkek_nufus": "7.433",
                "kadin_nufus": "7.458",
                "yuzolcumu": "845"
              },
              {
                "district": "Şabanözü",
                "nufus": "14.080",
                "erkek_nufus": "7.122",
                "kadin_nufus": "6.958",
                "yuzolcumu": "467"
              },
              {
                "district": "Kurşunlu",
                "nufus": "10.307",
                "erkek_nufus": "5.305",
                "kadin_nufus": "5.002",
                "yuzolcumu": "609"
              },
              {
                "district": "Yapraklı",
                "nufus": "8.981",
                "erkek_nufus": "4.358",
                "kadin_nufus": "4.623",
                "yuzolcumu": "741"
              },
              {
                "district": "Kızılırmak",
                "nufus": "8.220",
                "erkek_nufus": "4.077",
                "kadin_nufus": "4.143",
                "yuzolcumu": "514"
              },
              {
                "district": "Eldivan",
                "nufus": "7.588",
                "erkek_nufus": "3.792",
                "kadin_nufus": "3.796",
                "yuzolcumu": "350"
              },
              {
                "district": "Atkaracalar",
                "nufus": "7.213",
                "erkek_nufus": "3.730",
                "kadin_nufus": "3.483",
                "yuzolcumu": "234"
              },
              {
                "district": "Korgun",
                "nufus": "5.666",
                "erkek_nufus": "2.898",
                "kadin_nufus": "2.768",
                "yuzolcumu": "378"
              },
              {
                "district": "Bayramören",
                "nufus": "4.258",
                "erkek_nufus": "2.152",
                "kadin_nufus": "2.106",
                "yuzolcumu": "310"
              }
            ],
            "kisa_bilgi": "Tarihi M.Ö. 5 bin yılına dek uzanan Çankırı, İç Anadolu’nun kuzey ucunda bulunuyor."
          },
          {
            "city": "Çorum",
            "plaka_kodu": "19",
            "alan_kodu": "364",
            "nufus": "536.483",
            "bolge": "Karadeniz",
            "yuzolcumu": "12.428",
            "erkek_nufus_yuzde": "49,52%",
            "erkek_nufus": "265.678",
            "kadin_nufus_yuzde": "50,48%",
            "kadin_nufus": "270.805",
            "nufus_yuzdesi_genel": "0,26%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "294.807",
                "erkek_nufus": "146.299",
                "kadin_nufus": "148.508",
                "yuzolcumu": "2.436"
              },
              {
                "district": "Sungurlu",
                "nufus": "49.082",
                "erkek_nufus": "24.192",
                "kadin_nufus": "24.890",
                "yuzolcumu": "1.999"
              },
              {
                "district": "Osmancık",
                "nufus": "42.980",
                "erkek_nufus": "21.227",
                "kadin_nufus": "21.753",
                "yuzolcumu": "1.273"
              },
              {
                "district": "İskilip",
                "nufus": "32.546",
                "erkek_nufus": "16.068",
                "kadin_nufus": "16.478",
                "yuzolcumu": "1.170"
              },
              {
                "district": "Alaca",
                "nufus": "31.460",
                "erkek_nufus": "15.669",
                "kadin_nufus": "15.791",
                "yuzolcumu": "1.296"
              },
              {
                "district": "Bayat",
                "nufus": "16.525",
                "erkek_nufus": "7.933",
                "kadin_nufus": "8.592",
                "yuzolcumu": "717"
              },
              {
                "district": "Kargı",
                "nufus": "15.684",
                "erkek_nufus": "7.573",
                "kadin_nufus": "8.111",
                "yuzolcumu": "1.174"
              },
              {
                "district": "Mecitözü",
                "nufus": "15.655",
                "erkek_nufus": "7.840",
                "kadin_nufus": "7.815",
                "yuzolcumu": "825"
              },
              {
                "district": "Ortaköy",
                "nufus": "8.696",
                "erkek_nufus": "4.374",
                "kadin_nufus": "4.322",
                "yuzolcumu": "300"
              },
              {
                "district": "Uğurludağ",
                "nufus": "8.252",
                "erkek_nufus": "4.228",
                "kadin_nufus": "4.024",
                "yuzolcumu": "428"
              },
              {
                "district": "Dodurga",
                "nufus": "6.368",
                "erkek_nufus": "3.093",
                "kadin_nufus": "3.275",
                "yuzolcumu": "229"
              },
              {
                "district": "Oğuzlar",
                "nufus": "5.483",
                "erkek_nufus": "2.660",
                "kadin_nufus": "2.823",
                "yuzolcumu": "121"
              },
              {
                "district": "Laçin",
                "nufus": "4.770",
                "erkek_nufus": "2.429",
                "kadin_nufus": "2.341",
                "yuzolcumu": "196"
              },
              {
                "district": "Boğazkale",
                "nufus": "4.175",
                "erkek_nufus": "2.093",
                "kadin_nufus": "2.082",
                "yuzolcumu": "264"
              }
            ],
            "kisa_bilgi": "Pek çok medeniyete ev sahipliği yapmış, M.Ö. 4000 yılına dek uzanan yerleşim izlerine rastlanan, İç Orta Karadeniz bölgesinde, leblebisiyle meşhur bu ilimiz, ova ve akarsu zenginliğiyle dikkat çekiyor."
          },
          {
            "city": "Denizli",
            "plaka_kodu": "20",
            "alan_kodu": "258",
            "nufus": "1.027.782",
            "bolge": "Ege",
            "yuzolcumu": "12.134",
            "erkek_nufus_yuzde": "49,83%",
            "erkek_nufus": "512.109",
            "kadin_nufus_yuzde": "50,17%",
            "kadin_nufus": "515.673",
            "nufus_yuzdesi_genel": "1,25%",
            "ilceler": [
              {
                "district": "Pamukkale",
                "nufus": "344.065",
                "erkek_nufus": "171.568",
                "kadin_nufus": "172.497",
                "yuzolcumu": "823"
              },
              {
                "district": "Merkezefendi",
                "nufus": "302.213",
                "erkek_nufus": "149.611",
                "kadin_nufus": "152.602",
                "yuzolcumu": "336"
              },
              {
                "district": "Çivril",
                "nufus": "60.429",
                "erkek_nufus": "29.896",
                "kadin_nufus": "30.533",
                "yuzolcumu": "1.570"
              },
              {
                "district": "Acıpayam",
                "nufus": "55.648",
                "erkek_nufus": "27.934",
                "kadin_nufus": "27.714",
                "yuzolcumu": "1.772"
              },
              {
                "district": "Tavas",
                "nufus": "43.845",
                "erkek_nufus": "21.674",
                "kadin_nufus": "22.171",
                "yuzolcumu": "1.432"
              },
              {
                "district": "Honaz",
                "nufus": "33.184",
                "erkek_nufus": "18.228",
                "kadin_nufus": "14.956",
                "yuzolcumu": "449"
              },
              {
                "district": "Sarayköy",
                "nufus": "30.768",
                "erkek_nufus": "15.518",
                "kadin_nufus": "15.250",
                "yuzolcumu": "15.250"
              },
              {
                "district": "Buldan",
                "nufus": "27.241",
                "erkek_nufus": "13.264",
                "kadin_nufus": "13.977",
                "yuzolcumu": "523"
              },
              {
                "district": "Kale",
                "nufus": "20.393",
                "erkek_nufus": "10.168",
                "kadin_nufus": "10.225",
                "yuzolcumu": "684"
              },
              {
                "district": "Çal",
                "nufus": "19.259",
                "erkek_nufus": "9.485",
                "kadin_nufus": "9.774",
                "yuzolcumu": "860"
              },
              {
                "district": "Çameli",
                "nufus": "18.256",
                "erkek_nufus": "9.190",
                "kadin_nufus": "9.066",
                "yuzolcumu": "758"
              },
              {
                "district": "Serinhisar",
                "nufus": "14.430",
                "erkek_nufus": "7.156",
                "kadin_nufus": "7.274",
                "yuzolcumu": "256"
              },
              {
                "district": "Bozkurt",
                "nufus": "12.788",
                "erkek_nufus": "5.719",
                "kadin_nufus": "7.069",
                "yuzolcumu": "462"
              },
              {
                "district": "Güney",
                "nufus": "9.975",
                "erkek_nufus": "4.940",
                "kadin_nufus": "5.035",
                "yuzolcumu": ""
              },
              {
                "district": "Çardak",
                "nufus": "9.144",
                "erkek_nufus": "4.715",
                "kadin_nufus": "4.429",
                "yuzolcumu": "423"
              },
              {
                "district": "Bekilli",
                "nufus": "7.065",
                "erkek_nufus": "3.349",
                "kadin_nufus": "3.716",
                "yuzolcumu": "304"
              },
              {
                "district": "Beyağaç",
                "nufus": "6.903",
                "erkek_nufus": "3.536",
                "kadin_nufus": "3.367",
                "yuzolcumu": "333"
              },
              {
                "district": "Babadağ",
                "nufus": "6.522",
                "erkek_nufus": "3.358",
                "kadin_nufus": "3.164",
                "yuzolcumu": "124"
              },
              {
                "district": "Baklan",
                "nufus": "5.654",
                "erkek_nufus": "2.800",
                "kadin_nufus": "2.854",
                "yuzolcumu": "283"
              }
            ],
            "kisa_bilgi": "Denizli ili sanayisi ve turizmi ile Türkiyenin önemli şehirlerindendir. Tekstilin başkentidir. İhracatta ülke ekonomisine önemli katkılar sağlamıştır. İhracatta Türkiye 7. sidir. Türkiye de gelen turist sayısı olarak 5. il konumundadır."
          },
          {
            "city": "Diyarbakır",
            "plaka_kodu": "21",
            "alan_kodu": "412",
            "nufus": "1.732.396",
            "bolge": "Güneydoğu Anadolu",
            "yuzolcumu": "15.168",
            "erkek_nufus_yuzde": "50,54%",
            "erkek_nufus": "875.468",
            "kadin_nufus_yuzde": "49,46%",
            "kadin_nufus": "856.928",
            "nufus_yuzdesi_genel": "2,11%",
            "ilceler": [
              {
                "district": "Bağlar",
                "nufus": "388.387",
                "erkek_nufus": "195.143",
                "kadin_nufus": "193.244",
                "yuzolcumu": "429"
              },
              {
                "district": "Kayapınar",
                "nufus": "362.407",
                "erkek_nufus": "185.405",
                "kadin_nufus": "177.002",
                "yuzolcumu": "480"
              },
              {
                "district": "Yenişehir",
                "nufus": "206.793",
                "erkek_nufus": "104.564",
                "kadin_nufus": "102.229",
                "yuzolcumu": "358"
              },
              {
                "district": "Ergani",
                "nufus": "130.105",
                "erkek_nufus": "66.003",
                "kadin_nufus": "64.102",
                "yuzolcumu": "1.510"
              },
              {
                "district": "Bismil",
                "nufus": "117.674",
                "erkek_nufus": "58.693",
                "kadin_nufus": "58.981",
                "yuzolcumu": "1.679"
              },
              {
                "district": "Sur",
                "nufus": "112.306",
                "erkek_nufus": "55.871",
                "kadin_nufus": "56.435",
                "yuzolcumu": "1.227"
              },
              {
                "district": "Silvan",
                "nufus": "86.672",
                "erkek_nufus": "43.823",
                "kadin_nufus": "42.849",
                "yuzolcumu": "1.252"
              },
              {
                "district": "Çınar",
                "nufus": "74.207",
                "erkek_nufus": "37.188",
                "kadin_nufus": "37.019",
                "yuzolcumu": "1.934"
              },
              {
                "district": "Çermik",
                "nufus": "50.812",
                "erkek_nufus": "25.580",
                "kadin_nufus": "25.232",
                "yuzolcumu": "948"
              },
              {
                "district": "Dicle",
                "nufus": "38.220",
                "erkek_nufus": "19.263",
                "kadin_nufus": "18.957",
                "yuzolcumu": "738"
              },
              {
                "district": "Kulp",
                "nufus": "36.640",
                "erkek_nufus": "18.465",
                "kadin_nufus": "18.175",
                "yuzolcumu": "1.560"
              },
              {
                "district": "Hani",
                "nufus": "33.100",
                "erkek_nufus": "16.877",
                "kadin_nufus": "16.223",
                "yuzolcumu": "436"
              },
              {
                "district": "Lice",
                "nufus": "26.163",
                "erkek_nufus": "13.755",
                "kadin_nufus": "12.408",
                "yuzolcumu": "982"
              },
              {
                "district": "Eğil",
                "nufus": "23.369",
                "erkek_nufus": "11.714",
                "kadin_nufus": "11.655",
                "yuzolcumu": "449"
              },
              {
                "district": "Hazro",
                "nufus": "16.935",
                "erkek_nufus": "8.593",
                "kadin_nufus": "8.342",
                "yuzolcumu": "426"
              },
              {
                "district": "Kocaköy",
                "nufus": "16.679",
                "erkek_nufus": "8.530",
                "kadin_nufus": "8.149",
                "yuzolcumu": "248"
              },
              {
                "district": "Çüngüş",
                "nufus": "11.927",
                "erkek_nufus": "6.001",
                "kadin_nufus": "5.926",
                "yuzolcumu": "512"
              }
            ],
            "kisa_bilgi": "Tarihi surları, Ulucamisi, Dört Ayaklı Minaresi, binlerce yıllık geçmişiyle ülkemizin en güzel şehirlerinden biri olan Diyarbakır, Mezopotamya ile Anadolu medeniyetlerinin geçiş bölgesinde yer alır."
          },
          {
            "city": "Edirne",
            "plaka_kodu": "22",
            "alan_kodu": "284",
            "nufus": "411.528",
            "bolge": "Marmara",
            "yuzolcumu": "6.145",
            "erkek_nufus_yuzde": "50,88%",
            "erkek_nufus": "209.390",
            "kadin_nufus_yuzde": "49,12%",
            "kadin_nufus": "202.138",
            "nufus_yuzdesi_genel": "0,50%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "180.327",
                "erkek_nufus": "90.608",
                "kadin_nufus": "89.719",
                "yuzolcumu": "844"
              },
              {
                "district": "Keşan",
                "nufus": "84.442",
                "erkek_nufus": "43.496",
                "kadin_nufus": "40.946",
                "yuzolcumu": "1.098"
              },
              {
                "district": "Uzunköprü",
                "nufus": "61.485",
                "erkek_nufus": "30.859",
                "kadin_nufus": "30.626",
                "yuzolcumu": "1.185"
              },
              {
                "district": "İpsala",
                "nufus": "27.498",
                "erkek_nufus": "14.404",
                "kadin_nufus": "13.094",
                "yuzolcumu": "741"
              },
              {
                "district": "Havsa",
                "nufus": "18.947",
                "erkek_nufus": "9.534",
                "kadin_nufus": "9.413",
                "yuzolcumu": "545"
              },
              {
                "district": "Meriç",
                "nufus": "14.201",
                "erkek_nufus": "7.289",
                "kadin_nufus": "6.912",
                "yuzolcumu": "438"
              },
              {
                "district": "Enez",
                "nufus": "10.886",
                "erkek_nufus": "5.525",
                "kadin_nufus": "5.361",
                "yuzolcumu": "455"
              },
              {
                "district": "Süloğlu",
                "nufus": "7.062",
                "erkek_nufus": "4.114",
                "kadin_nufus": "2.948",
                "yuzolcumu": "342"
              },
              {
                "district": "Lalapaşa",
                "nufus": "6.680",
                "erkek_nufus": "3.561",
                "kadin_nufus": "3.119",
                "yuzolcumu": "497"
              }
            ],
            "kisa_bilgi": "Bademli kavala kurabiyesi, enfes badem ezmesi, meyve sabunları ve peyniri ile meşhur, Trak uygarlığına ilk kez ev sahipliği yaptığı düşünülen Edirne, 1365 yılında Osmanlı’nın başkenti olmuştur."
          },
          {
            "city": "Elazığ",
            "plaka_kodu": "23",
            "alan_kodu": "424",
            "nufus": "595.638",
            "bolge": "Doğu Anadolu",
            "yuzolcumu": "9.383",
            "erkek_nufus_yuzde": "49,80%",
            "erkek_nufus": "296.641",
            "kadin_nufus_yuzde": "50,20%",
            "kadin_nufus": "298.997",
            "nufus_yuzdesi_genel": "0,73%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "421.726",
                "erkek_nufus": "208.927",
                "kadin_nufus": "212.799",
                "yuzolcumu": "2.243"
              },
              {
                "district": "Kovancılar",
                "nufus": "38.774",
                "erkek_nufus": "19.327",
                "kadin_nufus": "19.447",
                "yuzolcumu": "960"
              },
              {
                "district": "Karakoçan",
                "nufus": "28.702",
                "erkek_nufus": "14.429",
                "kadin_nufus": "14.273",
                "yuzolcumu": "1.049"
              },
              {
                "district": "Palu",
                "nufus": "23.834",
                "erkek_nufus": "12.191",
                "kadin_nufus": "11.643",
                "yuzolcumu": "730"
              },
              {
                "district": "Baskil",
                "nufus": "20.505",
                "erkek_nufus": "10.350",
                "kadin_nufus": "10.155",
                "yuzolcumu": "1.318"
              },
              {
                "district": "Arıcak",
                "nufus": "17.028",
                "erkek_nufus": "8.826",
                "kadin_nufus": "8.202",
                "yuzolcumu": "354"
              },
              {
                "district": "Maden",
                "nufus": "16.608",
                "erkek_nufus": "8.334",
                "kadin_nufus": "8.274",
                "yuzolcumu": "819"
              },
              {
                "district": "Sivrice",
                "nufus": "10.710",
                "erkek_nufus": "5.311",
                "kadin_nufus": "5.399",
                "yuzolcumu": "710"
              },
              {
                "district": "Keban",
                "nufus": "8.409",
                "erkek_nufus": "4.144",
                "kadin_nufus": "4.265",
                "yuzolcumu": "641"
              },
              {
                "district": "Alacakaya",
                "nufus": "6.566",
                "erkek_nufus": "3.438",
                "kadin_nufus": "3.128",
                "yuzolcumu": "318"
              },
              {
                "district": "Ağın",
                "nufus": "2.776",
                "erkek_nufus": "1.364",
                "kadin_nufus": "1.412",
                "yuzolcumu": "242"
              }
            ],
            "kisa_bilgi": "Doğu Anadolu Bölgesi'nin Yukarı Fırat Havzasında bulunan, ülkemizin önemli barajlarından biri olan Keban Barajı ile Hazar Gölü’nün ve doğa harikası Buzluk Mağarası’nın bulunduğu Elazığ, Harput’un devamı niteliğindedir."
          },
          {
            "city": "Erzincan",
            "plaka_kodu": "24",
            "alan_kodu": "446",
            "nufus": "236.034",
            "bolge": "Doğu Anadolu",
            "yuzolcumu": "11.815",
            "erkek_nufus_yuzde": "50,99%",
            "erkek_nufus": "120.348",
            "kadin_nufus_yuzde": "49,01%",
            "kadin_nufus": "115.686",
            "nufus_yuzdesi_genel": "0,29%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "157.452",
                "erkek_nufus": "79.858",
                "kadin_nufus": "77.594",
                "yuzolcumu": "1.521"
              },
              {
                "district": "Tercan",
                "nufus": "17.623",
                "erkek_nufus": "9.144",
                "kadin_nufus": "8.479",
                "yuzolcumu": "1614"
              },
              {
                "district": "Üzümlü",
                "nufus": "14.390",
                "erkek_nufus": "7.198",
                "kadin_nufus": "7.192",
                "yuzolcumu": "591"
              },
              {
                "district": "Refahiye",
                "nufus": "12.456",
                "erkek_nufus": "6.655",
                "kadin_nufus": "5.801",
                "yuzolcumu": "1808"
              },
              {
                "district": "Çayırlı",
                "nufus": "9.032",
                "erkek_nufus": "4.662",
                "kadin_nufus": "4.370",
                "yuzolcumu": "1062"
              },
              {
                "district": "İliç",
                "nufus": "8.922",
                "erkek_nufus": "4.643",
                "kadin_nufus": "4.279",
                "yuzolcumu": "1382"
              },
              {
                "district": "Kemah",
                "nufus": "8.167",
                "erkek_nufus": "4.038",
                "kadin_nufus": "4.129",
                "yuzolcumu": "2311"
              },
              {
                "district": "Kemaliye",
                "nufus": "5.555",
                "erkek_nufus": "2.884",
                "kadin_nufus": "2.671",
                "yuzolcumu": "1207"
              },
              {
                "district": "Otlukbeli",
                "nufus": "2.437",
                "erkek_nufus": "1.266",
                "kadin_nufus": "1.171",
                "yuzolcumu": "320"
              }
            ],
            "kisa_bilgi": "Tarihi İpek Yolu üzerinde yer alan ve ülkemizin Doğu Anadolu Yukarı Fırat bölümünde bulunan Erzincan, tarih boyunca farklı medeniyetlere ev sahipliği yapan önemli bir yer olmuştur. Altıntepe’de yer alan Urartu kalıntıları şehrin tarihi hakkında fikir verecektir."
          },
          {
            "city": "Erzurum",
            "plaka_kodu": "25",
            "alan_kodu": "42",
            "nufus": "767.848",
            "bolge": "Doğu Anadolu",
            "yuzolcumu": "25.006",
            "erkek_nufus_yuzde": "49,94%",
            "erkek_nufus": "383.435",
            "kadin_nufus_yuzde": "50,06%",
            "kadin_nufus": "384.413",
            "nufus_yuzdesi_genel": "0,94%",
            "ilceler": [
              {
                "district": "Yakutiye",
                "nufus": "191.224",
                "erkek_nufus": "93.029",
                "kadin_nufus": "98.195",
                "yuzolcumu": "945"
              },
              {
                "district": "Palandöken",
                "nufus": "168.651",
                "erkek_nufus": "83.050",
                "kadin_nufus": "85.601",
                "yuzolcumu": "667"
              },
              {
                "district": "Aziziye",
                "nufus": "62.289",
                "erkek_nufus": "32.078",
                "kadin_nufus": "30.211",
                "yuzolcumu": "1.529"
              },
              {
                "district": "Horasan",
                "nufus": "39.445",
                "erkek_nufus": "20.090",
                "kadin_nufus": "19.355",
                "yuzolcumu": "1.740"
              },
              {
                "district": "Oltu",
                "nufus": "30.966",
                "erkek_nufus": "15.740",
                "kadin_nufus": "15.226",
                "yuzolcumu": "1441"
              },
              {
                "district": "Pasinler",
                "nufus": "28.961",
                "erkek_nufus": "14.713",
                "kadin_nufus": "14.248",
                "yuzolcumu": "1.134"
              },
              {
                "district": "Karayazı",
                "nufus": "28.502",
                "erkek_nufus": "14.576",
                "kadin_nufus": "13.926",
                "yuzolcumu": "1.953"
              },
              {
                "district": "Hınıs",
                "nufus": "26.865",
                "erkek_nufus": "13.768",
                "kadin_nufus": "13.097",
                "yuzolcumu": "1.367"
              },
              {
                "district": "Tekman",
                "nufus": "25.969",
                "erkek_nufus": "13.142",
                "kadin_nufus": "12.827",
                "yuzolcumu": "2.102"
              },
              {
                "district": "Aşkale",
                "nufus": "23.589",
                "erkek_nufus": "11.799",
                "kadin_nufus": "11.790",
                "yuzolcumu": "1507"
              },
              {
                "district": "Karaçoban",
                "nufus": "23.246",
                "erkek_nufus": "11.816",
                "kadin_nufus": "11.430",
                "yuzolcumu": "571"
              },
              {
                "district": "Şenkaya",
                "nufus": "18.281",
                "erkek_nufus": "9.228",
                "kadin_nufus": "9.053",
                "yuzolcumu": "1.381"
              },
              {
                "district": "Çat",
                "nufus": "17.389",
                "erkek_nufus": "9.021",
                "kadin_nufus": "8.368",
                "yuzolcumu": "1.448"
              },
              {
                "district": "Tortum",
                "nufus": "17.054",
                "erkek_nufus": "8.537",
                "kadin_nufus": "8.517",
                "yuzolcumu": "1.463"
              },
              {
                "district": "Köprüköy",
                "nufus": "16.178",
                "erkek_nufus": "8.313",
                "kadin_nufus": "7.865",
                "yuzolcumu": "777"
              },
              {
                "district": "İspir",
                "nufus": "15.898",
                "erkek_nufus": "7.830",
                "kadin_nufus": "8.068",
                "yuzolcumu": "2.129"
              },
              {
                "district": "Narman",
                "nufus": "13.381",
                "erkek_nufus": "6.718",
                "kadin_nufus": "6.663",
                "yuzolcumu": "799"
              },
              {
                "district": "Uzundere",
                "nufus": "8.744",
                "erkek_nufus": "4.404",
                "kadin_nufus": "4.340",
                "yuzolcumu": "505"
              },
              {
                "district": "Olur",
                "nufus": "6.715",
                "erkek_nufus": "3.342",
                "kadin_nufus": "3.373",
                "yuzolcumu": "893"
              },
              {
                "district": "Pazaryolu",
                "nufus": "4.501",
                "erkek_nufus": "2.241",
                "kadin_nufus": "2.260",
                "yuzolcumu": "654"
              }
            ],
            "kisa_bilgi": "23 Temmuz-7 Ağustos 1919 tarihleri arasında gerçekleştirilen ve Kurtuluş Mücadelesi'nde önemli ölçüde belirleyici kararların alındığı Erzurum Kongresi’ne ev sahipliği yapan ilin tarihimizde önemli bir yeri var. "
          },
          {
            "city": "Eskişehir",
            "plaka_kodu": "26",
            "alan_kodu": "222",
            "nufus": "871.187",
            "bolge": "İç Anadolu",
            "yuzolcumu": "13.960",
            "erkek_nufus_yuzde": "49,83%",
            "erkek_nufus": "434.112",
            "kadin_nufus_yuzde": "50,17%",
            "kadin_nufus": "437.075",
            "nufus_yuzdesi_genel": "1,06%",
            "ilceler": [
              {
                "district": "Odunpazarı",
                "nufus": "404.267",
                "erkek_nufus": "198.648",
                "kadin_nufus": "205.619",
                "yuzolcumu": "1.120"
              },
              {
                "district": "Tepebaşı",
                "nufus": "359.303",
                "erkek_nufus": "180.999",
                "kadin_nufus": "178.304",
                "yuzolcumu": "1.403"
              },
              {
                "district": "Sivrihisar",
                "nufus": "20.746",
                "erkek_nufus": "10.527",
                "kadin_nufus": "10.219",
                "yuzolcumu": "2.748"
              },
              {
                "district": "Çifteler",
                "nufus": "15.098",
                "erkek_nufus": "7.527",
                "kadin_nufus": "7.571",
                "yuzolcumu": "858"
              },
              {
                "district": "Seyitgazi",
                "nufus": "13.405",
                "erkek_nufus": "6.885",
                "kadin_nufus": "6.520",
                "yuzolcumu": "1.578"
              },
              {
                "district": "Alpu",
                "nufus": "11.242",
                "erkek_nufus": "5.753",
                "kadin_nufus": "5.489",
                "yuzolcumu": "1.028"
              },
              {
                "district": "Mihalıççık",
                "nufus": "8.526",
                "erkek_nufus": "4.294",
                "kadin_nufus": "4.232",
                "yuzolcumu": "1.809"
              },
              {
                "district": "Mahmudiye",
                "nufus": "7.998",
                "erkek_nufus": "4.081",
                "kadin_nufus": "3.917",
                "yuzolcumu": "659"
              },
              {
                "district": "Beylikova",
                "nufus": "6.953",
                "erkek_nufus": "3.553",
                "kadin_nufus": "3.400",
                "yuzolcumu": "715"
              },
              {
                "district": "İnönü",
                "nufus": "6.797",
                "erkek_nufus": "3.503",
                "kadin_nufus": "3.294",
                "yuzolcumu": "345"
              },
              {
                "district": "Günyüzü",
                "nufus": "6.127",
                "erkek_nufus": "2.991",
                "kadin_nufus": "3.136",
                "yuzolcumu": "828"
              },
              {
                "district": "Sarıcakaya",
                "nufus": "5.080",
                "erkek_nufus": "2.508",
                "kadin_nufus": "2.572",
                "yuzolcumu": "382"
              },
              {
                "district": "Mihalgazi",
                "nufus": "3.373",
                "erkek_nufus": "1.653",
                "kadin_nufus": "1.720",
                "yuzolcumu": "109"
              },
              {
                "district": "Han",
                "nufus": "2.272",
                "erkek_nufus": "1.190",
                "kadin_nufus": "1.082",
                "yuzolcumu": "378"
              }
            ],
            "kisa_bilgi": "Ortasından Porsuk Çayı geçen, 'öğrenci şehri' diye anılan, Met helvası, Nuga helvası, Haşhaşlı çöreği, Kalabak suyu ve çiböreği ile meşhur Eskişehir, ayrıca ülkemizde işlenebilir lületaşı çıkarılan tek yerdir."
          },
          {
            "city": "Gaziantep",
            "plaka_kodu": "27",
            "alan_kodu": "342",
            "nufus": "2.028.563",
            "bolge": "Güneydoğu Anadolu",
            "yuzolcumu": "6.803",
            "erkek_nufus_yuzde": "50,47%",
            "erkek_nufus": "1.023.716",
            "kadin_nufus_yuzde": "49,53%",
            "kadin_nufus": "1.004.847",
            "nufus_yuzdesi_genel": "2,47%",
            "ilceler": [
              {
                "district": "Şahinbey",
                "nufus": "906.043",
                "erkek_nufus": "458.113",
                "kadin_nufus": "447.930",
                "yuzolcumu": "960"
              },
              {
                "district": "Şehitkamil",
                "nufus": "774.179",
                "erkek_nufus": "390.260",
                "kadin_nufus": "383.919",
                "yuzolcumu": "1290"
              },
              {
                "district": "Nizip",
                "nufus": "142.389",
                "erkek_nufus": "70.705",
                "kadin_nufus": "71.684",
                "yuzolcumu": "944"
              },
              {
                "district": "İslahiye",
                "nufus": "67.674",
                "erkek_nufus": "34.482",
                "kadin_nufus": "33.192",
                "yuzolcumu": "865"
              },
              {
                "district": "Nurdağı",
                "nufus": "40.151",
                "erkek_nufus": "20.136",
                "kadin_nufus": "20.015",
                "yuzolcumu": "697"
              },
              {
                "district": "Araban",
                "nufus": "32.846",
                "erkek_nufus": "16.572",
                "kadin_nufus": "16.274",
                "yuzolcumu": "592"
              },
              {
                "district": "Oğuzeli",
                "nufus": "32.653",
                "erkek_nufus": "16.741",
                "kadin_nufus": "15.912",
                "yuzolcumu": "689"
              },
              {
                "district": "Yavuzeli",
                "nufus": "22.192",
                "erkek_nufus": "11.217",
                "kadin_nufus": "10.975",
                "yuzolcumu": "468"
              },
              {
                "district": "Karkamış",
                "nufus": "10.436",
                "erkek_nufus": "5.490",
                "kadin_nufus": "4.946",
                "yuzolcumu": "298"
              }
            ],
            "kisa_bilgi": "1987 yılında büyükşehir unvanını alan, Mezopotomya ve Akdeniz arasında, İpek Yolu üzerinde yer alan Antep, tarihin en eski yerleşim birimlerinden biri olarak kabul edilen Dülük antik kentinin de bulunduğu şehrimizdir."
          },
          {
            "city": "Giresun",
            "plaka_kodu": "28",
            "alan_kodu": "454",
            "nufus": "453.912",
            "bolge": "Karadeniz",
            "yuzolcumu": "7.025",
            "erkek_nufus_yuzde": "49,80%",
            "erkek_nufus": "226.067",
            "kadin_nufus_yuzde": "50,20%",
            "kadin_nufus": "227.845",
            "nufus_yuzdesi_genel": "0,55%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "135.920",
                "erkek_nufus": "66.828",
                "kadin_nufus": "69.092",
                "yuzolcumu": "376"
              },
              {
                "district": "Bulancak",
                "nufus": "66.736",
                "erkek_nufus": "33.181",
                "kadin_nufus": "33.555",
                "yuzolcumu": "774"
              },
              {
                "district": "Espiye",
                "nufus": "34.592",
                "erkek_nufus": "17.614",
                "kadin_nufus": "16.978",
                "yuzolcumu": "248"
              },
              {
                "district": "Görele",
                "nufus": "33.510",
                "erkek_nufus": "16.745",
                "kadin_nufus": "16.765",
                "yuzolcumu": "237"
              },
              {
                "district": "Tirebolu",
                "nufus": "32.008",
                "erkek_nufus": "15.854",
                "kadin_nufus": "16.154",
                "yuzolcumu": "259"
              },
              {
                "district": "Şebinkarahisar",
                "nufus": "21.814",
                "erkek_nufus": "10.983",
                "kadin_nufus": "10.831",
                "yuzolcumu": "1.396"
              },
              {
                "district": "Keşap",
                "nufus": "20.616",
                "erkek_nufus": "10.194",
                "kadin_nufus": "10.422",
                "yuzolcumu": "197"
              },
              {
                "district": "Dereli",
                "nufus": "20.405",
                "erkek_nufus": "10.378",
                "kadin_nufus": "10.027",
                "yuzolcumu": "849"
              },
              {
                "district": "Yağlıdere",
                "nufus": "16.758",
                "erkek_nufus": "8.404",
                "kadin_nufus": "8.354",
                "yuzolcumu": "327"
              },
              {
                "district": "Piraziz",
                "nufus": "14.659",
                "erkek_nufus": "7.357",
                "kadin_nufus": "7.302",
                "yuzolcumu": "127"
              },
              {
                "district": "Eynesil",
                "nufus": "13.955",
                "erkek_nufus": "6.518",
                "kadin_nufus": "7.437",
                "yuzolcumu": "51"
              },
              {
                "district": "Alucra",
                "nufus": "12.250",
                "erkek_nufus": "6.259",
                "kadin_nufus": "5.991",
                "yuzolcumu": "1.138"
              },
              {
                "district": "Çamoluk",
                "nufus": "9.759",
                "erkek_nufus": "5.201",
                "kadin_nufus": "4.558",
                "yuzolcumu": "444"
              },
              {
                "district": "Güce",
                "nufus": "7.809",
                "erkek_nufus": "3.925",
                "kadin_nufus": "3.884",
                "yuzolcumu": "351"
              },
              {
                "district": "Doğankent",
                "nufus": "6.690",
                "erkek_nufus": "3.401",
                "kadin_nufus": "3.289",
                "yuzolcumu": "110"
              },
              {
                "district": "Çanakçı",
                "nufus": "6.431",
                "erkek_nufus": "3.225",
                "kadin_nufus": "3.206",
                "yuzolcumu": "142"
              }
            ],
            "kisa_bilgi": "Dünyaca meşhur, parlak kabuklu, yağ oranı yüksek ve tombul fındığı, Fiskobirlik’in genel müdürlüğüne de ev sahipliği yapması, doğal ve kültürel zenginlikleri, hâlâ sürdürülen gelenek ve görenekleriyle Giresun, keşfedilesi şehirlerimizden biri."
          },
          {
            "city": "Gümüşhane",
            "plaka_kodu": "29",
            "alan_kodu": "456",
            "nufus": "162.748",
            "bolge": "Karadeniz",
            "yuzolcumu": "6.668",
            "erkek_nufus_yuzde": "50,67%",
            "erkek_nufus": "82.468",
            "kadin_nufus_yuzde": "49,33%",
            "kadin_nufus": "80.280",
            "nufus_yuzdesi_genel": "0,20%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "57.269",
                "erkek_nufus": "28.735",
                "kadin_nufus": "28.534",
                "yuzolcumu": "1889"
              },
              {
                "district": "Kelkit",
                "nufus": "47.891",
                "erkek_nufus": "24.429",
                "kadin_nufus": "23.462",
                "yuzolcumu": "1571"
              },
              {
                "district": "Şiran",
                "nufus": "20.084",
                "erkek_nufus": "10.135",
                "kadin_nufus": "9.949",
                "yuzolcumu": "965"
              },
              {
                "district": "Kürtün",
                "nufus": "14.346",
                "erkek_nufus": "7.512",
                "kadin_nufus": "6.834",
                "yuzolcumu": "917"
              },
              {
                "district": "Torul",
                "nufus": "13.771",
                "erkek_nufus": "6.893",
                "kadin_nufus": "6.878",
                "yuzolcumu": "973"
              },
              {
                "district": "Köse",
                "nufus": "9.387",
                "erkek_nufus": "4.764",
                "kadin_nufus": "4.623",
                "yuzolcumu": "353"
              }
            ],
            "kisa_bilgi": "Doğu Karadeniz’in iç kesiminde yer alan, Artabel Gölleri Tabiat Parkı ile Kuluca, Şiran Dağları gibi koruma altındaki doğal güzelliklere sahip Gümüşhane, kış turizmi açısından da dikkat çekiyor."
          },
          {
            "city": "Hakkari",
            "plaka_kodu": "30",
            "alan_kodu": "438",
            "nufus": "286.470",
            "bolge": "Doğu Anadolu",
            "yuzolcumu": "7.095",
            "erkek_nufus_yuzde": "54,84%",
            "erkek_nufus": "157.107",
            "kadin_nufus_yuzde": "45,16%",
            "kadin_nufus": "129.363",
            "nufus_yuzdesi_genel": "0,35%",
            "ilceler": [
              {
                "district": "Yüksekova",
                "nufus": "119.760",
                "erkek_nufus": "63.019",
                "kadin_nufus": "56.741",
                "yuzolcumu": "2.547"
              },
              {
                "district": "Merkez",
                "nufus": "81.424",
                "erkek_nufus": "44.130",
                "kadin_nufus": "37.294",
                "yuzolcumu": "2.179"
              },
              {
                "district": "Şemdinli",
                "nufus": "45.117",
                "erkek_nufus": "25.541",
                "kadin_nufus": "19.576",
                "yuzolcumu": "1.207"
              },
              {
                "district": "Derecik",
                "nufus": "22.953",
                "erkek_nufus": "12.582",
                "kadin_nufus": "10.371",
                "yuzolcumu": "437"
              },
              {
                "district": "Çukurca",
                "nufus": "17.216",
                "erkek_nufus": "11.835",
                "kadin_nufus": "5.381",
                "yuzolcumu": "725"
              }
            ],
            "kisa_bilgi": "Güneyinde Irak, doğusunda İran ile komşu olan Hakkari, tarih boyunca Urartu, Pers, Arap ardından Selçuklu hakimiyetinde bulunup, 16. yy’da ise Osmanlı İmparatorluğuna dahil edildi. "
          },
          {
            "city": "Hatay",
            "plaka_kodu": "31",
            "alan_kodu": "326",
            "nufus": "1.609.856",
            "bolge": "Güneydoğu Anadolu",
            "yuzolcumu": "5.524",
            "erkek_nufus_yuzde": "50,28%",
            "erkek_nufus": "809.484",
            "kadin_nufus_yuzde": "49,72%",
            "kadin_nufus": "800.372",
            "nufus_yuzdesi_genel": "1,96%",
            "ilceler": [
              {
                "district": "Antakya",
                "nufus": "377.793",
                "erkek_nufus": "188.755",
                "kadin_nufus": "189.038",
                "yuzolcumu": "703"
              },
              {
                "district": "İskenderun",
                "nufus": "248.335",
                "erkek_nufus": "125.256",
                "kadin_nufus": "123.079",
                "yuzolcumu": "247"
              },
              {
                "district": "Defne",
                "nufus": "151.017",
                "erkek_nufus": "74.420",
                "kadin_nufus": "76.597",
                "yuzolcumu": "155"
              },
              {
                "district": "Dörtyol",
                "nufus": "123.891",
                "erkek_nufus": "62.235",
                "kadin_nufus": "61.656",
                "yuzolcumu": "342"
              },
              {
                "district": "Samandağ",
                "nufus": "121.109",
                "erkek_nufus": "58.845",
                "kadin_nufus": "62.264",
                "yuzolcumu": "384"
              },
              {
                "district": "Kırıkhan",
                "nufus": "115.196",
                "erkek_nufus": "57.963",
                "kadin_nufus": "57.233",
                "yuzolcumu": "715"
              },
              {
                "district": "Reyhanlı",
                "nufus": "98.534",
                "erkek_nufus": "49.526",
                "kadin_nufus": "49.008",
                "yuzolcumu": "367"
              },
              {
                "district": "Arsuz",
                "nufus": "90.456",
                "erkek_nufus": "45.986",
                "kadin_nufus": "44.470",
                "yuzolcumu": "462"
              },
              {
                "district": "Altınözü",
                "nufus": "61.106",
                "erkek_nufus": "31.198",
                "kadin_nufus": "29.908",
                "yuzolcumu": "392"
              },
              {
                "district": "Hassa",
                "nufus": "56.409",
                "erkek_nufus": "29.035",
                "kadin_nufus": "27.374",
                "yuzolcumu": "520"
              },
              {
                "district": "Payas",
                "nufus": "41.409",
                "erkek_nufus": "20.982",
                "kadin_nufus": "20.427",
                "yuzolcumu": "157"
              },
              {
                "district": "Erzin",
                "nufus": "41.368",
                "erkek_nufus": "20.735",
                "kadin_nufus": "20.633",
                "yuzolcumu": "258"
              },
              {
                "district": "Yayladağı",
                "nufus": "35.460",
                "erkek_nufus": "20.143",
                "kadin_nufus": "15.317",
                "yuzolcumu": "445"
              },
              {
                "district": "Belen",
                "nufus": "33.540",
                "erkek_nufus": "16.935",
                "kadin_nufus": "16.605",
                "yuzolcumu": "184"
              },
              {
                "district": "Kumlu",
                "nufus": "14.233",
                "erkek_nufus": "7.470",
                "kadin_nufus": "6.763",
                "yuzolcumu": "193"
              }
            ],
            "kisa_bilgi": "Doğusunda ve güneyinde Suriye, batısında Akdeniz, kuzeybatısında Adana, kuzeyinde Osmaniye ve kuzeydoğusunda Gaziantep’in yer aldığı Hatay, arkeolojik olarak da oldukça değerli ve eski bir yerleşim yeridir."
          },
          {
            "city": "Isparta",
            "plaka_kodu": "32",
            "alan_kodu": "246",
            "nufus": "441.412",
            "bolge": "Akdeniz",
            "yuzolcumu": "8.946",
            "erkek_nufus_yuzde": "49,77%",
            "erkek_nufus": "219.681",
            "kadin_nufus_yuzde": "50,23%",
            "kadin_nufus": "221.731",
            "nufus_yuzdesi_genel": "0,54%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "258.375",
                "erkek_nufus": "128.470",
                "kadin_nufus": "129.905",
                "yuzolcumu": "773"
              },
              {
                "district": "Yalvaç",
                "nufus": "46.646",
                "erkek_nufus": "22.910",
                "kadin_nufus": "23.736",
                "yuzolcumu": "1.402"
              },
              {
                "district": "Eğirdir",
                "nufus": "32.436",
                "erkek_nufus": "16.551",
                "kadin_nufus": "15.885",
                "yuzolcumu": "1.315"
              },
              {
                "district": "Şarkikaraağaç",
                "nufus": "25.578",
                "erkek_nufus": "12.627",
                "kadin_nufus": "12.951",
                "yuzolcumu": "1.068"
              },
              {
                "district": "Gelendost",
                "nufus": "15.414",
                "erkek_nufus": "7.587",
                "kadin_nufus": "7.827",
                "yuzolcumu": "610"
              },
              {
                "district": "Keçiborlu",
                "nufus": "14.383",
                "erkek_nufus": "7.311",
                "kadin_nufus": "7.072",
                "yuzolcumu": "494"
              },
              {
                "district": "Senirkent",
                "nufus": "11.811",
                "erkek_nufus": "5.759",
                "kadin_nufus": "6.052",
                "yuzolcumu": "521"
              },
              {
                "district": "Sütçüler",
                "nufus": "10.707",
                "erkek_nufus": "5.419",
                "kadin_nufus": "5.288",
                "yuzolcumu": "1235"
              },
              {
                "district": "Gönen",
                "nufus": "7.364",
                "erkek_nufus": "3.698",
                "kadin_nufus": "3.666",
                "yuzolcumu": "285"
              },
              {
                "district": "Uluborlu",
                "nufus": "6.388",
                "erkek_nufus": "3.181",
                "kadin_nufus": "3.207",
                "yuzolcumu": "240"
              },
              {
                "district": "Atabey",
                "nufus": "5.477",
                "erkek_nufus": "2.715",
                "kadin_nufus": "2.762",
                "yuzolcumu": "223"
              },
              {
                "district": "Aksu",
                "nufus": "4.533",
                "erkek_nufus": "2.288",
                "kadin_nufus": "2.245",
                "yuzolcumu": "544"
              },
              {
                "district": "Yenişarbademli",
                "nufus": "2.300",
                "erkek_nufus": "1.165",
                "kadin_nufus": "1.135",
                "yuzolcumu": "237"
              }
            ],
            "kisa_bilgi": "Lezzetli, uzun saplı, sert meyveli kirazı, gülü ve halısıyla meşhur, ayrıca ülkemizde en çok elma üretiminin yapıldığı Isparta, Göller bölgesinde yer alıyor. "
          },
          {
            "city": "Mersin",
            "plaka_kodu": "33",
            "alan_kodu": "324",
            "nufus": "1.814.468",
            "bolge": "Akdeniz",
            "yuzolcumu": "16.010",
            "erkek_nufus_yuzde": "49,91%",
            "erkek_nufus": "905.520",
            "kadin_nufus_yuzde": "50,09%",
            "kadin_nufus": "908.948",
            "nufus_yuzdesi_genel": "2,21%",
            "ilceler": [
              {
                "district": "Tarsus",
                "nufus": "339.676",
                "erkek_nufus": "171.375",
                "kadin_nufus": "168.301",
                "yuzolcumu": "2.029"
              },
              {
                "district": "Toroslar",
                "nufus": "296.582",
                "erkek_nufus": "148.749",
                "kadin_nufus": "147.833",
                "yuzolcumu": "1.075"
              },
              {
                "district": "Akdeniz",
                "nufus": "264.618",
                "erkek_nufus": "134.075",
                "kadin_nufus": "130.543",
                "yuzolcumu": "265"
              },
              {
                "district": "Yenişehir",
                "nufus": "258.694",
                "erkek_nufus": "124.661",
                "kadin_nufus": "134.033",
                "yuzolcumu": "139"
              },
              {
                "district": "Mezitli",
                "nufus": "194.019",
                "erkek_nufus": "94.991",
                "kadin_nufus": "99.028",
                "yuzolcumu": "371"
              },
              {
                "district": "Erdemli",
                "nufus": "140.331",
                "erkek_nufus": "70.107",
                "kadin_nufus": "70.224",
                "yuzolcumu": "2.279"
              },
              {
                "district": "Silifke",
                "nufus": "119.303",
                "erkek_nufus": "59.962",
                "kadin_nufus": "59.341",
                "yuzolcumu": "2.692"
              },
              {
                "district": "Anamur",
                "nufus": "65.920",
                "erkek_nufus": "33.420",
                "kadin_nufus": "32.500",
                "yuzolcumu": "1.430"
              },
              {
                "district": "Mut",
                "nufus": "62.853",
                "erkek_nufus": "31.501",
                "kadin_nufus": "31.352",
                "yuzolcumu": "2.718"
              },
              {
                "district": "Bozyazı",
                "nufus": "26.840",
                "erkek_nufus": "13.518",
                "kadin_nufus": "13.322",
                "yuzolcumu": "642"
              },
              {
                "district": "Gülnar",
                "nufus": "25.865",
                "erkek_nufus": "13.051",
                "kadin_nufus": "12.814",
                "yuzolcumu": "1.416"
              },
              {
                "district": "Aydıncık",
                "nufus": "11.088",
                "erkek_nufus": "5.640",
                "kadin_nufus": "5.448",
                "yuzolcumu": "352"
              },
              {
                "district": "Çamlıyayla",
                "nufus": "8.679",
                "erkek_nufus": "4.470",
                "kadin_nufus": "4.209",
                "yuzolcumu": "602"
              }
            ],
            "kisa_bilgi": "Türkiye'nin güneyinde bulunan Mersin, ülkenin en büyük limanı (Mersin Limanı) ile bir liman kentidir. Mersin Serbest Bölgesi, tekstil firmaları yoğunlukta olmak üzere pek çok şirkete ev sahipliği yapar. "
          },
          {
            "city": "İstanbul",
            "plaka_kodu": "34",
            "alan_kodu": "212-216",
            "nufus": "15.067.724",
            "bolge": "Marmara",
            "yuzolcumu": "5.461",
            "erkek_nufus_yuzde": "50,06%",
            "erkek_nufus": "7.542.231",
            "kadin_nufus_yuzde": "49,94%",
            "kadin_nufus": "7.525.493",
            "nufus_yuzdesi_genel": "18,37%",
            "ilceler": [
              {
                "district": "Esenyurt",
                "nufus": "891.120",
                "erkek_nufus": "456.552",
                "kadin_nufus": "434.568",
                "yuzolcumu": "43"
              },
              {
                "district": "Küçükçekmece",
                "nufus": "770.317",
                "erkek_nufus": "385.154",
                "kadin_nufus": "385.163",
                "yuzolcumu": "44"
              },
              {
                "district": "Bağcılar",
                "nufus": "734.369",
                "erkek_nufus": "372.311",
                "kadin_nufus": "362.058",
                "yuzolcumu": "23"
              },
              {
                "district": "Pendik",
                "nufus": "693.599",
                "erkek_nufus": "350.302",
                "kadin_nufus": "343.297",
                "yuzolcumu": "190"
              },
              {
                "district": "Ümraniye",
                "nufus": "690.193",
                "erkek_nufus": "345.478",
                "kadin_nufus": "344.715",
                "yuzolcumu": "46"
              },
              {
                "district": "Bahçelievler",
                "nufus": "594.053",
                "erkek_nufus": "298.175",
                "kadin_nufus": "295.878",
                "yuzolcumu": "17"
              },
              {
                "district": "Üsküdar",
                "nufus": "529.145",
                "erkek_nufus": "257.831",
                "kadin_nufus": "271.314",
                "yuzolcumu": ""
              },
              {
                "district": "Sultangazi",
                "nufus": "523.765",
                "erkek_nufus": "266.629",
                "kadin_nufus": "257.136",
                "yuzolcumu": "35"
              },
              {
                "district": "Maltepe",
                "nufus": "497.034",
                "erkek_nufus": "248.023",
                "kadin_nufus": "249.011",
                "yuzolcumu": "53"
              },
              {
                "district": "Gaziosmanpaşa",
                "nufus": "487.046",
                "erkek_nufus": "244.474",
                "kadin_nufus": "242.572",
                "yuzolcumu": "12"
              },
              {
                "district": "Kartal",
                "nufus": "461.155",
                "erkek_nufus": "228.034",
                "kadin_nufus": "233.121",
                "yuzolcumu": "38"
              },
              {
                "district": "Kadıköy",
                "nufus": "458.638",
                "erkek_nufus": "207.069",
                "kadin_nufus": "251.569",
                "yuzolcumu": "25"
              },
              {
                "district": "Esenler",
                "nufus": "444.561",
                "erkek_nufus": "227.440",
                "kadin_nufus": "217.121",
                "yuzolcumu": "19"
              },
              {
                "district": "Kağıthane",
                "nufus": "437.026",
                "erkek_nufus": "220.089",
                "kadin_nufus": "216.937",
                "yuzolcumu": "15"
              },
              {
                "district": "Fatih",
                "nufus": "436.539",
                "erkek_nufus": "221.118",
                "kadin_nufus": "215.421",
                "yuzolcumu": "15"
              },
              {
                "district": "Avcılar",
                "nufus": "435.625",
                "erkek_nufus": "217.653",
                "kadin_nufus": "217.972",
                "yuzolcumu": "50"
              },
              {
                "district": "Başakşehir",
                "nufus": "427.835",
                "erkek_nufus": "215.652",
                "kadin_nufus": "212.183",
                "yuzolcumu": "107"
              },
              {
                "district": "Ataşehir",
                "nufus": "416.318",
                "erkek_nufus": "203.686",
                "kadin_nufus": "212.632",
                "yuzolcumu": "25"
              },
              {
                "district": "Sancaktepe",
                "nufus": "414.143",
                "erkek_nufus": "210.194",
                "kadin_nufus": "203.949",
                "yuzolcumu": "63"
              },
              {
                "district": "Eyüp",
                "nufus": "383.909",
                "erkek_nufus": "191.951",
                "kadin_nufus": "191.958",
                "yuzolcumu": "228"
              },
              {
                "district": "Sarıyer",
                "nufus": "342.503",
                "erkek_nufus": "169.304",
                "kadin_nufus": "173.199",
                "yuzolcumu": "177"
              },
              {
                "district": "Beylikdüzü",
                "nufus": "331.525",
                "erkek_nufus": "161.876",
                "kadin_nufus": "169.649",
                "yuzolcumu": "39"
              },
              {
                "district": "Sultanbeyli",
                "nufus": "327.798",
                "erkek_nufus": "167.763",
                "kadin_nufus": "160.035",
                "yuzolcumu": "29"
              },
              {
                "district": "Güngören",
                "nufus": "289.331",
                "erkek_nufus": "145.936",
                "kadin_nufus": "143.395",
                "yuzolcumu": "7"
              },
              {
                "district": "Zeytinburnu",
                "nufus": "284.935",
                "erkek_nufus": "142.052",
                "kadin_nufus": "142.883",
                "yuzolcumu": "12"
              },
              {
                "district": "Şişli",
                "nufus": "274.289",
                "erkek_nufus": "133.500",
                "kadin_nufus": "140.789",
                "yuzolcumu": "10"
              },
              {
                "district": "Bayrampaşa",
                "nufus": "271.073",
                "erkek_nufus": "135.907",
                "kadin_nufus": "135.166",
                "yuzolcumu": "9"
              },
              {
                "district": "Arnavutköy",
                "nufus": "270.549",
                "erkek_nufus": "140.662",
                "kadin_nufus": "129.887",
                "yuzolcumu": "453"
              },
              {
                "district": "Tuzla",
                "nufus": "255.468",
                "erkek_nufus": "130.360",
                "kadin_nufus": "125.108",
                "yuzolcumu": "138"
              },
              {
                "district": "Çekmeköy",
                "nufus": "251.937",
                "erkek_nufus": "126.336",
                "kadin_nufus": "125.601",
                "yuzolcumu": "152"
              },
              {
                "district": "Büyükçekmece",
                "nufus": "247.736",
                "erkek_nufus": "122.755",
                "kadin_nufus": "124.981",
                "yuzolcumu": "173"
              },
              {
                "district": "Beykoz",
                "nufus": "246.700",
                "erkek_nufus": "122.109",
                "kadin_nufus": "124.591",
                "yuzolcumu": "310"
              },
              {
                "district": "Beyoğlu",
                "nufus": "230.526",
                "erkek_nufus": "118.273",
                "kadin_nufus": "112.253",
                "yuzolcumu": "9"
              },
              {
                "district": "Bakırköy",
                "nufus": "222.668",
                "erkek_nufus": "103.677",
                "kadin_nufus": "118.991",
                "yuzolcumu": "29"
              },
              {
                "district": "Silivri",
                "nufus": "187.621",
                "erkek_nufus": "106.014",
                "kadin_nufus": "81.607",
                "yuzolcumu": "858"
              },
              {
                "district": "Beşiktaş",
                "nufus": "181.074",
                "erkek_nufus": "82.810",
                "kadin_nufus": "98.264",
                "yuzolcumu": "18"
              },
              {
                "district": "Çatalca",
                "nufus": "72.966",
                "erkek_nufus": "37.675",
                "kadin_nufus": "35.291",
                "yuzolcumu": ""
              },
              {
                "district": "Şile",
                "nufus": "36.516",
                "erkek_nufus": "18.805",
                "kadin_nufus": "17.711",
                "yuzolcumu": "1.142"
              },
              {
                "district": "Adalar",
                "nufus": "16.119",
                "erkek_nufus": "8.602",
                "kadin_nufus": "7.517",
                "yuzolcumu": "11"
              }
            ],
            "kisa_bilgi": "2010 Avrupa Kültür Başkenti, tarihi 300 bin yıl öncesine uzanan, üç tarafını Marmara Denizi, Boğaziçi ve Haliç'in sardığı bir yarım ada üzerinde yer alan, Roma, Bizans, Latin ve Osmanlı Türklerine başkentlik etmiş, Türkiye'nin en kalabalık şehri İstanbul, hem tarih boyunca, hem günümüzde pek çok alanda 'başkent' özelliğini korumuştur."
          },
          {
            "city": "İzmir",
            "plaka_kodu": "35",
            "alan_kodu": "232",
            "nufus": "4.320.519",
            "bolge": "Ege",
            "yuzolcumu": "11.891",
            "erkek_nufus_yuzde": "49,82%",
            "erkek_nufus": "2.152.585",
            "kadin_nufus_yuzde": "50,18%",
            "kadin_nufus": "2.167.934",
            "nufus_yuzdesi_genel": "5,27%",
            "ilceler": [
              {
                "district": "Buca",
                "nufus": "499.325",
                "erkek_nufus": "249.543",
                "kadin_nufus": "249.782",
                "yuzolcumu": "178"
              },
              {
                "district": "Karabağlar",
                "nufus": "479.986",
                "erkek_nufus": "236.677",
                "kadin_nufus": "243.309",
                "yuzolcumu": "89"
              },
              {
                "district": "Bornova",
                "nufus": "445.232",
                "erkek_nufus": "221.092",
                "kadin_nufus": "224.140",
                "yuzolcumu": "220"
              },
              {
                "district": "Konak",
                "nufus": "356.563",
                "erkek_nufus": "174.066",
                "kadin_nufus": "182.497",
                "yuzolcumu": "24"
              },
              {
                "district": "Karşıyaka",
                "nufus": "344.140",
                "erkek_nufus": "161.345",
                "kadin_nufus": "182.795",
                "yuzolcumu": "51"
              },
              {
                "district": "Bayraklı",
                "nufus": "311.524",
                "erkek_nufus": "154.715",
                "kadin_nufus": "156.809",
                "yuzolcumu": "30"
              },
              {
                "district": "Çiğli",
                "nufus": "194.525",
                "erkek_nufus": "96.838",
                "kadin_nufus": "97.687",
                "yuzolcumu": "139"
              },
              {
                "district": "Torbalı",
                "nufus": "178.772",
                "erkek_nufus": "90.173",
                "kadin_nufus": "88.599",
                "yuzolcumu": "577"
              },
              {
                "district": "Menemen",
                "nufus": "174.564",
                "erkek_nufus": "89.163",
                "kadin_nufus": "85.401",
                "yuzolcumu": "573"
              },
              {
                "district": "Gaziemir",
                "nufus": "137.553",
                "erkek_nufus": "70.309",
                "kadin_nufus": "67.244",
                "yuzolcumu": "70"
              },
              {
                "district": "Ödemiş",
                "nufus": "132.511",
                "erkek_nufus": "65.990",
                "kadin_nufus": "66.521",
                "yuzolcumu": "1.019"
              },
              {
                "district": "Kemalpaşa",
                "nufus": "106.298",
                "erkek_nufus": "53.975",
                "kadin_nufus": "52.323",
                "yuzolcumu": "681"
              },
              {
                "district": "Bergama",
                "nufus": "103.185",
                "erkek_nufus": "51.920",
                "kadin_nufus": "51.265",
                "yuzolcumu": "1.544"
              },
              {
                "district": "Aliağa",
                "nufus": "95.392",
                "erkek_nufus": "52.320",
                "kadin_nufus": "43.072",
                "yuzolcumu": "379"
              },
              {
                "district": "Menderes",
                "nufus": "93.796",
                "erkek_nufus": "47.316",
                "kadin_nufus": "46.480",
                "yuzolcumu": "777"
              },
              {
                "district": "Tire",
                "nufus": "84.457",
                "erkek_nufus": "41.552",
                "kadin_nufus": "42.905",
                "yuzolcumu": "716"
              },
              {
                "district": "Balçova",
                "nufus": "79.357",
                "erkek_nufus": "38.834",
                "kadin_nufus": "40.523",
                "yuzolcumu": "16"
              },
              {
                "district": "Urla",
                "nufus": "66.360",
                "erkek_nufus": "33.397",
                "kadin_nufus": "32.963",
                "yuzolcumu": "727"
              },
              {
                "district": "Narlıdere",
                "nufus": "66.203",
                "erkek_nufus": "33.765",
                "kadin_nufus": "32.438",
                "yuzolcumu": "50"
              },
              {
                "district": "Dikili",
                "nufus": "44.172",
                "erkek_nufus": "22.089",
                "kadin_nufus": "22.083",
                "yuzolcumu": "534"
              },
              {
                "district": "Kiraz",
                "nufus": "43.989",
                "erkek_nufus": "22.264",
                "kadin_nufus": "21.725",
                "yuzolcumu": "573"
              },
              {
                "district": "Seferihisar",
                "nufus": "43.546",
                "erkek_nufus": "22.361",
                "kadin_nufus": "21.185",
                "yuzolcumu": "375"
              },
              {
                "district": "Çeşme",
                "nufus": "43.489",
                "erkek_nufus": "22.041",
                "kadin_nufus": "21.448",
                "yuzolcumu": "285"
              },
              {
                "district": "Bayındır",
                "nufus": "40.584",
                "erkek_nufus": "20.141",
                "kadin_nufus": "20.443",
                "yuzolcumu": "548"
              },
              {
                "district": "Selçuk",
                "nufus": "36.360",
                "erkek_nufus": "18.125",
                "kadin_nufus": "18.235",
                "yuzolcumu": "317"
              },
              {
                "district": "Foça",
                "nufus": "33.131",
                "erkek_nufus": "19.790",
                "kadin_nufus": "13.341",
                "yuzolcumu": "251"
              },
              {
                "district": "Güzelbahçe",
                "nufus": "32.592",
                "erkek_nufus": "15.764",
                "kadin_nufus": "16.828",
                "yuzolcumu": "77"
              },
              {
                "district": "Kınık",
                "nufus": "29.803",
                "erkek_nufus": "15.267",
                "kadin_nufus": "14.536",
                "yuzolcumu": "479"
              },
              {
                "district": "Beydağ",
                "nufus": "12.507",
                "erkek_nufus": "6.264",
                "kadin_nufus": "6.243",
                "yuzolcumu": "172"
              },
              {
                "district": "Karaburun",
                "nufus": "10.603",
                "erkek_nufus": "5.489",
                "kadin_nufus": "5.114",
                "yuzolcumu": "421"
              }
            ],
            "kisa_bilgi": "Latince adı Smyrna olan İzmir, Ege Bölgesi'nin ortasında ve İzmir Körfezi çevresinde yer alır. Konak, kentin merkez ilçesidir ve burada gezilecek, görülecek çok şey vardır."
          },
          {
            "city": "Kars",
            "plaka_kodu": "36",
            "alan_kodu": "474",
            "nufus": "288.878",
            "bolge": "Doğu Anadolu",
            "yuzolcumu": "10.193",
            "erkek_nufus_yuzde": "51,76%",
            "erkek_nufus": "149.510",
            "kadin_nufus_yuzde": "48,24%",
            "kadin_nufus": "139.368",
            "nufus_yuzdesi_genel": "0,35%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "115.891",
                "erkek_nufus": "59.160",
                "kadin_nufus": "56.731",
                "yuzolcumu": "2.048"
              },
              {
                "district": "Kağızman",
                "nufus": "45.936",
                "erkek_nufus": "23.431",
                "kadin_nufus": "22.505",
                "yuzolcumu": "1.871"
              },
              {
                "district": "Sarıkamış",
                "nufus": "42.683",
                "erkek_nufus": "22.963",
                "kadin_nufus": "19.720",
                "yuzolcumu": "2.038"
              },
              {
                "district": "Selim",
                "nufus": "23.231",
                "erkek_nufus": "11.953",
                "kadin_nufus": "11.278",
                "yuzolcumu": "982"
              },
              {
                "district": "Digor",
                "nufus": "22.282",
                "erkek_nufus": "11.643",
                "kadin_nufus": "10.639",
                "yuzolcumu": "1092"
              },
              {
                "district": "Arpaçay",
                "nufus": "17.373",
                "erkek_nufus": "9.027",
                "kadin_nufus": "8.346",
                "yuzolcumu": "1165"
              },
              {
                "district": "Akyaka",
                "nufus": "10.985",
                "erkek_nufus": "5.957",
                "kadin_nufus": "5.028",
                "yuzolcumu": "417"
              },
              {
                "district": "Susuz",
                "nufus": "10.497",
                "erkek_nufus": "5.376",
                "kadin_nufus": "5.121",
                "yuzolcumu": "578"
              }
            ],
            "kisa_bilgi": "Ülkemizin en doğusunda, Ermenistan’la komşu Kars, çok eski kültürlere ev sahipliği yapmış, pek çok kıymetli halk ozanının yetiştiği önemli bir ilimiz."
          },
          {
            "city": "Kastamonu",
            "plaka_kodu": "37",
            "alan_kodu": "366",
            "nufus": "383.373",
            "bolge": "Karadeniz",
            "yuzolcumu": "13.064",
            "erkek_nufus_yuzde": "49,93%",
            "erkek_nufus": "191.431",
            "kadin_nufus_yuzde": "50,07%",
            "kadin_nufus": "191.942",
            "nufus_yuzdesi_genel": "0,45%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "148.931",
                "erkek_nufus": "74.647",
                "kadin_nufus": "74.284",
                "yuzolcumu": "1.847"
              },
              {
                "district": "Tosya",
                "nufus": "40.245",
                "erkek_nufus": "19.528",
                "kadin_nufus": "20.717",
                "yuzolcumu": "1.302"
              },
              {
                "district": "Taşköprü",
                "nufus": "38.849",
                "erkek_nufus": "19.075",
                "kadin_nufus": "19.774",
                "yuzolcumu": "1.758"
              },
              {
                "district": "Cide",
                "nufus": "23.329",
                "erkek_nufus": "11.855",
                "kadin_nufus": "11.474",
                "yuzolcumu": "652"
              },
              {
                "district": "İnebolu",
                "nufus": "21.738",
                "erkek_nufus": "10.960",
                "kadin_nufus": "10.778",
                "yuzolcumu": "417"
              },
              {
                "district": "Araç",
                "nufus": "18.863",
                "erkek_nufus": "9.306",
                "kadin_nufus": "9.557",
                "yuzolcumu": "1.446"
              },
              {
                "district": "Bozkurt",
                "nufus": "9.920",
                "erkek_nufus": "5.002",
                "kadin_nufus": "4.918",
                "yuzolcumu": "308"
              },
              {
                "district": "Daday",
                "nufus": "8.749",
                "erkek_nufus": "4.282",
                "kadin_nufus": "4.467",
                "yuzolcumu": "843"
              },
              {
                "district": "Azdavay",
                "nufus": "7.726",
                "erkek_nufus": "3.924",
                "kadin_nufus": "3.802",
                "yuzolcumu": "759"
              },
              {
                "district": "Çatalzeytin",
                "nufus": "7.035",
                "erkek_nufus": "3.546",
                "kadin_nufus": "3.489",
                "yuzolcumu": "272"
              },
              {
                "district": "Doğanyurt",
                "nufus": "6.153",
                "erkek_nufus": "3.086",
                "kadin_nufus": "3.067",
                "yuzolcumu": "193"
              },
              {
                "district": "Küre",
                "nufus": "6.119",
                "erkek_nufus": "3.147",
                "kadin_nufus": "2.972",
                "yuzolcumu": "406"
              },
              {
                "district": "Pınarbaşı",
                "nufus": "6.020",
                "erkek_nufus": "3.116",
                "kadin_nufus": "2.904",
                "yuzolcumu": "546"
              },
              {
                "district": "İhsangazi",
                "nufus": "5.469",
                "erkek_nufus": "2.656",
                "kadin_nufus": "2.813",
                "yuzolcumu": "445"
              },
              {
                "district": "Şenpazar",
                "nufus": "5.002",
                "erkek_nufus": "2.589",
                "kadin_nufus": "2.413",
                "yuzolcumu": "252"
              },
              {
                "district": "Abana",
                "nufus": "4.248",
                "erkek_nufus": "2.204",
                "kadin_nufus": "2.044",
                "yuzolcumu": "28"
              },
              {
                "district": "Hanönü",
                "nufus": "4.156",
                "erkek_nufus": "2.098",
                "kadin_nufus": "2.058",
                "yuzolcumu": "417"
              },
              {
                "district": "Seydiler",
                "nufus": "4.143",
                "erkek_nufus": "2.073",
                "kadin_nufus": "2.070",
                "yuzolcumu": "233"
              },
              {
                "district": "Ağlı",
                "nufus": "3.196",
                "erkek_nufus": "1.594",
                "kadin_nufus": "1.602",
                "yuzolcumu": "225"
              }
            ],
            "kisa_bilgi": "Karadeniz bölgesinde bulunan Kastamonu, tarihi boyunca önemli bir medeniyetler merkezi olmuş. Bu nedenle ilde pek çok değerli tarihi eser saymak mümkün."
          },
          {
            "city": "Kayseri",
            "plaka_kodu": "38",
            "alan_kodu": "352",
            "nufus": "1.389.680",
            "bolge": "İç Anadolu",
            "yuzolcumu": "16.970",
            "erkek_nufus_yuzde": "50,13%",
            "erkek_nufus": "696.658",
            "kadin_nufus_yuzde": "49,87%",
            "kadin_nufus": "693.022",
            "nufus_yuzdesi_genel": "1,69%",
            "ilceler": [
              {
                "district": "Melikgazi",
                "nufus": "555.671",
                "erkek_nufus": "277.262",
                "kadin_nufus": "278.409",
                "yuzolcumu": "668"
              },
              {
                "district": "Kocasinan",
                "nufus": "391.661",
                "erkek_nufus": "194.925",
                "kadin_nufus": "196.736",
                "yuzolcumu": "1.471"
              },
              {
                "district": "Talas",
                "nufus": "157.695",
                "erkek_nufus": "78.580",
                "kadin_nufus": "79.115",
                "yuzolcumu": "444"
              },
              {
                "district": "Develi",
                "nufus": "65.322",
                "erkek_nufus": "32.803",
                "kadin_nufus": "32.519",
                "yuzolcumu": "1.892"
              },
              {
                "district": "Yahyalı",
                "nufus": "36.331",
                "erkek_nufus": "18.339",
                "kadin_nufus": "17.992",
                "yuzolcumu": "1.587"
              },
              {
                "district": "Bünyan",
                "nufus": "31.497",
                "erkek_nufus": "17.561",
                "kadin_nufus": "13.936",
                "yuzolcumu": "1.210"
              },
              {
                "district": "Pınarbaşı",
                "nufus": "26.911",
                "erkek_nufus": "13.993",
                "kadin_nufus": "12.918",
                "yuzolcumu": "3.418"
              },
              {
                "district": "Tomarza",
                "nufus": "22.808",
                "erkek_nufus": "11.617",
                "kadin_nufus": "11.191",
                "yuzolcumu": "1.405"
              },
              {
                "district": "Yeşilhisar",
                "nufus": "17.525",
                "erkek_nufus": "8.821",
                "kadin_nufus": "8.704",
                "yuzolcumu": "913"
              },
              {
                "district": "Sarıoğlan",
                "nufus": "16.938",
                "erkek_nufus": "8.520",
                "kadin_nufus": "8.418",
                "yuzolcumu": "642"
              },
              {
                "district": "Hacılar",
                "nufus": "12.426",
                "erkek_nufus": "6.265",
                "kadin_nufus": "6.161",
                "yuzolcumu": "187"
              },
              {
                "district": "Sarız",
                "nufus": "10.098",
                "erkek_nufus": "5.155",
                "kadin_nufus": "4.943",
                "yuzolcumu": "1.173"
              },
              {
                "district": "Felahiye",
                "nufus": "6.602",
                "erkek_nufus": "3.394",
                "kadin_nufus": "3.208",
                "yuzolcumu": "444"
              },
              {
                "district": "Akkışla",
                "nufus": "6.429",
                "erkek_nufus": "3.252",
                "kadin_nufus": "3.177",
                "yuzolcumu": "371"
              },
              {
                "district": "Özvatan",
                "nufus": "5.413",
                "erkek_nufus": "2.788",
                "kadin_nufus": "2.625",
                "yuzolcumu": "270"
              }
            ],
            "kisa_bilgi": "İç Anadolu'nun güney bölümü ile Toros Dağlarının birbirine yaklaştığı, Orta Kızılırmak bölümünde yer alan Kayseri, değişik uygarlıkların kalıntılarına ev sahipliği yapan önemli bir ilimizdir."
          },
          {
            "city": "Kırklareli",
            "plaka_kodu": "39",
            "alan_kodu": "288",
            "nufus": "360.860",
            "bolge": "Marmara",
            "yuzolcumu": "6.459",
            "erkek_nufus_yuzde": "51,32%",
            "erkek_nufus": "185.181",
            "kadin_nufus_yuzde": "48,68%",
            "kadin_nufus": "175.679",
            "nufus_yuzdesi_genel": "0,44%",
            "ilceler": [
              {
                "district": "Lüleburgaz",
                "nufus": "148.037",
                "erkek_nufus": "74.838",
                "kadin_nufus": "73.199",
                "yuzolcumu": "1.016"
              },
              {
                "district": "Merkez",
                "nufus": "102.909",
                "erkek_nufus": "53.217",
                "kadin_nufus": "49.692",
                "yuzolcumu": "1.623"
              },
              {
                "district": "Babaeski",
                "nufus": "48.391",
                "erkek_nufus": "25.018",
                "kadin_nufus": "23.373",
                "yuzolcumu": "670"
              },
              {
                "district": "Vize",
                "nufus": "28.122",
                "erkek_nufus": "14.348",
                "kadin_nufus": "13.774",
                "yuzolcumu": "1.090"
              },
              {
                "district": "Pınarhisar",
                "nufus": "18.375",
                "erkek_nufus": "9.745",
                "kadin_nufus": "8.630",
                "yuzolcumu": "518"
              },
              {
                "district": "Demirköy",
                "nufus": "9.093",
                "erkek_nufus": "4.983",
                "kadin_nufus": "4.110",
                "yuzolcumu": "893"
              },
              {
                "district": "Pehlivanköy",
                "nufus": "3.565",
                "erkek_nufus": "1.799",
                "kadin_nufus": "1.766",
                "yuzolcumu": "104"
              },
              {
                "district": "Kofçaz",
                "nufus": "2.368",
                "erkek_nufus": "1.233",
                "kadin_nufus": "1.135",
                "yuzolcumu": "545"
              }
            ],
            "kisa_bilgi": "Marmara bölgesinin Trakya kesiminde bulunan Kırklareli, sınır ili konumunda olup, Bulgaristan’la komşudur. 1924 yılından bu yana il statüsünde bulunan Kırklareli, Anadolu ve Avrupa arasında geçiş konumunda bulunduğu için pek çok arkeolojik, doğal ve tarihi sit alanlarına sahip."
          },
          {
            "city": "Kırşehir",
            "plaka_kodu": "40",
            "alan_kodu": "386",
            "nufus": "241.868",
            "bolge": "İç Anadolu",
            "yuzolcumu": "6.584",
            "erkek_nufus_yuzde": "49,73%",
            "erkek_nufus": "120.278",
            "kadin_nufus_yuzde": "50,27%",
            "kadin_nufus": "121.590",
            "nufus_yuzdesi_genel": "0,29%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "153.511",
                "erkek_nufus": "76.383",
                "kadin_nufus": "77.128",
                "yuzolcumu": "1.719"
              },
              {
                "district": "Kaman",
                "nufus": "37.223",
                "erkek_nufus": "18.641",
                "kadin_nufus": "18.582",
                "yuzolcumu": "1.284"
              },
              {
                "district": "Mucur",
                "nufus": "18.763",
                "erkek_nufus": "9.284",
                "kadin_nufus": "9.479",
                "yuzolcumu": "992"
              },
              {
                "district": "Çiçekdağı",
                "nufus": "14.735",
                "erkek_nufus": "7.141",
                "kadin_nufus": "7.594",
                "yuzolcumu": "891"
              },
              {
                "district": "Akpınar",
                "nufus": "7.826",
                "erkek_nufus": "3.893",
                "kadin_nufus": "3.933",
                "yuzolcumu": "582"
              },
              {
                "district": "Boztepe",
                "nufus": "5.581",
                "erkek_nufus": "2.829",
                "kadin_nufus": "2.752",
                "yuzolcumu": "747"
              },
              {
                "district": "Akçakent",
                "nufus": "4.229",
                "erkek_nufus": "2.107",
                "kadin_nufus": "2.122",
                "yuzolcumu": "370"
              }
            ],
            "kisa_bilgi": "İç Anadolu bölgemizdeki Hititler dönemine dek uzanan tarihi ile Kırşehir aynı zamanda, Ahi Evran tarafından kurulan ve Anadolu’dan Orta Asya’ya dek esnaf ve zanaatkarları tek çatı altında toplamayı amaçlayan Ahilik Teşkilatı’nın da merkezi konumundadır."
          },
          {
            "city": "Kocaeli",
            "plaka_kodu": "41",
            "alan_kodu": "262",
            "nufus": "1.906.391",
            "bolge": "Marmara",
            "yuzolcumu": "3.397",
            "erkek_nufus_yuzde": "50,53%",
            "erkek_nufus": "963.326",
            "kadin_nufus_yuzde": "49,47%",
            "kadin_nufus": "943.065",
            "nufus_yuzdesi_genel": "2,47%",
            "ilceler": [
              {
                "district": "Gebze",
                "nufus": "371.000",
                "erkek_nufus": "188.436",
                "kadin_nufus": "182.564",
                "yuzolcumu": "418"
              },
              {
                "district": "İzmit",
                "nufus": "363.416",
                "erkek_nufus": "180.087",
                "kadin_nufus": "183.329",
                "yuzolcumu": "480"
              },
              {
                "district": "Darıca",
                "nufus": "201.468",
                "erkek_nufus": "102.015",
                "kadin_nufus": "99.453",
                "yuzolcumu": "23"
              },
              {
                "district": "Körfez",
                "nufus": "165.503",
                "erkek_nufus": "83.456",
                "kadin_nufus": "82.047",
                "yuzolcumu": "301"
              },
              {
                "district": "Gölcük",
                "nufus": "162.584",
                "erkek_nufus": "82.202",
                "kadin_nufus": "80.382",
                "yuzolcumu": "217"
              },
              {
                "district": "Derince",
                "nufus": "140.982",
                "erkek_nufus": "70.679",
                "kadin_nufus": "70.303",
                "yuzolcumu": "198"
              },
              {
                "district": "Çayırova",
                "nufus": "129.655",
                "erkek_nufus": "66.156",
                "kadin_nufus": "63.499",
                "yuzolcumu": "23"
              },
              {
                "district": "Kartepe",
                "nufus": "118.066",
                "erkek_nufus": "59.930",
                "kadin_nufus": "58.136",
                "yuzolcumu": "301"
              },
              {
                "district": "Başiskele",
                "nufus": "97.817",
                "erkek_nufus": "49.329",
                "kadin_nufus": "48.488",
                "yuzolcumu": "209"
              },
              {
                "district": "Karamürsel",
                "nufus": "56.604",
                "erkek_nufus": "28.135",
                "kadin_nufus": "28.469",
                "yuzolcumu": "262"
              },
              {
                "district": "Kandıra",
                "nufus": "51.348",
                "erkek_nufus": "28.301",
                "kadin_nufus": "23.047",
                "yuzolcumu": "840"
              },
              {
                "district": "Dilovası",
                "nufus": "47.948",
                "erkek_nufus": "24.600",
                "kadin_nufus": "23.348",
                "yuzolcumu": "125"
              }
            ],
            "kisa_bilgi": "Türkiye'nin en kalabalık illerinden, 1993 yılında büyükşehir unvanını alan, Marmara Bölgesi'nin Çatalca-Kocaeli Bölümü'nde bulunan Kocaeli, ülkenin sanayi sektöründe çok önemli bir konuma sahiptir."
          },
          {
            "city": "Konya",
            "plaka_kodu": "42",
            "alan_kodu": "332",
            "nufus": "2.205.609",
            "bolge": "İç Anadolu",
            "yuzolcumu": "40.838",
            "erkek_nufus_yuzde": "49,62%",
            "erkek_nufus": "1.094.441",
            "kadin_nufus_yuzde": "50,38%",
            "kadin_nufus": "1.111.168",
            "nufus_yuzdesi_genel": "2,69%",
            "ilceler": [
              {
                "district": "Selçuklu",
                "nufus": "648.850",
                "erkek_nufus": "318.588",
                "kadin_nufus": "330.262",
                "yuzolcumu": "1.931"
              },
              {
                "district": "Meram",
                "nufus": "342.315",
                "erkek_nufus": "169.973",
                "kadin_nufus": "172.342",
                "yuzolcumu": "1.822"
              },
              {
                "district": "Karatay",
                "nufus": "323.659",
                "erkek_nufus": "162.432",
                "kadin_nufus": "161.227",
                "yuzolcumu": "2.832"
              },
              {
                "district": "Ereğli",
                "nufus": "145.389",
                "erkek_nufus": "72.818",
                "kadin_nufus": "72.571",
                "yuzolcumu": "2.214"
              },
              {
                "district": "Akşehir",
                "nufus": "93.233",
                "erkek_nufus": "45.557",
                "kadin_nufus": "47.676",
                "yuzolcumu": "895"
              },
              {
                "district": "Beyşehir",
                "nufus": "73.768",
                "erkek_nufus": "36.755",
                "kadin_nufus": "37.013",
                "yuzolcumu": "2.054"
              },
              {
                "district": "Çumra",
                "nufus": "66.794",
                "erkek_nufus": "33.469",
                "kadin_nufus": "33.325",
                "yuzolcumu": "2.089"
              },
              {
                "district": "Seydişehir",
                "nufus": "64.687",
                "erkek_nufus": "32.515",
                "kadin_nufus": "32.172",
                "yuzolcumu": "1.458"
              },
              {
                "district": "Ilgın",
                "nufus": "54.622",
                "erkek_nufus": "26.963",
                "kadin_nufus": "27.659",
                "yuzolcumu": "1636"
              },
              {
                "district": "Cihanbeyli",
                "nufus": "52.525",
                "erkek_nufus": "26.148",
                "kadin_nufus": "26.377",
                "yuzolcumu": "3.702"
              },
              {
                "district": "Kulu",
                "nufus": "50.667",
                "erkek_nufus": "25.171",
                "kadin_nufus": "25.496",
                "yuzolcumu": "2.234"
              },
              {
                "district": "Karapınar",
                "nufus": "49.766",
                "erkek_nufus": "25.146",
                "kadin_nufus": "24.620",
                "yuzolcumu": "2.623"
              },
              {
                "district": "Kadınhanı",
                "nufus": "33.036",
                "erkek_nufus": "16.658",
                "kadin_nufus": "16.378",
                "yuzolcumu": "1.568"
              },
              {
                "district": "Sarayönü",
                "nufus": "26.875",
                "erkek_nufus": "13.389",
                "kadin_nufus": "13.486",
                "yuzolcumu": "1.620"
              },
              {
                "district": "Bozkır",
                "nufus": "26.287",
                "erkek_nufus": "12.818",
                "kadin_nufus": "13.469",
                "yuzolcumu": "1.105"
              },
              {
                "district": "Yunak",
                "nufus": "23.093",
                "erkek_nufus": "11.536",
                "kadin_nufus": "11.557",
                "yuzolcumu": "2.101"
              },
              {
                "district": "Hüyük",
                "nufus": "16.073",
                "erkek_nufus": "7.817",
                "kadin_nufus": "8.256",
                "yuzolcumu": "443"
              },
              {
                "district": "Doğanhisar",
                "nufus": "16.029",
                "erkek_nufus": "7.724",
                "kadin_nufus": "8.305",
                "yuzolcumu": "482"
              },
              {
                "district": "Altınekin",
                "nufus": "14.548",
                "erkek_nufus": "7.351",
                "kadin_nufus": "7.197",
                "yuzolcumu": "1.312"
              },
              {
                "district": "Hadim",
                "nufus": "11.970",
                "erkek_nufus": "5.988",
                "kadin_nufus": "5.982",
                "yuzolcumu": "1.165"
              },
              {
                "district": "Çeltik",
                "nufus": "10.071",
                "erkek_nufus": "5.104",
                "kadin_nufus": "4.967",
                "yuzolcumu": "640"
              },
              {
                "district": "Güneysınır",
                "nufus": "9.458",
                "erkek_nufus": "4.770",
                "kadin_nufus": "4.688",
                "yuzolcumu": "482"
              },
              {
                "district": "Emirgazi",
                "nufus": "8.949",
                "erkek_nufus": "4.505",
                "kadin_nufus": "4.444",
                "yuzolcumu": "798"
              },
              {
                "district": "Taşkent",
                "nufus": "7.635",
                "erkek_nufus": "3.824",
                "kadin_nufus": "3.811",
                "yuzolcumu": "457"
              },
              {
                "district": "Tuzlukçu",
                "nufus": "7.280",
                "erkek_nufus": "3.549",
                "kadin_nufus": "3.731",
                "yuzolcumu": "704"
              },
              {
                "district": "Derebucak",
                "nufus": "6.473",
                "erkek_nufus": "3.274",
                "kadin_nufus": "3.199",
                "yuzolcumu": "451"
              },
              {
                "district": "Akören",
                "nufus": "5.879",
                "erkek_nufus": "2.898",
                "kadin_nufus": "2.981",
                "yuzolcumu": "640"
              },
              {
                "district": "Ahırlı",
                "nufus": "5.084",
                "erkek_nufus": "2.498",
                "kadin_nufus": "2.586",
                "yuzolcumu": "325"
              },
              {
                "district": "Derbent",
                "nufus": "4.455",
                "erkek_nufus": "2.168",
                "kadin_nufus": "2.287",
                "yuzolcumu": "359"
              },
              {
                "district": "Halkapınar",
                "nufus": "4.354",
                "erkek_nufus": "2.154",
                "kadin_nufus": "2.200",
                "yuzolcumu": "605"
              },
              {
                "district": "Yalıhüyük",
                "nufus": "1.785",
                "erkek_nufus": "881",
                "kadin_nufus": "904",
                "yuzolcumu": "94"
              }
            ],
            "kisa_bilgi": "M.Ö. 7. bin yıldan beri yerleşim yeri olan ve çeşitli kültürlere, medeniyetlere evsahipliği yapan Konya, yüzölçüm olarak ülkemizin en büyük ilidir."
          },
          {
            "city": "Kütahya",
            "plaka_kodu": "43",
            "alan_kodu": "274",
            "nufus": "577.941",
            "bolge": "İç Anadolu",
            "yuzolcumu": "577.941",
            "erkek_nufus_yuzde": "49,26%",
            "erkek_nufus": "284.675",
            "kadin_nufus_yuzde": "50,74%",
            "kadin_nufus": "293.266",
            "nufus_yuzdesi_genel": "0,70%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "266.784",
                "erkek_nufus": "131.464",
                "kadin_nufus": "135.320",
                "yuzolcumu": "2.470"
              },
              {
                "district": "Tavşanlı",
                "nufus": "101.903",
                "erkek_nufus": "50.556",
                "kadin_nufus": "51.347",
                "yuzolcumu": "1.899"
              },
              {
                "district": "Simav",
                "nufus": "64.015",
                "erkek_nufus": "31.387",
                "kadin_nufus": "32.628",
                "yuzolcumu": "1.193"
              },
              {
                "district": "Gediz",
                "nufus": "49.827",
                "erkek_nufus": "24.474",
                "kadin_nufus": "25.353",
                "yuzolcumu": ""
              },
              {
                "district": "Emet",
                "nufus": "19.986",
                "erkek_nufus": "9.664",
                "kadin_nufus": "10.322",
                "yuzolcumu": "951"
              },
              {
                "district": "Altıntaş",
                "nufus": "16.803",
                "erkek_nufus": "8.419",
                "kadin_nufus": "8.384",
                "yuzolcumu": "941"
              },
              {
                "district": "Domaniç",
                "nufus": "15.181",
                "erkek_nufus": "7.526",
                "kadin_nufus": "7.655",
                "yuzolcumu": "539"
              },
              {
                "district": "Hisarcık",
                "nufus": "12.531",
                "erkek_nufus": "6.069",
                "kadin_nufus": "6.462",
                "yuzolcumu": "370"
              },
              {
                "district": "Aslanapa",
                "nufus": "9.224",
                "erkek_nufus": "4.551",
                "kadin_nufus": "4.673",
                "yuzolcumu": "731"
              },
              {
                "district": "Çavdarhisar",
                "nufus": "6.800",
                "erkek_nufus": "3.291",
                "kadin_nufus": "3.509",
                "yuzolcumu": "429"
              },
              {
                "district": "Şaphane",
                "nufus": "6.366",
                "erkek_nufus": "3.066",
                "kadin_nufus": "3.300",
                "yuzolcumu": "229"
              },
              {
                "district": "Pazarlar",
                "nufus": "5.522",
                "erkek_nufus": "2.686",
                "kadin_nufus": "2.836",
                "yuzolcumu": "115"
              },
              {
                "district": "Dumlupınar",
                "nufus": "2.999",
                "erkek_nufus": "1.522",
                "kadin_nufus": "1.477",
                "yuzolcumu": "250"
              }
            ],
            "kisa_bilgi": "Ülkemizin İç Batı Anadolu bölümünde bulunan, porselenleri, tertemiz doğası, maden yatakları ile önemli bir ilimiz olan Kütahya, jeolojik oluşum açısından da oldukça eski dönemlere dek uzanır."
          },
          {
            "city": "Malatya",
            "plaka_kodu": "44",
            "alan_kodu": "422",
            "nufus": "797.036",
            "bolge": "Doğu Anadolu",
            "yuzolcumu": "12259",
            "erkek_nufus_yuzde": "49,79%",
            "erkek_nufus": "396.877",
            "kadin_nufus_yuzde": "50,21%",
            "kadin_nufus": "400.159",
            "nufus_yuzdesi_genel": "0,97%",
            "ilceler": [
              {
                "district": "Yeşilyurt",
                "nufus": "304.839",
                "erkek_nufus": "151.776",
                "kadin_nufus": "153.063",
                "yuzolcumu": "953"
              },
              {
                "district": "Battalgazi",
                "nufus": "295.821",
                "erkek_nufus": "145.252",
                "kadin_nufus": "150.569",
                "yuzolcumu": "947"
              },
              {
                "district": "Doğanşehir",
                "nufus": "39.454",
                "erkek_nufus": "20.103",
                "kadin_nufus": "19.351",
                "yuzolcumu": "1.364"
              },
              {
                "district": "Akçadağ",
                "nufus": "35.359",
                "erkek_nufus": "18.475",
                "kadin_nufus": "16.884",
                "yuzolcumu": "1.118"
              },
              {
                "district": "Darende",
                "nufus": "29.045",
                "erkek_nufus": "14.631",
                "kadin_nufus": "14.414",
                "yuzolcumu": "1.482"
              },
              {
                "district": "Hekimhan",
                "nufus": "22.867",
                "erkek_nufus": "11.593",
                "kadin_nufus": "11.274",
                "yuzolcumu": "1.514"
              },
              {
                "district": "Yazıhan",
                "nufus": "16.673",
                "erkek_nufus": "8.530",
                "kadin_nufus": "8.143",
                "yuzolcumu": "653"
              },
              {
                "district": "Pütürge",
                "nufus": "15.049",
                "erkek_nufus": "7.363",
                "kadin_nufus": "7.686",
                "yuzolcumu": "1.086"
              },
              {
                "district": "Arapgir",
                "nufus": "10.868",
                "erkek_nufus": "5.542",
                "kadin_nufus": "5.326",
                "yuzolcumu": "987"
              },
              {
                "district": "Kuluncak",
                "nufus": "8.384",
                "erkek_nufus": "4.222",
                "kadin_nufus": "4.162",
                "yuzolcumu": "645"
              },
              {
                "district": "Arguvan",
                "nufus": "8.157",
                "erkek_nufus": "4.144",
                "kadin_nufus": "4.013",
                "yuzolcumu": "1.096"
              },
              {
                "district": "Kale",
                "nufus": "6.100",
                "erkek_nufus": "3.009",
                "kadin_nufus": "3.091",
                "yuzolcumu": "237"
              },
              {
                "district": "Doğanyol",
                "nufus": "4.420",
                "erkek_nufus": "2.237",
                "kadin_nufus": "2.183",
                "yuzolcumu": "177"
              }
            ],
            "kisa_bilgi": "Doğu Anadolu Bölgesi'nin Yukarı Fırat Bölümü'nde yer alan Malatya, kayısılarıyla dünya çapında bir üne sahiptir. Bu vesileyle her sene Malatya Fuarı ve Uluslararası Kültür, Sanat ve Kayısı Festivali yapılmaktadır."
          },
          {
            "city": "Manisa",
            "plaka_kodu": "45",
            "alan_kodu": "236",
            "nufus": "1.429.643",
            "bolge": "Ege",
            "yuzolcumu": "13.339",
            "erkek_nufus_yuzde": "50,39%",
            "erkek_nufus": "720.337",
            "kadin_nufus_yuzde": "49,61%",
            "kadin_nufus": "709.306",
            "nufus_yuzdesi_genel": "1,74%",
            "ilceler": [
              {
                "district": "Yunusemre",
                "nufus": "239.324",
                "erkek_nufus": "122.116",
                "kadin_nufus": "117.208",
                "yuzolcumu": "823"
              },
              {
                "district": "Akhisar",
                "nufus": "171.381",
                "erkek_nufus": "86.195",
                "kadin_nufus": "85.186",
                "yuzolcumu": "1.645"
              },
              {
                "district": "Şehzadeler",
                "nufus": "171.116",
                "erkek_nufus": "87.744",
                "kadin_nufus": "83.372",
                "yuzolcumu": "515"
              },
              {
                "district": "Turgutlu",
                "nufus": "163.223",
                "erkek_nufus": "82.133",
                "kadin_nufus": "81.090",
                "yuzolcumu": "549"
              },
              {
                "district": "Salihli",
                "nufus": "161.562",
                "erkek_nufus": "80.022",
                "kadin_nufus": "81.540",
                "yuzolcumu": "1.359"
              },
              {
                "district": "Soma",
                "nufus": "108.981",
                "erkek_nufus": "54.832",
                "kadin_nufus": "54.149",
                "yuzolcumu": "820"
              },
              {
                "district": "Alaşehir",
                "nufus": "104.507",
                "erkek_nufus": "53.824",
                "kadin_nufus": "50.683",
                "yuzolcumu": "971"
              },
              {
                "district": "Saruhanlı",
                "nufus": "54.660",
                "erkek_nufus": "27.388",
                "kadin_nufus": "27.272",
                "yuzolcumu": "771"
              },
              {
                "district": "Kula",
                "nufus": "44.410",
                "erkek_nufus": "21.910",
                "kadin_nufus": "22.500",
                "yuzolcumu": "981"
              },
              {
                "district": "Demirci",
                "nufus": "41.135",
                "erkek_nufus": "19.424",
                "kadin_nufus": "21.711",
                "yuzolcumu": "1.321"
              },
              {
                "district": "Kırıkağaç",
                "nufus": "39.790",
                "erkek_nufus": "20.418",
                "kadin_nufus": "19.372",
                "yuzolcumu": "541"
              },
              {
                "district": "Sarıgöl",
                "nufus": "35.957",
                "erkek_nufus": "17.831",
                "kadin_nufus": "18.126",
                "yuzolcumu": "432"
              },
              {
                "district": "Gördes",
                "nufus": "28.182",
                "erkek_nufus": "13.832",
                "kadin_nufus": "14.350",
                "yuzolcumu": "902"
              },
              {
                "district": "Selendi",
                "nufus": "20.291",
                "erkek_nufus": "10.136",
                "kadin_nufus": "10.155",
                "yuzolcumu": "726"
              },
              {
                "district": "Ahmetli",
                "nufus": "16.530",
                "erkek_nufus": "8.299",
                "kadin_nufus": "8.231",
                "yuzolcumu": "227"
              },
              {
                "district": "Gölmarmara",
                "nufus": "15.247",
                "erkek_nufus": "7.660",
                "kadin_nufus": "7.587",
                "yuzolcumu": "310"
              },
              {
                "district": "Köprübaşı",
                "nufus": "13.347",
                "erkek_nufus": "6.573",
                "kadin_nufus": "6.774",
                "yuzolcumu": "447"
              }
            ],
            "kisa_bilgi": "Ege bölgesinin ortasında bulunan, 2012 yılında büyükşehir unvanını alan Manisa, bölgenin İzmir'den sonra ikinci büyük sanayi ve ticaret merkezidir. "
          },
          {
            "city": "Kahramanmaraş",
            "plaka_kodu": "46",
            "alan_kodu": "344",
            "nufus": "1.144.851",
            "bolge": "Güneydoğu Anadolu",
            "yuzolcumu": "14.520",
            "erkek_nufus_yuzde": "50,70%",
            "erkek_nufus": "580.410",
            "kadin_nufus_yuzde": "49,30%",
            "kadin_nufus": "564.441",
            "nufus_yuzdesi_genel": "1,40%",
            "ilceler": [
              {
                "district": "Onikişubat",
                "nufus": "418.379",
                "erkek_nufus": "209.407",
                "kadin_nufus": "208.972",
                "yuzolcumu": "2.429"
              },
              {
                "district": "Dulkadiroğlu",
                "nufus": "222.938",
                "erkek_nufus": "114.173",
                "kadin_nufus": "108.765",
                "yuzolcumu": "1.176"
              },
              {
                "district": "Elbistan",
                "nufus": "142.548",
                "erkek_nufus": "72.886",
                "kadin_nufus": "69.662",
                "yuzolcumu": "2.201"
              },
              {
                "district": "Afşin",
                "nufus": "81.423",
                "erkek_nufus": "41.297",
                "kadin_nufus": "40.126",
                "yuzolcumu": "1.502"
              },
              {
                "district": "Türkoğlu",
                "nufus": "73.770",
                "erkek_nufus": "37.971",
                "kadin_nufus": "",
                "yuzolcumu": "660"
              },
              {
                "district": "Pazarcık",
                "nufus": "68.838",
                "erkek_nufus": "34.635",
                "kadin_nufus": "34.203",
                "yuzolcumu": "1.253"
              },
              {
                "district": "Göksun",
                "nufus": "55.985",
                "erkek_nufus": "28.378",
                "kadin_nufus": "27.607",
                "yuzolcumu": "1.942"
              },
              {
                "district": "Andırın",
                "nufus": "33.193",
                "erkek_nufus": "16.929",
                "kadin_nufus": "16.264",
                "yuzolcumu": "1.202"
              },
              {
                "district": "Çağlayancerit",
                "nufus": "23.346",
                "erkek_nufus": "11.976",
                "kadin_nufus": "11.370",
                "yuzolcumu": "470"
              },
              {
                "district": "Nurhak",
                "nufus": "12.592",
                "erkek_nufus": "6.514",
                "kadin_nufus": "6.078",
                "yuzolcumu": "1.028"
              },
              {
                "district": "Ekinözü",
                "nufus": "11.839",
                "erkek_nufus": "6.244",
                "kadin_nufus": "5.595",
                "yuzolcumu": "656"
              }
            ],
            "kisa_bilgi": "2012 yılında Büyükşehir unvanı alan, dövme dondurmasıyla dünyaca meşhur Kahramanmaraş’a, Kurtuluş Savaşı’ndaki direnişi nedeniyle İstiklal Madalyası verilmiştir. "
          },
          {
            "city": "Mardin",
            "plaka_kodu": "47",
            "alan_kodu": "482",
            "nufus": "829.195",
            "bolge": "Güneydoğu Anadolu",
            "yuzolcumu": "8.780",
            "erkek_nufus_yuzde": "50,29%",
            "erkek_nufus": "417.000",
            "kadin_nufus_yuzde": "49,71%",
            "kadin_nufus": "412.195",
            "nufus_yuzdesi_genel": "1,01%",
            "ilceler": [
              {
                "district": "Kızıltepe",
                "nufus": "252.656",
                "erkek_nufus": "127.256",
                "kadin_nufus": "125.400",
                "yuzolcumu": "1.236"
              },
              {
                "district": "Artuklu",
                "nufus": "174.731",
                "erkek_nufus": "88.521",
                "kadin_nufus": "86.210",
                "yuzolcumu": "885"
              },
              {
                "district": "Midyat",
                "nufus": "113.367",
                "erkek_nufus": "56.850",
                "kadin_nufus": "56.517",
                "yuzolcumu": "1.241"
              },
              {
                "district": "Nusaybin",
                "nufus": "105.856",
                "erkek_nufus": "52.634",
                "kadin_nufus": "53.222",
                "yuzolcumu": "1.079"
              },
              {
                "district": "Derik",
                "nufus": "61.830",
                "erkek_nufus": "30.837",
                "kadin_nufus": "30.993",
                "yuzolcumu": "1.381"
              },
              {
                "district": "Mazıdağı",
                "nufus": "35.757",
                "erkek_nufus": "18.161",
                "kadin_nufus": "17.596",
                "yuzolcumu": "850"
              },
              {
                "district": "Dargeçit",
                "nufus": "28.555",
                "erkek_nufus": "14.222",
                "kadin_nufus": "14.333",
                "yuzolcumu": "519"
              },
              {
                "district": "Savur",
                "nufus": "27.304",
                "erkek_nufus": "13.654",
                "kadin_nufus": "13.650",
                "yuzolcumu": "962"
              },
              {
                "district": "Yeşilli",
                "nufus": "14.906",
                "erkek_nufus": "7.616",
                "kadin_nufus": "7.290",
                "yuzolcumu": "168"
              },
              {
                "district": "Ömerli",
                "nufus": "14.233",
                "erkek_nufus": "7.249",
                "kadin_nufus": "6.984",
                "yuzolcumu": "458"
              }
            ],
            "kisa_bilgi": "Suriye ile sınır komşusu olan, İpek Yolu üzerinde yer alan Mardin, Yukarı Mezopotamya’nın en eski şehirlerinden biridir ve tarih boyunca çeşitli kültürlere ev sahipliği yapmıştır."
          },
          {
            "city": "Muğla",
            "plaka_kodu": "48",
            "alan_kodu": "252",
            "nufus": "967.487",
            "bolge": "Ege",
            "yuzolcumu": "12654",
            "erkek_nufus_yuzde": "50,97%",
            "erkek_nufus": "493.140",
            "kadin_nufus_yuzde": "49,03%",
            "kadin_nufus": "474.347",
            "nufus_yuzdesi_genel": "1,18%",
            "ilceler": [
              {
                "district": "Bodrum",
                "nufus": "171.850",
                "erkek_nufus": "87.350",
                "kadin_nufus": "84.500",
                "yuzolcumu": "650"
              },
              {
                "district": "Fethiye",
                "nufus": "157.745",
                "erkek_nufus": "79.319",
                "kadin_nufus": "78.426",
                "yuzolcumu": "875"
              },
              {
                "district": "Milas",
                "nufus": "139.446",
                "erkek_nufus": "70.645",
                "kadin_nufus": "68.801",
                "yuzolcumu": "2.067"
              },
              {
                "district": "Menteşe",
                "nufus": "112.447",
                "erkek_nufus": "56.780",
                "kadin_nufus": "55.667",
                "yuzolcumu": "1.659"
              },
              {
                "district": "Marmaris",
                "nufus": "94.247",
                "erkek_nufus": "49.805",
                "kadin_nufus": "44.442",
                "yuzolcumu": "906"
              },
              {
                "district": "Seydikemer",
                "nufus": "62.246",
                "erkek_nufus": "32.346",
                "kadin_nufus": "29.900",
                "yuzolcumu": "2.208"
              },
              {
                "district": "Ortaca",
                "nufus": "48.373",
                "erkek_nufus": "24.216",
                "kadin_nufus": "24.157",
                "yuzolcumu": "285"
              },
              {
                "district": "Yatağan",
                "nufus": "44.940",
                "erkek_nufus": "22.622",
                "kadin_nufus": "22.318",
                "yuzolcumu": "851"
              },
              {
                "district": "Dalaman",
                "nufus": "41.351",
                "erkek_nufus": "22.102",
                "kadin_nufus": "19.249",
                "yuzolcumu": "608"
              },
              {
                "district": "Köyceğiz",
                "nufus": "36.389",
                "erkek_nufus": "18.328",
                "kadin_nufus": "18.061",
                "yuzolcumu": "1.329"
              },
              {
                "district": "Ula",
                "nufus": "25.294",
                "erkek_nufus": "12.726",
                "kadin_nufus": "12.568",
                "yuzolcumu": "479"
              },
              {
                "district": "Datça",
                "nufus": "22.261",
                "erkek_nufus": "11.398",
                "kadin_nufus": "10.863",
                "yuzolcumu": "436"
              },
              {
                "district": "Kavaklıdere",
                "nufus": "10.898",
                "erkek_nufus": "5.503",
                "kadin_nufus": "5.395",
                "yuzolcumu": "302"
              }
            ],
            "kisa_bilgi": "Fethiye, Bodrum, Marmaris gibi turistik beldeleriyle meşhur ilin topraklarının büyük kısmı Ege’de, küçük bir kısmı ise Akdeniz’de yer alıyor."
          },
          {
            "city": "Muş",
            "plaka_kodu": "49",
            "alan_kodu": "436",
            "nufus": "407.992",
            "bolge": "Doğu Anadolu",
            "yuzolcumu": "8.650",
            "erkek_nufus_yuzde": "51,09%",
            "erkek_nufus": "208.431",
            "kadin_nufus_yuzde": "48,91%",
            "kadin_nufus": "199.561",
            "nufus_yuzdesi_genel": "0,50%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "193.394",
                "erkek_nufus": "98.894",
                "kadin_nufus": "94.500",
                "yuzolcumu": "2.750"
              },
              {
                "district": "Bulanık",
                "nufus": "80.540",
                "erkek_nufus": "41.232",
                "kadin_nufus": "39.308",
                "yuzolcumu": "1.948"
              },
              {
                "district": "Malazgirt",
                "nufus": "51.323",
                "erkek_nufus": "26.239",
                "kadin_nufus": "25.084",
                "yuzolcumu": "1.595"
              },
              {
                "district": "Varto",
                "nufus": "31.169",
                "erkek_nufus": "15.807",
                "kadin_nufus": "15.362",
                "yuzolcumu": "1.318"
              },
              {
                "district": "Korkut",
                "nufus": "25.817",
                "erkek_nufus": "13.207",
                "kadin_nufus": "12.610",
                "yuzolcumu": "775"
              },
              {
                "district": "Hasköy",
                "nufus": "25.749",
                "erkek_nufus": "13.052",
                "kadin_nufus": "12.697",
                "yuzolcumu": "264"
              }
            ],
            "kisa_bilgi": "Doğu Anadolu bölgesinde bulunan ilimiz, M.Ö. 2000’e uzanan tarihi, kendine özgü lalesi ve merkeze 8 km mesafede bulunan Güzeltepe Kayak Merkezi ile dikkat çekiyor."
          },
          {
            "city": "Nevşehir",
            "plaka_kodu": "50",
            "alan_kodu": "384",
            "nufus": "298.339",
            "bolge": "İç Anadolu",
            "yuzolcumu": "5.485",
            "erkek_nufus_yuzde": "49,42%",
            "erkek_nufus": "147.438",
            "kadin_nufus_yuzde": "50,58%",
            "kadin_nufus": "150.901",
            "nufus_yuzdesi_genel": "0,36%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "143.194",
                "erkek_nufus": "70.716",
                "kadin_nufus": "72.478",
                "yuzolcumu": "574"
              },
              {
                "district": "Ürgüp",
                "nufus": "35.352",
                "erkek_nufus": "17.178",
                "kadin_nufus": "18.174",
                "yuzolcumu": "587"
              },
              {
                "district": "Avanos",
                "nufus": "32.618",
                "erkek_nufus": "16.197",
                "kadin_nufus": "16.421",
                "yuzolcumu": "995"
              },
              {
                "district": "Gülşehir",
                "nufus": "21.771",
                "erkek_nufus": "10.580",
                "kadin_nufus": "11.191",
                "yuzolcumu": "879"
              },
              {
                "district": "Derinkuyu",
                "nufus": "20.786",
                "erkek_nufus": "10.471",
                "kadin_nufus": "10.315",
                "yuzolcumu": "473"
              },
              {
                "district": "Acıgöl",
                "nufus": "19.561",
                "erkek_nufus": "9.938",
                "kadin_nufus": "9.623",
                "yuzolcumu": "456"
              },
              {
                "district": "Kozaklı",
                "nufus": "13.570",
                "erkek_nufus": "6.721",
                "kadin_nufus": "6.849",
                "yuzolcumu": "772"
              },
              {
                "district": "Hacıbektaş",
                "nufus": "11.487",
                "erkek_nufus": "5.637",
                "kadin_nufus": "5.850",
                "yuzolcumu": "750"
              }
            ],
            "kisa_bilgi": "Nevşehir, ülkemizin en önemli turistik merkezlerinden biri olan, peri bacaları, muhteşem güzelliği ve M.Ö. 3000’lere ulaşan tarihiyle UNESCO Dünya Mirası Listesinde yer alan Kapadokya’nın merkezinde bulunuyor."
          },
          {
            "city": "Niğde",
            "plaka_kodu": "51",
            "alan_kodu": "388",
            "nufus": "364.707",
            "bolge": "İç Anadolu",
            "yuzolcumu": "7.234",
            "erkek_nufus_yuzde": "50,21%",
            "erkek_nufus": "183.109",
            "kadin_nufus_yuzde": "49,79%",
            "kadin_nufus": "181.598",
            "nufus_yuzdesi_genel": "0,44%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "224.289",
                "erkek_nufus": "112.384",
                "kadin_nufus": "111.905",
                "yuzolcumu": "2.223"
              },
              {
                "district": "Bor",
                "nufus": "60.335",
                "erkek_nufus": "29.937",
                "kadin_nufus": "30.398",
                "yuzolcumu": "1.522"
              },
              {
                "district": "Çiftlik",
                "nufus": "28.168",
                "erkek_nufus": "14.517",
                "kadin_nufus": "13.651",
                "yuzolcumu": "400"
              },
              {
                "district": "Ulukışla",
                "nufus": "23.252",
                "erkek_nufus": "11.737",
                "kadin_nufus": "11.515",
                "yuzolcumu": "1.375"
              },
              {
                "district": "Altunhisar",
                "nufus": "15.463",
                "erkek_nufus": "8.018",
                "kadin_nufus": "7.445",
                "yuzolcumu": "552"
              },
              {
                "district": "Çamardı",
                "nufus": "13.200",
                "erkek_nufus": "6.516",
                "kadin_nufus": "6.684",
                "yuzolcumu": "1.163"
              }
            ],
            "kisa_bilgi": "Kapadokya bölgesinin bu güzel turistik şehri, üretimine 1962 yılında başlanan gazozu ve elmasıyla meşhur olmasının yanında, pek çok medeniyetin hüküm sürdüğü 6000 yıllık koca bir tarihi zenginliğe sahip. "
          },
          {
            "city": "Ordu",
            "plaka_kodu": "52",
            "alan_kodu": "452",
            "nufus": "771.932",
            "bolge": "Karadeniz",
            "yuzolcumu": "5.861",
            "erkek_nufus_yuzde": "50,08%",
            "erkek_nufus": "386.547",
            "kadin_nufus_yuzde": "49,92%",
            "kadin_nufus": "385.385",
            "nufus_yuzdesi_genel": "0,94%",
            "ilceler": [
              {
                "district": "Altınordu",
                "nufus": "200.807",
                "erkek_nufus": "97.679",
                "kadin_nufus": "103.128",
                "yuzolcumu": "410"
              },
              {
                "district": "Ünye",
                "nufus": "126.702",
                "erkek_nufus": "62.982",
                "kadin_nufus": "63.720",
                "yuzolcumu": "569"
              },
              {
                "district": "Fatsa",
                "nufus": "116.154",
                "erkek_nufus": "58.213",
                "kadin_nufus": "57.941",
                "yuzolcumu": "363"
              },
              {
                "district": "Perşembe",
                "nufus": "33.253",
                "erkek_nufus": "17.217",
                "kadin_nufus": "16.036",
                "yuzolcumu": "217"
              },
              {
                "district": "Kumru",
                "nufus": "31.438",
                "erkek_nufus": "15.774",
                "kadin_nufus": "15.664",
                "yuzolcumu": "296"
              },
              {
                "district": "Korgan",
                "nufus": "30.545",
                "erkek_nufus": "15.551",
                "kadin_nufus": "14.994",
                "yuzolcumu": "254"
              },
              {
                "district": "Gölköy",
                "nufus": "29.852",
                "erkek_nufus": "15.061",
                "kadin_nufus": "14.791",
                "yuzolcumu": "421"
              },
              {
                "district": "Ulubey",
                "nufus": "26.737",
                "erkek_nufus": "13.831",
                "kadin_nufus": "12.906",
                "yuzolcumu": "295"
              },
              {
                "district": "Mesudiye",
                "nufus": "26.287",
                "erkek_nufus": "13.711",
                "kadin_nufus": "12.576",
                "yuzolcumu": "1.046"
              },
              {
                "district": "Akkuş",
                "nufus": "23.369",
                "erkek_nufus": "12.016",
                "kadin_nufus": "11.353",
                "yuzolcumu": "697"
              },
              {
                "district": "Aybastı",
                "nufus": "22.783",
                "erkek_nufus": "11.391",
                "kadin_nufus": "11.392",
                "yuzolcumu": "250"
              },
              {
                "district": "Gürgentepe",
                "nufus": "16.929",
                "erkek_nufus": "8.670",
                "kadin_nufus": "8.259",
                "yuzolcumu": "185"
              },
              {
                "district": "İkizce",
                "nufus": "16.451",
                "erkek_nufus": "8.480",
                "kadin_nufus": "7.971",
                "yuzolcumu": "148"
              },
              {
                "district": "Çatalpınar",
                "nufus": "14.954",
                "erkek_nufus": "7.525",
                "kadin_nufus": "7.429",
                "yuzolcumu": "101"
              },
              {
                "district": "Çaybaşı",
                "nufus": "13.922",
                "erkek_nufus": "7.070",
                "kadin_nufus": "6.852",
                "yuzolcumu": "102"
              },
              {
                "district": "Çamaş",
                "nufus": "11.851",
                "erkek_nufus": "6.123",
                "kadin_nufus": "5.728",
                "yuzolcumu": "81"
              },
              {
                "district": "Kabataş",
                "nufus": "11.375",
                "erkek_nufus": "5.723",
                "kadin_nufus": "5.652",
                "yuzolcumu": "74"
              },
              {
                "district": "Kabadüz",
                "nufus": "9.619",
                "erkek_nufus": "5.078",
                "kadin_nufus": "4.541",
                "yuzolcumu": "290"
              },
              {
                "district": "Gülyalı",
                "nufus": "8.904",
                "erkek_nufus": "4.452",
                "kadin_nufus": "4.452",
                "yuzolcumu": "62"
              }
            ],
            "kisa_bilgi": "Karadeniz kıyısında bulunan; Tokat, Sivas, Samsun ve Giresun ile komşu olan Ordu, 1920 yılında il statüsüne kavuştu. Ülkemiz fındık üretiminin yaklaşık yarısını karşılayan ilde nüfusun çoğunluğunun geçimi de fındığa dayanıyor. Ordu’da yer alan önemli fabrikalardan birkaçı şöyle; Doğuş Çay, Gürsoy Fındık, Sagra Çikolata Fabrikası gibi."
          },
          {
            "city": "Rize",
            "plaka_kodu": "53",
            "alan_kodu": "464",
            "nufus": "348.608",
            "bolge": "Karadeniz",
            "yuzolcumu": "3.835",
            "erkek_nufus_yuzde": "49,95%",
            "erkek_nufus": "174.130",
            "kadin_nufus_yuzde": "50,05%",
            "kadin_nufus": "174.478",
            "nufus_yuzdesi_genel": "0,43%",
            "ilceler": [
              {
                "district": "Merkez",
                "nufus": "141.143",
                "erkek_nufus": "68.673",
                "kadin_nufus": "72.470",
                "yuzolcumu": "253"
              },
              {
                "district": "Çayeli",
                "nufus": "44.304",
                "erkek_nufus": "22.063",
                "kadin_nufus": "22.241",
                "yuzolcumu": "442"
              },
              {
                "district": "Ardeşen",
                "nufus": "41.084",
                "erkek_nufus": "20.548",
                "kadin_nufus": "20.536",
                "yuzolcumu": "376"
              },
              {
                "district": "Pazar",
                "nufus": "31.527",
                "erkek_nufus": "15.528",
                "kadin_nufus": "15.999",
                "yuzolcumu": "173"
              },
              {
                "district": "Fındıklı",
                "nufus": "16.902",
                "erkek_nufus": "8.436",
                "kadin_nufus": "8.466",
                "yuzolcumu": "383"
              },
              {
                "district": "Kalkandere",
                "nufus": "16.768",
                "erkek_nufus": "9.702",
                "kadin_nufus": "7.066",
                "yuzolcumu": "107"
              },
              {
                "district": "Güneysu",
                "nufus": "16.750",
                "erkek_nufus": "8.391",
                "kadin_nufus": "8.359",
                "yuzolcumu": "159"
              },
              {
                "district": "İyidere",
                "nufus": "10.018",
                "erkek_nufus": "5.096",
                "kadin_nufus": "4.922",
                "yuzolcumu": "28"
              },
              {
                "district": "İkizdere",
                "nufus": "9.809",
                "erkek_nufus": "5.367",
                "kadin_nufus": "4.442",
                "yuzolcumu": "855"
              },
              {
                "district": "Derepazarı",
                "nufus": "9.365",
                "erkek_nufus": "4.828",
                "kadin_nufus": "4.537",
                "yuzolcumu": "28"
              },
              {
                "district": "Çamlıhemşin",
                "nufus": "8.121",
                "erkek_nufus": "4.096",
                "kadin_nufus": "4.025",
                "yuzolcumu": "897"
              },
              {
                "district": "Hemşin",
                "nufus": "2.817",
                "erkek_nufus": "1.402",
                "kadin_nufus": "1.415",
                "yuzolcumu": "134"
              }
            ],
            "kisa_bilgi": "Ülkemizin en çok yağış alan bu ili, herkesin bildiği gibi çayıyla, Anzer balıyla, yaylalarıyla meşhur. Muhteşem bir doğal güzelliği olan ve mutlaka görülmesi gereken illerimizden biri olan Rize, çok çeşitli doğa sporlarını yapmanıza da olanak tanıyor. Fırtına, Hemşin, Büyükdere, Taşlıdere gibi akarsularında kano ya da rafting yapabilir, yürüyüşleriniz sırasında Kaçkar Dağları Milli Parkı içinde bulunan Palovit Şelalesi’ni, Çayeli’ndeki Ağaran Şelalesi’ni fotoğraflayabilir, Çağrankaya, Petran, Handüzü, Demirkapı, Anzer, Samistal, Palovit gibi doğa harikası yaylalarında çadır kurabilirsiniz."
          },
          {
            "city": "Sakarya",
            "plaka_kodu": "54",
            "alan_kodu": "264",
            "nufus": "1.010.700",
            "bolge": "Marmara",
            "yuzolcumu": "4824",
            "erkek_nufus_yuzde": "50,03%",
            "erkek_nufus": "505.645",
            "kadin_nufus_yuzde": "49,97%",
            "kadin_nufus": "505.055",
            "nufus_yuzdesi_genel": "1,23%",
            "ilceler": [
              {
                "district": "Adapazarı",
                "nufus": "271.515",
                "erkek_nufus": "134.451",
                "kadin_nufus": "137.064",
                "yuzolcumu": "324"
              },
              {
                "district": "Serdivan",
                "nufus": "139.595",
                "erkek_nufus": "68.976",
                "kadin_nufus": "70.619",
                "yuzolcumu": "130"
              },
              {
                "district": "Akyazı",
                "nufus": "89.301",
                "erkek_nufus": "44.708",
                "kadin_nufus": "44.593",
                "yuzolcumu": "628"
              },
              {
                "district": "Erenler",
                "nufus": "87.197",
                "erkek_nufus": "43.592",
                "kadin_nufus": "43.605",
                "yuzolcumu": "136"
              },
              {
                "district": "Hendek",
                "nufus": "84.099",
                "erkek_nufus": "41.378",
                "kadin_nufus": "42.721",
                "yuzolcumu": "646"
              },
              {
                "district": "Karasu",
                "nufus": "64.124",
                "erkek_nufus": "32.005",
                "kadin_nufus": "32.119",
                "yuzolcumu": "411"
              },
              {
                "district": "Geyve",
                "nufus": "49.760",
                "erkek_nufus": "25.094",
                "kadin_nufus": "24.666",
                "yuzolcumu": "662"
              },
              {
                "district": "Arifiye",
                "nufus": "44.315",
                "erkek_nufus": "23.403",
                "kadin_nufus": "20.912",
                "yuzolcumu": "75"
              },
              {
                "district": "Sapanca",
                "nufus": "41.055",
                "erkek_nufus": "20.558",
                "kadin_nufus": "20.497",
                "yuzolcumu": "173"
              },
              {
                "district": "Pamukova",
                "nufus": "29.386",
                "erkek_nufus": "14.785",
                "kadin_nufus": "14.601",
                "yuzolcumu": "289"
              },
              {
                "district": "Ferizli",
                "nufus": "26.692",
                "erkek_nufus": "14.198",
                "kadin_nufus": "12.494",
                "yuzolcumu": "173"
              },
              {
                "district": "Kocaali",
                "nufus": "25.497",
                "erkek_nufus": "12.900",
                "kadin_nufus": "12.597",
                "yuzolcumu": "254"
              },
              {
                "district": "Kaynarca",
                "nufus": "24.255",
                "erkek_nufus": "12.415",
                "kadin_nufus": "11.840",
                "yuzolcumu": "343"
              },
              {
                "district": "Söğütlü",
                "nufus": "13.973",
                "erkek_nufus": "7.054",
                "kadin_nufus": "6.919",
                "yuzolcumu": "145"
              },
              {
                "district": "Karapürçek",
                "nufus": "13.041",
                "erkek_nufus": "6.647",
                "kadin_nufus": "6.394",
                "yuzolcumu": "142"
              },
              {
                "district": "Taraklı",
                "nufus": "6.895",
                "erkek_nufus": "3.481",
                "kadin_nufus": "3.414",
                "yuzolcumu": "292"
              }
            ],
            "kisa_bilgi": "Marmara Bölgesi'nin Çatalca-Kocaeli bölümünde yer alan Sakarya, önemli kara ve demiryollarının kavşak noktasında bulunuyor."
          },
          {
            "city": "Samsun",
            "plaka_kodu": "55",
            "alan_kodu": "362",
            "nufus": "1.335.716",
            "bolge": "Karadeniz",
            "yuzolcumu": "9.725",
            "erkek_nufus_yuzde": "49,57%",
            "erkek_nufus": "662.086",
            "kadin_nufus_yuzde": "50,43%",
            "kadin_nufus": "673.630",
            "nufus_yuzdesi_genel": "1,23%",
            "ilceler": [
              {
                "district": "İlkadım",
                "nufus": "332.230",
                "erkek_nufus": "164.350",
                "kadin_nufus": "167.880",
                "yuzolcumu": "155"
              },
              {
                "district": "Atakum",
                "nufus": "202.618",
                "erkek_nufus": "98.360",
                "kadin_nufus": "104.258",
                "yuzolcumu": "351"
              },
              {
                "district": "Bafra",
                "nufus": "142.210",
                "erkek_nufus": "70.503",
                "kadin_nufus": "71.707",
                "yuzolcumu": ""
              },
              {
                "district": "Çarşamba",
                "nufus": "138.840",
                "erkek_nufus": "69.474",
                "kadin_nufus": "69.366",
                "yuzolcumu": ""
              },
              {
                "district": "Canik",
                "nufus": "97.564",
                "erkek_nufus": "48.885",
                "kadin_nufus": "48.679",
                "yuzolcumu": ""
              },
              {
                "district": "Vezirköprü",
                "nufus": "95.569",
                "erkek_nufus": "47.158",
                "kadin_nufus": "48.411",
                "yuzolcumu": ""
              },
              {
                "district": "Terme",
                "nufus": "72.354",
                "erkek_nufus": "35.848",
                "kadin_nufus": "36.506",
                "yuzolcumu": ""
              },
              {
                "district": "Tekkeköy",
                "nufus": "52.258",
                "erkek_nufus": "26.229",
                "kadin_nufus": "26.029",
                "yuzolcumu": ""
              },
              {
                "district": "Havza",
                "nufus": "40.194",
                "erkek_nufus": "19.783",
                "kadin_nufus": "20.411",
                "yuzolcumu": ""
              },
              {
                "district": "19 Mayıs",
                "nufus": "26.337",
                "erkek_nufus": "13.214",
                "kadin_nufus": "13.123",
                "yuzolcumu": ""
              },
              {
                "district": "Alaçam",
                "nufus": "25.854",
                "erkek_nufus": "12.880",
                "kadin_nufus": "12.974",
                "yuzolcumu": ""
              },
              {
                "district": "Salıpazarı",
                "nufus": "22.923",
                "erkek_nufus": "11.778",
                "kadin_nufus": "11.145",
                "yuzolcumu": ""
              },
              {
                "district": "Ayvacık",
                "nufus": "21.847",
                "erkek_nufus": "11.326",
                "kadin_nufus": "10.521",
                "yuzolcumu": ""
              },
              {
                "district": "Kavak",
                "nufus": "21.692",
                "erkek_nufus": "10.874",
                "kadin_nufus": "10.818",
                "yuzolcumu": ""
              },
              {
                "district": "Asarcık",
                "nufus": "17.628",
                "erkek_nufus": "8.809",
                "kadin_nufus": "8.819",
                "yuzolcumu": ""
              },
              {
                "district": "Ladik",
                "nufus": "16.734",
                "erkek_nufus": "8.243",
                "kadin_nufus": "8.491",
                "yuzolcumu": ""
              },
              {
                "district": "Yakakent",
                "nufus": "8.864",
                "erkek_nufus": "4.372",
                "kadin_nufus": "4.492",
                "yuzolcumu": ""
              }
            ],
            "kisa_bilgi": "Orta Karadeniz bölümünde yer alan ve Karadeniz bölgesinin en kalabalık şehri olan Samsun, 1993 yılından bu yana büyükşehir unvanına sahiptir. İl ayrıca Mustafa Kemal Atatürk'ün 19 Mayıs 1919'da Milli Mücadele’yi başlatmak üzere ilk adımı attığı yer olarak tarihimizde de çok önemli bir yere sahiptir."
          },
      
          {
            "city": "Siirt",
            "plaka_kodu": "56",
            "alan_kodu": "484",
            "nufus": "331.670",
            "bolge": "Güneydoğu Anadolu",
            "yuzolcumu": "5.717",
            "erkek_nufus_yuzde": "51,61%",
            "erkek_nufus": "171.189",
            "kadin_nufus_yuzde": "48,39%",
            "kadin_nufus": "160.481",
            "nufus_yuzdesi_genel": "0,40%",
            "ilceler": [
              {
                  "district": "Merkez",
                  "nufus": "166.332",
                  "erkek_nufus": "85.736",
                  "kadin_nufus": "80.596",
                  "yuzolcumu": "633"
              },
              {
                  "district": "Kurtalan",
                  "nufus": "59.647",
                  "erkek_nufus": "30.275",
                  "kadin_nufus": "29.372",
                  "yuzolcumu": "811"
              },
              {
                  "district": "Pervari",
                  "nufus": "32.128",
                  "erkek_nufus": "16.608",
                  "kadin_nufus": "15.520",
                  "yuzolcumu": "1.651"
              },
              {
                  "district": "Baykan",
                  "nufus": "26.160",
                  "erkek_nufus": "13.345",
                  "kadin_nufus": "12.815",
                  "yuzolcumu": "488"
              },
              {
                  "district": "Şirvan",
                  "nufus": "22.580",
                  "erkek_nufus": "11.561",
                  "kadin_nufus": "11.019",
                  "yuzolcumu": "936"
              },
              {
                  "district": "Eruh",
                  "nufus": "20.513",
                  "erkek_nufus": "11.350",
                  "kadin_nufus": "9.163",
                  "yuzolcumu": "1.061"
              },
              {
                  "district": "Tillo",
                  "nufus": "4.310",
                  "erkek_nufus": "2.314",
                  "kadin_nufus": "1.996",
                  "yuzolcumu": "138"
              }
            ],
            "kisa_bilgi": "Güneydoğu Anadolu bölgesinde yer alan Siirt, Asurlulardan kalan 3500 yıllık Akabe Yolu’nun bulunduğu şehrimizdir. Dünyada var olan üç Akabe yolundan biridir burası."
            },
      
            {
              "city": "Sinop",
              "plaka_kodu": "57",
              "alan_kodu": "368",
              "nufus": "219.733",
              "bolge": "Karadeniz",
              "yuzolcumu": "5.717",
              "erkek_nufus_yuzde": "49,74%",
              "erkek_nufus": "109.296",
              "kadin_nufus_yuzde": "50,26%",
              "kadin_nufus": "110.437",
              "nufus_yuzdesi_genel": "0,27%",
              "ilceler": [
                {
                    "district": "Merkez",
                    "nufus": "64.544",
                    "erkek_nufus": "31.856",
                    "kadin_nufus": "32.688",
                    "yuzolcumu": "442"
                },
                {
                    "district": "Boyabat",
                    "nufus": "44.608",
                    "erkek_nufus": "22.091",
                    "kadin_nufus": "22.517",
                    "yuzolcumu": "1.512"
                },
                {
                    "district": "Gerze",
                    "nufus": "24.946",
                    "erkek_nufus": "12.295",
                    "kadin_nufus": "12.651",
                    "yuzolcumu": "487"
                },
                {
                    "district": "Ayancık",
                    "nufus": "23.720",
                    "erkek_nufus": "11.784",
                    "kadin_nufus": "11.936",
                    "yuzolcumu": "862"
                },
                {
                    "district": "Durağan",
                    "nufus": "19.650",
                    "erkek_nufus": "9.960",
                    "kadin_nufus": "9.690",
                    "yuzolcumu": "995"
                },
                {
                    "district": "Türkeli",
                    "nufus": "17.947",
                    "erkek_nufus": "8.996",
                    "kadin_nufus": "8.951",
                    "yuzolcumu": "263"
                },
                {
                    "district": "Erfelek",
                    "nufus": "12.049",
                    "erkek_nufus": "6.062",
                    "kadin_nufus": "5.987",
                    "yuzolcumu": "412"
                },
                {
                    "district": "Saraydüzü",
                    "nufus": "6.777",
                    "erkek_nufus": "3.452",
                    "kadin_nufus": "3.325",
                    "yuzolcumu": "334"
                },
                {
                    "district": "Dikmen",
                    "nufus": "5.492",
                    "erkek_nufus": "2.800",
                    "kadin_nufus": "2.692",
                    "yuzolcumu": "411"
                }
              ],
              "kisa_bilgi": "Ülkemizin en kuzey ucunda bulunan Sinop, 6 bin yıllık geçmişi, farklı medeniyetlerden kalan tarihi eserleri, enfes doğasıyla turistlerin ilgi noktasıdır"
              },
      
      
              {
                "city": "Sivas",
                "plaka_kodu": "58",
                "alan_kodu": "346",
                "nufus": "646.608",
                "bolge": "İç Anadolu",
                "yuzolcumu": "28.164",
                "erkek_nufus_yuzde": "50,04%",
                "erkek_nufus": "323.575",
                "kadin_nufus_yuzde": "49,96%",
                "kadin_nufus": "323.033",
                "nufus_yuzdesi_genel": "0,79%",
                "ilceler": [
                  {
                      "district": "Merkez",
                      "nufus": "377.561",
                      "erkek_nufus": "187.738",
                      "kadin_nufus": "189.823",
                      "yuzolcumu": "3.488"
                  },
                  {
                      "district": "Şarkışla",
                      "nufus": "38.954",
                      "erkek_nufus": "19.259",
                      "kadin_nufus": "19.695",
                      "yuzolcumu": "2.073"
                  },
                  {
                      "district": "Yıldızeli",
                      "nufus": "33.986",
                      "erkek_nufus": "17.224",
                      "kadin_nufus": "16.762",
                      "yuzolcumu": "2.529"
                  },
                  {
                      "district": "Suşehri",
                      "nufus": "25.654",
                      "erkek_nufus": "12.721",
                      "kadin_nufus": "12.933",
                      "yuzolcumu": "939"
                  },
                  {
                      "district": "Gemerek",
                      "nufus": "24.828",
                      "erkek_nufus": "12.447",
                      "kadin_nufus": "12.381",
                      "yuzolcumu": "1.131"
                  },
                  {
                      "district": "Zara",
                      "nufus": "23.336",
                      "erkek_nufus": "11.831",
                      "kadin_nufus": "11.505",
                      "yuzolcumu": "2.616"
                  },
                  {
                      "district": "Kangal",
                      "nufus": "21.669",
                      "erkek_nufus": "11.111",
                      "kadin_nufus": "10.558",
                      "yuzolcumu": "3.342"
                  },
                  {
                      "district": "Gürün",
                      "nufus": "19.076",
                      "erkek_nufus": "9.618",
                      "kadin_nufus": "9.458",
                      "yuzolcumu": "2.632"
                  },
                  {
                      "district": "Divriği",
                      "nufus": "16.377",
                      "erkek_nufus": "8.417",
                      "kadin_nufus": "7.960",
                      "yuzolcumu": "2.632"
                  },
                  {
                      "district": "Koyulhisar",
                      "nufus": "14.613",
                      "erkek_nufus": "7.227",
                      "kadin_nufus": "7.386",
                      "yuzolcumu": "891"
                  },
                  {
                      "district": "Hafik",
                      "nufus": "10.062",
                      "erkek_nufus": "5.196",
                      "kadin_nufus": "4.866",
                      "yuzolcumu": "1.765"
                  },
                  {
                      "district": "Ulaş",
                      "nufus": "9.682",
                      "erkek_nufus": "4.985",
                      "kadin_nufus": "4.697",
                      "yuzolcumu": "1.092"
                  },
                  {
                      "district": "Altınyayla",
                      "nufus": "9.309",
                      "erkek_nufus": "4.717",
                      "kadin_nufus": "4.592",
                      "yuzolcumu": "654"
                  },
                  {
                      "district": "İmranlı",
                      "nufus": "8.249",
                      "erkek_nufus": "4.291",
                      "kadin_nufus": "3.958",
                      "yuzolcumu": "1.292"
                  },
                  {
                      "district": "Akıncılar",
                      "nufus": "5.675",
                      "erkek_nufus": "2.960",
                      "kadin_nufus": "2.715",
                      "yuzolcumu": "432"
                  },
                  {
                      "district": "Gölova",
                      "nufus": "4.176",
                      "erkek_nufus": "2.137",
                      "kadin_nufus": "2.039",
                      "yuzolcumu": "286"
                  },
                  {
                      "district": "Doğanşar",
                      "nufus": "3.401",
                      "erkek_nufus": "1.696",
                      "kadin_nufus": "1.705",
                      "yuzolcumu": "370"
                  }
      
                ],
                "kisa_bilgi": "Anadolu’nun en eski yerleşim yerlerinden biri olan, tarihi İpek Yolu güzergahlarının kesiştiği yerde bulunan il, 4 Eylül 1919'da, Sivas Erkek Lisesi'nde toplanan kongre nedeniyle Milli Mücadele döneminde önemli bir yere sahip olmuştur. "
                },
      
      
                {
                  "city": "Tekirdağ",
                  "plaka_kodu": "59",
                  "alan_kodu": "282",
                  "nufus": "1.029.927",
                  "bolge": "Marmara",
                  "yuzolcumu": "6.190",
                  "erkek_nufus_yuzde": "51,26%",
                  "erkek_nufus": "527.975",
                  "kadin_nufus_yuzde": "48,74%",
                  "kadin_nufus": "501.952",
                  "nufus_yuzdesi_genel": "1,26%",
                  "ilceler": [
                    {
                        "district": "Çorlu",
                        "nufus": "262.862",
                        "erkek_nufus": "134.044",
                        "kadin_nufus": "128.818",
                        "yuzolcumu": "531"
                    },
                    {
                        "district": "Süleymanpaşa",
                        "nufus": "199.960",
                        "erkek_nufus": "101.786",
                        "kadin_nufus": "98.174",
                        "yuzolcumu": "1.053"
                    },
                    {
                        "district": "Çerkezköy",
                        "nufus": "166.789",
                        "erkek_nufus": "86.540",
                        "kadin_nufus": "80.249",
                        "yuzolcumu": "86"
                    },
                    {
                        "district": "Kapaklı",
                        "nufus": "116.882",
                        "erkek_nufus": "60.292",
                        "kadin_nufus": "56.590",
                        "yuzolcumu": "182"
                    },
                    {
                        "district": "Ergene",
                        "nufus": "62.458",
                        "erkek_nufus": "32.206",
                        "kadin_nufus": "30.252",
                        "yuzolcumu": "418"
                    },
                    {
                        "district": "Malkara",
                        "nufus": "52.758",
                        "erkek_nufus": "27.159",
                        "kadin_nufus": "25.599",
                        "yuzolcumu": "1.243"
                    },
                    {
                        "district": "Saray",
                        "nufus": "49.106",
                        "erkek_nufus": "24.879",
                        "kadin_nufus": "24.227",
                        "yuzolcumu": "620"
                    },
                    {
                        "district": "Şarköy",
                        "nufus": "32.565",
                        "erkek_nufus": "16.404",
                        "kadin_nufus": "16.161",
                        "yuzolcumu": "487"
                    },
                    {
                        "district": "Hayrabolu",
                        "nufus": "32.137",
                        "erkek_nufus": "16.551",
                        "kadin_nufus": "15.586",
                        "yuzolcumu": "1.009"
                    },
                    {
                        "district": "Muratlı",
                        "nufus": "28.537",
                        "erkek_nufus": "14.540",
                        "kadin_nufus": "13.997",
                        "yuzolcumu": "388"
                    },
                    {
                        "district": "Marmaraereğlisi",
                        "nufus": "25.873",
                        "erkek_nufus": "13.574",
                        "kadin_nufus": "12.299",
                        "yuzolcumu": "175"
                    }
                  ],
                  "kisa_bilgi": "2014 yılından bu yana büyükşehir belediyeciliği ile yönetilen Tekirdağ’ın tarihi, Kalkolitik Çağ'a kadar uzanıyor. Giderek gelişen ve ülkemizin en yaşanılası şehirlerinden biri olarak gösterilebilecek ilde 2008 yılında hizmete açılan Tekira Alışveriş Merkezi de bulunmakta. "
                  },
      
      
                  {
                    "city": "Tokat",
                    "plaka_kodu": "60",
                    "alan_kodu": "356",
                    "nufus": "612.646",
                    "bolge": "İç Anadolu",
                    "yuzolcumu": "10.042",
                    "erkek_nufus_yuzde": "49,94%",
                    "erkek_nufus": "305.977",
                    "kadin_nufus_yuzde": "50,06%",
                    "kadin_nufus": "306.669",
                    "nufus_yuzdesi_genel": "0,75%",
                    "ilceler": [
                      {
                          "district": "Merkez",
                          "nufus": "201.294",
                          "erkek_nufus": "102.140",
                          "kadin_nufus": "99.154",
                          "yuzolcumu": "2.003"
                      },
                      {
                          "district": "Erbaa",
                          "nufus": "95.361",
                          "erkek_nufus": "47.218",
                          "kadin_nufus": "48.143",
                          "yuzolcumu": "1.173"
                      },
                      {
                          "district": "Turhal",
                          "nufus": "79.916",
                          "erkek_nufus": "38.886",
                          "kadin_nufus": "41.030",
                          "yuzolcumu": "940"
                      },
                      {
                          "district": "Niksar",
                          "nufus": "64.119",
                          "erkek_nufus": "31.713",
                          "kadin_nufus": "32.406",
                          "yuzolcumu": "889"
                      },
                      {
                          "district": "Zile",
                          "nufus": "55.673",
                          "erkek_nufus": "27.617",
                          "kadin_nufus": "28.056",
                          "yuzolcumu": "1.480"
                      },
                      {
                          "district": "Reşadiye",
                          "nufus": "41.178",
                          "erkek_nufus": "20.649",
                          "kadin_nufus": "20.529",
                          "yuzolcumu": "1.102"
                      },
                      {
                          "district": "Almus",
                          "nufus": "28.413",
                          "erkek_nufus": "14.357",
                          "kadin_nufus": "14.056",
                          "yuzolcumu": "1.033"
                      },
                      {
                          "district": "Pazar",
                          "nufus": "14.335",
                          "erkek_nufus": "7.168",
                          "kadin_nufus": "7.167",
                          "yuzolcumu": "188"
                      },
                      {
                          "district": "Yeşilyurt",
                          "nufus": "9.154",
                          "erkek_nufus": "4.471",
                          "kadin_nufus": "4.683",
                          "yuzolcumu": "280"
                      },
                      {
                          "district": "Artova",
                          "nufus": "8.744",
                          "erkek_nufus": "4.394",
                          "kadin_nufus": "4.350",
                          "yuzolcumu": "444"
                      },
                      {
                          "district": "Sulusaray",
                          "nufus": "7.401",
                          "erkek_nufus": "3.753",
                          "kadin_nufus": "3.648",
                          "yuzolcumu": "266"
                      },
                      {
                          "district": "Başçiftlik",
                          "nufus": "7.058",
                          "erkek_nufus": "3.611",
                          "kadin_nufus": "3.447",
                          "yuzolcumu": "246"
                      }
      
                    ],
                    "kisa_bilgi": "Yeşilırmak  havzası  üzerinde  kurulmuş  olan, yaylaları, gölleri, mesire yerleri, tarihi atmosferi, doğa sporlarına imkân tanıyan dağları, tepeleriyle Tokat, pek çok ilklerin şehridir. Örneğin Roma İmparatoru Jul Sezar'ın Veni-Vidi-Viciyi söylediği yer olarak gösterilen Zile Kalesi ile Kazıklı Voyvoda III. Vlad'ın tutsak olarak kaldığı kale olan Tokat Kalesi buradadır. İl ayrıca 90 yıllık bir müzeye ev sahipliği yapar, onlarca el sanatını barındırır ve Anadolu’nun ilk başkentine ev sahipliği yapmıştır."
                    },
      
      
                    {
                      "city": "Trabzon",
                      "plaka_kodu": "61",
                      "alan_kodu": "462",
                      "nufus": "807.903",
                      "bolge": "Karadeniz",
                      "yuzolcumu": "4.628",
                      "erkek_nufus_yuzde": "49,43%",
                      "erkek_nufus": "399.377",
                      "kadin_nufus_yuzde": "50,57%",
                      "kadin_nufus": "408.526",
                      "nufus_yuzdesi_genel": "0,99%",
                      "ilceler": [
                        {
                            "district": "Ortahisar",
                            "nufus": "317.520",
                            "erkek_nufus": "155.450",
                            "kadin_nufus": "162.070",
                            "yuzolcumu": "235"
                        },
                        {
                            "district": "Akçaabat",
                            "nufus": "123.552",
                            "erkek_nufus": "60.993",
                            "kadin_nufus": "62.559",
                            "yuzolcumu": "375"
                        },
                        {
                            "district": "Araklı",
                            "nufus": "49.496",
                            "erkek_nufus": "24.764",
                            "kadin_nufus": "24.732",
                            "yuzolcumu": "464"
                        },
                        {
                            "district": "Of",
                            "nufus": "43.499",
                            "erkek_nufus": "21.616",
                            "kadin_nufus": "21.883",
                            "yuzolcumu": "258"
                        },
                        {
                            "district": "Yomra",
                            "nufus": "39.624",
                            "erkek_nufus": "19.654",
                            "kadin_nufus": "19.970",
                            "yuzolcumu": "200"
                        },
                        {
                            "district": "Arsin",
                            "nufus": "34.831",
                            "erkek_nufus": "17.540",
                            "kadin_nufus": "17.291",
                            "yuzolcumu": "157"
                        },
                        {
                            "district": "Vakfıkebir",
                            "nufus": "28.209",
                            "erkek_nufus": "13.812",
                            "kadin_nufus": "14.397",
                            "yuzolcumu": "141"
                        },
                        {
                            "district": "Sürmene",
                            "nufus": "27.428",
                            "erkek_nufus": "13.762",
                            "kadin_nufus": "13.666",
                            "yuzolcumu": "166"
                        },
                        {
                            "district": "Maçka",
                            "nufus": "26.626",
                            "erkek_nufus": "13.380",
                            "kadin_nufus": "13.246",
                            "yuzolcumu": "925"
                        },
                        {
                            "district": "Beşikdüzü",
                            "nufus": "22.630",
                            "erkek_nufus": "11.307",
                            "kadin_nufus": "11.323",
                            "yuzolcumu": "84"
                        },
                        {
                            "district": "Çarşıbaşı",
                            "nufus": "16.335",
                            "erkek_nufus": "8.305",
                            "kadin_nufus": "8.030",
                            "yuzolcumu": "66"
                        },
                        {
                            "district": "Çaykara",
                            "nufus": "16.213",
                            "erkek_nufus": "8.147",
                            "kadin_nufus": "8.066",
                            "yuzolcumu": "574"
                        },
                        {
                            "district": "Tonya",
                            "nufus": "14.592",
                            "erkek_nufus": "7.052",
                            "kadin_nufus": "7.540",
                            "yuzolcumu": "176"
                        },
                        {
                            "district": "Düzköy",
                            "nufus": "14.511",
                            "erkek_nufus": "7.128",
                            "kadin_nufus": "7.383",
                            "yuzolcumu": "125"
                        },
                        {
                            "district": "Şalpazarı",
                            "nufus": "11.934",
                            "erkek_nufus": "5.866",
                            "kadin_nufus": "6.068",
                            "yuzolcumu": "161"
                        },
                        {
                            "district": "Hayrat",
                            "nufus": "10.298",
                            "erkek_nufus": "5.230",
                            "kadin_nufus": "5.068",
                            "yuzolcumu": "244"
                        },
                        {
                            "district": "Köprübaşı",
                            "nufus": "6.401",
                            "erkek_nufus": "3.308",
                            "kadin_nufus": "3.093",
                            "yuzolcumu": "189"
                        },
                        {
                            "district": "Dernekpazarı",
                            "nufus": "4.204",
                            "erkek_nufus": "2.063",
                            "kadin_nufus": "2.141",
                            "yuzolcumu": "89"
                        }
      
                      ],
                      "kisa_bilgi": "Rize, Giresun, Gümüşhane ve Bayburt illeriyle çevrili Trabzon’un tarihi en azından 4000 yıl öncesine dek uzanıyor. Şenlikleriyle dikkati çeken, her sene pek çok turisti ağırlayan ve doğa sporlarına imkân tanıyan yaylalar, şehrin sosyo-kültürel yapısında büyük bir öneme sahip. "
                      },
      
      
                      {
                        "city": "Tunceli",
                        "plaka_kodu": "62",
                        "alan_kodu": "428",
                        "nufus": "88.198",
                        "bolge": "Doğu Anadolu",
                        "yuzolcumu": "7.582",
                        "erkek_nufus_yuzde": "55,69%",
                        "erkek_nufus": "49.118",
                        "kadin_nufus_yuzde": "44,31%",
                        "kadin_nufus": "39.080",
                        "nufus_yuzdesi_genel": "0,11%",
                        "ilceler": [
                          {
                              "district": "Merkez",
                              "nufus": "38.504",
                              "erkek_nufus": "20.984",
                              "kadin_nufus": "17.520",
                              "yuzolcumu": "1.128"
                          },
                          {
                              "district": "Pertek",
                              "nufus": "11.669",
                              "erkek_nufus": "6.206",
                              "kadin_nufus": "5.463",
                              "yuzolcumu": "858"
                          },
                          {
                              "district": "Mazgirt",
                              "nufus": "8.430",
                              "erkek_nufus": "4.650",
                              "kadin_nufus": "3.780",
                              "yuzolcumu": "702"
                          },
                          {
                              "district": "Çemişgezek",
                              "nufus": "8.347",
                              "erkek_nufus": "4.375",
                              "kadin_nufus": "3.972",
                              "yuzolcumu": "851"
                          },
                          {
                              "district": "Ovacık",
                              "nufus": "6.998",
                              "erkek_nufus": "4.059",
                              "kadin_nufus": "2.939",
                              "yuzolcumu": "1.401"
                          },
                          {
                              "district": "Hozat",
                              "nufus": "6.891",
                              "erkek_nufus": "4.391",
                              "kadin_nufus": "2.500",
                              "yuzolcumu": "663"
                          },
                          {
                              "district": "Pülümür",
                              "nufus": "3.760",
                              "erkek_nufus": "2.285",
                              "kadin_nufus": "1.475",
                              "yuzolcumu": "1.437"
                          },
                          {
                              "district": "Nazımiye",
                              "nufus": "3.599",
                              "erkek_nufus": "2.168",
                              "kadin_nufus": "1.431",
                              "yuzolcumu": "543"
                          }
      
                        ],
                        "kisa_bilgi": "Yerleşimin M.Ö. 5500-3500 yıllarına dek uzandığı, Doğu Anadolu’nun Yukarı Fırat bölümünde bulunan Tunceli, ülkemizin en az nüfusu olan illerinden biri.  Munzur’la birleşerek Keban Barajı'na dökülen Mercan Irmağı, Mercan Dağları, Munzur Suyu şehrin karakteristik güzelliklerinden birkaç tanesi"
                        },
      
      
                        {
                          "city": "Şanlıurfa",
                          "plaka_kodu": "63",
                          "alan_kodu": "414",
                          "nufus": "2.035.809",
                          "bolge": "Güneydoğu Anadolu",
                          "yuzolcumu": "19.242",
                          "erkek_nufus_yuzde": "50,43%",
                          "erkek_nufus": "1.026.719",
                          "kadin_nufus_yuzde": "49,57%",
                          "kadin_nufus": "1.009.090",
                          "nufus_yuzdesi_genel": "2,48%",
                          "ilceler": [
                            {
                                "district": "Eyyübiye",
                                "nufus": "379.123",
                                "erkek_nufus": "191.103",
                                "kadin_nufus": "188.020",
                                "yuzolcumu": "1.626"
                            },
                            {
                                "district": "Haliliye",
                                "nufus": "376.251",
                                "erkek_nufus": "189.102",
                                "kadin_nufus": "187.149",
                                "yuzolcumu": "1.924"
                            },
                            {
                                "district": "Siverek",
                                "nufus": "258.265",
                                "erkek_nufus": "130.946",
                                "kadin_nufus": "127.319",
                                "yuzolcumu": "3.936"
                            },
                            {
                                "district": "Viranşehir",
                                "nufus": "195.910",
                                "erkek_nufus": "97.140",
                                "kadin_nufus": "98.770",
                                "yuzolcumu": "2.297"
                            },
                            {
                                "district": "Karaköprü",
                                "nufus": "195.552",
                                "erkek_nufus": "100.163",
                                "kadin_nufus": "95.389",
                                "yuzolcumu": "1.222"
                            },
                            {
                                "district": "Akçakale",
                                "nufus": "113.194",
                                "erkek_nufus": "57.879",
                                "kadin_nufus": "55.315",
                                "yuzolcumu": "1.038"
                            },
                            {
                                "district": "Suruç",
                                "nufus": "104.302",
                                "erkek_nufus": "53.023",
                                "kadin_nufus": "51.279",
                                "yuzolcumu": "744"
                            },
                            {
                                "district": "Birecik",
                                "nufus": "95.149",
                                "erkek_nufus": "47.730",
                                "kadin_nufus": "47.419",
                                "yuzolcumu": "912"
                            },
                            {
                                "district": "Harran",
                                "nufus": "87.843",
                                "erkek_nufus": "43.933",
                                "kadin_nufus": "43.910",
                                "yuzolcumu": "904"
                            },
                            {
                                "district": "Ceylanpınar",
                                "nufus": "87.684",
                                "erkek_nufus": "44.346",
                                "kadin_nufus": "43.338",
                                "yuzolcumu": "1.589"
                            },
                            {
                                "district": "Bozova",
                                "nufus": "58.565",
                                "erkek_nufus": "29.423",
                                "kadin_nufus": "29.142",
                                "yuzolcumu": "1.329"
                            },
                            {
                                "district": "Hilvan",
                                "nufus": "42.829",
                                "erkek_nufus": "21.751",
                                "kadin_nufus": "21.078",
                                "yuzolcumu": "1.111"
                            }
      
                          ],
                          "kisa_bilgi": "Mardin, Gaziantep, Adıyaman, Diyarbakır illeri ve güneyde Suriye ile sınır komşusu olan, çok eski çağlara dek uzanan zengin tarihiyle Şanlıurfa (Kürtçe: Riha, Arapça: الرها ve Süryanice: Urhoy), ülkemizin en kalabalık şehirlerinden biridir. Kozmopolit yapısıyla çok zengin bir kültürü olan Urfa, -sayıları giderek azalsa da-, Türkiye Ezidilerinin çoğunluğuna evsahipliği yapar. Urfa, sahip olduğu doğal zenginlikleriyle de dikkati çeker."
                          },
      
      
                          {
                            "city": "Uşak",
                            "plaka_kodu": "64",
                            "alan_kodu": "276",
                            "nufus": "367.514",
                            "bolge": "Ege",
                            "yuzolcumu": "5.555",
                            "erkek_nufus_yuzde": "49,78%",
                            "erkek_nufus": "182.931",
                            "kadin_nufus_yuzde": "50,22%",
                            "kadin_nufus": "184.583",
                            "nufus_yuzdesi_genel": "0,45%",
                            "ilceler": [
                              {
                                  "district": "Merkez",
                                  "nufus": "252.044",
                                  "erkek_nufus": "125.487",
                                  "kadin_nufus": "126.557",
                                  "yuzolcumu": "1.655"
                              },
                              {
                                  "district": "Banaz",
                                  "nufus": "36.373",
                                  "erkek_nufus": "18.090",
                                  "kadin_nufus": "18.283",
                                  "yuzolcumu": "1.114"
                              },
                              {
                                  "district": "Eşme",
                                  "nufus": "34.932",
                                  "erkek_nufus": "17.300",
                                  "kadin_nufus": "17.632",
                                  "yuzolcumu": "1.156"
                              },
                              {
                                  "district": "Sivaslı",
                                  "nufus": "20.753",
                                  "erkek_nufus": "10.438",
                                  "kadin_nufus": "10.315",
                                  "yuzolcumu": "473"
                              },
                              {
                                  "district": "Ulubey",
                                  "nufus": "12.955",
                                  "erkek_nufus": "6.384",
                                  "kadin_nufus": "6.571",
                                  "yuzolcumu": "828"
                              },
                              {
                                  "district": "Karahallı",
                                  "nufus": "10.457",
                                  "erkek_nufus": "5.232",
                                  "kadin_nufus": "5.225",
                                  "yuzolcumu": "330"
                              }
                            ],
                            "kisa_bilgi": "İç Anadolu ile Ege Bölgesi arasında geçiş bölümü oluşturan, M.Ö. 4000 yılına uzanan geçmişiyle pek çok kültüre ev sahipliği yapmış olan il, aynı zamanda bir ilkler şehri. 1909 yılında elektrik kullanmaya başlayarak, ülkenin bu anlamda ilk şehri statüsüne sahip olmuştur. Türkiye’nin ilk demiryolu hattı buradan geçtiği gibi, 1926 yılında ilk şeker fabrikasının da açıldığı ildir aynı zamanda. Dolayısıyla ülke sanayileşmesinde değerli bir yeri vardır Uşak’ın. "
                            },
      
      
                            {
                              "city": "Van",
                              "plaka_kodu": "65",
                              "alan_kodu": "432",
                              "nufus": "1.123.784",
                              "bolge": "Doğu Anadolu ",
                              "yuzolcumu": "20.921",
                              "erkek_nufus_yuzde": "51,12%",
                              "erkek_nufus": "574.461",
                              "kadin_nufus_yuzde": "48,88%",
                              "kadin_nufus": "549.323",
                              "nufus_yuzdesi_genel": "1,37%",
                              "ilceler": [
                                {
                                    "district": "İpekyolu",
                                    "nufus": "312.244",
                                    "erkek_nufus": "156.807",
                                    "kadin_nufus": "155.437",
                                    "yuzolcumu": "956"
                                },
                                {
                                    "district": "Erciş",
                                    "nufus": "173.313",
                                    "erkek_nufus": "89.717",
                                    "kadin_nufus": "83.596",
                                    "yuzolcumu": "2.133"
                                },
                                {
                                    "district": "Tuşba",
                                    "nufus": "160.522",
                                    "erkek_nufus": "83.330",
                                    "kadin_nufus": "77.192",
                                    "yuzolcumu": "1.948"
                                },
                                {
                                    "district": "Edremit",
                                    "nufus": "125.884",
                                    "erkek_nufus": "63.053",
                                    "kadin_nufus": "62.831",
                                    "yuzolcumu": "515"
                                },
                                {
                                    "district": "Özalp",
                                    "nufus": "65.785",
                                    "erkek_nufus": "33.628",
                                    "kadin_nufus": "32.157",
                                    "yuzolcumu": "1.430"
                                },
                                {
                                    "district": "Çaldıran",
                                    "nufus": "63.013",
                                    "erkek_nufus": "32.564",
                                    "kadin_nufus": "30.449",
                                    "yuzolcumu": "1.478"
                                },
                                {
                                    "district": "Başkale",
                                    "nufus": "52.544",
                                    "erkek_nufus": "27.800",
                                    "kadin_nufus": "24.744",
                                    "yuzolcumu": "2.727"
                                },
                                {
                                    "district": "Muradiye",
                                    "nufus": "49.688",
                                    "erkek_nufus": "25.530",
                                    "kadin_nufus": "24.158",
                                    "yuzolcumu": "912"
                                },
                                {
                                    "district": "Gürpınar",
                                    "nufus": "35.663",
                                    "erkek_nufus": "18.355",
                                    "kadin_nufus": "17.308",
                                    "yuzolcumu": "4.028"
                                },
                                {
                                    "district": "Gevaş",
                                    "nufus": "28.620",
                                    "erkek_nufus": "14.322",
                                    "kadin_nufus": "14.298",
                                    "yuzolcumu": "1.544"
                                },
                                {
                                    "district": "Saray",
                                    "nufus": "20.949",
                                    "erkek_nufus": "10.945",
                                    "kadin_nufus": "10.004",
                                    "yuzolcumu": "872"
                                },
                                {
                                    "district": "Çatak",
                                    "nufus": "20.937",
                                    "erkek_nufus": "11.013",
                                    "kadin_nufus": "9.924",
                                    "yuzolcumu": "1.952"
                                },
                                {
                                    "district": "Bahçesaray",
                                    "nufus": "14.622",
                                    "erkek_nufus": "7.397",
                                    "kadin_nufus": "7.225",
                                    "yuzolcumu": "426"
                                }
      
                              ],
                              "kisa_bilgi": "Doğu Anadolu bölgesinin en büyük ili olan Van, Urartular’dan kalma ve hâlâ yaşanılan dünya üzerindeki en eski bir yerleşim yerlerinden biridir. "
                            },
      
      
                            {
                              "city": "Yozgat",
                              "plaka_kodu": "66",
                              "alan_kodu": "354",
                              "nufus": "424.981",
                              "bolge": "İç Anadolu",
                              "yuzolcumu": "13.690",
                              "erkek_nufus_yuzde": "49,87%",
                              "erkek_nufus": "211.936",
                              "kadin_nufus_yuzde": "50,13%",
                              "kadin_nufus": "213.045",
                              "nufus_yuzdesi_genel": "0,52%",
                              "ilceler": [
                                {
                                    "district": "Merkez",
                                    "nufus": "105.167",
                                    "erkek_nufus": "52.398",
                                    "kadin_nufus": "52.769",
                                    "yuzolcumu": ""
                                },
                                {
                                    "district": "Sorgun",
                                    "nufus": "79.314",
                                    "erkek_nufus": "39.728",
                                    "kadin_nufus": "39.586",
                                    "yuzolcumu": ""
                                },
                                {
                                    "district": "Akdağmadeni",
                                    "nufus": "42.919",
                                    "erkek_nufus": "21.391",
                                    "kadin_nufus": "21.528",
                                    "yuzolcumu": ""
                                },
                                {
                                    "district": "Yerköy",
                                    "nufus": "35.561",
                                    "erkek_nufus": "17.350",
                                    "kadin_nufus": "18.211",
                                    "yuzolcumu": ""
                                },
                                {
                                    "district": "Boğazlıyan",
                                    "nufus": "34.121",
                                    "erkek_nufus": "17.398",
                                    "kadin_nufus": "16.723",
                                    "yuzolcumu": ""
                                },
                                {
                                    "district": "Sarıkaya",
                                    "nufus": "33.010",
                                    "erkek_nufus": "16.547",
                                    "kadin_nufus": "16.463",
                                    "yuzolcumu": ""
                                },
                                {
                                    "district": "Çekerek",
                                    "nufus": "19.786",
                                    "erkek_nufus": "9.777",
                                    "kadin_nufus": "10.009",
                                    "yuzolcumu": ""
                                },
                                {
                                    "district": "Şefaatli",
                                    "nufus": "15.135",
                                    "erkek_nufus": "7.528",
                                    "kadin_nufus": "7.607",
                                    "yuzolcumu": ""
                                },
                                {
                                    "district": "Saraykent",
                                    "nufus": "14.198",
                                    "erkek_nufus": "7.140",
                                    "kadin_nufus": "7.058",
                                    "yuzolcumu": ""
                                },
                                {
                                    "district": "Çayıralan",
                                    "nufus": "13.512",
                                    "erkek_nufus": "6.716",
                                    "kadin_nufus": "6.796",
                                    "yuzolcumu": ""
                                },
                                {
                                    "district": "Kadışehri",
                                    "nufus": "11.716",
                                    "erkek_nufus": "5.828",
                                    "kadin_nufus": "5.888",
                                    "yuzolcumu": ""
                                },
                                {
                                    "district": "Aydıncık",
                                    "nufus": "10.407",
                                    "erkek_nufus": "5.202",
                                    "kadin_nufus": "5.205",
                                    "yuzolcumu": ""
                                },
                                {
                                    "district": "Yenifakılı",
                                    "nufus": "5.538",
                                    "erkek_nufus": "2.697",
                                    "kadin_nufus": "2.841",
                                    "yuzolcumu": ""
                                },
                                {
                                    "district": "Çandır",
                                    "nufus": "4.597",
                                    "erkek_nufus": "2.236",
                                    "kadin_nufus": "2.361",
                                    "yuzolcumu": ""
                                }
      
                              ],
                              "kisa_bilgi": "Yaklaşık 5000 bin sene öncesine ait tarihi kalıntıların bulunduğu, Eti’lerin yerleşim merkezlerinden biri olan, ülkemizin tam ortasında yer alan, parmak çöreğiyle meşhur Yozgat, Türkiye’nin ilk milli parkı olan Çamlık Milli Parkı’nı da bünyesinde barındırıyor."
                              },
      
      
                              {
                                "city": "Zonguldak",
                                "plaka_kodu": "67",
                                "alan_kodu": "372",
                                "nufus": "599.698",
                                "bolge": "Karadeniz",
                                "yuzolcumu": "3.342",
                                "erkek_nufus_yuzde": "49,58%",
                                "erkek_nufus": "297.303",
                                "kadin_nufus_yuzde": "50,42%",
                                "kadin_nufus": "302.395",
                                "nufus_yuzdesi_genel": "0,73%",
                                "ilceler": [
                                  {
                                      "district": "Ereğli",
                                      "nufus": "175.605",
                                      "erkek_nufus": "87.313",
                                      "kadin_nufus": "88.292",
                                      "yuzolcumu": "736"
                                  },
                                  {
                                      "district": "Merkez",
                                      "nufus": "125.339",
                                      "erkek_nufus": "62.111",
                                      "kadin_nufus": "63.228",
                                      "yuzolcumu": "272"
                                  },
                                  {
                                      "district": "Çaycuma",
                                      "nufus": "91.569",
                                      "erkek_nufus": "45.020",
                                      "kadin_nufus": "46.549",
                                      "yuzolcumu": "492"
                                  },
                                  {
                                      "district": "Devrek",
                                      "nufus": "57.540",
                                      "erkek_nufus": "28.844",
                                      "kadin_nufus": "28.696",
                                      "yuzolcumu": "953"
                                  },
                                  {
                                      "district": "Kozlu",
                                      "nufus": "48.381",
                                      "erkek_nufus": "23.637",
                                      "kadin_nufus": "24.744",
                                      "yuzolcumu": "177"
                                  },
                                  {
                                      "district": "Alaplı",
                                      "nufus": "44.286",
                                      "erkek_nufus": "22.035",
                                      "kadin_nufus": "22.251",
                                      "yuzolcumu": "383"
                                  },
                                  {
                                      "district": "Kilimli",
                                      "nufus": "35.323",
                                      "erkek_nufus": "17.644",
                                      "kadin_nufus": "17.679",
                                      "yuzolcumu": "145"
                                  },
                                  {
                                      "district": "Gökçebey",
                                      "nufus": "21.655",
                                      "erkek_nufus": "10.699",
                                      "kadin_nufus": "10.956",
                                      "yuzolcumu": "183"
                                  }
      
                                ],
                                "kisa_bilgi": "Batı Karadeniz Bölgesi’nde yer alan, ülkenin en zengin taş kömürü madenlerini barındıran ve limanıyla da deniz ticaretinde önemli bir yeri olan il, ayrıca Karadeniz’in en güzel sahillerinden birkaçına sahip."
                                },
      
      
                                {
                                  "city": "Aksaray",
                                  "plaka_kodu": "68",
                                  "alan_kodu": "382",
                                  "nufus": "412.172",
                                  "bolge": "İç Anadolu",
                                  "yuzolcumu": "7.659",
                                  "erkek_nufus_yuzde": "49,69%",
                                  "erkek_nufus": "204.792",
                                  "kadin_nufus_yuzde": "50,31%",
                                  "kadin_nufus": "207.380",
                                  "nufus_yuzdesi_genel": "0,50%",
                                  "ilceler": [
                                    {
                                        "district": "Merkez",
                                        "nufus": "295.351",
                                        "erkek_nufus": "146.611",
                                        "kadin_nufus": "148.740",
                                        "yuzolcumu": "3.539"
                                    },
                                    {
                                        "district": "Ortaköy",
                                        "nufus": "32.504",
                                        "erkek_nufus": "15.846",
                                        "kadin_nufus": "16.658",
                                        "yuzolcumu": "727"
                                    },
                                    {
                                        "district": "Eskil",
                                        "nufus": "26.648",
                                        "erkek_nufus": "13.425",
                                        "kadin_nufus": "13.223",
                                        "yuzolcumu": "1.701"
                                    },
                                    {
                                        "district": "Gülağaç",
                                        "nufus": "19.903",
                                        "erkek_nufus": "10.000",
                                        "kadin_nufus": "9.903",
                                        "yuzolcumu": "326"
                                    },
                                    {
                                        "district": "Güzelyurt",
                                        "nufus": "11.761",
                                        "erkek_nufus": "5.863",
                                        "kadin_nufus": "5.898",
                                        "yuzolcumu": "285"
                                    },
                                    {
                                        "district": "Sultanhanı",
                                        "nufus": "10.884",
                                        "erkek_nufus": "5.555",
                                        "kadin_nufus": "5.329",
                                        "yuzolcumu": "463"
                                    },
                                    {
                                        "district": "Ağaçören",
                                        "nufus": "8.320",
                                        "erkek_nufus": "4.127",
                                        "kadin_nufus": "4.193",
                                        "yuzolcumu": "407"
                                    },
                                    {
                                        "district": "Sarıyahşi",
                                        "nufus": "6.801",
                                        "erkek_nufus": "3.365",
                                        "kadin_nufus": "3.436",
                                        "yuzolcumu": "210"
                                    }
      
                                  ],
                                  "kisa_bilgi": "1989 yılında Niğde’den ayrılarak il statüsüne kavuşan Aksaray, tarihi ve doğal zenginlikleriyle harika bir turistik rota. Hıristiyanlığın ilk yıllarında önemli bir din merkezi oluşu, pek çok kilisenin eski zamanlardan bu yana günümüze ulaştığı bir yere dönüştürüyor Aksaray’ı."
                                },
      
      
                                {
                                  "city": "Bayburt",
                                  "plaka_kodu": "69",
                                  "alan_kodu": "458",
                                  "nufus": "82.274",
                                  "bolge": "Karadeniz",
                                  "yuzolcumu": "3.746",
                                  "erkek_nufus_yuzde": "50,29%",
                                  "erkek_nufus": "41.377",
                                  "kadin_nufus_yuzde": "49,71%",
                                  "kadin_nufus": "40.897",
                                  "nufus_yuzdesi_genel": "0,10%",
                                  "ilceler": [
                                    {
                                        "district": "Merkez",
                                        "nufus": "66.633",
                                        "erkek_nufus": "33.487",
                                        "kadin_nufus": "33.146",
                                        "yuzolcumu": "2705"
                                    },
                                    {
                                        "district": "Demirözü",
                                        "nufus": "8.657",
                                        "erkek_nufus": "4.369",
                                        "kadin_nufus": "4.288",
                                        "yuzolcumu": "594"
                                    },
                                    {
                                        "district": "Aydıntepe",
                                        "nufus": "6.984",
                                        "erkek_nufus": "3.521",
                                        "kadin_nufus": "3.463",
                                        "yuzolcumu": "446"
                                    }
      
                                  ],
                                  "kisa_bilgi": "Karadeniz bölgesinde bulunan bu minik ilin tarihi M.Ö. 3 bin yıllarına dek uzanıyor. 1989 yılında il statüsüne kavuşan Bayburt, daha öncesinde Erzurum ve Gümüşhane’ye bağlıydı."
                                  },
      
                                  {
                                    "city": "Karaman",
                                    "plaka_kodu": "70",
                                    "alan_kodu": "338",
                                    "nufus": "251.913",
                                    "bolge": "İç Anadolu",
                                    "yuzolcumu": "8.678",
                                    "erkek_nufus_yuzde": "50,01%",
                                    "erkek_nufus": "125.992",
                                    "kadin_nufus_yuzde": "49,99%",
                                    "kadin_nufus": "125.921",
                                    "nufus_yuzdesi_genel": "0,31%",
                                    "ilceler": [
                                      {
                                          "district": "Merkez",
                                          "nufus": "194.018",
                                          "erkek_nufus": "96.646",
                                          "kadin_nufus": "97.372",
                                          "yuzolcumu": "4.036"
                                      },
                                      {
                                          "district": "Ermenek",
                                          "nufus": "28.832",
                                          "erkek_nufus": "14.656",
                                          "kadin_nufus": "14.176",
                                          "yuzolcumu": "1.242"
                                      },
                                      {
                                          "district": "Sarıveliler",
                                          "nufus": "12.601",
                                          "erkek_nufus": "6.558",
                                          "kadin_nufus": "6.043",
                                          "yuzolcumu": "590"
                                      },
                                      {
                                          "district": "Ayrancı",
                                          "nufus": "8.338",
                                          "erkek_nufus": "4.182",
                                          "kadin_nufus": "4.156",
                                          "yuzolcumu": "2.245"
                                      },
                                      {
                                          "district": "Kazımkarabekir",
                                          "nufus": "4.407",
                                          "erkek_nufus": "2.046",
                                          "kadin_nufus": "2.361",
                                          "yuzolcumu": "423"
                                      },
                                      {
                                          "district": "Başyayla",
                                          "nufus": "3.717",
                                          "erkek_nufus": "1.904",
                                          "kadin_nufus": "1.813",
                                          "yuzolcumu": "141"
                                      }
      
                                    ],
                                    "kisa_bilgi": "İç Anadolu bölgesinde yer alan Karaman, 1989 yılında Konya’dan ayrılarak il statüsüne kavuştu. "
                                  },
      
                                  {
                                    "city": "Kırıkkale",
                                    "plaka_kodu": "71",
                                    "alan_kodu": "318",
                                    "nufus": "286.602",
                                    "bolge": "İç Anadolu",
                                    "yuzolcumu": "4791",
                                    "erkek_nufus_yuzde": "50,00%",
                                    "erkek_nufus": "143.305",
                                    "kadin_nufus_yuzde": "50,00%",
                                    "kadin_nufus": "143.297",
                                    "nufus_yuzdesi_genel": "0,35%",
                                    "ilceler": [
                                      {
                                          "district": "Merkez",
                                          "nufus": "196.645",
                                          "erkek_nufus": "97.295",
                                          "kadin_nufus": "99.350",
                                          "yuzolcumu": "432"
                                      },
                                      {
                                          "district": "Yahşihan",
                                          "nufus": "31.308",
                                          "erkek_nufus": "15.388",
                                          "kadin_nufus": "15.920",
                                          "yuzolcumu": "304"
                                      },
                                      {
                                          "district": "Keskin",
                                          "nufus": "18.139",
                                          "erkek_nufus": "9.787",
                                          "kadin_nufus": "8.352",
                                          "yuzolcumu": "1.129"
                                      },
                                      {
                                          "district": "Delice",
                                          "nufus": "10.017",
                                          "erkek_nufus": "5.094",
                                          "kadin_nufus": "4.923",
                                          "yuzolcumu": "970"
                                      },
                                      {
                                          "district": "Sulakyurt",
                                          "nufus": "8.531",
                                          "erkek_nufus": "4.519",
                                          "kadin_nufus": "4.012",
                                          "yuzolcumu": "708"
                                      },
                                      {
                                          "district": "Bahşili",
                                          "nufus": "7.907",
                                          "erkek_nufus": "3.984",
                                          "kadin_nufus": "3.923",
                                          "yuzolcumu": "266"
                                      },
                                      {
                                          "district": "Balışeyh",
                                          "nufus": "7.221",
                                          "erkek_nufus": "3.725",
                                          "kadin_nufus": "3.496",
                                          "yuzolcumu": ""
                                      },
                                      {
                                          "district": "Karakeçili",
                                          "nufus": "3.810",
                                          "erkek_nufus": "1.961",
                                          "kadin_nufus": "1.849",
                                          "yuzolcumu": "623"
                                      },
                                      {
                                          "district": "Çelebi",
                                          "nufus": "3.024",
                                          "erkek_nufus": "1.552",
                                          "kadin_nufus": "1.472",
                                          "yuzolcumu": "223"
                                      }
                                    ],
                                    "kisa_bilgi": "Kızılırmak ve Delice ırmaklarının suladığı topraklarda yer alan Kırıkkale, ülkemizin ortasında bulunan, kuruluşu oldukça yeni bir şehir."
                                  },
      
      
                                  {
                                    "city": "Batman",
                                    "plaka_kodu": "72",
                                    "alan_kodu": "488",
                                    "nufus": "599.103",
                                    "bolge": "Güneydoğu Anadolu",
                                    "yuzolcumu": "4.477",
                                    "erkek_nufus_yuzde": "50,31%",
                                    "erkek_nufus": "301.413",
                                    "kadin_nufus_yuzde": "49,69%",
                                    "kadin_nufus": "297.690",
                                    "nufus_yuzdesi_genel": "0,73%",
                                    "ilceler": [
                                      {
                                          "district": "Merkez",
                                          "nufus": "447.106",
                                          "erkek_nufus": "224.474",
                                          "kadin_nufus": "222.632",
                                          "yuzolcumu": "653"
                                      },
                                      {
                                          "district": "Kozluk",
                                          "nufus": "61.437",
                                          "erkek_nufus": "30.912",
                                          "kadin_nufus": "30.525",
                                          "yuzolcumu": "1.101"
                                      },
                                      {
                                          "district": "Sason",
                                          "nufus": "32.907",
                                          "erkek_nufus": "16.906",
                                          "kadin_nufus": "16.001",
                                          "yuzolcumu": "706"
                                      },
                                      {
                                          "district": "Beşiri",
                                          "nufus": "30.445",
                                          "erkek_nufus": "15.397",
                                          "kadin_nufus": "15.048",
                                          "yuzolcumu": "809"
                                      },
                                      {
                                          "district": "Gercüş",
                                          "nufus": "20.484",
                                          "erkek_nufus": "10.295",
                                          "kadin_nufus": "10.189",
                                          "yuzolcumu": "914"
                                      },
                                      {
                                          "district": "Hasankeyf",
                                          "nufus": "6.724",
                                          "erkek_nufus": "3.429",
                                          "kadin_nufus": "3.295",
                                          "yuzolcumu": "293"
                                      }
      
                                    ],
                                    "kisa_bilgi": "Ülkemizin güneydoğusunda, Dicle Nehri ile yan kolları olan Batman ve Garzan çayları arasındaki havzada bulunan şehir, 1990 yılından bu yana il statüsüne sahip"
                                    },
      
      
                                    {
                                      "city": "Şırnak",
                                      "plaka_kodu": "73",
                                      "alan_kodu": "486",
                                      "nufus": "524.190",
                                      "bolge": "Güneydoğu Anadolu",
                                      "yuzolcumu": "7.078",
                                      "erkek_nufus_yuzde": "52,96%",
                                      "erkek_nufus": "277.585",
                                      "kadin_nufus_yuzde": "47,04%",
                                      "kadin_nufus": "246.605",
                                      "nufus_yuzdesi_genel": "0,64%",
                                      "ilceler": [
                                        {
                                            "district": "Cizre",
                                            "nufus": "143.124",
                                            "erkek_nufus": "73.187",
                                            "kadin_nufus": "69.937",
                                            "yuzolcumu": "444"
                                        },
                                        {
                                            "district": "Silopi",
                                            "nufus": "134.754",
                                            "erkek_nufus": "70.699",
                                            "kadin_nufus": "64.055",
                                            "yuzolcumu": "831"
                                        },
                                        {
                                            "district": "Merkez",
                                            "nufus": "93.431",
                                            "erkek_nufus": "51.548",
                                            "kadin_nufus": "41.883",
                                            "yuzolcumu": "1.701"
                                        },
                                        {
                                            "district": "İdil",
                                            "nufus": "76.523",
                                            "erkek_nufus": "38.335",
                                            "kadin_nufus": "38.188",
                                            "yuzolcumu": "1.148"
                                        },
                                        {
                                            "district": "Uludere",
                                            "nufus": "46.040",
                                            "erkek_nufus": "26.721",
                                            "kadin_nufus": "19.319",
                                            "yuzolcumu": "841"
                                        },
                                        {
                                            "district": "Beytüşşebap",
                                            "nufus": "17.227",
                                            "erkek_nufus": "9.776",
                                            "kadin_nufus": "7.451",
                                            "yuzolcumu": "1.647"
                                        },
                                        {
                                            "district": "Güçlükonak",
                                            "nufus": "13.091",
                                            "erkek_nufus": "7.319",
                                            "kadin_nufus": "5.772",
                                            "yuzolcumu": "466"
                                        }
                                      ],
                                      "kisa_bilgi": "Asırlar süren bir gelenekle günümüze ulaşan Jirki ve Guyan kilimleri, çeşitli el sanatları ve halk oyunları ile zengin bir kültürel geçmişe sahip olan Şırnak, hem Güneydoğu Anadolu hem de Doğu Anadolu topraklarında yer alıyor. "
                                    },
      
                                    {
                                      "city": "Bartın",
                                      "plaka_kodu": "74",
                                      "alan_kodu": "378",
                                      "nufus": "198.999",
                                      "bolge": "Karadeniz",
                                      "yuzolcumu": "2330",
                                      "erkek_nufus_yuzde": "49,71%",
                                      "erkek_nufus": "98.913",
                                      "kadin_nufus_yuzde": "50,29%",
                                      "kadin_nufus": "100.086",
                                      "nufus_yuzdesi_genel": " 0,24%",
                                      "ilceler": [
                                        {
                                            "district": "Merkez",
                                            "nufus": "155.016",
                                            "erkek_nufus": "76.719",
                                            "kadin_nufus": "78.297",
                                            "yuzolcumu": "1.091"
                                        },
                                        {
                                            "district": "Ulus",
                                            "nufus": "22.333",
                                            "erkek_nufus": "11.071",
                                            "kadin_nufus": "11.262",
                                            "yuzolcumu": "909"
                                        },
                                        {
                                            "district": "Amasra",
                                            "nufus": "14.776",
                                            "erkek_nufus": "7.583",
                                            "kadin_nufus": "7.193",
                                            "yuzolcumu": "178"
                                        },
                                        {
                                            "district": "Kurucaşile",
                                            "nufus": "6.874",
                                            "erkek_nufus": "3.540",
                                            "kadin_nufus": "3.334",
                                            "yuzolcumu": "152"
                                        }
      
                                      ],
                                      "kisa_bilgi": "Batı Karadeniz’de bulunan Bartın, 1991 yılında Zonguldak’tan ayrılarak il statüsüne kavuştu. Bu küçük il, 2000 yılında milli park ilan edilen ve 1998’de Dünya Doğayı Koruma Vakfı (WWF) tarafından Avrupa Ormanları’nda korumada öncelikli alanlardan biri olarak seçilen Küre Dağları Milli Parkı içerisinde yer alıyor."
                                      },
      
      
                                      {
                                        "city": "Ardahan",
                                        "plaka_kodu": "75",
                                        "alan_kodu": "478",
                                        "nufus": "98.907",
                                        "bolge": "Doğu Anadolu",
                                        "yuzolcumu": "4.934",
                                        "erkek_nufus_yuzde": "52,16%",
                                        "erkek_nufus": "51.593",
                                        "kadin_nufus_yuzde": "47,84%",
                                        "kadin_nufus": "47.314",
                                        "nufus_yuzdesi_genel": "0,12%",
                                        "ilceler": [
                                          {
                                              "district": "Merkez",
                                              "nufus": "42.226",
                                              "erkek_nufus": "21.782",
                                              "kadin_nufus": "20.444",
                                              "yuzolcumu": "1.261"
                                          },
                                          {
                                              "district": "Göle",
                                              "nufus": "25.187",
                                              "erkek_nufus": "13.098",
                                              "kadin_nufus": "12.089",
                                              "yuzolcumu": "1.290"
                                          },
                                          {
                                              "district": "Çıldır",
                                              "nufus": "9.833",
                                              "erkek_nufus": "5.174",
                                              "kadin_nufus": "4.659",
                                              "yuzolcumu": "988"
                                          },
                                          {
                                              "district": "Hanak",
                                              "nufus": "9.054",
                                              "erkek_nufus": "4.663",
                                              "kadin_nufus": "4.391",
                                              "yuzolcumu": "647"
                                          },
                                          {
                                              "district": "Posof",
                                              "nufus": "6.805",
                                              "erkek_nufus": "3.591",
                                              "kadin_nufus": "3.214",
                                              "yuzolcumu": "583"
                                          },
                                          {
                                              "district": "Damal",
                                              "nufus": "5.802",
                                              "erkek_nufus": "3.285",
                                              "kadin_nufus": "2.517",
                                              "yuzolcumu": "165"
                                          }
      
                                        ],
                                        "kisa_bilgi": "Tarihi boyunca Urartu, Pers, Roma, Selçuklu, Osmanlı gibi medeniyetlere ev sahipliği yapan, ülkemizin Kafkaslara açılan kapısı niteliğindeki Ardahan, 1992 yılında Kars’tan ayrılarak il statüsüne kavuştu."
                                        },
      
      
                                        {
                                          "city": "Iğdır",
                                          "plaka_kodu": "76",
                                          "alan_kodu": "476",
                                          "nufus": "197.456",
                                          "bolge": "Doğu Anadolu",
                                          "yuzolcumu": "3664",
                                          "erkek_nufus_yuzde": "51,74%",
                                          "erkek_nufus": "102.171",
                                          "kadin_nufus_yuzde": "48,26%",
                                          "kadin_nufus": "95.285",
                                          "nufus_yuzdesi_genel": "0,24%",
                                          "ilceler": [
                                            {
                                                "district": "Merkez",
                                                "nufus": "137.613",
                                                "erkek_nufus": "70.666",
                                                "kadin_nufus": "66.947",
                                                "yuzolcumu": "1.273"
                                            },
                                            {
                                                "district": "Tuzluca",
                                                "nufus": "24.560",
                                                "erkek_nufus": "12.900",
                                                "kadin_nufus": "11.660",
                                                "yuzolcumu": "1.270"
                                            },
                                            {
                                                "district": "Aralık",
                                                "nufus": "21.311",
                                                "erkek_nufus": "11.329",
                                                "kadin_nufus": "9.982",
                                                "yuzolcumu": "852"
                                            },
                                            {
                                                "district": "Karakoyunlu",
                                                "nufus": "13.972",
                                                "erkek_nufus": "7.276",
                                                "kadin_nufus": "6.696",
                                                "yuzolcumu": "269"
                                            }
      
                                          ],
                                          "kisa_bilgi": "Ülkemizin en doğusun yer alan Iğdır, 1992 yılında Kars’tan ayrılarak il statüsüne kavuştu. Ermenistan, İran ve Azerbaycan ile sınır komşusu olması nedeniyle hem kültürel anlamda çeşitliliğe sahiptir, hem de ülkemizin üç ülkeyle sınırı olan tek şehridir. "
                                          },
      
      
                                          {
                                            "city": "Yalova",
                                            "plaka_kodu": "77",
                                            "alan_kodu": "226",
                                            "nufus": "262.234",
                                            "bolge": "Marmara",
                                            "yuzolcumu": "798",
                                            "erkek_nufus_yuzde": "50,11%",
                                            "erkek_nufus": "131.403",
                                            "kadin_nufus_yuzde": "49,89%",
                                            "kadin_nufus": "130.831",
                                            "nufus_yuzdesi_genel": "0,32%",
                                            "ilceler": [
                                              {
                                                  "district": "Merkez",
                                                  "nufus": "144.407",
                                                  "erkek_nufus": "71.400",
                                                  "kadin_nufus": "73.007",
                                                  "yuzolcumu": "139"
                                              },
                                              {
                                                  "district": "Çiftlikköy",
                                                  "nufus": "39.110",
                                                  "erkek_nufus": "19.610",
                                                  "kadin_nufus": "19.500",
                                                  "yuzolcumu": "136"
                                              },
                                              {
                                                  "district": "Çınarcık",
                                                  "nufus": "34.076",
                                                  "erkek_nufus": "16.812",
                                                  "kadin_nufus": "17.264",
                                                  "yuzolcumu": "178"
                                              },
                                              {
                                                  "district": "Altınova",
                                                  "nufus": "28.232",
                                                  "erkek_nufus": "15.009",
                                                  "kadin_nufus": "13.223",
                                                  "yuzolcumu": "113"
                                              },
                                              {
                                                  "district": "Armutlu",
                                                  "nufus": "9.625",
                                                  "erkek_nufus": "4.931",
                                                  "kadin_nufus": "4.694",
                                                  "yuzolcumu": "166"
                                              },
                                              {
                                                  "district": "Termal",
                                                  "nufus": "6.784",
                                                  "erkek_nufus": "3.641",
                                                  "kadin_nufus": "3.143",
                                                  "yuzolcumu": "67"
                                              }
                                            ],
                                            "kisa_bilgi": "Marmara Bölgesinde bulunan Yalova, 1995 yılında İstanbul’dan ayrılarak il statüsüne kavuştu. İlk yerleşim izlerinin M.Ö. 3000 yılına dek uzandığı, sağlık turizmiyle de ülkemizde önemli bir yeri olan Yalova’nın birbirinden güzel ilçeleri, özellikle İstanbullular için tam birer kaçış noktası niteliğine sahip."
                                            },
      
      
                                            {
                                              "city": "Karabük",
                                              "plaka_kodu": "78",
                                              "alan_kodu": "370",
                                              "nufus": "248.014",
                                              "bolge": "Karadeniz",
                                              "yuzolcumu": "4142",
                                              "erkek_nufus_yuzde": "50,24%",
                                              "erkek_nufus": "124.596",
                                              "kadin_nufus_yuzde": "49,76%",
                                              "kadin_nufus": "123.418",
                                              "nufus_yuzdesi_genel": "0,30%",
                                              "ilceler": [
                                                {
                                                    "district": "Merkez",
                                                    "nufus": "131.989",
                                                    "erkek_nufus": "65.268",
                                                    "kadin_nufus": "66.721",
                                                    "yuzolcumu": "790"
                                                },
                                                {
                                                    "district": "Safranbolu",
                                                    "nufus": "67.042",
                                                    "erkek_nufus": "34.561",
                                                    "kadin_nufus": "32.481",
                                                    "yuzolcumu": "750"
                                                },
                                                {
                                                    "district": "Yenice",
                                                    "nufus": "21.625",
                                                    "erkek_nufus": "10.820",
                                                    "kadin_nufus": "10.805",
                                                    "yuzolcumu": "777"
                                                },
                                                {
                                                    "district": "Eskipazar",
                                                    "nufus": "13.185",
                                                    "erkek_nufus": "6.814",
                                                    "kadin_nufus": "6.371",
                                                    "yuzolcumu": "754"
                                                },
                                                {
                                                    "district": "Eflani",
                                                    "nufus": "9.088",
                                                    "erkek_nufus": "4.476",
                                                    "kadin_nufus": "4.612",
                                                    "yuzolcumu": "674"
                                                },
                                                {
                                                    "district": "Ovacık",
                                                    "nufus": "5.085",
                                                    "erkek_nufus": "2.657",
                                                    "kadin_nufus": "2.428",
                                                    "yuzolcumu": "398"
                                                }
                                              ],
                                              "kisa_bilgi": "Batı Karadeniz bölgesinde yer alan Karabük en çok meşhur turistik beldesi Safranbolu ile biliniyor. 1994 yılında UNESCO Dünya Miras Listesi’ne alınan Safranbolu, 14. yy’dan bu yana Türklerin hakimiyetinde bulunuyor ve geleneksel şehir dokusunun olduğu gibi görülebileceği, koruma altında bulunan bir tarihi miras."
                                              },
      
      
                                              {
                                                "city": "Kilis",
                                                "plaka_kodu": "79",
                                                "alan_kodu": "348",
                                                "nufus": "142.541",
                                                "bolge": "Güneydoğu Anadolu",
                                                "yuzolcumu": "1412",
                                                "erkek_nufus_yuzde": "51,10%",
                                                "erkek_nufus": "72.841",
                                                "kadin_nufus_yuzde": "48,90%",
                                                "kadin_nufus": "69.700",
                                                "nufus_yuzdesi_genel": "0,17%",
                                                "ilceler": [
                                                  {
                                                      "district": "Merkez",
                                                      "nufus": "116.034",
                                                      "erkek_nufus": "59.081",
                                                      "kadin_nufus": "56.953",
                                                      "yuzolcumu": "610"
                                                  },
                                                  {
                                                      "district": "Musabeyli",
                                                      "nufus": "14.620",
                                                      "erkek_nufus": "7.494",
                                                      "kadin_nufus": "7.126",
                                                      "yuzolcumu": "346"
                                                  },
                                                  {
                                                      "district": "Elbeyli",
                                                      "nufus": "6.526",
                                                      "erkek_nufus": "3.534",
                                                      "kadin_nufus": "2.992",
                                                      "yuzolcumu": "238"
                                                  },
                                                  {
                                                      "district": "Polateli",
                                                      "nufus": "5.361",
                                                      "erkek_nufus": "2.732",
                                                      "kadin_nufus": "2.629",
                                                      "yuzolcumu": "218"
                                                  }
      
                                                ],
                                                "kisa_bilgi": "Suriye ile komşu olan ve 1995 yılından bu yana il statüsünde bulunan Kilis’in tarihi M.Ö. 3 bin yıllarına dek uzanıyor. Zengin tarihi ve kültürel dokuya sahip Kilis’te görebileceğiniz pek çok yer var: Konik bir tepe üzerine kurulu, Haçlı Seferlerine uzanan tarihiyle Ravanda Kalesi, yerel ağızda çeşme anlamına gelen kastelleri, hayranlık uyandıran hamamları (Eski Hamam, Paşa Hamamı, Hoca Hamamı, Tuğlu Hamamı gibi), Neşet Efendi Konağı, Kilis Evi, geleneksel konakları, Ahmet Bey Konağı sayabileceklerimiz arasında."
                                              },
      
      
                                              {
                                                "city": "Osmaniye",
                                                "plaka_kodu": "80",
                                                "alan_kodu": "328",
                                                "nufus": "534.415",
                                                "bolge": "Akdeniz",
                                                "yuzolcumu": "3320",
                                                "erkek_nufus_yuzde": "50,50%",
                                                "erkek_nufus": "269.875",
                                                "kadin_nufus_yuzde": "49,50%",
                                                "kadin_nufus": "264.540",
                                                "nufus_yuzdesi_genel": "0,65%",
                                                "ilceler": [
                                                  {
                                                      "district": "Merkez",
                                                      "nufus": "264.373",
                                                      "erkek_nufus": "132.484",
                                                      "kadin_nufus": "131.889",
                                                      "yuzolcumu": "859"
                                                  },
                                                  {
                                                      "district": "Kadirli",
                                                      "nufus": "124.053",
                                                      "erkek_nufus": "62.017",
                                                      "kadin_nufus": "62.036",
                                                      "yuzolcumu": "1.021"
                                                  },
                                                  {
                                                      "district": "Düziçi",
                                                      "nufus": "83.971",
                                                      "erkek_nufus": "42.202",
                                                      "kadin_nufus": "41.769",
                                                      "yuzolcumu": "595"
                                                  },
                                                  {
                                                      "district": "Bahçe",
                                                      "nufus": "22.242",
                                                      "erkek_nufus": "11.189",
                                                      "kadin_nufus": "11.053",
                                                      "yuzolcumu": "208"
                                                  },
                                                  {
                                                      "district": "Toprakkale",
                                                      "nufus": "20.127",
                                                      "erkek_nufus": "11.881",
                                                      "kadin_nufus": "8.246",
                                                      "yuzolcumu": "112"
                                                  },
                                                  {
                                                      "district": "Sumbas",
                                                      "nufus": "14.308",
                                                      "erkek_nufus": "7.328",
                                                      "kadin_nufus": "6.980",
                                                      "yuzolcumu": "358"
                                                  },
                                                  {
                                                      "district": "Hasanbeyli",
                                                      "nufus": "5.341",
                                                      "erkek_nufus": "2.774",
                                                      "kadin_nufus": "2.567",
                                                      "yuzolcumu": "168"
                                                  }
      
                                                ],
                                                "kisa_bilgi": "Akdeniz bölgesi ile Çukurova’nın doğusunda bulunan, yer fıstığıyla meşhur Osmaniye, 1933 yılına kadar vilayet iken, kazaya indirilmiş; ancak 1996 yılında tekrar il statüsüne kavuşmuştur. Akarsu ve bitki örtüsü zenginliğiyle dikkati çeken il, Zorkun-Olukbaşı-Ürün ve Kadirli Almacık gibi güzel yaylalara sahip."
                                              },
      
      
                                              {
                                                "city": "Düzce",
                                                "plaka_kodu": "81",
                                                "alan_kodu": "380",
                                                "nufus": "387.844",
                                                "bolge": "Karadeniz",
                                                "yuzolcumu": "2.492",
                                                "erkek_nufus_yuzde": "50,03%",
                                                "erkek_nufus": "194.051",
                                                "kadin_nufus_yuzde": "49,97%",
                                                "kadin_nufus": "193.793",
                                                "nufus_yuzdesi_genel": "0,47%",
                                                "ilceler": [
                                                  {
                                                      "district": "Merkez",
                                                      "nufus": "240.633",
                                                      "erkek_nufus": "118.923",
                                                      "kadin_nufus": "121.710",
                                                      "yuzolcumu": "710"
                                                  },
                                                  {
                                                      "district": "Akçakoca",
                                                      "nufus": "38.846",
                                                      "erkek_nufus": "19.060",
                                                      "kadin_nufus": "19.786",
                                                      "yuzolcumu": "380"
                                                  },
                                                  {
                                                      "district": "Kaynaşlı",
                                                      "nufus": "20.772",
                                                      "erkek_nufus": "10.344",
                                                      "kadin_nufus": "10.428",
                                                      "yuzolcumu": "237"
                                                  },
                                                  {
                                                      "district": "Gölyaka",
                                                      "nufus": "20.353",
                                                      "erkek_nufus": "10.237",
                                                      "kadin_nufus": "10.116",
                                                      "yuzolcumu": "228"
                                                  },
                                                  {
                                                      "district": "Çilimli",
                                                      "nufus": "20.266",
                                                      "erkek_nufus": "11.688",
                                                      "kadin_nufus": "8.578",
                                                      "yuzolcumu": "85"
                                                  },
                                                  {
                                                      "district": "Yığılca",
                                                      "nufus": "16.432",
                                                      "erkek_nufus": "8.401",
                                                      "kadin_nufus": "8.031",
                                                      "yuzolcumu": "636"
                                                  },
                                                  {
                                                      "district": "Gümüşova",
                                                      "nufus": "15.647",
                                                      "erkek_nufus": "7.862",
                                                      "kadin_nufus": "7.785",
                                                      "yuzolcumu": "103"
                                                  },
                                                  {
                                                      "district": "Cumayeri",
                                                      "nufus": "14.895",
                                                      "erkek_nufus": "7.536",
                                                      "kadin_nufus": "7.359",
                                                      "yuzolcumu": "113"
                                                  }
      
                                                ],
                                                "kisa_bilgi": "Tarihi Hititlere dek uzanan, İstanbul-Ankara karayolunun önemli kavşak noktalarından biri konumundaki Düzce, deniz ve doğa severler için cazip bir yer. Motor, bisiklet ve havacılık sporları, rafting, kamp, karavan tatili, yelken ve dalış gibi su sporları, yayla, göl, mağara turizmi gibi çok çeşitli aktivitelere olanak sağlayan il, büyük şehirlerden kaçıp kafa dinlemek isteyenler için harika bir seçenek. "
                                                }
        ]

