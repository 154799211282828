import { Location } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NbAuthService, NbTokenService } from '@nebular/auth';
import { NbIconLibraries, NbNativeDateService } from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { SeoService } from './@core/utils/seo.service';
import { responseKodlari } from './pages/constants';
import { AuthService } from './services/auth.service';
import { LangTranslateService } from './services/lang.service';
import * as bildirimActions from './store/bildirim/bildirim.actions';
import { selectBildirimLoaded } from './store/bildirim/bildirim.connector';
import * as fromUser from './store/user/user.connector';
import * as aplikasyonActions from './store/aplikasyon/aplikasyon.actions';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { asyncLocalStorage } from './pages/global-functions';
import { SetOrgInfo } from './store/organization/organization.actions';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'ngx-app',
  templateUrl: 'app.component.html',
  providers: [NbAuthService, NbTokenService, AuthService, LangTranslateService, Location],

})


export class AppComponent implements OnInit, AfterViewChecked {
  lang$: Subject<string> = new Subject<string>();
  tag: string = 'AppComponent --->';

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  loading: boolean = true;
  deviceInfo = null;
  authState: boolean;

  constructor(
    private iconLibraries: NbIconLibraries,
    private seoService: SeoService,
    public translate: LangTranslateService,
    public trs: TranslateService,
    private cd: ChangeDetectorRef,
    private store: Store<{}>,
    private router: Router,
    private spinner: NgxSpinnerService,
    private deviceService: DeviceDetectorService,
    private authService: NbAuthService,

  ) {

    this.store.pipe(select(fromUser.selectUserLoaded)).subscribe(isLoadedUser => {
      if (isLoadedUser) {
        this.store.pipe(select(selectBildirimLoaded)).subscribe(isLoaded => {
          if (!isLoaded) {
            this.store.dispatch(bildirimActions.loadBildirim({}));
          }
        }).unsubscribe();
      }
    });

   


    //#endregion 
    this.checkConnection();
    this.setNabularSettings();
    this.subscribeLanguage();
    this.aygitInfo();// Kullanıcı Aygıtını algılıyoruz



  }


  setNabularSettings() {
    this.iconLibraries.registerFontPack('custom-icons');
    this.iconLibraries.registerFontPack('font-awesome', { prefix: 'fas' });
  }

  subscribeLanguage() {
    /*
    * Eğer store üzerinde kullanıcı verisi varsa, dili kullanıcı versine atar.
    * Eğer store üzerinde kullanıcı verisi yoksa, dili browser verisine göre atar.
    * Eğer browser dili de okunamıyorsa default değer en.
    */

    // DEFAULT LANG ? BROWSER LANG ? USER LANG
    const browserLang = this.translate.translateS.getBrowserLang();

    const bLang = browserLang.match(/en|tr/) ? browserLang : 'tr';
    this.lang$.next(bLang);

    this.store.pipe(select(fromUser.selectUser)).subscribe(user => {
      if (user?.dil) {
        this.lang$.next(user.dil);
      }
    });

    this.lang$.subscribe(val => this.setLanguage(val ? val : 'tr'));
  }

  setLanguage(lang: string) {
    this.store.dispatch(aplikasyonActions.SetLang({ lang: lang }));
  }

  checkConnection() {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      setTimeout(() => {

        Swal.fire({
          ...responseKodlari.offline,
        } as SweetAlertOptions);

      }, 3000);
    }));


  }

  ngOnInit(): void {
    this.seoService.trackCanonicalChanges();
    this.router.events.subscribe(router => {
      if (router instanceof NavigationStart)
        this.spinner.show();
      else if (router instanceof NavigationEnd)
        this.spinner.hide();
      else if (router instanceof NavigationCancel)
        this.spinner.hide();
      else if (router instanceof NavigationError)
        this.spinner.hide();
    });

    this.checkOrgInfo();
  }

  async checkOrgInfo() {
    let orgInfo: any = await asyncLocalStorage.getItem('org');
    orgInfo = JSON.parse(orgInfo);
    if (orgInfo && orgInfo?.apas_id) {
      this.store.dispatch(SetOrgInfo({ ORG: orgInfo }));
    }
  }

  aygitInfo() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo) {
      this.store.dispatch(aplikasyonActions.setAygitBilgisi(this.deviceInfo));
    }

  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }





}

