
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AlisSatisModel } from '../../models/cari_hakeret.model';
import * as alisSatisActions from './alis-satis.actions';
import { AlisState, SatisState } from './alisSatisInterfaces';




export const AlisAdapter: EntityAdapter<AlisSatisModel> = createEntityAdapter<AlisSatisModel>({
    sortComparer: sortBy,
});


export const defaultAlisState: AlisState = {
    ids: [],
    entities: {},
    selectedAlis: null,
    loading: false,
    loaded: false,
    form: {
        page: 0,
        count: 20,
        search: null,
        order_by: null,
        filter: {
            cari_id: null,
            start_date: null,
            end_date: null,
            with_fatura: true,
            without_fatura: true,
            tags: null,
        },
    },
    error: '',
    isEnd: false,
};

let sortKey = 'date';
let sortDirection = true;

function setSort(sortStr: string) {
    if (sortStr.charAt(0) === '-') {
        sortDirection = false;
        sortKey = sortStr.substring(1);
    } else {
        sortDirection = true;
        sortKey = sortStr;
    }
}

export function sortBy(a: AlisSatisModel, b: AlisSatisModel): number {


    if (typeof b[sortKey] === 'string') {
        if (sortDirection) {
            return b[sortKey].localeCompare(a[sortKey]);
        }
        return a[sortKey].localeCompare(b[sortKey]);

    } else {
        if (sortDirection) {
            return b[sortKey] - a[sortKey];
        }
        return a[sortKey] - b[sortKey];
    }
}

export const SatisAdapter: EntityAdapter<AlisSatisModel> = createEntityAdapter<AlisSatisModel>({
    sortComparer: sortBy,
});

export const defaultSatisState: SatisState = {
    ids: [],
    entities: {},
    selectedSatis: null,
    loading: false,
    loaded: false,
    form: {
        page: 0,
        count: 20,
        search: null,
        order_by: null,
        filter: {
            cari_id: null,
            start_date: null,
            end_date: null,
            with_fatura: true,
            without_fatura: true,
            tags: null,
        },
    },
    error: '',
    isEnd: false,
};


const alisInitialState = AlisAdapter.getInitialState(defaultAlisState);
const satisInitialState = SatisAdapter.getInitialState(defaultSatisState);

export const defaultAlisSatisState = {
    Alislar: alisInitialState,
    Satislar: satisInitialState,
};


const alisSatisReducer = createReducer(
    defaultAlisSatisState,
    on(alisSatisActions.loadAlis, (state, props: any) => {
        if (props.order_by) {
            setSort(props.order_by);
        }
        return { ...state, Alislar: { ...state.Alislar, loading: true, form: props } };
    }),
    on(alisSatisActions.loadAlisSuccess, (state, props) => {
        // tslint:disable-next-line: max-line-length
        return {
            ...state,
            Alislar: {
                ...AlisAdapter.upsertMany(props.data, state.Alislar),
                loading: false,
                loaded: true,
                isEnd: props.isEnd,
            },
        };
    }),
    on(alisSatisActions.loadAlisFail, (state, props) => {
        return {
            ...state,
            Alislar: { ...state.Alislar, loading: false, loaded: false, isEnd: false, error: props.error },
        };
    }),
    // on(alisSatisActions.addUpAlisItem, (state, props: any) => {
    //     return { ...state, Alislar: { ...AlisAdapter.upsertOne(props, state.Alislar) } };
    // }),
    on(alisSatisActions.addUpAlisSuccess, (state, props: any) => {
        return { ...state, Alislar: { ...AlisAdapter.upsertOne(props, state.Alislar) } };
    }),
    on(alisSatisActions.selectAlis, (state, props: any) => {
        return { ...state, Alislar: { ...state.Alislar, selectedAlis: props.id } };
    }),
    on(alisSatisActions.deleteAlis, (state, props: any) => {
        return { ...state, Alislar: { ...AlisAdapter.removeOne(props.id, state.Alislar) } };
    }),
    on(alisSatisActions.setAlisFilter, (state, props: any) => {
        if (props.order_by) {
            setSort(props.order_by);
        }
        return { ...state, Alislar: { ...state.Alislar, loading: true, form: props } };
    }),
    on(alisSatisActions.clearAlis, (state, props: any) => {
        return { ...state, Alislar: { ...defaultAlisState } };
    }),
    on(alisSatisActions.loadSatis, (state, props: any) => {
        if (props.order_by) {
            setSort(props.order_by);
        }

        return { ...state, Satislar: { ...state.Satislar, loading: true, form: props } };
    }),
    on(alisSatisActions.loadSatisSuccess, (state, props) => {
        // tslint:disable-next-line: max-line-length
        return {
            ...state,
            Satislar: {
                ...SatisAdapter.upsertMany(props.data, state.Satislar),
                loading: false,
                loaded: true,
                isEnd: props.isEnd,
            },
        };
    }),

    on(alisSatisActions.loadSatisFail, (state, props) => {
        return {
            ...state, Satislar:
                { ...state.Satislar, loading: false, loaded: false, isEnd: props.isEnd, error: props.error },
        };

    }),

    on(alisSatisActions.addUpSatisSuccess, (state, props: any) => {
        return { ...state, Satislar: { ...SatisAdapter.upsertOne(props, state.Satislar) } };
    }),
    on(alisSatisActions.selectSatis, (state, props: any) => {
        return { ...state, Satislar: { ...state.Satislar, selectedSatis: props.id } };
    }),
    on(alisSatisActions.deleteSatis, (state, props: any) => {
        return { ...state, Satislar: { ...SatisAdapter.removeOne(props.id, state.Satislar) } };
    }),
    on(alisSatisActions.setSatisFilter, (state, props: any) => {
        if (props.order_by) {
            setSort(props.order_by);
        }
        return { ...state, Satislar: { ...state.Satislar, loading: true, form: props } };
    }),
    on(alisSatisActions.clearSatis, (state, props: any) => {
        return { ...state, Satislar: { ...defaultSatisState } };
    }),



);

export function AlisSatisReducer(action, state) {
    return alisSatisReducer(action, state);
}







