import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MarkaService {
  private readonly API_URL = `${environment.backendurl}api/depo/brand/`;

  constructor(private httpClient: HttpClient) { }

  searchBrand(search?: string): Observable<any[]> {
    const data = search ? { search: search } : {};
    const url: string = `${this.API_URL}search/`;
    return this.httpClient.post<any[]>(url, data);
  }

  detail(instanceId): Observable<any[]> {
    const url: string = `${this.API_URL}detail/${instanceId}/`;
    return this.httpClient.get<any[]>(url);
  }

  adBrand(data): Observable<any> {
    const url: string = `${this.API_URL}add/`;
    return this.httpClient.post<any>(url, data);

  }
}
