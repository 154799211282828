import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import * as socketTokenActions from '../store/socketToken/sockettoken.action';
import * as socketTokenConnector from '../store/socketToken/sockettoken.connector';
import { OrganizasyonService } from './organizasyon.service';

@Injectable()
export class SocketTokenService {
    private readonly API_URL = `${environment.backendurl}api/akis/`;

    constructor(
        private httpClient: HttpClient,
        private store: Store<{}>,
        private organizationService: OrganizasyonService,
    ) {
        this.setOrgStatus();
    }

    connectionToken() {
        const url: string = `${this.API_URL}token/`;
        return this.httpClient.get(url);
    }

    getSocketToken() {
        this.connectionToken().subscribe((val: any) => {
            this.store.dispatch(socketTokenActions.setConnectionToken(val));
        });
    }

    public socketToken$ = this.store.select(socketTokenConnector.selectToken);

    public orgSocketStatus$: Subject<boolean> = new Subject<boolean>();
    orgApasId;

    getOrgApasId() {
        return this.orgApasId;
    }
    setOrgStatus() {
        this.organizationService.org$.subscribe(orgInfo => {
            if (orgInfo?.apas_id) {
                this.orgApasId = orgInfo['apas_id'];
                this.orgSocketStatus$.next(true);
            } else {
                this.orgSocketStatus$.next(false);
            }
        });

    }
}
