<nb-card accent="primary" class="m-user" style="min-height: 48rem;">
  <nb-card-header class="m-header">
    <div class="row" style="align-items: center;">
      <div class="col-md-2">
        {{ 'PERSONEL.PERSONELTITLE' | translate }}
      </div>
      <div class="col-md">
        <!-- <button nbButton style="background-color: #ff704345 !important;"></button>Apas Üyesi Olanlar
          <button nbButton style="background-color: #daeaf5  !important;"></button>Apas Üyesi Olmayanlar
  -->

        <button size="small" nbButton status="primary" style="float:right"
          [routerLink]="['/pages/personel/firma-personel-ekle']" routerLinkActive="router-link-active">
          {{ 'PERSONEL.PERSONELEKLE2' | translate }}
        </button>
        <button size="small" nbButton status="warning" (click)="grupOlsutur()" style="float:right">{{ 'PERSONEL.PERSONELGURUP' | translate }}</button>
        <button size="small" nbButton outline status="info" style="float:right">{{ 'PERSONEL.TOPLAMPERSONEL' | translate }}
          {{personel_toplam}}</button>
        <!--Personel Listeleme Görünüm-->
        <button nbButton size="small" style="float:right" status='basic' (click)="personelGorunum(2)">
          <nb-icon icon="list-outline"></nb-icon>
        </button>

        <button nbButton size="small" style="float:right" status="primary" (click)="personelGorunum(1)">
          <nb-icon icon="grid-outline"></nb-icon>
        </button>

        <button nbButton routerLink="../personel-main"> Personel Main</button>

        <!--// Personel Listeleme Görünüm-->
      </div>
    </div>
  </nb-card-header>
  <nb-card-body [nbSpinner]="isLoading" nbSpnnerSize="tiny">

    <div class="row" *ngIf="goster">
      <div class="col-md">
        <!--Tüm Personelleri Göster-->
        <nb-checkbox status="info" [disabled]="personelGoster === 'tum'" [checked]="personelGoster === 'tum'"
          (checkedChange)="personelListele('tum')">
          {{ 'PERSONEL.TUMPEROSNEL' | translate }}
        </nb-checkbox>
        <!--// Tüm Personelleri Göster-->

        <!--Apas Üyesi Olanları Göster-->
        <nb-checkbox status="info" [disabled]="personelGoster === 'apasUye'" [checked]="personelGoster === 'apasUye'"
          class="ml-2" (checkedChange)="personelListele('apasUye')">
          {{ 'PERSONEL.TUMYETKILI' | translate }}
        </nb-checkbox>
        <!--// Apas Üsyesi Olanları Göster-->

        <!--Apas Üyesi Olmayanları Göster-->
        <nb-checkbox status="info" [disabled]="personelGoster === 'apasUyeDegil'"
          [checked]="personelGoster === 'apasUyeDegil'" class="ml-2" (checkedChange)="personelListele('apasUyeDegil')">
          {{ 'PERSONEL.YETKILIOLMAYAN' | translate }}
        </nb-checkbox>
        <!--// Apas Üyesi Olmayanları Göster-->

        <!--Pasif Üyeleri Göster-->
        <nb-checkbox status="info" [disabled]="personelGoster === 'apasUyePasif'"
          [checked]="personelGoster === 'apasUyePasif'" class="ml-2" (checkedChange)="personelListele('apasUyePasif')">
          {{ 'PERSONEL.PASIFPERSONEL' | translate }} 
        </nb-checkbox>
        <!--// Pasif Üyeleri Göster-->
      </div>


    </div>
    <br>

    <div class="row" *ngIf="goster && current_personel">
      <!--Personel Listesi-->
      <ng-container *ngIf="current_personel.length > 0 else noData">
        <div class="col-xxl-4 col-md-{{colValue?colValue:'4'}}" *ngFor="let item of current_personel">
          <nb-flip-card #personelFlipCard [nbSpinner]="personelGroupLoadings[item.apas_id]" nbSpinnerSize="tiny"
            [showToggleButton]="false">
            <nb-card-front>

              <nb-card style="border-top-width: 0rem !important;height: 12rem;" [class.isUser]="!item.is_my_user" [class.isUserTrue]="item.is_my_user" >
                <nb-card-body style=" overflow: hidden;"  >
                  <div class="row" style="align-items: center;" >
                    <div class="col-xxl-9 col-md-9">
                      <nb-user size="large" name={{item.name}} [title]="item.job_id?.name" [picture]="item?.image ? prefixBase64+item?.image : null"
                      > </nb-user>

                      <small style="margin-left: 55px;" class="personel_apas_id">
                        <!--Plus Svg-->
                        <svg class="plus" version="1.0" height="17" width="17" xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 76.000000 60.000000" preserveAspectRatio="xMidYMid meet"
                          style="margin-left: -5px;">

                          <g transform="translate(0.000000,60.000000) scale(0.100000,-0.100000)" stroke="none">
                            <path d="M310 460 l0 -90 -90 0 -90 0 0 -75 0 -75 90 0 90 0 0 -85 0 -85 75 0
                        75 0 0 85 0 85 90 0 90 0 0 75 0 75 -90 0 -90 0 0 90 0 90 -75 0 -75 0 0 -90z" />
                          </g>
                        </svg>
                        <!--//Plus Svg-->
                        <b>{{ 'PERSONEL.APASID' | translate }} {{item.apas_id}}</b></small>
                    </div>
                    <div class="col-xxl-1 col-md-1">
                      <button nbButton size="tiny" status="info" [nbTooltip]="item.phone">
                        <nb-icon icon="phone-outline"></nb-icon>
                      </button>
                      <a [href]="'mailto:'+item.email">
                        <button *ngIf="item.is_my_user" nbButton size="tiny" status="warning" [nbTooltip]="item.email">
                          <nb-icon icon="email-outline"></nb-icon>
                        </button>
                      </a>
                      <button nbButton size="tiny" (click)="personelDetay(item)" status="primary">
                        <nb-icon icon="eye-outline"></nb-icon>
                      </button>
                      <!-- <button nbButton size="tiny" (click)="personelGruplariniGetir(item,personelFlipCard)" nbTooltip="Personel Guruplarını Göster">
                        <nb-icon icon="people-outline"></nb-icon>
                      </button> -->
                    </div>

                    <div class="col-md-12 text-center mt-3" *ngIf="!item.is_my_user && item.is_active">
                      <button size="tiny" nbButton status="warning" (click)="yetkiliKullanici(item)"
                        routerLinkActive="router-link-active"
                        nbTooltip="Personel Firma Bilgilerine Ulaşabilir Olacaktır.">{{ 'PERSONEL.YETKILITANIMLA' | translate }}
                        </button>

                    </div>
                    <div class="col-md-12 text-center mt-3" *ngIf="!item.is_active">
                      <button size="tiny" nbButton status="warning" (click)="yenidenPersonel(item)"
                        routerLinkActive="router-link-active"
                        nbTooltip="Personel Firma Bilgilerine Ulaşabilir Olacaktır.">{{ 'PERSONEL.YENIDENPERSONEL' | translate }}</button>
                    </div>
                  </div>

                </nb-card-body>

                <nb-card-footer style="padding: 0.50rem 1.25rem !important;" [ngStyle]="{'background-color': item.is_admin ? '#90CAF9' : ''}">
                  <button size="tiny" nbButton status="warning"
                    (click)="personelGruplariniGetir(item,personelFlipCard)">{{ 'PERSONEL.GRUBUGOR' | translate }}
                     </button>

                </nb-card-footer>
              </nb-card>

            </nb-card-front>
            <nb-card-back>
              <nb-card style="border-top-width: 0rem !important;height: 12rem;">
                <nb-card-body>
                 
                      <nb-accordion class="w-100">
                        <nb-accordion-item *ngFor=" let item of gruplar;let index=index">
                          <nb-accordion-item-header>{{item.title}}</nb-accordion-item-header>
                          <nb-accordion-item-body>
                         
                            <nb-user *ngFor="let users of item.assign_to_ids" [name]="users.name" size="tiny">
                            </nb-user>
                       
                          </nb-accordion-item-body>
                        </nb-accordion-item>
                      </nb-accordion>

                </nb-card-body>
                <nb-card-footer style="padding: 0.50rem 1.25rem !important;">
                  <button size="tiny" nbButton status="warning"
                    (click)="personelGruplariniGetir(undefined,personelFlipCard)">{{ 'PERSONEL.ERSONEL' | translate }}</button>
                </nb-card-footer>
              </nb-card>
            </nb-card-back>
          </nb-flip-card>
        </div>

        <!-- Yeni Personel Ekle -->
        <div class="col-xxl-4 col-md-{{colValue?colValue:'4'}}" >

              <nb-card style="border-top-width: 0rem !important;height: 12rem;">
                <nb-card-body style=" overflow: hidden;">
                  <div class="d-flex justify-content-center h-100">
                    <button nbButton size="small" status="basic" [routerLink]="['/pages/personel/firma-personel-ekle']"  routerLinkActive="router-link-active">{{ 'PERSONEL.PERSONELEKLE2' | translate }}</button>

                  </div>
                </nb-card-body>
              </nb-card>

        </div>
      </ng-container>
      <ng-template #noData><b style="width: 100%;height: 100%;text-align: center;">
          {{isLoading?'Lütfen bekleyin.':'Personel bulunamadı.'}}</b></ng-template>
      <!--// Personel Listesi-->
    </div>
  </nb-card-body>
</nb-card>