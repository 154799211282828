import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { SetLang } from '../store/aplikasyon/aplikasyon.actions';

@Injectable()
export class LangTranslateService {
    currentLang: string;
    supportedLangs = ['tr', 'en'];
    translateS;
    constructor(public translate: TranslateService,
                public store: Store<{lang}>) {
        translate.addLangs(['tr', 'en']);
        this.translateS = translate;
        translate.setDefaultLang('tr');

        translate.onLangChange.subscribe(
            data => {
                this.currentLang = data.lang;
            },
        );
    }

    setLang(lang: string) {
        this.translate.use(lang);
        this.store.dispatch(SetLang({lang: lang}));
        this.currentLang = lang;
    }

    setDefaultLang(lang: string) {
        this.translate.setDefaultLang(lang);
    }
}
