import { createSelector } from '@ngrx/store';
import * as fromRoot from '../../app-state';
import { akisAdapter, AkisState } from './akis.reducer';

const getAkisFeatureState = createSelector(
        fromRoot.getOrganizationSelector, (state: fromRoot.OrganizationState) => state?.Akis,
);
const selectAkis = createSelector(
        getAkisFeatureState,
        akisAdapter.getSelectors().selectAll,
);

const selectAkisLoading = createSelector(
        getAkisFeatureState,
        (state: AkisState) => state.loading,
);

const selectAkisLoaded = createSelector(
        getAkisFeatureState,
        (state: AkisState) => state.loaded,
);
const selectAkisEnd = createSelector(
        getAkisFeatureState,
        (state: AkisState) => state.end,
);
const selectAkisError = createSelector(
        getAkisFeatureState,
        (state: AkisState) => state.error,
);

const getCurrentAkisId = createSelector(getAkisFeatureState,
        (state: AkisState) => state.selectedAkisId);

const selectAkisItem = createSelector(
        getAkisFeatureState,
        getCurrentAkisId,
        state => state.entities[state.selectedAkisId],
);
const connectionStatus = createSelector(
        getAkisFeatureState,
        (state: AkisState) => state.connection,
);

export {
        selectAkis,
        selectAkisLoading,
        selectAkisLoaded,
        selectAkisEnd,
        selectAkisError,
        getCurrentAkisId,
        selectAkisItem,
        connectionStatus,
};
