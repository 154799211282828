import { createReducer, on } from '@ngrx/store';
import { DeviceInfo } from 'ngx-device-detector';
import { environment } from '../../../environments/environment';
import { LocationModel } from '../../models/location.model';
import * as aplikasyonActions from './aplikasyon.actions';


interface AplikasyonState {
    lang: string;
    location: LocationModel;
    url: string;
    aygit: DeviceInfo;
}

const defaultAplikasyonSate = {
    lang: 'tr',
    redirect: false,
    location: {
        lat: 37,
        lng: 37,
        city: '',
        country: '',
        address: '',
    },
    url: environment.backendurl,
    aygit: {
        browser: null,
        browser_version: null,
        device: null,
        deviceType: null,
        orientation: null,
        os: null,
        os_version: null,
        userAgent: null,
    },
};

const aplikasyonReducer = createReducer(
    defaultAplikasyonSate,
    on(aplikasyonActions.SetLang, (state, props) => {
        return { ...state, lang: props.lang };
    }),
    on(aplikasyonActions.SetUrl, (state, props) => {
        return { ...state, url: props.URL };
    }),
    on(aplikasyonActions.SetLocation, (state, props) => {
        return { ...state, location: props.curLoc };
    }),
    on(aplikasyonActions.setAygitBilgisi, (state, props) => {
        return { ...state,  aygit: props };
    }),
);

function AplikasyonReducer(state, action) {
    return aplikasyonReducer(state, action);
}

export {
    AplikasyonState,
    defaultAplikasyonSate,
    AplikasyonReducer,
};
