import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ColumnMode, SortType } from '@swimlane/ngx-datatable';
import { Workbook } from 'exceljs';
import { Observable, Subject } from 'rxjs';
import * as fs from 'file-saver';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { SantiyeModel } from '../../../models/santiye_model';
import { TalepItemModel, TalepModel } from '../../../models/talep.model';
import { TedarikciModel } from '../../../models/tedarikci.model';
import {
  birim,
  Constants,
  currency,
  getMoneyCurrency,
  responseKodlari,
  tarih_format,
  tarihFullFormat,
  getBirim,
  bordered,
} from '../../../pages/constants';
import { convertDateWithTZ, downLoadFile, removeAllNull, alphabeticIndex, addOrUpdate } from '../../../pages/global-functions';
import { TedarikciService } from '../../../services/tedarikci.service';
import { TeklifService } from '../../../services/teklifler.service';
import OrgInfoModel from '../../../models/orgInfo.model';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { KullaniciService } from '../../../services/kullanici.service';
import { Location } from '@angular/common'


export enum GenelTekliflerTeklifType {
  Hizmet,
  Talep,
  Genel,
}
@Component({
  selector: 'gelen-teklifler-list',
  templateUrl: './gelen-teklifler-list.component.html',
  styleUrls: ['./gelen-teklifler-list.component.scss'],
  providers: [OrganizasyonService, KullaniciService],
  encapsulation: ViewEncapsulation.None,
})
export class GelenTekliflerListComponent implements OnInit, OnDestroy {
  tag: string = 'GelenTekliflerListComponent --->';
  @Input() teklif_veren_id = null;
  @Input() headerShow = true;
  @Input() filterShow = true;

  orgInfo: OrgInfoModel;
  teklifId: number;
  genelTekliflerTeklifType = GenelTekliflerTeklifType;

  teklifType: GenelTekliflerTeklifType;
  user;

  birim = birim;
  currency = currency;
  getMoneyCurrency = getMoneyCurrency;
  convert = Constants.prototype;

  santiyes: SantiyeModel[] = null;
  santiyeLoading: boolean = true;
  selectedCurr = 1;
  brand$: Observable<any[]>;
  brandList: any[] = [];
  brandLoading: boolean = false;
  brandInput$ = new Subject<string>();
  default_brand_items: any[];
  genelTeklifGoster: boolean = false;
  talep_select_box: NgSelectComponent;

  //  myTable2

  expanded: any = {};
  timeout: any;
  ColumnMode = ColumnMode;
  SortType = SortType;
  duration: number;
  rows2;
  talepler$: Observable<TalepModel[]>;
  talepItemList: TalepItemModel[] = [];
  selectedTab: boolean = true;
  islem = [];
  talepLoading = false;
  talepInput$ = new Subject<string>();
  _selected_santiye: number;
  default_items: TalepModel[];
  teklifEnd = false;
  tedarikciler: TedarikciModel[];
  urun_list: any[] = [];
  firmalar: any[] = [];
  teklifler: any[] = [];
  isUpdate: boolean;
  form: FormGroup;
  tarihFullFormat = tarihFullFormat;
  isLoading: boolean = false;
  dosyaSpinner: boolean = false;
  sonHarf;
  get teklif_groupFormArray(): FormArray {
    return this.form.get('teklif_group') as FormArray;
  }

  get talepBasligi(): FormControl {
    return this.form.get('talep_id') as FormControl;
  }
  get tekliVereId(): FormControl {
    return this.form.get('teklif_veren_id') as FormControl;
  }

  teklif: any;
  // talep listesinin varlığını sorgular :D
  isTaleplistAvailable: Subject<boolean>;

  tarih_format = tarih_format;
  isSubmitting: boolean = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private teklifService: TeklifService,
    private tedarikci: TedarikciService,
    private organizasyonService: OrganizasyonService,
    private kullaniciService: KullaniciService,
    private location: Location
  ) {

    this.orgSubscription();
    this.userSubscribe();
  }

  organizationSubscription;
  orgSubscription() {
    this.organizationSubscription = this.organizasyonService.org$.subscribe(org => this.orgInfo = org);
  }
  userSubscription;
  userSubscribe() {
    this.userSubscription = this.kullaniciService.user$.subscribe(user => this.user = user);
  }
  ngOnDestroy(): void {
    if (this.organizationSubscription) this.organizationSubscription.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
  }


  ngOnInit() {
    this.teklifEnd = this.teklif_veren_id ? true : false;
    this.initForm();
    this.getTeklifList();
    this.tedarikci.getTedarikci().subscribe(data => {
      this.tedarikciler = data;
    });

  }

  initForm() {
    this.form = this.fb.group({
      search: [],
      teklif_veren_id: [],
      page: [0],
      count: [20],
      start_date: [],
      end_date: [],
      order_by: [],
      is_active: [true],
    });
    this.form.get('search').valueChanges.subscribe(() => {
      setTimeout(() => {
        this.getFilteredData();
      }, 150);
    });
    this.form.get('is_active').valueChanges.subscribe(() => {
      setTimeout(() => {
        this.getFilteredData();
      }, 150);
    });
  }
  getFilteredData() {
    this.isLoading = true;
    this.teklifler = [];
    this.teklifEnd = this.teklif_veren_id ? true : false;
    this.form.get('page').setValue(0);
    this.getTeklifList();
  }



  goToNextPage() {
    if (!this.isLoading && !this.teklifEnd) {
      this.form.get('page').setValue(Number(this.form.get('page').value) + 1);
      this.getTeklifList();
    }
  }

  getTeklifList() {
    this.isLoading = true;
    const formValue = removeAllNull(this.form.value);
    if (this.teklif_veren_id) {
      // Böyle olmaması gerekirdi ama bi zamanlama hatası olduğu için bunu yapıyorum.
      formValue.count = 500;
      formValue.page = 0;
      formValue.teklif_veren_id = this.teklif_veren_id;
    }

    this.teklifService.getNewTeklifList(formValue).subscribe((resp) => {
      resp.forEach(elm => {
        elm = this.clearDate(elm);
        this.teklifler = addOrUpdate(this.teklifler, elm);
      });
      this.isLoading = false;
    }, (err) => {
      this.isLoading = false;
      this.teklifEnd = true;
    });
  }

  clearDate(elm) {
    elm['created_time'] = convertDateWithTZ(elm, 'created_time');
    elm.firmalar.forEach(element => {
      element['date'] = convertDateWithTZ(element, 'date');
    });
    return elm;
  }
  formatingTeklifs(data: any[]) {
    data.forEach((el) => {
      const uruns: any[] = [];
      const firmas: any[] = [];
      if (el.teklif_group) {
        el.teklif_group.forEach((elUrun) => {
          uruns.push({
            urun_id: elUrun.id,
            urun: elUrun.title,
            birim: elUrun.is_product ? elUrun.product_id.unit : elUrun.unit,
            miktar: elUrun.teklif_amount,
            teklif: elUrun.unit_price,
          });
        });
      }

      data.forEach((elFirma) => {
        const isFirma: Boolean = elFirma.teklif_group.some((s) =>
          el.teklif_group.some((se) => se.title === s.title),
        );
        if (isFirma)
          firmas.push({
            firma_adi: elFirma.teklif_veren_name,
            teklifler: uruns,
          });
      });
      const isAdded: Boolean = this.rows2.some((s) => s.title === el.title);
      if (!isAdded)
        this.rows2 = [
          ...this.rows2,
          ...[
            {
              created_by_name: el.created_by_name,
              title: el.title,
              santiye_title: el.santiye_title,
              en_dusuk: '50₺',
              urunler: uruns,
              firma: firmas,
            },
          ],
        ];
    });
  }


  teklifGrubuSil(item) {
    const swalHtml = `${item.title} Başlıklı teklif grubunu silmek istediğinizden <u>Emin Misiniz?</u> <br/> <b>Bu İşlem Geri Alınamaz !</b>`;
    Swal.fire(responseKodlari.shureToDelete as SweetAlertOptions).then(({ value }) => {
      if (value) {
        this.teklifService.deleteTeklifGroup(item.id).subscribe(val => {
          this.getFilteredData();
        });
      }
    });
  }

  teklifGrubunuArsivle(item: any, status: boolean) {
    Swal.fire(
      {
        ...responseKodlari.shureToPasif,
        ...{
          html: `${item.title} Başlıklı teklif grubunu ${status ? 'arşivden çıkartmak' : 'arşivlemek'} istediğinizden  </br> <b>Emin Misiniz?</b>`,
          confirmButtonText: status ? 'Arşivden Çıkart' : 'Arşivle',
        },
      } as SweetAlertOptions,
    ).then(({ value }) => {
      if (value) {
        this.teklifService.updateGroup(item.id, { is_active: status }).subscribe(val => {
          this.getFilteredData();
        });
      }
    });

  }
  Euro = 0;
  Dolar = 0;
  TurkLiras = 0;
  totalPrices = {};
  refreshTotalPrice() {
    this.TurkLiras = this.Dolar = this.Euro = 0;
    for (let index = 0; index < this.teklif_groupFormArray.length; index++) {
      this.TurkLiras += this.totalPrices[index].TL;
      this.Dolar += this.totalPrices[index].USD;
      this.Euro += this.totalPrices[index].EURO;
    }
    this.form.get('trytoplam').setValue(this.TurkLiras);
    this.form.get('eurtoplam').setValue(this.Euro);
    this.form.get('dlrtoplam').setValue(this.Dolar);
  }

  addNewTeklif(teklif?) {

    this.router.navigate(['pages/teklifler/genel-teklif-ekle'], {
      state: { data: teklif },
    });


  }


  isEditing: boolean = false;
  teklifiGuncelle(teklif, item) {
    item['title'] = teklif?.title;
    item['id'] = teklif?.id;
    item['talep_id'] = teklif?.talep_id;


    this.router.navigate(['pages/teklifler/genel-teklif-ekle'], {
      state: { data: item },
    });

  }

  firmaIndex: number;


  teklifiSil(teklif_id, group_id) {
    const teklif = {
      teklif_veren_id: teklif_id,
      group_id: group_id,
    };
    Swal.fire(responseKodlari.shureToDelete as SweetAlertOptions).then(
      ({ value }) => {
        if (value) {
          this.teklifService.deleteTeklifItem(teklif).subscribe(resp => {
            this.getFilteredData();
          });
        }
      },
    );
  }





  historyList = [];
  isHistoryLoading: boolean = false;
  getHistory(itemId) {
    this.historyList = [];
    this.isHistoryLoading = true;
    setTimeout(() => {
      this.teklifService.getItemHistory(itemId).subscribe(resp => {
        this.historyList = resp;
        this.isHistoryLoading = false;
      }, (err) => {
        this.isHistoryLoading = false;
      });
    }, 150);

  }

  findFormData(firma, item) {

    if (item.title) {
      return firma.teklifler.find(x => x.title === item.title);
    } else if (item.product_id) {
      return firma.teklifler.find(x => x.product_id === item.product_id);
    } else {
      return null;
    }
  }

  onaylaTeklifItem(teklif) {

    this.teklifService.onaylaTeklifItem(teklif.teklifler).subscribe(resp => {
      // tslint:disable-next-line: max-line-length
      Swal.fire({ ...responseKodlari.teklifOnayladi, text: `${teklif.name} isimli firmanın teklifini onayladınız.` } as SweetAlertOptions);
      this.getFilteredData();
    });
  }



  toplamTutarHesapla(firma) {
    const TL: number[] = [];
    const USD: number[] = [];
    const EUR: number[] = [];
    firma.teklifler.forEach((el) => {
      if (el.amount_currency === 1) {
        TL.push(Number(el.unit_price) * Number(el.teklif_amount));
      } else if (el.amount_currency === 2) {
        USD.push(Number(el.unit_price) * Number(el.teklif_amount));
      } else if (el.amount_currency === 3) {
        EUR.push(Number(el.unit_price) * Number(el.teklif_amount));
      } else {
        TL.push(Number(el.unit_price) * Number(el.teklif_amount));
      }
    });
    // TL dizisinin icini toplar.
    // tslint:disable-next-line: max-line-length
    return { TL: TL.reduce((toplam, f) => toplam + f, 0), USD: USD.reduce((toplam, f) => toplam + f, 0), EUR: EUR.reduce((toplam, f) => toplam + f, 0) };
  }

  exportToExcel(row) {
    console.log('Export To Excel ', row);
    const workBook = new Workbook();

    const font = {
      name: 'Arial Black',
      color: { argb: 'ff704300' },
      family: 2,
      size: 9,
      bold: true,
    };


    const tumUrunlerSheet = workBook.addWorksheet('Gelen Teklifler');
    const tumBaslikRow = tumUrunlerSheet.getRow(1);
    tumBaslikRow.height = 20;




    /*Firma Başlık Alanı*/
    const teklifAlanFirma = tumUrunlerSheet.getRow(1);
    const teklifFirmaCell = teklifAlanFirma.getCell('A');
    teklifFirmaCell.value = this.orgInfo.name;
    teklifFirmaCell.font = { size: 15 };

    teklifFirmaCell.alignment = { horizontal: 'center', vertical: 'middle' };
    teklifFirmaCell.style.border = bordered;
    /*Firma Başlık Alanı*/

    /*Teklif Detay Bilgileri*/



    const talepGrubuRow = tumUrunlerSheet.getRow(4);
    const talepGrubuCell = talepGrubuRow.getCell('A');
    talepGrubuCell.value = 'Talep Grubu: ';
    talepGrubuCell.border = bordered;
    tumUrunlerSheet.mergeCells('A4:C4');

    const projeAdiRow = tumUrunlerSheet.getRow(5);
    const projeAdiCell = projeAdiRow.getCell('A');
    projeAdiCell.value = 'Proje Adı: ';
    projeAdiCell.border = bordered;
    tumUrunlerSheet.mergeCells('A5:C5');

    const yetkiliKisiRow = tumUrunlerSheet.getRow(6);
    const yetkiliKisiCell = yetkiliKisiRow.getCell('A');
    yetkiliKisiCell.border = bordered;
    yetkiliKisiCell.value = 'Yetkili Kişi: ';
    tumUrunlerSheet.mergeCells('A6:C6');

    const telefonRow = tumUrunlerSheet.getRow(7);
    const telefonCell = telefonRow.getCell('A');
    telefonCell.border = bordered;
    telefonCell.value = 'Telefon: ';
    tumUrunlerSheet.mergeCells('A7:C7');






    // Detay Bilgileri Value


    const talepGrupRowValue = tumUrunlerSheet.getRow(4);
    const talepGrupCellValue = talepGrupRowValue.getCell('D');
    talepGrupCellValue.border = bordered;
    talepGrupCellValue.value = row.title;
    tumUrunlerSheet.mergeCells('D4:F4');




    const projeAdiRowValue = tumUrunlerSheet.getRow(5);
    const projeAdiCellValue = projeAdiRowValue.getCell('D');
    projeAdiCellValue.border = bordered;
    projeAdiCellValue.value = row.santiye_title;
    tumUrunlerSheet.mergeCells('D5:F5');

    const yetkiliKisiRowValue = tumUrunlerSheet.getRow(6);
    const yetkiliKisiCellValue = yetkiliKisiRowValue.getCell('D');
    yetkiliKisiCellValue.value = this.user.name;
    yetkiliKisiCellValue.border = bordered;
    tumUrunlerSheet.mergeCells('D6:F6');

    const telefonRowValue = tumUrunlerSheet.getRow(7);
    const telefonCellValue = telefonRowValue.getCell('D');
    telefonCellValue.border = bordered;
    tumUrunlerSheet.mergeCells('D7:F7');



    /*Teklif Detay Bilgileri*/


    let verilenTeklifRowIndex = 11; // 11 satırdan başlayacak
    let verilenTeklifCell = 4; // 4 sütundan başlayacak
    let teklifMiktar;
    let teklifBirim;
    let teklifBirimFiyat;
    let teklifTutar;
    let verilenTeklifRow = tumUrunlerSheet.getRow(verilenTeklifRowIndex);
    /*Teklif Detay Bilgileri*/

    const baslikRowIndex = 9; // 9 satıra yerleşeceğiz
    const baslikRow = tumUrunlerSheet.getRow(baslikRowIndex);
    baslikRow.height = 20;
    let baslikCellIndex = 4; // Her başlık için 4 sütun eklenecek.

    const teklifDetayRowIndex = 10;
    const teklifRow = tumUrunlerSheet.getRow(teklifDetayRowIndex);
    let teklifCellIndex = 4; // 4 Sütundan başlayacak

    let urunRowCount = 0; // Ürün Counta bakıyorum ki rowlar bozuk çalışmasın ürünlere for loopa sokacam

    row.firmalar.forEach(firma => {

      let alpStart = alphabeticIndex(baslikCellIndex); // D harfini dönecek
      alpStart = alpStart.toUpperCase();
      let alpEnd = alphabeticIndex(baslikCellIndex + 3); // G Harfini Dönecek
      alpEnd = alpEnd.toUpperCase();
      this.sonHarf = alpEnd;

      alpStart = `${alpStart}${baslikRowIndex}`;
      alpEnd = `${alpEnd}${baslikRowIndex}`;

      tumUrunlerSheet.mergeCells(alpStart, alpEnd);
      const baslikCell = baslikRow.getCell(baslikCellIndex);
      const teklifDate = firma.date;
      baslikCell.value = firma.name + ' (' + teklifDate.toLocaleDateString('tr-TR') + ')';
      baslikCell.font = font;
      baslikCell.alignment = { horizontal: 'center', vertical: 'middle' };
      baslikCell.border = bordered;

      const miktarCell = teklifRow.getCell(teklifCellIndex);
      const birimCell = teklifRow.getCell(teklifCellIndex + 1);
      const birimFiyatCell = teklifRow.getCell(teklifCellIndex + 2);
      const tutarCell = teklifRow.getCell(teklifCellIndex + 3);
      miktarCell.border =

        birimCell.border = bordered;

      birimFiyatCell.border = bordered;



      tutarCell.border = bordered;



      miktarCell.value = 'Miktar';
      birimCell.value = 'Birim';
      birimFiyatCell.value = 'Birim Fiyat';
      tutarCell.value = 'Tutar';

      urunRowCount = firma?.teklifler?.length > urunRowCount ? firma?.teklifler?.length : urunRowCount;// En büyük row sayısını buluyorum ona göre boş colonlar oluşturacağım
      for (let i = 0; i < urunRowCount; i++) {
        const element = firma.teklifler[i];
        console.log('elementasdasd A', element, firma.teklifler[i], i);
        verilenTeklifRow = tumUrunlerSheet.getRow(verilenTeklifRowIndex);
        if (element) {
          teklifMiktar = verilenTeklifRow.getCell(verilenTeklifCell);
          teklifBirim = verilenTeklifRow.getCell(verilenTeklifCell + 1);
          teklifBirimFiyat = verilenTeklifRow.getCell(verilenTeklifCell + 2);
          teklifTutar = verilenTeklifRow.getCell(verilenTeklifCell + 3);

          teklifMiktar.value = element.teklif_amount;
          teklifBirim.value = getBirim(element.unit);

          teklifBirimFiyat.value = element.unit_price + getMoneyCurrency(element.amount_currency);
          teklifTutar.value = element.unit_price * element.teklif_amount;
          teklifTutar.value = teklifTutar.value + getMoneyCurrency(element.amount_currency);

          teklifMiktar.border = bordered;
          teklifMiktar.alignment = { horizontal: 'right', vertical: 'middle' };


          teklifBirim.border = bordered;
          teklifBirim.width = 200;
          teklifBirim.alignment = { horizontal: 'right', vertical: 'middle' };


          teklifBirimFiyat.border = bordered;
          teklifBirimFiyat.alignment = { horizontal: 'right', vertical: 'middle' };


          teklifTutar.border = bordered;
          teklifTutar.alignment = { horizontal: 'right', vertical: 'middle' };
        } else {

          let tklfStart = alphabeticIndex(verilenTeklifCell); // D harfini dönecek
          tklfStart = tklfStart.toUpperCase();
          let tklfEnd = alphabeticIndex(verilenTeklifCell + 3); // G Harfini Dönecek
          tklfEnd = tklfEnd.toUpperCase();
          console.log('Merge edilecek alanlar', `${tklfStart}${verilenTeklifRowIndex}`, `${tklfEnd}${verilenTeklifRowIndex}`);
          tklfStart = `${tklfStart}${verilenTeklifRowIndex}`;
          tklfEnd = `${tklfEnd}${verilenTeklifRowIndex}`;

          tumUrunlerSheet.mergeCells(tklfStart, tklfEnd);
          const bosBorder = verilenTeklifRow.getCell(verilenTeklifCell);
          bosBorder.border = bordered;

        }
        verilenTeklifRowIndex++;

      }


      // firma.teklifler.forEach(element => {
      //   verilenTeklifRow = tumUrunlerSheet.getRow(verilenTeklifRowIndex);
      //   teklifMiktar = verilenTeklifRow.getCell(verilenTeklifCell);
      //   teklifBirim = verilenTeklifRow.getCell(verilenTeklifCell + 1);
      //   teklifBirimFiyat = verilenTeklifRow.getCell(verilenTeklifCell + 2);
      //   teklifTutar = verilenTeklifRow.getCell(verilenTeklifCell + 3);

      //   teklifMiktar.value = element.teklif_amount;
      //   teklifBirim.value = getBirim(element.unit);
      //   teklifBirimFiyat.value = element.unit_price + getMoneyCurrency(element.amount_currency);
      //   teklifTutar.value = element.unit_price * element.teklif_amount;
      //   teklifTutar.value = teklifTutar.value + getMoneyCurrency(element.amount_currency);

      //   teklifMiktar.border = bordered;
      //   teklifMiktar.alignment = { horizontal: 'right', vertical: 'middle' };


      //   teklifBirim.border = bordered;
      //   teklifBirim.width = 200;
      //   teklifBirim.alignment = { horizontal: 'right', vertical: 'middle' };


      //   teklifBirimFiyat.border = bordered;
      //   teklifBirimFiyat.alignment = { horizontal: 'right', vertical: 'middle' };


      //   teklifTutar.border = bordered;
      //   teklifTutar.alignment = { horizontal: 'right', vertical: 'middle' };


      //   verilenTeklifRowIndex++;
      // });


      baslikCellIndex += 4; // Bir sonraki Firma için 4 Sütun sola git.
      teklifCellIndex += 4;
      verilenTeklifCell += 4;
      verilenTeklifRowIndex = 11;
      // console.log('alpEnd: ' + 'A1:' + this.sonHarf + '2');
    });





    tumUrunlerSheet.mergeCells('A1:' + this.sonHarf + '2');

    /*Tarih*/
    let alpStartTarih = alphabeticIndex(baslikCellIndex - 1);
    alpStartTarih = alpStartTarih.toUpperCase();
    let alpEndTarih = alphabeticIndex(baslikCellIndex - 1);
    alpEndTarih = alpEndTarih.toUpperCase();
    tumUrunlerSheet.mergeCells(alpStartTarih + '4:' + alpEndTarih + '7');

    const tarihGosterRow = tumUrunlerSheet.getRow(4);
    const tarihCell = tarihGosterRow.getCell(alpStartTarih);
    const tarihColum = tumUrunlerSheet.getColumn(alpStartTarih);
    tarihColum.width = 15;
    tarihCell.alignment = { horizontal: 'center', vertical: 'middle' };
    tarihCell.font = font;
    const bugun = new Date();
    tarihCell.value = bugun;
    tarihCell.border = bordered;



    /*Tarih*/


    let urunRowIndex = 11;

    const urunTitle = tumUrunlerSheet.getCell('A9');
    urunTitle.value = 'Ürün';
    urunTitle.border = bordered;
    tumUrunlerSheet.mergeCells(`A9`, `C10`);
    urunTitle.font = font;
    urunTitle.alignment = { horizontal: 'center', vertical: 'middle' };

    row.urunler.forEach((elm) => {
      const urunCell = tumUrunlerSheet.getCell(`A${urunRowIndex}`);
      tumUrunlerSheet.mergeCells(`A${urunRowIndex}`, `C${urunRowIndex}`);
      urunCell.value = elm.title;
      urunCell.border = bordered;
      urunRowIndex++;
    });


    let altBilgiRowIndex = urunRowIndex + 2;
    let altBilgiRow;
    let altBilgiTitle;

    const altBasliklar = ['Ödeme: ', 'Teklif Geçerlilik Süresi:  ', 'Teslim Tarihi: ', 'Teslim Yeri: '];
    altBasliklar.forEach(element => {
      altBilgiRow = tumUrunlerSheet.getRow(altBilgiRowIndex);
      altBilgiTitle = altBilgiRow.getCell('A');

      altBilgiTitle.value = element;
      altBilgiTitle.border = bordered;
      altBilgiRow.height = 30;
      tumUrunlerSheet.mergeCells(`A` + altBilgiRowIndex + `:C` + altBilgiRowIndex);
      altBilgiTitle.alignment = { horizontal: 'right', vertical: 'middle' };
      altBilgiRowIndex++;
    });


    const kdvBilgisiRowIndex = urunRowIndex + 2;
    const kdvBilgisiRow = tumUrunlerSheet.getRow(kdvBilgisiRowIndex);
    let kdvBilgisiTitle;
    let kdvBaslikCellIndex = 4;
    let alpStartKdv;
    let alpEndtKdv;
    for (let index = 0; index < row.firmalar.length; index++) {
      alpStartKdv = alphabeticIndex(kdvBaslikCellIndex); // D harfini dönecek
      alpStartKdv = alpStartKdv.toUpperCase();
      alpEndtKdv = alphabeticIndex(kdvBaslikCellIndex + 3); // G Harfini Dönecek
      alpEndtKdv = alpEndtKdv.toUpperCase();
      kdvBilgisiTitle = kdvBilgisiRow.getCell(kdvBaslikCellIndex);
      kdvBilgisiTitle.value = 'KDV Dahildir / Değildir';
      kdvBilgisiTitle.border = bordered;
      kdvBilgisiTitle.alignment = { horizontal: 'center', vertical: 'top' };

      alpStartKdv = `${alpStartKdv}${kdvBilgisiRowIndex}`;
      alpEndtKdv = `${alpEndtKdv}${kdvBilgisiRowIndex + 3}`;
      tumUrunlerSheet.mergeCells(alpStartKdv, alpEndtKdv);
      kdvBaslikCellIndex += 4;
    }


    workBook.xlsx
      .writeBuffer()
      .then((exp) => {
        const dosyaAdi = 'ExcelÇıktı';
        const blob = new Blob([exp], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const filename = dosyaAdi + '.xlsx';
        fs.saveAs(blob, filename);
        Swal.fire('Dosya İndirme İşlemi Tamamlandı!', '', 'success');
      })
      .catch((err) => console.log('BAŞARISIZ', err));
  }
  /** kendisinie verilen firmanın tüm tekliflerininn
 *  toplam tutarını hesaplar.
 */


  teklifDownload(id) {
    this.dosyaSpinner = true;
    if (id) {
      this.teklifService.getTeklifFile(id).subscribe(data => {

        downLoadFile(data.teklif_file, data.title);
        this.dosyaSpinner = false;
      }, (err) => {
        this.dosyaSpinner = false;
      });
    }
  }

  goBack(){
    this.location.back();

  }
}

