import { createAction, props } from '@ngrx/store';
import { AkisItem } from '../../../../models/akis.model';

export const loadMuhasebeAkis = createAction('[MuhasebeAkis] Load Muhasebe Akis', props<{ page?: number }>());
// tslint:disable-next-line: max-line-length
export const loadMuhasebeAkisSuccess = createAction('[MuhasebeAkis] Load Muhasebe Akis Success', props<{ akisItem: AkisItem[], isEnd?: boolean }>());
export const loadMuhasebeAkisFail = createAction('[MuhasebeAkis] Muhasebe Load Akis Fail', props<String>());
// tslint:disable-next-line: max-line-length
export const addMuhasebeAkisItem = createAction('[MuhasebeAkis] Add Muhasebe Akis Item', props<{ akisItem: AkisItem }>());

export const setConnected = createAction('[MuhasebeAkis] SetConnected', props<{ connection: boolean }>());
