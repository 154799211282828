import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../../pages/constants';
import { findExtantion, removeAllNull } from '../../../../pages/global-functions';
import { ApasFileService } from '../../../../services/apasfile.service';

@Component({
  selector: 'ngx-apas-files-upload',
  templateUrl: './apas-files-upload.component.html',
  styleUrls: ['./apas-files-upload.component.scss'],
  providers: [ApasFileService],
})
export class ApasFilesUploadComponent implements OnInit {
  form: FormGroup;
  file_key: string = 'file';
  instance_key: string;
  instance_id;
  url: string;
  row;
  fileName = '';
  acceptFileFormats: string[] = [
    'doc',
    'docx',
    'xls',
    'xlsx',
    'pdf',
    'jpg',
    'jpeg',
    'png',
    'dwg',
    'dxf',
    'dwf',
    'txt',
  ];
  currentFile: File;
  isLoading: boolean = false;

  percentDone: number = 100;

  isSubmitting: boolean = false;
  constructor(public fb: FormBuilder,
    private fileService: ApasFileService,
    private ref: NbDialogRef<ApasFilesUploadComponent>) { }

  ngOnInit(): void {
    this.initForm();
    try {
      this.form.get('instance_id').setValue(this.instance_id);
    } catch (error) {
      
    }

    if (this.row) {
      this.form.patchValue(this.row);

    }
  }

  initForm() {
    this.form = this.fb.group({
      instance_id: [null],
      title: [],
      note: [],
      file: [null, Validators.required],
    });
  }



  fileReset() {
    this.form.get('file').reset();
    this.form.get('title').reset;
    this.fileName = '';
  }
  /**
   * Resim ekle butonu için gerekli fonksiyon.
   * @param fileInput #fileInput type="file" accept="image/png, image/jpeg" olarak tanımlanmış buton.
   */
  openFile(fileInput) {
    fileInput.value = '';
    fileInput.click();
  }
  /**
   * Dosya(lar) seçildikden sonra çalışan fonksiyon.
   * @param event Seçilen dosya(lar)ın listesinin bulunduğu event openFile dialoğun geri dönüşüdür.
   */
  openFileDialog(event) {
    // yeni bir dosya seçildiği icin eski dosyanın adını siler.
    // this.clearSelection();
    this.isLoading = true;

    // tek bir dosya alıcağım icin ilk dosyayı seçiyorum.
    const tmpFile: File = (event.target as HTMLInputElement).files[0];

    const fileName = tmpFile.name.split(new RegExp('.[a-z]*$', 'gmi'))[0];
    // Seçilen elemanon dosya uzantısının doğru olup olmadığına bakılır.
    let isFileCurrect;
    const format = findExtantion(tmpFile.type);
    isFileCurrect = this.acceptFileFormats.find(x => x === format);
    // eğer uyumsuz herhangi bir dosya var ise onu kullanıcıya uyarı olarak gösterir.
    if (!isFileCurrect) {
      this.isLoading = false;

      Swal.fire({
        icon: 'error',
        title: 'Lütfen sadece uyumlu bir dosya seçiniz.',
        html:
          '<div style="color: #f27474;text-align: left !important;">' +
          fileName +
          '</div>',
        showConfirmButton: true,
      });
      return;
    }
    this.currentFile = tmpFile;
    const reader = new FileReader();
    reader.onload = () => {
      this.isLoading = false;
      const fileBase64 = reader.result as string;
      this.form.get('file').setValue(fileBase64);
    };
    reader.readAsDataURL(tmpFile);
    if (!this.form.get('title').value) {
      this.form.get('title').setValue(fileName);
    }
    this.fileName = tmpFile.name;
  }

  uploadFile() {
    const formData = this.valueToForm();
    formData[this.file_key] = formData.file;
    if (!this.instance_key) {
      this.instance_key = `${this.url}_id`;
    }
    formData[this.instance_key] = formData.instance_id;
    if (this.form.valid) {
      if (!this.row) {
        this.fileService.uploadFile(this.url, formData).subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                this.percentDone = 0;
                this.isSubmitting = true;
                break;
              case HttpEventType.UploadProgress:
                this.percentDone = Math.round((100 * event.loaded) / event.total);
                break;
              case HttpEventType.Response:
                this.isSubmitting = false;
                this.succesUploadFile();

                Swal.fire({
                  ...responseKodlari.successProgress,
                  timer: 3000,
                  showConfirmButton: true,
                } as SweetAlertOptions).then(() => {
                  this.close(true);
                });

                break;
            }
          },
          (err: HttpErrorResponse) => {
            this.isSubmitting = false;
            Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
          },
        );
      } else {
        this.fileService.updateFile(this.row.identifier, this.url, formData).subscribe(
          (event: HttpEvent<any>) => {
            switch (event.type) {
              case HttpEventType.Sent:
                this.percentDone = 0;
                this.isSubmitting = true;
                break;
              case HttpEventType.UploadProgress:
                this.percentDone = Math.round((100 * event.loaded) / event.total);
                break;
              case HttpEventType.Response:
                this.isSubmitting = false;
                this.succesUploadFile();

                Swal.fire({
                  ...responseKodlari.successProgress,
                  timer: 3000,
                  showConfirmButton: true,
                } as SweetAlertOptions).then(() => {
                  this.close(true);
                });

                break;
            }
          },
          (err: HttpErrorResponse) => {
            this.isSubmitting = false;
            Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
          },
        );
      }

    } else {

      Swal.fire(responseKodlari.form.unValidForm as SweetAlertOptions);
    }

    // setTimeout(() => this.succesUploadFile(), 100);
  }
  valueToForm(): any {
    const data = removeAllNull(this.form.value);
    return data;
  }
  succesUploadFile() {
    this.close(true);
  }
  close(success?) {
    this.ref.close(success);
  }



}
