import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import OrgInfoModel from '../models/orgInfo.model';
import { BaseModel } from '../models/base.model';
import { PersonelGrup } from '../models/personel_grup.model';
import { Store } from '@ngrx/store';
import { removeAllNull } from '../pages/global-functions';
import { getOrgInfo } from '../store/organization/organization.connector';
import { environment } from '../../environments/environment';


@Injectable({ providedIn: 'root' })
export class OrganizasyonService {
  private readonly API_URL = `${environment.backendurl}api/organization/`;
  private readonly AP2URL = `${environment.backendurl}api/gorev/`;
  private readonly APIURL3 = `${environment.backendurl}api/taseron/`;
  private readonly APIURL4 = `${environment.backendurl}api/isguvenligi/`;



  constructor(
    private httpClient: HttpClient,
    private store: Store<{}>,
  ) {
  }

  
  getKurmJson(): Observable<any[]> {
    // const url = 'https://www.apasplus.com/assets/kurum.json';
    // return this.httpClient.get<any[]>(url);
    const kurumlar = require('../../assets/resources/kurum.json');

    return of(kurumlar);
  }

  getOrganizasyonlar(): Observable<OrgInfoModel[]> {

    return this.httpClient.get<OrgInfoModel[]>(this.API_URL);
  }
  getOrganizasyonGroups(): Observable<PersonelGrup[]> {
    const url: string = `${this.API_URL}personelgrup/list/`;
    return this.httpClient.get<PersonelGrup[]>(url);
  }


  updateOrganization(data): Observable<OrgInfoModel> {
    return this.httpClient.patch<OrgInfoModel>(this.API_URL + 'update/', data);

  }

  getCurrentOrganizationInformation(): Observable<OrgInfoModel> {
    return this.httpClient.get<OrgInfoModel>(this.API_URL + 'current/');
  }

  addOrganizasyonWithUser(registerItem: any) {
    return this.httpClient.post(this.API_URL + 'addwithuser/', registerItem);

  }

  addOrganizationAndRegisterUser(registerItem: any) {
    return this.httpClient.post(this.API_URL + 'addwithuser/', registerItem);
  }

  searchOrganization(search: string, is_related: boolean): Observable<BaseModel[]> {
    const data = { 'search': search, is_related: is_related };
    
    return this.httpClient.post<BaseModel[]>(this.API_URL + 'search/', data);
  }

  
  searchOrganizationUsers(search: number) {
    return this.httpClient.post(this.API_URL + 'personel/', { 'search': search });
  }

  getMyOrganizationsPersonels(_filter?: string): Observable<any[]> {
    const url = `${this.API_URL}mypersonel/` + (_filter ? `${_filter}/` : '');
    
    return this.httpClient.get<any[]>(url);
  }
  getOrganizationsPersonels(orgApasId: number): Observable<any[]> {
    const url: string = this.API_URL + 'organizationuser/list/' + orgApasId + '/';

    return this.httpClient.get<any[]>(url);
  }

  getOrganizationPersonelsWithId(orgId: number) {
    const url = `${this.API_URL}orguser/list/${orgId}/`;
    return this.httpClient.get<any[]>(url);
  }
  /**
   * Organizasyon ait personelleri listeler.
   * @param data serachable data {search:'kelime'}
   * @param orgApasId personellerini almak istediğimiz organizasyonun id'si
   * @returns {Observable<any[]>]}
   */
  getOtherOrganizationsPersonels(orgApasId: number, search?: any): Observable<any[]> {
    const url: string = this.API_URL + 'otherpersonel/' + orgApasId + '/';
    return this.httpClient.post<any[]>(url, { 'search': search });
  }

  getOtherOrganizationsPersonelsV2(data): Observable<any[]> {
    const url: string = this.API_URL + 'v2/otherpersonel/';
    return this.httpClient.post<any[]>(url, data);
  }

  orgUpdate(data: any): Observable<OrgInfoModel> {
    return this.httpClient.patch<OrgInfoModel>(this.API_URL, data);
  }

  addPersonel(data: OrgInfoModel) {
    return this.httpClient.post(this.API_URL + 'mypersonel/', data);
  }
  /**
   * Personelin dogrulama ilemini gerçekliştir.
   * @param id taseronun id'si
   * @param data activation codu içeren data.
   * { activation_code:kod}
   */
  smsActivation(id, data) {
    const url = this.APIURL3 + 'personel/' + id + '/';
    return this.httpClient.post(url, data);
  }
  /**
 * Organizasyon dogrulama ilemini gerçekliştir.
 * @param aps_id taseronun aps_id'si
 * @param data activation codu içeren data.
 * { activation_code:kod}
 */
  smsActivationOrganization(data) {
    const url = this.API_URL + `mypersonel/`;
    return this.httpClient.post(url, data);
  }

  add_yetkili_user(apas_id): Observable<any> {
    return this.httpClient.post(this.API_URL + 'adduser/', apas_id);
  }

  remove_yetkili_user(apas_id) {
    // tslint:disable-next-line: max-line-length
    return this.httpClient.post(this.API_URL + 'removeuser/', { apas_id: apas_id });
  }

  reactivate_personel(apas_id): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.httpClient.post(this.API_URL + 'reactivate/', { 'apas_id': apas_id });
  }

  deactivate_personel(apas_id): Observable<any> {
    const url = this.API_URL + `deactivate/${apas_id}/`;
    return this.httpClient.get(url);
  }
  get_personel_gruplari(apas_id): Observable<any[]> {
    const url = this.API_URL + 'personelgruplari/' + apas_id + '/';
    
    return this.httpClient.get<any[]>(url);
  }
  postPersonelGrup(data: PersonelGrup): Observable<PersonelGrup> {
    const url = this.API_URL + 'personelgrup/add/';
    return this.httpClient.post<PersonelGrup>(url, data);
  }
  /**
   * Personel guruplarını günecellmeye yarar
   * @param data patch lenecek gurubun verisi
   * @param id patchlenecek gurubun id'si
   */
  patchPersonelGrup(data: PersonelGrup, id: number): Observable<PersonelGrup> {
    const url = `${this.API_URL}personelgrup/update/${id}/`;
    return this.httpClient.patch<PersonelGrup>(url, data);
  }

  /**
   * Personel guruplarını silmeye yarar
   * @param id Silmek istediğiniz gurubun id'si
   */
  deletePersonelGrup(id: number): Observable<PersonelGrup> {
    const url = `${this.API_URL}personelgrup/delete/${id}/`;
    return this.httpClient.delete<PersonelGrup>(url);
  }
  get_personel_remaining() {
    return this.httpClient.get(this.API_URL + 'adduser/kullanim_hakki/');
  }

  getPersonelFiles(): Observable<any[]> {
    const url = `${this.APIURL4}sozlesme/list/`;
    return this.httpClient.get<any[]>(url);
  }
  /**
   * Id'si bilinen imza dosyansını getirir.
   * @param id dosyanın id'si
   */
  getPersonelFileDetail(id: number): Observable<any> {
    const url = `${this.APIURL4}sozlesme/tek/${id}/`;

    // return this.httpClient.get<any>(url, this.httpOptions);
    const req = new HttpRequest('GET', url, { reportProgress: true });
    return this.httpClient.request(req);

  }
  getUyarilar() {
    const url: string = this.API_URL + 'uyarilar/';

    return this.httpClient.get<any[]>(url);
  }

  registerKurum(data: any) {
    const url: string = `${this.API_URL}kurumkayit/`;

    return this.httpClient.post<any>(url, data);
  }

  updatePersonelInformation(data: any, apasId) {
    const url = `${this.API_URL}updatepersonel/${apasId}/`;
    return this.httpClient.patch<any>(url, data);
  }

  killthefirm() {
    const url: string = `${this.API_URL}kill/`;

    return this.httpClient.delete<any>(url);
  }

  personelList(filter): Observable<any> {
    const url: string = `${this.API_URL}newpersonellist/`;
    return this.httpClient.post<any>(url, filter);
  }

  searchPerson(data): Observable<any> {
    const url: string = `${this.API_URL}searchperson/`;
    return this.httpClient.post<any>(url, data);
  }

  newPersonelAdd(data): Observable<any> {
    const url: string = `${this.API_URL}newpersoneladd/`;
    return this.httpClient.post<any>(url, data);
  }

  getPersonelDetail(apas_id): Observable<any> {
    const url: string = `${this.API_URL}personel/detay/${apas_id}/`;
    return this.httpClient.get<any>(url);
  }


  relatedCompanies(data): Observable<any> {
    data = removeAllNull(data);
    const url: string = `${this.API_URL}related/`;
    return this.httpClient.post<any>(url, data);
  }

  getRelatedCompany(instanceId) {
    const url: string = `${this.API_URL}related/${instanceId}/`;
    return this.httpClient.get(url);
  }


  invitePersonels(data) {
    const url: string = `${this.API_URL}personel/invite/`;
    return this.httpClient.post(url, data);
  }


  getPersonelProfile(instanceId) {
    const url: string = `${this.API_URL}personelprofile/${instanceId}/`;
    return this.httpClient.get(url);
  }

  addLeaveDays(data) {
    const url: string = `${this.API_URL}addleavedays/`;
    return this.httpClient.post(url, data);
  }

  updateYearLeaveDays(instanceId, data) {
    const url: string = `${this.API_URL}updateyearleavedays/${instanceId}/`;
    return this.httpClient.patch(url, data);
  }

  updateLeaveDays(instanceId, data) {
    const url: string = `${this.API_URL}updateleavedays/${instanceId}/`;
    return this.httpClient.patch(url, data);
  }

  deleteLeaveDays(instanceId) {
    const url: string = `${this.API_URL}deleteleavedays/${instanceId}/`;
    return this.httpClient.delete(url);
  }


  /** Personel Dosyaları */
  getPersonelFileList(data) {
    const url: string = `${this.API_URL}personelfilelist/`;
    return this.httpClient.post(url, data);
  }

  addPersonelFile(data) {
    const url: string = `${this.API_URL}addpersonelfile/`;
    return this.httpClient.post(url, data);
  }

  updatePersonelFile(instanceId, data) {
    const url: string = `${this.API_URL}updatepersonelfile/${instanceId}/`;
    return this.httpClient.patch(url, data);
  }


  deletePersonelFile(instanceId) {
    const url: string = `${this.API_URL}deletepersonelfile/${instanceId}/`;
    return this.httpClient.delete(url);
  }

  addWorkArea(data) {
    const url: string = `${this.API_URL}addworkarea/`;
    return this.httpClient.post(url, data);
  }

  updateWorkArea(instanceId, data) {
    const url: string = `${this.API_URL}updateworkarea/${instanceId}/`;
    return this.httpClient.patch(url, data);

  }

  deleteWorkArea(instanceId) {
    const url: string = `${this.API_URL}deleteworkarea/${instanceId}/`;
    return this.httpClient.delete(url);
  }



  /**
   * Apas dışı firma ekler.
   * @param data gerekli data
   * @param addType apas dışı firmanın tipini belirtir.
   */
  apasDisi(data) {
    const url = `${this.API_URL}addnew/`;
    return this.httpClient.post(url, data);
  }

  updateRelatedOrganization(instanceId, data) {
    const url = `${this.API_URL}updatenew/${instanceId}/`;
    return this.httpClient.patch(url, data);
  }

  addTaseronPersonel(_tpersonel: any, _taseronID: number) {
    return this.httpClient.post(this.API_URL + 'personel/' + _taseronID + '/', _tpersonel);
  }

  public org$ = this.store.select(getOrgInfo);

}

