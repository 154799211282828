<nb-card style="width: 550px;" class="modalType animated fadeInRight">
  <nb-card-header>
    <b>{{takvimTarih * 1000 | date: tarih_format}}</b>
    <button nbButton (click)="closed()" size="tiny" style="background: none !important;
    color: #000 !important;
    float: right;
    font-size: 18px;">X</button>
  </nb-card-header>
  <nb-card-body class="p-0">

    <nb-list *ngIf="takvimDetay.length > 0">
      <nb-list-item *ngFor="let item of takvimDetay">
        <div class="linked-div row w-100 m-2 " *ngIf="item.type != 4" (click)="detay(item)">
          <div class="col-md-1">
            <nb-icon icon="clock-outline" *ngIf="item.type === 1"></nb-icon>
            <nb-icon icon="icon-toplanti" pack="custom-icons" *ngIf="item.type === 2"></nb-icon>

          </div>
          <div class="col-md-9" [nbTooltip]="item.title">
            {{item.title | slice:0:length ? length:30}}{{item.title.length > length ? '...' : null }}
          </div>
          <div class="col-md-1">
            <button nbButton size="tiny" status="warning" *ngIf="item.type === 1">{{ 'TAKVIM.DETAY' | translate
              }}</button>
            <button nbButton size="tiny" status="info" *ngIf="item.type === 2">{{ 'TAKVIM.DETAY' | translate }}</button>
            <button nbButton size="tiny" status="success" *ngIf="item.type === 3">{{ 'TAKVIM.DETAY' | translate
              }}</button>
          </div>
        </div>
        <div class="row w-100 m-2 " *ngIf="item.type == 4">
          <div class="col-md-1">
            <nb-icon icon="layers-outline"></nb-icon>
          </div>
          <div class="col-md-11" [nbTooltip]="item.title">
            <div class="row">
              <div class="col-md-12">
                {{item.sozlesme_name ? item.sozlesme_name : ''}}
              </div>
              <div class="col-md-12">
                {{item.title | slice:0:length ? length:30}}{{item.title.length > length ? '...' : null }}
              </div>
              <div class="col-md-12 row d-flex align-items-center">
                <div class="col-md-8">
                  <div class="row" [ngStyle]="{'color': tamamlananColor}">
                    <div class="col-md-9 p-0 m-0">
                      Tamamlanma Oranı 
                    </div>
                    <div class="col-md-3  p-0 m-0">
                      : <b>{{item.completed}}</b>
                    </div>
                  </div>
                  <div class="row" [ngStyle]="{'color': ongorulenColor}">
                    <div class="col-md-9 p-0 m-0"> 
                      Öngörülen Oran
                    </div>
                    <div class="col-md-3  p-0 m-0">
                      : <b>{{item.should_completed}}</b>
                    </div>
                  </div>
                </div>
  
                <div class="col-md-4">
                  <ngx-charts-pie-chart [view]="[150, 100]" [scheme]="colorScheme" [results]="item.data" [gradient]="gradient"
                    [legend]="showLegend" [labels]="showLabels" [doughnut]="isDoughnut">
                  </ngx-charts-pie-chart> <!--  [legendPosition]="legendPosition"   -->
                  <!-- <chart type="pie" [data]="item.data" [options]="options"></chart> -->
                </div>
              </div>

            </div>
          </div>
          <!-- <div class="col-md-1">
            <button nbButton size="tiny" status="info" >{{ 'TAKVIM.DETAY' | translate }}</button>
          </div> -->
        </div>

      </nb-list-item>


    </nb-list>




    <h6 class="text-center" *ngIf="takvimDetay.length <= 0" style="line-height: 9;">
      <nb-icon icon="info-outline"></nb-icon>
      {{ 'TAKVIM.NOT' | translate }}
    </h6>
  </nb-card-body>

  <nb-card-footer class="text-center">
    <button nbButton status="primary" (click)="hatirlatmaEkle()">{{ 'TAKVIM.HATIRLAMAEKLE' | translate }}</button>
  </nb-card-footer>


</nb-card>