import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as akisActions from './akis.actions';
import { AkisItem } from '../../models/akis.model';
import { WebSocketService } from '../../services/websocket.service';


@Injectable()
export class AkisEffects {

    colorArray = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];
    randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
    selectColor() {
        const random = this.randomIntFromInterval(0, this.colorArray.length);

        return this.colorArray[random];
    }
    loaAkis$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(akisActions.loadAkis),
            mergeMap((props) => this.webSocketService.getAkis({ page: props.page }).pipe(
                map((akislar: AkisItem[]) => {
                    const isEnd: boolean = akislar.length < 10;
                    akislar.forEach(elm => {
                        elm['color'] = this.selectColor();
                    });
                    return (akisActions.loadAkisSuccess({ akisItem: akislar, isEnd: isEnd }));
                }),
                catchError(() => of(akisActions.loadAkisFail('Akış yüklenemedi.'))),
            )),
        );
    });


    constructor(
        private actions$: Actions,
        private webSocketService: WebSocketService,
    ) {

    }

}
