import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BildirimModel } from '../../../../models/bildirim.model';
import { BildirimService } from '../../../../services/bildirim.service';
import { Store, select } from '@ngrx/store';
import * as fromBildirim from '../../../../store/bildirim/bildirim.connector';
import * as bildirimActions from '../../../../store/bildirim/bildirim.actions';
import { BildirimState } from '../../../../store/bildirim/bildirim.reducer';
@Component({
  selector: 'apas-notification',
  templateUrl: './apas-notification.component.html',
  styleUrls: ['./apas-notification.component.css'],
  providers: [BildirimService],
})
export class ApasNotificationComponent implements OnInit, AfterViewInit {
  unReadedCount: number;
  tag: string = 'ApasNotificationComponent --->';


  bildirimData: BildirimModel[] = [];
  isLoading: boolean = false;
  bildirimInfo: BildirimState;
  isInit: boolean = false;
  constructor(private bildirimService: BildirimService,
    private store: Store<{}>) {

    this.store.pipe(select(fromBildirim.selectBildirimInfo)).subscribe(bildirimInfo => {
      this.bildirimInfo = bildirimInfo;
    });
    this.store.pipe(select(fromBildirim.selectBildirim),
    ).subscribe(bildirimler => {
      this.bildirimData = bildirimler;
    });
  }

  ngOnInit() {
    this.store.pipe(select(fromBildirim.selectBildirimLoaded)).subscribe(isLoaded => {
      if (!isLoaded) {
        this.store.dispatch(bildirimActions.loadBildirim({}));
      }
    });

  }


  ngAfterViewInit(): void {
    // akışı başlatır.
    // eğer daha önce akış indirilmiş ise başdan çekmez.
    if (!this.bildirimInfo.loaded && this.bildirimData.length <= 0) {
      this.store.dispatch(bildirimActions.loadBildirim({}));
    } else {
      this.isInit = true;
    }

  }

  pageSize = 20;
  /**
   * İnifinitin sonuna gelince cağrılırç
   */
  loadNext() {
    

    if (this.bildirimInfo.loading
      || this.bildirimData === undefined
      || this.bildirimInfo.end
      || (!this.bildirimInfo.loaded && !this.isInit)) { return false; }
    // gideceğimiz sayfa numarasını veririr.
    // const pageCount: number = Math.ceil(this.bildirimData.length / this.pageSize);
    const last: number = this.bildirimData[this.bildirimData.length - 1].created_time;

    this.store.dispatch(bildirimActions.loadBildirim({ page: last }));
  }

  setReaded() {
    this.bildirimData.map(element => {
      if (!element.is_readed) {
        this.bildirimService.setReaded(element.bildirim_id).subscribe(response => {
          this.store.dispatch(bildirimActions.setBildirimReaded({ id: element.bildirim_id }));
        });
      }
    });
  }



  tumunuIsaretle() {
    this.bildirimService.setAllReaded().subscribe(resp => {
      this.bildirimData.map(elm => {
        this.store.dispatch(bildirimActions.setBildirimReaded({ id: elm.bildirim_id }));
      });
    });
  }


}
