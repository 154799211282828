import { ActionReducer, combineReducers, compose, createFeatureSelector } from '@ngrx/store';
import { AlisSatisReducer, defaultAlisSatisState } from './alis-satis/alis-satis.reducer';
import { AlisSatisState } from './alis-satis/alisSatisInterfaces';
import { CariReducer, CariState, defaultCariState } from './cari/cari.reducer';
import { defaultExchangeState, ExchangeReducer, ExchangeState } from './exchanges/exchanges.reducer';
import { defaultHesapState, HesapReducer, HesapState } from './hesaplar/hesap.reducer';
import { calendarMuhasebeReducers, defaultMuhasebeCalendar, MuhasebeDefaultCalendarState } from './muhasebe-calendar/muhasebe-calendar.reducer';
import { defaultMuhasebeAkisState, MuhasebeAkisReducer, MuhasebeAkisState } from './muhasebeAkis/muhasebe-akis.reducer';


const muhasebeReducers = {
        Hesap: HesapReducer,
        Cari: CariReducer,
        Exchanges: ExchangeReducer,
        MuhasebeAkis: MuhasebeAkisReducer,
        AlisSatis: AlisSatisReducer,
        CalendarMuhasebeReducers: calendarMuhasebeReducers
};


const defaultMuhasebeStates = {
        Hesap: defaultHesapState,
        Cari: defaultCariState,
        Exchanges: defaultExchangeState,
        MuhasebeAkis: defaultMuhasebeAkisState,
        AlisSatis: defaultAlisSatisState,
        CalendarMuhasebeReducers: defaultMuhasebeCalendar

};

interface MuhasebeState {
        CalendarMuhasebeReducers: MuhasebeDefaultCalendarState;
        Hesap: HesapState;
        Cari: CariState;
        Exchanges: ExchangeState;
        MuhasebeAkis: MuhasebeAkisState;
        AlisSatis: AlisSatisState;

}

const muhasebeReducer: ActionReducer<MuhasebeState> = combineReducers(muhasebeReducers);

function MuhasebeReducer(state: any, action: any) {

        return muhasebeReducer(state, action);
}

const getMuhasebeSelector = createFeatureSelector<MuhasebeState>('Muhasebe');

export {
        getMuhasebeSelector,
        MuhasebeReducer,
        muhasebeReducer,
        defaultMuhasebeStates,
        MuhasebeState,
};
