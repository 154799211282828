import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { removeAllNull } from '../../../pages/global-functions';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { ControlValueAccessorConnector } from '../../../shared/control-value-accessor-connector';
import { PersonelEkleFirmaComponent } from '../../personel/personel-ekle-firma/personel-ekle-firma.component';

@Component({
  selector: 'personel-select',
  template: `<ng-select
      [items]="personelList$ | async"
      [loading]="isLoading"
      appendTo="body"
      [typeahead]="personelInput$"
      typeToSearchText="Arama yapmak için yazınız."
      bindLabel="name"
      [bindValue]="bindValue"
      [addTag]="false"
      [multiple]="multiple"
      [selectOnTab]="true"
      [formControl]="control"
      [placeholder]="'Personel Seç'"
      class="uzun-select"
      ngDefaultControl
      (change)="personelSelected($event)"
  >
      <ng-template style="margin:unset" ng-footer-tmp *ngIf="showAddButtons">
          <button nbButton size="small" status="primary" hero (click)="addNewPersonel()" *ngIf="showTaseronAdd"> Personel Ekle </button>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          {{item.name}} - (<b> {{ 'MUTABAKATLISTE.APASID' | translate }}</b> {{item.apas_id}})
      </ng-template>
  </ng-select>`
  ,
  styleUrls: ['./personel-select.component.scss'],
  providers: [
    OrganizasyonService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonelSelectComponent),
      multi: true,
    },
  ],
})
export class PersonelSelectComponent extends ControlValueAccessorConnector implements OnInit, OnChanges {

  @Input() formControl!: FormControl;
  @Input() formControlName: string;
  
  isLoading: boolean = false;

  // tslint:disable-next-line: max-line-length
  @Input() bindValue: string = 'id'; // true ? sadece benim şirketime ait şantiyeleri liste : ilişkili olduğum tüm şantiyeleri listele

  // tslint:disable-next-line: max-line-length
  @Input() is_active: boolean = true; // null : tüm şanyiyeleri listele - true : sadece aktif şantiyeleri listele - false : sadece pasif şantiyeleri listele

  @Input() multiple: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter();

  @Input() showAddButtons: boolean = true;
  @Input() showTaseronAdd: boolean = false;
  @Input() showTedarikciAdd: boolean = false;

  @Input() personelTypes = ['yetkili', 'aktif', 'pasif'];

  tag: string = '------ Personel Select ------------> ';
  constructor(
    private organizationService: OrganizasyonService,
    injector: Injector,
    cd: ChangeDetectorRef,
    private nbDialogService: NbDialogService,
  ) {
    super(injector);
    this.firstList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    
    this.firstList();
  }

  ngOnInit(): void {
    this.getPersonel();

  }
  personelSelected(event) {
    this.change.emit(event);
  }

  personelList$: Observable<any[]>;
  personelInput$ = new Subject<string>();
  getPersonel() {
    // 
    this.personelList$ = concat(
      of([]), // default items
      this.personelInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.isLoading = true),
        switchMap(term =>
          this.organizationService.personelList(
            removeAllNull({ search: term, filter: { is_active: true } }),
          ).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.isLoading = false),
          ),
        ),
      ),
    );
  }

  private firstList() {
    this.getPersonel();
    setTimeout(() => {
      this.personelInput$.next('');
    }, 300);
  }

  addNewPersonel() {
    this.nbDialogService.open(PersonelEkleFirmaComponent);
  }
}
