import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { BetonModel, DepoModel, DepoSinifModel } from '../models/depo.model';
import { ProductModel } from '../models/product.model';
import { removeAllNull } from '../pages/global-functions';



@Injectable({ providedIn: 'root' })
export class DepoService {
    private readonly API_URL = `${environment.backendurl}api/depo/`;


    constructor(private httpClient: HttpClient) { }
    getDepo(): Observable<DepoModel[]> {
        return this.httpClient.get<any>(this.API_URL + 'list/');
    }

    addDepo(data): Observable<DepoModel> {
        return this.httpClient.post<DepoModel>(this.API_URL + 'add/', data);
    }
    updateDepo(data, depo_id): Observable<DepoModel> {
        return this.httpClient.patch<DepoModel>
            (this.API_URL + 'update/' + depo_id + '/', data);
    }
    deleteDepo(depo_id): Observable<DepoModel> {
        return this.httpClient.delete<DepoModel>(this.API_URL + 'delete/' + depo_id + '/');
    }
    addInOrOut(data): Observable<ProductModel[]> {
        return this.httpClient.post<ProductModel[]>(this.API_URL + 'inout/', data);
    }

    getDepoProduct(_depo: number): Observable<ProductModel[]> {
        return this.httpClient.get<ProductModel[]>
            (this.API_URL + 'depourunlerlist/' + _depo + '/');
    }
    getUrunHareket(depo_id: number, product_id: number): Observable<ProductModel[]> {

        const url: string = `${this.API_URL}depotekurunlist/${depo_id}/${product_id}/`;
        console.log('depo_id:', depo_id, ' product_id:', 'url:', url);
        return this.httpClient.get<ProductModel[]>(url);
    }
    getUrunTumHareket(depo_id: number, product_id: number, link_to_own: number): Observable<ProductModel[]> {
        return this.httpClient.get<ProductModel[]>
            (this.API_URL + 'depourunlinklist/' + depo_id +
                '/' + product_id + '/' + link_to_own + '/');
    }

    /**
     * Depo / Depolar sayfasındaki filtre kısmı için personel listesini getirir. (Searchable infinite select)
     * @param data search input text
     * @param page required değil
     */


    personelController(data?: string, page?: number, tedarikci_apas_id?): Observable<any[]> {
        const url: string = `${this.API_URL}person/${(page ? page + '/' : '')}`;

        const requetData = data ? { search: data } : {};
        if (tedarikci_apas_id) {
            requetData['tedarikci_apas_id'] = tedarikci_apas_id;
        }
        return this.httpClient.post<any[]>(url, requetData);
    }

    /**
     * Depo / Depolar sayfasındaki filtre kısmı için tedarikçi listesini getirir. (Searchable infinite select)
     * @param serach search input text
     * @param page required değil
     */
    tedarikciController(serach?: string, page?: number): Observable<any[]> {
        const url: string = `${this.API_URL}tedarikci/${(page ? (page + '/') : '')}`;

        const requetData = serach ? { search: serach } : '';
        return this.httpClient.post<any>(url, requetData);
    }

    /**
     * Depo / Depolar sayfasındaki filtre işlemlerini yöneten servis.
     * @param data ware_house -> depo id REQUIRED, tedarikci_apas_id ->
     * seçilen tedarikci id required değil, personel -> seçilen personelin ismi required değil.
     * @param page required değil,
     */
    filterController(data: any, page?: number): Observable<any> {
        const url: string = `${this.API_URL}filter/${(page ? (page + '/') : '')}`;
        return this.httpClient.post<any[]>(url, data);
    }



    patchBetonDokum(identifier, data): Observable<any> {
        const url: string = `${this.API_URL}beton/update/${identifier}/`;
        const req = new HttpRequest('PATCH', url, data, { reportProgress: true });
        return this.httpClient.request<BetonModel>(req);
    }

    postBetonDokum(data): Observable<HttpEvent<BetonModel>> {
        const url: string = `${this.API_URL}beton/add/`;
        const req = new HttpRequest('POST', url, data, {
            reportProgress: true,
        });

        return this.httpClient.request<BetonModel>(req);
    }

    getBetons(filters: any): Observable<any> {
        const url = `${this.API_URL}betonlist/`;
        const params = removeAllNull(filters);
        return this.httpClient.post<BetonModel[]>(url, params);
    }
    getBetonSinif(filters?: any): Observable<DepoSinifModel[]> {
        const url = `${this.API_URL}betonsinif/`;
        filters = filters ? filters : {};
        return this.httpClient.post<DepoSinifModel[]>(url, filters);
    }

    getBetonDetay(identifier: any): Observable<HttpEvent<BetonModel>> {
        const url = `${this.API_URL}beton/tek/${identifier}/`;
        const req = new HttpRequest('GET', url, {
            reportProgress: true,
        });
        return this.httpClient.request(req);
    }

    newBetonList(filter: any): Observable<any> {
        const url: string = `${this.API_URL}newbetonlist/`;
        return this.httpClient.post(url, filter);
    }

    newBetonDetay(filter: any): Observable<any> {
        /**
         * @filterParams : tedarikci_id, santiye_id, beton_sinif
         */
        const url: string = `${this.API_URL}newbetondetay/`;
        return this.httpClient.post(url, filter);
    }

    getProductForTalep(product_id: number): Observable<any[]> {
        const url: string = `${this.API_URL}taleplist/${product_id}/`;

        return this.httpClient.get<any[]>(url);
    }


    getIrsaliyeImage(identifier: string) {
        const url: string = `${this.API_URL}getirsaliye/${identifier}/`;
        return this.httpClient.get(url);
    }

    editHareket(instanceId, data) {
        const url: string = `${this.API_URL}edithareket/${instanceId}/`;
        return this.httpClient.patch(url, data);
    }

    deleteHareket(instanceId) {
        const url: string = `${this.API_URL}deletehareket/${instanceId}/`;
        return this.httpClient.delete(url);
    }

    getRowAgain(wareHouseId, product_id) {
        const url: string = `${this.API_URL}getonerow/${wareHouseId}/${product_id}/`;
        return this.httpClient.get(url);
    }
}
