import * as jobActions from './jobs.actions';
import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { JobModel } from '../../components/select-componentler/job-select/job.model';

interface JobState extends EntityState<JobModel> {
    loading: boolean;
    loaded: boolean;
    error: String;
}

const jobsAdapter: EntityAdapter<JobModel> = createEntityAdapter<JobModel>();

const defaultJobsState: JobState = {
    ids: [],
    entities: {},
    loading: false,
    loaded: false,
    error: '',
};

const initialState = jobsAdapter.getInitialState(defaultJobsState);

const jobsReducer = createReducer(
    initialState,
    on(jobActions.loadJobs, (state, _props) => {
        return { ...state, loading: true };
    }),
    on(jobActions.loadJobsSuccess, (state, props) => {
        return jobsAdapter.upsertMany(props.jobItems, {
            ...state,
            loading: false,
            loaded: true,
        });
    }),
    on(jobActions.loadJobsFail, (state, props) => {
        return { ...state, loading: false, end: true, error: props };
    }),
    on(jobActions.addJobsItem, (state, props) => {
        return jobsAdapter.upsertOne(props.jobItem, {
            ...state,
            selectedCustomerId: props.jobItem.id,
        });
    }),

);

function JobsReducer(action, state) {
    return jobsReducer(action, state);
}

export {
    JobState,
    defaultJobsState,
    JobsReducer,
};
