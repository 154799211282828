<nb-card class="modalType animated fadeInRight" size="giant" style="width: 1100px;">
  <nb-card-header>Satın Alma <button nbButton (click)="closed()" size="tiny" class="m-close"
    style="background: none !important; color: #7c7c7c !important;  float: right;  font-size: 18px;">X</button></nb-card-header>
  <nb-card-body style="overflow:hidden;">
    <iframe #iframe  frameborder="0" style="overflow:hidden;height:100%;width:100%" height="100%" width="100%"></iframe>

  </nb-card-body>
  <nb-card-footer>
    <!-- <button nbButton status="primary" style="float: right;" (click)="closed()">
      Kapat
    </button> -->
  </nb-card-footer>
</nb-card>  