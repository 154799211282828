<div class="aps-splt" [formGroup]="form" [nbSpinner]="isLoading">
  <div class="d-flex align-items-center">
    <div>
      <h6 class="pageHeader" *ngIf="headerShow">Depo</h6>
    </div>

  </div>
  <nb-card>
    <!--Filter Template-->
    <apas-filter [parentComponent]="this" [bodyTemplate]="body" [islemlerTemplate]="islemler" [araText]="'Ürün Ara'"
      formControlName="search" [showFilterButton]="filterShow" [formValue]="form">
    </apas-filter>

    <ng-template #body>

      <div class="form-group">
        <label class="label"><b>Firma</b></label>


        <organization-select formControlName="tedarikci_apas_id" bindValue="apas_id"></organization-select>

      </div>
      <!--// Tedarikçi-->
      <!--Teslim alan-->
      <div class="fomr-group">
        <label class="label"><b>Teslim alan</b></label>
        <div class="form-group">
          <personel-select formControlName="personel"></personel-select>
        </div>
      </div>
      <!--// Teslim Alan-->

      <!--Tarih Aralığı -->
      <div class="form-group">
        <label class="label"><b>Başlangıç Tarihi</b></label>
        <input id="startDate" fullWidth nbInput placeholder="Başlangıç Tarihi" [nbDatepicker]="startPicker"
          formControlName="start_date">
        <nb-datepicker #startPicker></nb-datepicker>
      </div>

      <div class="form-group">
        <label class="label"><b>Bitiş Tarihi</b></label>
        <input id="endDate" fullWidth nbInput placeholder="Bitiş Tarihi" [nbDatepicker]="endPicker"
          formControlName="end_date">
        <nb-datepicker #endPicker></nb-datepicker>
      </div>
      <!--// Tarih Aralığı -->

    </ng-template>

    <ng-template #islemler>

      <!--Ürün Giriş Aç-->
      <button nbButton [nbPopover]="urunGirisiPop" status="warning" size="small">
        Ürün Girişi veya Ürün İadesi Yap
      </button>

      <ng-template #urunGirisiPop let-data>
        <nb-card class="templateCard outoo p-0 m-0 borderTop" style="min-width:300px;">
          <nb-card-body class="py-0">
            <nb-list>
  
              <nb-list-item class="linked-div muhasebeNbListItems " (click)="urunIslemYap(null,2)">
                <b>Tedarikçiden Ürün Girişi Yap</b>
              </nb-list-item>

              <nb-list-item class="linked-div muhasebeNbListItems " (click)="urunIslemYap(null,3)">
                <b>Ürün Çıkışı Yap</b>
              </nb-list-item>

              <nb-list-item class="linked-div muhasebeNbListItems " (click)="urunIslemYap(null,1)">
                <b>Sahadan Ürün Girişi Yap</b>
              </nb-list-item>

              <nb-list-item class="linked-div muhasebeNbListItems " (click)="urunIslemYap(null,4)">
                <b>Ürün İadesi Yap</b>
              </nb-list-item>

              <nb-list-item class="linked-div muhasebeNbListItems " (click)="urunIslemYap(null,6)">
                <b>Depolar Arası Ürün Transferi</b>
              </nb-list-item>

              <nb-list-item class="linked-div muhasebeNbListItems " (click)="urunIslemYap(null,5)">
                <b>Fire / Zayiat Ekle</b>
              </nb-list-item>



            </nb-list>
          </nb-card-body>
        </nb-card>
      </ng-template>
      <!--// Ürün Giriş Aç  -->
    </ng-template>


    <!--// Filter Template-->
  </nb-card>
  <div>



  </div>
  <div class="row mb-2">
    <div class="col-md-4">
      <depo-select formControlName="warehouse_id"></depo-select>

    </div>
    <div class="col-md">
      <button class="excel_button" nbButton size="small" (click)="exportExcel()" style="float: right;"
        [nbSpinner]="excelLoading">
        <i class="fas fa-file-excel pr-2"></i>Excel Çıktısı Al
      </button>

      <date-range startDateControlName="start_date" endDateControlName="end_date"></date-range>
    </div>
  </div>
  <nb-card class="anaTable">
    <nb-card-header>
      <div class="row m-0 p-0 tableHeader">

        <div class="col-md-4">
          <span>{{'DEPO.URUNADI'|translate}}</span>
        </div>
        <div class="col-md-2" *ngIf="!form.get('warehouse_id').value ">
          Depo
        </div>
        <div class="col-md-2">
          <span>{{'DEPO.MIKTAR'|translate}} </span>
        </div>

        <!-- filtreleme yapılmış-->
        <div class="col-md-2" *ngIf="filterControl">
          Tedarikçi
        </div>
        <div class="col-md-2" *ngIf="filterControl">
          İşlem Türü
        </div>
        <!-- filtreleme yapılmış-->

        <!--  filtreleme yapılmamış -->



        <div class="col-md-2" *ngIf="!filterControl">
          Çıkan
        </div>

        <div class="col-md-2" *ngIf="!filterControl">
          Kalan
        </div>
        <!--  filtreleme yapılmamış -->


      </div>
    </nb-card-header>
    <nb-card-body>
      <nb-list *ngIf="rows && rows.length > 0">

        <nb-list-item class="aps-hover p-0 listCustom linked-div" *ngFor="let row of rows;let i = index;" nbInfiniteList
          listenWindowScroll [threshold]="100" (bottomThreshold)="goToNextPage()" nbInfiniteList listenWindowScroll
          [threshold]="100">

          <nb-accordion class="w-100" style="box-shadow: none; ">
            <!-- Akordiyon, Tablo Başlangıcı  -->

            <nb-accordion-item style="background: none;">
              <nb-accordion-item-header class="mouseoverSari p-0 m-0" style="font-size: small;">

                <div class="col-md-4" [nbTooltip]="row.product_name">
                  <span>
                    <div class="d-flex align-items-center">
                      <div>
                        <span>{{ i + 1 }} )</span>
                      </div>
                      <div class="ml-2">
                        <b>{{formatLongText(row?.product_name,30)}}</b>
                        <small *ngIf="filterControl" class="d-block" style="margin-top:-8px;">
                          {{row?.brand_name}}
                        </small>
                      </div>

                    </div>
                  </span>
                </div>

                <div class="col-md-2" *ngIf="!form.get('warehouse_id').value " [nbTooltip]="row?.warehouse_name">
                  {{formatLongText(row?.warehouse_name,20)}}
                </div>

                <div class="col-md-2">
                  <span *ngIf="!filterControl">
                    <!--*ngIf="row.positive_amount"-->
                    <apas-para-item [value]="row?.positive_amount" [alacak]="false" [negative]="true" [decrypt]="false"
                      [setColor]="false" [currency]="false" [animate]="false"></apas-para-item> {{getBirim(row?.unit)}}

                  </span>
                  <span *ngIf="filterControl">
                    <b>
                      <apas-para-item [value]="row?.amount" [alacak]="false" [negative]="true" [decrypt]="false"
                        [setColor]="false" [currency]="false" [animate]="false"></apas-para-item>
                      {{getBirim(row?.unit)}}
                    </b>
                  </span>
                </div>


                <div class="col-md-2" *ngIf="filterControl">
                  <span>
                    {{row?.delivery_by_org_name}}
                  </span>
                </div>
                <div class="col-md-2" *ngIf="filterControl">
                  <b [class]="'depoIslemTuru'+row?.warehouse_in_out_type">
                    {{c.setWarehouseInOutText(row?.warehouse_in_out_type)}} <br> ({{row?.created_time |
                    date:tarih_format}})
                  </b>
                </div>

                <!--  filtreleme yapılmamış çıkan ve kalan miktarı gösterir -->

                <div class="col-md-2" *ngIf="!filterControl">
                  <apas-para-item [value]="row.positive_amount - row.total_amount" [alacak]="false" [negative]="true"
                    [decrypt]="false" [setColor]="false" [currency]="false" [animate]="false"></apas-para-item>
                  {{getBirim(row?.unit)}}
                </div>

                <div class="col-md-2" *ngIf="!filterControl">
                  <b>
                    <apas-para-item [value]="row?.total_amount" [alacak]="false" [negative]="true" [decrypt]="false"
                      [setColor]="false" [currency]="false" [animate]="false"></apas-para-item>{{getBirim(row?.unit)}}
                  </b>
                </div>
                <!--  filtreleme yapılmamış -->

              </nb-accordion-item-header>
              <nb-accordion-item-body>


                <div class="row py-3">
                  <div class="col-md-12 text-right">
                    <!--Ürün Dosyası-->
                    <span *ngIf="filterControl">
                      <button nbButton status="warning" size="small" [nbTooltip]="'Dosya'" *ngIf="row?.irsaliye_image"
                        (click)="openIrsaliyeImage(row)">
                        <nb-icon icon="file-outline"></nb-icon> {{'DOSYAAC'|translate}}
                      </button>

                      <button nbButton size="small" [nbTooltip]="'Dosya'" *ngIf="!row?.irsaliye_image">
                        <nb-icon icon="file-outline"></nb-icon> {{'DOSYAYOK'|translate}}
                      </button>

                    </span>
                    <!--Ürün Dosyası-->

                    <!-- <ng-container *ngIf="mainWarehouseId.value">
                </ng-container> -->


                    <!--Sahadan ürün girişi-->
                    <button nbButton size="small" style="background:#b048c2;color:#fff;" (click)="urunIslemYap(row,1)">
                      <nb-icon icon="arrow-forward-outline"></nb-icon>
                      {{ 'DEPO.SAHAURUN2' | translate }}
                    </button>
                    <!--Sahadan ürün girişi-->

                    <!--Tedarikçiden ürün girişi-->

                    <button nbButton size="small" outLine (click)="urunIslemYap(row,2)"
                      style="background:#8bc34a; color:#fff;">
                      <nb-icon icon="arrow-downward-outline"></nb-icon>
                      Tedarikçiden Ürün Girişi
                    </button>
                    <!--Tedarikçiden ürün girişi-->

                    <!--Ürün iade-->

                    <button nbButton status="warning" size="small" outLine (click)="urunIslemYap(row,4)">
                      <nb-icon icon="arrow-upward-outline"></nb-icon>

                      {{ 'DEPO.URUNIADE' | translate }}
                    </button>
                    <!--Ürün iade-->

                    <!--Ürün Fire ve Zayiat-->

                    <button nbButton size="small" outLine (click)="urunIslemYap(row,5)"
                      style="background-color: #cd4444 !important;color:#fff;">
                      <nb-icon icon="close-outline"></nb-icon>
                      Fire / Zayiat Ekle
                    </button>
                    <!--Ürün Fire ve Zayiat-->

                    <!--Ürün Transfer-->

                    <button nbButton size="small" outLine (click)="urunIslemYap(row,6)"
                      style="background-color: #8c7ae6 !important;color:#fff;">
                      <nb-icon icon="flip-2-outline"></nb-icon>
                      Transfer Et
                    </button>
                    <!--Ürün Transfer-->



                    <!--Ürün Hareketleri-->

                    <button nbButton [nbTooltip]="'Ürün Hareketleri'" size="small"
                      style=" background: #00BCD4 !important; color:#fff;" (click)="urunHareket(row)">
                      <nb-icon icon='eye-outline' style="transform: scale(1.5);"></nb-icon> Ürün Hareketleri
                    </button>
                    <!--Ürün Hareketleri-->


                    <!--Ürün Düzenle-->

                    <button nbButton size="small" (click)="hareketDuzenle(row)" *ngIf="filterControl"
                      style="background-color: #01579B !important;color:#fff;" outLine>
                      <nb-icon icon="edit-outline" style="transform: scale(1.5);"></nb-icon> Düzenle
                    </button>
                    <!--Ürün Düzenle-->


                    <!--Ürün Sil-->

                    <button nbButton size="small" (click)="urunSil(row)" *ngIf="filterControl"
                      style="background-color: red !important;color:#fff;" outLine>
                      <nb-icon icon="trash-outline" style="transform: scale(1.5);"></nb-icon> Sil
                    </button>
                    <!--Ürün Sil-->

                    <!--Ürün Çıkış-->

                    <button nbButton size="small" (click)="urunIslemYap(row,3)"
                      style="background:#e1ca05 !important; color:#fff;">
                      <nb-icon icon="arrowhead-right-outline"></nb-icon> {{ 'DEPO.CIKIS' | translate }}
                    </button>
                    <!--Ürün Çıkış-->



                  </div>
                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>

          </nb-accordion>



        </nb-list-item>


      </nb-list>


      <!-- <aps-emptylist *ngIf="!isLoading && !(rows && rows?.length > 0)" [text]="' '"
        [message]="rows.length<=0 && form.get('warehouse_id').value ? 'Seçmiş olduğunuz depoda ürün yok' : 'Depo Seçiniz'">
      </aps-emptylist> -->

      <aps-emptylist *ngIf="!isLoading && !(rows && rows?.length > 0)">
      </aps-emptylist>


    </nb-card-body>
  </nb-card>



</div>