<div [class.readed]="!context?.is_readed" class="px-2 py-4  bildirimItem linked-div " (click)="click()" style=" border-bottom: 1px solid #607d8b3d !important;padding: 15px !important;">
    <div class="row">
        <div class="col-1" style="padding: 5px;margin: 5px; background: #607d8b38; border-radius: 5px;height:30px">
            <nb-icon icon="icon-gorevler" pack="custom-icons"></nb-icon>
        </div>
        <div class="col-9">
            {{context?.message}}
        </div>
        <div class="col-1" style="place-self: center;">
            <nb-icon *ngIf="context?.is_readed" icon="done-all-outline" style="color:#ff7043"></nb-icon>
            <nb-icon *ngIf="!context?.is_readed" icon="radio-button-off"></nb-icon>
        </div>
    </div>
    <div style="padding-bottom: 5px;padding-right: 89px;text-align: right;"> <small style="position: absolute;">{{context?.created_time *1000 | date:'dd/MM/yyyy HH:mm'}} </small> </div>

</div>