import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbTabsetComponent } from '@nebular/theme';
import { Store, select } from '@ngrx/store';
import Swal from 'sweetalert2';
import * as fromExchanges from '../muhasebe-store/exchanges/exchanges.connector';
import * as exchangesActions from '../muhasebe-store/exchanges/exchanges.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { EntegrasyonService } from '../../../services/entegrasyon.service';
import { BasvuruSecenekleriComponent } from '../e-fatura-sureci/basvuru-secenekleri/basvuru-secenekleri.component';
import { BasvuruBilgisiComponent } from '../e-fatura-sureci/basvuru-bilgisi/basvuru-bilgisi.component';
import { MuhasebeYedekService } from '../ayarlar/yedek/yedek.service';
import * as moment from 'moment';
import { downLoadFile } from '../../../pages/global-functions';
import { BasvuruTypes } from '../entegrasyon/enums';
import { MuhasebeService } from '../muhasebe-service/muhasebe.service';
import { ExchangeModel } from '../models/exchanges.model';
import { getOrgInfo } from '../../../store/organization/organization.connector';
import { CheckListMainComponent } from '../../../components/check-list/check-list-main/check-list-main.component';

export enum MuhasebeTabType {
  'Empty' = 'empty',
  'Dashboard' = 'ÖZET',
  'Hesap' = 'HESAPLAR',
  'Cari' = 'CARİ HESAPLAR',
  'Masraf' = 'MASRAFLAR',
  'Alis' = 'ALIŞLAR',
  'Satis' = 'SATIŞLAR',
  'Ceksenet' = 'ÇEK-SENET',
  'Rapor' = 'RAPORLAR',
  'Ayarlar' = 'AYARLAR',
  'islemler' = 'İŞLEMLER',
  'Efatura' = 'E-FATURA',
}
// 'Earsiv' = 'E-ARŞİV',


@Component({
  selector: 'ngx-apasx-main',
  templateUrl: './apasx-main.component.html',
  styleUrls: ['./apasx-main.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [EntegrasyonService, MuhasebeService, MuhasebeYedekService],

})
export class ApasxMainComponent implements OnInit, OnDestroy {
  @ViewChild('muhasebemain') mainView: ElementRef;
  bugun = new Date();
  muhasebeTapType = MuhasebeTabType;
  selectedTab = this.muhasebeTapType.Dashboard;
  isNavigated;
  orgInfo;
  permision;
  @ViewChild('muhasebeTab') tabSet: NbTabsetComponent;

  constructor(
    private router: Router,
    private entegrasyonService: EntegrasyonService,
    private store: Store<{ Exchanges }>,
    private nbDialogService: NbDialogService,
    private activatedRouter: ActivatedRoute,
    private yedekService: MuhasebeYedekService,
  ) {

    this.activatedRouter.queryParams.subscribe(val => {
      if (val?.yedek_download_link) {
        this.downloadYedek(val?.yedek_download_link);
      }
    })

    if (this.router.getCurrentNavigation().extras.state != null) {
      this.selectedTab = this.router.getCurrentNavigation().extras.state.tab;
      this.isNavigated = true;
    }

    this.getExchangesList();
  }

  ngOnDestroy(): void {
    clearInterval(this.kurInterval);
  }


  kurInterval;
  ngOnInit() {
    this.getEntegrasyonStatus();
    this.kurInterval = setInterval(() => {
      this.kurGetir();
    }, 600000);

    this.store.select(getOrgInfo).subscribe((data:any) => {
      this.orgInfo = data;
      if(data.id){
        console.log('Organization Data',data,data.moduls.find(x => x.modulname =='muhasebe')?.value)
      this.permision = data.moduls.find(x => x.modulname =='muhasebe').value;
      }
    });

  }

  tabChange(event) {
    this.selectedTab = event.tabTitle;
    if (this.menuAcikmi) {
      this.menuShowHide();
    }
  }


  isCariLoading: boolean = false;
  isCariLoaded: boolean = false;
  isCariLoadingEnd: boolean = false;


  isExchangesLoading: boolean = false;
  isExchangesLoaded: boolean = false;

  getExchangesList() {
    this.isExchangesLoading = true;
    // cari durumunu bildirir.
    this.store
      .pipe(select(fromExchanges.selectExchangeLoading))
      .subscribe((durum) => {
        this.isExchangesLoading = durum;
      });

    this.store
      .pipe(select(fromExchanges.selectExchangeLoaded))
      .subscribe((durum) => {
        this.isExchangesLoaded = durum;
      });
    // Veri Çekmeye başlar.
    // eğer daha önce akış indirilmiş ise başdan çekmez.
    if (!this.isExchangesLoading && !this.isExchangesLoaded) {
      this.store.dispatch(exchangesActions.loadExchanges());
    }

    this.store
      .pipe(select(fromExchanges.selectExchange))
      .subscribe((stateData) => {
        this.kurOne = stateData.currency_one;
        this.kurTwo = stateData.currency_two;
        this.kurTree = stateData.currency_tree;
        this.kurGetir();
      });
  }

  kur0Rate: number;
  kur1Rate;
  kur2Rate;
  kur3Rate;
  kurGuncellemeSaati;
  kurGuncellemeSaati1: string;
  kurOne;
  kurTwo;
  kurTree;

  kurGetir() {
    const _org = JSON.parse(localStorage.getItem('org'));
    let ontanimliPara = null;
    if (_org) {
      ontanimliPara = _org.default_currency;
    } else {
      return;
    }

    const xhr = new XMLHttpRequest();
    const url = 'https://canlidoviz.com/doviz-kurlari.jsonld';
    xhr.open('POST', url);

    const prom = ((y) =>
      new Promise((resolve, reject) => {
        xhr.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200) {
            resolve(this.responseText);
          }
        };
      }).then((gelenResponse: string) => {
        const veri = JSON.parse(gelenResponse);
        const kurlar: ExchangeModel[] = [...veri.mainEntity.itemListElement.map((x: any) => { return {id: x.currency, name: x.currency, price: x.currentExchangeRate.price } })];
        
        this.store.dispatch(exchangesActions.saveExchangeData({data: kurlar}))
        this.kur0Rate = 1;
        let a: number = 0;

        kurlar.forEach((item) => {
          if (item.name === ontanimliPara) {
            this.kur0Rate = Number(item.price);
          }

          if (item.name === this.kurOne) {
            a = Number(item.price);
            this.kur1Rate = (a / this.kur0Rate).toFixed(4);
          }
          if (item.name === this.kurTwo) {
            a = Number(item.price);
            this.kur2Rate = (a / this.kur0Rate).toFixed(4);
          }
          if (item.name === this.kurTree) {
            a = Number(item.price);
            this.kur3Rate = (a / this.kur0Rate).toFixed(4);
          }
        });

        if (this.kurOne === 'TRY') {
          a = 1 / this.kur0Rate;
          this.kur1Rate = (a / this.kur0Rate).toFixed(4);
        }
        if (this.kurTwo === 'TRY') {
          a = 1 / this.kur0Rate;
          this.kur2Rate = (a / this.kur0Rate).toFixed(4);
        }
        if (this.kurTree === 'TRY') {
          a = 1 / this.kur0Rate;
          this.kur3Rate = (a / this.kur0Rate).toFixed(4);
        }

        if (this.kurOne === 'JPY') {
          this.kurOne = '100xJPY';
        }
        if (this.kurTwo === 'JPY') {
          this.kurTwo = '100xJPY';
        }
        if (this.kurTree === 'JPY') {
          this.kurTree = '100xJPY';
        }

        this.kurGuncellemeSaati = new Date();
        if (this.kurGuncellemeSaati) {
          this.kurGuncellemeSaati1 = this.kurGuncellemeSaati.toLocaleTimeString();
        }
      }))();
    xhr.send('');
    
  }


  menuAcikmi = false;
  menuShowHide() {
    document.getElementById('myMenu').classList.toggle('mobildeGoster');
    this.menuAcikmi = !this.menuAcikmi;

  }

  sifreliAlert() {
    Swal.fire({
      title: 'Apas+ Muhasebe Veri Güvenliği !',
      width: 600,
      html: `Apas+ Muhasebe Modülünde; Para ile ilgili Verileriniz <br><b> Uçtan Uca ŞİFRELENİR</b>.
      <br/> Parasal Veriler; Veri Tabanlarımızda  Anlamsız(Şifrelenmiş) Bilgiler Halinde Saklanır.<br>
      Şirketinize Özel Üretilen ve Sadece Sizde Bulunan Anahtarla Okuyabileceğiniz Hale Getirilir.`,
      imageUrl: '../../../../assets/images/enc.png',
      imageAlt: 'Custom image',
    });
  }

  basvuruTypes = BasvuruTypes;
  basvuruStatus: BasvuruTypes;
  getEntegrasyonStatus() {
    this.entegrasyonService.getStatus().subscribe(data => {
      this.basvuruStatus = data.status;
    }, (err: HttpErrorResponse) => {
      if (err.status === 400) {
        if (err.error.ER35) {
          this.basvuruStatus = BasvuruTypes.basvuruYap;
        } else if (err.error.ER36) {
          this.basvuruStatus = BasvuruTypes.basvuruYapildi;
        }
      } else {
        this.basvuruStatus = null;
      }
    });

  }

  openEntegrasyon() {
    switch (this.basvuruStatus) {
      case this.basvuruTypes.basvuruYap:
        this.nbDialogService.open(BasvuruSecenekleriComponent).onClose.subscribe(() => {
          this.getEntegrasyonStatus();
        });
        break;
      case this.basvuruTypes.basvuruYapildi:

        this.nbDialogService.open(BasvuruBilgisiComponent).onClose.subscribe(() => {
          this.getEntegrasyonStatus();
        });
        break;
      default:
        break;
    }

    // this.nbDialogService.open(MuhasebeEFaturaComponent).onClose.subscribe(val => {
    //   setTimeout(() => {
    //     this.getEntegrasyonStatus();
    //   }, 150);
    // });
  }

  downloadYedek(identifier) {
    this.yedekService.getLastYedek(identifier).subscribe((resp: any) => {
      const today = moment().format("DD_MM_yyyy")
      const fileName = `${today}_apas_muhasebe_yedek_${identifier}.zip`;
      downLoadFile(resp.file, fileName);
    })
  }
  openCheckList() {

    const context = {
      instanceId: this.orgInfo.id,
      instanceType: 'organization',
      instanceTitle: 'Muhasebe',
    };

    this.nbDialogService.open(CheckListMainComponent, { context: context, closeOnBackdropClick: false });
  }

}

