import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { NbComponentSize } from '@nebular/theme/public_api';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { inputMaskTemizle, yuvarla } from '../pages/global-functions';
import { paraInputManuel } from '../pages/muhasebe/muhasebe-shared/paraInput';
import { getOrgInfo } from '../store/organization/organization.connector';
import { ApasDecryptePipe } from './apas-decrypte-pipe';
import { ApasEncryptePipe } from './apas-encrypte-pipe';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[paraDirective]',
})
export class ParaNumberDirective implements AfterViewInit, OnChanges, OnDestroy {

  @Input() encrypt: boolean = false;
  @Input() decrypt: boolean = false;
  @Input() negative: boolean = true;
  @Input() forceRefresh: number;
  @Input() plcholder: string;
  @Input() currency: string;
  @Input() maxDecimal: number = 2; // Virgülden sonra alacağı basamak sayısı.
  @Input() debug: boolean = false;
  @Input() fieldSize: NbComponentSize = "medium";
  ayrac$; // State Org Info Subscription. Close Before Leave

  binlikAyrac = '.';
  floatingAyrac: string = this.binlikAyrac === '.' ? ',' : '.';
  floatingCount: number = 0;
  numbers = [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
    ',', '.', 'Backspace', 'ArrowRight', 'ArrowLeft', 'Delete', 'Tab'];
  constructor(
    private el: ElementRef,
    private control: NgControl,
    private apasEncryptePipe: ApasEncryptePipe,
    private apasDecryptePipe: ApasDecryptePipe,
    private translate: TranslateService,
    private store: Store<{}>,
    private renderer: Renderer2,
  ) {

    this.ayrac$ = this.store.select(getOrgInfo).subscribe(StateOrgInfo => {
      this.binlikAyrac = StateOrgInfo.ayrac;
      this.floatingAyrac = this.binlikAyrac === '.' ? ',' : '.';
    });

  }
  ngOnDestroy(): void {
    if (this.ayrac$) this.ayrac$.unsubscribe();
  }

  currencySpan: HTMLElement;
  addCurrency() {
    if (!this.currencySpan) {
      this.currencySpan = this.renderer.createElement('span');


      if (this.fieldSize == 'small' || this.fieldSize == 'tiny') {
        this.currencySpan.classList.add('paraDirectiveCurrencySmall');
      } else {
        this.currencySpan.classList.add('paraDirectiveCurrency');
      }

      if (this.el) {
        this.renderer.insertBefore(this.el.nativeElement.parentElement, this.currencySpan, this.el.nativeElement);
      }
    }

    this.currencySpan.innerHTML = this.currency;

  }

  log(message: string) {
    if (this.debug) {
      console.log('Para Directive' ,message)
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    const elm = this.getInput();
    if (changes.decrypt) {
      let controlVal = this.clearDecrypt();
      if (controlVal) {
        controlVal = yuvarla(controlVal, this.maxDecimal);
        controlVal = paraInputManuel(controlVal);
        elm.setAttribute('data-model-value', controlVal);
        elm.value = controlVal;
      }

    }

    if (changes.forceRefresh) {
      let elmVal = elm.value;

      if (elm.value.slice(-1) === this.floatingAyrac) {
        // Son Karakter olarak virgül yazılmış. işlemleri bi sonraya bırakmak lazım.
        this.log('son karakterim virgül benim.');
        return;
      }

      const controlVal = this.control.control.value;
      const isNumber = !isNaN(controlVal);
      this.log(`control val : ${controlVal} , elm value : , ${elmVal}, 'is Number :  ${isNaN(controlVal)}`);

      if (isNumber) {
        elmVal = yuvarla(controlVal, this.maxDecimal);
        elmVal = paraInputManuel(elmVal);
      }
      elm.setAttribute('data-model-value', elmVal);
      elm.value = elmVal;
    }

    if (changes.currency) {
      this.addCurrency();
    }
  }

  clearDecrypt() {
    const elm = this.getInput();

    if (this.decrypt) {

      let controlVal: any = this.control.value;

      if (controlVal) {

        controlVal = this.apasDecryptePipe.transform(controlVal);
        controlVal = Number(controlVal);
        if (!this.negative) {
          controlVal = Math.abs(controlVal);
          const decData = paraInputManuel(controlVal);
          this.setControl(decData);
        }
        return controlVal;
      }
    }
  }


  ngAfterViewInit(): void {
    if (this.negative) {
      this.numbers.push('-');
    }
    const elm = this.getInput();
    let holder = this.translate.instant('INPUTS.AMOUNT');
    if (this.plcholder) {
      holder = this.plcholder;
    }
    elm.placeholder = holder;
    if (this.control.value) {
      // Eğer control değer ile initilze edildiyse, ilk değer olarak veriyi atar.
      let controlVal: any = this.control.value;
      if (this.decrypt) {
        controlVal = this.clearDecrypt();
      }
      if (controlVal) {
        controlVal = paraInputManuel(controlVal);
        elm.setAttribute('data-model-value', controlVal);
        // set view value using DOM value property
        elm.value = controlVal;
      }

    }
  }



  @HostListener('keydown', ['$event'])
  keyDown(event: KeyboardEvent) {

    const ele = this.getInput();
    if (ele) {
      if (this.numbers.indexOf(event.key) === -1 || event.key === this.binlikAyrac) {
        event.preventDefault();
        return;
      }
      if (!this.negative && event.key === '-') {
        event.preventDefault();
        return;
      }
      if (ele.value.length > 0 && event.key === '-') { 
        // negatif işareti ancak başta olabilir
        event.preventDefault();
        return;
      }
      if (ele.value.length === 0 && event.key === this.floatingAyrac) { 
        // Başta Float Ayraç Olamaz.
        event.preventDefault();
        return;
      }
      if (event.key === this.floatingAyrac && ele.value.indexOf(this.floatingAyrac) !== -1) { 
        // zaten bi tane virgül yazmışsa
        event.preventDefault();
        return;
      }
    }
  }

  ekranValue;
  @HostListener('keyup', ['$event'])
  keyUp() {
    let negatifmi = false;

    const ele = this.getInput();

    if (ele) {

      let para = ele.value;
      if (para && para !== null && para !== undefined) {
        const dizi = [...para];
        // negatif sayı ise '-' yi sil sonra tekrar koyacaksın
        if (dizi[0] === '-') { dizi.splice(0, 1); negatifmi = true; }

        dizi.forEach((item, index) => {
          // binlik ayraç varsa temizle, aşağıda yeniden yazacaksın
          if (item === this.binlikAyrac) dizi.splice(index, 1);
        });
        const floatingAyrac_Index = dizi.indexOf(this.floatingAyrac);

        let para_len = 0;

        if (floatingAyrac_Index !== -1) { // floating ayraç var
          para_len = floatingAyrac_Index;
          const decimals = dizi.slice(floatingAyrac_Index + 1);
          const decimalLenght = decimals ? decimals.length : 0;
          if (decimalLenght > this.maxDecimal) {
            const toRemove = (decimalLenght - this.maxDecimal);
            let i = 0;
            while (i < toRemove) {
              dizi.pop();
              i += 1;
            }
          }
        } else para_len = dizi.length;

        const kac_tane_ucluk = Math.floor(para_len / 3); // 12.564.256.878--->3
        const uclukten_kalan = para_len % 3; // /12.564.256.878--->2

        for (let i = 1; i < kac_tane_ucluk; i++) { // 1234567890 len=10 index=9
          dizi.splice((para_len - (3 * i)), 0, this.binlikAyrac);
        }

        if (uclukten_kalan > 0 && kac_tane_ucluk > 0) { // 1.234
          dizi.splice(uclukten_kalan, 0, this.binlikAyrac);
        }

        para = '';
        if (negatifmi) para += '-'; // sildiğin - yi tekrar yaz (binlik ayıraçta 3 er li grupta sqayılmasın diye)
        dizi.forEach(item => para += item);
      }
      ele.value = para;
      ele.setAttribute('data-model-value', ele);

      if (para.slice(-1) === this.floatingAyrac) {
        // son karakter ayraçsa forma herhangi bir şey set etme. bir sonraki karakteri bekle.
        return;
      }
      this.setControl(para);

    }
  }

  clearPara(para) {
    if (para) {
      para = inputMaskTemizle(para);
      // para = Number(para);
      // para = yuvarla(para, this.maxDecimal);
      return para;
    }
  }

  setControl(para) {
    if (para) {
      para = this.clearPara(para);
    }
    if (para && this.encrypt) {
      para = this.apasEncryptePipe.transform(para);
    }

    this.log(`Forma Atanan Değer : ${para} `);
    if (this.control.control.value !== para) {
      this.control.control.setValue(para, { emitModelToViewChange: false });
    }
  }

  getInput() {
    return this.el.nativeElement;
  }
}
