import { createSelector } from '@ngrx/store';
import * as fromRoot from '../../app-state';
import { bildirimAdapter, BildirimState } from './bildirim.reducer';



const bildirimFeatureState = createSelector(
    fromRoot.getUserStateSelector, (state: fromRoot.UserState) => state?.Bildirim,
);


const selectBildirim = createSelector(
    bildirimFeatureState,
    bildirimAdapter.getSelectors().selectAll,
);
const selectBildirimInfo = createSelector(
    bildirimFeatureState,
    (state: BildirimState) => state,
);
const selectUnShowedBildirim = createSelector(
    bildirimFeatureState,
    (state: BildirimState) => state.unShowedCount,
);
const selectBildirimEnd = createSelector(
    bildirimFeatureState,
    (state: BildirimState) => state.end,
);
const selectBildirimLoading = createSelector(
    bildirimFeatureState,
    (state: BildirimState) => state.loading,
);

const selectBildirimLoaded = createSelector(
    bildirimFeatureState,
    (state: BildirimState) => state.loaded,
);
const selectBildirimError = createSelector(
    bildirimFeatureState,
    (state: BildirimState) => state.error,
);

const getCurrentBildirimId = createSelector(bildirimFeatureState,
    (state: BildirimState) => state.selectedBildirimId);

const selectBildirimItem = createSelector(
    bildirimFeatureState,
    getCurrentBildirimId,
    state => state.entities[state.selectedBildirimId],
);

const connectionStatus = createSelector(
    bildirimFeatureState,
    (state: BildirimState) => state.connection,
);

export {
    selectBildirim,
    selectBildirimInfo,
    selectUnShowedBildirim,
    selectBildirimEnd,
    selectBildirimLoading,
    selectBildirimLoaded,
    selectBildirimError,
    getCurrentBildirimId,
    selectBildirimItem,
    connectionStatus
}