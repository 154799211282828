import * as fromRoot from '../../../../app-state';
import { createSelector } from '@ngrx/store';
import { AlisState, SatisState } from './alisSatisInterfaces';
import { AlisAdapter, SatisAdapter } from './alis-satis.reducer';



export const getAlisFeatureState = createSelector(
    fromRoot.getOrganizationSelector, (state: fromRoot.OrganizationState) => state?.Module.Muhasebe?.AlisSatis?.Alislar,
);

export const getSatisFeatureState = createSelector(
    // tslint:disable-next-line: max-line-length
    fromRoot.getOrganizationSelector, (state: fromRoot.OrganizationState) => state?.Module.Muhasebe?.AlisSatis?.Satislar,
);

export const selectAlis = createSelector(getAlisFeatureState, AlisAdapter.getSelectors()?.selectAll);

export const selectAlisLoading = createSelector(getAlisFeatureState, (state: AlisState) => state.loading);
export const selectAlisEnd = createSelector(getAlisFeatureState, (state: AlisState) => state.isEnd);

export const selectAlisList = createSelector(getAlisFeatureState, (state: AlisState) => state?.entities);

export const selectAlisItem = createSelector(
    getAlisFeatureState,
    (state: AlisState) => {
        return state.selectedAlis ? state.entities[state.selectedAlis] : null;
    },
);
export const selectSatis = createSelector(getSatisFeatureState, SatisAdapter.getSelectors()?.selectAll);

export const selectSatisLoading = createSelector(getSatisFeatureState, (state: SatisState) => state.loading);
export const selectSatisEnd = createSelector(getSatisFeatureState, (state: SatisState) =>  state.isEnd);


export const selectSatisList = createSelector(getSatisFeatureState, (state: SatisState) => state?.entities);

export const selectSatisItem = createSelector(
    getSatisFeatureState,
    (state: SatisState) => {
        return state.selectedSatis ? state.entities[state.selectedSatis] : null;
    },
);


