import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import * as organizationActions from './organization.actions';

@Injectable()
export class OrganizasyonEffects {


    // clearOrgInfo$ = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(organizationActions.ClearOrgInfo),
    //         tap(() => {
    //             this.store.dispatch(uyariOpen());
    //         }),
    //     );
    // });

    constructor(
        private actions$: Actions,
        private store: Store<{}>,
    ) {

    }
}
