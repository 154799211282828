import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class ChecklistService {

    private readonly API_URL = `${environment.backendurl}api/checklist/`;
    private readonly CHECKLIST_URL = `${this.API_URL}check/`;
    private readonly TEMPLATE_URL = `${this.API_URL}template/`;
    constructor(private httpClient: HttpClient) { }

    list(data): Observable<any> {
        const url = `${this.CHECKLIST_URL}list/`;
        return this.httpClient.post<any>(url, data);
    }

    addCheckItem(data) {
        /**
         * @param data = {'check_group': datanın içerisinde gelen id, 'title': 'yeni çek item'}
         */
        const url: string = `${this.CHECKLIST_URL}add/`;
        return this.httpClient.post(url, data);
    }

    addCheckGroup(data) {
        const url: string = `${this.CHECKLIST_URL}add/`;
        return this.httpClient.post(url, data);
    }

    updateCheckGroup(data) {
        const url: string = `${this.CHECKLIST_URL}update/`;
        return this.httpClient.patch(url, data);
    }

    deleteChekGroup(data) {
        const url: string = `${this.CHECKLIST_URL}delete/${data.instance_type}/${data.object_id}/`;
        return this.httpClient.delete(url, data);
    }

    setCheckStatus(itemIdentifier, status) {
        const url = `${this.CHECKLIST_URL}setstatus/${itemIdentifier}/`;
        return this.httpClient.patch(url, { status: status });
    }

    deleteChekItem(itemId) {
        const url = `${this.API_URL}delete/${itemId}/`;
        return this.httpClient.delete(url);
    }

    sablonList(instanceType: string) {
        // ^api/checklist/template/list/$ [name='defaultgroup-listele']
        const url: string = `${this.TEMPLATE_URL}list/`;
        return this.httpClient.post(url, { instance_type: instanceType });
    }

    addSablonGroup(data) {
        // ^api/checklist/template/add/$ [name='defaultgroup-add']
        const url: string = `${this.TEMPLATE_URL}add/`;
        return this.httpClient.post(url, data);
    }

    updateSablonGroup(instanceId, data) {
        // ^api/checklist/template/update/(?P<pk>\d+)/$ [name='defaultgroup-patch']
        const url: string = `${this.TEMPLATE_URL}update/${instanceId}/`;
        return this.httpClient.patch(url, data);
    }

    deleteSablonGroup(instanceId) {
        // ^api/checklist/template/update/(?P<pk>\d+)/$ [name='defaultgroup-patch']
        const url: string = `${this.TEMPLATE_URL}${instanceId}/`;
        return this.httpClient.delete(url);
    }

    applyToAll(data) {
        const url: string = `${this.TEMPLATE_URL}applytoall/`;
        return this.httpClient.post(url, data);
    }

    addSablonItem(data) {
        // ^api/checklist/template/item/$ [name='defaultitem-list']
        const url: string = `${this.TEMPLATE_URL}/item/add/`;
        return this.httpClient.post(url, data);
    }

    updateSablonItem(instanceId, data) {
        // ^api/checklist/template/item/(?P<pk>[^/.]+)/$ [name='defaultitem-detail']
        const url: string = `${this.TEMPLATE_URL}/item/${instanceId}/`;
        return this.httpClient.patch(url, data);
    }

    deleteSablonItem(instanceId) {
        // ^api/checklist/template/item/(?P<pk>[^/.]+)/$ [name='defaultitem-detail']
        const url: string = `${this.TEMPLATE_URL}/item/${instanceId}/`;
        return this.httpClient.delete(url);
    }

    setSablon(data) {
        /**
         * @param data : {
         *      'instance_type': 'santiye', (şantiye, taşeron, personel, v.b.)
         *      'object_id':'template'in atancağı objenin (şantiye, taşeron v.b.) id':
         *      'template_id':'atancak template id'
         * }
         */
        const url: string = `${this.TEMPLATE_URL}settemplate/`;
        return this.httpClient.post(url, data);
    }
}
