import * as fromRoot from '../../../../app-state';
import { createSelector } from '@ngrx/store';
import { cariAdapter, CariState } from './cari.reducer';
import { addOrUpdate, clearCapitalICharacters, orderByKey } from '../../../global-functions';
import { CariTypes } from '../../muhasebe-enum';



const getCariFeatureState = createSelector(
    fromRoot.getOrganizationSelector, (state: fromRoot.OrganizationState) => state?.Module.Muhasebe.Cari,
);

const selectCari = createSelector(
    getCariFeatureState,
    cariAdapter.getSelectors().selectAll,
);

const filterCari = createSelector(
    getCariFeatureState,
    (state: CariState) => {

        let liste = [];
        Object.values(state.entities).forEach(elm => {
            liste.push(elm);
        });
        const filter = state.filter;
        if (filter?.search && filter?.search !== null) {
            const newList = [];
            let searchVal = filter.search;

            searchVal = clearCapitalICharacters(searchVal);

            liste.map(item => {
                let unvan = item.unvan;
                unvan = clearCapitalICharacters(unvan);
                const st = unvan.search(searchVal);
                if (st > -1) newList.push(item);
            });
            liste = newList;
        }

        if (filter?.turu) {
            const turler = [];

            Object.keys(filter.turu).forEach(tur => {
                if (filter.turu[tur]) {
                    if (tur === CariTypes.taseron) turler.push(CariTypes.taseron);
                    else if (tur === CariTypes.musteri) turler.push(CariTypes.musteri);
                    else if (tur === CariTypes.personel) turler.push(CariTypes.personel);
                    else if (tur === CariTypes.tedarikci) turler.push(CariTypes.tedarikci);
                }
            });
            liste = liste.filter(item => turler.includes(item.turu));
        }

        if (filter?.tags && filter?.tags.length > 0) {

            let newListForTags = [];
            liste.forEach(cari => {
                // [{id: 3132, title: serhat}, {....}]
                const taglist = cari.tags.map(x => x.id);

                filter.tags.forEach(tag => {
                    if (taglist.indexOf(tag) > -1) {
                        newListForTags = addOrUpdate(newListForTags, cari);
                    } else {
                        const index = newListForTags.indexOf(cari);
                        if (index > -1) {
                            newListForTags.splice(index, 1);
                        }
                    }
                });
            });
            liste = newListForTags;
        }

        if (filter?.borclu === true) {
            let newList = [...liste];
            newList = newList.filter(x => x.borclu === true);
            liste = newList;
        }

        if (filter?.alacakli === true) {
            const newList = [];
            liste.map(x => {
                if (x.alacakli === true) {
                    newList.push(x);
                }
            });
            liste = newList;
        }

        if (filter?.is_active) {
            let newList = [...liste];
            newList = newList.filter(x => x.is_active === false);
            liste = newList;

        } else {
            let newList = [...liste];
            newList = newList.filter(x => x.is_active === true);
            liste = newList;
        }

        liste = orderByKey(liste, 'unvan');
        return liste;
    },
);

const selectCariLoading = createSelector(
    getCariFeatureState,
    (state: CariState) => state.loading,
);

const selectCariLoaded = createSelector(
    getCariFeatureState,
    (state: CariState) => state.loaded,
);

const selectCariEnd = createSelector(
    getCariFeatureState,
    (state: CariState) => state.isEnd,
);

const selectCariError = createSelector(
    getCariFeatureState,
    (state: CariState) => state.error,
);

const getCurrentCariId = createSelector(
    getCariFeatureState,
    (state: CariState) => state.selectedcariId,
);

const selectcariItem = createSelector(
    getCariFeatureState,
    (state: CariState) => {
        return state.selectedcariId ? state.entities[state.selectedcariId] : null;
    },
);

const selectCariById = (id) => createSelector(
    getCariFeatureState,
    (state: CariState) => state.entities[id],
);

const getSelectedCariId = createSelector(
    getCariFeatureState,
    (state: CariState) => {
        return state.selectedcariId ? state.selectedcariId : null;
    },
);

const getFilter = createSelector(
    getCariFeatureState,
    (state: CariState) => state.filter,
);

const getSelectedHareket = createSelector(
    getCariFeatureState, (state) => {
        const currentHesap = state.entities[state.selectedcariId];
        const hareket = currentHesap.cari_harekets.find(x => x.id === state.selectedHareketId);
        console.log('get cari selected hareket : ', hareket);
        return hareket;
    }
);
export {
    selectCari,
    filterCari,
    selectCariLoading,
    getSelectedHareket,
    selectCariLoaded,
    selectCariEnd,
    selectCariError,
    getCurrentCariId,
    selectcariItem,
    selectCariById,
    getSelectedCariId,
    getFilter,
};
