import { ActionReducer, combineReducers, compose, createFeatureSelector } from '@ngrx/store';
import { AkisReducer, AkisState, defaultAkisState } from './store/akis/akis.reducer';
import { BildirimReducer, BildirimState, defaultBildirimState } from './store/bildirim/bildirim.reducer';
import { defaultJobsState, JobsReducer, JobState } from './store/jobs/jobs.reducer';
import { ClearOrgInfo } from './store/organization/organization.actions';
import { defaultOrgState, OrgInfoReducer } from './store/organization/organization.reducer';
import {
    defaultSocketTokenState,
    SocketTokenReducer,
    SocketTokenState,
} from './store/socketToken/sockettoken.reducer';
import { defaultTagState, TagReducer, TagState } from './store/tags/tags.reducer';
import { logoutUserSuccess } from './store/user/user.actions';
import { defaultUserInfoState, UserInfoReducer, UserInfoState } from './store/user/user.reducer'; 
import { defaultModuleStates, ModuleReducer, ModuleState } from './store/module/module-state';
import { UserSettingsReducer, UserSettingState } from './store/user-settings/user.settings.reducer';

/** User Section */

const userReducers = {
    UserSettings: UserSettingsReducer,
    Bildirim: BildirimReducer,
    UserInfo: UserInfoReducer,
    SocketToken: SocketTokenReducer,
};

interface UserState {
    UserSettings: UserSettingState;
    Bildirim: BildirimState;
    UserInfo: UserInfoState;
    SocketToken: SocketTokenState;
}

const defaultUserStates = {
    Bildirim: defaultBildirimState,
    UserInfo: defaultUserInfoState,
    SocketToken: defaultSocketTokenState,
};

const userReducer: ActionReducer<UserState> = compose(
    (rootReducer: Function) => {
        return function (state, action) {
            if (action.type === logoutUserSuccess.type) {
                localStorage.clear();
                state = defaultUserStates;
            }
            return rootReducer(state, action);
        };
    }, combineReducers)(userReducers);

function UserReducer(state: any, action: any) {
    return userReducer(state, action);
}

const getUserStateSelector = createFeatureSelector<UserState>('User');

/** User Section END */

/** Organization Section */

const organizationReducers = {
    Akis: AkisReducer,
    Organizasyon: OrgInfoReducer,
    Module: ModuleReducer,
    TagsGroups: TagReducer,
    Jobs: JobsReducer,
};

const defaultOrganizationStates = {
    Akis: defaultAkisState,
    Organizasyon: defaultOrgState,
    Module: defaultModuleStates,
    TagsGroups: defaultTagState,
    Jobs: defaultJobsState,

};

interface OrganizationState {
    Akis: AkisState;
    Organizasyon: {};
    Module: ModuleState;
    TagsGroups: TagState;
    Jobs: JobState;
}

const organizationReducer: ActionReducer<OrganizationState> = compose(
    (rootReducer: Function) => {
        return function (state, action) {
            if (action.type === ClearOrgInfo.type) {
                state = defaultOrganizationStates;
                localStorage.removeItem('org');
            }
            return rootReducer(state, action);
        };
    }, combineReducers)(organizationReducers);

function OrganizationReducer(state: any, action: any) {

    return organizationReducer(state, action);
}

const getOrganizationSelector = createFeatureSelector<OrganizationState>('Organization');

/** Organization Section END */

export {
    OrganizationReducer,
    OrganizationState,
    organizationReducers,
    UserReducer,
    UserState,
    getOrganizationSelector,
    getUserStateSelector,
};
