import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { KullaniciService } from '../../../services/kullanici.service';
import { logoutUser } from '../../../store/user/user.actions';

@Component({
  selector: 'ngx-kullanici-logout',
  templateUrl: './kullanici-logout.component.html',
  styleUrls: ['./kullanici-logout.component.scss'],
  providers: [KullaniciService],
})
export class KullaniciLogoutComponent implements OnInit {
  token;
  constructor(
    private store: Store<{}>,
  ) {
    const _token = localStorage.getItem('token');
    this.token = JSON.parse(_token);
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.store.dispatch(logoutUser());
    }, 300);

  }
}
