<div class="pt-3 d-flex flex-column" [nbSpinner]="true">

        <p style="text-align: center; font-size: large; font-weight: 600; color: #2d6187;" >{{message}}</p>
        <p style="text-align: center; color: #2d6187; font-size: small;" >{{text}}</p>
<!-- 
        <ng-lottie
          style="align-self: center;"
          [width]="genislik"
          [height]="yukseklik"
          containerClass="moving-box another-class"
          [options]="options"
        ></ng-lottie> -->

</div>
