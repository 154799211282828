import { createSelector } from '@ngrx/store';
import { SocketTokenState } from './sockettoken.reducer';
import * as fromRoot from '../../app-state';


const socketTokenFeatureState = createSelector(
        fromRoot.getUserStateSelector, (state: fromRoot.UserState) => state?.SocketToken,
);

export const selectToken = createSelector(
        socketTokenFeatureState,
    (state: SocketTokenState) => state.token,
);
