<nb-card style="width:30%;border:unset;" class="modalType animated fadeInRight" size="giant">
    <nb-card-header class="d-flex justify-content-between">
        {{data?.edit ? 'Ürün Güncelle':'Yeni Ürün ekle'}}
        <button nbButton (click)="close()" size="tiny" class="heryerdeClose">X</button>
    </nb-card-header>

    <nb-card-body>

        <div   [formGroup]="form" >
            <div class=" row form-padding mx-1">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="label form-label">{{ 'MUTABAKATLISTE.ANLASMATARIHI' | translate }}</label>

                        <div class="tarih-pos">
                            <input nbInput [placeholder]="'TARIHPLACEHOLDER' | translate" dateOnly [nbDatepicker]="startpicker" fullWidth formControlName="start_date">
                            <nb-datepicker #startpicker></nb-datepicker>
                        </div>
                    </div>

                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="label form-label">{{ 'MUTABAKATLISTE.TIME' | translate }}</label>

                        <div class="tarih-pos" >
                            <input nbInput [placeholder]="'TARIHPLACEHOLDER' | translate" dateOnly [nbDatepicker]="formpicker" fullWidth formControlName="finish_date">
                            <nb-datepicker #formpicker></nb-datepicker>
                        </div>
                    </div>

                </div>
                <!--Tanım-->
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="label form-label"> {{'TEKLIFLER.BIRIM9'|translate}}</label>
                        <input nbInput [placeholder]="'TEKLIFLER.BIRIM9'|translate" fullWidth
                            formControlName="description" apasInvalid />

                    </div>
                </div>
                <!--//Tanım-->
                <!--Birim-->

                <div class="col-md-4">
                    <div class="form-group">
                        <label class="form-label label">{{'MUTABAKATLISTE.BIRIM' | translate}}</label>
                        <ng-select class="ng-select-without-bottom-margin" [items]="birim" appendTo="body"
                            [addTag]="false" [placeholder]="'MUTABAKATLISTE.BIRIM' | translate" bindLabel="label"
                            bindValue="value" [searchable]="true" formControlName="unit">
                        </ng-select>
                    </div>
                </div>
                <!--//Birim-->
                <!--Birim Fiyat-->
                <div class="col-md-4">
                    <div class="form-group">
                        <label [for]="'Birimfiyat'"
                            class="label form-label">{{'MUTABAKATLISTE.BIRIM2'|translate}}</label>
                        <input [id]="'Birimfiyat'" nbInput fullWidth formControlName="price" placeholder=" "
                            paraDirective apasInvalid [negative]="false" [plcholder]="'MUTABAKATLISTE.BIRIM2'|translate" />
                    </div>
                </div>
                <!--//Birim Fiyat-->
                <!--Miktar-->
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="label form-label"> {{'MUTABAKATLISTE.ADET'|translate}}</label>
                        <input [plcholder]="'MUTABAKATLISTE.ADET'|translate" paraDirective [negative]="false" nbInput
                            formControlName="amount" fullWidth apasInvalid />
                    </div>
                </div>
                <!--//Miktar-->


                <!--Tutar-->
                <div class="col-md-9">
                    <div class="form-group">
                        <label class="label form-label">{{'MUTABAKATLISTE.TOPLAMFIYAT'|translate}}</label>

                        <input [plcholder]="'MUTABAKATLISTE.TOPLAMFIYAT'|translate" nbInput style="color:#000;"
                            formControlName="total_price" fullWidth apasInvalid paraDirective [negative]="false" />
                    </div>
                </div>
                <!--//Tutar-->
                <!--Para Birimi-->
                <div class="col-md-3">
                    <div class="form-group">

                        <label class="form-label label"> {{'MUTABAKATLISTE.PARABIRIMI' | translate}} </label>
                        <ng-select [items]="currency" appendTo="body" [addTag]="false" formControlName="price_currency" [clearable]="false"
                            [placeholder]="'MUTABAKATLISTE.PARABIRIMI' | translate" bindLabel="label" class=""
                            bindValue="value" [searchable]="false" apasInvalid>
                            <!-- (change)="parabirimiChange()" -->
                        </ng-select>

                    </div>
                </div>
                <!--Para Birimi-->
                <!--Not-->
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="label form-label">{{'MUTABAKATLISTE.NOT'|translate}}</label>
                        <textarea [placeholder]="'MUTABAKATLISTE.NOT'|translate" nbInput fullWidth formControlName="note"
                        apasInvalid ></textarea>
                      
                    </div>
                </div>
                <!--//Not-->
            </div>
        </div>
        <div class="text-center">
            <button nbButton status="primary" (click)="postForm()" *ngIf="!data?.edit" [disabled]="isLoading" [nbSpinner]="isLoading">Kaydet</button>
            <button nbButton status="primary" (click)="patchForm()" *ngIf="data?.edit"  [disabled]="isLoading" [nbSpinner]="isLoading">Güncelle</button>
        </div>
    </nb-card-body>
</nb-card>