import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '../control-value-accessor-connector';

@Component({
  selector: 'date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeComponent),
      multi: true,
    },
  ],
})
export class DateRangeComponent extends ControlValueAccessorConnector implements OnInit {
  dateType = new FormControl('tumu');


  @Input() startDateControl!: FormControl;
  @Input() startDateControlName: string;

  @Input() endDateControl!: FormControl;
  @Input() endDateControlName: string;

  get startDateControlForm(): any {
    if (this.startDateControl) {
      return this.startDateControl;
    } else if (this.startDateControlName) {
      this.startDateControl = this.controlContainer?.control?.get(this.startDateControlName) as FormControl;
      return this.startDateControl;
    }
  }


  get endDateControlForm(): FormControl {
    if (this.endDateControl) {
      return this.endDateControl;
    } else if (this.endDateControlName) {
      this.endDateControl = this.controlContainer?.control?.get(this.endDateControlName) as FormControl;
      return this.endDateControl;
    }
  }

  constructor(injector: Injector) {
    super(injector);
  }

  

  ngOnInit(): void {
    this.dateType.valueChanges.subscribe(data => {
      const date = new Date();
      switch (data) {
        case 'tumu': {
          this.startDateControlForm.setValue(null);
          this.endDateControlForm.setValue(null);
          break;
        }

        case 'bugun': {
          this.startDateControlForm.setValue(new Date());
           this.endDateControlForm.setValue(new Date());
          break;
        }

        case 'buhafta': {
          this.startDateControlForm.setValue(this.addDays(7));
           this.endDateControlForm.setValue(new Date());
          break;
        }

        case 'buay': {
          this.startDateControlForm.setValue(new Date(date.getFullYear(), date.getMonth(), 1));
           this.endDateControlForm.setValue(new Date(date.getFullYear(), date.getMonth() + 1, 0));
          break;
        }


        default: {

          this.startDateControlForm.setValue(null);
           this.endDateControlForm.setValue(null);
          break;
        }
      }
    });
  }

  addDays(days: number): Date {
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() - days);
    return futureDate;
  }

}
