import { ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'password-input',
  template: `
  <div class="input-group mb-3">

    <span class="input-group-text" (click)="setInputType()" style="cursor:pointer;background: unset; border: unset; position: absolute; right: 8px; z-index: 999;top: 8px;padding: 0;" >
        <nb-icon [icon]="inputType=='password' ? 'eye-off-outline' : 'eye-outline'"></nb-icon>
    </span>
    <input class="form-control" nbInput fullWidth status="basic" [type]="inputType" placeholder="Şifre" [(ngModel)]="value" (ngModelChange)="setValue($event)">

</div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})

export class PasswordInputComponent implements ControlValueAccessor {

  disabled: boolean = false;
  inputType: string = 'password';
  @Input() status = 'basic';


  value: string;
  private onChange: Function;
  private onTouched: Function;

  constructor(private cd: ChangeDetectorRef) {

    this.onChange = (_: any) => { };
    this.onTouched = () => { };
    this.disabled = false;
  }

  public isActive(value: string): boolean {
    return value === this.value;
  }

  public setValue(value: string): void {
    this.value = value;
    this.onChange(this.value);
    this.onTouched();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  setInputType() {
    this.inputType = this.inputType === 'password' ? 'text' : 'password';
  }
}
