import {
  ChangeDetectorRef,
  Component, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { NG_VALUE_ACCESSOR, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ApasDecryptePipe } from '../../../../../directives/apas-decrypte-pipe';
import { CariModel } from '../../../models/cari.model';
import { removeAllNull } from '../../../../../pages/global-functions';
import { ControlValueAccessorConnector } from '../../../../../shared/control-value-accessor-connector';

import * as fromCari from '../../../muhasebe-store/cari/cari.connector';
import * as cariReducer from '../../../muhasebe-store/cari/cari.reducer';
import * as cariActions from '../../../muhasebe-store/cari/cari.actions';
import { MuhasebeService } from '../../../muhasebe-service/muhasebe.service';
import { CariTypes } from '../../../muhasebe-enum';

@Component({
  selector: 'cari-select',
  template: `
  <ng-select
  #selectBox
  [clearable]="clearable"
  class="uzun-select"
  [items]="cariList$ | async"
  [closeOnSelect]="closeOnSelect"
  [loading]="cariLoading"
  [typeahead]="cariInput$"
  [multiple]="multiple"
  appendTo="body"
  [addTag]="false"
  placeholder="Cari Seçiniz"
  bindLabel="unvan"
  bindValue="id"
  [searchable]="true"
  [virtualScroll]="true"
  (scrollToEnd)="onScrollToEnd()"
  (change)="cariChange($event)"
  [formControl]="control"
  >
  <ng-template ng-label-tmp let-item="item">

    <div class="d-flex aling-items-center ">
      <span class=" d-flex align-items-center ">
        <nb-user class="blackUser" name={{item?.unvan}} [title]="item?.turu" [showTitle]="false"
          [showName]="false" [color]="'#546E7A'" [picture]="item?.image" size="small">
        </nb-user>
      </span>
      <span class="d-flex align-items-center">
        <div style="font-weight:bold;" *ngIf="item?.unvan">
          <span>
            {{item?.unvan}}
          </span>
          <span *ngIf="item?.turu">
            ({{"cari_type."+(item?.turu) | translate}})
          </span>
        </div>
      </span>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <div class="d-flex aling-items-center m-2 justify-content-between">
      <div class="d-flex aling-items-center">
        <nb-user class="blackUser" name={{item?.unvan}} [title]="item?.turu" [showTitle]="false"
          [showName]="false" [color]="'#546E7A'" [picture]="item?.image">
        </nb-user>
        <strong style="color:#2d6287;">
            <span>{{item.unvan}}</span>
            <small class="d-block" style="color:#556e7a;">
              ({{"cari_type."+(item?.turu) | translate}})
            </small>
        </strong>
      </div>
      <div>
        <div style="margin:auto 0;" *ngFor="let bakiye of item?.current_value">
          <apas-para-item [value]="bakiye.bakiye" [decrypt]="true" [alacak]="true"
            [currency]="bakiye.currency" style="float: right; font-size: small; font-weight: bold; ">
          </apas-para-item>
        </div>
      </div>
    </div>
  </ng-template>
  
<ng-template ng-header-tmp  *ngIf="turFilter">
<div [formGroup]="cariTurForm" class="d-flex align-items-center">
<nb-toggle class="pr-2" status="primary" formControlName="musteri">
    Müşteri</nb-toggle>
<nb-toggle class="pr-2" status="success" formControlName="tedarikci">
    Tedarikçi</nb-toggle>
<nb-toggle class="pr-2" status="danger" formControlName="taseron">
    Taşeron</nb-toggle>
<nb-toggle class="pr-2" status="warning" formControlName="personel">
    Personel</nb-toggle>
    <button nbButton (click)="selectAll()" size="small" status="basic" class="btn btn-sm btn-secondary">
    {{selected ? 'Seçimleri Kaldır' : 'AYARLAR.TUMUNUSEC'|translate}}</button>
  
</div>
</ng-template>


</ng-select>
  `,
  providers: [MuhasebeService, ApasDecryptePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CariSelectComponent),
      multi: true,
    },
  ],
})
export class CariSelectComponent extends ControlValueAccessorConnector implements OnInit, OnChanges {

  @Input() formControl!: FormControl;

  @Input() formControlName: string;
  
  cariList$: Observable<any[]>;
  cariLoading = false;
  cariInput$ = new Subject<any>();
  cari_Detay: any;
  cari_id: number;
  cariLoaded: boolean = false;
  cariEnd: boolean = false;
  allData: CariModel[];
  filtre: any = { page: 0, search: null, count: 20 };
  @Input() selectedCari: number;
  @Input() cariTur?;
  @ViewChild('cariSelect') cariSelect: NgSelectComponent;
  // Seçim işlemi gerçekleştiği anda, üst komponente veri gönderimini sağlayan event.
  @Output() select: EventEmitter<any> = new EventEmitter();
  @Input() multiple: boolean;
  @Input() clearable: boolean = false;
  @Input() turFilter = false;
  cariTurForm: FormGroup;
  selected;

  closeOnSelect: boolean = true;

  constructor(
    private store: Store<{ Cari }>,
    private muhasebeService: MuhasebeService,
    private cd: ChangeDetectorRef,
    injector: Injector,
    private fb: FormBuilder


  ) {
    super(injector);
    this.store.pipe(select(fromCari.selectCariLoading)).subscribe((durum) => {
      this.cariLoading = durum;
    });
    this.cariList$ = this.muhasebeService.cariList$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectedCari?.currentValue) {
      this.muhasebeService.getCariDetay(changes?.selectedCari?.currentValue).subscribe(data => {
        this.store.dispatch(cariActions.addCariItem({ cariItem: data }));
        this.cd.detectChanges();
      });
    }
  }



  ngOnInit() {
    this.cariTurForm = this.fb.group({
      taseron: [true],
      tedarikci: [true],
      personel: [true],
      musteri: [true],
    });
    this.cariTurForm.valueChanges.subscribe((val) => {
      this.filtre = {
        page: 0,
        search: null,
        count: 200000,
        turu: val,
      };
      this.getList();

    });
    if (this.cariTur) {
      let taseron: boolean = false;
      let musteri: boolean = false;
      let tedarikci: boolean = false;
      let personel: boolean = false;

      this.cariTur = this.cariTur.split(',');

      this.cariTur.forEach(element => {
        if (element === 'taseron') {
          taseron = true;
        } if (element === 'musteri') {
          musteri = true;
        } if (element === 'tedarikci') {
          tedarikci = true;
        } if (element === 'personel') {
          personel = true;
        }

      });
      this.filtre = {
        page: 0,
        search: null,
        count: 20,
        turu: {
          taseron: taseron,
          musteri: musteri,
          tedarikci: tedarikci,
          personel: personel,
        },
      };
    }

    if (this.multiple === undefined || this.multiple == null) {
      this.multiple = false;
    }
    this.multiple ? this.closeOnSelect = false : this.closeOnSelect = true; // multiple true ise çoklu seçim açık

    this.cariInput$.next();
    this.cariInput$.subscribe(data => {
      this.filtre.search = data ? data.toLocaleLowerCase() : null;
      this.filtre.page = 0;
      this.store.dispatch(cariActions.setCariFilter(this.filtre));
    });

    this.getList();
  }

  getList() {
    const formVal: cariReducer.CariFilterInterface = removeAllNull(this.filtre);
    formVal['reset'] = true;
    this.store.dispatch(cariActions.setCariFilter(formVal));

  }

  resetList() {
    this.filtre = { page: 0, search: null, count: 20 };
    this.getList();
  }

  clear() {
    this.cariSelect.clearModel();
  }

  onScrollToEnd() {
    
    this.filtre.page += 1;
    const formVal: cariReducer.CariFilterInterface = removeAllNull(this.filtre);
    this.store.dispatch(cariActions.loadCari(formVal));
  }

  cariChange(ev) {
    this.select.emit(ev);
  }
  selectAll() {
    let subs;
    if (!this.selected) {
      subs = this.cariList$.subscribe(data => {
        const ids = data.map(x => x.id);
        this.control.setValue(ids);
      })
      this.selected = true;
    } else {
      this.selected = false;
      this.control.setValue(null);
    }
    if (subs) {
      subs.unsubscribe();
    }
  }
}

