import * as akisActions from './muhasebe-akis.actions';
import {
    createReducer,
    on,
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { AkisItem } from '../../../../models/akis.model';

interface MuhasebeAkisState extends EntityState<AkisItem> {
    selectedAkisId: number | null;
    end: boolean;
    active: boolean;
    loading: boolean;
    loaded: boolean;
    error: String;
    connection?: boolean;
}

const muhasebeAkisAdapter: EntityAdapter<AkisItem> = createEntityAdapter<AkisItem>({
    sortComparer: (a, b) => a.created_time - b.created_time,
});

const defaultMuhasebeAkisState: MuhasebeAkisState = {
    ids: [],
    entities: {},
    selectedAkisId: null,
    end: false,
    loading: false,
    active: false,
    loaded: false,
    connection: false,
    error: '',
};

const initialState = muhasebeAkisAdapter.getInitialState(defaultMuhasebeAkisState);



const muhasebeAkisReducer = createReducer(
    initialState,
    on(akisActions.loadMuhasebeAkis, (state, _props) => {
        return { ...state, loading: true };
    }),
    on(akisActions.loadMuhasebeAkisSuccess, (state, props) => {
        return muhasebeAkisAdapter.upsertMany(props.akisItem, {
            ...state,
            loading: false,
            loaded: true,
            end: props.isEnd,
        });
    }),
    on(akisActions.loadMuhasebeAkisFail, (state, props) => {
        return { ...state, loading: false, end: true, error: props };
    }),
    on(akisActions.addMuhasebeAkisItem, (state, props) => {
        return muhasebeAkisAdapter.upsertOne(props.akisItem, {
            ...state,
            selectedCustomerId: props.akisItem.id,
        });
    }),
    on(akisActions.setConnected, (state, props) => {
        return { ...state, ...props };
    }),
);

const MuhasebeAkisReducer = (action, state) => muhasebeAkisReducer(action, state);

export {
    MuhasebeAkisReducer,
    MuhasebeAkisState,
    defaultMuhasebeAkisState,
};
