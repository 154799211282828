import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  FooterComponent,
  HeaderComponent,
  TinyMCEComponent,
} from './components';

import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,

} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OnerComponent } from '../components/apas-components/arkadasina-oner/oner/oner.component';

import {
  ApasNotificationItemComponent,
} from '../components/Apas-Modules/apas-notification-module/apas-notification-item/apas-notification-item.component';
import {
  ApasNotificationComponent,
} from '../components/Apas-Modules/apas-notification-module/apas-notification/apas-notification.component';
import { UyarilarComponent } from '../components/apas-components/uyarilar/uyarilar.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { NbThemeModule } from '@nebular/theme';
import { DARK_THEME } from './styles/theme.dark';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  TinyMCEComponent,
  ApasNotificationItemComponent,
  ApasNotificationComponent,
  OnerComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  UyarilarComponent,
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
  ],
  exports: [
    CommonModule,
    ...COMPONENTS,
  ],
  declarations: [...COMPONENTS],
  entryComponents: [OnerComponent],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [ DEFAULT_THEME, DARK_THEME ],
        ).providers,
      ],
    };
  }
}
