import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { ApasInfo } from '../../../pages/constants';
import { isDevMode } from '@angular/core';
import { SetUrl } from '../../../store/aplikasyon/aplikasyon.actions';


@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: ` 
    <span class="created-by">APAS+ </span>
    <div class="socials d-sm-none d-md-block ">
    <nb-toggle class="mobildeGizle" *ngIf="devMode" (checkedChange)="changeBackendUrl($event)">{{backendUrl}}</nb-toggle>
    </div>
    <div>
    <nb-icon icon="phone-call-outline mobildeGizle"></nb-icon> <b> <a [href]="'tel:'+apasInfo?.phone">
      Çağrı Merkezi {{apasInfo?.phone}} </a></b>
    
  `,
})
export class FooterComponent {
  apasInfo = ApasInfo;
  devMode: boolean = false;

  backendUrl: string = environment.backendurl;

  constructor(private store: Store<{}>) {
    this.devMode = isDevMode();
  }

  changeBackendUrl(event) {
    if (event) {
      this.backendUrl = environment.testendurl;
    } else {
      this.backendUrl = environment.backendurl;
    }
    this.store.dispatch(SetUrl({ URL: this.backendUrl }));
  }
}
