<div class="header-container">
    <!-- <div style="max-width: 0px; max-height: 0px; display: none;"><workflow></workflow></div> -->
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>

        <a class="plusreg" (click)="goHome()" style="height:25px;">
            <!--Apas Svg-->
            <svg class="apas" style="margin:0 !important" version="1.0" height="25" width="165"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 392.000000 64.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" stroke="none">
                    <path d="M213 604 c-36 -18 -73 -70 -73 -103 0 -32 65 -30 96 4 l23 25 316 0
     c302 0 316 -1 335 -20 14 -14 20 -33 20 -65 l0 -45 -347 0 c-311 0 -352 -2
     -383 -18 -96 -48 -108 -174 -25 -252 l27 -25 404 -3 404 -3 0 212 c0 325 23
     309 -437 309 -280 0 -333 -3 -360 -16z m717 -354 l0 -60 -348 0 c-257 0 -351
     3 -360 12 -17 17 -15 74 4 92 13 14 60 16 360 16 l344 0 0 -60z" />
                    <path d="M1170 602 c-19 -9 -45 -32 -57 -51 -22 -33 -23 -42 -23 -242 l0 -209
     45 0 45 0 0 55 0 55 318 0 c288 0 320 2 351 18 56 30 71 71 71 188 0 88 -3
     105 -23 135 -44 67 -57 69 -392 69 -266 0 -304 -2 -335 -18z m641 -82 c17 -9
     19 -22 19 -103 0 -59 -4 -97 -12 -105 -9 -9 -95 -12 -325 -12 l-313 0 0 105
     c0 88 3 106 18 114 23 14 587 15 613 1z" />
                    <path d="M2075 604 c-35 -17 -63 -53 -75 -94 l-9 -30 50 0 c36 0 49 4 49 15 0
     31 38 35 354 35 314 0 315 0 330 -22 9 -12 16 -42 16 -65 l0 -43 -345 0 c-271
     0 -353 -3 -379 -14 -93 -39 -114 -165 -41 -244 l33 -37 406 -3 406 -3 0 213
     c0 325 24 308 -437 308 -273 0 -333 -3 -358 -16z m715 -354 l0 -60 -350 0
     c-335 0 -350 1 -360 19 -15 29 -12 62 7 83 15 17 40 18 360 18 l343 0 0 -60z" />
                    <path d="M3011 598 c-115 -57 -97 -243 27 -278 21 -6 158 -10 319 -10 309 0
     313 -1 313 -59 0 -61 -2 -61 -378 -61 l-342 0 0 -45 0 -45 353 0 c398 0 396 0
     437 79 36 71 18 149 -44 196 -26 19 -46 20 -338 25 -292 5 -310 6 -324 24 -20
     28 -17 63 6 86 19 19 33 20 360 20 l340 0 0 45 0 45 -342 0 c-332 0 -344 -1
     -387 -22z" />
                </g>
            </svg>
            <!--//Apas Svg-->
    

            <!-- X SVG -->
            <svg class="plus" version="1.0" height="30" width="30" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 122.000000 137.000000" preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,137.000000) scale(0.100000,-0.100000)" stroke="none">
                    <path d="M51 1314 c-13 -9 -21 -23 -18 -33 2 -9 82 -135 177 -281 96 -146 174
           -272 174 -280 0 -8 -82 -139 -182 -291 -100 -152 -182 -281 -182 -287 0 -6 10
           -18 22 -26 17 -12 50 -16 145 -16 67 0 132 4 145 9 15 6 61 72 133 188 l110
           179 103 -170 c57 -94 113 -179 124 -188 17 -15 41 -18 153 -18 137 0 178 7
           188 33 4 10 -67 128 -181 304 -102 158 -186 295 -186 303 0 8 79 133 177 277
           97 144 177 266 177 272 0 5 -10 17 -22 25 -31 22 -264 23 -295 1 -11 -8 -62
           -81 -113 -162 -51 -82 -98 -153 -104 -160 -8 -9 -36 29 -113 156 -73 119 -110
           170 -125 175 -13 3 -82 6 -153 6 -104 0 -135 -3 -154 -16z" />
                </g>
            </svg>
            <!-- // X SVG -->
        </a>
    </div>
</div>

<div class="header-container">


    <!--Uyarılar-->
    <uyarilar></uyarilar>
    <!--Uyarılar-->


    <nb-actions size="small">

        <!-- Bildirim Button  -->
        <nb-action #bildirimPopOver [nbPopover]="bildirimRef" (click)="setAllNotificationAsShowed()"
            [badgeText]="unShowedBildrimCount <= 10 ? (unShowedBildrimCount + '') : '10+'" badgeStatus="danger"
            nbPopoverPlacement="bottom" class="control-item" color="white" icon="message-square-outline"
            pack="custom-icons">
        </nb-action>
        <!-- Bildirim Button  -->

        <!--User Profile-->
        <nb-action #userPopOver class="user-action" *nbIsGranted="['view', 'user']" [nbPopover]="userRef">
            <nb-user [title]="org_info?.name" [onlyPicture]="userPictureOnly"
                [name]="user?.name?.length > 30 ? user?.name?.slice(0,10) + '...'  + user?.name?.slice(user?.name?.length - 10)  : user?.name"
                [picture]="user?.image">
            </nb-user>
        </nb-action>

        <!-- User Template  -->
        <ng-template #userRef>
            <nb-card class="m-header" style="border-top: none;  padding-top: 8px;" class="sizeOfScreen m-0">
                <nb-card-body class="p-0" style="max-width: 350px;   overflow: hidden;">
                    <!--Profil mini-->
                    <div class="text-center" (click)="menuUser('Profil')">
                        <nb-user size="giant" class="mini-profile linked-div" [title]="'HEADER.MESLEGI' | translate"
                            [onlyPicture]="userPictureOnly" [name]="user?.name" [picture]="user?.image" [color]="'#607d8b'">
                        </nb-user>

                        <b>{{user?.name?.length > 30 ? user?.name?.slice(0,10) + '...' +
                            user?.name?.slice(user?.name?.length - 10) : user?.name}}</b><br>
                        <small>{{user?.job_id?.name}}</small>
                        <br />
                        <span>
                            <!-- <svg class="plus" version="1.0" height="12" width="12" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 76.000000 60.000000" preserveAspectRatio="xMidYMid meet"
                                style="margin-left: -5px;">

                                <g transform="translate(0.000000,60.000000) scale(0.100000,-0.100000)" stroke="none">
                                    <path
                                        d="M310 460 l0 -90 -90 0 -90 0 0 -75 0 -75 90 0 90 0 0 -85 0 -85 75 0 75 0 0 85 0 85 90 0 90 0 0 75 0 75 -90 0 -90 0 0 90 0 90 -75 0 -75 0 0 -90z" />
                                </g>
                            </svg> -->
                            <!--//Plus Svg-->
                            <label class="label" style="display: initial; color: #ff7043;">APASID </label> <b
                                class="ml-2" style="font-size: small;">{{user?.apas_id}}</b>
                        </span>
                    </div>

                    <!--// Profil mini-->

                    <nb-list style="overflow: hidden;">
                        <!--Profil Link-->
                        <nb-list-item class="animated flipInX linked-div" (click)="menuUser('Profil')">
                            <nb-icon icon="person-outline"></nb-icon> <b class="ml-1">{{'HEADER.PROFIL' | translate}}
                            </b>

                        </nb-list-item>
                        <!--Profil Link-->

                        <!--Hesabım-->
                        <nb-list-item class="animated flipInX linked-div" (click)="menuUser('Hesabim')"
                            style="border-top-width: 0 !important; border-top-width: 0 !important;border-left: 5px solid #ff7043;">
                            <nb-icon style="margin-left: -5px;" icon="shopping-cart-outline"></nb-icon>

                            <!-- <i class="fa fa-shopping-cart" aria-hidden="true"></i> -->

                            <b class="ml-1"> {{'HEADER.HESABIM' | translate}} </b>
                        </nb-list-item>
                        <!--Hesabım-->


                        <!--Öner-->
                        <nb-list-item class="animated flipInX linked-div"
                            style="border-top-width: 0 !important; border-top-width: 0 !important;"
                            (click)="arkadasinaOner()">
                            <b>
                                <!--Plus Svg-->
                                <svg style="fill: #ff7043;width: 20px;height: 20px;" version="1.0"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.000000 60.000000"
                                    preserveAspectRatio="xMidYMid meet">

                                    <g transform="translate(0.000000,60.000000) scale(0.100000,-0.100000)"
                                        stroke="none">
                                        <path d="M310 460 l0 -90 -90 0 -90 0 0 -75 0 -75 90 0 90 0 0 -85 0 -85 75 0
                                75 0 0 85 0 85 90 0 90 0 0 75 0 75 -90 0 -90 0 0 90 0 90 -75 0 -75 0 0 -90z" />
                                    </g>
                                </svg>
                                <!--//Plus Svg--> {{'HEADER.ARKADASINAONER' | translate}} <br>

                            </b>
                        </nb-list-item>
                        <!--Öner-->
                        <nb-list-item class="animated flipInX linked-div" style="display: initial;"
                            (click)="menuUser('Firma Değiştir')">

                            <div>
                                <nb-icon icon="swap-outline"></nb-icon> <b class="ml-1">{{'HEADER.FIRMADEGISTIR' |translate}}</b>
                            </div>
                            <!-- // Firma Değiştir-->

                        </nb-list-item>
                        <!--Çağrı merkezi-->
                        <nb-list-item class="animated flipInX linked-div">
                            <nb-icon icon="phone-call-outline"></nb-icon>
                            <b class="ml-1">
                                <a [href]="'tel:'+apasInfo?.phone">
                                    {{'HEADER.CAGRIMERKEZI' |translate}} {{apasInfo?.phone}}
                                </a>
                            </b>
                        </nb-list-item>
                        <!--Çağrı merkezi-->
                    </nb-list>
                </nb-card-body>
                <nb-card-footer>
                    <div class="showAlls linked-div text-center" (click)="menuUser('Çıkış Yap')">
                        <nb-icon icon="power-outline"></nb-icon> {{'HEADER.CIKISYAP' |translate}}
                    </div>

                </nb-card-footer>
            </nb-card>
        </ng-template>
        <!-- User Template  -->


    </nb-actions>
</div>


<!-- Bildirim Template  -->
<ng-template #bildirimRef>
    <apas-notification></apas-notification>
</ng-template>
<!-- // Bildirim Template  -->