import { Pipe, PipeTransform } from '@angular/core';
import { decrypte } from '../pages/global-functions';
@Pipe({
    name: 'apasdecrypte',
  })

  export class ApasDecryptePipe implements PipeTransform {
    transform(raw_text: string) {
      if (!raw_text) return null;
      const decrypted = decrypte(raw_text);
      return decrypted;
    }
}
