import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApasAlertOptions } from '../models/apas-alert.model';
import { NbDialogRef } from '@nebular/theme';
import { ApasAlertServiceService } from '../services/apas-alert.service';

@Component({
  selector: 'apas-alert',
  templateUrl: './apas-alert.component.html',
  styleUrls: ['./apas-alert.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ApasAlertComponent implements OnInit {
  options: ApasAlertOptions;
  validationMessage: string = undefined;
  isClose: boolean = false;
  ispreConfirmLoading: boolean = false;
  ispreFileLoading: boolean = false;
  ispreDenyLoading: boolean = false;

  constructor(private nbDialogRef: NbDialogRef<ApasAlertComponent>,
    private apasAlertServiceService: ApasAlertServiceService,
  ) {
    this.isClose = false;
    apasAlertServiceService.close$.subscribe(val => {
      if (val) {
        this.close(val);
      }
    });
    apasAlertServiceService.update$.subscribe(val => {
      this.update(val);
    });
    apasAlertServiceService.showValidationMessage$.subscribe(val => {
      this.showValidationMessage(val);
    });
  }

  ngOnInit() {
  }

  async file() {
    if (this.options.preFile) {
      this.ispreFileLoading = true;
      const val = await this.options.preFile({});
      this.ispreFileLoading = false;
      if (!this.validationMessage) {
        this.close(val);
      }
    } else {

      this.close('file');
    }
  }

  async confirm() {
    // 

    if (this.options.preConfirm) {
      this.ispreConfirmLoading = true;
      const val = await this.options.preConfirm({});
      this.ispreConfirmLoading = false;
      if (!this.validationMessage) {
        this.close(val);
      }
    } else {

      this.close(true);
    }

  }
  async deny() {
    if (this.options.preDeny) {
      this.ispreDenyLoading = true;
      const val = await this.options.preDeny({});
      this.ispreDenyLoading = false;
      if (!this.validationMessage) {
        this.close(val);
      }
    } else {

      this.close(false);
    }
  }
  cancel() {
    this.isClose = true;
    this.close(false);
  }
  close(value: boolean | any) {
    setTimeout(() => {
      this.nbDialogRef.close({ value: value });
    }, 300);
  }

  update(options: ApasAlertOptions) {
    this.options = { ...this.options, ...options };
  }

  showValidationMessage(message: string) {
    this.validationMessage = message;
  }
  clearValidationMessage() {
    this.validationMessage = undefined;
  }

}
