<span class="d-flex w-100 align-items-center">


    <span class="tarihColSiraNo pr-2" *ngIf="index !== null">
        {{index + 1}} )
    </span>

    <span #elm *ngIf="tarih">
        <strong class="text-center">
            <!--d-block -->
            {{tarih | date : 'dd'}} {{tarih | date : 'MMM'}}
        </strong>
        <strong class="text-center">
            <!--d-block -->
            {{tarih | date : 'yyyy'}}
        </strong>
    </span>
    <span *ngIf="!tarih">
        --/--/----
    </span>
</span>