<div #mainArea class="h-100 ngBottom"
    style="border: 1px solid #e4e9f2;border-radius: 5px;background-color:#f7f9fc;position: relative;"
    (resized)="onResized($event)">
    <div>
        <div class="linked-div" (click)="etiketleriGoster(true)" style="float: right; padding: 7px;padding-top:9px"
            [nbTooltip]="'Yeni Etiket Ekle!'" *ngIf="!kucukMu">
            <nb-icon icon="plus-outline"
                style="color:white;background-color:#ff6600;border-radius: 100%; margin-top:1px;margin-left:30px">
            </nb-icon>
        </div>
        <div class="linked-div" (click)="etiketleriGoster(true)" style="float: right; padding: 7px;padding-top:9px"
            [nbTooltip]="'Yeni Etiket Ekle!'" *ngIf="kucukMu">
            <nb-icon icon="plus-outline"
                style="color:white;background-color:#ff6600;border-radius: 100%; margin-bottom:5px;margin-left:30px">
            </nb-icon>
        </div>
    </div>
    <div style="height:100%; flex: 1; border-right: 1px solid #f6f5f5; min-height: 40px;">
        <div class="row mx-1">
            <div>
                <div style="float: left;" *ngIf="control?.value">
                    <div class="m-1" *ngFor="let item of control?.value" [ngStyle]="{
                        'float': 'left',
                        'background-color': item.color,
                        'border-radius': '3px'
                         }">
                        <span class="m-1"
                            [ngStyle]="{'color': '#FFFFFF', 'text-shadow': '2px 2px 10px' + item.shadow_color + ', 0.03em 0.03em 0' + item.shadow_color, 'font-size': '14px', 'font-weight': 400}">
                            {{item.title}}</span>
                        <nb-icon class="linked-div" icon='close-circle-outline'
                            style="font-size:medium ; margin:auto; color: white;" (click)="removeTag(item)"> </nb-icon>
                    </div>
                </div>
                <div style="float: left;">
                    <ng-select class="noBgSelect" style="min-width: 150px;" #selectArea [items]="tagList$ | async" 
                        appendTo="body" [typeahead]="tagInput$" 
                        bindLabel="title" [addTag]="addTagPromise" [addTagText]="'Ekle'" [placeholder]="'Etiket Ekle'"
                        (change)="addTag($event)">

                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div
                                [style]="'padding:5px;border-radius: 5px;color:#ffff;background-color:'+item.shadow_color">
                                <b>{{item.title}}</b>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
    </div>

    <tags #tagarea [tagTypes]="tagFilters" [popOver]="true" [style]="showtags ? 'display: block;' : 'display: none;'"
        (kapan)="etiketleriGoster(false)" (transfer)="addTag($event)" (removeItem)="removeTagFrom($event)"></tags>
    <input [formControl]="control" style="display: none;">
</div>