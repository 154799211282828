<nb-card class="modalType animated fadeInRight" size="giant" id="kasaEkle" [formGroup]="form" style="width: 600px;">
    <nb-card-header [class]="'header w-100 header-'+type">
        <div class="d-flex">
            <div *ngIf="editMode" class="kasaTur">
                <span class="baslik">{{kasaDetail.title}} {{'HAKEDISDOSYALIST.DUZENLE'|translate}}
                </span>
            </div>
            <div *ngIf="!editMode" class="kasaTur">
                <span *ngIf="type==1" class="baslik">{{"MUHASEBE.KASAEKLE"|translate}}
                </span>
                <span *ngIf="type==2" class="baslik">{{"MUHASEBE.BANKAEKLE"|translate}}</span>
                <span *ngIf="type==3" class="baslik">{{"MUHASEBE.KREDIEKLE"|translate}}
                </span>
                <span *ngIf="type==4" class="baslik">
                    {{"MUHASEBE.POSEKLE"|translate}}</span>
                <span *ngIf="type==5" class="baslik">
                    {{"MUHASEBE.KREDIKARTEKLE"|translate}}</span>
            </div>
            <nb-icon class="linked-div closeIcon" icon='close-outline' (click)="close()">
            </nb-icon>
        </div>
    </nb-card-header>

    <nb-card-body>


        <!-- Kasa Ekle Kartı -->
        <div *ngIf="type==1" [nbSpinner]="isLoading">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-9 mb-3 pr-1">
                            <label class="label"> {{"MUHASEBE.KASAADI"|translate}}</label>
                            <input type="text" fullWidth nbInput [placeholder]="'MUHASEBE.KASAADI' | translate"
                                formControlName="name" apasInValid>

                        </div>
                        <div class="col-md-3 pl-0 ">
                            <label class="label"> {{"MUTABAKATLISTE.PARABIRIMI"|translate}}</label>
                            <ng-select [clearable]="false" fullWidth [placeholder]="'MUHASEBE.PARABIRIMI'|translate"
                                [items]="muhasebeCurrency" appendTo="body" [selectOnTab]="true" bindValue="label"
                                bindLabel="label" formControlName="currency">
                            </ng-select>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <label *ngIf="!editMode" class="label"> {{'MUHASEBE.ACILISBAKIYESI' | translate}}</label>
                            <input fullWidth nbInput *ngIf="!editMode" formControlName="open_amount" paraDirective
                                [encrypt]="true" [negative]="true">
                        </div>


                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <label class="label">{{"MUHASEBE.YETKILIKULLANICISEC" | translate}}</label>
                            <ng-select bindLabel="name" bindValue="apas_id" fullWidth [loading]="authListLoading"
                                [items]="authList" [multiple]="true"
                                [placeholder]="'MUHASEBE.YETKILIKULLANICISEC' | translate" appendTo="body"
                                formControlName="authorized_to">
                            </ng-select>
                        </div>
                    </div>
                </div>

            </div>



        </div>
        <!-- Banka Ekle Kartı -->
        <div *ngIf="type == 2">
            <div class="row">
                <div class="col-md-12">
                    <div class="row pr-3 mb-3">
                        <div class="col-md-9 pr-1">
                            <label class="label"> {{"MUHASEBE.BANKAADI"|translate}}</label>
                            <input type="text" fullWidth nbInput [placeholder]="'MUHASEBE.BANKAADI'|translate"
                                formControlName="bank_name" apasInValid>
                        </div>

                        <div class="col-md-3 px-0 ">
                            <label class="label"> {{"MUTABAKATLISTE.PARABIRIMI"|translate}}</label>
                            <ng-select fullWidth [clearable]="false" [placeholder]="'MUHASEBE.PARABIRIMI'|translate"
                                [items]="muhasebeCurrency" appendTo="body" [selectOnTab]="true" bindValue="label"
                                bindLabel="label" formControlName="currency">
                            </ng-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <label class="label"> {{"MUHASEBE.SUBEADI"|translate}}</label>
                            <input type="text" fullWidth nbInput [placeholder]="'MUHASEBE.SUBEADI'|translate"
                                formControlName="name" apasInvalid>

                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <label class="label"> {{'MUHASEBE.HESAPNO'|translate}}</label>
                            <input type="text" fullWidth nbInput [placeholder]="'MUHASEBE.HESAPNO'|translate"
                                formControlName="account_number">
                        </div>
                        <div class="col-md-12 mb-3">

                            <label class="label"> {{'MUHASEBE.IBAN'|translate}}</label>
                            <div class="col-md-12 pr-0 pl-0">
                                <input nbInput type="text" fullWidth placeholder="TR" formControlName="iban"
                                    mask="SS00 0000 0000 0000 0000 0000 00" [ngClass]="{'border border-danger': form.get('iban').invalid,
                                'border border-success': form.get('iban').valid
                                 }">
                                <small *ngIf="form.get('iban').touched && form.get('iban').invalid"
                                    class="form-text text-danger">

                                    Lütfen geçerli bir IBAN numarası giriniz!

                                </small>

                            </div>


                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <label *ngIf="!editMode" class="label"> {{'MUHASEBE.ACILISBAKIYESI' | translate}}</label>
                            <input fullWidth nbInput *ngIf="!editMode" formControlName="open_amount" paraDirective
                                [encrypt]="true" [negative]="true">
                        </div>



                    </div>




                    <label class="label">{{"MUHASEBE.YETKILIKULLANICISEC"|translate}} </label>
                    <ng-select bindLabel="name" bindValue="apas_id" fullWidth [loading]="authListLoading"
                        [items]="authList" [multiple]="true" [placeholder]="'MUHASEBE.YETKILIKULLANICISEC' | translate"
                        appendTo="body" formControlName="authorized_to">
                    </ng-select>

                </div>


            </div>


        </div>
        <!-- Kredi Ekle Kartı -->
        <!-- Kredi Ekle Kartı -->
        <div *ngIf="type == 3">
            <div class="row">
                <div class="col-md-12">
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <label class="label"> {{"MUHASEBE.BANKAKURUMADI"|translate}} </label>
                            <input type="text" fullWidth nbInput [placeholder]="'MUHASEBE.BANKAKURUMADI'|translate"
                                formControlName="bank_name">
                            <div *ngIf="form.get('bank_name').touched && form.get('bank_name').hasError('required')">
                                <p class="uyari">{{'KASALAR.LUTFENBANKA'|translate}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 mb-3" *ngIf="!editMode">
                            <label class="label"> {{"MUHASEBE.KREDIADI"|translate}} </label>
                            <input type="text" fullWidth nbInput [placeholder]="'MUHASEBE.KREDIADI'|translate"
                                formControlName="name">
                            <div *ngIf="form.get('name').touched && form.get('name').hasError('required')">
                                <p class="uyari">{{'KASALAR.LUTFENKREDIADIGIRIN'|translate}}</p>
                            </div>
                        </div>
                        <div class="col-md-12 mb-3" *ngIf="editMode">
                            <label class="label"> {{"MUHASEBE.KREDIADI"|translate}} </label>
                            <input type="text" fullWidth nbInput [placeholder]="'MUHASEBE.KREDIADI'|translate"
                                formControlName="name">
                            <div *ngIf="form.get('name').touched && form.get('name').hasError('required')">
                                <p class="uyari">{{'KASALAR.LUTFENKREDIADIGIRIN'|translate}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!editMode">
                        <div class="col-md-9 pr-1">
                            <label class="label"> {{'MUHASEBE.KALANBORC'|translate}} </label>
                            <input fullWidth nbInput *ngIf="!editMode" formControlName="remaining_dept" paraDirective
                                [encrypt]="true" [negative]="true">
                            <div
                                *ngIf="form.get('remaining_dept').touched && form.get('remaining_dept').hasError('required')">
                                <p class="uyari">{{'KASALAR.LUTFENKALANBORC'|translate}}</p>
                            </div>
                        </div>
                        <div class="col-md-3 pl-0" *ngIf="!editMode">
                            <label class="label"> {{"MUTABAKATLISTE.PARABIRIMI"|translate}}</label>
                            <ng-select fullWidth [clearable]="false" [placeholder]="'MUHASEBE.PARABIRIMI'|translate"
                                [items]="muhasebeCurrency" appendTo="body" [selectOnTab]="true" bindValue="label"
                                bindLabel="label" formControlName="currency">
                            </ng-select>
                        </div>
                        <div class="col-md-6">
                            <label class="label"> {{'MUHASEBE.KALANTAKSIT'|translate}} </label>
                            <input type="number" fullWidth nbInput [placeholder]="'MUHASEBE.KALANTAKSIT'|translate"
                                formControlName="remaining_installments">
                            <div
                                *ngIf="form.get('remaining_installments').touched && form.get('remaining_installments').hasError('required')">
                                <p class="uyari">{{'KASALAR.LUTFENKALANTAKSITSAYI'|translate}}</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="label"> {{'MUHASEBE.ILKTAKSITTARIHI'|translate}}</label>
                            <input nbInput fullWidth [nbDatepicker]="datepicker"
                                [placeholder]="'MUHASEBE.ILKTAKSITTARIHI'|translate" dateOnly
                                formControlName="first_installment_date">
                            <nb-datepicker #datepicker></nb-datepicker>
                            <div
                                *ngIf="form.get('first_installment_date').touched && form.get('first_installment_date').hasError('required')">
                                <p class="uyari">{{'KASALAR.LUTFENKALANTAKSITTARIH'|translate}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="!editMode">
                        <div class="col-md-12 mt-3">
                            <label class="label"> {{'MUHASEBE.TAKSITPERIOD'|translate}}</label>
                            <ng-select fullWidth appendTo="body" [placeholder]="'MUHASEBE.TAKSITPERIOD'|translate"
                                formControlName="installment_period" bindLabel="label" bindValue="value"
                                [items]="taksit_periot" apasInvalid>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-12 pl-0 pr-0">
                        <label class="label">{{"MUHASEBE.YETKILIKULLANICISEC"|translate}} </label>
                        <ng-select bindLabel="name" bindValue="apas_id" fullWidth [loading]="authListLoading"
                            [items]="authList" [multiple]="true"
                            [placeholder]="'MUHASEBE.YETKILIKULLANICISEC' | translate" appendTo="body"
                            formControlName="authorized_to">
                        </ng-select>
                    </div>
                </div>

            </div>

        </div>
        <!-- POS Ekle Kartı -->
        <div *ngIf="type==4">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-9 mb-3 pr-1">
                            <label class="label"> {{"MUHASEBE.POSADI"|translate}} </label>
                            <input type="text" fullWidth nbInput [placeholder]="'MUHASEBE.POSADI'|translate"
                                formControlName="name">
                            <div *ngIf="form.get('name').touched && form.get('name').hasError('required')">
                                <p class="uyari">{{'KASALAR.LUTFENPOSADI'|translate}}</p>
                            </div>
                        </div>
                        <div class="col-md-3 pl-0 ">
                            <label class="label"> {{"MUTABAKATLISTE.PARABIRIMI"|translate}}</label>
                            <ng-select [clearable]="false" fullWidth [placeholder]="'MUHASEBE.PARABIRIMI'|translate"
                                [items]="muhasebeCurrency" appendTo="body" [selectOnTab]="true" bindValue="label"
                                bindLabel="label" formControlName="currency">
                            </ng-select>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12 mr-3 mb-3">
                            <label *ngIf="!editMode" class="label"> {{'MUHASEBE.ACILISBAKIYESI' | translate}}</label>
                            <input fullWidth nbInput *ngIf="!editMode" formControlName="open_amount" paraDirective
                                [encrypt]="true" [negative]="true">
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="label">{{"MUHASEBE.YETKILIKULLANICISEC"|translate}}</label>
                            <ng-select bindLabel="name" bindValue="apas_id" fullWidth [loading]="authListLoading"
                                [items]="authList" [multiple]="true"
                                [placeholder]="'MUHASEBE.YETKILIKULLANICISEC' | translate" appendTo="body"
                                formControlName="authorized_to">
                            </ng-select>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!-- Kredi Kartı Ekle Kartı -->
        <div *ngIf="type==5">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-9 mb-3 pr-1">
                            <label class="label"> {{"MUHASEBE.KARTADI"|translate}} </label>
                            <input type="text" fullWidth nbInput [placeholder]=" 'MUHASEBE.KARTADI'|translate "
                                formControlName="name" apasInvalid>

                        </div>
                        <div class="col-md-3 pl-0 ">
                            <label class="label"> {{"MUTABAKATLISTE.PARABIRIMI"|translate}}</label>
                            <ng-select [clearable]="false" fullWidth [placeholder]="'MUHASEBE.PARABIRIMI'|translate"
                                [items]="muhasebeCurrency" appendTo="body" [selectOnTab]="true" [clearable]="false"
                                bindValue="label" bindLabel="label" formControlName="currency">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="label"> {{"MUHASEBE.KESIMTARIHI"|translate}} </label>
                            <ng-select fullWidth appendTo="body" [placeholder]="'MUHASEBE.KESIMTARIHI'|translate"
                                formControlName="closing_day" bindLabel="label" bindValue="value" [items]="hesap_kesim">
                            </ng-select>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <label *ngIf="!editMode" class="label"> {{'MUHASEBE.ACILISBAKIYESI' | translate}}</label>
                            <input fullWidth nbInput *ngIf="!editMode" formControlName="open_amount" paraDirective
                                [encrypt]="true" [negative]="true">
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="label">{{"MUHASEBE.YETKILIKULLANICISEC"|translate}}</label>
                            <ng-select bindLabel="name" bindValue="apas_id" fullWidth [loading]="authListLoading"
                                [items]="authList" [multiple]="true"
                                [placeholder]="'MUHASEBE.YETKILIKULLANICISEC' | translate" appendTo="body"
                                formControlName="authorized_to">
                            </ng-select>
                        </div>
                    </div>
                </div>

            </div>



        </div>
        <div class="d-flex justify-content-center mt-5">
            <div *ngIf="editMode">
                <button *ngIf="editMode && kasaDetail?.is_active" nbButton status="warning"
                    (click)="closeAccount(false)" size="medium">
                    <nb-icon icon="checkmark-circle-2-outline" class="kasa_Islem_Icon"></nb-icon>
                    Hesabı Kapat
                </button>
                <button *ngIf="editMode && !kasaDetail?.is_active" nbButton status="info" (click)="closeAccount(true)"
                    size="medium">
                    <nb-icon icon="checkmark-circle-2-outline" class="kasa_Islem_Icon"></nb-icon>

                    Hesabı Yeniden Aç
                </button>

                <button *ngIf="editMode" (click)="deleteAccount()" nbButton outline size="medium" status="danger">
                    <nb-icon icon="trash-outline" class="kasa_Islem_Icon">
                    </nb-icon> Sil
                </button>
                <button class="save_guncelle_button" *ngIf="!editMode" nbButton status="info" (click)="postForm()"
                    size="medium" [nbSpinner]="isLoading" [disabled]="isLoading">
                    {{"KAYDET"|translate}}</button>

                <button class="save_guncelle_button" *ngIf="editMode" nbButton status="info" (click)="patchForm()"
                    [nbSpinner]="isLoading" [disabled]="isLoading" size="medium"> {{ "GUNCELLE" | translate }} </button>
            </div>

            <div *ngIf="!editMode">

                <button class="save_guncelle_button" *ngIf="!editMode" nbButton status="info" (click)="postForm()"
                    [nbSpinner]="isLoading" [disabled]="isLoading" size="medium"> {{"KAYDET"|translate}}</button>

                <button class="save_guncelle_button" *ngIf="editMode" nbButton status="info" (click)="patchForm()"
                    [nbSpinner]="isLoading" [disabled]="isLoading" size="medium"> {{ "GUNCELLE" | translate }} </button>

            </div>

        </div>
    </nb-card-body>
</nb-card>