import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root',
})

export class ApasFileService {
  private readonly API_URL = `${environment.backendurl}` + 'api/';

  constructor(private httpClient: HttpClient) { }

  getFilesList(address: string, filter) {
    const url = `${this.API_URL}${address}/file/list/`;
    return this.httpClient.post<any[]>(url, filter);
  }

  getFileDetail(address: string, identifier: string) {
    const url: string = `${this.API_URL}${address}/file/detail/${identifier}/`;
    const req = new HttpRequest('GET', url, {
      reportProgress: true,
    });
    return this.httpClient.request(req);
  }

  uploadFile(address: string, data) {
    const url: string = `${this.API_URL}${address}/file/upload/`;
    const req = new HttpRequest('POST', url, data, {
      reportProgress: true,
    });
    return this.httpClient.request(req);
  }

  updateFile(identifier, address: string, data) {
    const url: string = `${this.API_URL}${address}/file/update/${identifier}/`;
    const req = new HttpRequest('PATCH', url, data, {
      reportProgress: true,
    });
    return this.httpClient.request(req);
  }


  deleteFile(address: string, identifier: string) {
    const url = `${this.API_URL}${address}/file/delete/${identifier}/`;
    return this.httpClient.delete<any>(url);

  }

  downloadFile(address: string) {
    const req = new HttpRequest('GET', address, {
      reportProgress: true,
      responseType: 'arraybuffer',
    });
    return this.httpClient.request(req);
  }


}
