import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../constants';
import { removeAllNull } from '../../../global-functions';
import { EntegrasyonService } from '../../../../services/entegrasyon.service';

@Component({
  selector: 'ngx-e-fatura-giris',
  templateUrl: './e-fatura-giris.component.html',
  styleUrls: ['./e-fatura-giris.component.scss'],
  providers: [EntegrasyonService],
})
export class EFaturaGirisComponent implements OnInit {
  form: FormGroup;
  api_key;
  constructor(private ref: NbDialogRef<EFaturaGirisComponent>,
    private fb: FormBuilder,
    private entegrasyonService: EntegrasyonService) { }

  ngOnInit(): void {
    this.initForm();
    this.form.get('api_key').setValue(null);
    this.form.get('api_secret').setValue(null);
    if (this.api_key) {
      
      this.form.get('api_key').setValue(this.api_key);
    }
  }

  initForm() {
    this.form = this.fb.group({
      api_key: [null, Validators.required],
      api_secret: [null, Validators.required],
    });
  }
  close(success?) {
    this.ref.close(success);
  }
  saveLoad = false;
  girisYap() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.saveLoad = true;
    const formData = removeAllNull(this.form.value);
    this.entegrasyonService.addLoginInfo(formData).subscribe(resp => {
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.saveLoad = false;
      this.close(true);
    }, (err: HttpErrorResponse) => {
      Swal.fire({ ...responseKodlari.unSuccessProgress, ...{ text: 'Kimlik Hatası!' } } as SweetAlertOptions);
      this.saveLoad = false;
    });
  }

}
