<div style="max-height: 15px !important; overflow: hidden; cursor: default;" [nbPopover]="tooltemp" nbPopoverTrigger="hover" >
    <div class="pr-2" *ngFor="let tag of tagItems" style="font-size: small; float: left;">
        <div [ngStyle]="{'height': '15px', 'width': '15px', 'background-color': (tag.color ? tag.color : '#FF4857'), 'border-radius': '50%', 'float': 'left', 'padding-right': '4px'}" ></div>
        <span style="font-size: small; float: left; margin-left: 2px; line-height: 15px;">{{tag.title}}</span>
    </div>
</div>

<ng-template #tooltemp>
    <div style="max-width: 400px;" class="p-2 m-2">
        <div class="pr-2" *ngFor="let tag of tagItems" style="font-size: small; float: left;">
            <div [ngStyle]="{'height': '15px', 'width': '15px', 'background-color': (tag.color ? tag.color : '#FF4857'), 'border-radius': '50%', 'float': 'left', 'padding-right': '4px'}" ></div>
            <span style="font-size: small; float: left; margin-left: 2px; line-height: 15px;">{{tag.title | json}}</span>
        </div>
    </div>
    <div class="p-2 m-2"></div>
</ng-template>