import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { NgSelectComponent } from '@ng-select/ng-select';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { removeAllNull } from '../../../global-functions';
import { CariTypes } from '../../muhasebe-enum';
import { CariEkleComponent } from '../../muhasebe-main/muhasebe-cari/modallar/cari-ekle/cari-ekle.component';
import { OrganizasyonService } from '../../../../services/organizasyon.service';
import { ControlValueAccessorConnector } from '../../../../shared/control-value-accessor-connector';

@Component({
  selector: 'organization-select',
  template: `<ng-select
      [items]="organizationList$ | async"
      [loading]="isLoading"
      appendTo="body"
      [typeahead]="organizationInput$"
      typeToSearchText="Arama yapmak için yazınız."
      bindLabel="name"
      [bindValue]="bindValue"
      [addTag]="false"
      [multiple]="multiple"
      [selectOnTab]="true"
      [formControl]="control"
      [placeholder]="'Firma Seç'"
      class="uzun-select"
      (change)="organizationSelected($event)"
  >
      <ng-template style="margin:unset" ng-footer-tmp *ngIf="showAddButtons">
          <button nbButton size="small" status="primary" hero (click)="openNewFirm(cariTypes.taseron)" *ngIf="showTaseronAdd"> Taşeron Ekle </button>
          <button nbButton size="small" status="primary" hero (click)="openNewFirm(cariTypes.tedarikci)" *ngIf="showTedarikciAdd"> Tedarikçi Ekle </button>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          {{item.name}} - (<b> {{ 'MUTABAKATLISTE.APASID' | translate }}</b> {{item.apas_id}})
      </ng-template>
  </ng-select>`
  ,
  providers: [
    OrganizasyonService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XOrganizationSelectComponent),
      multi: true,
    },
  ],
})
export class XOrganizationSelectComponent extends ControlValueAccessorConnector implements OnInit, OnChanges {

  @Input() formControl!: FormControl;

  @Input() formControlName: string;

  isLoading: boolean = false;
  cariTypes = CariTypes;
  @Input() bindValue: string = 'id';
  @Input() is_active: boolean = true;
  @Input() multiple: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter();

  @Input() showAddButtons: boolean = true;
  @Input() showTaseronAdd: boolean = true;
  @Input() showTedarikciAdd: boolean = true;

  @Input() firmTypes = ['taseron', 'tedarikci', 'isveren'];
  @Input() demo: boolean;
  @Input() organizationSearch: any;

  constructor(
    private organizationService: OrganizasyonService,
    injector: Injector,
    private nbDialogService: NbDialogService,
    private cd: ChangeDetectorRef,
  ) {
    super(injector);
    this.firstList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.firstList();
    if (this.organizationSearch) {
      setTimeout(() => {
        this.organizationInput$.next(this.organizationSearch);
      }, 1000);
    }
  }

  ngOnInit(): void {
    this.getOrganizations();

  }
  organizationSelected(event) {
    this.change.emit(event);
  }

  organizationList$: Observable<any[]>;
  organizationInput$ = new Subject<string>();
  getOrganizations() {
    this.organizationList$ = concat(
      of([]), // default items
      this.organizationInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.isLoading = true),
        switchMap(term =>
          this.organizationService.relatedCompanies(
            removeAllNull(
              {
                search: term,
                filter: { is_active: true, status: this.firmTypes, demo: this.demo },
              }),
          ).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.isLoading = false),
          ),
        ),
      ),
    );
  }

  private firstList() {
    this.getOrganizations();
    setTimeout(() => {
      this.organizationInput$.next('');
    }, 300);
  }
  @ViewChildren(NgSelectComponent) ngSelects: QueryList<NgSelectComponent>;

  openNewFirm(firmaTipi: CariTypes) {
    this.ngSelects.forEach(elm => elm.close());
    this.nbDialogService.open(CariEkleComponent,
      { context: { type: firmaTipi }, closeOnBackdropClick: false },
    ).onClose.subscribe(resp => {
      if (resp) {
        const returnData = {
          id: resp?.company_id,
          name: resp?.company_name,
          demo: resp?.demo,
        };
        this.organizationList$ = of([returnData]);
        setTimeout(() => {
          this.control.setValue(returnData[this.bindValue]);
        }, 150);

        this.change.emit(returnData);
      }
    });
    this.cd.detectChanges();
  }

}

