import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { birim, currency, defaults } from '../../../pages/constants';
import { removeAllNull } from '../../../pages/global-functions';
import { MutabakatService } from '../../../services/mutabakat.service';

@Component({
  selector: 'ngx-mutabakat-satir-ekle',
  templateUrl: './mutabakat-satir-ekle.component.html',
  styleUrls: ['./mutabakat-satir-ekle.component.scss'],
  providers: [MutabakatService],
})
export class MutabakatSatirEkleComponent implements OnInit {
  data;
  form: FormGroup;
  birim = birim;
  currency = currency;
  isLoading: boolean = false;
  constructor(
    private fb: FormBuilder,
    private ref: NbDialogRef<MutabakatSatirEkleComponent>,
    private mutabakatService: MutabakatService,
  ) { }

  ngOnInit(): void {
    this.initForm();
    if (this.data?.edit) {
      this.form.patchValue(this.data);
    }
    
  }

  initForm() {
    this.form = this.fb.group({
      mutabakat_id: [this.data.id ? this.data.id : null, Validators.required],
      start_date: [new Date(), Validators.required],
      finish_date: [null],
      description: [null, [Validators.required, Validators.maxLength(1024)]],
      amount: [null, Validators.required],
      price: [null, Validators.required],
      total_price: [{ value: null, disabled: true }],
      unit: [null, Validators.required],
      note: [null, Validators.maxLength(2048)],
      price_currency: [defaults.amount_currency, Validators.required],

    });
    this.form.valueChanges.subscribe(() => {
      this.tutarHesapla();
    });
  }

  tutarHesapla() {
    const formVal = this.form.value;
    const total = formVal.price * formVal.amount;
    this.form.get('total_price').setValue(total.toFixed(2), { emitEvent: false });
  }


  close(resp?) {
    this.ref.close(resp);
  }


  postForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    const formVal = removeAllNull(this.form.getRawValue());
    this.mutabakatService.addItem(formVal).subscribe(resp => {
      this.isLoading = false;
      this.close(resp);
    }, err => this.isLoading = false);
  }
  patchForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const data = this.data;
    this.isLoading = true;
    const formVal = removeAllNull(this.form.getRawValue());
    this.mutabakatService.patchMutabakatItem(data.mutabakat_id, data.id, formVal).subscribe(resp => {
      this.isLoading = false;
      this.close(resp);
    }, err => this.isLoading = false);
  }
}
