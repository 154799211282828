<infinite-scroll [pagerowCount]="pageRowCount" [rows]="hatirlatmalar" (scrolled)="onScrool($event)">
  <div *ngFor="let hatirlatma of hatirlatmalar; let i=index; ">
    <nb-card class="animated flipInX" [class.hatirlatma_ayir]="i % 2 ==0" accent="primary" style=" border: 1px solid #c3ced38f;">
      <nb-card-body class="pb-1 pt-0">
        <div class="row" [class.aktif]="hatirlatma.status" [class.pasif]="!hatirlatma.status">
          <div class="col-md pt-3  linked-div" (click)="hatirlatmaDetay(hatirlatma.id)">
            <p style="margin-bottom:0px;">
              <small style="opacity:0.8">{{ hatirlatma.reminder_time * 1000 | date: 'dd/MM/yyyy - ' }}
                <b style="color:#ff7043">{{ hatirlatma.reminder_time * 1000 | date: 'HH:mm ' }}</b></small>
              <br>
              <b nbTooltip={{hatirlatma.note}}>
                {{hatirlatma.note | slice:0:100}}{{hatirlatma.note.length > 100 ? '...' : null }}</b>

          </div>
        </div>

      </nb-card-body>
    </nb-card>
  </div>
  <div style="padding: 50px;"></div>


</infinite-scroll>