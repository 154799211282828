<div class="aps-splt" [formGroup]="form" [nbSpinner]="isLoading">
        <h6>{{ 'PERSONEL.PERSONELTITLE' | translate }}</h6>
        <nb-card>
            	
        <!--Filter Template-->
        <apas-filter [parentComponent]="this" [bodyTemplate]="body" [islemlerTemplate]="islemler" [araText]="'Personel Ara'"  [formValue]="form"  formControlName="search"></apas-filter>

        <ng-template #body>
		<div class="row d-flex justify-content-center"  [formGroup]="filterFormGroup">
		<nb-toggle formControlName="aktif" class="pr-4 col-md-12"> {{
			'PERSONEL.TUMPEROSNEL' | translate }} </nb-toggle>
		<nb-toggle formControlName="pasif" class="pr-4 col-md-12">{{
			'PERSONEL.PASIFPERSONEL' | translate }} </nb-toggle>
		<nb-toggle formControlName="yetkili" class="pr-4 col-md-12">{{
			'PERSONEL.TUMYETKILI' | translate }} </nb-toggle>
		<nb-toggle formControlName="yetkisiz" class="pr-4 col-md-12">{{
			'PERSONEL.YETKILIOLMAYAN' | translate }} </nb-toggle>
	</div>
        </ng-template>
        <ng-template #islemler>
		<button size="small" nbButton status="primary"  (click)="grupOlsutur()">{{
			'PERSONEL.PERSONELGURUP' | translate }}</button>
		<button size="small" status="warning" class="ekleIcon" nbButton (click)="openPersonelAdd()">
			<nb-icon icon='plus-outline'> </nb-icon>{{'YENIEKLE'|translate}}
		</button>
		
        </ng-template>
        <!--// Filter Template-->

        </nb-card>
        <nb-card  class="anaTable">
            <nb-card-header>
                <div class="row m-0 p-0 tableHeader">
                       
			<div class="col-md-3">
				<span> Personel Adı
					<nb-icon icon="code-outline" class=" linked-div siralaIcon siralaIconRotate"
						*ngIf="orderStatuses['unvan'] == null" (click)="orderBy('unvan', true)">
					</nb-icon>
					<nb-icon icon="arrow-ios-upward-outline" class=" linked-div siralaIcon"
						*ngIf="orderStatuses['unvan'] == true" (click)="orderBy('unvan', true)">
					</nb-icon>
					<nb-icon icon="arrow-ios-downward-outline" class=" linked-div siralaIcon"
						*ngIf="orderStatuses['unvan'] == false" (click)="orderBy('unvan', false)"></nb-icon>
				</span>
			</div>

			<div class="col-md-2">
				<span>Meslek</span>
			</div>
			<div class="col-md-2">
				<span>Telefon </span>
			</div>
			<div class="col-md-2">
				<span>E-mail</span>
			</div>

			<div class="col-md"></div>
    
                </div>
            </nb-card-header>
            <nb-card-body>
		<div class="row p-0 m-0 yatayOrtala" style="width: 95%;" *ngIf="!isLoading && (!rows || rows.length === 0)">
			<aps-emptylist [message]="'Personel Bulunamadı ... '"></aps-emptylist>
		</div>

		<nb-list *ngIf="rows && rows.length > 0" class="aps-splt">

			<nb-list-item class="aps-hover p-0 listCustom linked-div" *ngFor="let row of rows;let i = index;"
			 nbInfiniteList listenWindowScroll [threshold]="100" (bottomThreshold)="goToNextPage()">

				<span class="col-md-3 p-0  m-0 d-flex justify-content-left align-middle"  (click)="viewDetail(row)">

					<!-- <strong class="mgauto  f-size-1-rem aps-ortala">{{i + 1}}|</strong> -->
					<strong class="mgauto  f-size-1-rem aps-ortala" style="min-width: 40px;text-align: end;"> {{ i + 1
						}} ) </strong>
					<nb-user name={{row.name}} [title]="row.job_id?.name" [showTitle]="false"
						[class]="row.is_my_user && row.is_active ? 'personel-yetki-green':'personel-yetki-orange'"
						[showName]="false" [color]="'#546E7A'" [picture]="row?.image ? prefixBase64+row?.image : null">
					</nb-user>
					<div class="mb-2 mt-2">
						<b>{{row.name}}</b>
					</div>

				</span>
				<div class="col-md-2" (click)="viewDetail(row)">
					<span>{{row.job_id?.name}}</span>
				</div>
				<div class="col-md-2" (click)="viewDetail(row)">
					<span>
						{{row?.phone | mask:telefonKodu:''}}
					</span>
				</div>
				<div class="col-md-2" (click)="viewDetail(row)">
					<span style="word-break: break-all;">{{row.email}}</span>
				</div>
				<span class="col-md">
					<button size="tiny" nbButton status="info" (click)="viewDetail(row)" >
						{{'BUTTONS.DETAIL'|translate}}
						<nb-icon icon="arrow-right-outline"></nb-icon>
					</button>

				

					<button size="tiny" nbButton status="warning"  
						(click)="yetkiliKullanici(row)"
						*ngIf="row.can_give_auth && hasAuthorization" routerLinkActive="router-link-active"
						[nbTooltip]="'Personel Firma Bilgilerine Ulaşabilir Olacaktır.'"
                
					>
						Yetkili Yap
						<nb-icon icon="person-done-outline"></nb-icon>
					</button>

					<button size="tiny" nbButton
						*ngIf="row.can_remove_auth && hasAuthorization" (click)="removeUserAuth(row)"
						routerLinkActive="router-link-active" [nbTooltip]="'Yetkilerini Kaldırabilirsiniz.'"
						style="background:#28a745;color:#fff;">
						Yetkili Personel
						<nb-icon icon="eye-outline"></nb-icon>
					</button>

					<span class="text-success" [nbTooltip]="'Yöneticiye sadece şirket sahibi müdahale edebilir.'" 
					*ngIf="row?.is_admin"><b>Yönetici</b></span>



				</span>

			</nb-list-item>

		</nb-list>
            </nb-card-body>
        </nb-card>
     
    
    
    </div>
