import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, switchMap } from 'rxjs/operators';
import * as hesapActions from './hesap.actions';
import { select, Store } from '@ngrx/store';
import * as fromHesap from './hesap.connector';
import { TranslateService } from '@ngx-translate/core';
import { Update } from '@ngrx/entity';
import { convertDateWithTZ } from '../../../global-functions';
import { CariHareketModel } from '../../models/cari_hakeret.model';
import { HesapModel } from '../../muhasebe-main/muhasebe-hesaplar/hesap.model';
import { MuhasebeService } from '../../muhasebe-service/muhasebe.service';
import { ApasCrypto } from '../../../../shared/ApasEncryptor/apas-encryptor';
import { calculateHesapBakiye } from '../../muhasebe-main/cari-hareket-detay/clear-cari-hareket';


@Injectable()
export class HesapEffects {

    loaHesap$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(hesapActions.loadHesap),
            mergeMap((props) => this.muhasebeService.searchAccount({})
                .pipe(
                    map((hesaplar: HesapModel[]) => {
                        const isEnd: boolean = false;
                        hesaplar.forEach(elm => {
                            if (elm.next_installment_date) {
                                elm.next_installment_date = convertDateWithTZ(elm, 'next_installment_date');
                            }
                        });
                        return (hesapActions.loadHesapSuccess({ hesapItem: hesaplar, isEnd: isEnd }));
                    }),
                    catchError(() => of(hesapActions.loadHesapFail('Hesap yüklenemedi.'))),
                )),
        );
    });

    hesapUpdateS$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(hesapActions.updateHesap),
            mergeMap((props: any) => {
                this.store.dispatch(hesapActions.loadHesapHareket({ hesapId: Number(props.id) }))
                return of(props);
            }),
            mergeMap((props: any) => this.muhasebeService.getAccountDetails(props.id).pipe(
                map((hesapItem: any) => {
                    return hesapActions.updateHesapSuccess({ hesap: { id: hesapItem.id, changes: hesapItem } });
                }),
                catchError((err) => {
                    return of(hesapActions.updateHesapFail(err));
                })
            )),
            catchError((err) => {
                return of(hesapActions.updateHesapFail(err));
            })

        );
    });

    addHesapHareket$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(hesapActions.addHesapHareket),
            mergeMap((props) => this.muhasebeService.getCariHareketDetailForHesap(props.hesapId, props.hareketId).pipe(
                map((hareket: CariHareketModel) => {
                    return hesapActions.addHesapHareketSuccess({ hesapId: props.hesapId, hareket: hareket });
                }),
                catchError((err) => of(hesapActions.loadHesapHareket({ hesapId: props.hesapId }))),
            ),
            ),

        );
    });

    addHesapHareketSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(hesapActions.addHesapHareketSuccess),
            switchMap((props) => {
                return of(hesapActions.updateHesap({ id: props.hesapId }));
            }),
            catchError(async (data: any) => {
                return hesapActions.loadHesapHareket({ hesapId: data.hesapId });
            }),
        );
    });

    removeHareket$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(hesapActions.removeHesapHareket),
            mergeMap((props) => this.muhasebeService.deleteCariHareket(props.hareketId).pipe(
                map((hareket: any) => {
                    return hesapActions.removeHesapHareketSuccess({ hesapId: props.hesapId, hareketId: hareket.id });
                }),
                catchError((err) => of(hesapActions.loadHesapHareket({ hesapId: props.hesapId }))),
            ),
            ),
        );
    });

    removeHareketSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(hesapActions.removeHesapHareketSuccess),
            mergeMap((props) => {
                console.log('UPDATE HESAP DETAIL FROM REMOVE HAREKET', props);
                return of(hesapActions.updateHesap({ id: props.hesapId }));
            }),
            catchError(async (data: any) => {
                return hesapActions.loadHesapHareket({ hesapId: data.hesapId });
            }),
        );
    });

    loadHareketList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(hesapActions.loadHesapHareket),
            mergeMap((props) => this.muhasebeService.getCariHareketList({ instance_type: 'account', instance_id: props.hesapId }).pipe(
                map(resp => {


                    const data = {
                        hesapId: props.hesapId,
                        results: resp,
                    }
                    return data;
                }),
                catchError((err) => of(hesapActions.loadHesapHareketFail({ hesapId: props.hesapId, err: err }))),
            )),
            mergeMap(async (props: any) => {
                if (props?.error) return hesapActions.loadHesapHareketFail({ hesapId: props.hesapId, err: `Hareket Listesi Yüklenemedi.` });
                if (!props.results) return hesapActions.loadHesapHareketFail({ hesapId: props.hesapId, err: `Hareket Listesi Yüklenemedi.` })

                let selected: HesapModel;

                this.store.pipe(select(fromHesap.selecthesapItem(props.hesapId))).subscribe(resp => {
                    selected = resp;
                }).unsubscribe();

                const decryptor = new ApasCrypto();
                let hareketList = await props.results;
                let clearList = [];
                return Promise.all(hareketList.map(async (elm) => {
                    elm = await this.clearEncryption(elm, decryptor);
                    clearList.push(elm);
                    return elm
                })
                ).then(() => hesapActions.loadHesapHareketSuccess({ hesapId: props.hesapId, items: clearList })).catch((err) => hesapActions.loadHesapHareketFail({ hesapId: props.hesapId, err: err }))
            },
            ),


        );
    });

    loadHesapHareketSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(hesapActions.loadHesapHareketSuccess),
            mergeMap(async (props) => {
                let selected;
                this.store.pipe(select(fromHesap.selecthesapItem(props.hesapId))).subscribe(resp => {
                    selected = resp;
                }).unsubscribe();
                let newList = [...props.items];

                newList = newList.sort((x, y) => y.timestamp - x.timestamp);
                // newList = newList.map((item) => Object.assign({}, item, { selected: false }));

                const decryptor = new ApasCrypto()
                let clearBakiye: string | number = await decryptor.decrypt(selected.current_value)
                clearBakiye = Number(clearBakiye)
                await calculateHesapBakiye(clearBakiye, newList).then(val => newList = val);
                newList = newList.sort((x, y) => y.timestamp - x.timestamp);
                const hesap: Update<HesapModel> = { id: props.hesapId, changes: { cari_harekets: newList, isLoading: false } };
                return hesapActions.updateHesapSuccess({ hesap: hesap });
            }),
        );
    });

    loadHesapHareketFail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(hesapActions.loadHesapHareketFail),
            map((props) => {
                of(hesapActions.updateHesap({ id: props.hesapId }));
                return props;
            }),
            mergeMap(async (props) => {
                let selected;
                this.store.pipe(select(fromHesap.selecthesapItem(props.hesapId))).subscribe(resp => {
                    selected = resp;
                }).unsubscribe();
                const hesap: Update<HesapModel> = { id: props.hesapId, changes: { cari_harekets: [], isLoading: false } };
                return hesapActions.updateHesapSuccess({ hesap: hesap });
            }),
        );
    });

    async clearEncryption(data, decryptor: ApasCrypto) {
        data = await Object.assign({}, data, { selected: false })

        if (data.date) {
            data.timestamp = data.date;
            data.date = convertDateWithTZ(data, 'date');
        }

        if (data.created_time) {
            data.created_time = convertDateWithTZ(data, 'created_time');
        }
        // data.tutar = await decryptor.decrypt(data.total_price);

        data.tutar = await decryptor.decrypt(data.total_price);
        data.tutar = Number(data.tutar);

        if (data.pn > 0) data.tahsilat = data.tutar;
        if (data.pn < 0) data.odeme = data.tutar;
        try {
            data.hareket_tipi = this.translate.instant(data.hareket_type);
        } catch (error) {
            console.log('Hareket type not found.');
        }
        return await data;
    }

    constructor(
        private actions$: Actions,
        private muhasebeService: MuhasebeService,
        private store: Store<{}>,
        private translate: TranslateService,
    ) { }
}

