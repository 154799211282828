<nb-card style="width: 450px;" size="giant" class="modalType animated fadeInRight">
  <nb-card-header class="d-flex justify-content-between align-items-center">
    <span>
      {{'ARKADASINAONER.ARKADASINAONER' | translate }}
    </span>
    <button nbButton (click)="closed()" status="basic" size="small" class="heryerdeClose">x</button>
  </nb-card-header>
  <nb-card-body [formGroup]="form" [nbSpinner]="isSubmitting">
    <div class="row form-padding">
      <!--isim-->
      <!--data-content="Ad Soyad (Davet edilen kişi)"-->

      <div class="col-md-12">
        <div class="form-group">
          <label class="label">Adı Soyadı</label>
          <input  type="text" nbInput fullWidth placeholder="Adı Soyadı" formControlName="name" apasInvalid/>
       
        </div>
      </div>
      <!--// isim-->

      <!--E-mail-->
      <div class="col-md-12">
        <div class="form-group">
          <label class="label">{{'ARKADASINAONER.EMAIL' | translate}}</label>
          <input id="email"  type="text" nbInput fullWidth [placeholder]="'ARKADASINAONER.EMAIL' | translate"
            formControlName="email" /> 
        </div>
      </div>
      <!--// E-mail-->

      <!--Telfono-->
      <div class="col-md-12">
        <div class="form-group">
          <label class="label">{{'ARKADASINAONER.TELEFON' | translate}}</label>
          <apas-telefon-input formControlName="phone"></apas-telefon-input>
       
        </div>
      </div>
      <!--// Telefon-->



      <!--Not-->
      <div class="col-md-12">
        <div class="form-group">
          <label class="label">{{'ARKADASINAONER.NOT' | translate}}</label>
          <textarea [placeholder]="'ARKADASINAONER.NOT' | translate" nbInput fullWidth
            formControlName="note"></textarea>

         

        </div>
      </div>
      <!--// Not-->
    </div>
    <div class="text-center">
      <button nbButton status="primary" (click)="sendInvitation()">{{'ARKADASINAONER.GONDER' | translate}}</button>
    </div>


  </nb-card-body>
</nb-card>