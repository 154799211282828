import {
  AfterViewInit,
  Directive,
  DoCheck,
  ElementRef,
  Input,
  Optional,
  Renderer2,
} from '@angular/core';
import {  NgControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '([apasInvalid])',
})
export class ApasInvalidDirective implements DoCheck, AfterViewInit {
  @Input('apasInvalid') message: string = 'Bu alan boş bırakılamaz!';
  @Input('apasInvalidMin') min: number;
  @Input('apasInvalidMax') max: number;

  // belli bir html elementiindeki değişimi yakalar
  observer = new MutationObserver((mutations) => {
    mutations?.forEach(() => {
      this.onInputChange();
    });
  });
  isInvalid: boolean = false;
  isTouched: boolean = false;
  uyari: HTMLDivElement;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Optional() private control: NgControl,
  ) {
    this.uyari = this.renderer.createElement('div');

    this.uyari.innerHTML = this.message;
    this.uyari.classList.add('uyari');
    this.uyari.classList.add('d-none');
    if (el) {
      this.renderer.appendChild(el.nativeElement.parentElement, this.uyari);
      this.observer.observe(this.el.nativeElement, {
        attributes: true,
        childList: true,
        characterData: true,
      });
    }
  }
  addBorder: boolean = false;
  removeBorder: boolean = false;
  ngAfterViewInit(): void {
  }
  onInputChange() {
    this.uyari.classList.add('d-none');
    this.uyari.innerHTML = this.message;
    this.isInvalid = false;
    this.isTouched = false;
    if (this.control) {
      if (this.control?.control?.touched) {
        if (!this.control.control.valid) {
          if (!this.addBorder) {
            this.el.nativeElement.classList.add('inputRequired');
            this.addBorder = true;
            this.removeBorder = false;
          }
        } else {
          if (!this.removeBorder) {
            this.el.nativeElement.classList.remove('inputRequired');
            this.removeBorder = true;
            this.addBorder = false;
          }
        }
      }
    }
    this.el.nativeElement.classList.forEach((element) => {
      if (element === 'ng-invalid') {
        this.isInvalid = true;
      }
      if (element === 'ng-touched') {
        this.isTouched = true;
      }
      if (this.isInvalid && this.isTouched) {
        this.uyari.classList.remove('d-none');
        if (this.control) {
          if (this.control.hasError('required')) {
            this.uyari.innerHTML = this.message
              ? this.message
              : 'Bu alan boş bırakılamaz!';
          } else if (this.control.hasError('email')) {
            this.uyari.innerHTML = 'Lütfen geçerli bir e posta giriniz.';
          } else if (this.control.hasError('minlength')) {
            this.uyari.innerHTML = `Bu alanı en az ${this.min} karakter olmalıdır!"`;
          } else if (this.control.hasError('maxlength')) {
            this.uyari.innerHTML = `Bu alanı en fazla ${this.max} karakter olmalıdır!"`;
          } else if (this.control.hasError('min')) {
            this.uyari.innerHTML = `Lütfen ${this.control.errors.min.min}den büyük bir sayı giriniz.`;
          } else if (this.control.hasError('max')) {
            this.uyari.innerHTML = `Lütfen ${this.control.errors.max.max}den küçük bir sayı giriniz.`;
          } else if (this.control.hasError('bothempty')) {
            this.uyari.innerHTML = `Bu iki alandan en az birini doldurunuz.`;
          }
        }
      } else {
        this.uyari.classList.add('d-none');
      }
    });
  }
  ngDoCheck(): void { }
}
