import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MuhasebeCurrency } from '../../../pages/constants';
import { ControlValueAccessorConnector } from '../../../shared/control-value-accessor-connector';

@Component({
  selector: 'money-currency-input',
  templateUrl: './money-currency-input.component.html',
  styleUrls: ['./money-currency-input.component.scss']
})
export class MoneyCurrencyInputComponent extends ControlValueAccessorConnector implements OnInit {

  @Input() moneyControl!: FormControl;
  @Input() moneyControlName: string;


  @Input() currencyControl!: FormControl;
  @Input() currencyControlName: string = 'currency';

  currency = MuhasebeCurrency;
  @Input() forceRefresh = 0;
  @Input() encrypt = false;
  @Input() decrypt = false;
  @Input() negative = true;
  @Input() placeholder = 'Tutar';

  get control(): any {
    if (this.moneyControl) {
        return this.moneyControl;
    } else if (this.moneyControlName) {
        this.moneyControl = this.controlContainer?.control?.get(this.moneyControlName) as FormControl;
        return this.moneyControl;
    }
}


  get currControl(): FormControl {
    if (this.currencyControl) {
      return this.currencyControl;
    } else if (this.currencyControlName) {
      this.currencyControl = this.controlContainer?.control?.get(this.currencyControlName) as FormControl;
      return this.currencyControl;
    }
  }


  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {

    const dataObject = JSON.parse(localStorage.getItem('org'));
    const default_currency = dataObject?.default_currency;

    if (this.currControl?.value == (null || undefined) && !this.currControl.disabled) {
      
      this.currControl.patchValue(default_currency);
    }


  }

}
