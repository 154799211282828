import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { telefonKodu, responseKodlari } from '../../../../../../pages/constants';
import { removeAllNull } from '../../../../../../pages/global-functions';
import { KullaniciService } from '../../../../../../services/kullanici.service';
import * as cariActions from '../../../../muhasebe-store/cari/cari.actions';
import { CariTypes, musteriType } from '../../../../muhasebe-enum';
import { MuhasebeService } from '../../../../muhasebe-service/muhasebe.service';

@Component({
  selector: 'cari-ekle',
  templateUrl: './cari-ekle.component.html',
  styleUrls: ['./cari-ekle.component.css'],
  providers: [MuhasebeService],
})
export class CariEkleComponent implements OnInit {
  accordionOpen = false;
  cariTypes = CariTypes;
  type: CariTypes;
  editMode: boolean = false;
  taseronList: any[] = [];
  isLoading: boolean = false;
  form: FormGroup;
  telefon_kodu = telefonKodu;
  musteriType = musteriType;
  constructor(
    private nbDialogRef: NbDialogRef<CariEkleComponent>,
    private router: Router,
    private muhasebeService: MuhasebeService,
    private fb: FormBuilder,
    private ref: NbDialogRef<CariEkleComponent>,
    private kullaniciService: KullaniciService,
    private store: Store<{ Cari }>,
  ) {
    this.initForm();
  }
  initForm() {
    this.form = this.fb.group({
      tip: [null, Validators.required],
      musteri_type: [],
      open_amount: [],
      instance_id: [null],
      name: [null, Validators.required],
      kimlik_no: [],
      phone: [],
      job_id: [],
      email: [],
      company_country: [null],
      company_city: [null],
      address: [],
      currency: ['TRY', Validators.required],
    });
  }
  getCarisizList(tip: string) {

    this.isLoading = true;
    this.muhasebeService.getCarisizList({ tip: tip }).subscribe(resp => {
      this.taseronList = resp;
      this.isLoading = false;
    }, (err) => this.isLoading = false);
  }

  meslekler = [];

  ngOnInit() {

    this.form.get('tip').setValue(this.type);
    this.kullaniciService.getMeslekler().subscribe(data => {
      this.meslekler = data;
      this.meslekler = [...this.meslekler];
    });
  }

  kapat() {
    this.ref.close();
  }
  postForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    const formVal = removeAllNull(this.form.value);


    this.muhasebeService.addNewCari(formVal).subscribe(resp => {
      this.store.dispatch(cariActions.addCariItem({ cariItem: resp }));
      this.nbDialogRef.close(resp);
      this.isLoading = false;
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.store.dispatch(cariActions.selectCariItem({ id: resp.id }));
      this.router.navigate([`pages/muhasebe/cari-detay`],
        { state: { openGuncelle: true, data: resp } },
      );
    }, (err) => {
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
      this.isLoading = false;
    });

  }

  close() {
    this.nbDialogRef.close();
  }
  musteriTypeChange(event) {
    this.form.get('musteri_type').setValue(event?.tabId);
  }
}
