import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as bildirimActions from './bildirim.actions';
import { BildirimModel } from '../../models/bildirim.model';
import { BildirimService } from '../../services/bildirim.service';
import { switchMap } from 'rxjs-compat/operator/switchMap';


@Injectable()
export class BildirimEffects {
    showBildirim$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(bildirimActions.setBildirimShowed),
            mergeMap(({ id }) => this.bildirimService.setShowed()
                .pipe(
                    map((bildirimItem: BildirimModel) => {
                        //  
                        return bildirimActions.setBildirimShowedSuccess({
                            bildirim: { id: bildirimItem.bildirim_id, changes: { is_showed: true } },
                        });
                    }),
                    catchError(() => of(bildirimActions.setBildirimShowedFail({ err: 'Bildirim yüklenemedi.' }))),
                )),
        );
    });

    setAllShowed$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(bildirimActions.setAllBildirimShowed),
            mergeMap(() => this.bildirimService.setShowed()
                .pipe(
                    map((count: any) => {
                        //  
                        return bildirimActions.updateCount(count);
                    }),
                    catchError(() => of(bildirimActions.setBildirimReadedFail({ err: 'Bildirim yüklenemedi.' }))),
                )),
        );
    });
    readedBildirim$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(bildirimActions.setBildirimReaded),
            mergeMap(({ id }) => this.bildirimService.setReaded(id)
                .pipe(
                    map((bildirimItem: BildirimModel) => {
                        //  
                        return bildirimActions.setBildirimReadedSuccess({
                            bildirim: { id: bildirimItem.bildirim_id, changes: { is_readed: true, is_showed: true } },
                        });
                    }),
                    catchError(() => of(bildirimActions.setBildirimReadedFail({ err: 'Bildirim yüklenemedi.' }))),
                )),
        );
    });


    loadBildirimRead$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(bildirimActions.loadBildirim),
            mergeMap(({ page }) => this.bildirimService.getBildirim(page)
                .pipe(
                    map((bildirimItem: BildirimModel[]) => {
                        const isEnd = (bildirimItem.length < 20); // bir istekde gelen bildirim sayısı
                        return bildirimActions.loadBildirimSuccess({ bildirimItem: bildirimItem, isEnd: isEnd });
                    }),
                    catchError(() => of(bildirimActions.loadBildirimFail('Bildirim yüklenemedi.'))),
                )),
        );
    });

    setBildirimCount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(bildirimActions.getCount),
            mergeMap(() => this.bildirimService.getUnShowedCount()
                .pipe(
                    map((count: any) => {
                        return bildirimActions.updateCount(count);
                    }),
                )),
        );
    });



    constructor(
        private actions$: Actions,
        private bildirimService: BildirimService,
    ) {

    }

}
