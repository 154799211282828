import { createAction, props } from '@ngrx/store';
import { JobModel } from '../../components/select-componentler/job-select/job.model';

export const loadJobs = createAction('[Jobs] Load jobs');
// tslint:disable-next-line: max-line-length
export const loadJobsSuccess = createAction('[Jobs] Load Jobs Success', props<{ jobItems: JobModel[]}>());
export const loadJobsFail = createAction('[Jobs] Load Jobs Fail', props<String>());
// tslint:disable-next-line: max-line-length
export const addJobsItem = createAction('[Jobs] Add Jobs Item', props<{ jobItem: JobModel }>());


