import { Injectable } from '@angular/core';


@Injectable()
export class AuthService {
    authInfo: string = 'Auth Is Not Setted';


    setAuth2() {
        this.authInfo = 'Auth Is Setted';
    }
    getAuth2(): string {
        return this.authInfo;
    }
}
