import { Injectable } from '@angular/core';
import { TaseronModel } from '../models/taseron.model';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TaseronPersonelModel } from '../models/taseron_personel.model';
import { IsMakinasiModel } from '../models/is_makinalari.model';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class TaseronService {
  private readonly API_URL = `${environment.backendurl}api/taseron/`;

  constructor(private httpClient: HttpClient) { }
  patchTeseronEdit(id, data): Observable<TaseronModel> {
    return this.httpClient.patch<TaseronModel>(this.API_URL + id + '/', data);
  }

  deleteTaseron(id) {
    return this.httpClient.delete(this.API_URL + id + '/');
  }

  patchTeseronTagEdit(id, data): Observable<TaseronModel> {
    const url: string = `${this.API_URL}tags/${id}/`;
    return this.httpClient.patch<TaseronModel>(url, data);
  }
  getTaseronlar(): Observable<TaseronModel[]> {
    return this.httpClient.get<TaseronModel[]>(this.API_URL);
  }
  getNewTaseronList(filter): Observable<TaseronModel[]> {
    const url: string = `${this.API_URL}list/`;
    return this.httpClient.post<TaseronModel[]>(url, filter);
  }
  getTaseronPersonelList(filter): Observable<TaseronModel[]> {
    const url: string = `${this.API_URL}personel/list/`;
    return this.httpClient.post<TaseronModel[]>(url, filter);
  }
  getAllTaseron(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.API_URL + 'getall/');
  }

  getTaseronPersonel(_id: number): Observable<TaseronPersonelModel[]> {
    return this.httpClient.get<TaseronPersonelModel[]>(this.API_URL + '/personel/' + _id);
  }

  getTaseronIsMakinalari(taseronId: number): Observable<IsMakinasiModel[]> {
    const url = `${this.API_URL}ismakinalari/${taseronId}/`;
    return this.httpClient.get<IsMakinasiModel[]>(url);
  }
  getDetailedTaseron(_id: number): Observable<TaseronModel> {
    return this.httpClient.get<TaseronModel>(this.API_URL + _id + '/');
  }

  addTaseron(apas_id: number) {
    return this.httpClient.post(this.API_URL + 'ekle/', { 'apas_id': apas_id });
  }

  addTaseronPersonel(_tpersonel: any, _taseronID: number) {
    return this.httpClient.post(this.API_URL + 'personel/' + _taseronID + '/', _tpersonel);
  }

  getTaseronFiles(filter) {
    const url = `${this.API_URL}file/list/`;
    return this.httpClient.post<IsMakinasiModel[]>(url, filter);
  }

  getFileDetail(identifier: string) {
    const url: string = `${this.API_URL}file/detail/${identifier}/`;
    // return this.httpClient.get<any>(url);
    const req = new HttpRequest('GET', url, { reportProgress: true });
    return this.httpClient.request(req);
  }

  uploadFile(data) {
    const url: string = `${this.API_URL}file/upload/`;
    const req = new HttpRequest('POST', url, data, { reportProgress: true });
    return this.httpClient.request(req);
  }

  deleteFile(identifier: string) {
    const url = `${this.API_URL}file/delete/${identifier}/`;
    return this.httpClient.delete<IsMakinasiModel[]>(url);

  }

  getPersonelDetail(taseronId, instanceId) {
    const url = `${this.API_URL}personel/detail/${taseronId}/${instanceId}/`;
    return this.httpClient.get(url);
  }

  updatePersonelInformation(taseronId, instanceId, data) {
    const url = `${this.API_URL}personel/update/${taseronId}/${instanceId}/`;
    return this.httpClient.patch(url, data);
  }
}
