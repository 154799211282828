import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../pages/constants';
import { removeAllNull } from '../../../pages/global-functions';
import { OrganizasyonService } from '../../../services/organizasyon.service';

@Component({
  selector: 'personel-invite',
  templateUrl: './personel-invite-component.html',
  styleUrls: ['./personel-invite-component.scss'],
  providers: [OrganizasyonService],
})
export class PersonelInviteComponent implements OnInit {
  /**
   * Firma İlk Oluştuğunda Liste Halinde Personel Davet Etmek İçin
   */

  form: FormGroup;
  get personels(): FormArray { return this.form.get('personels') as FormArray; }
  constructor(
    private fb: FormBuilder,
    private organizationService: OrganizasyonService,
    private ref: NbDialogRef<PersonelInviteComponent>,
  ) {

    this.initForm();
  }

  ngOnInit(): void {
  }

  initForm() {
    this.form = this.fb.group({
      personels: this.fb.array([], [Validators.min(1), Validators.max(7)]),
    });

    this.addFormItem();
  }

  addFormItem() {
    const formItem = this.fb.group({
      phone: [],
    });

    this.personels.push(formItem);
  }

  removeFormItem(i) {
    if (this.personels.length > 1) {
      this.personels.removeAt(i);
    } else {
      this.personels.at(0).reset();
    }
  }

  postForm() {
    const formVal = removeAllNull(this.personels.value);
    this.organizationService.invitePersonels(formVal).subscribe(resp => {
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.close(resp);
    });
  }

  close(resp?) {
    this.ref.close(resp);
  }
}
