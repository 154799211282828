import * as akisActions from './akis.actions';
import { AkisItem } from '../../models/akis.model';
import {
    createReducer,
    on,
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

interface AkisState extends EntityState<AkisItem> {
    selectedAkisId: number | null;
    end: boolean;
    loading: boolean;
    loaded: boolean;
    error: String;
    connection: boolean;
}

const defaultAkisState: AkisState = {
    ids: [],
    entities: {},
    selectedAkisId: null,
    end: false,
    loading: false,
    loaded: false,
    error: '',
    connection: false,
};


const akisAdapter: EntityAdapter<AkisItem> = createEntityAdapter<AkisItem>();

const initialState = akisAdapter.getInitialState(defaultAkisState);

const akisReducer = createReducer(
    initialState,
    on(akisActions.loadAkis, (state, props) => {
        return { ...state, loading: true };
    }),
    on(akisActions.loadAkisSuccess, (state, props) => {
        return akisAdapter.upsertMany(props.akisItem, {
            ...state,
            loading: false,
            loaded: true,
            end: props.isEnd,
        });
    }),
    on(akisActions.loadAkisFail, (state, props) => {
        return { ...state, loading: false, end: true, error: props };
    }),
    on(akisActions.addItem, (state, props) => {
        return akisAdapter.upsertOne(props.akisItem, {
            ...state,
            selectedCustomerId: props.akisItem.id,
        });
    }),
    on(akisActions.setConnected, (state, props) => {
        return { ...state, ...props };
    }),
);

function AkisReducer(action, state) { return akisReducer(action, state); }


export {
    AkisReducer,
    AkisState,
    defaultAkisState,
    akisAdapter,
};
