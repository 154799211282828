<div class="tagsComponent aps-splt" cdkDrag id="tagDiv" style="z-index: 99;">
    <div cdkDragHandle style=" background-color: #8db596;height: 58px;    padding: 16px;">
        <Span class="baslik"> {{'KASALAR.ETIKETLER'|translate}}</Span>
        <nb-icon class=" linked-div closeIcon" icon='close-outline'  (click)="kapat()">
        </nb-icon>
    </div>
    <div id="revealCard">
        <nb-reveal-card [showToggleButton]="false" [revealed]="revealed" id="etiketCard" class="p-0 m-0" 
        style="width: 500px; border-top: 0 !important;height:85vh !important;">
            <nb-card-front class="h-100 w-100" >
                <nb-card class="p-0 m-0 d-flex h-100 w-100" style=" border-top: 0 !important;"  [nbSpinner]="isLoading">
                    <nb-card-header>Etiket Grupları
                        <p *ngIf="secilenEtiketler.length > 0" style="margin-bottom: 0px;margin-top:5px;">Seçilen etiketler</p>
                        <div>
                            <div *ngFor="let item of secilenEtiketler"
                                            id="{{item.title}}" draggable="true"   class=" m-1" 
                                            [ngStyle]="{'float':'left','border-radius':'3px','cursor': 'copy', 'background-color': item?.color}" 
                                            >
                                                <span class="m-1 h-100 w-100" 
                                                [ngStyle]="{'color': '#FFFFFF', 'text-shadow': '2px 2px 10px' + item?.shadow_color + ', 0.03em 0.03em 0' + item?.shadow_color, 'font-size': '14px', 'font-weight': 400}">
                                           {{item.title}}</span>
                                           <nb-icon class="linked-div" icon='close-circle-outline' style="font-size:medium ; margin:auto; color: white;" (click)="removeTagItem(item)"> </nb-icon>
                                            </div>
                        </div>
                    </nb-card-header>
                    <nb-card-body style="background-color: #ffff;">
                       
                        <div id="EtiketCntynr" >
                            <nb-accordion id="akordiyon">
                                <nb-accordion-item *ngFor="let etiket of tagsList$ | async">

                                    <nb-accordion-item-header class="p-2"> <!--#f1efe3-->
                                        {{etiket?.title}}
                                    </nb-accordion-item-header>

                                    <nb-accordion-item-body>
                                        <div>
                                            <div 
                                            id="{{item.title}}" draggable="true" (dragstart)="drag($event, item)"  
                                            (click)="baski(item)" class=" m-1" 
                                            [ngStyle]="{'float':'left','border-radius':'3px','cursor': 'copy', 'background-color': etiket?.color}" 
                                            *ngFor="let item of etiket?.tag_items">
                                                <span class="m-1 h-100 w-100" 
                                                [ngStyle]="{'color': '#FFFFFF', 'text-shadow': '2px 2px 10px' + etiket?.shadow_color + ', 0.03em 0.03em 0' + etiket?.shadow_color, 'font-size': '14px', 'font-weight': 400}">
                                           {{item.title}}</span>
                                            </div>
                                        </div>
                                    </nb-accordion-item-body>
                                </nb-accordion-item>
                            </nb-accordion>
                            <div  cdkDragHandle style="text-align: center; margin-top: 8px;">
                                <button nbButton outline style="color:#00000080; height: 30px; border: 1px solid #74593D;margin-top: 8px; background-color:#B5C1B4;" 
                                (click)="onRevealed()">Yeni Etiket Ekle / Düzenle</button>
                            </div>
                        </div>
                    </nb-card-body>
                </nb-card>
            </nb-card-front>

            <nb-card-back class="h-100 w-100">
                <!--  etiket değiştir ekle -->
                <nb-card class="p-0 m-0 d-flex h-100 w-100" style="border-top: 0 !important;">
                    <nb-card-header>Etiket Ekle Düzenle</nb-card-header>
                    <nb-card-body id="bcontainer" [formGroup]="form" #backContainer class="py-2">
                        <nb-accordion id="akordiyon" formArrayName="liste" *ngFor="let etiket of mainList.controls; let i = index" style="box-shadow: none;">
                            <nb-accordion-item [formGroupName]="i" class="px-0" style="border-radius:0px">
                                <nb-accordion-item-header class="p-1 parent d-flex" style="border-bottom:1px solid #eeee;"> <!--  -->
                                            <input nbInput 
                                                class="likeInput"
                                                fieldSize="small" 
                                                style="border: none;"
                                                formControlName="title" (blur)="grupAdiDegistir(etiket)" 
                                                style="font-size: 13px;" 
                                            />
                                 <nb-icon class=" linked-div" icon='close-outline' (click)="grupSil(i)"
                                 style="margin-left: auto; margin-right: 30px;"> </nb-icon>
                                </nb-accordion-item-header>
                                <nb-accordion-item-body>
                                    <div formArrayName="tag_items">
                                        <div *ngFor="let item of subList(i).controls; let k=index">

                                        <span [formGroupName]="k">
                                            <input nbInput fieldSize="small" (blur)="etiketAdiDegis(item, i, k)" style="margin-bottom:16px; "
                                            formControlName="title" type="text" placeholder="Etiket Ekle">
                                            <nb-icon *ngIf="item.get('id').value" class=" linked-div" icon='close-outline'
                                            (click)="etiketSil(item, i, k)"> </nb-icon>
                                            <!-- <button *ngIf="!item.get('id').value" style="height:20px; float: right; max-width: 70px !important;"
                                            nbButton outline (click)="etiketEkle(i, k)">Kaydet</button> -->
                                        </span>
                                        </div>
                                    </div>
                                </nb-accordion-item-body>
                            </nb-accordion-item>
                        </nb-accordion>
                        <ng-template #templateRef let-data>
                            <nb-card style="width: 360px; border-top: 0 !important;z-index: 1002;" >
                                <nb-card-header style="background-color:#FFECB3;">
                                    <span>{{'KASALAR.YENIETIKETGRUBUEKLE'|translate}}</span>
                                </nb-card-header>
                                <nb-card-body>
                                    <input nbInput id="yeniEtiketGrupId" placeholder="Yeni Etket Grup Adı" style="float: left;" [formControl]="title">
                                    <button style="float:right" size="small" nbButton status="info" (click)="addNewGroup()">
                                     {{"MUHASEBE.KAYDET"|translate}}</button>
                                </nb-card-body>
                                <nb-card-footer>
                        
                                </nb-card-footer>
                            </nb-card>
                        </ng-template>
                    </nb-card-body>

                    <nb-card-footer>
                        <button style="height:20px; float: left;" nbButton outline [nbPopover]="templateRef">{{'KASALAR.YENIETIKETGRUBU'|translate}}</button>
                        <button (click)="onRevealed()" style="height:20px; float: right;" nbButton outline> Tamam</button>
                    </nb-card-footer>
                    

                </nb-card>

            </nb-card-back>

        </nb-reveal-card>
    </div>
</div>
