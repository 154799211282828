import { trigger, transition, style, animate } from '@angular/animations';

export const fadeInOutTimeout = 1000;
export const fadeInOut = trigger('fadeInOut', [
  transition('green => *', [
    style({ color: 'green' }),
    animate(fadeInOutTimeout),
  ]),
  transition('red => *', [
    animate(fadeInOutTimeout, style({ color: 'red' })),
  ]),
  transition('* => *', [
    animate(fadeInOutTimeout, style({ color: 'white' })),
  ]),
]);
