<nb-card class="modalType animated fadeInRight" size="giant" style="width: 85%;">

    <nb-card-body class="py-0 px-1" style="overflow-x: hidden;">


        <div class="rounded bg-white h-100">
            <div class="row h-100">
                <div class="col-md-3  border-right">
                    <nb-icon icon="arrow-back-outline"
                        style="transform: scale(1.5);   margin-left: 16px;  margin-top: 15px;" (click)="close()">
                    </nb-icon>
                    <div class="d-flex flex-column align-items-center text-center  pt-4 px-5">

                        <nb-user name={{kullaniciBilgileri.name}} [title]="kullaniciBilgileri.job_id?.name"
                            [showTitle]="false" size="giant"
                            [class]="kullaniciBilgileri.is_my_user && kullaniciBilgileri.is_active ? 'personel-yetki-green mini-profile linked-div':'personel-yetki-orange mini-profile linked-div'"
                            [showName]="false" [color]="'#546E7A'" (click)="profilPhotoModal()"
                            [picture]="kullaniciBilgileri?.image ? prefixBase64+kullaniciBilgileri?.image : null">

                        </nb-user>
                        <b class="d-block">{{kullaniciBilgileri?.name}}</b>
                        <small>{{kullaniciBilgileri?.job_id?.name}}</small>
                    </div>
                    <div class="d-flex align-items-center justify-content-center px-5">
                        <!--Plus Svg-->
                        <svg class="plus" version="1.0" height="17" width="17" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 76.000000 60.000000" preserveAspectRatio="xMidYMid meet"
                            style="margin-left: -15px;">

                            <g transform="translate(0.000000,60.000000) scale(0.100000,-0.100000)" stroke="none">
                                <path d="M310 460 l0 -90 -90 0 -90 0 0 -75 0 -75 90 0 90 0 0 -85 0 -85 75 0
                  75 0 0 85 0 85 90 0 90 0 0 75 0 75 -90 0 -90 0 0 90 0 90 -75 0 -75 0 0 -90z" />
                            </g>
                        </svg>
                        <!--//Plus Svg-->
                        <small> <b>{{ 'PERSONEL.APASID' | translate }}{{kullaniciBilgileri?.apas_id}}</b></small>
                    </div>

                    <nb-list class="userIslemler">
                        <!--Yönetici durumu-->
                        <nb-list-item (click)="giveAll()" *ngIf="kullaniciBilgileri.can_make_admin">
                            <nb-icon icon="person-add-outline"></nb-icon>
                            {{ kullaniciBilgileri.is_admin ? 'Yönetici' : 'PERSONEL.YONETICI' | translate }}
                        </nb-list-item>

                        <!--Yönetici durumu-->

                        <!--Yetkili Yap-->
                        <nb-list-item *ngIf="hasAuthorization && kullaniciBilgileri.can_give_auth"
                            [nbTooltip]="'Personel Firma Bilgilerine Ulaşabilir Olacaktır.'"
                            (click)="yetkiliKullanici(kullaniciBilgileri)">
                            <nb-icon icon="unlock-outline"></nb-icon>
                            Yetkili Yap
                        </nb-list-item>
                        <nb-list-item *ngIf="hasAuthorization && kullaniciBilgileri.can_remove_auth"
                            [nbTooltip]="'Personel Firma Bilgilerine Ulaşabilir Olacaktır.'"
                            (click)="removeUserAuth(kullaniciBilgileri)">
                            <nb-icon icon="unlock-outline"></nb-icon>
                            Yetkileri Kaldır
                        </nb-list-item>
                        <!--// Yetkili Yap-->

                        <!--Gözlemci Personel-->
                        <!-- <nb-list-item *ngIf="kullaniciBilgileri.can_remove_auth"
                            (click)="removeUserAuth(kullaniciBilgileri)">
                            <nb-icon icon="eye-outline"></nb-icon>
                            Gözlemci Yap
                        </nb-list-item> -->
                        <!--//Gözlemci Personel-->

                        <!--Personel Aktif Pasif-->
                        <nb-list-item *ngIf="hasAuthorization && kullaniciBilgileri.can_deactivate" (click)="deactivatePersonel()">
                            <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
                            {{ 'PERSONEL.PERSONELPASIF' | translate }}
                        </nb-list-item>

                        <nb-list-item *ngIf="hasAuthorization && kullaniciBilgileri.can_activate" (click)="reactiveatePersonel()">
                            <nb-icon icon="close-circle-outline"></nb-icon>
                            Personeli Aktif Yap
                        </nb-list-item>
                        <!--//Personel Aktif Pasif-->


                        <!--Yetkiler-->
                        <nb-list-item (click)="openYetki()" *ngIf="hasAuthorization && kullaniciBilgileri.can_remove_auth">
                            <nb-icon icon="credit-card-outline"></nb-icon>
                            Yetkiler
                        </nb-list-item>
                        <!--// Yetkiler-->

                         <!--Personel Dosyaları-->
                         <nb-list-item (click)="openPersonelFile()">
                            <nb-icon icon="archive-outline"></nb-icon>
                            Personel Dosyaları
                        </nb-list-item>
                        <!--// Personel Dosyaları-->



                    </nb-list>

                </div>
                <div class="inputColor col-md-4 border-right pt-5" [formGroup]="form">
                    <div class="d-flex justify-content-between">
                        <h6>Personel Bilgileri</h6> 
                        <button nbButton status="primary" size="small" *ngIf="canEdit && !personelEdit" (click)="personelEdit = true">Düzenle</button>
                        <button nbButton status="danger" size="small" *ngIf="personelEdit" (click)="personelEdit = false">İptal</button>

                    </div>
                    <div class="row" *ngIf="canEdit && personelEdit">
                        <div class="col-md-12">
                            <label class="label">İsim</label>
                            <input nbInput fullWidth formControlName="first_name" apasInvalid>
                        </div>
                        <div class="col-md-12">
                            <label class="label">Soyisim</label>
                            <input nbInput fullWidth formControlName="last_name" apasInvalid>
                        </div>
                        <div class="col-md-12">
                            <label class="label">Kimlik No</label>
                            <input nbInput type="number" fullWidth formControlName="kimlik_no" apasInvalid>
                        </div>
                        <div class="col-md-12">
                            <label class="label">Telefon</label>
                            <apas-telefon-input formControlName="phone" apasInvalid></apas-telefon-input>
                        </div>
                        <!-- <div class="col-md-12">
                            <label class="label">E-posta</label>
                            <input nbInput fullWidth formControlName="email" apasInvalid>

                        </div> -->
                        <div class="col-md-12">
                            <label class="label">Meslek</label>
                            <job-select formControlName="job_id"></job-select>
                        </div>
                        <div class="col-md-12">
                            <label class="label">Görev Ünvanı</label>
                            <input nbInput fullWidth formControlName="unvan">
                        </div>
                        <div class="col-md-12 text-right">

                        <button nbButton status="warning"  (click)="updatePersonel()" [nbSpinner]="isLoading" [disabled]="isLoading">Güncelle</button>
                            
                        </div>
                    </div>

                    <nb-list *ngIf="!personelEdit">
                        <nb-list-item class="px-0">
                            <div class="col-md-4"><b>İsim: </b></div>
                            <div class="col-md">{{kullaniciBilgileri?.first_name}}</div>
                        </nb-list-item>

                        <nb-list-item class="px-0">
                            <div class="col-md-4"><b>Soyisim: </b></div>
                            <div class="col-md">{{kullaniciBilgileri?.last_name}}</div>
                        </nb-list-item>

                        <nb-list-item class="px-0">
                            <div class="col-md-4"><b>Kimlik No: </b></div>
                            <div class="col-md">{{kullaniciBilgileri?.kimlik_no}}</div>
                        </nb-list-item>
                        <nb-list-item class="px-0">
                            <div class="col-md-4"><b>Telefon: </b></div>
                            <div class="col-md">{{kullaniciBilgileri?.phone | mask:telefonKodu:''}}</div>
                        </nb-list-item>
                        <nb-list-item class="px-0">
                            <div class="col-md-4"><b>E-posta: </b></div>
                            <div class="col-md">{{kullaniciBilgileri?.email}}</div>
                        </nb-list-item>
                        <nb-list-item class="px-0">
                            <div class="col-md-4"><b>Meslek: </b></div>
                            <div class="col-md">{{kullaniciBilgileri?.job_id?.name}}</div> 
                        </nb-list-item>
                        <nb-list-item class="px-0">
                            <div class="col-md-4"><b>Görev Ünvanı </b></div>
                            <div class="col-md">{{kullaniciBilgileri?.unvan}}</div> 
                        </nb-list-item>
                    </nb-list>
                    
                </div>
                <div class="col-md-5">
                    <button nbButton (click)="close()" size="small" class="heryerdeClose"
                        style="float: right;">X</button>

                    <div class="py-5">
                        
                        <!-- <button status="info" nbButton size="tiny"><nb-icon icon="eye-outline"></nb-icon></button> -->

                        <personel-ozluk-bilgileri [kullaniciBilgileri]="kullaniciBilgileri"></personel-ozluk-bilgileri>

                    </div>
                </div>
            </div>
        </div>
    </nb-card-body>

</nb-card>