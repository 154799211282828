<nb-card  style="width: 350px;" class="modalType animated fadeInRight">
  <nb-card-header class="d-flex justify-content-between align-items-center">
    <span>
      {{ 'HATIRLATMALAR.EKLE' | translate }} 
    </span>
    <button nbButton (click)="closed()" size="tiny" class="heryerdeClose">X</button>
  </nb-card-header>
  <nb-card-body [nbSpinner]="isLoading" nbSipnnerSize="tiny">
    <form [formGroup]="hatirlatmaForm">
      <textarea type="text" [placeholder]="'HATIRLATMALAR.ACIKLAMA' | translate" formControlName="note" nbInput
        fullWidth></textarea>
      <div class="uyari" *ngIf="hatirlatmaForm.get('note').touched && hatirlatmaForm.get('note').hasError('required')">

        {{ 'HATIRLATMALAR.UYARI' | translate }}
      </div>

      <input nbInput fullWidth [nbDatepicker]="formpicker" formControlName="gun"
        [placeholder]="'HATIRLATMALAR.TARIHI' | translate">

      <nb-datepicker #formpicker></nb-datepicker>

      <div class="uyari" *ngIf="hatirlatmaForm.get('gun').touched && hatirlatmaForm.get('gun').hasError('required')">

        {{ 'HATIRLATMALAR.UYARI' | translate }}
      </div>

      <div class="24hr-example" style="margin-top: 5px;">
        <input [placeholder]="'HATIRLATMALAR.SAATI' | translate" formControlName="saat" nbInput fullWidth
          aria-label="24hr format" [ngxTimepicker]="fullTime" [format]="24" readonly>
        <div class="uyari"
          *ngIf="hatirlatmaForm.get('saat').touched && hatirlatmaForm.get('saat').hasError('required')">

          {{ 'HATIRLATMALAR.UYARI' | translate }}
        </div>
        <ngx-material-timepicker #fullTime [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn">
        </ngx-material-timepicker>
        <ng-template #cancelBtn>
          <button style="margin-right: 10px;" nbButton status="warning" size="small">
            {{ 'HATIRLATMALAR.IPTAL' | translate }}</button>
        </ng-template>
        <ng-template #confirmBtn>
          <button nbButton status="primary" size="small">
            {{ 'HATIRLATMALAR.TAMAMLA' | translate }}</button>
        </ng-template>
      </div>
    </form>
    <div class="text-center">
      <button nbButton (click)="hatirlatmaEkle()" status="primary">
        {{ 'HATIRLATMALAR.BUTTON' | translate }}</button>
    </div>
  </nb-card-body>
</nb-card>