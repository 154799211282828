import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { YetkiModulModel, SozlesmeYetkiModel } from '../models/yetki.model';
import { environment } from '../../environments/environment';


@Injectable()
export class YetkiService {
    private readonly API_URL = `${environment.backendurl}api/yetki/`;

    constructor(private httpClient: HttpClient) { }
    /**
     * Apas id'si bilinen bir personelin tüm yetki bilgilerini almamızı sağlar.
     * @param apas_id kullanıcının apas_id'si
     */
    getYetkiModuls(apasId: number): Observable<YetkiModulModel[]> {
        const url: string = `${this.API_URL}listmoduls/${apasId}/`;
        return this.httpClient.get<YetkiModulModel[]>(url);
    }
    /**
        * Apas id'si bilinen bir personelin tüm sozlesme yetkilerini bilgilerini almamızı sağlar.
        * @param apas_id kullanıcının apas_id'si
        */
    getSozlesmeYetkiModuls(apasId: number): Observable<SozlesmeYetkiModel[]> {
        const url: string = `${this.API_URL}sozlesmeyetki/list/${apasId}/`;
        return this.httpClient.get<SozlesmeYetkiModel[]>(url);
    }
    /**
   * Kullanıcıya yetki veririr.
   * @param yetki_info  YetkiModulModel cinsinden değer alır.
   */
    giveYetkiToSozlesme(yetki_info): Observable<SozlesmeYetkiModel> {
        const url = `${this.API_URL}sozlesmeyetki/add/`;
        return this.httpClient.post<SozlesmeYetkiModel>(url, yetki_info);
    }


    /**
     * Kullanıcıya yetki veririr.
     * @param yetki_info  YetkiModulModel cinsinden değer alır.
     */
    giveYetkiToPersonel(data): Observable<YetkiModulModel> {
        const url = `${this.API_URL}kullaniciyetki/add/`;
        return this.httpClient.post<YetkiModulModel>(url, data);
    }
    /**
     * Belirtilen apas_id'li personele tüm yekileri kazandırır.
     * @param apas_id yetkilendirilecek kişinin apas_id'si.
     */
    giveAll(apasId): Observable<YetkiModulModel> {
        const data = { apas_id: apasId };
        const url = `${this.API_URL}giveall/`;
        return this.httpClient.post<YetkiModulModel>(url, data);
    }

}

