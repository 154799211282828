import { createSelector } from '@ngrx/store';
import OrgInfoModel from '../../models/orgInfo.model';
import * as fromRoot from '../../app-state';

const getOrgInfoFeatureState = createSelector(
        fromRoot.getOrganizationSelector, (state: fromRoot.OrganizationState) => state?.Organizasyon,
);

const getIsLoaded = createSelector(
        getOrgInfoFeatureState,
        (state: OrgInfoModel) => state.isLoaded,
);

const getOrgInfo = createSelector(
        getOrgInfoFeatureState,
        (state: OrgInfoModel) => state,
);

const selectUyari = createSelector(
        getOrgInfoFeatureState,
        (state: OrgInfoModel) => state.uyariHide,
);

export { getIsLoaded, getOrgInfo, selectUyari };
