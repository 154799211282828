import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { KullaniciLogoutComponent } from './kullanici/components/kullanici-logout/kullanici-logout.component';
import { AuthGuard, PaymentGuard } from './_auth/auth.guard';
import { KullanimSartlariComponent } from './kullanim-sartlari/kullanim-sartlari.component';

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
   canActivate: [AuthGuard, PaymentGuard],
  },
  {
    path: 'profil',
    loadChildren: () => import('./profil/profil.module').then(m => m.ProfilModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'first',
    loadChildren: () => import('./home/home.module')
      .then(m => m.HomeModule),
  },
  {
    path: 'access',
    loadChildren: () => import('./access/access.module')
      .then(m => m.AccessModule),
  },
  {
    path: 'kullanim-sartlari',
    component: KullanimSartlariComponent,
  },
  {
    path: 'karsilama',
    loadChildren: () => import('./karsilama/karsilama.module').then(m => m.KarsilamaModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'logout',
    component: KullaniciLogoutComponent,
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: 'first', pathMatch: 'full' },
  { path: '**', redirectTo: 'first' },
];

const config: ExtraOptions = {
    useHash: false,
    relativeLinkResolution: 'legacy'
}
// {
//     useHash: false,
//     relativeLinkResolution: 'legacy'
// };

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
