<ng-content>

</ng-content>
<!-- <div class="text-center" *ngIf="rows?.length === 0 && !isLoaiding">
     <nb-icon icon="alert-circle-outline"></nb-icon> 
    {{options.message?options.message:'Veri Bulunamadı.'}}
</div> -->

<div #anchor class="row" [style]="isEnd ? 'display: none;' : ' display: flex;'">
    <div class="col-md text-center"  *ngIf="isLoaiding">
        <div class="spinner-border" role="status"></div>
        <div class="col" >{{'INFINITE_SCROLL_COMPONENT.YUKLENIYOR'| translate}}</div>
    </div>
</div>