<span [ngSwitch]="status">
    <span *ngSwitchCase="faturaCurrentStatus.gonderildi" class="p-0 badge aps-success">
        {{status | translate}}
    </span>
    <span *ngSwitchCase="faturaCurrentStatus.gonderiliyor" class="p-0 badge aps-warning">
        {{status | translate}}
    </span>

    <span *ngSwitchCase="faturaCurrentStatus.gonderilmedi" class="p-0 badge aps-warning">
        {{status | translate}}
    </span>
    <span *ngSwitchCase="faturaCurrentStatus.iptal_edildi" class="p-0 badge aps-danger">{{status | translate}}</span>
    <span *ngSwitchCase="faturaCurrentStatus.beklemede" class="p-0 badge aps-info">{{status | translate}}</span>
    <span *ngSwitchCase="faturaCurrentStatus.cevap_verilmedi" class="p-0 badge aps-warning">{{status | translate}}</span>
    <span *ngSwitchCase="faturaCurrentStatus.kabul_edildi" class="p-0 badge aps-success">{{status | translate}}</span>
    <span *ngSwitchCase="faturaCurrentStatus.reddedili" class="p-0 badge aps-danger">{{status | translate}}</span>

    <span *ngSwitchCase="faturaCurrentStatus.tamamlandi" class="p-0 badge aps-success">
        {{status | translate}}
    </span>
</span>

