<nb-card style="min-height: 400px;" status="info" style="border: unset;">
    <nb-card-header >
        <span>Personel (Kullanıcı) Davet Et</span>

        <span style="float: right;">
            <button nbButton (click)="close()" size="tiny" class="m-close" style="background: none !important;
            color: white !important;
            border: none;
            float: right;
            font-size: 18px;">X</button>

        </span>
    </nb-card-header>

    <nb-card-body [formGroup]="form">
        <div formArrayName="personels" *ngFor="let subProducts of personels.controls; let i = index ;">

            <div class="row" [formGroupName]="i">
                <div class="col-11">
                    <label class="form-label label"> Telefon</label>
                    <apas-telefon-input fullWidth formControlName="phone" [labelShow]="false">
                    </apas-telefon-input>
                </div>
                <div class="col-1 pr-2 d-flex justify-content-end">
                    <b style="margin-top: auto;">
                        <button nbButton status="danger" size="small" (click)="removeFormItem(i)">
                            <nb-icon icon="minus-outline" style="color: white; font-weight: bold;"></nb-icon>
                        </button>
                    </b>
                </div>
            </div>
        </div>
        <div class=" row ">
            <div class="col pr-2 d-flex justify-content-end">
                <b>
                    <button nbButton status="primary" size="small" (click)="addFormItem()">
                        <nb-icon icon="plus-outline"></nb-icon>
                    </button>
                </b>
            </div>

        </div>
    </nb-card-body>

    <nb-card-footer class="d-flex justify-content-center">
        <b>
            <button nbButton status="primary" style="margin-left: auto; margin-right: auto;" (click)="postForm()">Davet Et</button>
        </b>
    </nb-card-footer>
</nb-card>