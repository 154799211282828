import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ImageModel } from '../models/image.model';
import { environment } from '../../environments/environment';


@Injectable()
export class ApasImageUplaoder {
    private readonly API_URL = `${environment.backendurl}api/`;

    constructor(private httpClient: HttpClient) { }


    /**
     * Suncuya resm yükleyen fonksiyon
     * @param url api/ den sonraki upload url'si
     * @param image ImageModel cinsinden sunucuya yüklenecek data
     */
    postImage(url: string, image: ImageModel) {
        url = this.API_URL + url;
        return this.httpClient.post(url, image);
    }
    /**
     * Suncuya resm yükleyen fonksiyon
     * @param url api/ den sonraki upload url'si
     * @param image string cinsinden sunucuya yüklenecek data
     */
    patchImage(url: string, image: string) {
        const _image = { image: image };
        url = this.API_URL + url;
        return this.httpClient.patch(url, _image);
    }
}
