import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApasDecryptePipe } from '../../../directives/apas-decrypte-pipe';
import { fadeInOut } from '../../../pages/animations';
import { formatNumberWithCurrency } from '../../../pages/global-functions';


@Component({
  selector: 'apas-para-item',
  templateUrl: './apas-para-item.component.html',
  styleUrls: ['./apas-para-item.component.scss'],
  providers: [ApasDecryptePipe],
  animations: [fadeInOut],
})
export class ApasParaItemComponent implements OnInit, OnChanges {
  @Input() value: any;
  ekranValue;
  @Input() decrypt: boolean = false;
  @Input() negative: boolean = true;
  @Input() abs: any;
  @Input() currency: any;
  @Input() alacak: boolean = false; // Paranın Yanına (-) ise (A) (+) ise (B) değeri atar.
  @Input() log: boolean = false;
  @Input() decimalPlaces: number = 2; // Virgülden sonra kaç hane yazılacak.
  addToEnd: string;
  binlikAyrac = '.';
  constructor(private apasDecryptPipe: ApasDecryptePipe) { }

  colorClass = 'clearColor';

  green = '#689f38';
  red = '#e62452';

  ngOnInit(): void {
    this.setDotsToValue();
  }

  oldValue;
  @Input() animate: boolean = false;
  animateColor: string = this.green;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      if (this.ekranValue) {
        this.oldValue = JSON.parse(JSON.stringify(this.ekranValue));
      }
      this.setDotsToValue();
    }
  }

  @Input() setColor: boolean = false; // Negatif Para miktarlarını kırmızı, pozitifleri yeşil boya
  color: string;

  setDotsToValue() {
    if (this.value !== null || this.value !== undefined || this.value !== NaN) {
      if (this.decrypt) {
        this.ekranValue = this.apasDecryptPipe.transform(this.value);
      } else {
        this.ekranValue = this.value;
      }

      this.ekranValue = Number(this.ekranValue);

      if (this.setColor) {
        if (this.ekranValue > 0) {
          this.color = this.green;
          this.colorClass = 'positiveMoney';
        // tslint:disable-next-line: triple-equals
        } else if (this.ekranValue == 0) {
          this.colorClass = 'clearColor';
          this.color = 'unset';
        } else {
          this.color = this.red;
          this.colorClass = 'negativeMoney';
        }
      }

      if (!this.negative) {
        if (this.alacak) {
          if (this.ekranValue > 0) {
            this.addToEnd = '(A)';
          // tslint:disable-next-line: triple-equals
          } else if (this.ekranValue == 0) {
            this.addToEnd = '(-)';
          } else {
            this.addToEnd = '(B)';
          }
        }
        this.ekranValue = Math.abs(this.ekranValue);
      }

      if (this.oldValue) {
        if (this.ekranValue < this.oldValue) {
          this.animateColor = this.green;
        } else {
          this.animateColor = this.red;
        }
      }

      this.ekranValue = formatNumberWithCurrency(this.ekranValue, this.currency, this.decimalPlaces);

    } else {
      this.ekranValue = null;
    }
  }

  logTo(value) {
    if (this.log) {
      console.log(value);
    }
  }
}
