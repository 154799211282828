import {
  Component,
  EventEmitter,
  HostListener, OnInit, Output, ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NbDialogRef } from '@nebular/theme';
import { ImageModel } from '../../../models/image.model';
import { tarih_format } from '../../../pages/constants';
import { ApasImageLightBoxService } from '../../../services/apas-image-lightbox.service';
import { ApasImageLightboxInfo } from '../model/apas-image-lightbox.model';

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
}
@Component({
  selector: 'apas-image-lightbox',
  templateUrl: './apas-image-lightbox.component.html',
  styleUrls: ['./apas-image-lightbox.component.css'],
  providers: [ApasImageLightBoxService],
})
export class ApasImageLightboxComponent implements OnInit {
  editMod: boolean = false;
  info: ImageModel;
  tarih_format = tarih_format;
  url: number;
  updateUrl: string;
  single: boolean;
  image: string;
  img;
  isLoading: boolean = false;
  santiyeId: number;
  imageIndex: number;
  groupIndex: number;
  rows: any[] = [];
  currentImageIndex: number = 0;
  currentgroupIndex: number = 0;
  currentImageId: number = 0;

  canNext: boolean = true;
  canPrevius: boolean = true;
  method: string = 'get';
  /**
   * Sonraki buronuna tıklandığında çağırılır.
   */
  @Output() nextEvent: EventEmitter<ApasImageLightboxComponent> = new EventEmitter();
  /**
   * Önceki buronuna tıklandığında çağırılır.
   */
  @Output() previousEvent: EventEmitter<ApasImageLightboxComponent> = new EventEmitter();

  /**
 * Resim sunucudan başarılı birşekilde indirilirse tetiklenir.
 */
  @Output() onSuccessLoad: EventEmitter<ApasImageLightboxComponent> = new EventEmitter();
  tag: string = 'ApasImageLightboxComponent --->';

  constructor(private domSanitizer: DomSanitizer,
    private ref: NbDialogRef<ApasImageLightboxComponent>,
    private apasImageLightBoxService: ApasImageLightBoxService) { }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {

    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.next();
    }

    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.previous();
    }
  }
  ngOnInit() {
    this.currentImageIndex = this.imageIndex - 1;
    this.currentgroupIndex = this.groupIndex;

    const currentUrl: string = this.getNextImage();
    if (this.image) {
      this.img = this.domSanitizer.bypassSecurityTrustUrl(this.image);
    }

    this.showImage(currentUrl);
  }
  images: any = {};
  infos: any = {};
  showImage(currentUrl: string) {
    if (currentUrl) {
      this.isLoading = true;
      if (this.images[this.currentImageId]) {
        this.img = this.images[this.currentImageId];
        this.info = this.infos[this.currentImageId];
        this.isLoading = false;
      } else {
        this.apasImageLightBoxService.getImageDetay(currentUrl, this.method).subscribe((responsImage: ImageModel) => {
          this.images[this.currentImageId] = this.domSanitizer.bypassSecurityTrustUrl(responsImage.image);

          setTimeout(() => {
            this.infos[this.currentImageId] = responsImage;
            this.info = responsImage;
            this.img = this.images[responsImage.id];
            this.isLoading = false;
          }, 1000);

          this.onSuccessLoad.emit(this);
        });
      }
    } else {
      throw new Error('Gönderdiğinz url hatalı!');
    }
  }


  getImage() {

  }
  closed() {
    this.ref.close();
  }
  getNextImage(): string {
    this.currentImageIndex++;
    // eğer gurubun son resminide göstermiş ise bi üst guruba geç
    if (this.currentImageIndex > this.rows[this.currentgroupIndex].images.length - 1) {
      this.currentgroupIndex++;
      this.currentImageIndex = 0;
    }

    if ((this.currentgroupIndex >= this.rows.length - 1) &&
      (this.currentImageIndex >= this.rows[this.currentgroupIndex].images.length - 1)) {
      this.canNext = false;
    }
    this.canPrevius = true;
    if (this.currentgroupIndex === 0 && this.currentImageIndex <= 0) {
      this.canPrevius = false;

    }

    this.currentImageId = this.rows[this.currentgroupIndex].images[this.currentImageIndex].id;
    let url: string = `${this.url}${this.santiyeId}/${this.currentImageId}/`;
    if (this.single){
      url= `${this.url}`;
      return url;
    }
    if (this.santiyeId) {
      url = `${this.url}${this.santiyeId}/${this.currentImageId}/`;
    }else {
      url = `${this.url}/${this.currentImageId}/`;
    }
    return url;
  }
  getPreviusImage(): string {
    
    this.currentImageIndex--;
    // eğer gurubun son resminide göstermiş ise bi üst guruba geç
    if (this.currentImageIndex < 0) {
      this.currentgroupIndex--;
      if (this.currentgroupIndex > 0) {
        this.currentImageIndex = this.rows[this.currentgroupIndex].images.length - 1;
        if ((this.currentgroupIndex === 0) && (this.currentImageIndex === 0)) {
          this.canPrevius = false;
        }
      } else {
        this.currentgroupIndex = 0;
        this.currentImageIndex = 0;
        this.canPrevius = false;
      }
    }

    this.canNext = true;

    this.currentImageId = this.rows[this.currentgroupIndex].images[this.currentImageIndex].id;
    const url: string = `${this.url}${this.santiyeId}/${this.currentImageId}/`;
    return url;
  }

  /**
   * Sonraik butonuna tıklandığında yapılacaklar.
   */
  next() {
    if (this.canNext) {
      this.showImage(this.getNextImage());
      this.nextEvent.emit(this);
    }
  }
  /**
 * Önceki butonuna tıklandığında yapılacaklar.
 */
  previous() {
    if (this.canPrevius) {
      this.showImage(this.getPreviusImage());
      this.previousEvent.emit(this);
    }
  }
  /**
* Resim sunucudan başarılı birşekilde indirilirse tetiklenir.
*/
  onSuccess() {
    this.onSuccessLoad.emit(this);
  }
}
