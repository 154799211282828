import { Component, OnInit } from '@angular/core';
import { TaseronModel } from '../../../models/taseron.model';
import { TaseronPersonelModel } from '../../../models/taseron_personel.model';
import { FormArray, FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { TaseronService } from '../../../services/taseron.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'personel-ekle',
  templateUrl: './personel-ekle.component.html',
  styleUrls: ['./personel-ekle.component.css'],
})
export class PersonelEkleComponent implements OnInit {

  tag: string = 'Personel ekle ';
  // Taşeron Modeli
  taseron: TaseronModel;
  // Bu sayfaya nerden gelidigimizi bilidirir.
  isTaseron: boolean = true;
  // Personel Modeli
  personel_list: TaseronPersonelModel[];
  personels: FormArray;


  /**
   * Personel Ekleme ile ilgili form elementleri
   */
  personelEkleForm: FormGroup;
  personelListesi: FormArray;


  taseronPage: any;

  statu_list = [
    {
      label: 'Yönetici',
      value: 1,
    },
    {
      label: 'Mühendis',
      value: 2,
    },
    {
      label: 'Usta',
      value: 3,
    },
    {
      label: 'Kalfa',
      value: 4,
    },
    {
      label: 'İşçi',
      value: 5,
    },
  ];



  constructor(
    private formBuilder: FormBuilder,
    private taseronService: TaseronService,
    private ref: NbDialogRef<PersonelEkleComponent>,
  ) { }

  closed() {
    this.ref.close();
  }

  ngOnInit() {
    

    this.personelEkleForm = this.formBuilder.group({
      personel: this.formBuilder.array([]),
    });
    this.personelFormEkle();

  }

  personelFormSil() {
    const personelListesi = this.personelEkleForm.controls.personel as FormArray;
    const sonIndex = personelListesi.length - 1;
    if (sonIndex >= 1) {
      personelListesi.removeAt(sonIndex);
    }
  }

  personelFormEkle() {
    const personelListesi = this.personelEkleForm.controls.personel as FormArray;
    personelListesi.push(this.formBuilder.group({
      taseron_id: new FormControl(this.taseron.id),
      name: new FormControl('', Validators.required),
      tc_kimlik_no: new FormControl(),
      telefon: new FormControl(),
      statu: new FormControl(),
    }));

  }

  get personelForm() {
    return (<FormArray>this.personelEkleForm.get('personel')).controls;
  }

  personelEklemeKaydet() {

    const personelList = this.personelEkleForm.controls.personel as FormArray;
    this.personelEkleForm.markAllAsTouched();
    if (this.personelEkleForm.valid) {
      this.taseronService.addTaseronPersonel(personelList.value, this.taseron.id).subscribe(data => {
        Swal.fire({
          icon: 'success',
          title: 'Kayıt Başarılı',
          showConfirmButton: false,
          timer: 1500,
        });
        this.personelEkleForm.reset();
      });
    }
  }

}
