import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  OnDestroy,
} from '@angular/core';
import { NbDialogRef, NbDialogService, NbThemeService } from '@nebular/theme';
import { tarih_format } from '../../../pages/constants';
import { HatirlatmaEkleComponent } from '../../hatirlatmalar/hatirlatma-ekle/hatirlatma-ekle.component';
import { HatirlatmaDetayComponent } from '../../hatirlatmalar/hatirlatma-detay/hatirlatma-detay.component';
import { Router } from '@angular/router';
import { PieChartComponent } from '@swimlane/ngx-charts';

@Component({
  selector: 'takvim-detay',
  templateUrl: './takvim-detay.component.html',
  styleUrls: ['./takvim-detay.component.css'],
})
export class TakvimDetayComponent implements OnInit, OnDestroy {
  @ViewChildren(PieChartComponent) charts: QueryList<PieChartComponent>;
  tarih_format = tarih_format;
  takvimTarih;
  takvimDetay;
  length: number = 30;

  // options
  gradient: boolean = false;
  showLegend: boolean = false;
  showLabels: boolean = false;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';
  tamamlananColor = '#7aa3e5';
  ongorulenColor = '#a95963';
  bekleyenColor = '#aae3f5';
  colorScheme = {
    domain: [this.bekleyenColor, this.ongorulenColor, this.tamamlananColor, '#AAAAAA'],
  };

  constructor(
    private ref: NbDialogRef<TakvimDetayComponent>,
    private _nbDialogService: NbDialogService,
    private router: Router,
    private theme: NbThemeService,
  ) {}
  ngOnDestroy(): void {}

  ngOnInit() {
    
    
    this.takvimDetay.forEach((element) => {
      if (element.type === 4) {
        element.completed = Number((element.completed).toFixed(2));
        element.should_completed = Number((element.should_completed).toFixed(2));
        const tamamlanan = element.completed;
        let ongorulen = element.should_completed;
        const tamami = 100 - ongorulen;
        ongorulen = ongorulen - tamamlanan;
        // Tamamı 100 - tamamlanan 30 - öngörünel 70
        // ongorulen = ongululen - tamamlanan // 40
        element.data = [
          {
            name: 'Tamamı',
            value: tamami,
          },
          {
            name: 'Öngörülen',
            value: ongorulen,
          },
          {
            name: 'Tamamlanan',
            value: tamamlanan,
          },
        ];
      }
    });
  }

  closed() {
    this.ref.close();
  }


  hatirlatmaEkle() {
    const context: {} = {
      tarihTakvim: this.takvimTarih,
    };

    this._nbDialogService.open(HatirlatmaEkleComponent, { context });
  }

  detay(item) {
    

    switch (item.type) { 
      case 1:
        this._nbDialogService.open(HatirlatmaDetayComponent, {
          context: {
            hatirlatmaID: item.item_id,
          },
        });
        break;

      case 2:
        this.router.navigate([`pages/toplanti/toplanti-detay/${item.item_id}`]);
        this.ref.close();
        break;
      default:
        break;
    }
  }
}
