import { HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import * as moment from 'moment';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { ApasDecryptePipe } from '../../../../../../directives/apas-decrypte-pipe';
import { ApasEncryptePipe } from '../../../../../../directives/apas-encrypte-pipe';
import { CariHareketModel } from '../../../../models/cari_hakeret.model';
import { acceptFileFormats, cariHareketTypes, responseKodlari } from '../../../../../../pages/constants';
import { downLoadFile, removeAllNull } from '../../../../../../pages/global-functions';
import { MuhasebeService } from '../../../../muhasebe-service/muhasebe.service';
import { Store } from '@ngrx/store';
import * as hesapConnector from '../../../../muhasebe-store/hesaplar/hesap.connector';
@Component({
  selector: 'masraf-ekle',
  templateUrl: './masraf-ekle.component.html',
  styleUrls: ['./masraf-ekle.component.css'],
  providers: [MuhasebeService, ApasEncryptePipe, ApasDecryptePipe],
})
export class MasrafEkleComponent implements OnInit {
  acceptFileFormats = acceptFileFormats;
  form: FormGroup;
  @Input() data: CariHareketModel;
  dataIslem: CariHareketModel;
  editMod: boolean = false;
  account_type = ['BankAccount', 'CaseAccount', 'CrediCartAccount'];
  @Input() kasaId: number;
  kasaInfo;
  constructor(
    private ref: NbDialogRef<MasrafEkleComponent>,
    private muhasebeService: MuhasebeService,
    private fb: FormBuilder,
    private store: Store<{}>,
  ) {
    this.initForm();

  }

  islemlist = [];
  forceRefresh = 0;
  ngOnInit() {
    if (this.kasaId) {
      this.form.controls.sender_account.setValue(this.kasaId);
    }

    if (this.data) {
      this.form.patchValue(this.data);
      this.editMod = true;

      // İşlemler sayfasından geliyorsa, işlem listesi de taşır.
      // Bu liste kayıt sonrasında, işlemleri onaylamak için kullanılır.
      if (this.data['islemlist']) this.islemlist = this.data['islemlist'];

      if (this.data.total_price) {
        this.form.get('total_price').setValue(this.data.total_price);
      }

      if (this.data.date) {
        const date = moment(this.data.date, 'X').toDate();
        this.form.get('date').setValue(date);
      }
      if (!this.data.edit) return;

      this.forceRefresh += 1;

      this.form.controls.sender_account.setValue(this.data?.sender_account);
    }

    // Masraf Ekle İşlemlerden Açılıyorsa
    if (this.dataIslem) {
      this.form.patchValue(this.dataIslem);

      // İşlemler sayfasından geliyorsa, işlem listesi de taşır.
      // Bu liste kayıt sonrasında, işlemleri onaylamak için kullanılır.
      if (this.dataIslem['islemlist']) this.islemlist = this.dataIslem['islemlist'];

      if (this.dataIslem.total_price) {
        this.form.get('total_price').setValue(this.dataIslem.total_price);
        this.forceRefresh += 1;
      }

      if (this.dataIslem.date) {
        const date = moment(this.dataIslem.date, 'X').toDate();
        this.form.get('date').setValue(date);
      }
      this.forceRefresh += 1;

      if (!this.dataIslem.edit) return;

    }
  }

  initForm() {
    this.form = this.fb.group({
      sender_account: [null, Validators.required],
      total_price: [null, Validators.required],
      currency: [null, Validators.required],
      date: [new Date(), Validators.required],
      note: [null, [Validators.maxLength(1024)]],
      hareket_type: [cariHareketTypes.masraf],
      dosya: [],
      tags: [],
    });

    this.form.controls.sender_account.valueChanges.subscribe(kasaid => {
      this.store.select(hesapConnector.selecthesapItem(kasaid)).subscribe(val => {
        this.kasaInfo = val;

        this.form.controls.currency.setValue(this.kasaInfo?.currency);
        console.log('Kasa Select Change : ', this.kasaInfo);

      });
    })
    this.form.controls.currency.valueChanges.subscribe(val => console.log('Currency change : ', val));
  }

  close() {
    this.ref.close();
  }

  selectTag(event) {
    this.form.get('tags').setValue(event);
  }

  updateIslemler() {
    if (this.islemlist) {
      this.islemlist.forEach(elm => {
        elm['hareket_onay'] = true;
      });
      this.muhasebeService.updateIslemHareket(this.islemlist).subscribe(resp => { });
    }
  }


  dosyaGetir(item) {
    if (item.has_dosya) {
      this.muhasebeService.getCariHareketFile(item.id).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              item['percentDone'] = 0;

              item['isDownloading'] = true;
              break;
            case HttpEventType.DownloadProgress:
              item['percentDone'] = Math.round(
                (100 * event.loaded) / event.total,
              );

              break;
            case HttpEventType.Response:
              item['isDownloading'] = false;
              item['dosya'] = event.body.dosya;
              downLoadFile(item.dosya, 'Fatura');
              break;
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
          }
        },
      );
    }
  }
  dosyaSil(row) {
    Swal.fire(responseKodlari.shureToDeleteFile as SweetAlertOptions).then(({ value }) => {
      if (value) {

        this.muhasebeService.deleteCariHareketFile(row.id).subscribe((resp) => {
          Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
          row.dosya = null;
          this.data.has_dosya = false;
        }, (err) => {
          Swal.fire(responseKodlari.unSuccesDeleted as SweetAlertOptions);
        });
      }
    });

  }
  isLoading: boolean = false;
  postForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.isLoading = true;

    const formValue = removeAllNull(this.form.value);
    this.muhasebeService.addCariHareket(formValue).subscribe((resp) => {
      this.isLoading = false;
      this.updateIslemler();
      this.ref.close(resp);
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
    }, err => {
      this.isLoading = false;
    });
  }
  patchForm() {
    this.isLoading = true;
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.isLoading = false;
      return;
    }
    const formValue = removeAllNull(this.form.value);
    this.muhasebeService
      .updateCariHareket(this.data.id, formValue)
      .subscribe((resp) => {
        this.isLoading = false;
        this.ref.close(resp);
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      }, err => {
        this.isLoading = false;
      });
  }
}
