<nb-card class="modalType animated fadeInRight" size="giant">
    <nb-card-header class="d-flex align-items-center justify-content-between">
        <span>
            Başvuru Seçenekleri

        </span>
        <button nbButton (click)="close()" class="heryerdeClose">X</button>
    </nb-card-header>

    <nb-card-body>
        <div class="basvuruYapildi">

            <div class="my-3">
                <span>
                    <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
                </span>
                <h6>Logo e-fatura başvurunuz yapılmıştır.</h6>
            </div>

            <div class="my-3">

                <b>Başvurunuz ile alakalı dönüş aldıysanız ve entegrasyon bilgileriniz elinizde ise
                    <button nbButton status="warning" size="small" (click)="entegrasyonYap()">Giriş yap</button>
                    butonuna tıklayarak giriş yapabilirsiniz.
                </b>

            </div>
            <div class="my-3">

                <b>48 saat içerisinde geri dönüş almadıysanız bize ulaşabilirsiniz.</b>
                <div class="text-center">
                    <b class="text-success">Çağrı Merkezi 0 (850) 227 3191</b>
                </div>
            </div>


        </div>
    </nb-card-body>
</nb-card>