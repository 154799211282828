import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { AkisItem } from '../../../../models/akis.model';
import { MuhasebeAkisService } from '../../muhasebe-service/muhasebe-akis.service';
import * as akisActions from './muhasebe-akis.actions';


@Injectable()
export class MuhasebeAkisEffects {

    loaAkis$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(akisActions.loadMuhasebeAkis),
            mergeMap((props) => this.muhasebeAkisService.getAkis({ page: props.page }).pipe(
                map((akislar: AkisItem[]) => {
                    const isEnd: boolean = akislar.length < 10;
                    
                    akislar.forEach(elm => {
                        if (elm.modelname === 'muhasebe.islemhareket') {
                            elm.is_active = false;
                        } else {
                            elm.is_active = true;
                        }
                    });
                    return (akisActions.loadMuhasebeAkisSuccess({ akisItem: akislar, isEnd: isEnd }));
                }),
                catchError(() => of(akisActions.loadMuhasebeAkisFail('Akış yüklenemedi.'))),
            )),
        );
    });


    constructor(
        private actions$: Actions,
        private muhasebeAkisService: MuhasebeAkisService,
    ) {

    }

}
