import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as tagActions from './tags.actions';
import { TagGroupModel } from '../../models/tag.model';
import { TagService } from '../../services/tags.service';
@Injectable()
export class TagEffects {

        loadTag$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(tagActions.loadTag),
            mergeMap(() => this.tagService.getTagList().pipe(
                    map((taglar: TagGroupModel[]) => {
                        return (tagActions.loadTagSuccess({ TagItem: taglar }));
                    }),
                    catchError(() => of(tagActions.loadTagFail('Taglar yüklenemedi.'))),
                )),
        );
    });

    constructor(
        private actions$: Actions,
        private tagService: TagService,
    ) {

    }
}



