import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgCircleProgressModule } from 'ng-circle-progress'; 
import { NgxEchartsModule } from 'ngx-echarts';
import { NotesModalComponent } from '../components/apas-components/notes-modal/notes-modal.component';
import { ApasAlertComponent } from '../components/Apas-Modules/apas-alert-modul/apas-alert/apas-alert.component';
import { ApasImageLightboxComponent } from '../components/Apas-Modules/apas-image-lightbox/apas-image-lightbox.component';
import { ApasImageUploaderComponent } from '../components/Apas-Modules/apas-image-uploader/apas-image-uploader.component';
import { ApasInfiniteScrollComponent } from '../components/Apas-Modules/infinite-scroll/infinite-scroll.component';
import { UrunEkleComponent } from '../components/depolar/urun-ekle/urun-ekle.component';
import { FirmaEkleComponent } from '../components/firma/firma-ekle/firma-ekle.component';
import { HatirlatmaDetayComponent } from '../components/hatirlatmalar/hatirlatma-detay/hatirlatma-detay.component';
import { HatirlatmaEkleComponent } from '../components/hatirlatmalar/hatirlatma-ekle/hatirlatma-ekle.component';
import { HatirlatmaInfinitComponent } from '../components/hatirlatmalar/hatirlatma-list/hatirlatma-infinit/hatirlatma-infinit.component';
import { HatirlatmaListComponent } from '../components/hatirlatmalar/hatirlatma-list/hatirlatma-list.component';
import { PersonelEkleComponent } from '../components/personel/personel-ekle/personel-ekle.component';
import { PersonelGrubuOlusturComponent } from '../components/personel/personel-grubu-olustur/personel-grubu-olustur.component';
import { PersonelListComponent } from '../components/personel/personel-list/personel-list.component';
import { KullaniciTakvimComponent } from '../components/takvim/kullanici-takvim/kullanici-takvim.component';
import { TakvimDetayComponent } from '../components/takvim/takvim-detay/takvim-detay.component';
import { YardimListComponent } from '../components/yardim/yardim-list/yardim-list.component';
import { ApasInvalidDirective } from '../directives/apas-invalid.directive';
import { ApasRoundPipeDirective } from '../directives/apas-round-pipe.directive';
import { ApasRoundDirective } from '../directives/apas-round.directive';
import { PhoneMaskDirective } from '../directives/phone-mask.directive';
import { IzypayPaymentModalComponent } from '../profil/firma-profil-main/payment/izypay-payment-modal/izypay-payment-modal.component';
import { ProfilPhotoComponent } from '../profil/kullanici-profil-main/profil-photo/profil-photo.component';
import { CalendarHeaderComponent } from './calender-utils/calender-header.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartModule } from 'angular2-chartjs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TagsComponent } from '../components/apas-components/tags/tags.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ApasEncryptePipe } from '../directives/apas-encrypte-pipe';
import { NotesListComponent } from '../components/apas-components/notes-list/notes-list.component';
import { TagInputComponent } from '../components/apas-components/tag-input/tag-input.component';
import { TagItemComponent } from '../components/apas-components/tag-item/tag-item.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxIbanModule } from 'ngx-iban';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PersonelEkleFirmaComponent } from '../components/personel/personel-ekle-firma/personel-ekle-firma.component';
import { ApasFilesComponent } from '../components/apas-components/apas-files/apas-files.component';
import { FileSizePipe } from '../@theme/pipes/filesize.pipe';
import { EmptylistComponent } from '../components/apas-components/emptylist/emptylist.component';
import { PrepareComponent } from '../components/apas-components/prepare/prepare.component';
import { EmptyselectComponent } from '../components/apas-components/emptyselect/emptyselect.component';
import * as echarts from 'echarts';
import { ApasParaItemComponent } from '../components/apas-components/apas-para-item/apas-para-item.component';
import { ParaNumberDirective } from '../directives/para-directive';
import { ApasTelefonInputComponent } from '../components/apas-components/apas-telefon-input/apas-telefon-input.component';
import { ApasDecryptePipe } from '../directives/apas-decrypte-pipe';
import { ApasSearchComponent } from '../components/apas-components/apas-search/apas-search.component';
import { PersonelMainComponent } from '../components/personel/personel-main/personel-main.component';
import { ApasMonthPickerModule } from '../components/Apas-Modules/apas-month-picker/apas-month-picker.module';
import { PlusLoadingComponent } from '../components/apas-components/plus-loading/plus-loading.component';
import { PersonelInviteComponent } from '../components/personel/personel-invite/personel-invite';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  NbRouteTabsetModule,
  NbUserModule,
  NbActionsModule,
  NbRadioModule,
  NbSelectModule,
  NbCalendarModule,
  NbAutocompleteModule,
  NbListModule,
  NbIconModule,
  NbTreeGridModule,
  NbDatepickerModule,
  NbProgressBarModule,
  NbAccordionModule,
  NbInputModule,
  NbDialogModule,
  NbCheckboxModule,
  NbCardModule,
  NbToastrModule,
  NbTabsetModule,
  NbSpinnerModule,
  NbButtonModule,
  NbPopoverModule,
  NbToggleModule,
  NbTooltipModule,
  NbCalendarKitModule,
  NbAlertModule,
  NbStepperModule,
  NbSidebarModule,
  NbMenuModule,
  NbWindowModule,
  NbLayoutModule,
  NbSearchModule,
  NbContextMenuModule,
  NbMenuService,
  NbBadgeModule,
  NbTimepickerModule,
  NbButtonGroupModule,
  NbFormFieldModule,
} from '@nebular/theme';
import { ApasFilterComponent } from '../components/apas-components/apas-filter/apas-filter.component';
import { LoadinglistComponent } from '../components/apas-components/loadinglist/loadinglist.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DateOnlyDirective } from '../directives/date-only.directive';
import { SafePipe } from '../@theme/pipes/safe.pipe';
import { FirmaBilgiGuncelleComponent } from '../profil/firma-profil-main/firma-bilgi-guncelle/firma-bilgi-guncelle.component';
import { EFaturaGirisComponent } from '../pages/muhasebe/e-fatura-sureci/e-fatura-giris/e-fatura-giris.component';
import { PasswordInputComponent } from '../components/apas-components/password-input/password-input.component';
import { DosyaEkleInputComponent } from '../components/apas-components/dosya-ekle-input/dosya-ekle-input.component';
import { FilePreviewerComponent } from '../components/apas-components/file-preview-component/file-previewer.component';
import { ApasFilesUploadComponent } from '../components/apas-components/apas-files/apas-files-upload/apas-files-upload.component';
import { DepoEkleComponent } from '../components/depolar/depo-ekle/depo-ekle.component';
import { DepoMainComponent } from '../components/depolar/depo-main/depo-main.component';
import { UrunGirisiYapComponent } from '../components/depolar/depo-main/urun-girisi-yap/urun-girisi-yap.component';
import { UrunHareketEditComponent } from '../components/depolar/urun-hareketler/urun-hareket-edit/urun-hareket-edit.component';
import { UrunHareketlerComponent } from '../components/depolar/urun-hareketler/urun-hareketler.component';
import { MutabakatListComponent } from '../components/mutabakat/mutabakat-list/mutabakat-list.component';
import { MutabakatSatirEkleComponent } from '../components/mutabakat/mutabakat-satir-ekle/mutabakat-satir-ekle.component';
import { GelenTekliflerListComponent } from '../components/teklifler/gelen-teklifler-list/gelen-teklifler-list.component';
import { DigerFirmaPersoneComponent } from '../components/apas-components/personel-ekle/personel-ekle.component';
import { ApasAnimatedDirective } from '../directives/apas-animated';
import { AppInterceptor } from './app-interceptor';
import { BasvuruBilgisiComponent } from '../pages/muhasebe/e-fatura-sureci/basvuru-bilgisi/basvuru-bilgisi.component';
import { BasvuruSecenekleriComponent } from '../pages/muhasebe/e-fatura-sureci/basvuru-secenekleri/basvuru-secenekleri.component';
import { EfaturaSettingsComponent } from '../pages/muhasebe/entegrasyon/logo/efatura-settings/efatura-settings.component';
import { AyarlarComponent } from '../pages/muhasebe/ayarlar/ayarlar.component';
import { NumberOnlyDirective } from '../directives/apas-only-number.directive';
import { FaturaStatusComponent } from '../pages/muhasebe/entegrasyon/fatura-status/fatura-status.component';
import { MoneyCurrencyInputComponent } from '../components/apas-components/money-currency-input/money-currency-input.component';
import { XOrganizationSelectComponent } from '../pages/muhasebe/muhasebe-shared/apasx-organization-select/apasx-organization-select';
import { DepoSelectComponent } from '../components/select-componentler/depo-select/depo-select';
import { JobSelectComponent } from '../components/select-componentler/job-select/job-select.component';
import { MarkaSelectComponent } from '../components/select-componentler/marka-select/marka-select.component';
import { PersonelSelectComponent } from '../components/select-componentler/personel-select/personel-select.component';
import { ApasProductComponent } from '../components/select-componentler/product-select/apas-product-component.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { TarihColComponent } from '../components/apas-components/tarih-col/tarih-col.component';
import { CariSelectComponent } from '../pages/muhasebe/muhasebe-main/muhasebe-cari/cari-select/cari-select.component';
import { HesapSelectComponent } from '../pages/muhasebe/muhasebe-main/muhasebe-hesaplar/hesap-select/hesap-select.component';
import { MasrafEkleComponent } from '../pages/muhasebe/muhasebe-main/muhasebe-masraflar/modallar/masraf-ekle/masraf-ekle.component';
import { ApasCalendarComponent } from '../components/Apas-Modules/apas-calendar/apas-calendar.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller'; 
import { DateRangeComponent } from './date-range/date-range.component';
import { CheckListMainComponent } from '../components/check-list/check-list-main/check-list-main.component';
import { CheckListTemplateComponent } from '../components/check-list/check-list-template/check-list-template.component';
import { CheckDuzenleComponent } from '../components/check-list/check-duzenle/check-duzenle.component';


export function playerFactory() {
  return player;
}

const selectComponents = [
  CariSelectComponent,
  HesapSelectComponent,
  XOrganizationSelectComponent,
  DepoSelectComponent,
  JobSelectComponent,
  MarkaSelectComponent,
  PersonelSelectComponent,
  ApasProductComponent,
  DateRangeComponent
]

const apasEntryComponent = [
  DigerFirmaPersoneComponent,
  ApasFilesUploadComponent,
  FirmaBilgiGuncelleComponent,
  FilePreviewerComponent,
  ApasImageLightboxComponent,
  NotesModalComponent,
  NotesListComponent,
  IzypayPaymentModalComponent,
  YardimListComponent,
  ApasFilesComponent,
  CheckListMainComponent,
  CheckListTemplateComponent,
  CheckDuzenleComponent,
];

const apasComponent = [
  ...apasEntryComponent,
  ...selectComponents,
  FaturaStatusComponent,
  DosyaEkleInputComponent,
  PasswordInputComponent,
  SafePipe,
  DateOnlyDirective,
  NumberOnlyDirective,
  ApasInvalidDirective,
  ApasAnimatedDirective,
  ApasRoundDirective,
  ApasRoundPipeDirective,
  ParaNumberDirective,
  ApasTelefonInputComponent,
  ApasEncryptePipe,
  LoadinglistComponent,
  ApasDecryptePipe,
  ApasAlertComponent,
  PhoneMaskDirective,
  ApasInfiniteScrollComponent,
  ApasImageUploaderComponent,
  ApasSearchComponent,
  FileSizePipe,
  TagsComponent,
  ApasFilterComponent,
  TagItemComponent,
  TagInputComponent,
  EmptylistComponent,
  EmptyselectComponent,
  PlusLoadingComponent,
  PrepareComponent,
  ApasParaItemComponent,
  MoneyCurrencyInputComponent,
  TarihColComponent,
  ApasCalendarComponent,
];


const depoEntryComponent = [
  UrunGirisiYapComponent,
  UrunHareketlerComponent,
  UrunHareketEditComponent,
  DepoEkleComponent,
  UrunEkleComponent,
];
const depoComponent = [
  ...depoEntryComponent,
  DepoMainComponent,
];



const gelenTeklifComponent = [
  GelenTekliflerListComponent,
];

const mutabakatEntry = [
  MutabakatSatirEkleComponent,
];
const mutabakatComponent = [
  ...mutabakatEntry,
  MutabakatListComponent,

];
const personelEntry = [
  PersonelEkleComponent,
  PersonelGrubuOlusturComponent,
  PersonelEkleFirmaComponent,

];

const personelComponent = [
  ...personelEntry,
  PersonelListComponent,
  PersonelMainComponent,
  PersonelInviteComponent,
];

const kullaniciEntry = [
  HatirlatmaDetayComponent,
  HatirlatmaEkleComponent,
  TakvimDetayComponent,
  ProfilPhotoComponent,

];
const kullaniciComponents = [
  ...kullaniciEntry,
  HatirlatmaListComponent,
  CalendarHeaderComponent,
  HatirlatmaInfinitComponent,
  KullaniciTakvimComponent,
];


const firmaEntry = [
  FirmaEkleComponent,
];

const firmaComponent = [
  ...firmaEntry,

];

const entegrasyonEntryComponents = [
  EfaturaSettingsComponent,
  BasvuruSecenekleriComponent,
  BasvuruBilgisiComponent,
  EFaturaGirisComponent,


];
const entegrasyonComponents = [
  ...entegrasyonEntryComponents,
];


const muhasebeEntry = [
  MasrafEkleComponent,
];
const muhasebeComponent = [
  ...muhasebeEntry,
];



const MainEntryComponents = [
  ...apasEntryComponent,
  ...depoEntryComponent,
  ...mutabakatEntry,
  ...personelEntry,
  ...kullaniciEntry,
  ...firmaEntry,
  ...entegrasyonEntryComponents,
  ...muhasebeEntry,
];

const exportNbModules = [
  NbRouteTabsetModule,
  NbUserModule,
  NbActionsModule,
  NbRadioModule,
  NbSelectModule,
  NbCalendarModule,
  NbAutocompleteModule,
  NbListModule,
  NbIconModule,
  NbTreeGridModule,
  NbDatepickerModule,
  NbProgressBarModule,
  NbAccordionModule,
  NbInputModule,
  NbDialogModule,
  NbCheckboxModule,
  NbCardModule,
  NbToastrModule,
  NgCircleProgressModule,
  NbTabsetModule,
  NbSpinnerModule,
  NbButtonModule,
  NbPopoverModule,
  NbToggleModule,
  NbTooltipModule,
  NbCalendarKitModule,
  NbAlertModule,
  NbStepperModule,
  NbSidebarModule,
  NbMenuModule,
  NbWindowModule,
  NbToastrModule,
  NbLayoutModule,
  NbSearchModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbEvaIconsModule,
  NbBadgeModule,
  NbFormFieldModule,
];

const angularCoreModules = [
  ScrollingModule,
  CommonModule,
  HttpClientModule,
  ReactiveFormsModule,
  FormsModule,
  RouterModule,
  DragDropModule,
  VirtualScrollerModule,
];



// Tüm Componentler Burada Birleşir

const MainComponents = [
  ...apasComponent,
  ...depoComponent,
  ...gelenTeklifComponent,
  ...mutabakatComponent,
  ...personelComponent,
  ...kullaniciComponents,
  ...firmaComponent,
  ...entegrasyonComponents,
  ...muhasebeComponent,

];

const NB_MODULES = [
  NbRouteTabsetModule,
  NbButtonGroupModule,
  NbUserModule,
  NbActionsModule,
  NbRadioModule,
  NbSelectModule,
  NbCalendarModule,
  NbAutocompleteModule,
  NbListModule,
  NbIconModule,
  NbTreeGridModule,
  NbDatepickerModule.forRoot(),
  NbProgressBarModule,
  NbAccordionModule,
  NbInputModule,
  NbDialogModule.forRoot(),
  NbCheckboxModule,
  NbCardModule,
  NgCircleProgressModule.forRoot({}),
  NbTabsetModule,
  NbSpinnerModule,
  NbButtonModule,
  NbPopoverModule,
  NbToggleModule,
  NbTooltipModule,
  NbCalendarKitModule,
  NbAlertModule,
  NbStepperModule,
  NbSidebarModule.forRoot(),
  NbMenuModule.forRoot(),
  NbWindowModule.forRoot(),
  NbToastrModule.forRoot(),
  NbLayoutModule,
  NbSearchModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbEvaIconsModule,
  NbBadgeModule,
  NbFormFieldModule,
];

@NgModule({
  declarations: [
    ...MainComponents,
  ],
  imports: [
    ...NB_MODULES,
    TranslateModule,
    CommonModule,
    HttpClientModule,
    NgSelectModule,
    NgxIbanModule,
    NgxEchartsModule.forRoot({ echarts: { init: echarts.init } }),
    NbTimepickerModule.forRoot(),
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    NgxDatatableModule,
    FormsModule,
    RouterModule,
    CommonModule,
    NgxMaterialTimepickerModule.setLocale('tr-TR'),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgxChartsModule,
    ChartModule,
    DragDropModule,

    LottieModule.forRoot({ player: playerFactory }),
    InfiniteScrollModule,
    NgxMaskModule.forRoot(),
    ApasMonthPickerModule.forRoot(),
    ScrollingModule,
    IvyCarouselModule,
  ],
  exports: [
    ...MainComponents,
    ...exportNbModules,
    ...angularCoreModules,
    NgSelectModule,
    NgxIbanModule,
    NgxMaskModule,
    TranslateModule,
    NgxEchartsModule,
    NgxDatatableModule,
    LottieModule,
    NgxMaterialTimepickerModule,
    CalendarModule,
    NgxChartsModule,
    ChartModule,
    InfiniteScrollModule,
    ApasMonthPickerModule,
    IvyCarouselModule,
    NbButtonGroupModule
  ],
  entryComponents: [
    ...MainEntryComponents,


  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
  ],
})
export class SharedModule { }
