import {
    ControlContainer,
    ControlValueAccessor,
    FormControl,
    FormControlDirective,
} from '@angular/forms';
import { AfterViewInit, Injectable, Injector, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';

@Injectable()
export class ControlValueAccessorConnector implements ControlValueAccessor {
    @ViewChild(FormControlDirective, { static: true }) formControlDirective!: FormControlDirective;

    @Input() formControl!: FormControl;

    @Input() formControlName: string;

    get control(): FormControl {
        // 
        if (this.formControl) {
            return this.formControl;
        } else if (this.formControlName) {
            this.formControl = this.controlContainer?.control?.get(this.formControlName) as FormControl;
            return this.formControl;
        }
    }


    get controlContainer() {
        return this.injector.get(ControlContainer);
    }

    constructor(
        private injector: Injector
    ) { }

    registerOnTouched(fn: any): void {
        this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
    }

    registerOnChange(fn: any): void {
        this.formControlDirective?.valueAccessor?.registerOnChange(fn);
    }

    writeValue(obj: any): void {
        this.formControlDirective?.valueAccessor?.writeValue(obj);
    }

    setDisabledState(isDisabled: boolean): void {
        //this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
    }
}
