import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { concat, Observable, of, Subject } from 'rxjs';
import { addOrUpdate, removeAllNull } from '../../../pages/global-functions';
import { TagService } from '../../../services/tags.service';
import { ControlValueAccessorConnector } from '../../../shared/control-value-accessor-connector';
import { TagsComponent } from '../tags/tags.component';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';


@Component({
  selector: 'aps-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TagInputComponent,
      multi: true,
    },
    TagService,
  ],
  // encapsulation: ViewEncapsulation.Emulated,
})
export class TagInputComponent extends ControlValueAccessorConnector implements OnChanges, OnInit {

  @Input() formControl!: FormControl;

  @Input() formControlName: string;


  @ViewChild('tagarea') tagArea: TagsComponent;
  @ViewChild('selectArea') selectArea: NgSelectComponent;

  @Input() tagFilters = [];
  @Input() forceRefresh;
  value;
  model;
  constructor(
    private cd: ChangeDetectorRef,
    private tagService: TagService,
    injector: Injector,
  ) {
    super(injector);
  }
  ngOnInit(): void {
    this.firstList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.forceRefresh) {
      
      this.cd.markForCheck();
    }
  }

  removeTag(item) {
    
    this.tagArea.removeTagItem(item);
  }

  removeTagFrom(item) {
    let controlVal = this.control.value;
    controlVal = controlVal.filter((x) => x.id !== item.id);
    this.control.setValue(controlVal);
  }

  addTag(event) {

    /**
     * Form Control'e yeni tag elemanı ekler.
     * @param event : tag nesnesinin kendinisi alır (içinde id, title, color vb bilgiler içeriri)
    **/


    if (event?.id) {
      let controlVal = this.control.value ? this.control.value : [];
      controlVal = addOrUpdate(controlVal, event);
      this.control.setValue(controlVal);
      this.writeValue(controlVal);

      
      this.selectArea.clearModel();
      this.selectArea.writeValue(null);
    }


  }


  @ViewChild('mainArea', { read: ElementRef })
  mainArea: ElementRef;
  @ViewChild('childArea1', { read: ElementRef })
  childArea1: ElementRef;
  @ViewChild('childArea2', { read: ElementRef })
  childArea2: ElementRef;
  @ViewChild('textArea', { read: ElementRef })
  textArea: ElementRef;
  @ViewChild('iconArea', { read: ElementRef })
  iconArea: ElementRef;



  @Input() bindValue: string;
  @Input() bindLabel: string = 'title';

  showtags: boolean = false;
  kucukMu: boolean = false;


  changeViewArea(hg) {
    if (hg < 60) {
      this.kucukMu = true;
    } else {
      this.kucukMu = false;
    }
  }

  onResized(event) {
    this.changeViewArea(event.newHeight);
  }


  etiketleriGoster(bu: boolean) {
    if (bu) {
      this.showtags = true;
    } else {
      this.showtags = false;
    }
    this.cd.markForCheck();

  }
  isLoading: boolean = false;

  isLoadingTag = false;
  tagList$: Observable<any[]>;
  tagInput$ = new Subject<string>();


  getTagListe() {
    this.tagList$ = concat(
      of([]), // default items
      this.tagInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.isLoadingTag = true),
        switchMap(term =>
          this.tagService.getTagSerarch(
            removeAllNull({ search: term }),
          ).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.isLoadingTag = false),
          ),
        ),
      ),
    );
  }

  private firstList() {
    this.getTagListe();
    setTimeout(() => {
      this.tagInput$.next('');
    }, 300);
  }

  addTagPromise = (title) => {
    
    return this.tagService.addTagItem({ title: title }).subscribe(resp => {
      this.addTag(resp);
    });
  }

}
