import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'apas-search',
  templateUrl: './apas-search.component.html',
  styleUrls: ['./apas-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ApasSearchComponent),
      multi: true
    }
  ],
})
export class ApasSearchComponent implements  ControlValueAccessor, AfterViewInit  {
  @ViewChild('searchInput') searchInput: ElementRef;
  sGizleGoster: boolean = false;
  mIcon: string = 'search';
  @Input('placeHolder') placeHolder?: string;

  @Input()
  public get value(): string {
    return this._value;
  }
  public set value(obj: string) {
    this.switch(obj);
  }
 
  @Input()
  public disabled: boolean = false;

  @Output()
  public change: EventEmitter<string> = new EventEmitter<string>();

  public onChange: (value: string) => {};
  public onTouched: () => void;

  private _value: string;

  @ViewChild('search') search: ElementRef<HTMLInputElement>;

  constructor(private _cdr: ChangeDetectorRef) {
    
   }

  ngAfterViewInit(): void {
      setTimeout(() => {
        this.switch(null);
      }, 100);
      this.searchInput.nativeElement.focus();
    }


  ngOnInit(): void {
  }

  public switch(value: string) {
    if (value !== this.value && this.disabled === false) {
      this._value = value;
      let sendValue = this.value;
      this.change.emit(sendValue);

      if (this.onChange) {
        this.onChange(sendValue);
      }

      this._cdr.markForCheck();
    }
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  searchGizleGoster() {
    if (this.sGizleGoster === false) {
      this.sGizleGoster = true;
      this.mIcon = 'close';
      this.search.nativeElement.focus();

    } else {
      this.sGizleGoster = false;
      this.mIcon = 'search';
    }
  }
}


