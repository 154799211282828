import { Component, OnInit, ɵNOT_FOUND_CHECK_ONLY_ELEMENT_INJECTOR, Input, ChangeDetectorRef } from '@angular/core';
import { YetkiService } from '../../../services/yetki.service';
import { YetkiModulModel } from '../../../models/yetki.model';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import * as fromUser from '../../../store/user/user.reducer';
import { KullaniciModel } from '../../../models/kullanici_model';
import { NbDialogRef } from '@nebular/theme';
import { selectUser, selectUserLoaded } from '../../../store/user/user.connector';
@Component({
  selector: 'yetki',
  templateUrl: './yetki.component.html',
  styleUrls: ['./yetki.component.css'],
  providers: [YetkiService],
})
export class YetkiComponent implements OnInit {
  isI: boolean;

  @Input('kullaniciBilgileri') set kullaniciBilgileri(value: any) {
    this.kullanici = value;
    
    this.getYetki();
  };
  isSubmitingYetki: boolean = false;
  isSubmitingSozlesmeYetki: boolean = false;
  kullanici: any;
  modulList$: Observable<YetkiModulModel[]>;
  tag: string = 'YetkiComponent -->';
  form: FormGroup;
  test;
  get permissions(): FormArray { return this.form.get('perimissions') as FormArray; }
  get soslesmePerimissions(): FormArray { return this.form.get('soslesmePerimissions') as FormArray; }

  getPermission(index: number): FormGroup { return this.permissions.at(index) as FormGroup; }
  getSoslesmePerimissions(index: number): FormGroup { return this.soslesmePerimissions.at(index) as FormGroup; }

  user: KullaniciModel;

  constructor(private yetkiService: YetkiService,
    private fb: FormBuilder,
    private store: Store<{}>,
    private ref: NbDialogRef<YetkiComponent>) {
    this.initForm();

    store.pipe(select(selectUserLoaded)).subscribe(isLoadedUser => {
      if (isLoadedUser) {
        // store'dan user bilgisini çeker
        store.pipe(select(selectUser)).subscribe(user => {
          // 
          this.user = user;
        });
      }
    });
  }
  refreshYetki() {
    this.initForm();
    if (this.kullanici) { this.getYetki(); }
  }
  getYetki() {
    this.isSubmitingYetki = true;
    this.isSubmitingSozlesmeYetki = true;

    // this.modulList$ = yetkiService.getYetkiModuls(this.apas_id);
    this.yetkiService.getYetkiModuls(this.kullanici.apas_id).subscribe(data => {
      
      this.isSubmitingYetki = false;
      this.isI = (this.user.apas_id === this.kullanici.apas_id);
      

      data.forEach((el, index) => {
        this.addPermission({ apas_id: this.kullanici.apas_id, ...el, IsRead: true, IsWrite: true });
        this.setPermissionToForm(index, this.isI);
      });
    }, (err: HttpErrorResponse) => {
      this.isSubmitingYetki = false;

    });


    // Sözleşme yetkileri
    this.yetkiService.getSozlesmeYetkiModuls(this.kullanici.apas_id).subscribe(data => {
      this.isSubmitingSozlesmeYetki = false;

      
      data.forEach((el, index) => {
        this.addSozlesmePermission({ apas_id: this.kullanici.apas_id, ...el });
        // this.setPermissionToForm(index);
      }, (err: HttpErrorResponse) => {
        this.isSubmitingSozlesmeYetki = false;
        
      });
    });

  }
close(){
  this.ref.close();
}
  ngOnInit() {
  }
  initForm() {
    this.form = this.fb.group({
      perimissions: this.fb.array([]),
      soslesmePerimissions: this.fb.array([]),
    });

  }
  addPermission(data) {
    this.permissions.push(this.fb.group(data));

  }
  addSozlesmePermission(data) {
    this.soslesmePerimissions.push(this.fb.group(data));
  }
  /**
   * IsRead
   * IsWrite
   * IsUpdate
   */
  setPermission(index) {
    // şuanki yetkiler.
    const currentPermissions = { ...this.getPermission(index).getRawValue() };
    const postPermissions = { ...this.getPermission(index).getRawValue() };
    // eski data
    currentPermissions.IsWrite = !currentPermissions.IsUpdate;
    currentPermissions.IsRead = !currentPermissions.IsUpdate;
    currentPermissions.IsUpdate = !currentPermissions.IsUpdate;

    // yeni data
    // kullanıcının değiştirdiği veri
    postPermissions.IsWrite = postPermissions.IsUpdate;
    postPermissions.IsRead = postPermissions.IsUpdate;
    postPermissions.IsUpdate = postPermissions.IsUpdate;
    
    // kullanıcı checkbox ları işaretler.
    this.setPermissionToForm(index);
    // kullanıcının değiştirdiği veri
    // const data = { ...this.getPermission(index).getRawValue() };

    // 

    this.yetkiService.giveYetkiToPersonel(postPermissions).subscribe(_data => {
      // işlem başarılı.
      
    }, (err: HttpErrorResponse) => {
      // Swal.fire('Hata!', 'Birşeyler yanlış gitti.');

      const IsRead: FormControl = this.getPermission(index).get('IsRead') as FormControl;
      const IsWrite: FormControl = this.getPermission(index).get('IsWrite') as FormControl;
      const IsUpdate: FormControl = this.getPermission(index).get('IsUpdate') as FormControl;

      IsRead.enable();
      IsWrite.enable();
      IsUpdate.enable();
      // işlem başarısız olunca eski yetkileri göster.
      this.getPermission(index).setValue(currentPermissions);
      // checkboxları güncelle
      this.setPermissionToForm(index);
    });
  }
  /**
   * Form group'u güncelle
   * @param index güncelleneck grubun index'i
   */
  setPermissionToForm(index, isI?) {
    const data = { ...this.getPermission(index).getRawValue() };

    const IsRead: FormControl = this.getPermission(index).get('IsRead') as FormControl;
    const IsWrite: FormControl = this.getPermission(index).get('IsWrite') as FormControl;
    const IsUpdate: FormControl = this.getPermission(index).get('IsUpdate') as FormControl;

    IsRead.enable();
    IsWrite.enable();
    IsUpdate.enable();

    if (isI) {
      IsRead.disable();
      IsWrite.disable();
      IsUpdate.disable();

    } else {
      if (data.IsUpdate) {
        IsRead.setValue(true);
        IsRead.disable();

        IsWrite.setValue(true);
        IsWrite.disable();
      } else if (data.IsWrite) {
        IsRead.setValue(true);
        IsRead.disable();
      }
    }
  }
}
