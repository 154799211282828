import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as fromJobs from '../../../store/jobs/jobs.connector';
import { JobModel } from './job.model';


@Injectable({ providedIn: 'root' })
export class JobService {

    private readonly API_URL = `${environment.backendurl}api/ek/jobs/`;

    constructor(
        private httpClient: HttpClient,
        private store: Store<{}>,
    ) { }

    getJobList(): Observable<JobModel[]> {
        const url: string = this.API_URL;
        return this.httpClient.get<JobModel[]>(url);
    }

    addJob(data): Observable<JobModel> {
        const url: string = `${this.API_URL}add/`;
        return this.httpClient.post<JobModel>(url, data);
    }

    public jobs$ = this.store.select(fromJobs.selectJobs);
}
