import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari, telefonKodu } from '../../../../pages/constants';
import { removeAllNull } from '../../../../pages/global-functions';
import { KullaniciService } from '../../../../services/kullanici.service';

@Component({
  selector: 'oner',
  templateUrl: './oner.component.html',
  styleUrls: ['./oner.component.css']
})
export class OnerComponent implements OnInit {
  telefonKodu = telefonKodu[0].mask;
  form: FormGroup;
  isSubmitting: boolean;
  constructor(private ref: NbDialogRef<OnerComponent>,
    private fb: FormBuilder,
    private kullaniciService: KullaniciService) {
    this.initForm();

  }

  ngOnInit() {

  }
  closed() {
    this.ref.close();
  }

  initForm() {
    this.form = this.fb.group({
      name: [null, Validators.required],
      phone: [],
      email: [],
      note: [],
    });
  }

  sendInvitation() {
    if (this.form.valid) {
      this.isSubmitting = true;
      const formValue = removeAllNull(this.form.value);

      this.kullaniciService.recomend(formValue).subscribe(response => {
        this.isSubmitting = false;


        Swal.fire(responseKodlari.successProgress as SweetAlertOptions).then(() => {
          this.ref.close();
        });
      });

    } else {
      this.form.markAllAsTouched();
      Swal.fire(responseKodlari.form.unValidForm as SweetAlertOptions);
    }
  }
}
