<nb-card class="m-header" size="large" class="sizeOfScreen m-0" style="border: unset;" >
    <nb-card-header>
        <div class="d-flex justify-content-between">
            <div>{{'APASNOTIFICATION.BILDIRIMLER' |translate}}</div>
            <div>
            </div>
            <div class="linked-div" style="font-size: smaller;" (click)="tumunuIsaretle()">{{'APASNOTIFICATION.TUMUNUOKUNDUOLARAKISARETLE'|translate}}
            </div>
        </div>

    </nb-card-header>

    <nb-card-body class="p-0" style="max-width:350px;height: 406px;overflow-y: scroll;overflow-x: hidden;">

        <infinite-scroll [isLoaiding]="!bildirimInfo.end" [rows]="bildirimData" [pagerowCount]="20" (scrolled)="loadNext()">

            <div *ngFor="let bildirim of bildirimData; let i=index">
                <apas-notification-item [context]="bildirim"></apas-notification-item>
            </div>
        </infinite-scroll>

    </nb-card-body>
    <nb-card-footer>
        <!-- <div class="showAlls linked-div text-center">Tümünü Göster</div> -->

    </nb-card-footer>
</nb-card>