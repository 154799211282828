import { createSelector } from '@ngrx/store';
import { UserInfoState } from './user.reducer';
import * as fromRoot from '../../app-state';

const getUserFeatureState = createSelector(
    fromRoot.getUserStateSelector, (state: fromRoot.UserState) => state?.UserInfo,
);

const selectUser = createSelector(
    getUserFeatureState,
    (state: UserInfoState) => state.user ,
);
const selectUserInfo = createSelector(
    getUserFeatureState,
    (state: UserInfoState) => state,
);

const selectUserLoading = createSelector(
    getUserFeatureState,
    (state: UserInfoState) => state.loading,
);

const selectUserLoaded = createSelector(
    getUserFeatureState,
    (state: UserInfoState) => state.loaded,
);

const selectUserError = createSelector(
    getUserFeatureState,
    (state: UserInfoState) => state.error,
);

const selectLogoutStatus = createSelector(
    getUserFeatureState,
    (state: UserInfoState) => state.logouting,
);
export {
    selectUser,
    selectUserInfo,
    selectUserLoading,
    selectUserLoaded,
    selectUserError,
    selectLogoutStatus,
};
