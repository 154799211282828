<form [formGroup]="form">
  <!-- <br> loading:{{loading}}
  <br> upoading:{{uploading}} -->
  <div [nbSpinner]="loading || uploading" nbSpinnerStatus="success" style="text-align: -webkit-center;">
    <nb-icon *ngIf="isSubmited && !isSuccess && !uploading" style="position: relative;transform: matrix(2, 0, 0, 2, 0, 170);color:red;" icon="close-circle-outline"></nb-icon>
    <nb-icon *ngIf="isSubmited && isSuccess && !uploading" style="position: relative;transform: matrix(2, 0, 0, 2, 0, 170);color: #4ada4f;" icon="checkmark-circle-2-outline"></nb-icon>


    <img *ngIf="!ImageUrl" src="https://via.placeholder.com/200" style="width: 100%;">

    <img #img *ngIf="ImageUrl && ImageUrl !==''" #aracFoto [src]="ImageUrl" style="width: 100%;
    object-fit: contain;
    max-height: 150px;
    min-height: 100px;"
      [class.img-opacity]="isSuccess" /><!--style="max-width: -webkit-fill-available;max-height: -webkit-fill-available; "-->

    <hr>
    <div class="form-group text-center">
      <textarea *ngIf="detay" formControlName="titleControl" [placeholder]="'APASIMAGEUPLOADER.ACIKLAMA' |translate" fullWidth nbInput
        style="height: 50px;"></textarea>
      <div class="uyari" *ngIf="form.get('titleControl').touched && form.get('titleControl').hasError('maxlength')"> Başlık 512 ({{form.get('titleControl').value.length}}) karakterden uzun olamaz.</div>
        <nb-checkbox *ngIf="public" formControlName="publicControl"  [checked]="true">{{publicText}}</nb-checkbox>
      <input *ngIf="showDateInput" nbInput fullWidth placeholder="Tarih" [nbDatepicker]="tarih" formControlName="created_time">
        <nb-datepicker #tarih></nb-datepicker>
      <button *ngIf="!isSuccess && uploadType === uploadtype.manuel" nbButton status="primary" size="tiny" (click)="upload()">
        <nb-icon style="margin-right: 5px;" icon="cloud-upload-outline"></nb-icon>
        {{'APASIMAGEUPLOADER.YUKLE' |translate}}
      </button>

      <button *ngIf="isSuccess" nbButton status="warning" size="tiny">
        <nb-icon style="margin-right: 5px;" icon="checkmark-circle-2-outline"></nb-icon>
        {{ 'APASIMAGEUPLOADER.YUKLENDI' |translate}}
      </button>

      <button *ngIf="!isSuccess" nbButton status="danger" size="tiny" (click)="remove()">
        <nb-icon style="margin-right: 5px;" icon="close-outline"></nb-icon>{{'APASIMAGEUPLOADER.KALDIR'|translate }}
      </button>
    </div>

  </div>
</form>
