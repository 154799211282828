import { createSelector } from '@ngrx/store';
import * as fromRoot from '../../../../app-state';
import { ExchangeState } from './exchanges.reducer';


const getExchangeFeatureState = createSelector(
    fromRoot.getOrganizationSelector, (state: fromRoot.OrganizationState) => state?.Module.Muhasebe.Exchanges,
);


const selectExchange = createSelector(
    getExchangeFeatureState,
    (state: ExchangeState) => state.exchanges,
);
const selectExchangeInfo = createSelector(
    getExchangeFeatureState,
    (state: ExchangeState) => state,
);

const selectExchangeLoading = createSelector(
    getExchangeFeatureState,
    (state: ExchangeState) => state.loading,
);

const selectExchangeLoaded = createSelector(
    getExchangeFeatureState,
    (state: ExchangeState) => state.loaded,
);

const selectExchangeError = createSelector(
    getExchangeFeatureState,
    (state: ExchangeState) => state.error,
);

const selectCurrency = (currency: string) => createSelector(
    getExchangeFeatureState,
    (state: ExchangeState) => state.entities[currency],
);

export {
    selectExchange,
    selectExchangeInfo,
    selectExchangeLoading,
    selectExchangeLoaded,
    selectExchangeError,
    selectCurrency,
};
