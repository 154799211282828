import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import * as alisSatisAction from './alis-satis.actions';
import { addOrUpdate } from '../../../global-functions';
import { Store } from '@ngrx/store';
import { MuhasebeService } from '../../muhasebe-service/muhasebe.service';
import { clearAlisItemEncryption } from '../../muhasebe-main/cari-hareket-detay/clear-cari-hareket';
import { ApasCrypto } from '../../../../shared/ApasEncryptor/apas-encryptor';
import { AlisSatisItemModel, AlisSatisModel } from '../../models/cari_hakeret.model';

@Injectable()
export class AlisSatisEffects {


    loadAlis$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(alisSatisAction.loadAlis),
            switchMap((props) => this.muhasebeService.getAlisList(props).pipe(
                // tslint:disable-next-line: max-line-length
                catchError((err: HttpErrorResponse) => of(alisSatisAction.loadAlisFail({ error: `Alış yüklenemedi.`, isEnd: false })))),
            ),
            switchMap(async (alislar) => {
                // tslint:disable-next-line: max-line-length
                if (alislar?.error) return alisSatisAction.loadAlisFail({ error: `Olmayan Alış temizlenemedi.`, isEnd: false });
                const isEnd: boolean = false;
                const cleanAlisData = await this.clearAllData(alislar);
                return alisSatisAction.loadAlisSuccess({ data: cleanAlisData, isEnd: isEnd });
            },
            ),
        );
    });

    loadSatis$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(alisSatisAction.loadSatis),
            switchMap((props) => this.muhasebeService.getSatisList(props).pipe(
                // tslint:disable-next-line: max-line-length
                catchError((err: HttpErrorResponse) => of(alisSatisAction.loadSatisFail({ error: 'Satış yüklenemedi.', isEnd: false }))),
            )),
            switchMap(async (satislar: any) => {
                // tslint:disable-next-line: max-line-length
                if (satislar?.error) return alisSatisAction.loadSatisFail({ error: `Olmayan Satış temizlenemedi.`, isEnd: false });

                const isEnd: boolean = false;
                const cleanSatisData = await this.clearAllData(satislar);
                return alisSatisAction.loadSatisSuccess({ data: cleanSatisData, isEnd: isEnd });
            },
            ),
        );
    });

    setAlisFilter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(alisSatisAction.setAlisFilter),
            switchMap((props) => this.muhasebeService.getAlisList(props).pipe(
                // tslint:disable-next-line: max-line-length
                catchError((err: HttpErrorResponse) => of(alisSatisAction.loadAlisFail({ error: 'Alış yüklenemedi.', isEnd: false }))),
            )),
            switchMap(async (alislar: any) => {
                // tslint:disable-next-line: max-line-length
                if (alislar?.error) return alisSatisAction.loadAlisFail({ error: `Olmayan Alış temizlenemedi.`, isEnd: false });
                const isEnd: boolean = false;
                const cleanAlisData = await this.clearAllData(alislar);
                return alisSatisAction.loadAlisSuccess({ data: cleanAlisData, isEnd: isEnd });
            },
            ),
        );
    });

    setSatisFilter$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(alisSatisAction.setSatisFilter),
            switchMap((props) => this.muhasebeService.getSatisList(props).pipe(
                // tslint:disable-next-line: max-line-length
                catchError((err: HttpErrorResponse) => of(alisSatisAction.loadSatisFail({ error: 'Satış yüklenemedi.', isEnd: false }))),
            )),
            switchMap(async (satislar: any) => {
                // tslint:disable-next-line: max-line-length
                if (satislar?.error) return alisSatisAction.loadSatisFail({ error: `Olmayan Satış temizlenemedi.`, isEnd: false });
                const isEnd: boolean = false;
                const cleanSatisData = await this.clearAllData(satislar);
                return alisSatisAction.loadSatisSuccess({ data: cleanSatisData, isEnd: isEnd });
            },
            ),
        );
    });

    addAlis$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(alisSatisAction.addUpAlisItem),
            switchMap(async (data: any) => {
                this.store.dispatch(alisSatisAction.selectAlis({ id: data.Alis.id }));
                return data;
            }),
            switchMap(async (data: any) => {
                let clearItem = data.Alis;
                const decryptor = new ApasCrypto();

                if (data?.decrypte) { // Eğer Satış Decrypte edilmesi gerekiyorsa yap zaten decrypte ise karışma
                    clearItem = await clearAlisItemEncryption(data.Alis, decryptor);
                }
                return clearItem;
            }),
            switchMap(async (data: any) => {
                return alisSatisAction.addUpAlisSuccess(data);
            }),
        );
    });


    addSatis$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(alisSatisAction.addUpSatisItem),
            switchMap(async (data: any) => {
                this.store.dispatch(alisSatisAction.selectSatis({ id: data.Satis.id }));
                return data;
            }),
            switchMap(async (data: any) => {
                let clearItem = data.Satis;
                const decryptor = new ApasCrypto();
                if (data?.decrypte) { // Eğer Satış Decrypte edilmesi gerekiyorsa yap zaten decrypte ise karışma
                    clearItem = await clearAlisItemEncryption(data.Satis, decryptor);
                }   
                return clearItem;
            }),
            switchMap(async (data: any) => {
                return alisSatisAction.addUpSatisSuccess(data);
            }),
        );
    });


    async clearAllData(data) {
        let newData = [];
        if (!data) {
            return newData;
        }
        
        data = await data.map(async (x: AlisSatisModel) => await Object.assign({}, x, { selected: false }));
        const decryptor = new ApasCrypto();
        for await (let elm of data) {
            const clearItem = await clearAlisItemEncryption(elm, decryptor);
            newData = addOrUpdate(newData, clearItem);
        }
        return newData;
    }

    constructor(
        private actions$: Actions,
        private muhasebeService: MuhasebeService,
        private store: Store,
    ) {

    }

}


