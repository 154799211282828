<nb-card id="ParaGirisiId" class="masraf_ekle modalType animated fadeInRight" size="giant" [formGroup]="form"
  style="width: 500px;">
  <nb-card-header class="header">
    <span class="baslik">MASRAF / HARCAMA EKLE </span>
    <nb-icon class="linked-div closeIcon" icon='close-outline' (click)="close()">
    </nb-icon>
  </nb-card-header>

  <nb-card-body [nbSpinner]="isLoading">
    <nb-alert outline="info" class="d-flex">
      <span style="font-weight: 400;">
        <nb-icon icon="info-outline"></nb-icon>
        Yaptığınız Bu İşlem,
        <span *ngIf="kasaInfo">
          <b>{{kasaInfo?.title}}&nbsp;</b> Hesabın Hareketlerinde
          &nbsp;
        </span>
        &nbsp;<span class="font-weight-bold">Masraf</span>&nbsp; Olarak
        Görünür Ve Hiçbir &nbsp;<span class="font-weight-bold">Cari Hesabı Etkilemez.</span>
      </span>
    </nb-alert>
    <div class="row">
      <!-- Hesap -->
      <div class="col-md-12 form-group">
        <label class="label">Hesap</label>
        <hesap-select class="d-block" [clearable]="false" [restrict]="account_type" formControlName="sender_account" apasInvalid></hesap-select>
      </div>
      <!-- Tarih -->
      <div class="col-md-12 form-group">
        <label class="label">Tarih</label>
        <input type="text" fullWidth nbInput [placeholder]="'Tarih' | translate" [nbDatepicker]="masrafDatePicker"
          formControlName="date" dateOnly apasInvalid>
        <nb-datepicker #masrafDatePicker></nb-datepicker>

      </div>

      <!-- Tutar -->
      <div class="col-md-12 form-group">
        <label class="label">Tutar</label>
        <input nbInput fullWidth paraDirective [encrypt]="true" [decrypt]="editMod" [forceRefresh]="forceRefresh"
          formControlName="total_price" [currency]="form.get('currency').value" apasInvalid>
      </div>

      <!-- Note -->
      <div class="col-md-12 form-group">
        <label class="label">Açıklama</label>
        <textarea rows='1' nbInput fullWidth placeholder="Açıklama Ekleyin" formControlName="note"
          apasInvalid></textarea>
      </div>

      <div class="col-md-12 form-group">
        <label class="label">Etiketler</label>
        <div>
          <aps-tag-input formControlName="tags"></aps-tag-input>

        </div>
      </div>

    </div>
    <div class="d-flex justify-content-center">
      <div>
        <dosya-ekle-input formControlName="dosya"></dosya-ekle-input>
      </div>
      <div>
        <button class="mt-0" *ngIf="!editMod" nbButton status="info" (click)="postForm()" [disabled]="isLoading">
          {{"MUHASEBE.KAYDET"|translate}}</button>
        <button class="mt-0" *ngIf="editMod" nbButton status="info" (click)="patchForm()" [disabled]="isLoading">
          {{"MUHASEBE.GUNCELLE"|translate}}</button>
      </div>
    </div>
  </nb-card-body>

</nb-card>