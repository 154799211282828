<nb-card accent="primary">
  <nb-card-header>

    <div class="col-md-9">
      {{ 'TAKVIM.TITLE' | translate }}
    </div>
    <div class="col-md text-right">
      <!-- <button nbButton  style="background: none !important;padding:0px;"  [routerLink]="['pages/takvim/kullanici-takvim']" >
        <nb-icon icon="expand-outline" style="width: 24px; height: 24px;"></nb-icon>
      </button> -->
    </div>
  </nb-card-header>
  <nb-card-body style="min-height: 45rem;">

    <!--Takvim Header-->
    <div class="row">

      <div class="col-md-6">
        <div class="row">
          <div class="col-md">
            <div class="btn-group">
              <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate)" (viewDateChange)="getDate()">
                {{ 'TAKVIM.GERI' | translate }}
              </div>
              <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate)" (viewDateChange)="getDate()">

                {{ 'TAKVIM.BUGUN' | translate }}
              </div>
              <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange.next(viewDate)" (viewDateChange)="getDate()">
                {{ 'TAKVIM.ILERI' | translate }}
              </div>
            </div>
          </div>
          <div class="col-md">
            <b>{{ viewDate | calendarDate: view + 'ViewTitle':locale }}</b>
          </div>
        </div>
      </div>
    </div>
    <!--// Takvim Header-->


    <!--Takvim Template-->
    <ng-template #customCellTemplate let-day="day" let-locale="locale">
      <div class="cal-cell-top">
        <!-- <button nbButton status="primary" size="tiny">Görev Ekle</button>
        <button nbButton status="warning" size="tiny">Hatırlatma Ekle</button> -->
        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
      </div>
      <small *ngFor="let item of day.events ; let i = index" [nbTooltip]="item.title">
        <span *ngIf="day.isToday ? i < 2 : i < 3">
          <p *ngIf="item.type == 1" class="gorev-takvim">
            <nb-icon icon="checkmark-outline" style="font-size: 1rem; "></nb-icon>
            <span>
              {{item.title | slice:0:length ? length:30}}{{item.title.length > length ? length:30 ? '...' : null }}</span>
          </p>

          <p *ngIf="item.type == 2" class="hatirlatma-takvim" [nbTooltip]="item.title">
            <nb-icon icon="clock-outline" style="font-size: 1rem;"></nb-icon>
            <span>
              {{item.title | slice:0:length ? length:30}}{{item.title.length > length ? length:30 ? '...' : null }}</span>
          </p>
          <!--takvimSlice?takvimSlice:'4' [takvimSlice]="'6'"-->

          <p *ngIf="item.type == 3" class="toplanti-takvim" [nbTooltip]="item.title">
            <nb-icon icon="icon-toplanti" pack="custom-icons" style="font-size: 1rem;"></nb-icon>
            <span>
              {{item.title | slice:0:length ? length:30}}{{item.title.length > length ? length:30 ? '...' : null }}</span>
          </p>
          <!--takvimSlice?takvimSlice:'4' [takvimSlice]="'6'"-->
        </span>

      </small>
      <small *ngIf="day.isToday && showIsProgrami" (click)="openIsProgramiList()">
        <p class="isprogrami-takvim">
          <nb-icon icon="layers-outline" style="font-size: 1rem;"></nb-icon>
          <span>İş Programı</span>
        </p>
      </small>
      
      <small>
        <p *ngIf="day.events.length > 3" class="devam-takvim text-right">...</p>
      </small>

    </ng-template>


    <div [ngSwitch]="view">
      <mwl-calendar-month-view (dayClicked)="takvimClick($event)" *ngSwitchCase="'month'" [viewDate]="viewDate" [weekStartsOn]="weekStartsOn"
        [events]="takvim" [cellTemplate]="customCellTemplate">
        <!--(dayClicked)="takvimClick($event)"-->

      </mwl-calendar-month-view>

    </div>
    <!--// Takvim Title-->

  </nb-card-body>
</nb-card>