import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { responseKodlari } from '../../../pages/constants';
import { removeAllNull } from '../../../pages/global-functions';
import { ChecklistService } from '../../../services/checklist.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'ngx-check-duzenle',
  templateUrl: './check-duzenle.component.html',
  styleUrls: ['./check-duzenle.component.scss'],
  providers: [ChecklistService],
})
export class CheckDuzenleComponent implements OnInit {
  form: FormGroup;
  instanceId: number;
  instanceType: string;
  editData: any[];
  get checkItems(): FormArray { return this.form.get('checkitem_set') as FormArray; }
  isLoading = false;
  constructor(private ref: NbDialogRef<CheckDuzenleComponent>,
    private fb: FormBuilder,
    private checkService: ChecklistService) { }

  ngOnInit(): void {
    this.initForm();
    this.form.get('instance_type').setValue(this.instanceType);
    this.form.get('object_id').setValue(this.instanceId);
    if (this.editData.length > 0) {
      console.log('Edit Data:', this.editData);
      this.editData.forEach(elm => {
        this.addCheckItem(elm);
      });
    } else {
      this.addCheckItem();
    }
  }

  initForm() {
    this.form = this.fb.group({
      instance_type: [this.instanceType, Validators.required],
      object_id: [this.instanceId, Validators.required],
      checkitem_set: this.fb.array([]),
    });
  }

  addCheckItem(item?) {
    const formItem = this.fb.group({
      identifier: [null],
      title: [null, [Validators.required, Validators.maxLength(256)]],
    });
    if (item) {
      formItem.patchValue(item);
    }
    this.checkItems.push(formItem);
  }

  deletedItems = [];

  deleteCheckItem(index) {
    const item = this.checkItems.at(index).value;
    if (item.identifier) this.deletedItems.push(item.identifier);
    if (this.checkItems.length > 1) {
      this.checkItems.removeAt(index);
    } else {
      return;
    }
  }

  deleteCheckList() {
    Swal.fire(responseKodlari.shureToDelete as SweetAlertOptions).then(({value}) => {
      if (value) {
        const formVal = removeAllNull(this.form.value);
        this.checkService.deleteChekGroup(formVal).subscribe(resp => {
          Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
          this.close(true);
          this.isLoading = false;
        }, (err) => {
          this.isLoading = false;
          Swal.fire(responseKodlari.unSuccesDeleted as SweetAlertOptions);
        });
      }
    });
  }

  post() {
    console.log('post new check list');
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      console.log('form errors : ', this.form);
      return;
    }
    this.isLoading = true;
    const formData = removeAllNull(this.form.value);
    this.checkService.addCheckGroup(formData).subscribe(data => {
      this.isLoading = false;
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.close(true);
    }, err => {
      this.isLoading = false;
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    });
  }

  patch() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    const formData = removeAllNull(this.form.value);
    formData['deleted_items'] = this.deletedItems;
    this.checkService.updateCheckGroup(formData).subscribe(data => {
      this.isLoading = false;
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.close(data);
    }, err => {
      this.isLoading = false;
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    });
  }

  close(success?) {
    this.ref.close(success);
  }


}
