import { Component, OnInit, ViewEncapsulation, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbFlipCardComponent, NbTriggerStrategyBuilderService } from '@nebular/theme';
import { PersonelGrubuOlusturComponent } from '../personel-grubu-olustur/personel-grubu-olustur.component';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { prefixBase64, responseKodlari } from '../../../pages/constants';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { KullaniciModel } from '../../../models/kullanici_model';
import { NbComponentStatus } from '@nebular/theme/components/component-status';
@Component({
  selector: 'personel-list',
  templateUrl: './personel-list.component.html',
  styleUrls: ['./personel-list.component.scss'],
  providers: [OrganizasyonService],
  encapsulation: ViewEncapsulation.None,
})
export class PersonelListComponent implements OnInit {
  personelGroupLoadings = {};
  personelGoster: string = 'tum';
  personel: any[];
  current_personel: any[];
  spinner: boolean = true;
  goster: boolean = true;
  personel_toplam: number = 0;
  form: FormGroup;
  @Input('colValue') colValue?: string;
  gruplar: any[];
  isLoading: boolean = false;
  tag: string = 'PersonelListComponent ---> ';
  prefixBase64 = prefixBase64;
  constructor(private router: Router,
    private _organizationService: OrganizasyonService,
    private fb: FormBuilder,
    private _nbDialogService: NbDialogService) {
    this._organizationService.getMyOrganizationsPersonels('all').subscribe(data => {
      
      this.personel = data;
      this.personel_toplam = this.personel.length;
      this.current_personel = this.personel;
      this.spinner = false;
    });

  }
  ngOnInit() {
  }


  personelGorunum(gorunum: number) {
    if (gorunum === 1) {
      this.goster = true;
    } else {
      this.goster = false;
    }
  }

  personelDetay(item) {
    this.router.navigate(['/pages/personel/detay'], {
      state: {
        kullanici: item,
      },
    });

  }
  personelListele(listType: string) {
    this.isLoading = true;
    const tmp_gecici_personel: KullaniciModel[] = [];
    if (listType === 'tum') {
      this.personelGoster = 'tum';
      this._organizationService.getMyOrganizationsPersonels('all').subscribe(data => {
        this.isLoading = false;

        this.personel = data;
        this.personel_toplam = this.personel.length;
        this.current_personel = this.personel;
        this.spinner = false;
      }, () => {
        this.isLoading = false;

      });

    } else if (listType === 'apasUye') {
      this.personelGoster = 'apasUye';
      this._organizationService.getMyOrganizationsPersonels('yetkili').subscribe(data => {
        this.isLoading = false;

        this.personel = data;
        this.personel_toplam = this.personel.length;
        this.current_personel = this.personel;
        this.spinner = false;
      }, () => {
        this.isLoading = false;

      });
    } else if (listType === 'apasUyeDegil') {
      this.personelGoster = 'apasUyeDegil';
      this._organizationService.getMyOrganizationsPersonels('yetkisiz').subscribe(data => {
        this.isLoading = false;

        this.personel = data;
        this.personel_toplam = this.personel.length;
        this.current_personel = this.personel;
        this.spinner = false;
      }, () => {
        this.isLoading = false;

      });

    } else if (listType === 'apasUyePasif') {
      /*this.personel.forEach((el: KullaniciModel) => {
        if (!el.is_my_user) {
          tmp_gecici_personel.push(el);
        }
      });*/
      this.personelGoster = 'apasUyePasif';
      this.isLoading = true;
      this._organizationService.getMyOrganizationsPersonels('pasif').subscribe(data => {
        this.isLoading = false;

        this.personel = data;
        
        this.personel_toplam = this.personel.length;
        this.current_personel = this.personel;
      }, () => {
        this.isLoading = false;

      });
    }

    this.current_personel = tmp_gecici_personel;
    
  }

  yenidenPersonel(item) {

    
    this.isLoading = true;
    this._organizationService.reactivate_personel({ apas_id: item.apas_id }).subscribe(data => {
      this.isLoading = false;

      item.is_active = true;
      if (this.personelGoster !== 'tum')
        this.current_personel = this.current_personel.filter(f => f.apas_id !== item.apas_id);
      
    }, () => {
      this.isLoading = false;

    });

  }
  personelGruplariniGetir(item, personelFlipCard: NbFlipCardComponent) {
    

    if (item) {
      this.personelGroupLoadings[item.apas_id] = true;
      this._organizationService.get_personel_gruplari(item.apas_id).subscribe(data => {
        this.personelGroupLoadings[`${item.apas_id}`] = false;
        personelFlipCard.toggle();
        
        this.gruplar = data;
      }, () => {
        this.personelGroupLoadings[`${item.apas_id}`] = false;
        this.grupOlsutur();

      });
    } else {
      personelFlipCard.toggle();

    }
  }

  yetkiliKullanici(item) {

    

    let kalan_kullanim = 0;
    this.isLoading = true;

    this._organizationService.get_personel_remaining().subscribe(data => {
      kalan_kullanim = data['kalan'];
      this.isLoading = false;


      if (kalan_kullanim > 0) {

        Swal.fire({
          ...responseKodlari.userCount, html: `<b style='color:#01579b'>
        Mevcut Paketinizde Kalan Kullanıcı Hakkınız: <b  style='color:#dd3333'>${kalan_kullanim} </b></b><br> <b>
         ${item.name} </b>
         kullanıcı olarak artık veri girişi yapıp yetki verdiğiniz modulleri kullanabilecektir.`
        } as SweetAlertOptions).then((result) => {
          if (result.value) {
            this.add_as_yetkili(item);
          }
        });
      } else {
        Swal.fire(responseKodlari.userAccessEnd as SweetAlertOptions).then((result) => {
          if (result.value) {
            this.router.navigate(['profil/firma-profil']);
          }
        });
      }
    }, () => {
      this.isLoading = false;

    });
  }

  add_as_yetkili(item) {

    this.isLoading = true;

    this._organizationService.add_yetkili_user({ 'apas_id': item.apas_id }).subscribe(data => {
      this.isLoading = false;

      if (data.SC01) {
        item.is_my_user = true;
        if (this.personelGoster !== 'tum') {
          this.current_personel = this.current_personel.filter(f => f.apas_id !== item.apas_id);
        }
      }
      else if (data.SC02) {
        Swal.fire(responseKodlari.personel.personelDavetEdildi as SweetAlertOptions)
      }

      
    }, () => {
      this.isLoading = false;

    });

  }
  grupOlsutur() {
    this._nbDialogService.open(PersonelGrubuOlusturComponent);
  }
}




