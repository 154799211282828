<nb-card class="modalType animated fadeInRight" size="giant" style="width:400px;"> 
  <nb-card-header class="d-flex justify-content-between align-items-center">
    <span>
      <b *ngIf="!data">{{ 'DEPO.URUNEKLE' | translate }}</b>
      <b *ngIf="data">Ürün Güncelle</b>
    </span>
    <button nbButton (click)="closed()" size="tiny" class="heryerdeClose">X</button>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="form">
      <!--Ürün Adı-->
      <div class="form-group">
        <label class="label">Ürün Adı</label>
        <input placeholder="Ürün Adı" type="text"  nbInput fullWidth
          formControlName="name" apasInvalid/>
       
      </div>
      <!--//Ürün Adı-->

      <!--Birim-->
      <div class="form-group">
        <label class="label">{{'DEPO.BIRIM' | translate}}</label>
      </div>
      <ng-select [items]="birim" appendTo="body" [addTag]="false" [placeholder]="'DEPO.BIRIM' | translate"
        bindLabel="label" bindValue="value" [searchable]="false" formControlName="unit" apasInvalid>
      </ng-select>
    
      <!--//Birim-->

      <div class="form-group">
        <label class="label">Barkod</label>
        <input type="text" nbInput fullWidth 
        placeholder="Barkod" formControlName="barkod" apasInvalid />

    </div>
    

      <!--Açıklama-->
      <div class="form-group">
        <label class="label">{{'DEPO.ACIKLAMA' | translate}}</label>
        <textarea id="aciklama" formControlName="note" nbInput fullWidth
          [placeholder]="'DEPO.ACIKLAMA' | translate"></textarea>
      </div>
      <!--//Açıklama-->


    </form>

    <div class="text-center">
      <button nbButton status="success" size="medium" (click)="urunEkle()" *ngIf="!data">
        {{ 'DEPO.BUTTONKAYDET' | translate }}</button>
      <button nbButton status="success" size="medium" (click)="urunEkle()" *ngIf="data">
        {{ 'DEPO.GUNCELLE' | translate}} 
      </button>
    </div>
  </nb-card-body>
  
</nb-card>