import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { NextObserver, Observable, Subject } from 'rxjs';
import { WebSocketModel } from '../models/workflow.model';
import { OrganizasyonService } from './organizasyon.service';
import { Store } from '@ngrx/store';
import * as akisActions from '../store/akis/akis.actions';
import * as akisReducer from '../store/akis/akis.connector';

import { delay, retryWhen } from 'rxjs/operators';
import { SocketTokenService } from './socket.token.service';
import { environment } from '../../environments/environment';
import { NbToastrService } from '@nebular/theme';
import { getSocketControllerConnector } from '../shared/socket-functions';


@Injectable()
export class WebSocketService {
    myWebSocket: WebSocketSubject<WebSocketModel>;
    private readonly SOCKET_URL = `${environment.socketUrl}akis/`;


    // Socket için gerekli bağlantı parametrelerinin durumunu kontrol eder.
    // setDurumControllers fonksiyonları tarafından yönetilir.
    baglantiControl: Subject<boolean> = new Subject<boolean>();
    orgApasId: any;
    canConnect: boolean = false;
    connectionTicket;
    reconnectInterval: number = 1000;
    maxReconnectAttempts: number = 250;
    reconncetAttempts: number = 0;
    openObserver: NextObserver<Event>;
    closeObserver: NextObserver<CloseEvent>;
    connected = false;

    // Muhasebe İçin Veri Trafiğini yönetecek Fonksiyon.
    // İleriki tarihlerde geri kalan tüm moduller için genişletilebilir.
    // Tüm modüller için genişletilmesi durumunda bütün sistem real time çalışacaktır.
    socketController;

    constructor(
        private httpClient: HttpClient,
        private organizationService: OrganizasyonService,
        private socketStore: Store<{ Akis }>,
        private socketTokenService: SocketTokenService,
        public toastrService: NbToastrService,
    ) {
        this.socketController = getSocketControllerConnector(this.socketStore, this.toastrService);
        this.baglantiControl.next(false);
        this.baglantiControl.subscribe(val => {
            if (val) {
                // Open Connection.
                this.setConnection();
            } else {
                // close connection
                if (this.myWebSocket) {
                    this.connectionTicket = null;
                    this.myWebSocket.unsubscribe();
                }
                this.myWebSocket = null;
            }
        });

        this.setBaglantiController();
        this.setConnectionControllers();

    }


    setBaglantiController() {

        // Tokenin ve firma bilgilerinin durumunu kontrol eden fonksiyonlar.
        this.organizationService.org$.subscribe(orgInfo => {
            if (orgInfo?.apas_id) {
                this.orgApasId = orgInfo['apas_id'];
                this.baglantiControl.next(true);
            } else {
                this.baglantiControl.next(false);
            }
        });

        this.socketTokenService.socketToken$.subscribe(val => {
            this.connectionTicket = val;
            if (this.orgApasId && this.connectionTicket) {
                this.baglantiControl.next(true);
            } else {
                this.baglantiControl.next(false);
            }
        });

        this.socketStore.select(akisReducer.connectionStatus).subscribe(val => {
            this.connected = val;
        });
    }

    setConnection() {
        if (this.connectionTicket && this.orgApasId) {
            this.connect();
        } else {
            this.socketTokenService.getSocketToken();
        }
    }
    async setConnectionControllers() {

        // Bağlantının durumunu yöneten kontroller.

        this.closeObserver = {
            next: (err: CloseEvent) => {
                if (err.code === 4123) {
                    this.baglantiControl.next(false);
                    this.connectionTicket = null;
                }
                this.socketStore.dispatch(akisActions.setConnected({ connection: false }));
            },
        };

        this.openObserver = {
            next: () => {
                // this.startHeartbeat();
                this.socketStore.dispatch(akisActions.setConnected({ connection: true }));
            },
        };
    }

    connect() {
        if (this.connected) return;
        const url: string = `${this.SOCKET_URL}${this.orgApasId}/?ticket=${this.connectionTicket}`;

        this.myWebSocket = webSocket(
            {
                url: url,
                openObserver: this.openObserver,
                closeObserver: this.closeObserver,
            },
        );
        if (this.connected) return;

        this.myWebSocket.pipe(retryWhen((errors) => errors.pipe(delay(this.reconnectInterval)))).subscribe(msg => {
            this.socketController.getContentValues(msg.message);
            if (msg.message.is_active) {
                this.socketStore.dispatch(akisActions.addItem({ akisItem: msg['message'] }));
            }
        });
    }

    /**
     * Geçmiş akış verisini çeker
     * @param date belli bir tarihden itibaren akış getirir.
     * @param page infinitolark çekilecek olan sayfanın indisini belirtir.
     */
    getAkis(params: { date?: number, page?: number }): Observable<any[]> {
        // tslint:disable-next-line: max-line-length
        const url: string = environment.backendurl + (params.date ? ('api/akis/' + params.date) : 'api/akis/' + (params.page ? 'list/' + params.page + '/' : ''));
        return this.httpClient.get<any[]>(url);
    }
}
