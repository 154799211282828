
import { createAction, props } from '@ngrx/store';
import { DeviceInfo } from 'ngx-device-detector';
import { LocationModel } from '../../models/location.model';

const SetUrl = createAction('[Aplikasyon] UrlChange', props<{ URL: string }>());
const SetLang = createAction('[Aplikasyon] SetLang', props<{ lang: string }>());
const SetLocation = createAction('[Location] Location Set', props<{ curLoc: LocationModel }>());
const setAygitBilgisi = createAction('[Aygıt Türü] SetAygıtTur', props<DeviceInfo>());
export {
    SetUrl,
    SetLang,
    SetLocation,
    setAygitBilgisi,
};
