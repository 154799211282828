import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import Swal from 'sweetalert2';
import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, Input, Injector, forwardRef } from '@angular/core';
import { acceptFileFormats } from '../../../pages/constants';
import { ControlValueAccessorConnector } from '../../../shared/control-value-accessor-connector';

@Component({
  selector: 'dosya-ekle-input',
  templateUrl: './dosya-ekle-input.component.html',
  styleUrls: ['./dosya-ekle-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DosyaEkleInputComponent),
      multi: true,
    },
  ],
})
export class DosyaEkleInputComponent extends ControlValueAccessorConnector {


  @Input() formControl!: FormControl;

  @Input() formControlName: string;

  @Input('dosyaTip') dosyaTip: string = acceptFileFormats;
  @Input() public disabled: boolean = false;
  @Input() buttonStatus = 'warning';
  @Input('buttonText') buttonText = 'Dosya Ekle';
  public get value(): string {
    return this._value;
  }
  public set value(obj: string) {
    this.switch(obj);
  }
  constructor(
    injector: Injector,
    private cd: ChangeDetectorRef,
  ) {
    super(injector);
  }


  @Output()
  public change: EventEmitter<string> = new EventEmitter<string>();

  public onChange: (value: string) => {};
  public onTouched: () => void;

  private _value: string;

  public switch(value: string) {
    if (value !== this.value && this.disabled === false) {
      this._value = value;
      const sendValue = this.value;
      this.change.emit(sendValue);

      if (this.onChange) {
        this.onChange(sendValue);
      }

      this.cd.markForCheck();
    }
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  //#region Dosya işlemleri
  currentFile: File;
  isFileCurrect: boolean = false;
  isFileUploading: boolean = false;
  dosya_adi;
  percentDone: number = 100;
  isSubmitting: boolean = false;

  isLoading: boolean = false;
  santiyeList: any[] = [];
  //#endregion

  /**
   * Resim ekle butonu için gerekli fonksiyon.
   * @param fileInput #fileInput type="file" accept="image/png, image/jpeg" olarak tanımlanmış buton.
   */
  openFile(fileInput) {
    fileInput.value = '';
    fileInput.click();
  }
  /**
   * Dosya(lar) seçildikden sonra çalışan fonksiyon.
   * @param event Seçilen dosya(lar)ın listesinin bulunduğu event openFile dialoğun geri dönüşüdür.
   */
  openFileDialog(event) {
    this.isLoading = true;
    // tek bir dosya alıcağım icin ilk dosyayı seçiyorum.
    const tmpFile: File = (event.target as HTMLInputElement).files[0];
    const fileName = tmpFile.name.split('.');
    const currentType = fileName.pop();

    // Seçilen elemanon dosya uzantısının doğru olup olmadığına bakılır.
    this.isFileCurrect = this.dosyaTip
      .split(',')
      .some((s) => s.toLowerCase().includes(currentType.toLowerCase()));

    // eğer uyumsuz herhangi bir dosya var ise onu kullanıcıya uyarı olarak gösterir.
    if (!this.isFileCurrect) {
      this.isLoading = false;

      Swal.fire({
        icon: 'error',
        title: 'Lütfen uygun formatta dosya seçiniz.',
        html:
          '<div style="color: #f27474;text-align: left !important;">' +
          tmpFile.name +
          '</div>' +
          '<div style="margin:15px 0px;color:#000;text-align:left !important;"> <b>Geçerli dosya formatları aşağıdadır.</b></div>' +
          '<div style="color:green;text-align:left !important;"> <b>' + this.dosyaTip + '</b></div>',
        showConfirmButton: true,
      });
    } else {
      this.dosya_adi = tmpFile.name.concat();
      this.currentFile = tmpFile;
      const reader = new FileReader();
      reader.onload = () => {
        this.isLoading = false;
        const fileBase64 = reader.result as string;
        this.control.setValue(fileBase64);
      };
      reader.readAsDataURL(tmpFile);
    }
  }
  /* dosya işlemleri son*/

  dosya: any;

}
