import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { DepoService } from '../../../services/depo.service';
import { Observable, Subject, concat, of } from 'rxjs';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { ProductService } from '../../../services/product.service';
import { ProductModel } from '../../../models/product.model';
import { birim, Constants, getBirim, responseKodlari, setWarehouseInOutText, tarih_format } from '../../../pages/constants';
import { BaseModel } from '../../../models/base.model';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { TaseronService } from '../../../services/taseron.service';
import {
  addOrUpdate,
  convertDateWithTZ,
  dateFormSetter,
  exportExcelListeWithHeaders,
  formatLongText,
  removeAllNull,
} from '../../../pages/global-functions';
import { UrunHareketlerComponent } from '../urun-hareketler/urun-hareketler.component';
import * as moment from 'moment-timezone';
import { UrunHareketEditComponent } from '../urun-hareketler/urun-hareket-edit/urun-hareket-edit.component';
import { UrunGirisiYapComponent } from './urun-girisi-yap/urun-girisi-yap.component';
import { FilePreviewerComponent } from '../../apas-components/file-preview-component/file-previewer.component';


@Component({
  selector: 'depo-main',
  templateUrl: './depo-main.component.html',
  styleUrls: ['./depo-main.component.scss'],
  providers: [DepoService, OrganizasyonService, ProductService, TaseronService],
})
export class DepoMainComponent implements OnInit {
  @Input() tedarikci_apas_id = null;
  @Input() headerShow = true;
  @Input() filterShow = true;
  c = Constants.prototype;
  birim = birim;

  name: string;
  timeout: any;
  tarih_format = tarih_format;
  personelList$: Observable<BaseModel[]>;
  rows = [];
  default_items: ProductModel[];
  isLoading: boolean = false;
  form: FormGroup;
  durdur = false;
  filterControl = false;
  getBirim = getBirim;
  date;

  formatLongText = formatLongText;

  constructor(private fb: FormBuilder,
    private nbDialogService: NbDialogService,
    private depoService: DepoService,
  ) { }

  ngOnInit() {

    this.initForm();
    this.getDepoDataList();

  }

  initForm() {
    this.form = this.fb.group({
      page: [0],
      count: [20],
      tedarikci_apas_id: [],
      end_date: [],
      start_date: [],
      personel: [],
      warehouse_id: [],
      search: [],
    });
    this.form.get('warehouse_id').valueChanges.subscribe(() => {

      this.form.get('start_date').setValue(null, { emitEvent: false });
      this.form.get('personel').setValue(null, { emitEvent: false });
      this.form.get('tedarikci_apas_id').setValue(null, { emitEvent: false });
      this.form.get('search').setValue(null, { emitEvent: false });
      this.filterControlSet();

    });

    this.form.get('search').valueChanges.subscribe(() => {
      setTimeout(() => {
        this.getFilteredData();
      }, 150);
    });
    this.form.get('end_date').valueChanges.subscribe(val => {
      setTimeout(() => {
        this.getFilteredData();
      }, 150);
      this.filterControlSet();
    });

    this.form.get('tedarikci_apas_id').valueChanges.subscribe(() => {
      this.filterControlSet();
    });
    this.form.get('personel').valueChanges.subscribe(() => {
      this.filterControlSet();
    });


  


  }

  filterControlSet() {
    const tedarikci = this.form.get('tedarikci_apas_id').value;
    const start_date = this.form.get('start_date').value;
    const end_date = this.form.get('end_date').value;
    const personel = this.form.get('personel').value;
    if (tedarikci || start_date || end_date || personel) {
      this.filterControl = true;
    } else {
      this.filterControl = false;
      this.getFilteredData();
    }
  }

  openIrsaliyeImage(event) {
    this.isLoading = true;
    this.depoService.getIrsaliyeImage(event.identifier).subscribe((resp) => {
      this.isLoading = false;
      console.log('İmage Resp:', resp);
      const fileContext: {} = {
        _file: resp['irsaliye_image'],
        extension: 'png',
      };
      this.nbDialogService.open(FilePreviewerComponent,
        {
          context: { item: fileContext },
          closeOnBackdropClick: false,
        });
    }, err => {
      this.isLoading = false;

    });
  }




  addDays(days: number): Date {
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() - days);
    return futureDate;
  }
  getFilteredData() {
    this.isLoading = false;
    this.durdur = false;
    this.rows = [];
    this.form.get('page').setValue(0);
    this.getDepoDataList();
  }


  goToNextPage() {
    if (!this.isLoading && this.durdur === false) {
      this.form.get('page').setValue(Number(this.form.get('page').value) + 1);
      console.log('Giden page', Number(this.form.get('page').value));

      this.getDepoDataList();
    }
  }
  async getDepoDataList() {

    this.isLoading = true;
    const formData = removeAllNull(this.form.value);
    // if (excel) {
    //   formData['full'] = true;

    // }
    if (formData.filter && formData.filter.start_date) {
      formData.filter.start_date = dateFormSetter(formData.filter.start_date, 'start');
    }

    if (formData.filter && formData.filter.end_date) {
      formData.filter.end_date = dateFormSetter(formData.filter.end_date, 'end');
    }

    if (this.tedarikci_apas_id) {
      formData.tedarikci_apas_id = this.tedarikci_apas_id;
      this.filterControl = true;

    }
    return await this.depoService.filterController(formData).subscribe(
      async (data) => {
        data.forEach(element => {
          element = this.clearElement(element);
          this.rows = addOrUpdate(this.rows, element);
        });
        this.isLoading = false;
        return await this.rows;
      }, err => {
        this.isLoading = false;
        this.durdur = true;
      }, () => {
        this.isLoading = false;
      },
    );
  }


  clearElement(element) {
    element['created_time'] = convertDateWithTZ(element, 'created_time');
    element['total_amount'] = Number(element['total_amount']);
    element['total_count'] = Number(element['total_count']);
    return element;
  }





  hareketDuzenle(hareket) {
    this.nbDialogService.open(
      UrunHareketEditComponent, { context: { editData: hareket } },
    ).onClose.subscribe((resp) => {
      if (resp) {
        this.getFilteredData();
      }
    });

  }
  urunSil(row) {

    Swal.fire({
      ...responseKodlari.shureToDelete,
      ...{
        text: `${row.product_name} ürününün işlemini silmek istediğinizden emin misiniz?`,
      },
    } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        this.depoService.deleteHareket(row.id).subscribe(
          (resp) => {
            Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
            this.rows = [];
            this.getFilteredData();
          },
          (err) => {
            console.log('ERROR', err);
          },
        );
      }
    });


  }



  urunHareket(row) {
    this.nbDialogService.open(
      UrunHareketlerComponent,
      { context: { urun: row, editMod: true } },
    ).onClose.subscribe(data => {
      console.log('Data', data);
      if (data && data.refresh) {
        setTimeout(() => {
          this.getFilteredData();
        }, 300);
        // this.depoService.getRowAgain(row.warehouse_id, row.product_id).subscribe(resp => {
        //   row = resp;
        //   this.rows = addOrUpdate(this.rows, resp);
        //   this.rows = [...this.rows];
        // });
      }
    });
  }




  excelLoading: boolean = false;


  exportExcel() {
    this.excelLoading = true;

    const formData = removeAllNull(this.form.value);

    if (formData.filter && formData.filter.start_date) {
      formData.filter.start_date = dateFormSetter(formData.filter.start_date, 'start');
    }

    if (formData.filter && formData.filter.end_date) {
      formData.filter.end_date = dateFormSetter(formData.filter.end_date, 'end');
    }

    if (this.tedarikci_apas_id) {
      formData.tedarikci_apas_id = this.tedarikci_apas_id;
      this.filterControl = true;

    }

    formData['full'] = true;

    const toExcelRequest = this.depoService.filterController(formData).toPromise();

    toExcelRequest.then(resp => {
      this.excelLoading = false;

      const suAn = moment().format('DD/MM/YYYY');
      const excelTitle = `${suAn} Depo Rapor`;
      if (!this.filterControl && this.form.get('warehouse_id').value) {
        const basliklar = [
          { header: 'Ürün Adı', key: 'product_name', width: 50 },
          { header: 'Giren Miktar', key: 'positive_amount', width: 20 },
          { header: 'Harcanan Miktar', key: 'negatif_amount', width: 20 },
          { header: 'Kalan Miktar', key: 'total_amount', width: 20 },
          { header: 'Birim', key: 'unit', width: 30 },
        ];

        const data = [...resp];
        data.forEach((elm) => {
          elm.negatif_amount = Number(elm.positive_amount - elm.total_amount);
          elm.unit = getBirim(elm.unit);
          elm.negatif_amount = Number(elm.negatif_amount);
          elm.total_amount = Number(elm.total_amount);
        });

        exportExcelListeWithHeaders(basliklar, resp, excelTitle, 'icmal');
      } else if (!this.form.get('warehouse_id').value && !this.filterControl) {

        const basliklar = [
          { header: 'Ürün Adı', key: 'product_name', width: 50 },
          { header: 'Bulunduğu Depo', key: 'warehouse_name', width: 40 },
          { header: 'Miktar', key: 'total_amount', width: 20 },
          { header: 'Birim', key: 'unit', width: 20 },

        ];

        const data = [...resp];
        data.forEach((elm) => {
          elm.unit = getBirim(elm.unit);
          elm.amount = Number(elm.amount);
        });

        exportExcelListeWithHeaders(basliklar, resp, excelTitle, 'icmal');
      } else {
        const basliklar = [
          { header: 'Tarih', key: 'created_time', width: 20 },
          { header: 'Ürün Adı', key: 'product_name', width: 50 },
          { header: 'Marka', key: 'brand_name', width: 30 },
          { header: 'Miktar', key: 'amount', width: 20 },
          { header: 'Birim', key: 'unit', width: 20 },

          { header: 'İşlem', key: 'giris_turu', width: 30 },
          { header: 'Tedarikci', key: 'delivery_by_org_name', width: 40 },
          { header: 'İşlemi Yapan', key: 'created_by_name', width: 40 },
          { header: 'Karşı Taraf Personel', key: 'delivery_by_name', width: 40 },
          { header: 'Not', key: 'note', width: 70 },
        ];

        const data = [...resp];
        data.forEach((elm) => {
          elm.amount = Number(elm.amount);

          if (elm.created_time)
            elm.created_time = moment(elm.created_time, 'X').format('DD/MM/YYYY');
          if (elm.warehouse_in_out_type) {
            elm['giris_turu'] = setWarehouseInOutText(elm.warehouse_in_out_type);
          }
          if (elm.unit) {
            elm.unit = getBirim(elm.unit);
          }

          if (elm.irsaliye_image) elm.image = elm.irsaliye_image;
        });
        exportExcelListeWithHeaders(basliklar, data, excelTitle, 'icmal');
      }

    });


  }


  urunIslemYap(row?, type?) {

    const contextData = {
      warehouseId: row?.warehouse_id ? row?.warehouse_id : this.form.get('warehouse_id').value,
      row: row,
      type: type,
    };
    this.nbDialogService.open(UrunGirisiYapComponent, { context: { data: contextData } }).onClose.subscribe((resp) => {
      if (resp) {
        this.getFilteredData();
      }
    });
  }




}
