<nb-card status="primary" style="width: 25rem;height:25rem">
    <nb-card-header>
        Profil Fotoğrafı Güncelle
        <button nbButton (click)="closed()" size="tiny" class="m-close" style="background: none !important;
      color: #fff !important;
      float: right;
      font-size: 18px;">X</button>
    </nb-card-header>
    <nb-card-body class=" m-user">
        <div *ngIf="!currentFile" class="default-img " style="padding: 0px;">
            <div (click)="openFile(fileInput)" class="linked-div" style="position: absolute; width: 130px;  right: 20px; padding: 6px; background: #ffffff8c;">
                <nb-icon icon="plus-circle-outline" style="width: auto;   height: auto; color: #443939;"></nb-icon> <br>
                <span> Fotoğrafı Değiştir</span>
            </div>
           
            <img *ngIf="myKullanici.image" [src]="serializer.bypassSecurityTrustUrl(myKullanici.image)" width="100%">

            <input #fileInput type="file" accept="image/*" style="display:none" (change)="openFileDialog($event)">
        </div>

        <!--<input #fileInput type="file" accept="image/*" style="display:none" multiple (change)="openFileDialog($event)">-->
        <div>
            <apas-image-uploader *ngIf="currentFile" [Url]="url" [showDetay]="false" [Src]="currentFile" [method]="requestMethod" (success)="success($event)" (onRemove)="remove($event)" (successLoad)="successLoad()"> </apas-image-uploader>

        </div>
    </nb-card-body>

</nb-card>