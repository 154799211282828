import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { NgSelectComponent } from '@ng-select/ng-select';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari, tarih_format } from '../../../pages/constants';
import { addOrUpdate, convertDateWithTZ, removeAllNull } from '../../../pages/global-functions';
import { ChecklistService } from '../../../services/checklist.service';
import { CheckDuzenleComponent } from '../check-duzenle/check-duzenle.component';
import { CheckListTemplateComponent } from '../check-list-template/check-list-template.component';

@Component({
  selector: 'ngx-check-list-main',
  templateUrl: './check-list-main.component.html',
  styleUrls: ['./check-list-main.component.scss'],
  providers: [ChecklistService],
})
export class CheckListMainComponent implements OnInit {
  form: FormGroup;
  formArray: FormArray;
  instanceType: string = 'santiye';
  instanceId: number;
  instanceTitle: string;
  rows = [];
  sablonListesi = [];
  isLoading: boolean = false;
  tarih_format = tarih_format;

  sablonFormControl = new FormControl(null, Validators.required);

  constructor(
    private fb: FormBuilder,
    @Optional() private ref: NbDialogRef<CheckListMainComponent>,
    private checkListService: ChecklistService,
    private nbDialogService: NbDialogService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.form.get('object_id').setValue(this.instanceId);
    this.form.get('instance_type').setValue(this.instanceType);
    this.getCheckList();

    if (!(this.rows && this.rows.length > 0)) {
      // Daha Önce Oluşturulmuş bir şablon listesi yok ise şablonları çeker ve seçenek olarak sunar
      this.sablonList();
    }
  }

  initForm() {
    this.form = this.fb.group({
      object_id: [null, Validators.required],
      instance_type: [null, Validators.required],
    });
    this.formArray = this.fb.array([]);

    this.sablonFormControl.valueChanges.subscribe(val => {
      this.selectSablon(val);
    });
  }

  data;
  request;
  getCheckList() {
    if (!this.form.valid) {
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
      return;
    }
    if (this.request) this.request.unsubscribe();
    this.isLoading = true;
    const formData = removeAllNull(this.form.value);
    this.request = this.checkListService.list(formData).subscribe((resp: any) => {
      resp?.checkitem_set?.forEach(elm => {
        elm = this.clearData(elm);
        this.rows = addOrUpdate(this.rows, elm);
      });
      this.data = resp;
      this.isLoading = false;
    }, (err) => this.isLoading = false);
  }

  sablonList() {
    this.checkListService.sablonList(this.instanceType).subscribe((resp: any) => {
      console.log('Sablon List:', resp);
      resp.forEach(elm => {
        this.sablonListesi = addOrUpdate(this.sablonListesi, elm);
      });
    });
  }

  clearData(elm) {
    elm.checked_time ? elm.checked_time = convertDateWithTZ(elm, 'checked_time') : null;
    return elm;
  }

  addFormItem(data) {
    const formItem = this.fb.group({
      object_id: [null, Validators.required],
      instance_type: [null, Validators.required],
      title: [null, Validators.required],
      status: [null, Validators.required],
    });
    formItem.setValue(data);


    this.formArray.push(formItem);
  }

  @ViewChild('ngSelect') ngSelect: NgSelectComponent;
  sablonEkle(item?) {
    this.ngSelect.close();
    const context = {
      instanceType: this.instanceType,
      editData: item,
    };
    console.log('Context:', context);
    this.nbDialogService.open(
      CheckListTemplateComponent,
      { context: context, closeOnBackdropClick: false },
    ).onClose.subscribe(resp => {
      if (resp) {
        this.getCheckList();
        this.sablonList();
        this.sablonListesi = addOrUpdate(this.sablonListesi, resp);
        this.sablonFormControl.setValue(resp.id);
      }
    });
  }

  deleteSablon(item) {
    Swal.fire(
      {
        ...responseKodlari.shureToDelete,
        ...{
          text: `${item.title} başlıklı Şablonu silmek istediğinizden Emin Misiniz? Bu işlem Geri Alınamaz.`,
        },
      } as SweetAlertOptions).then(({ value }) => {
        if (value) {
          this.isLoading = true;

          this.checkListService.deleteSablonGroup(item.id).subscribe(() => {
            this.sablonListesi = this.sablonListesi.filter(x => x.id !== item.id);
            this.sablonFormControl.setValue(null);
            this.isLoading = false;
          }, (err => {
            Swal.fire(responseKodlari.unSuccesDeleted as SweetAlertOptions);
            this.isLoading = false;
          }));
        }
      });
  }

  checkListEkle() {
    const context = {
      instanceType: this.instanceType,
      instanceId: this.instanceId,
      editData: this.rows,
    };
    this.nbDialogService.open(
      CheckDuzenleComponent, {
      context: context,
      closeOnBackdropClick: false,
    }).onClose.subscribe(resp => {
      if (resp) {
        this.rows = [];
        this.getCheckList();
      }
    });
  }

  close() {
    this.ref.close();
  }
  selectedSablon = null;
  selectSablon(id) {
    this.selectedSablon = this.sablonListesi.find(x => x.id === id);
    console.log('selected sablon', this.selectedSablon);
  }

  sablonSetEt() {
    if (!this.sablonFormControl.valid) {
      this.sablonFormControl.markAsTouched();
      return;
    }
    const data = {
      instance_type: this.instanceType,
      object_id: this.instanceId,
      template_group: this.sablonFormControl.value,
    };
    this.isLoading = true;
    this.checkListService.setSablon(data).subscribe(resp => {
      this.isLoading = false;
      this.getCheckList();
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
    }, err => {
      this.isLoading = false;
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    });

  }

  setChecked(instance) {
    this.isLoading = true;
    this.checkListService.setCheckStatus(instance.identifier, !instance.status).subscribe(resp => {
      resp = this.clearData(resp);
      this.rows = addOrUpdate(this.rows, resp);
      this.isLoading = false;
    }, (err) => this.isLoading = false);
  }

}
