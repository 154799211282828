import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'aps-emptyselect',
  templateUrl: './emptyselect.component.html',
  styleUrls: ['./emptyselect.component.scss'],
})
export class EmptyselectComponent implements OnInit {

  @Input() message: string = 'Lütfen Bir Seçim Yapınız.';
  @Input() animation = '/assets/selectone.json';
  constructor(private cd: ChangeDetectorRef) { }
  options: AnimationOptions = {
    path: this.animation,
  };
  ngOnInit() {
  }
}
