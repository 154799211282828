import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import {  of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { ExchangesModel } from '../../models/exchanges.model';
import { MuhasebeService } from '../../muhasebe-service/muhasebe.service';

import * as exchangeActions from './exchanges.actions';


@Injectable()
export class ExchangeEffects {

    loadExchanges$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(exchangeActions.loadExchanges),
            mergeMap(() => this.muhasebeService.getExchageList()
                .pipe(
                    map((exchange: ExchangesModel) => {
                        return exchangeActions.loadExchangesSuccess({ exchanges: exchange });
                    }),
                    catchError(() => of(exchangeActions.loadExchangesFail('Exchange bilgisi alınamadı.'))),
                )),
        );
    });


    constructor(
        private actions$: Actions,
        private muhasebeService: MuhasebeService,
    ) {

    }

}
