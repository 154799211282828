import { Component } from '@angular/core';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { YetkiService } from '../../../services/yetki.service';
import { MuhasebeService } from '@muhasebe/muhasebe-service/muhasebe.service';

@Component({
  selector: 'ngx-ayarlar',
  templateUrl: './ayarlar.component.html',
  styleUrls: ['./ayarlar.component.scss'],
  providers: [MuhasebeService, OrganizasyonService, YetkiService],
})
export class AyarlarComponent {

}
