import { NbGlobalLogicalPosition, NbToastrConfig, NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import * as cariActions from '../pages/muhasebe/muhasebe-store/cari/cari.actions';
import * as hesapActions from '../pages/muhasebe/muhasebe-store/hesaplar/hesap.actions';
import * as alisSatisActions from '../pages/muhasebe/muhasebe-store/alis-satis/alis-satis.actions';
import * as muhasebeAkisActions from '../pages/muhasebe/muhasebe-store/muhasebeAkis/muhasebe-akis.actions';
import * as tagActions from '../store/tags/tags.actions';


let socketController = null;
class WebSocketFunctions {

    toasterConfig = {
        duration: 10000,
        limit: 3,
        position: NbGlobalLogicalPosition.TOP_END,
    };

    constructor(
        public store: Store<{}>,
        private toastrService: NbToastrService,
    ) {
    }


    public getContentValues(data) {
        console.log('socket data : ', data);
        switch (data.model_id) {

            case 'muhasebe.baseacoountmodel':
                this.store.dispatch(hesapActions.addHesapItem({ hesapItem: data.content }));
                break;
            case 'muhasebe.cari':
                this.store.dispatch(cariActions.addCariItem({ cariItem: data.content }));
                break;

            case 'muhasebe.carihareket':
                // Cari Hakeret Verileri Güncellenecek.
                if (!data.not_set_akis) {
                    // Cari Detay Sayfası Üzerinden geliyorsa akışa ekleme.
                    this.store.dispatch(muhasebeAkisActions.addMuhasebeAkisItem({ akisItem: data }));
                }
                if (data.content.sender_cari_id) {
                    this.store.dispatch(cariActions.updateCari({ id: data.content.sender_cari_id }));
                }
                if (data.content.reciver_cari_id) {
                    this.store.dispatch(cariActions.updateCari({ id: data.content.reciver_cari_id }));
                }
                if (data.content.sender_account) {
                    this.store.dispatch(hesapActions.updateHesap({ id: data.content.sender_account }));
                }
                if (data.content.reciver_account) {
                    this.store.dispatch(hesapActions.updateHesap({ id: data.content.reciver_account }));
                }


                if (data.content.old_sender_cari_id) {
                    this.store.dispatch(hesapActions.updateHesap({ id: data.content.old_sender_cari_id }));
                }
                if (data.content.old_reciver_cari_id) {
                    this.store.dispatch(hesapActions.updateHesap({ id: data.content.old_reciver_cari_id }));
                }
                if (data.content.old_sender_account) {
                    this.store.dispatch(hesapActions.updateHesap({ id: data.content.old_sender_account }));
                }
                if (data.content.old_reciver_account) {
                    this.store.dispatch(hesapActions.updateHesap({ id: data.content.old_reciver_account }));
                }
                break;

            case 'entegrasyon.invoice':
                if (data.content.tip === 'alis') {
                    this.store.dispatch(alisSatisActions.addUpAlisItem({ Alis: data.content, decrypte: true }));
                } else if (data.content.tip === 'satis') {
                    this.store.dispatch(alisSatisActions.addUpSatisItem({ Satis: data.content, decrypte: true }));
                }

                if (data.show_toastr) {
                    const config = { ...this.toasterConfig, ...{ status: data.status } };
                    if (data.status === 'danger') {
                        config.duration = 0;
                    }
                    this.toastrService.show(data.message, data.title, config);
                }
                break;

            case 'tags.taggroup':

                switch (data.content.status) {
                    case 'deleted':

                        this.store.dispatch(tagActions.deleteTagGroup({ tagGroupId: data.content.id }));
                        break;
                    case 'added':

                        this.store.dispatch(tagActions.addTagItem({ tagGroupItem: data.content }));
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }
    }
}

export function getSocketControllerConnector(store: Store, toasterService: NbToastrService): WebSocketFunctions {
    if (socketController === null) {
        socketController = new WebSocketFunctions(store, toasterService);
    }
    return socketController;
}