import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { telefonKodu } from '../../../pages/constants';

@Component({
  selector: 'apas-telefon-input',
  templateUrl: './apas-telefon-input.component.html',
  styleUrls: ['./apas-telefon-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ApasTelefonInputComponent),
      multi: true
    }
  ],
})
export class ApasTelefonInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input('labelShow') labelShow?: boolean;
  bayrakPath = '../../../../assets/images/bayrak/';
  ulkem: FormControl = new FormControl(telefonKodu[0]);

  telefon_kodu = telefonKodu;

  @Input()
  public get value(): string {
    return this._value;
  }
  public set value(obj: string) {
    this.switch(obj);
  }

  @Input()
  public disabled: boolean = false;

  @Output()
  public change: EventEmitter<string> = new EventEmitter<string>();

  public onChange: (value: string) => {};
  public onTouched: () => void;

  private _value: string;

  constructor(private _cdr: ChangeDetectorRef) { }
  ngAfterViewInit(): void {
    this.ulkem.valueChanges.subscribe(x => {
      setTimeout(() => {

        this.switch(this._value ? this._value.substr(2) : null);
      }, 100);
    });

    this.ulkem.setValue(telefonKodu[0]);
  }

  public switch(value: any) {
    if (value !== this.value && this.disabled === false) {
      this._value = value;
      if (value) {
        const valueSpltControl = value.split('');
        if (valueSpltControl[0] === '0') {
          this.value = this._value.substring(1);
        }
      }

      let sendValue = this.ulkem.value.kod + this.value;
      this.change.emit(sendValue);

      if (this.onChange) {
        this.onChange(sendValue);
      }

      this._cdr.markForCheck();
    }
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
