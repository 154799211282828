

import { Component, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject } from 'rxjs';
import { DepoModel } from '../../../models/depo.model';
import { addOrUpdate } from '../../../pages/global-functions';
import { DepoService } from '../../../services/depo.service';
import { ControlValueAccessorConnector } from '../../../shared/control-value-accessor-connector';
import { DepoEkleComponent } from '../../depolar/depo-ekle/depo-ekle.component';

@Component({
    selector: 'depo-select',
    template: `
  <ng-select
  class="noBgSelect outoo uzun-select"
  appendTo="body"
  [placeholder]="'DEPO.DEPOSECINIZ' | translate"
   style="border: 1px solid #ff7043;border-radius: 5px;height: 42px;"
   bindLabel="title"
   bindValue="id"
   [items]="depolar"
   [searchable]="true"
   [clearable]="true"
   [searchable]="true"
   #depo
   [formControl]="control"
 [closeOnSelect]="true"
 (change)="depoSelect($event)"
 >
    <ng-template ng-label-tmp let-item="item">
        <div class="d-flex align-items-center">
              <b>Seçilen Depo:</b> <b style="color:#ff7043;"> {{item.title}}</b>
        </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div class="d-flex align-items-center justify-content-between">
        <div>
          <b>{{item.title}}</b>
          <small *ngIf="item?.santiye_name" class="label">( {{item.santiye_name}} )</small>
        </div>
        <div (click)="yeniDepo(depo,item)">
            <nb-icon icon="edit-outline"></nb-icon>
        </div>
        </div>
    </ng-template>
    <ng-template ng-footer-tmp>
        <div (click)="yeniDepo(depo)" class="linked-div yeniEkle">
        {{ 'DEPO.DEPOEKLE' | translate }}
        </div>
    </ng-template>`
    ,
    providers: [
        DepoService,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DepoSelectComponent),
            multi: true,
        },
    ],
})
export class DepoSelectComponent extends ControlValueAccessorConnector implements OnInit, OnChanges {

    @Input() formControl!: FormControl;

    @Input() formControlName: string;

    isLoading: boolean = false;

    depolar: DepoModel[];

   
    @Input() default_depo = false;
    @Output() change: EventEmitter<any> = new EventEmitter();
    constructor(
        private depoService: DepoService,
        injector: Injector,
        private nbDialogService: NbDialogService,
    ) {
        super(injector);
    }
    ngOnChanges(changes: SimpleChanges): void {

    }

    ngOnInit(): void {
        this.getDepoList();

    }
    getDepoList() {
        this.depoService.getDepo().subscribe(data => {
            this.depolar = data;
            if (this.default_depo) {
                this.control.setValue(this.depolar[0].id);
            }
        });
    }



    yeniDepo(selectComponent: NgSelectComponent, data?) {
        selectComponent.close();
        this.nbDialogService.open(DepoEkleComponent,
            { context: { editData: data } }).onClose.subscribe((resp: any) => {
                if (resp) {
                    this.depolar = [...addOrUpdate(this.depolar, resp)];
                    this.control.setValue(resp.id);
                    this.depoSelect(resp);
                    if(resp.delete){
                        this.getDepoList();
                    }
                }
            });
    }

    depoSelect(event) {
        this.change.emit(event);
    }


}

