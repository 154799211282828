<div class="aps-splt" [formGroup]="form" [nbSpinner]="isLoading">
    <h6 *ngIf="headerShow">Gelen Teklifler</h6>
    <nb-card>
        <!--Filter Template-->
        <apas-filter [parentComponent]="this" [bodyTemplate]="body" [islemlerTemplate]="islemler"
            [araText]="'Teklif Ara'" [formValue]="form" formControlName="search" [showFilterButton]="filterShow">
        </apas-filter>

        <ng-template #body>
            <div class="form-group">

                <!--Tedarikçi-->
                <label class="label form-label"><b>{{'TEKLIFLER.TEKLIFVERENFIRMA'| translate}}</b></label>
                <ng-select #tedarikci [items]="tedarikciler" bindValue="company_id" bindLabel="company_name"
                    appendTo="body" [addTag]="false" [placeholder]="'TEKLIFLER.TEKLIFVERENFIRMA' | translate"
                    formControlName="teklif_veren_id" class="ngBottom">
                </ng-select>

                <!--// Tedarikçi-->

                <label class="label form-label"><b> {{ 'BASLANGICTARIHI' | translate }}</b></label>
                <input nbInput fullWidth [placeholder]="'BASLANGICTARIHI' | translate" [nbDatepicker]="startDatePicker"
                    formControlName="start_date" dateOnly />
                <nb-datepicker #startDatePicker></nb-datepicker>

                <label class="label form-label"><b> {{ 'BITISTARIHI' | translate }}</b></label>
                <input nbInput fullWidth [placeholder]="'BITISTARIHI' | translate" [nbDatepicker]="endDatePicker"
                    formControlName="end_date" dateOnly />
                <nb-datepicker #endDatePicker></nb-datepicker>

            </div>
        </ng-template>
        <ng-template #islemler>
            <button nbPopoverPlacement="bottom" size="small" status="warning" nbButton class="ekleIcon"
                 (click)="goBack()">
                <nb-icon icon='plus-outline'> </nb-icon> {{'YENIEKLE'| translate}}
            </button>
        </ng-template>
        <!--// Filter Template-->
    </nb-card>
    <div class="row py-2 d-flex justify-content-end pr-4">
        <nb-toggle formControlName="is_active">{{form.get('is_active').value ? 'Aktif' : 'Arşivlenmiş'}}
            Anlaşmaları Göster</nb-toggle>


    </div>

    <nb-card class="anaTable">
        <nb-card-header>
            <div class="row m-0 p-0 tableHeader">
                <div class="col-md-3">
                    <span>Tarih</span>
                </div>

                <div class="col-md">
                    <span>Talep Grubu </span>
                </div>
                <div class="col-md-4">
                    <span>İşlemler</span>
                </div>

            </div>
        </nb-card-header>
        <nb-card-body>
            <nb-list *ngIf="teklifler && teklifler.length > 0" style="background: #fff;">
                <nb-list-item class="aps-hover p-0 listCustom linked-div row p-0 m-0 w-100"
                    *ngFor="let row of teklifler;let i = index;" nbInfiniteList listenWindowScroll [threshold]="100"
                    (bottomThreshold)="goToNextPage()">
                    <nb-accordion class="w-100" style="box-shadow: none; ">
                        <nb-accordion-item>
                            <nb-accordion-item-header class="p-2">
                                <span class="col-md-3 p-0  m-0 d-flex justify-content-left align-middle ">
                                        <tarih-col class="w-100" [index]="i" [tarih]="row?.created_time"></tarih-col>
                                </span>
                                <div class="col-md">
                                    <span>
                                        {{row.title}}
                                    </span>
                                </div>
                                <div class="col-md-4">
                                    <button nbButton size="small" (click)="addNewTeklif(row)" *ngIf="!row.is_approved"
                                        status="primary">{{'GELENTEKLIFLERLIST.YENITEKLIFEKLE'|translate}}</button>
                                    <!--Teklif Formu İndir-->
                                    <button nbButton [nbTooltip]="'TEKLIFLER.EXCELOLARAK'|translate" size="small"
                                        (click)="exportToExcel(row)" style="background: green !important;color:#fff;">
                                        <nb-icon icon='cloud-download-outline'></nb-icon>
                                        Excele Aktar
                                    </button>
                                    <!--// Teklif Formu İndir-->
                                </div>
                            </nb-accordion-item-header>
                            <nb-accordion-item-body style="background-color: #fff; padding: 0 !important;">
                                <div [style]="row.firmalar.length > 4 ? 'width:150rem;':''">
                                    <table class=" table-striped  table table-bordered table-sm">
                                        <tr>
                                            <th scop="col" width="300" nowrap>{{ 'TEKLIFLER.URUN' | translate }}</th>
                                            <th nowrap width="400"
                                                *ngFor="let item of row.firmalar; let index = index;">
                                                <div class="d-flex justify-content-between">
                                                    <div>
                                                        {{item.name}}
                                                        <button *ngIf="item.dosya" nbButton status="info" size="tiny"
                                                            (click)="teklifDownload(item.dosya.id)"
                                                            [nbSpinner]="dosyaSpinner">Teklif Dosyasını İndir</button>
                                                    </div>
                                                    <div>
                                                        <button #popover nbButton size="tiny" [nbPopover]="userRef"
                                                            *ngIf="!item?.is_approved && !row.is_approved">
                                                            <nb-icon icon="more-vertical-outline"></nb-icon>
                                                        </button>
                                                        <button nbButton disabled size="small" *ngIf="item?.is_approved"
                                                            status="success" class="onayli"
                                                            style="background-color: #00d68f !important; border-color: #00d68f !important; color: #ffffff !important;">{{
                                                            'TEKLIFLER.TEKLIFONAYLANDI' | translate }}</button>

                                                        <ng-template #userRef>
                                                            <nb-card
                                                                style="margin-bottom: 0px; border-top-width: 0rem !important;">
                                                                <nb-card-body>


                                                                    <div class="col-md-12">
                                                                        <button nbButton size="tiny" outLine
                                                                            status="primary"
                                                                            (click)="onaylaTeklifItem(item);"
                                                                            style="width: 180px;"
                                                                            *ngIf="!item?.is_approved">
                                                                            {{'GELENTEKLIFLERLIST.ONAYLA'|translate}}
                                                                        </button><br>

                                                                        <button nbButton size="tiny" outLine
                                                                            status="info" style="width: 180px;"
                                                                            (click)="teklifiGuncelle(row, item);"
                                                                            *ngIf="item?.can_edit">
                                                                            {{'GELENTEKLIFLERLIST.GUNCELLE'|translate}}
                                                                        </button><br>

                                                                        <button nbButton size="tiny" outLine
                                                                            status="warning" style="width: 180px;"
                                                                            (click)="teklifiSil(item.teklif_veren_id,row.id)"
                                                                            *ngIf="item?.can_edit">
                                                                            {{'GELENTEKLIFLERLIST.SIL'|translate}}
                                                                        </button><br>
                                                                    </div>
                                                                </nb-card-body>
                                                            </nb-card>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                        <tbody>
                                            <tr *ngFor="let item of row.urunler; let i = index;">
                                                <td width="300" nowrap [nbTooltip]="item?.title"
                                                    style="white-space: pre-line;">
                                                    {{item?.title | slice : 0 : 160}} ...
                                                </td>
                                                <td *ngFor="let firmaItem of row.firmalar">
                                                    <div class="row pr-3 pl-3">
                                                        <ng-container *ngIf="findFormData(firmaItem, item)">

                                                            <div class="col-11">
                                                                <strong>
                                                                    {{findFormData(firmaItem, item)?.unit_price| number
                                                                    :'0.2-2' }}
                                                                    {{getMoneyCurrency(findFormData(firmaItem,
                                                                    item)?.amount_currency)}}
                                                                </strong> x {{findFormData(firmaItem,
                                                                item)?.teklif_amount}}
                                                                {{findFormData(firmaItem, item)?.unit ?
                                                                convert.getBirim(findFormData(firmaItem, item)?.unit)
                                                                : '-'}}
                                                                =
                                                                {{ findFormData(firmaItem, item)?.unit_price *
                                                                findFormData(firmaItem, item)?.teklif_amount | number
                                                                :'0.2-2'}}
                                                                {{getMoneyCurrency(findFormData(firmaItem,
                                                                item)?.amount_currency)}}
                                                                <span
                                                                    *ngIf="findFormData(firmaItem, item)?.brand_id">({{findFormData(firmaItem,
                                                                    item)?.brand_id?.title}})</span>
                                                            </div>
                                                            <div class="col-1" [nbPopover]="historyRef"
                                                                [nbPopoverTrigger]="'click'"
                                                                (click)="getHistory(findFormData(firmaItem, item).identifier)">
                                                                <nb-icon icon="question-mark-circle-outline"></nb-icon>
                                                            </div>
                                                            <div class="col-12" style="color: blue;">
                                                                {{findFormData(firmaItem, item)?.note ?
                                                                findFormData(firmaItem,
                                                                item)?.note : '-'}}
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <ng-template #historyRef>
                                                        <nb-card class="m-header" style="height:auto; width: 400px;"
                                                            [nbSpinner]="isHistoryLoading">
                                                            <div class="row"
                                                                *ngFor="let item of historyList; let i = index">
                                                                <div [class]="i%2 == 0 ? 'col bg-lightgray': 'col'">
                                                                    <div class="row">
                                                                        <div class="col-6">
                                                                            {{'GELENTEKLIFLERLIST.GUNCELLEYEN'|translate}}
                                                                        </div>
                                                                        <div class="col-6">{{item?.updated_by}}</div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-6">
                                                                            {{'GELENTEKLIFLERLIST.GUNCELLEMEZAMANI'|translate}}
                                                                        </div>
                                                                        <div class="col-6">
                                                                            {{item?.update_time * 1000 | date :
                                                                            tarihFullFormat}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-6">
                                                                            {{'GELENTEKLIFLERLIST.TEKLIFFIYATI'|translate}}
                                                                        </div>
                                                                        <div class="col-6">
                                                                            {{item?.unit_price}}
                                                                            {{getMoneyCurrency(item?.amount_currency)}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-6">
                                                                            {{'GELENTEKLIFLERLIST.TEKLIFMIKTARI'|translate}}
                                                                        </div>
                                                                        <div class="col-6">
                                                                            {{item?.teklif_amount}}
                                                                            {{convert.getBirim(item.unit)}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col"
                                                                            style="width: 1px; background-color: black;">

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </nb-card>
                                                    </ng-template>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colspan="1" class="text-right"><b>{{ 'TEKLIFLER.TOPLAM' | translate
                                                        }}
                                                    </b></td>

                                                <td *ngFor="let item of row.firmalar">

                                                    <button nbButton size="tiny" class="try" status="warning">
                                                        TRY:{{ toplamTutarHesapla(item).TL | number :'0.2-2'}}
                                                    </button>
                                                    <button nbButton status="warning" size="tiny" class="dolar">
                                                        {{'GELENTEKLIFLERLIST.DOLAR'|translate}}{{
                                                        toplamTutarHesapla(item).USD |
                                                        number :'0.2-2'}}
                                                    </button>
                                                    <button nbButton status="warning" size="tiny" class="euro">
                                                        EURO: {{ toplamTutarHesapla(item).EUR | number :'0.2-2'}}
                                                    </button>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td colspan="1" class="text-right">
                                                    <b>{{ 'TEKLIFLER.ISLEMLER' | translate }}</b>
                                                </td>
                                                <td *ngFor="let item of row.firmalar">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="row">
                                                                <div class="col-4">
                                                                    <b>{{ 'TEKLIFLER.TEKLİFALAN' | translate }}</b>
                                                                </div>
                                                                <div class="col-8">
                                                                    {{item?.created_by_name}}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="row">
                                                                <div class="col-4">
                                                                    <b>{{'GELENTEKLIFLERLIST.TEKLIFTARIHI'|translate}}
                                                                    </b>
                                                                </div>
                                                                <div class="col-8">
                                                                    {{item?.date | date:tarih_format}}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div class="d-flex justify-content-end">
                                        <button nbButton size="small" *ngIf="row?.is_active" status="info"
                                            (click)="teklifGrubunuArsivle(row, false)">
                                            <nb-icon icon="archive-outline"></nb-icon>
                                            Arşivle
                                        </button>
                                        <button nbButton size="small" *ngIf="!row?.is_active" status="info"
                                            (click)="teklifGrubunuArsivle(row, true)">
                                            <nb-icon icon="archive-outline"></nb-icon>
                                            Arşivden Çıkart
                                        </button>
                                        <button nbButton size="small" class="silButon" (click)="teklifGrubuSil(row)">
                                            <nb-icon icon="trash-outline"> </nb-icon> Sil
                                        </button>

                                    </div>
                                </div>
                            </nb-accordion-item-body>
                        </nb-accordion-item>
                    </nb-accordion>
                </nb-list-item>
            </nb-list>
            <aps-emptylist [message]="'Teklif Bulunamadı ... '"
                *ngIf="!isLoading && (!teklifler || teklifler.length === 0)"></aps-emptylist>
            <aps-loadinglist class="animated slideInUp" *ngIf="isLoading && (!teklifler || teklifler.length == 0)">
            </aps-loadinglist>
        </nb-card-body>
    </nb-card>
</div>