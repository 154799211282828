<nb-card style="height: 600px; width: 900px">
  <nb-card-header>
    <h1>{{ 'YARDIM.APASNEDIR' | translate }}</h1>
  </nb-card-header>

  <nb-card-body>
    <nb-accordion>
      <nb-accordion-item>
        <nb-accordion-item-header  style="background-color: bisque;"
          ><b>Videolar</b></nb-accordion-item-header
        >
        <nb-accordion-item-body>
          <div style="width: 200px; font-weight: 500; float:left">
            <a target="_blank" href="https://youtu.be/1p9RExAuRII">Apas+ a Bireysel Kayıt</a>
          <iframe
            width="187"
            height="115"
            src="https://www.youtube.com/embed/1p9RExAuRII"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
          <div style="width: 200px; font-weight: 500; float:left">
            <a target="_blank" href="https://youtu.be/CgCk3fe5NmE">Puantaj Nasıl Eklenir</a>
          <iframe
            width="187"
            height="115"
            src="https://www.youtube.com/embed/CgCk3fe5NmE"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
          <div style="width: 200px; font-weight: 500; float:left">
            <a target="_blank" href="https://youtu.be/gllsadAaCPU">Yeni Firma Eklemek</a>
          <iframe
            width="187"
            height="115"
            src="https://www.youtube.com/embed/gllsadAaCPU"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
          <div style="width: 200px; font-weight: 500; float:left">
            <a target="_blank" href="https://youtu.be/GSgAbHUIZnI">Şantiye Nasıl Eklenir</a>
          <iframe
            width="187"
            height="115"
            src="https://www.youtube.com/embed/GSgAbHUIZnI"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
          <div style="width: 200px; font-weight: 500; float:left">
            <a target="_blank" href="https://youtu.be/oFhhEH86Q8c">Firmanıza Personel Eklemek</a>
          <iframe
            width="187"
            height="115"
            src="https://www.youtube.com/embed/oFhhEH86Q8c"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header
          ><b>{{ 'YARDIM.APASNE' | translate }}</b></nb-accordion-item-header
        >

        <nb-accordion-item-body>
          <ul>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.APASINSAAT' | translate }} <br />
                {{ 'YARDIM.HERHANGIBIR' | translate }}
              </p>
              <p class="mb-1">
                {{ 'YARDIM.APASSANTIYE' | translate }}<br /><br />
                {{ 'YARDIM.GUNICERISINDE' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.TASERON' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.SATINALMA' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.WHATSAPP' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.TASERONLARIN' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.DUZENSIZ' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.HANGIGUNE' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.SIZEGELEN' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.DEPOLARINIZDAKI' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.TOPLANTITUTANAKLARINIZ' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.PERSONELINIZE' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.LABORATUVAR' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.BINEKARACLARINIZA' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.ISMAKINELERINIZIN' | translate }}
                <br />
                {{ 'YARDIM.GIBIBIR' | translate }}
                <b>{{ 'YARDIM.SISTEMATIK' | translate }}</b
                >.
              </p>
            </li>
          </ul>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header
          ><b>{{ 'YARDIM.ANASAYFA' | translate }}</b></nb-accordion-item-header
        >
        <nb-accordion-item-body>
          <ul>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.SANTIYELERINIZIN' | translate }}
              </p>
              <p class="mb-1">
                {{ 'YARDIM.SANTIYE' | translate }}<b>></b
                >{{ 'YARDIM.IKONUNA' | translate }}
              </p>
              <p class="mb-1">
                {{ 'YARDIM.SANTIYEADININ' | translate }}
              </p>
            </li>

            <li>
              <p class="mb-1">
                {{ 'YARDIM.GOREVLERBOLUMUNDEN' | translate }}
              </p>
            </li>
            <li>
              <p class="mb-1">
                {{ 'YARDIM.HATIRLATMALARBOLUMUNDEN' | translate }}
              </p>
            </li>
            <li>
              <p class="mb-1">
                <li>
                  <p class="mb-1">
                    {{ 'YARDIM.TAKVIMBOLUMU' | translate }}
                  </p>
                </li>
              </p>
            </li>
          </ul>
        </nb-accordion-item-body>
      </nb-accordion-item>

      <nb-accordion-item>
        <nb-accordion-item-header
          ><b>{{
            'YARDIM.MENUPROJELER' | translate
          }}</b></nb-accordion-item-header
        >
        <nb-accordion-item-body>
          <ul>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.YENIPROJE' | translate }}
              </p>
              <p class="mb-1">
                {{ 'YARDIM.PROJEILE' | translate }}
              </p>
              <p class="mb-1">
                {{ 'YARDIM.PROJEEKLERKEN' | translate }}
              </p>
              <p class="mb-1">
                {{ 'YARDIM.PROJEEKLERKENILK' | translate }}
              </p>
              <img src="assets/images/yardim/isveren_kutu.png" />
            </li>
          </ul>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header
          ><b>{{
            'YARDIM.MENUANLASMALAR' | translate
          }}</b></nb-accordion-item-header
        >
        <nb-accordion-item-body>
          <ul>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.ANLASMAEKLE' | translate }}
              </p>
              <p class="mb-1">
                {{ 'YARDIM.DIGERFIRMALARIN' | translate }}
              </p>
            </li>
          </ul>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header
          ><b>{{
            'YARDIM.MENUTASERONLAR' | translate
          }}</b></nb-accordion-item-header
        >
        <nb-accordion-item-body>
          <ul>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.BUSAYFADA' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.YENITAS' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.APASAKAYITLIOLMAYAN' | translate }}
              </p>
              <img src="assets/images/yardim/apas_disi_taseron.png" />
              <p class="mb-1">
                {{ 'YARDIM.APASDISIFIRMA' | translate }}
              </p>
            </li>
          </ul>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header
          ><b>{{
            'YARDIM.MENUPUANTAJLAR' | translate
          }}</b></nb-accordion-item-header
        >
        <nb-accordion-item-body>
          <ul>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.TASERONLARINIZVEYA' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.YENIPUANTAJ' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.GIRDIGINIZPUANTAJLARI' | translate }}
              </p>
            </li>
          </ul>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header
          ><b>{{
            'YARDIM.MENUHAKEDISLER' | translate
          }}</b></nb-accordion-item-header
        >
        <nb-accordion-item-body>
          <ul>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.IDARIVETASERON' | translate }}<br />
                {{ 'YARDIM.IDARIHAKEDIS' | translate }}<br />
                {{ 'YARDIM.DOSYAYUKLEME' | translate }}
                {{ 'YARDIM.YENIHAKEDISEK' | translate }}
              </p>
            </li>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.TASERONHAKEDISMODUL' | translate }} <br />
                {{ 'YARDIM.METRAJ' | translate }}
              </p>
            </li>
          </ul>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header
          ><b>{{ 'YARDIM.MENULABA' | translate }}</b></nb-accordion-item-header
        >
        <nb-accordion-item-body>
          <ul>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.PROJENIZI' | translate }} <br />
                {{ 'YARDIM.DILERSENIZ' | translate }} <br />
                {{ 'YARDIM.BOYLECE' | translate }}
              </p>
            </li>
          </ul>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item>
        <nb-accordion-item-header
          ><b>{{
            'YARDIM.MENUTEKLIF' | translate
          }}</b></nb-accordion-item-header
        >
        <nb-accordion-item-body>
          <ul>
            <li style="margin-bottom: 8px">
              <p class="mb-1">
                {{ 'YARDIM.GEREKSATINALMA' | translate }}<br />
                {{ 'YARDIM.GEREKTASERON' | translate }}
                {{ 'YARDIM.GECMISTE' | translate }}
              </p>
            </li>
          </ul>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </nb-card-body>
</nb-card>
