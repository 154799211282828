import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { birim, responseKodlari } from '../../../pages/constants';
import { ProductService } from '../../../services/product.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { removeAllNull } from '../../../pages/global-functions';

@Component({
  selector: 'urun-ekle',
  templateUrl: './urun-ekle.component.html',
  styleUrls: ['./urun-ekle.component.scss'],
  providers: [ProductService],
  encapsulation: ViewEncapsulation.None,
})
export class UrunEkleComponent implements OnInit {
  birim = birim;
  form: FormGroup;
  data;
  // addProduct
  constructor(
    private fb: FormBuilder,
    private ref: NbDialogRef<UrunEkleComponent>,
    private _productService: ProductService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      id: [],
      name: [null, Validators.required],
      unit: [null, Validators.required],
      barkod: [null],
      note: [],
    });
    if (this.data) {
      this.form.patchValue(this.data);
    }
  }
  closed() {
    this.ref.close();
  }
  urunEkle() {
    // 
    if (this.form.valid) {
      let formVal = this.form.value;
      formVal = removeAllNull(formVal);
      if (!this.data) {
        this._productService.addProduct(formVal).subscribe((resp) => {
          Swal.fire(responseKodlari.successProgress as SweetAlertOptions).then(() => this.ref.close());
          this.ref.close(resp);
        }, () => {
          Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
        });
      } else {

        this._productService.patchProduct(formVal).subscribe((resp) => {
          Swal.fire(responseKodlari.successProgress as SweetAlertOptions).then(() => this.ref.close());
          this.ref.close(resp);
        }, () => {
          Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
        });
      }

    } else {
      Object.keys(this.form.controls).forEach(key => {
        this.form.controls[key].markAsTouched();
      });
    }
  }
}
