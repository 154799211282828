<nb-card class="cari_ekle modalType animated fadeInRight" size="giant" id="kasaEkle" [formGroup]="form"
  style="width: 500px;">
  <nb-card-header class="header">
    <span class="baslik" *ngIf="type== cariTypes.taseron">Taşeron Ekle</span>
    <span class="baslik" *ngIf="type== cariTypes.tedarikci">Tedarikçi Ekle</span>
    <span class="baslik" *ngIf="type== cariTypes.musteri ">Müşteri Ekle</span>
    <span class="baslik" *ngIf="type== cariTypes.personel ">Personel Ekle</span>
    <nb-icon class="linked-div closeIcon" icon='close-outline' (click)="kapat()">
    </nb-icon>
  </nb-card-header>
  <!-- Taşeron Ekle -->
  <nb-card-body *ngIf="type==cariTypes.taseron" [nbSpinner]="isLoading">
    <div class="row">

      <div class="col-md-12 form-group">
        <label class="label">Taşeron Adı :</label>
        <input nbInput placeholder="Taşeron Adı" formControlName="name" fullWidth apasInvalid>

      </div>
      <div class="col-md-12 form-group" [nbTooltip]="'MUHASEBE.ALACAKBORCTOOLTIP' | translate">
        <label class="label"> Açılış Fişi : </label>
        <money-currency-input moneyControlName="open_amount" [encrypt]="true" [negative]="true"></money-currency-input>
      </div>
    </div>
    <div class="text-center">
      <button nbButton size="small" status="primary" (click)="postForm()" [disabled]="isLoading">
        {{"MUHASEBE.KAYDET"|translate}}</button>
    </div>
  </nb-card-body>

  <!-- TEDARIKCI BODY -->
  <nb-card-body *ngIf="type == cariTypes.tedarikci" [nbSpinner]="isLoading">
    <div class="row">
      <div class="col-md-12 form-group">
        <label class="label">Tedarikçi Adı :</label>
        <input nbInput placeholder="Tedarikçi Adı" formControlName="name" fullWidth apasInvalid>
      </div>
      <div class="col-md-12 form-group" [nbTooltip]="'MUHASEBE.ALACAKBORCTOOLTIP' | translate">
        <label class="label"> Açılış Fişi : </label>
        <money-currency-input moneyControlName="open_amount" [encrypt]="true" [negative]="true"></money-currency-input>
      </div>
    </div>
    <div class="text-center">
      <button nbButton size="small" status="primary" (click)="postForm()" [disabled]="isLoading">
        {{"MUHASEBE.KAYDET"|translate}}</button>
    </div>
  </nb-card-body>
  <!--MÜŞTERİ BODY-->
  <nb-card-body *ngIf="type == cariTypes.musteri" [nbSpinner]="isLoading">
    <div class="row">
      <div class="col-md-12 d-flex justify-content-center centerTab">
        <nb-tabset (changeTab)="musteriTypeChange($event)" class="centerNbTab">
          <nb-tab [tabId]="musteriType.tuzel" class="p-0" tabTitle="Tüzel Kişi"
            [active]="form.get('musteri_type').value === musteriType.tuzel "></nb-tab>
          <nb-tab [tabId]="musteriType.gercek" class="p-0" tabTitle="Gerçek Kişi"
            [active]="form.get('musteri_type').value === musteriType.gercek ">
          </nb-tab>
        </nb-tabset>
      </div>

      <div class="col-md-12 form-group" >

        <label for="adi" class="form-label label"> Cari Adı :</label>
        <input id="adi" type="text" fullWidth nbInput [placeholder]="'Cari Adı'" formControlName="name" apasInvalid>

      </div>

      <div class="col-md-12 form-group" [nbTooltip]="'MUHASEBE.ALACAKBORCTOOLTIP' | translate">
        <label class="label">Açılış Fişi : </label>
        <money-currency-input moneyControlName="open_amount" [encrypt]="true" [negative]="true"></money-currency-input>
      </div>
    </div>
    <div class="text-center">
      <button nbButton size="small" status="primary" (click)="postForm()" [disabled]="isLoading">
        {{"MUHASEBE.KAYDET"|translate}}</button>
    </div>
  </nb-card-body>
</nb-card>