<nb-card style="width:70%;border:unset;" class="modalType animated fadeInRight" size="giant">
        <nb-card-header>
          Firma Bilgileri Güncelle
          <button nbButton (click)="close()" size="tiny" class="heryerdeClose">X</button>
        </nb-card-header>
        <nb-card-body [formGroup]="form">
                <div class="row">
                        <div class="fieldBilgi col-md-12"><h6>İletişim Bilgileri</h6></div>
                        <div class="col-md-12">
                                <div class="form-group">
                                        <label class="font-weight-bold">Firma Ünvanı</label>
                                        <input type="text" nbInput fullWidth formControlName="name" apasInvalid>
                                </div>
                        </div>

                        <div class="col-md-12">
                                <div class="form-group">
                                        <label class="font-weight-bold">Adres</label>
                                        <textarea nbInput fullWidth formControlName="address"  apasInvalid></textarea>
                                </div>
                        </div>

                        
                        <div class="col-md-6">
                                <div class="form-group">
                                        <label class="font-weight-bold">E-posta</label>
                                        <input type="email" nbInput fullWidth formControlName="email"  apasInvalid >
                                </div>
                        </div>
                        <div class="col-md-6">
                                <div class="form-group">
                                        <label class="font-weight-bold">Telefon</label>
                                        <apas-telefon-input formControlName="phone"  apasInvalid></apas-telefon-input>
                                </div>
                        </div>

                        <div class="col-md-6">
                                <div class="form-group">
                                        <label class="font-weight-bold">Şehir</label>
                                        <ng-select formControlName="city" [items]="sehirAndIlce" bindLabel="city" bindValue="city" apasInvalid (change)="selectSehir($event)"></ng-select>
                                </div>
                        </div>

                        <div class="col-md-6">
                                <div class="form-group">
                                        <label class="font-weight-bold">İlçe</label>
                                        <ng-select formControlName="district" [items]="ilceler"  bindLabel="district" bindValue="district" apasInvalid></ng-select>
                                </div>
                        </div>
 
                </div>

                <div class="row">
                        <div class="fieldBilgi col-md-12"><h6>Resmi Bilgiler</h6></div>
                        
                    
                        
                        <div class="col-md">
                                <div class="form-group">
                                        <label class="font-weight-bold">Vergi Dairesi</label>
                                        <ng-select formControlName="tax_office" apasInvalid [items]="vergiDaireleri" bindLabel="title" bindValue="title"></ng-select>

                                </div>
                        </div>

                        <div class="col-md">
                                <div class="form-group">
                                        <label class="font-weight-bold">Vergi No <b class="text-danger">(Şahıs firmaları için TCKN girin)</b></label>
                                        <input type="text" nbInput fullWidth formControlName="tax_no" apasInvalid (blur)="unvanBul($event)">
                                </div>
                        </div>

                        <div class="col-md">
                                <div class="form-group">
                                        <label class="font-weight-bold">Resmi Ünvan</label>
                                        <input type="text" nbInput fullWidth formControlName="resmi_unvan">
                                </div>
                        </div>
 
                        

                </div>

                <!-- <div class="row">
                        <div class="fieldBilgi col-md-12"><h6>Entegrator Ayarları(Entegratörünüz tarafından size iletilen kullanıcı adı ve şifreyi girin)</h6></div>
                        
                        <div class="col-md-6">
                                <div class="form-group">
                                        <label class="font-weight-bold">Kullanıcı Adı</label>
                                        <input type="text" nbInput fullWidth apasInvalid formControlName="user">
                                </div>
                        </div>

                        <div class="col-md-6">
                                <div class="form-group">
                                        <label class="font-weight-bold">Şifre</label>
                                        <input type="password" nbInput fullWidth apasInvalid formControlName="password">
                                </div>
                        </div>

                         
                </div> -->
                <div class="col-md-12 text-center">
                         <button nbButton status="primary" (click)="patchForm()" [nbSpinner]="saveLoad" [disabled]="saveLoad">Kaydet</button>

                </div>
        </nb-card-body>
        
</nb-card>