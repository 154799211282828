import { Action, createAction, props } from '@ngrx/store';
import OrgInfoModel, { generalMessage } from '../../models/orgInfo.model';

const SetOrgInfo = createAction('[OrgInfo] Add', props<{ ORG: OrgInfoModel }>());

const updateOrgInfo = createAction('[OrgInfo] Update', props<{ update: any }>());

const ClearOrgInfo = createAction('OrganizationClear');
const uyariClose = createAction('[UYARI CLOSE] uyariclose');


export { SetOrgInfo, updateOrgInfo, ClearOrgInfo, uyariClose};
