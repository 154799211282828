import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'aps-loadinglist',
  templateUrl: './loadinglist.component.html',
  styleUrls: ['./loadinglist.component.scss'],
})
export class LoadinglistComponent implements OnInit {

  @Input() message: string = 'Yükleniyor.';
  @Input() text: string = 'Lütfen Bekleyiniz...';
  @Input() animation = '/assets/animations/loadinganim.json';
  @Input() genislik = '500px';
  @Input() yukseklik = '500px';
  constructor() {
  }

  options: AnimationOptions = {
    path: this.animation,
  };
  ngOnInit() {
  }

}
