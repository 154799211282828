import { Component, EventEmitter, forwardRef, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromHesap from '../../../muhasebe-store/hesaplar/hesap.connector';
import * as hesapActions from '../../../muhasebe-store/hesaplar/hesap.actions';
import { ApasDecryptePipe } from '../../../../../directives/apas-decrypte-pipe';
import Swal from 'sweetalert2';
import { NbDialogService } from '@nebular/theme';
import { KasaEkleComponent } from '../kasa-ekle/kasa-ekle.component';
import { takeUntil } from 'rxjs/operators';
import { ControlValueAccessorConnector } from '../../../../../shared/control-value-accessor-connector';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HesapModel } from '../hesap.model';
import { MuhasebeService } from '../../../muhasebe-service/muhasebe.service';
import { Constants } from '../../../../constants';


@Component({
  selector: 'hesap-select',
  template: `
    <ng-select
      #hesapSelect
      (change)="hesapChange($event)"
      [formControl]="control"
      [clearable]="clearable"
      [items]="subs$ | async"
      [loading]="isHesaplarLoading"
      [closeOnSelect]="closeOnSelect"
      [selectOnTab]="true"
      [multiple]="multiple"
      appendTo="body"
      [addTag]="false"
      [placeholder]="plcholder"
      bindLabel="name" bindValue="id"
      [searchable]="true" [virtualScroll]="true"
      >

        <ng-template *ngIf="showSelectAll" ng-header-tmp>
            <button nbButton (click)="selectAll()" size="small" status="basic" class="btn btn-sm btn-secondary">
              {{selected ? 'Seçimleri Kaldır' : 'AYARLAR.TUMUNUSEC'|translate}}</button>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
            <!-- <span class="col-6" style="font-weight: 700; overflow: hidden;"> {{item.title | slice : 0:40}} </span> -->
            <div style="min-width: 200px;">

              <div class="d-flex aling-items-center ">
                  <span>
                      <nb-user class="blackUser" size="small"
                          [name]="item?.currency ? c.getParaBirimSymbol(item?.currency) : ''" [showTitle]="false"
                          [showName]="false" [color]="item?.currency ? c.getParaBirimColor(item?.currency) : ''">
                      </nb-user>
                  </span>
                  <span class=" d-flex align-items-center ">
                      <div style="font-weight:bold;">
                          {{item?.title}}
                      </div>

                  </span>

              </div>

        </div>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="d-flex aling-items-center py-3" style="border-bottom:1px solid #eee;">
                            <span>
                                <nb-user class="blackUser font-size-user" [name]="item?.currency ? c.getParaBirimSymbol(item?.currency) : ''"
                                    [showTitle]="false" [showName]="false" [color]="item?.currency ? c.getParaBirimColor(item?.currency) : ''"
                                    size="medium">
                                </nb-user>
                            </span>
                            <span class=" d-flex align-items-center">
                                <div style="font-weight:bold;">
                                    {{item.title}}
                                    <small class="d-block ">
                                        <b>
                                            <apas-para-item [value]="item.current_value" [decrypt]="true"
                                                [alacak]="false" [setColor]="true" [negative]="true"
                                                [currency]="item?.currency"></apas-para-item>
                                        </b>

                                    </small>
                                </div>

                            </span>

                        </div>
        </ng-template>
    </ng-select>`,
  providers: [
    MuhasebeService, ApasDecryptePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HesapSelectComponent),
      multi: true,
    },
  ],
})
export class HesapSelectComponent extends ControlValueAccessorConnector implements OnInit, OnDestroy {

  @Input() formControl!: FormControl;
  @Input() formControlName: string;
  @Input() plcholder = 'Hesap Seçiniz';
  c = Constants.prototype;

  @Input() currencyControl!: FormControl;
  @Input() currencyControlName: string;

  get currencyController(): any {
    // Para Birimini Yönetir.
    if (this.currencyControl) {
      return this.currencyControl;
    } else if (this.currencyControlName) {
      this.currencyControl = this.controlContainer?.control?.get(this.currencyControlName) as FormControl;
      return this.currencyControl;
    }
  }


  @ViewChild('hesapSelect') hesapSelect: NgSelectComponent;
  hesap$: Observable<any[]>;
  hesapTmp: HesapModel[] = [];
  allData: HesapModel[] = [];
  isLoaded: boolean = false;
  isHesaplarLoading: boolean = false;


  @Output() select: EventEmitter<any> = new EventEmitter();
  @Input() showSelectAll: boolean = false;
  @Input() multiple: boolean;
  @Input() selected: any;
  @Input() currency: string;
  @Input() restrict: string[];
  @Input() clearable: boolean = true;

  closeOnSelect: boolean = true;


  subs = [];
  constructor(
    private store: Store<{ Hesap }>,
    private nbDialogService: NbDialogService,
    injector: Injector,
  ) {
    super(injector);
    this.isHesaplarLoading = true;
    // cari durumunu bildirir.
    this.subs[0] = this.store.pipe(select(fromHesap.selectHesapLoading)).subscribe((durum) => {
      this.isHesaplarLoading = durum;
    });

    this.subs[1] = this.store.pipe(select(fromHesap.selectHesapLoaded)).subscribe((durum) => {
      this.isLoaded = durum;
    });

    // Veri Çekmeye başlar.
    // eğer daha önce akış indirilmiş ise başdan çekmez.
    if (!this.isHesaplarLoading && !this.isLoaded) {
      this.store.dispatch(hesapActions.loadHesap());
    }
  }

  private destroy$ = new Subject();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  resetList() {
    this.hesapTmp = this.allData;
  }
  ngOnInit() {
    if (this.multiple === undefined || this.multiple == null) {
      this.multiple = false;
    }
    this.multiple ? this.closeOnSelect = false : this.closeOnSelect = true;
    this.getHesapList();
  }

  subs$;
  getHesapList() {
    const filtre = {
    };
    if (this.currency) {
      filtre['currency'] = this.currency;
    }
    if (this.restrict) {
      filtre['restrict'] = this.restrict;
    }
    this.subs$ = this.store.pipe(select(fromHesap.selectHesap(filtre)));

    this.subs[2] = this.subs$.pipe(takeUntil(this.destroy$)).subscribe((stateData) => {
      this.hesapTmp = stateData;
      if (!this.isHesaplarLoading && this.isLoaded && stateData.length === 0 && this.restrict) {
        Swal.fire({
          title: 'Dikkat',
          text: 'Seçtiğiniz para birimi ile uyumlu Hesap bulunamamıştır.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Kasa Ekle',
        }).then((result) => {
          if (result.isConfirmed) {
            this.nbDialogService.open(KasaEkleComponent);
          }
        });
      }
    });
  }
  clear() {
    this.hesapSelect.clearModel();
  }

  hesapChange(event) {
    this.select.emit(event);
    if (this.currencyController) {
      this.currencyController.setValue(event?.currency);
    }
  }

  selectAll() {
    if(!this.selected){
      this.selected = this.hesapTmp.map(x => x.id);
      this.control.setValue(this.selected);
      this.select.emit(this.selected);
    }else{
      this.selected = null;
      this.control.setValue(null);
      this.select.emit(null);
    }
   
  }

}
