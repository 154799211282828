import * as userActions from './user.actions';
import { createReducer, on } from '@ngrx/store';
import { KullaniciModel } from '../../models/kullanici_model';

interface UserInfoState {
    user: KullaniciModel;
    loading: boolean;
    loaded: boolean;
    logouting: boolean;
    error: String;
}

const defaultUserInfoState: UserInfoState = {
    user: {} as KullaniciModel,
    loading: false,
    loaded: false,
    logouting: false,
    error: '',
};

let countsuccess = 0;

const userInfoReducer = createReducer(
    defaultUserInfoState,
    on(userActions.clearUser, (state) => {
        return { ...state, user: null, loading: false, loaded: false, logouting: false };
    }),
    on(userActions.loadUser, (state) => {
        return { ...state, loading: true, loaded: false, logouting: false };
    }),
    on(userActions.loadUserSuccess, (state, user) => {
        return { ...state, user: user.user, loading: false, loaded: true, logouting: false };
    }),
    on(userActions.loadUserFail, (state, prop) => {
        return { ...state, loading: false, error: prop, logouting: false };
    }),
    on(userActions.startLogoutProcess, (state) => {
        return { ...state, logouting: false };
    }),
    on(userActions.invalidTokenLogout, (state) => {
        return { ...state, logouting: true };
    }),
    on(userActions.logoutUserSuccess, (state) => {
        return { ...state, user: null, logouting: false };
    }),
);

function UserInfoReducer(action, state) {
    return userInfoReducer(action, state);
}

export {
    UserInfoState,
    defaultUserInfoState,
    UserInfoReducer,
};
