import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { responseKodlari } from '../../../pages/constants';
import { removeAllNull } from '../../../pages/global-functions';
import { ChecklistService } from '../../../services/checklist.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'ngx-check-list-template',
  templateUrl: './check-list-template.component.html',
  styleUrls: ['./check-list-template.component.scss'],
  providers: [ChecklistService],
})
export class CheckListTemplateComponent implements OnInit {
  form: FormGroup;
  instanceType: string;
  editData: any;

  get checkGroup(): FormArray { return this.form.get('templateitem_set') as FormArray; }
  isLoading = false;
  constructor(private ref: NbDialogRef<CheckListTemplateComponent>,
    private fb: FormBuilder,
    private checkService: ChecklistService) { }

  ngOnInit(): void {
    this.initForm();
    this.form.get('instance_type').setValue(this.instanceType);
    if (this.editData) {
      console.log('Edit Olacak Data:', this.editData);
      this.form.get('title').setValue(this.editData.title);
      this.editData.templateitem_set.forEach(element => {
        this.addCheckItem(element);
      });
    } else {
      this.addCheckItem();
    }
  }

  initForm() {
    this.form = this.fb.group({
      instance_type: [this.instanceType, Validators.required],
      title: [null, Validators.required],
      templateitem_set: this.fb.array([]),
    });
  }

  addCheckItem(item?) {
    const checkItem = this.fb.group({
      identifier: [null],
      title: [null, Validators.required],
    });
    if (item) {
      checkItem.patchValue(item);
    }

    this.checkGroup.push(checkItem);
  }
  delteCheckItem(index) {
    if (this.checkGroup.length > 1) {
      const removeItem = this.checkGroup.at(index).value;
      if (removeItem.identifier) {
        this.deleted_items.push(removeItem.identifier);
      }
      this.checkGroup.removeAt(index);
    } else {
      return;
    }
  }
  post() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    const formData = removeAllNull(this.form.value);
    this.checkService.addSablonGroup(formData).subscribe(data => {
      this.isLoading = false;
      this.runSwal(data);
    }, err => {
      this.isLoading = false;
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    });
  }

  deleted_items = [];

  runSwal(resp) {

    const swalResponse: SweetAlertOptions = { ...responseKodlari.successProgress as SweetAlertOptions };
    swalResponse.timer = null;
    swalResponse.title = 'Başarılı';
    swalResponse.html = `${resp.title} başlıklı şablonu
      <b>CheckListesi Olmayan</b> tüm ${this.instanceType}ler için
       uygulamak ister misiniz?`;
    swalResponse.showConfirmButton = true;
    swalResponse.text = `Not: Tümüne uygulama işlemi sadece şablon tanımlanmayan ${this.instanceType}ler için gerçekleşecektir.`;
    swalResponse.confirmButtonText = `Uygula`;
    swalResponse.showCancelButton = true;
    swalResponse.cancelButtonText = 'Uygulama';
    Swal.fire(swalResponse).then(({ value }) => {
      if (value) {
        this.applyToAll(resp);
      } else {
        this.close(resp);
      }
    });
  }

  patch() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    console.log('Göncerilecek Data', this.form.value);
    this.isLoading = true;
    const formData = removeAllNull(this.form.value);
    formData['deleted_items '] = this.deleted_items;
    this.checkService.updateSablonGroup(this.editData?.id, formData).subscribe((resp: any) => {
      this.isLoading = false;
      this.close(true);
    }, (err) => {
      this.isLoading = false;
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    });

  }

  applyToAll(resp) {
    console.log('hepsine uygula');
    this.checkService.applyToAll({ instance_type: this.instanceType, template_group: resp.id }).subscribe(resp => {
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.close(resp);
    });
  }
  close(success?) {
    this.ref.close(success);
  }

}
