
export enum EntegrasonFaturaTypes {
        'ticari' = 'TICARIFATURA',
        'temel' = 'TEMELFATURA',
        'earsiv' = 'EARCHIVE',
        'efatura' = 'EINVOICE',
}

export enum FaturaStatus {
        'taxerr' = 'TAXERROR',
        'entegrerr' = 'ENTEGRATIONERROR',
        'cariinfoerr' = 'CARIINFORMATIONERROR',
}

export enum FaturaCurrentStatus {
        taslak = 'taslak',
        gonderiliyor = 'gonderiliyor',
        gonderilmedi = 'gonderilemedi',
        beklemede = 'beklemede',
        gonderildi = 'gonderildi',
        kabul_edildi = 'KABUL',
        reddedili = 'RED',
        cevap_verilmedi = 'cevap_verilmedi',
        iptal_edildi = 'iptal_edildi',
        tamamlandi = 'tamamlandi',
}


export enum BasvuruTypes {
        entegre = 'entegre',
        basvuruYap = 'basvuruYap',
        basvuruYapildi = 'basvuruYapildi',
}

export enum OptTypes {
        // Giden veya gelen fatura opsiyon tipleri.
        giden = 1, // Satış Faturaları
        gelen = 2, // Alış Faturaları
}
