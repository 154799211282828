import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { NextObserver, Observable, Subject } from 'rxjs';
import { BildirimModel, BildirimWebSocketModel } from '../models/bildirim.model';
import { Store, select } from '@ngrx/store';
import { KullaniciModel } from '../models/kullanici_model';
import { BildirimUserPermission, BildirimModulPermission } from '../models/birimfiyat.model';
import { SocketTokenService } from './socket.token.service';
import * as bildirimActions from '../store/bildirim/bildirim.actions';
import { delay, retryWhen } from 'rxjs/operators';
import { connectionStatus } from '../pages/muhasebe/muhasebe-store/muhasebeAkis/muhasebe-akis.connect';
import { selectUser } from '../store/user/user.connector';
import { environment } from '../../environments/environment';
import { NbGlobalLogicalPosition, NbToastrService } from '@nebular/theme';


@Injectable({ providedIn: 'root' })
export class BildirimService implements OnInit {
    myWebSocket: WebSocketSubject<BildirimWebSocketModel>;
    baglantiControl: Subject<boolean> = new Subject<boolean>();
    private readonly API_URL = `${environment.backendurl}api/bildirim/`;
    private readonly SOCKET_URLT = `${environment.socketUrl}bildirim/`;
    apas_id;
    tag: string = 'BildirimService --->';
    connectionTicket;
    openObserver: NextObserver<Event>;
    closeObserver: NextObserver<CloseEvent>;
    connected: boolean = false;
    reconnectInterval: number = 1000;

    toasterConfig = {
        duration: 10000,
        limit: 3,
        position: NbGlobalLogicalPosition.TOP_END,
        status: 'basic'
    };
    constructor(
        private httpClient: HttpClient,
        private store: Store<{}>,
        private socketTokenService: SocketTokenService,
        private toastrService: NbToastrService,

    ) {
        this.baglantiControl.next(false);
        this.setConnectionControllers();
        this.setBaglantiController();
        this.manageConnection();
    }

    setBaglantiController() {

        this.store.pipe(select(selectUser)).subscribe((user: KullaniciModel) => {
            if (user?.apas_id) {
                this.apas_id = user.apas_id;
                this.baglantiControl.next(true);
            } else {
                this.baglantiControl.next(false);
            }
        });

        // Tokenin ve firma bilgilerinin durumunu kontrol eden fonksiyonlar.
        this.socketTokenService.socketToken$.subscribe(val => {
            this.connectionTicket = val;
            if (val) {
                this.baglantiControl.next(true);
            } else {
                this.baglantiControl.next(false);
            }
        });

        this.store.select(connectionStatus).subscribe(val => {
            this.connected = val;
        });
    }

    manageConnection() {
        // Bağlantı Controllerın durumu ile socket bağlantısnı başlatır veya bitirir.
        this.baglantiControl.subscribe(val => {
            if (val && this.apas_id) {
                // Open Connection.
                this.setConnection();
            } else {
                // close connection
                if (this.myWebSocket) {
                    this.connectionTicket = null;
                    this.myWebSocket.unsubscribe();
                }
                this.myWebSocket = null;
            }
        });
    }

    ngOnInit(): void {
        this.manageConnection();
    }

    setConnection() {
        if (this.connectionTicket) {
            this.connect();
        } else {
            this.socketTokenService.getSocketToken();
        }
    }
    async setConnectionControllers() {

        // Bağlantının durumunu yöneten kontroller.

        this.closeObserver = {
            next: (err: CloseEvent) => {
                if (err.code === 4123) {
                    this.baglantiControl.next(false);
                    this.connectionTicket = null;
                }
                this.store.dispatch(bildirimActions.setConnection({ connection: false }));
            },
        };

        this.openObserver = {
            next: () => {
                // this.startHeartbeat();
                this.store.dispatch(bildirimActions.setConnection({ connection: true }));
            },
        };
    }


    connect() {
        if (this.connected) return;
        // Bağlantıyı Başlatan ve state'e verileri aktaran bölüm.
        const url: string = `${this.SOCKET_URLT}${this.apas_id}/?ticket=${this.connectionTicket}`;

        this.myWebSocket = webSocket(
            {
                url: url,
                openObserver: this.openObserver,
                closeObserver: this.closeObserver,
            },
        );


        this.myWebSocket.pipe(retryWhen((errors) => errors.pipe(delay(this.reconnectInterval)))).subscribe(msg => {
            this.toastrService.show(msg.message.message, msg.message.title, this.toasterConfig);

            this.store.dispatch(bildirimActions.addItem({ bildirimItem: msg.message }));
        });
    }


    /**
     * Socketten gelen mesajları alan observable olarak geriye döndüren fonksiyon
     */
    getMessage() {
        return this.myWebSocket.asObservable();
    }
    /**
     * Bildirimleri Sunucudan Alıp geriye BildirimModel[] cinsinde observable olarak döndüren fonksiyon
     * @param date zorunlu değil
     */
    getBildirim(date?: number): Observable<BildirimModel[]> {
        const url: string = `${this.API_URL}${date ? date : 0}/`;
        // 
        return this.httpClient.get<BildirimModel[]>(url);
    }
    /**
     * Gelen id ye göre nesnenin is_readed ve is_showed true yapan fonksiyon.
     * @param id
     */
    setReaded(id: number): Observable<BildirimModel> {
        return this.httpClient.patch<BildirimModel>(this.API_URL + 'update/' + id + '/', {
            is_readed: true,
            is_showed: true,
        });
    }

    setAllReaded() {
        const url: string = `${this.API_URL}okundu/`;
        return this.httpClient.post(url, '');
    }

    /**
   * Gelen id ye göre nesnenin e is_showed true yapan fonksiyon.
   * @param id
   */
    setShowed(): Observable<BildirimModel> {
        const url: string = `${this.API_URL}okundu/`;
        return this.httpClient.patch<BildirimModel>(url, '');
    }

    getUnShowedCount() {
        const url: string = `${this.API_URL}okundu/`;
        return this.httpClient.get<BildirimModel>(url);
    }

    updateUnReaded(id?: number) {
        if (id) {
            return this.httpClient.patch(this.API_URL + 'update/' + id + '/', {
                is_readed: false,
                is_showed: false,
            });
        } else {
            //
        }
    }
    /**
     * Link tipli bildirimlerin Onayla durumu için kullanılacaktır.
     * @param url linkin Url'si
     */
    setLing(url: string): Promise<any> {
        return this.httpClient.get<any>(url).toPromise();
    }
    /**
 * Link tipli bildirimlerin Onayla durumu için kullanılacaktır.
 * @param url linkin Url'si
 */
    getFileLing(url: string): Promise<any> {
        return this.httpClient.get<any>(url).toPromise();
    }

    getUserPermissions(data): Observable<BildirimUserPermission[]> {

        const url: string = `${this.API_URL}personels/list/`;
        return this.httpClient.post<BildirimUserPermission[]>(url, data);
    }
    /**
     * Belli bir kullanıcıadan bildirim alıp almayacağını belirler.
     * {
            "status": true,
            "notifer_apas_id": "2010412345"
         }
     * @param data gönderilen data.
     */
    setUserPermissions(data): Observable<BildirimUserPermission> {

        const url: string = `${this.API_URL}personels/`;
        return this.httpClient.post<BildirimUserPermission>(url, data);
    }
    getModulPermissions(): Observable<BildirimModulPermission[]> {

        const url: string = `${this.API_URL}module/`;
        return this.httpClient.get<BildirimModulPermission[]>(url);
    }

    setModulPermissions(data): Observable<BildirimUserPermission> {

        const url: string = `${this.API_URL}module/`;
        return this.httpClient.post<BildirimUserPermission>(url, data);
    }


}
//  ws://apasplus.com:8001/soc/bildirim/(Kullanıcı ID)/?token=(TOKEN)
