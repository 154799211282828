import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';


@Injectable()
export class MuhasebeYedekService {

    private readonly YEDEK_URL = `${environment.backendurl}api/muhasebe/yedek/`;

    constructor(
        private httpClient: HttpClient
    ) { }


    getLastYedek(identifier?) {
        const url = `${this.YEDEK_URL}${identifier?identifier+'/':''}`
        return this.httpClient.get(url)
    }

    postYedek(data) {
        const url = `${this.YEDEK_URL}`;
        return this.httpClient.post(url, data);
    }

}