import { AlisSatisItemModel, AlisSatisModel, CariHareketModel } from "../../models/cari_hakeret.model";
import { ApasCrypto } from "../../../../shared/ApasEncryptor/apas-encryptor";
import { convertDateWithTZ } from "../../../global-functions";


export const clearCariHareket = async (data: CariHareketModel, decryptor?: ApasCrypto) => {
    if (!decryptor) {
        decryptor = new ApasCrypto();
    }
    data.date = convertDateWithTZ(data, 'date');
    data.created_time = convertDateWithTZ(data, 'created_time');
    if (data.cek_id) {
        data.cek_id.vade = convertDateWithTZ(data.cek_id, 'vade');
    }
    data.tutar = await decryptor.decrypt(data.total_price);
    data.tutar = Number(data.tutar);

    if (data.pn > 0) {
        data.borc = data.tutar;
    } else {
        data.alacak = data.tutar;
    }

    if (data.cek_id) {
        data.cek_id = clearCekSenetData(data.cek_id);
    }

    if (data.alis_satis_id) {
        console.log('CLEAR ALIS ITEM : ', data.alis_satis_id)
        try {
            data.alis_satis_id = await clearAlisItemEncryption(data.alis_satis_id, decryptor);
        } catch (error) {
            console.log('ALIŞ CLEAR ERROR : ', error);
        }
    }
    return await data;
}

export const clearCekSenetData = (cekSenetData) => {
    cekSenetData.vade = convertDateWithTZ(cekSenetData, 'vade');
    cekSenetData.keside_tarihi = convertDateWithTZ(cekSenetData, 'keside_tarihi');
    cekSenetData.created_time = convertDateWithTZ(cekSenetData, 'created_time');
    return cekSenetData;
}

export const clearAlisItemEncryption = async (data: AlisSatisModel, decryptor: ApasCrypto) => {
        data = Object.assign({ emitEvent: false }, data, { selected: false });
        data.vergi_list = data?.vergi_list ? data?.vergi_list.map((item) => Object.assign({ emitEvent: false }, item, { selected: false })) : [];
        data.alis_satis_items = data?.alis_satis_items ? data?.alis_satis_items.map((item) => Object.assign({ emitEvent: false }, item, { selected: false })) : [];

        data.created_time = convertDateWithTZ(data, 'created_time');
        data.fatura_date = convertDateWithTZ(data, 'fatura_date');
        data.vade = convertDateWithTZ(data, 'vade');
        data.forwarding_date = convertDateWithTZ(data, 'forwarding_date');
        data.date = convertDateWithTZ(data, 'date');
        data.price = Number(await decryptor.decrypt(data?.price));
        data.ara_toplam = Number(await decryptor.decrypt(data?.ara_toplam));
        data.indirim_toplam = Number(await decryptor.decrypt(data?.indirim_toplam));
        await data?.vergi_list.forEach(async (vergi) => {
            vergi.tevkifat = Number(await decryptor.decrypt(vergi?.tevkifat));
            vergi.value = Number(await decryptor.decrypt(vergi?.value));
            vergi.matrah = Number(await decryptor.decrypt(vergi?.matrah));

        });
  
        const clearAlisItems = new Promise(async (rslv) => {
            const newList = [];
            for await (let item of data?.alis_satis_items) {
                item = Object.assign({}, item, {selected: false});
                item.unit_price = Number(await decryptor.decrypt(item?.unit_price));
                item.amount = Number(await item?.amount);
                item.toplam_tutar = Number(await decryptor.decrypt(item?.toplam_tutar));
                item.ara_toplam = Number(await decryptor.decrypt(item?.ara_toplam));
                item.birim_ara_toplam = Number(await decryptor.decrypt(item?.birim_ara_toplam));
                item.birim_basina_vergi = Number(await decryptor.decrypt(item?.birim_basina_vergi));
                item.net_fiyat = Number(await decryptor.decrypt(item?.net_fiyat));
                item.toplam_temel_fiyat = Number(await decryptor.decrypt(item?.toplam_temel_fiyat));
                item.temel_fiyat = Number(await decryptor.decrypt(item?.temel_fiyat));
    
                item.toplam_vergi = Number(await decryptor.decrypt(item?.toplam_vergi));
                item.toplam_indirim = Number(await decryptor.decrypt(item?.toplam_indirim));
                if (!item?.birim_basina_indirim) {
                    item.birim_basina_indirim = 0;
                } else {
                    item.birim_basina_indirim = Number(await decryptor.decrypt(item?.birim_basina_indirim));
                }
                /*Vergiler*/
                let taxItems = [];
                if (item?.tax_group.length > 0) {
                    await item.tax_group.forEach(async (taxItem) => {
                        taxItem = Object.assign({}, taxItem, {selected: false});
                        taxItem.tax_amount = Number(await decryptor.decrypt(taxItem?.tax_amount));
                        taxItem.tax_price = Number(await decryptor.decrypt(taxItem?.tax_price));
                        taxItem.taxable_amount = Number(await decryptor.decrypt(taxItem?.taxable_amount));
                        taxItem.total_tax_amount = Number(await decryptor.decrypt(taxItem?.total_tax_amount));
                        taxItem.tax_rate = Number(await taxItem?.tax_rate);
                        taxItems.push(taxItem);
                    });
                    item.tax_group = taxItems;
                }
                /*Vergiler*/
                newList.push(item);
            }
            rslv(newList);
          }
        
        )
        await clearAlisItems.then((alisItems: any) => {
            data.alis_satis_items = alisItems
        });
        return await data;
};





// CARI BAKIYE HESABI 
async function calculateCariItemBakiye(index: number, rows: CariHareketModel[], currentBakiye: any[]) {

    const currentItem = rows[index];
    if (index === 0) {
        currentItem.bakiye = currentBakiye;
    }

    const lastElm = rows[index - 1];
    if (!lastElm) {
        return currentItem;
    }
    const bakiye = lastElm.bakiye.map((item) => Object.assign({}, item, { selected: false }));
    const found = bakiye.find(x => x.currency === lastElm.currency);
    const minus = Number(lastElm.tutar) * lastElm.pn;
    found.bakiye = Number(found.bakiye);
    found.bakiye -= minus;
    currentItem.bakiye = bakiye;
    return await currentItem;
}

export async function calculateCariBakiye(currentBakiye, rows) {
    /**
     * Kasa ve cari için her bir hareketin işlemin içine bakiye bilgisini işler.
     *
     * @prop currentBakiye : [
     * {currency: "TRY"
        bakiye: "G7RQ9wTto4esrhvHCmRCWs99eLlNVc60ucpv32j0uM0="
        cek: "BNILZWbuJUsExpNUo5P9QhuACuLhzeareIEdmgqjCiw="
        positive: "2Mq7bsDuqkq5KA6Fz+g/hCyivaWBtfxxfK4camdafNs="
        negative: "XfmtZX7u8rPRJ8ErDnlMeYecaoqVvIurHDTCdDpqekw="
      }
    ]
  
    * @prop rows : [] // O cariye ait tüm işlemler listesi.
    */

    const decryptor = new ApasCrypto()

    currentBakiye = [...currentBakiye];
    currentBakiye = currentBakiye.map((item) => Object.assign({}, item, { selected: false }));

    await currentBakiye.forEach(async (element: any) => {
        element.bakiye = await decryptor.decrypt(element.bakiye);
        element.bakiye = Number(element.bakiye);
    });

    rows = rows.sort((x, y) => y.timestamp - x.timestamp);


    await rows.forEach(async (element, i) => {
        element = await Object.assign({}, element, { selected: false })
        await calculateCariItemBakiye(i, rows, currentBakiye).then(val => element = val);
    },
    );

    rows = rows.sort((x, y) => y.date - x.date);
    rows = [...rows];
    return rows;
}





// KASA BAKIYE HESABI 



async function calculateHesapItemBakiye(index: number, rows: CariHareketModel[], currentBakiye, element) {

    if (index === 0) {
        element.bakiye = currentBakiye
        return element;
    }

    const lastElm = rows[index - 1];

    if (!lastElm) {
        return element;
    }

    const bakiye = lastElm?.bakiye;
    const minus = Number(lastElm.tutar) * lastElm.pn;
    element.bakiye = bakiye - minus;
    return await element;
}

export async function calculateHesapBakiye(currentBakiye: number, rows: CariHareketModel[]) {
    /**
     * Kasa ve cari için her bir hareketin işlemin içine bakiye bilgisini işler.
     * @prop currentBakiye : "G7RQ9wTto4esrhvHCmRCWs99eLlNVc60ucpv32j0uM0="
     * @prop rows : Kasaya ait tüm işlemler listesi.
    **/
    if (!rows || rows.length == 0) {
        return rows;
    }

    rows = rows.map(x => Object.assign({}, x, { selected: false }));

    rows = rows.sort((x, y) => y.timestamp - x.timestamp);
    // İlk elemanı göz ardı ederek devam etmek güzel olur.
    rows.forEach(async (element, i: number) => {
        await calculateHesapItemBakiye(i, rows, currentBakiye, element).then(val => element = val).catch((err) => console.log('calculate item err : ', err));
    }
    );

    rows = rows.sort((x, y) => y.date - x.date);
    return rows;
}