import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[apasFocus]'
})
export class ApasFocusDirective {

  constructor(private host: ElementRef) {
    this.host.nativeElement.focus();
   }

}
