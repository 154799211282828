import { Component, Input, OnInit } from '@angular/core';
import { FaturaCurrentStatus } from '../enums';

@Component({
  selector: 'fatura-status',
  templateUrl: './fatura-status.component.html',
  styleUrls: ['./fatura-status.component.scss'],
})
export class FaturaStatusComponent implements OnInit {
  @Input() status: FaturaCurrentStatus;
  faturaCurrentStatus =  FaturaCurrentStatus;
  constructor() { }

  ngOnInit(): void {
  }

}
