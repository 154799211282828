import * as exchangeActions from './exchanges.actions';
import {
    createReducer,
    on,
} from '@ngrx/store';
import { ExchangeModel, ExchangesModel } from '../../models/exchanges.model';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';



interface ExchangeState extends EntityState<ExchangeModel> {
    exchanges: ExchangesModel;
    loading: boolean;
    loaded: boolean;
    error: String;
}

const defaultExchangeState: ExchangeState = {
    exchanges: {} as ExchangesModel,
    ids: [],
    entities: {},
    loading: false,
    loaded: false,
    error: '',
};

const exchangeAdapter: EntityAdapter<ExchangeModel> = createEntityAdapter<ExchangeModel>();

const initialState = exchangeAdapter.getInitialState(defaultExchangeState);

const exchangeReducer = createReducer(
    initialState,
    on(exchangeActions.clearExchanges, (state) => {
        return { ...state, exchange: null, loading: false, loaded: false };
    }),
    on(exchangeActions.loadExchanges, (state) => {
        return { ...state, loading: true, loaded: false };
    }),
    on(exchangeActions.loadExchangesSuccess, (state, { exchanges }) => {
        return { ...state, exchanges: exchanges, loading: false, loaded: true };
    }),
    on(exchangeActions.loadExchangesFail, (state, prop) => {
        return { ...state, loading: false, error: prop };
    }),
    on(exchangeActions.saveExchangeData, (state, props) => {
        return exchangeAdapter.upsertMany(props.data, {
            ...state,
            loading: false,
            loaded: true,
        });
    }),
);

function ExchangeReducer(action, state) {
    return exchangeReducer(action, state);
}
export {
    ExchangeState,
    defaultExchangeState,
    ExchangeReducer,
};
