import { TagItemModel } from '../../../models/tag.model';
import { CekItem } from './cek.model';

export interface CariHareketModel {
    alis_satis_items?: any;
    id?: number;
    note?: string;
    odeme_turu?: string;
    total_price?: any;
    created_time?: any;
    date?: any;
    created_by_id?: number;
    created_by_name?: string;
    hareket_type?: string;
    hesap?: string;
    cari?: string;
    bakiye?: any;
    cek_id?: CekItem;
    sender_name?: string;
    reciver_name?: string;
    currency?: string;
    alis_satis_id?: AlisSatisModel;
    reciver_cari_id?: number;
    sender_cari_id?: number;
    reciver_account?: number;
    sender_account?: number;
    gmt?: string;
    tags?: TagItemModel[];
    tahsilat?: any;
    odeme?: any;
    has_fatura?: boolean;
    has_dosya?: boolean;
    isDownloading?: boolean;
    percentDone?: number;
    exchange_rate?: number;
    tarih?: any;
    tutar?: any;
    odenmesi_gereken?: any;
    edit?: boolean;
    maas_tahakkuk_id?: MaasTahakkukModel;
    borc?: any;
    alacak?: any;
    input_amount?: any;
    second_amount?: any;
    timestamp?: number;
    pn?: number;
    information?: string;
    file?: string;
    file_url?: string;
    extension?: string;
    virman_id?:any;
}

export interface AlisSatisModel {
    id: number;
    cari_id: number;
    created_by_id: number;
    created_by_name: string;
    created_time: any;
    currency: string;
    date?: Date;
    document_no: string;
    fatura_date: any;
    fatura_tarih: any;
    fatura_file: any;
    has_fatura: boolean;
    has_fatura_file: boolean;
    hesap: string;
    note: string;
    tip: string;
    vade: any;
    vade_tarih: any;
    alis_satis_items: AlisSatisItemModel[];
    total_price: any;
    price: any;
    isDownloading?: boolean;
    percentDone?: number;
    tags: TagItemModel[];
    toplam_indirim: number;
    vergi_list: any[];
    gmt?: string;
    irsaliye_no: string[];
    forwarding_date: any;
    form: any;
    indirim_toplam?: any;
    ara_toplam?: any;
    invoice_type: any;
    hareket_id?: number;
}

export interface AlisSatisItemModel {
    id: number;
    product_id: number;
    price: any;
    tip: any;
    note: string;
    title: string;
    unit_price: any;
    amount: any;
    tax1: any;
    tax1_tutar: any;
    tax1_title: string;
    tax2: any;
    tax2_tutar: any;
    tax2_title: string;
    tax3: any;
    tax3_tutar: any;
    tax3_title: string;
    discount_tutar: any;
    tutar: any;
    unit: any;
    toplam_temel_fiyat: any;
    temel_fiyat: any;
    toplam_indirim: any;
    birim_basina_indirim: any;
    birim_basina_vergi: any;
    toplam_vergi: any;
    ara_toplam: any;
    birim_ara_toplam: any;
    toplam_tutar: any;
    net_fiyat: any;
    tax_group: any[];
}


export interface MaasTahakkukModel {
    calisilan_ay: any;
    calisilan_gun_sayisi: number;
    date: any;
    salary: string | number;
    toplam_mesai_ucreti: string | number;
    yevmiye: string | number;

    mesai_items: MesaiItemModel[];
    currency?: string;
}

export interface MesaiItemModel {
    maas_tahakkuk: number;
    mesai_saat: number;
    mesai_ucret: string | number;
    saatlik_ucret: string | number;
    title: string;
}
