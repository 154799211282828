import {
    AfterViewInit,
    Directive,
    DoCheck,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as appLication from '../store/aplikasyon/aplikasyon.connector';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[aps-animated]',
})
export class ApasAnimatedDirective implements DoCheck, AfterViewInit, OnChanges {
    @Input() itemIndex = 1;
    @Input() animatedSet = false;
    listEl: HTMLInputElement;
    animationSecond = 10; // List elamanına eklenen animasyon item indexine göre animationSecond katları olarak eklenir.
    aygit: any;
    constructor(private el: ElementRef,
        private store: Store) { }
  
    ngAfterViewInit(): void {
        this.listEl = this.el.nativeElement;
        const idx = this.itemIndex % 21;
        this.animationSecond = this.animationSecond * idx;


    }

    ngOnChanges(changes: SimpleChanges): void {
         this.store.select(appLication.selectAygit).subscribe((data: any) => {
            this.aygit = data;
        });
        if (this.aygit?.deviceType !== 'mobile') {
            if (this.animatedSet) {
                this.listEl?.classList?.add('animated', 'fadeInLeft');
                this.animateRemove('fadeInRight');

            } else {
                this.animateRemove('fadeInLeft');
                this.listEl?.classList?.add('animated', 'fadeInRight');

            }
        }

    }

   

    animateRemove(animate: string) {
        if (animate) {
            let varmi = this.listEl?.classList?.contains(String(animate));

            if (varmi) {
                this.listEl?.classList?.remove(animate);

            }
        }
    }



    ngDoCheck(): void { }
}
