<nb-card style="width:40%;border:unset;" class="modalType animated fadeInRight aps-splt" size="giant">
    <nb-card-header>
        <div>E-FATURAYA YENİ BAŞVURU YAPMAK İSTİYORUM</div>
        <small>
            <nb-icon icon="info-outline" status="warning"></nb-icon> Bu pencerede sizden alınan bilgilerin tamamı
            entegratör firmaya iletilecektir.
        </small>
        <button nbButton (click)="close()" size="tiny" class="heryerdeClose">X</button>
    </nb-card-header>
    <nb-card-body class="p-0">
        <nb-list>
            <nb-list-item>
                <div class="col-md-4"><b>Ünvan:</b></div>
                <div class="col-md">{{organization_info?.resmi_unvan}}</div>
            </nb-list-item>
            <nb-list-item>
                <div class="col-md-4"><b>E-posta:</b></div>
                <div class="col-md">{{organization_info?.email}}</div>
            </nb-list-item>
            <nb-list-item>
                <div class="col-md-4"><b>Telefon:</b></div>
                <div class="col-md">{{organization_info?.phone}}</div>
            </nb-list-item>
            <nb-list-item>
                <div class="col-md-4"><b>Adres:</b></div>
                <div class="col-md">{{organization_info?.address}}</div>
            </nb-list-item>
            <nb-list-item>
                <div class="col-md-4"><b>Vergi Dairesi:</b></div>
                <div class="col-md">{{organization_info?.tax_office}}</div>
            </nb-list-item>
            <nb-list-item>
                <div class="col-md-4"><b>Vergi Numarası:</b></div>
                <div class="col-md">{{organization_info?.tax_no}}</div>
            </nb-list-item>
        </nb-list>

        <div class="kontorSat">
            <div class="text-center py-3">
                <h6>Satın almak istediğiniz kontör paketini seçiniz</h6>
                <small>
                    <nb-icon icon="info-outline" status="warning"></nb-icon>
                    Yapacağınız seçim sadece bilgi amaçlı olup şuan için bir ücret talep edilmeyecektir.
                </small>
            </div>
            <nb-radio-group status="warning" [formControl]="kontorKontrol">
                <nb-radio [value]="500">
                    <h6>500 Kontör </h6> <!-- (110 TRY) -->
                    <!-- <span class="d-block">+ 250 kontör hediye</span> -->
                </nb-radio>
                <nb-radio [value]="1000">
                    <h6>1000 Kontör</h6> <!--  (200 TRY)  -->
                    <!-- <span class="d-block">+ 500 kontör hediye</span> -->
                </nb-radio>
                <nb-radio [value]="2000">
                    <h6>2000 Kontör</h6> <!--  (400 TRY)  -->
                    <!-- <span class="d-block">+ 1000 kontör hediye</span> -->
                </nb-radio>
                <nb-radio [value]="5000">
                    <h6>5000 Kontör</h6> <!--  (900 TRY) -->
                    <!-- <span class="d-block">+ 2500 kontör hediye</span> -->
                </nb-radio>
            </nb-radio-group>
        </div>

    </nb-card-body>

    <nb-card-footer class="text-center">
        <button nbButton status="danger" (click)="islemYap(islemType.iptal)">Vazgeç</button>
        <button nbButton status="info" (click)="islemYap(islemType.bilgiDuzenle)">Bilgilerimi Güncelle</button>
        <button nbButton status="warning" (click)="islemYap(islemType.basvuru)"
            [nbTooltip]="'Yukarıdaki Bilgilerle Başvuru Yap'">Başvuru Yap</button>
    </nb-card-footer>

</nb-card>