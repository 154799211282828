<nb-card class="modalType animated fadeInRight" size="giant" style="width:600px;">
    <nb-card-header class="d-flex justify-content-between align-items-center">
        <span>
            {{editData?.product_name}} <br>
            <b [class]="'depoIslemTuru'+editData?.warehouse_in_out_type">
            {{c.setWarehouseInOutText(editData?.warehouse_in_out_type)}}
            </b>
        </span>
        <button nbButton (click)="closed()" size="tiny" class="heryerdeClose">X</button>
    </nb-card-header>
    <nb-card-body [nbSpinner]="isLoading" nbSpinnerSize="tiny">

        <div [formGroup]="form">
            <div style="overflow-y: hidden;">

                <div class="form-group" *ngIf="editData?.warehouse_in_out_type === 2 || editData?.warehouse_in_out_type === 4">
                    <label class="label"> {{'DEPO.TEDARIKCI' | translate}}</label>
                    <ng-select class="ng-select-without-bottom-margin" #tedarikci appendTo="body" [addTag]="false"
                        [selectOnTab]="true" [placeholder]="'DEPO.TEDARIKCI' | translate" bindLabel="name"
                        bindValue="apas_id" [items]="tedarikci$ | async " [loading]="organizationLoading"
                        [typeahead]="orgListeInput$" [searchable]="true" formControlName="delivery_by_org_apas_id"
                        (change)="tedarikciSelected($event)">
                    </ng-select>

                </div>


                <div class="form-group" 
                *ngIf="editData?.warehouse_in_out_type === 2">
                    <label class="label">{{'DEPO.TESLIMEDEN' | translate}}</label>
                    <ng-select class="ng-select-without-bottom-margin" [typeahead]="personelListInput$"
                        [loading]="personelLoading" [addTag]="true" [selectOnTab]="true"
                        formControlName="delivery_by_apas_id" bindLabel="name" bindValue="name"
                        (change)="selectedDeliveryPersonel($event)" [placeholder]="'DEPO.TESLIMEDEN' | translate"
                        [items]="personelList$ | async" appendTo="body">
                    </ng-select>
                </div>

                  <!--Teslim Alan-->
              <div class="form-group" *ngIf="editData?.warehouse_in_out_type === 3">
                <label class="form-label label">{{ 'DEPO.TESLIMALAN' | translate }}</label>

                <ng-select
                 class="ng-select-without-bottom-margin" 
                 (change)="selectedDeliveryPersonel($event)"
                 [addTag]="true"
                formControlName="delivery_by_apas_id" 
                bindLabel="name" bindValue="apas_id"
                [placeholder]="'DEPO.TESLIMALAN' | translate" 
                [items]="allPersonel" 
                [loading]="allLoading"
                [loadingText]="'Yükleniyor'"
                appendTo="body">
                </ng-select>
              </div>
              <!--Teslim Alan-->



                <div class="form-group">
                    <label class="label">{{'TARIH' | translate}}</label>
                    <input nbInput [placeholder]="'TARIH' | translate " [nbDatepicker]="startpicker" fullWidth
                        formControlName="created_time">
                    <nb-datepicker #startpicker></nb-datepicker>
                </div>

                <div class="form-group">
                    <label class="label">{{'DEPO.MIKTAR'|translate}}</label>

                    <div class="row p-0 m-0">
                        <div class="col-md-9 p-0">
                            <input paraDirective [encrypt]="false" [decrypt]="false" [negative]="false"
                                [plcholder]="'DEPO.MIKTAR'|translate" formControlName="amount" type="text"
                                placeholder="1" nbInput fullWidth apasInvalid />
                        </div>

                        <div class="col-md p-0">
                            <ng-select class="ng-select-without-bottom-margin" formControlName="unit" bindLabel="label"
                                bindValue="value" [placeholder]="'DEPO.BIRIM' | translate" [items]="birim"
                                appendTo="body" [clearable]="false">
                            </ng-select>
                        </div>
                    </div>




                </div>

            </div>



            <div class="form-group">
                <label class="label">{{'DEPO.ACIKLAMA'|translate}}</label>
                <textarea nbInput fullWidth  formControlName="note" [placeholder]="'DEPO.ACIKLAMA'|translate"></textarea>

            </div>

            <div class="row ">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                   <dosya-ekle-input formControlName="irsaliye_image"  [dosyaTip]="'jpg,jpeg,png'"></dosya-ekle-input> 

                </div>
                <div class="col-md-4"></div>
            </div>

        </div>

       

    </nb-card-body>

    <nb-card-footer class="text-center">
        <button nbButton (click)="patchForm()" [nbSpinner]="saveLoad" [disabled]="saveLoad" status="primary"> Güncelle
        </button>

    </nb-card-footer>
</nb-card>