import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthOAuth2JWTToken, NbAuthResult, NbAuthService } from '@nebular/auth';
import { select, Store } from '@ngrx/store';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { KullaniciService } from '../services/kullanici.service';
import { responseKodlari } from '../pages/constants';
import * as moment from 'moment-timezone';
import { OrganizasyonService } from '../services/organizasyon.service';
import { selectBackendUrl } from '../store/aplikasyon/aplikasyon.connector';
import { environment } from '../../environments/environment';
import { isDevMode } from '@angular/core';
import { selectLogoutStatus } from '../store/user/user.connector';
import { ClearOrgInfo } from '../store/organization/organization.actions';
import { invalidTokenLogout } from '../store/user/user.actions';


@Injectable()
export class AppInterceptor implements HttpInterceptor {
    reg = RegExp('https://[a-z,0-9]*.apasplustest.com/');
    backendUrl = environment.backendurl;
    tag: string = 'interceptor --->';
    orgId;
    logouting: boolean = false;
    constructor(
        private store: Store<{}>,
        private kullaniciService: KullaniciService,
        private organizationService: OrganizasyonService,
        private injector: Injector,
    ) {
        if (isDevMode()) {
            this.store.pipe(select(selectBackendUrl)).subscribe((data) => {
                this.backendUrl = data;
            });
        }

        this.organizationService.org$.subscribe(org => {
            this.orgId = org?.id;
        });

        this.store.select(selectLogoutStatus).subscribe((val) => {
            this.logouting = val;
        });
    }

    protected get authService(): NbAuthService { return this.injector.get(NbAuthService); }

    protected get routerService(): Router { return this.injector.get(Router); }

    /** içerisinde berier token varmı diye bakılacak */
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Eğer token geçersiz kaldıysa request göndermenin ne anlamı var?
        if (this.logouting) {
            return;
        }
        // test - backend arası geçişi yönetir.
        httpRequest = httpRequest.clone({ url: httpRequest.url.replace(this.reg, this.backendUrl) });
        // requestin içine client timezone verisini basar.
        httpRequest = httpRequest.clone({ setHeaders: { GMT: moment.tz.guess() } });

        // requestin içine varsa organizasyon verisini gömer.
        if (this.orgId) httpRequest = httpRequest.clone({ setHeaders: { Organization: `ORG${this.orgId}` } });

        // Token ile ilintili bir request ise içine token gömmenin bir anlamı da
        if (httpRequest.url.includes('token2')) {
            httpRequest = httpRequest.clone({ body: { ...httpRequest.body, tip: 1 } });
            return from(this.handleRequest(httpRequest, next));
        }

        return this.authService.isAuthenticatedOrRefresh().pipe(
            switchMap((authenticated) => {
                if (authenticated) {
                    return this.authService.getToken().pipe(
                        switchMap((token: NbAuthOAuth2JWTToken) => {
                            if (!token.isValid()) {
                                return this.authService
                                    .refreshToken(token.getOwnerStrategyName(), token)
                                    .pipe(
                                        switchMap((nbAuthResult: NbAuthResult) => {
                                            if (nbAuthResult.isSuccess()) {
                                                const JWT = `Bearer ${token.getValue()}`;
                                                httpRequest = httpRequest.clone({
                                                    setHeaders: { Authorization: JWT },
                                                });
                                                return this.handleRequest(httpRequest, next);
                                            } else {
                                                return this.handleRequest(httpRequest, next);
                                            }
                                        }),
                                    );
                            } else {
                                const JWT = `Bearer ${token.getValue()}`;
                                httpRequest = httpRequest.clone({
                                    setHeaders: { Authorization: JWT },
                                });
                                return this.handleRequest(httpRequest, next);
                            }
                        }),
                    );
                } else {
                    return this.handleRequest(httpRequest, next);
                }
            }),
        );

    }

    handleRequest(request: HttpRequest<any>, next: HttpHandler) {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                this.handleTokenRefresError(error, request);
                return throwError(error);
            }),
        );
    }

    logout(showMessage?) {
        if (!this.logouting) {
            if (showMessage) Swal.fire(responseKodlari.token_not_valid as SweetAlertOptions);
            this.store.dispatch(invalidTokenLogout());
        }
    }

    karsilama() {
        this.store.dispatch(ClearOrgInfo());
        this.routerService.navigate(['karsilama']);
    }
    firmaHatasi() {
        if (this.logouting) {
            Swal.fire(responseKodlari.ER01 as SweetAlertOptions).then(
                ({ value }) => {
                    if (value) {
                        this.karsilama();
                    }
                },
            );
        }
    }

    private handleTokenRefresError(error: HttpErrorResponse, request: HttpRequest<any>) {
        if (error !== null && error !== undefined) {
            if (error.error) {
                if (error.error.detail) {
                    if (error.error.detail === 'Authentication credentials were not provided.') {
                        this.logout();
                    }
                }
                if (error.error.code) {
                    if (error.error.code === 'token_not_valid') {
                        // show message;
                        this.logout(true);
                    } else if (error.error.code === 'user_inactive') {
                        Swal.fire(responseKodlari.inActiveUser as SweetAlertOptions).then(
                            (val) => {
                                if (val.value) {
                                    this.kullaniciService
                                        .resendActivationMail(request.body.email)
                                        .subscribe((response) => {
                                            Swal.fire(
                                                responseKodlari.successProgress as SweetAlertOptions,
                                            );
                                        });
                                }
                            },
                        );
                        this.logout();

                    }
                }
                let _err = error.error;
                if (_err instanceof Array && _err.length > 0) {
                    _err = _err[0];
                }
                if (_err.ER01 !== undefined) {
                    setTimeout(() => {
                        this.firmaHatasi();
                    }, 300);
                } else if (_err.ER05 !== undefined) {
                    Swal.fire(responseKodlari.ER05 as SweetAlertOptions);
                } else if (_err.ER96 !== undefined) {
                    Swal.fire(responseKodlari.ER96 as SweetAlertOptions);
                } else if (_err.ER95 !== undefined) {
                    this.firmaHatasi();
                } else if (_err.ER16 !== undefined) {
                    Swal.fire(responseKodlari.ER16 as SweetAlertOptions);
                } else if (_err.SC06 !== undefined) {
                    Swal.fire(responseKodlari.SC06 as SweetAlertOptions);
                } else if (_err.ER12 !== undefined) {
                    Swal.fire(responseKodlari.ER12 as SweetAlertOptions);
                } else if (_err.ER00 !== undefined) {
                    Swal.fire(responseKodlari.ER00 as SweetAlertOptions);
                } else if (_err.ER23 !== undefined) {
                    Swal.fire(responseKodlari.ER23 as SweetAlertOptions);
                } else if (_err.ER24 !== undefined) {
                    Swal.fire(responseKodlari.ER24 as SweetAlertOptions);
                } else if (_err.ER25 !== undefined) {
                    Swal.fire(responseKodlari.ER25 as SweetAlertOptions);
                } else if (_err.ER27 !== undefined) {
                    Swal.fire(responseKodlari.ER27 as SweetAlertOptions);
                } else if (_err.email !== undefined) {
                    Swal.fire(responseKodlari.email as SweetAlertOptions);
                } else if (_err.phone !== undefined) {
                    Swal.fire(responseKodlari.phone as SweetAlertOptions);
                } else if (_err.kimlik_no !== undefined) {
                    Swal.fire(responseKodlari.kimlik_no as SweetAlertOptions);
                } else if (_err.ER28 !== undefined) {
                    Swal.fire(responseKodlari.ER28 as SweetAlertOptions);
                } else if (_err.ER29 !== undefined) {
                    Swal.fire(responseKodlari.ER29 as SweetAlertOptions);
                } else if (_err.ER98 !== undefined) {
                    this.firmaHatasi();

                } else if (_err.ER99 !== undefined) {
                    setTimeout(() => {
                        Swal.fire(responseKodlari.ER99 as SweetAlertOptions).then(
                            ({ value }) => {
                                if (value) {
                                    this.routerService.navigate(['profil/firma-profil']);
                                }
                            },
                        );
                    }, 5000);
                } else if (_err.ER97 !== undefined) {
                    setTimeout(() => {
                        this.firmaHatasi();
                    }, 300);
                } else if (_err.ER15 !== undefined) {
                    Swal.fire(responseKodlari.inActiveUser as SweetAlertOptions).then(
                        (val) => {
                            if (val.value) {
                                this.kullaniciService
                                    .resendActivationMail(request.body.email)
                                    .subscribe((response) => {
                                        Swal.fire(
                                            responseKodlari.successProgress as SweetAlertOptions,
                                        );
                                    });
                            }
                        },
                    );
                    this.logout();

                } else {
                    if (error.status !== 404 && error.status !== 401 && error.status !== 400) {
                        // Swal.fire({
                        //   ...responseKodlari.unSuccessProgress, footer: `
                        // <div style="max-width:calc(100vw);"> ${ JSON.stringify(error)} </div>
                        //   ` } as SweetAlertOptions);
                        Swal.fire({
                            ...responseKodlari.unSuccessProgress,
                        } as SweetAlertOptions);
                    } else {
                        return throwError(error);
                    }
                }
            }
        }

        return throwError(error);
    }
}
