<nb-card class="modalType animated fadeInRight" size="giant" style="min-width: 600px;">
    <nb-card-header class="d-flex justify-content-between align-items-center">
        <span>{{ 'DEPO.DEPOEKLE' | translate }}</span>
        <button nbButton (click)="closed()" size="small" class="m-close"
            style="background: none !important;font-size: 18px;">X</button>
    </nb-card-header>
    <nb-card-body [formGroup]="form">
        <!--Depo Adı-->
        <div class="form-group">
            <label class="label">{{ 'DEPO.ADI' | translate }}</label>
            <input type="text" formControlName="title" nbInput fullWidth apasInvalid [placeholder]="'DEPO.ADI' | translate">
        </div>
        <!--//Depo Adı-->

        <div class="text-center">
            <button *ngIf="!editData" nbButton status="success" size="medium" (click)="postForm()"> Kaydet </button>
            <b [nbTooltip]="editData?.can_delete ? 'Depoyu Sil':'Depo içerisinde ürün bulunduğundan dolayı depoyu silemezsiniz'" >
            <button *ngIf="editData" nbButton status="danger"
             [disabled]="!editData?.can_delete"
             (click)="deleteDepo()" > Sil </button>
            </b>
            <button *ngIf="editData" nbButton status="primary" (click)="patchForm()"> Güncelle </button>
        </div>
    </nb-card-body>
</nb-card>