import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AkisItem } from '../../models/akis.model';
// Customers
// Load Actions
export const loadAkis = createAction('[Akis] Load Akis', props<{ page?: number }>());
export const loadAkisSuccess =
    createAction('[Akis] Load Akis Success', props<{ akisItem: AkisItem[], isEnd?: boolean }>());
export const loadAkisFail = createAction('[Akis] Load Akis Fail', props<String>());


export const addItem = createAction('[Akis] Add AkisItem', props<{ akisItem: AkisItem }>());

export const setConnected = createAction('[Akis] SetConnected', props<{ connection: boolean }>());
