import { Component, Input, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'aps-prepare',
  templateUrl: './prepare.component.html',
  styleUrls: ['./prepare.component.scss'],
})
export class PrepareComponent implements OnInit {
  @Input() message: string = 'Veri Hazırlanıyor. Lütfen Bekleyiniz...';
  @Input() text: string = 'Lütfen Filterleri Değiştirerek Tekrar Deneyeniz.';

  constructor() { }
  options: AnimationOptions = {
    path: '/assets/prepare.json',
  };
  ngOnInit() {
  }

}
