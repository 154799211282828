import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ibanValidator } from 'ngx-iban';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { MuhasebeCurrency, responseKodlari, taksit_periot } from '../../../../../pages/constants';
import { removeAllNull } from '../../../../../pages/global-functions';
import { MuhasebeService } from '../../../muhasebe-service/muhasebe.service';
import * as hesapActions from '../../../muhasebe-store/hesaplar/hesap.actions'
import { MuhasebeTabType } from '../../apasx-main.component';

@Component({
  selector: 'kasa-ekle',
  templateUrl: './kasa-ekle.component.html',
  styleUrls: ['./kasa-ekle.component.css'],
  providers: [MuhasebeService],
})

export class KasaEkleComponent implements OnInit {

  muhasebeCurrency = MuhasebeCurrency;
  type: number = 1;
  form: FormGroup;
  account_type: string;
  isLoading: boolean = false;
  authList: any[] = [];
  authListLoading: boolean = false;
  kasaDetail: any;
  date;
  editMode: boolean = false;
  router: any;
  get assignList(): FormArray { return this.form.get('authorized_to') as FormArray; }
  constructor(
    private ref: NbDialogRef<KasaEkleComponent>,
    private fb: FormBuilder,
    private muhasebeService: MuhasebeService,
    private store: Store<{ Hesap }>,
  ) {
    this.initForm();
  }

  ngOnInit() {
    switch (this.type) {
      case 1:
        this.account_type = 'CaseAccount';
        break;
      case 2:
        this.account_type = 'BankAccount';
        this.form.get('bank_name').setValidators(Validators.required);
        this.form.get('bank_name').updateValueAndValidity();
        this.form.get('iban').setValidators([ibanValidator('TR')]); // REQUIRED DEĞİL !
        this.form.get('iban').updateValueAndValidity();
        break;
      case 3:
        this.account_type = 'CrediAccount';
        this.form.get('remaining_dept').setValidators(Validators.required);
        this.form.get('remaining_dept').updateValueAndValidity();
        this.form.get('installment_period').setValidators(Validators.required);
        this.form.get('installment_period').updateValueAndValidity();
        this.form.get('remaining_installments').setValidators(Validators.required);
        this.form.get('remaining_installments').updateValueAndValidity();
        this.form.get('first_installment_date').setValidators(Validators.required);
        this.form.get('first_installment_date').updateValueAndValidity();
        break;
      case 4:
        this.account_type = 'PosAccount';
        break;
      case 5:
        this.account_type = 'CrediCartAccount';
        break;
      default:
        this.account_type = 'CaseAccount';
        break;
    }
    if (this.kasaDetail) {
      this.form.patchValue(this.kasaDetail);
      this.form.get('remaining_dept').disable();
      this.form.get('remaining_installments').disable();
      this.form.get('first_installment_date').disable();
      this.date = this.form.get('first_installment_date').value;
      this.date = moment(this.date, 'X').toDate();
      this.form.get('first_installment_date').setValue(this.date);
      this.form.get('installment_period').disable();
      this.form.get('currency').disable();
      this.editMode = true;
    }
    this.getAuthList();

  }

  getAuthList() {
    this.authListLoading = true;
    this.muhasebeService.getYetkiList().subscribe(resp => {

      resp = resp.filter(x => x.has_auth === true);
      this.authList = resp;
      this.authListLoading = false;

    }, (err) => this.authListLoading = false);

  }
  taksit_periot = taksit_periot;

  hesap_kesim = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
    { value: 13, label: '13' },
    { value: 14, label: '14' },
    { value: 15, label: '15' },
    { value: 16, label: '16' },
    { value: 17, label: '17' },
    { value: 18, label: '18' },
    { value: 19, label: '19' },
    { value: 20, label: '20' },
    { value: 21, label: '21' },
    { value: 22, label: '22' },
    { value: 23, label: '23' },
    { value: 24, label: '24' },
    { value: 25, label: '25' },
    { value: 26, label: '26' },
    { value: 27, label: '27' },
    { value: 28, label: '28' },
    { value: 29, label: '29' },
    { value: 30, label: '30' },
    { value: 31, label: '31' },
  ];

  initForm() {
    this.form = this.fb.group({
      name: [null, Validators.required],
      currency: ['TRY', Validators.required],
      santiye_id: [],
      account_number: [],
      iban: [],
      bank_name: [],
      remaining_dept: [],
      remaining_installments: [],
      first_installment_date: [],
      installment_period: [null],
      closing_day: [],
      open_amount: [],
      authorized_to: [null],
    });

  }

  close() {
    this.ref.close();
  }
  getDetail() {
    this.kasaDetail;
    this.isLoading = true;
    const formVal = removeAllNull(this.form.value);
    this.muhasebeService.getCrediDetail(formVal).subscribe(
      (resp) => {
        this.kasaDetail = resp;
        this.form.patchValue(this.kasaDetail);
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
      },
    );

  }



  postForm() {
    if (!this.form.valid) {

      this.form.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    const formData = removeAllNull(this.form.value);
    formData.account_type = this.account_type;
    this.muhasebeService.createAccount(formData).subscribe(resp => {
      this.isLoading = false;
      this.ref.close(resp);
      this.store.dispatch(hesapActions.addHesapItem({ hesapItem: resp }));
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
    }, (err) => this.isLoading = false);
  }

  patchForm() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    const formData = removeAllNull(this.form.value);
    this.muhasebeService.updateAccount(this.kasaDetail.id, formData).subscribe(resp => {
      this.isLoading = false;
      this.store.dispatch(hesapActions.addHesapItem({ hesapItem: resp }));
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
      this.ref.close(resp);
    });
  }
  prev;
  goBack() {

    if (this.prev === '/pages/muhasebe/hesaplar') {
      this.router.navigate(['pages/muhasebe/hesaplar']);
    } else {
      this.muhasebeService.navigate(MuhasebeTabType.Hesap);
    }
    // this._location.back();
  }

  closeAccount(active) {
   console.log('Kasa Kapat',this.kasaDetail); 
    let text = this.kasaDetail.account_type === 'CrediAccount' ? 
    `${this.kasaDetail.title} kredi hesabını kapatmak istediğinize emin misiniz ? Daha sonra bu hesaba erişmek için Hesaplar sayfasındaki pasif hesapları göster seçeneğini kullanabilirsiniz.`:
    `${this.kasaDetail.title} Kasasını kapatmak istediğinize Emin misiniz?`
    Swal.fire({ ...responseKodlari.shureToDelete, ...{ text: text, confirmButtonText: 'Onayla' } } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        const formData = { is_active: active };
        this.isLoading = true;
       
        this.muhasebeService.updateAccount(this.kasaDetail.id, formData).subscribe(resp => {
          this.store.dispatch(hesapActions.deleteHesapItem({ id: resp.id }));
          this.ref.close(resp);
          Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
          this.goBack();
        });
      }
    });
  }
  deleteAccount() {
    if (!this.kasaDetail.can_delete) {
      Swal.fire({ ...responseKodlari.cantDelete, ...{ text: 'İçerisinde işlem hareketi bulunan kasalar silinemez. Lütfen önce işlem hareketlerini siliniz.' } } as SweetAlertOptions);
      return;
    }
    const text = `${this.kasaDetail.title} Kasasını silmek istediğinizden Emin misiniz? Bu İşlem Geri Alınamaz. Eğer bu kasadan bir işlem yaptıysanız, bu işlem silinecektir.`;
    Swal.fire({ ...responseKodlari.shureToDelete, ...{ text: text } } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        this.muhasebeService.deleteAccount(this.kasaDetail.id).subscribe(resp => {

          this.store.dispatch(hesapActions.deleteHesapItem({ id: resp.id }));
          this.ref.close(resp);
          Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
          this.goBack();
        });
      }
    });

  }
}
