<nb-card class="modalType animated fadeInRight" size="giant" style="min-width: 600px;max-width: 600px;">
    <nb-card-header>
        <span style="float: left;">
            {{ 'PERSONEL.PERSONELEKLE2' | translate }}
        </span>
        <nb-icon style="float: right; cursor: pointer;" icon="close-outline" (click)="close()"></nb-icon>
    </nb-card-header>
    <nb-card-body [nbSpinner]="isSubmiting" nbSpinnerSize="tiny">
        <ng-container [formGroup]="personelForm">
            <div class="row">
                <!--Ad soyad-->
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="form-label label">{{ 'PERSONEL.ADI' | translate }}</label>
                        <input nbInput type="text"  fullWidth formControlName="first_name"     [placeholder]="'PERSONEL.ADI' | translate" apasInvalid/>
                        
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="form-label label">{{'PERSONELEKLEFIRMA.SOYADI'|translate}}</label>
                        <input nbInput [nbAutocomplete]="person" type="text"  fullWidth apasInvalid
                            formControlName="last_name" [placeholder]="'PERSONELEKLEFIRMA.SOYADI'|translate" />                       
                    </div>
                </div>
                <nb-autocomplete class="w-100" #person>
                    <nb-option class="w-100" *ngFor="let option of filteredOptions$ | async" [value]="option" (click)="onSelectionChange(option)">
                           <nb-user #userProfile 
                            
                            size="large" class="user-option"
                            [name]="option.full_name"
                            [title]="option.job_id ? '(' + option.job_id?.name + ')' : ''" 
                            [badgeText]="'ApasID : ' + option.apas_id"
                            [picture]="option.person_thumbnail">
                            </nb-user>
                    </nb-option>
                </nb-autocomplete>
                <!--//Ad Soyad-->

                <!--Kimlik No-->
                <div class="col-md-12">

                    <div class="form-group">
                        <label class="form-label label"> {{ 'PERSONEL.KIMLIKNO' | translate }}</label>
                        <input nbInput type="number" placeholder=" " fullWidth formControlName="kimlik_no"   [placeholder]="'PERSONEL.KIMLIKNO' | translate" />
                    </div>

                </div>
                <!--//Kimlik No-->
                <!--Telefon-->
                <div class="col-md-12 mb-3">
                    <label class="form-label label">Telefon</label>

                    <apas-telefon-input fullWidth class="d-block" formControlName="phone" [labelShow]="false" apasInvalid>
                    </apas-telefon-input>
                    
                </div>

                <!--//Telefon-->
                <!--Meslek-->
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="form-label label">{{ 'PERSONEL.MESLEK' | translate }}</label>
                        <job-select fullWidth class="d-block" apasInvalid formControlName="job_id" [addTag]="true"></job-select>
                    </div>
                </div>
                <!--// Meslek-->
            </div>

        </ng-container>

    </nb-card-body>

    <nb-card-footer>
        <div class="text-center mt-3">

            <button status="success" nbButton class="mt-0" (click)="personelKaydet()" [nbSpinner]="isSubmiting" nbSpinnerStatus="control" [disabled]="isSubmiting">
                {{ 'PERSONEL.KAYDET' | translate }}
            </button>

        </div>
    </nb-card-footer>
</nb-card>
