import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TedarikciModel } from '../models/tedarikci.model';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TedarikciService {

  private readonly API_URL = `${environment.backendurl}api/tedarikci/`;

  constructor(private httpClient: HttpClient) { }
  getTedarikci(): Observable<TedarikciModel[]> {
    return this.httpClient.get<TedarikciModel[]>(this.API_URL);
  }
  postTedarikci(data: TedarikciModel) {
    return this.httpClient
      .post(this.API_URL, data);
  }
  patchTedarikci(id: number, data: any): Observable<TedarikciModel> {
    return this.httpClient.patch<TedarikciModel>(this.API_URL + 'update/' + id + '/', data);
  }

  newList(filter): Observable<any> {
    const url: string = `${this.API_URL}list/`;
    return this.httpClient.post<any>(url, filter);
  }

  patchTedarikciTagEdit(id, data): Observable<TedarikciModel> {
    const url: string = `${this.API_URL}tags/${id}/`;
    return this.httpClient.patch<TedarikciModel>(url, data);
  }

  deleteTedarikci(id) {
    const url: string = `${this.API_URL}delete/${id}/`;
    return this.httpClient.delete(url);
  }


}
