import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { CariFilterInterface } from './cari.reducer';
import { CariHareketModel } from '../../models/cari_hakeret.model';
import { CariModel } from '../../models/cari.model';
// Customers
// Load Actions
// tslint:disable-next-line: max-line-length
export const loadCari = createAction('[Cari] Load Cari',
    props<CariFilterInterface>());

export const clearCari = createAction('[Cari] Clear Cari');

//#region  SetReaded
export const updateCari = createAction('[Cari] Update Cari', props<{ id: number }>());

// tslint:disable-next-line: max-line-length
export const updateCariSuccess = createAction('[Cari] Update Cari Success', props<{ cari: Update<CariModel> }>());
export const updateCariFail = createAction('[Cari] Update Cari Fail', props<String>());

// tslint:disable-next-line: max-line-length
export const loadCariSuccess = createAction('[Cari] Load Cari Success', props<{ cariItem: CariModel[], isEnd?: boolean }>());

// tslint:disable-next-line: max-line-length
export const loadCariFail = createAction('[Cari] Load Cari Fail', props<{ error: string, isEnd?: boolean }>());

export const addCariItem = createAction('[Cari] Add CariItem', props<{ cariItem: CariModel }>());

export const selectCariItem = createAction('[Cari] Select CariItem', props<{ id: number }>());

// tslint:disable-next-line: max-line-length
export const setCariFilter = createAction('[Cari] Set Cari Filter', props<CariFilterInterface>());

export const filterCari = createAction('[Cari] Filter Cari');

export const setCariEnd = createAction('[Cari] Set Cari End', props<{ isEnd: boolean }>());


/** Cari Hareket Region */

export const loadCariHareket = createAction('[Cari] Load Hareket List', props<{ cariId: number, items?: any[] }>());

// tslint:disable-next-line: max-line-length
export const loadCariHareketSuccess = createAction('[Cari] Load Hareket List Success', props<{ cariId: number, items: CariHareketModel[] }>());

// tslint:disable-next-line: max-line-length
export const loadCariHareketFail = createAction('[Cari] Load Hareket List Fail', props<{ cariId: number, err?: String }>());

export const getCariHareket = createAction('[Cari] Get Hareket', props<{ id: number }>());

export const addCariHareket = createAction('[Cari] Add Cari Hareket', props<{ cariId: number, hareketId: number }>());

// tslint:disable-next-line: max-line-length
export const addCariHareketSuccess = createAction('[Cari] Add Cari Hareket Success', props<{ cariId: number, hareket: CariHareketModel }>());

// tslint:disable-next-line: max-line-length
export const removeCariHareket = createAction('[Cari] Remove Cari Hareket', props<{ cariId: number, hareketId: number }>());

// tslint:disable-next-line: max-line-length
export const removeCariHareketSuccess = createAction('[Cari] Remove Cari Hareket', props<{ cariId: number, hareketId: number }>());

// tslint:disable-next-line: max-line-length
export const updateCariHareket = createAction('[Cari] Update Cari Hareket', props<{ cariId: number, hareketId: number }>());

export const calculateHareketBakiye = createAction('[Cari] Calculate Bakiye', props<{ cariId: number }>());
/** Cari Hareket Region */

export const setSelectedHareketId = createAction('[Cari] Set Selected Hareket', props<{hareketId: number}>());

export const updateHareketItem = createAction('[Cari] Update Hareket Item', props<{hareket: CariHareketModel}>())