    <!-- Uyarı Alanı -->
    <div class="apsAlertler animated bounceInRight" cdkDrag cdkDragHandle>
            <div #uyari1 class="animated" *ngIf="gizleGoster">
                <nb-alert  style="background-color: #ff7043ed;" status="success" closable  *ngIf="currentUyari" class="animated tada" (close)="close_alert()"><div class="linked-div" (click)="gotoUyari(currentUyari.error_code)"
                        >
                        <div class="uYari">
                            <nb-icon icon="alert-circle-outline"></nb-icon>
                           {{currentUyari.error_message}}
                         </div>
                     </div>
                     </nb-alert>
            </div>
        
            <!-- <nb-alert #uyari2 status="success" closable  *ngIf="oldUyari" class="animated" style="background-color: #ff7043ed;">
               <div class="uYari">

                 <nb-icon icon="alert-circle-outline"></nb-icon>
                {{oldUyari.error_message}}

                </div>
            </nb-alert> -->
    </div>

    <!-- // Uyarı Alanı //-->