import { Component, forwardRef, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, Subject, concat, of } from 'rxjs';
import { distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { ControlValueAccessorConnector } from '../../../shared/control-value-accessor-connector';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../pages/constants';
import { NgSelectComponent } from '@ng-select/ng-select';
import { MarkaService } from './marka.service';


@Component({
  selector: 'marka-select',
  template: `
      <ng-select
          #markaSelect
          class="uzun-select ng-select-without-bottom-margin"
          [items]="brand$ | async"
          [loading]="brandLoading"
          [typeahead]="brandInput$"
          appendTo="body"
          [addTag]="addTagPromise"
          [placeholder]="'DEPO.MARKA' | translate"
          bindLabel="title"
          bindValue="id"
          [searchable]="true"
          [selectOnTab]="true"
          apasInvalid
          [formControl]="control"
          [addTagText]="'Yeni Marka Ekle'"
      ></ng-select>`,
  providers: [
    MarkaService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarkaSelectComponent),
      multi: true,
    },
  ],
})
export class MarkaSelectComponent extends ControlValueAccessorConnector implements OnInit, OnChanges {

  @Input() formControl!: FormControl;

  @Input() formControlName: string;

  @ViewChild('markaSelect') markaSelect: NgSelectComponent;
  brand$: Observable<any[]> = of([]);
  brandList: any[] = [];
  brandLoading: boolean = false;
  brandInput$ = new Subject<string>();
  default_brand_items: any[];
  @Input() forceRefresh: number;

  constructor(
    private markaService: MarkaService,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.firstBrandList();
    this.control.valueChanges.subscribe(val => {
      this.findOrGetProductById(val);
    });
  }

  findOrGetProductById(instanceId?) {
    // Ahmet Abi yazacağın fonksiyon isimlerini sevsinler.
    // Eğer Id'ye kayıtlı bir ürün elimdeki listede mevcutsa, sıkıntı yok,
    // Eğer mevcut değilse, bi zahmet request atıp elimdeki listeye pushlayayım.

    if (!instanceId) return;
    // tslint:disable-next-line: triple-equals
    this.brand$.subscribe(data => {
      const found = data.find(x => x.id === instanceId);
      if (!found) {

        this.markaService.detail(instanceId).subscribe(val => {
          this.brand$ = of([val]);
        });

      }
    });


  }

  ngOnChanges(changes: SimpleChanges): void {
    // Force Refresh.
    
    this.findOrGetProductById(this.control.value);
  }

  private firstBrandList() {
    this.brandLoading = true;
    this.markaService.searchBrand().subscribe(data => {
      this.default_brand_items = data;
      this.brandLoading = false;
      // this.cd.detectChanges();

      this.loadBrand();
    }, err => {
      this.brandLoading = false;
    },
    );
  }

  private loadBrand() {
    this.brand$ = concat(
      of(this.default_brand_items), // default items
      this.brandInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.brandLoading = true),
        switchMap(term => this.markaService.searchBrand(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.brandLoading = false),
        )),
      ),
    );
  }



  addTagPromise = (name?) => {
    this.markaService.adBrand({ title: name }).subscribe(response => {
      this.markaSelect.close();
      this.brand$ = of([response]);

      this.control.setValue(response.id);
      this.brandLoading = false;
    }, err => {
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    });
  }
}
