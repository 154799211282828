<div class="pt-3">

        <p style="text-align: center; font-size: large; font-weight: 600; color: #2d6187;" >{{message}}</p>
        <p style="text-align: center; color: #2d6187; font-size: small;" *ngIf="text">{{text}}</p>

        <ng-lottie
          width="500px"
          height="500px"
          containerClass="moving-box another-class"
          [options]="options"
        ></ng-lottie>

</div>
