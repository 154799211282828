import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TagGroup, TagItem } from '../models/tag.models';
import { Store } from '@ngrx/store';
import * as fromTag from '../store/tags/tags.connector';
import { environment } from '../../environments/environment';

@Injectable()
export class TagService {
    private readonly API_URL = `${environment.backendurl}api/tags/`;

    constructor(
        private httpClient: HttpClient,
        private store: Store<{}>,
    ) { }


    getTagList(): Observable<TagGroup[]> {
        const url: string = `${this.API_URL}list/`;
        return this.httpClient.get<TagGroup[]>(url);
    }
    getTagSerarch(data): Observable<any> {
        const url = this.API_URL + 'search/';
        return this.httpClient.post<any>(url, data);
    }

    addTagGroup(data: any): Observable<TagGroup> {
        const url: string = `${this.API_URL}addgroup/`;
        return this.httpClient.post<TagGroup>(url, data);
    }

    updateTagGroup(groupId: number, data: any): Observable<TagGroup> {
        const url: string = `${this.API_URL}updategroup/${groupId}/`;
        return this.httpClient.patch<TagGroup>(url, data);
    }
    deleteTagGroup(itemId: number): Observable<any> {
        const url: string = `${this.API_URL}deletegroup/${itemId}/`;

        return this.httpClient.delete<any>(url);
    }

    addTagItem(data: any): Observable<TagItem> {
        const url: string = `${this.API_URL}additem/`;
        return this.httpClient.post<TagItem>(url, data);
    }

    updateTagItem(itemId: number, data: any): Observable<TagItem> {
        const url: string = `${this.API_URL}updateitem/${itemId}/`;
        return this.httpClient.patch<TagItem>(url, data);
    }

    deleteTagItem(itemId: number): Observable<any> {
        const url: string = `${this.API_URL}deleteitem/${itemId}/`;

        return this.httpClient.delete<any>(url);
    }

    public tagList$ = this.store.select(fromTag.selectTags);
}
