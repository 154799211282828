import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'izypay-payment-modal',
  templateUrl: './izypay-payment-modal.component.html',
  styleUrls: ['./izypay-payment-modal.component.css'],
})
export class IzypayPaymentModalComponent implements OnInit {
  myHtml: string;
  @ViewChild('iframe') iframe: ElementRef;

  trustedHTML;

  constructor(private sanitizer: DomSanitizer,
    private ref: NbDialogRef<IzypayPaymentModalComponent>) {

    this.trustedHTML = this.sanitizer.bypassSecurityTrustHtml(this.myHtml);
    setTimeout(() => {
      this.setIframe(this.iframe);
    });
  }

  ngOnInit() {
  }

  private setIframe(iframe: ElementRef): void {
    const win: Window = iframe.nativeElement.contentWindow;
    const doc: Document = win.document;
    doc.open();
    doc.write(this.myHtml);
    doc.close();
  }
  closed(resp?) {
    this.ref.close(resp);
  }

}
