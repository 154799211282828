import { createReducer, on } from '@ngrx/store';
import * as MuhasebeCalendarAction from './muhasebe-calendar.actions'
interface MuhasebeDefaultCalendarState {
    dates: any;
}
const defaultMuhasebeCalendar: MuhasebeDefaultCalendarState = {
    dates: null,
};



const calendarMuhasebeReducer = createReducer(
    defaultMuhasebeCalendar,
    on(MuhasebeCalendarAction.SetCalendarMonth, (state, payload:any) => {
         return { ...state.dates, dates:payload.dates};
    }),

);

function calendarMuhasebeReducers(action, state) {
    return calendarMuhasebeReducer(action, state);
}

export {
    MuhasebeDefaultCalendarState,
    defaultMuhasebeCalendar,
    calendarMuhasebeReducers,
};
