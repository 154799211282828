<nb-card status="primary" size="large" style="max-width: 700px;">
  <nb-card-header>
    {{ 'PERSONEL.PERSONELEKLE2' | translate }}
    <button nbButton (click)="closed()" size="tiny" class="m-close"
      style="background: none !important; color: #fff !important;">X</button>
  </nb-card-header>

  <nb-card-body>

    <button (click)="personelFormSil()" class="btn btn-primary btn-sm mr-2">
      <nb-icon icon="minus" pack="eva"></nb-icon>
    </button>
    <button (click)="personelFormEkle()" class="btn btn-primary btn-sm mr-2">
      <nb-icon icon="plus" pack="eva"></nb-icon>
    </button>



    <form [formGroup]="personelEkleForm">
      <div formArrayName="personel"
        *ngFor="let i = index ; let pers of personelForm; ">

        <div [formGroupName]="i" class="row col-md-12 mb-2">

          <div class="col-md-3 px-1 ">
            <input nbInput fullWidth [placeholder]="'PERSONEL.TCKIMLIKNO' | translate" formControlName="tc_kimlik_no" type="number" min="0"
              fieldSize="medium">
          </div>
          <div class="col-md-3 px-1">
            <div class="row">
              <div class="col"> <input nbInput fullWidth [placeholder]="'PERSONEL.ADISOYADI' | translate" formControlName="name"
                  fieldSize="medium">
              </div>
              <div class="col" *ngIf="pers.get('name').touched && pers.get('name').hasError('required')"
                class="uyari">
                {{ 'PERSONEL.UYARI9' | translate }}
              </div>
            </div>


          </div>
          <div class="col-md-3 px-1">
         
          </div>
          <div class="col-md-3 px-1 ">
            <ng-select class="m-select" [searchable]="false" [clearable]="true" formControlName="statu"
              [placeholder]="'PERSONEL.CALISANDURUMU' | translate">
              <ng-option *ngFor="let statu of statu_list" value={{statu.value}}>{{ statu.label }}</ng-option>
            </ng-select>
          </div>
          <div *ngIf="personelEkleForm.get('personel').dirty && 
           personelEkleForm.controls.personel.hasError('required')"> {{ 'PERSONEL.UYARI7' | translate }}
          </div>

        </div>
      </div>
    </form>
  </nb-card-body>

  <!--Taşeron'a Ait Personel Bulunmuyorsa Personel Ekleme Penceresi Açılacak -->

  <nb-card-footer>
    <button (click)="personelEklemeKaydet()" class="btn btn-primary">{{ 'PERSONEL.KAYDET' | translate }}  </button>
  </nb-card-footer>
</nb-card>