<nb-card class="modalType animated fadeInRight" size="giant" style="width: 600px;">
    <nb-card-header class="d-flex align-items-center justify-content-between">
        {{'FIRMAEKLE.FIRMAEKLE'|translate}}
        <button nbButton (click)="closed()" size="tiny" class="heryerdeClose">X</button>
    </nb-card-header>
    <nb-card-body [formGroup]="kayitFormCompany" [nbSpinner]="isLoading" nbSpinnerSize="tiny">


            <!-- Firma İsmi Alani -->
            <div class="form-group">
                <label class="font-weight-bold">{{'FIRMAEKLE.FIRMAISMI'|translate}}</label>
                <input apasInvalid nbInput fullWidth [placeholder]="'FIRMAEKLE.FIRMAISMI'|translate" type="name"
                    name="companyname" formControlName="companyname">
            </div>

            <!-- Telefon bilgileri Alani -->
            <div class="form-group">
                <label class="font-weight-bold">Telefon</label>
                <apas-telefon-input class="d-block" apasInvalid formControlName="companyphone"></apas-telefon-input>
            </div>

            <div class="form-group">
                <label class="font-weight-bold">Şehir</label>
                <ng-select formControlName="city" [items]="sehirAndIlce" bindLabel="city" bindValue="city"
                    appendTo="body" placeholder="Şehir" apasInvalid (change)="selectSehir($event)"></ng-select>
            </div>

            <div class="form-group">
                <label class="font-weight-bold">İlçe</label>
                <ng-select formControlName="district" [items]="ilceler" bindLabel="district" bindValue="district"
                    appendTo="body" placeholder="İlçe" apasInvalid></ng-select>
            </div>

            <div class="text-center">
                <button nbButton status="success" size="medium"
                    (click)="kaydetKurum()">{{'FIRMAEKLE.KAYDET'|translate}}</button>
            </div>
    </nb-card-body>

</nb-card>