import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class HatirlatmaService {
    private readonly API_URL = `${environment.backendurl}api/hatirlatma/`;

    constructor(private httpClient: HttpClient) { }

    getHatirlatma(data?: number): Observable<any[]> {
        const url: string = `${this.API_URL}${data ? (data + '/') : ''};`;
        return this.httpClient.get<any[]>(url);
    }

    postHatirlatma(data) {
        return this.httpClient.post(this.API_URL, data);
    }

    getHatirlatmaDetay(instanceId: number): Observable<any> {
        const url: string = `${this.API_URL}tek/${instanceId}/`;
        return this.httpClient.get<any>(url);
    }

    patchHatirlatma(instanceId: number, data): Observable<any> {
        const url: string = `${this.API_URL}update/${instanceId}/`;
        return this.httpClient.patch<any>(url, data);
    }
}
