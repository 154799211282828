import { createSelector } from '@ngrx/store';
import * as fromRoot from '../../app-state';
import { tagAdapter, TagState } from './tags.reducer';

const getTagFeatureState = createSelector(
        fromRoot.getOrganizationSelector, (state: fromRoot.OrganizationState) => state?.TagsGroups,
);


export const selectTagLoading = createSelector(
        getTagFeatureState,
        (state: TagState) => state.loading,
);

export const selectTagLoaded = createSelector(
        getTagFeatureState,
        (state: TagState) => state.loaded,
);

export const selectTags = createSelector(
        getTagFeatureState,
        tagAdapter.getSelectors().selectAll,
);





