import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable()
export class TakvimService {
    private readonly API_URL = `${environment.backendurl}api/kullanici/takvim/`;

    constructor(private httpClient: HttpClient) { }

    getTakvim(start_time: number, end_time: number): Observable<any> {
        return this.httpClient.get<any>(this.API_URL + start_time + '/' + end_time + '/');
    }

    getTakvimDashboard(data): Observable<any> {
        return this.httpClient.post<any>(this.API_URL, data);
    }


}
