<nb-card class="modalType animated fadeInRight" size="giant" style="width: 750px;">

        <nb-card-header class="d-flex justify-content-between">
            {{'checklist' | translate}} {{editData?.length > 0 ? 'Düzenle' : 'Oluştur'}} 
            <button nbButton (click)="close()" size="tiny" class="heryerdeClose">X</button>
          </nb-card-header>
    
        <nb-card-body>
    
           <div  [formGroup]="form"> 
            <div  formArrayName="checkitem_set">
                <div class="row px-2 align-items-center" *ngFor="let item of checkItems.controls; let i = index;" [formGroupName]="i">
                    <div>
                       <b> {{i + 1}}</b>
                    </div>
                    <div class="col-md px-2">
                        <input nbInput fullWidth formControlName="title" placeholder="Başlık" apasInvalid> 
                    </div>
                    <div class="text-right">
                      
                        <button nbButton status="danger" (click)="deleteCheckItem(i)"> 
                           <nb-icon icon="minus-outline"></nb-icon>
                       </button>  
                    </div>
                </div>
            </div>
            <div class=" row px-2 d-flex justify-content-end">
                <button nbButton status="primary" (click)="addCheckItem()">
                    <nb-icon icon="plus-outline"></nb-icon>
                </button>
            </div>
      
           </div>
        </nb-card-body>
    
    
        <nb-card-footer class="text-center">
            <button nbButton status="danger" *ngIf="editData.length > 0" class="silButton" status="danger" size="small" (click)="deleteCheckList()">CheckListi Sil</button>
            <button nbButton status="primary" *ngIf="editData.length === 0" size="small" (click)="post()" [nbSpinner]="isLoading" [disabled]="isLoading"> Kaydet </button> 
            <button nbButton status="primary" *ngIf="editData.length > 0" size="small" (click)="patch()" [nbSpinner]="isLoading" [disabled]="isLoading"> Güncelle </button> 

        </nb-card-footer>
    </nb-card>