import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { acceptedImageFormats, responseKodlari } from '../../../../constants';
import { clearTurkishCharacters } from '../../../../global-functions';
import { EntegrasyonService } from '../../../../../services/entegrasyon.service';
import { EFaturaGirisComponent } from '../../../e-fatura-sureci/e-fatura-giris/e-fatura-giris.component';

@Component({
  selector: 'efatura-settings',
  templateUrl: './efatura-settings.component.html',
  styleUrls: ['./efatura-settings.component.scss'],
  providers: [EntegrasyonService],
})
export class EfaturaSettingsComponent implements OnInit {
  imgFormatter = acceptedImageFormats;
  isLoading: boolean = false;
  isLoadingFautraInfo: boolean = false;

  // tslint:disable-next-line: max-line-length
  faturaTagControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(3)]);
  // tslint:disable-next-line: max-line-length
  earsivTagControl: FormControl = new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(3)]);

  firmaLogo: FormControl = new FormControl(null, Validators.required);

  firmaImza: FormControl = new FormControl(null, Validators.required);
  entegrasyonInfo;

  constructor(
    private entegrasyonService: EntegrasyonService,
    private fb: FormBuilder,
    private nbDialogService: NbDialogService,
    private cd: ChangeDetectorRef
  ) {
    this.getEntegrasyonInfo();

  }

  ngOnInit(): void {

  }

  initForm() {
    this.faturaTagControl.valueChanges.subscribe(val => {
      this.faturaTagControl.setValue(clearTurkishCharacters(val).toUpperCase(), { emitEvent: false });
    });
    this.earsivTagControl.valueChanges.subscribe(val => {
      this.earsivTagControl.setValue(clearTurkishCharacters(val).toUpperCase(), { emitEvent: false });
    });

  }


  getEntegrasyonInfo() {
    this.isLoadingFautraInfo = true;
    this.entegrasyonService.getLoginInfo().subscribe((data) => {
      this.entegrasyonInfo = data;
      console.log('this.entegrasyonInfo', this.entegrasyonInfo);
      if (this.entegrasyonInfo?.efatura_prefix) {
        this.faturaTagControl.setValue(this.entegrasyonInfo.efatura_prefix);
      }
      if (this.entegrasyonInfo?.earsiv_prefix) {
        this.earsivTagControl.setValue(this.entegrasyonInfo.earsiv_prefix);
      }

      if (this.entegrasyonInfo?.imza) {
        this.firmaImza.setValue(data.imza);
      }

      if (this.entegrasyonInfo?.firma_logo) {
        this.firmaLogo.setValue(data.firma_logo);
      }
      this.isLoadingFautraInfo = false;
      this.cd.detectChanges();

    }, err => {
      console.log('err', err);
      this.isLoadingFautraInfo = false;
      this.cd.detectChanges();

    });
  }
  deleteEntegrasyon() {
    Swal.fire({
      ...responseKodlari.shureToDelete,
      ...{
        text: `${this.entegrasyonInfo.api_key} VKN/TCKN numarası ile oluşturulan entegrasyonu silmek istiyormusunuz?`,
      },
    } as SweetAlertOptions).then(({ value }) => {
      if (value) {
        this.entegrasyonService.deleteLoginInfo().subscribe(() => {
          Swal.fire(responseKodlari.succesDeleted as SweetAlertOptions);
          this.cd.detectChanges();
          this.entegrasyonInfo = null;
          this.getEntegrasyonInfo();
        });
      }
    });
  }
  entegrasyonGiris() {
    let enApiKey;
    if (this.entegrasyonInfo) {
      enApiKey = this.entegrasyonInfo.api_key;
    }

    this.nbDialogService.open(EFaturaGirisComponent, { context: { api_key: enApiKey } }).onClose.subscribe(resp => {
      if (resp) {
        this.getEntegrasyonInfo();
      }
    });
  }


  setFaturaPrefix() {
    if (!this.faturaTagControl.valid) {
      this.faturaTagControl.markAsTouched();
      return;
    }

    this.isLoading = true;

    this.entegrasyonService.updateFaturaSetting({ efatura_prefix: this.faturaTagControl.value }).subscribe(val => {
      this.isLoading = false;
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
    }, (err) => {
      this.isLoading = false;
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    });
  }


  setEArsivPrefix() {
    if (!this.earsivTagControl.valid) {
      this.earsivTagControl.markAsTouched();
      return;
    }

    this.isLoading = true;

    this.entegrasyonService.updateFaturaSetting({ earsiv_prefix: this.earsivTagControl.value }).subscribe(val => {
      this.isLoading = false;
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
    }, (err) => {
      this.isLoading = false;
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    });
  }

  postFirmaLogo() {
    this.isLoading = true;

    this.entegrasyonService.updateFaturaSetting({ firma_logo: this.firmaLogo.value }).subscribe(val => {
      this.isLoading = false;
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
    }, (err) => {
      this.isLoading = false;
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    });
  }

  postImza() {
    this.isLoading = true;

    this.entegrasyonService.updateFaturaSetting({ imza: this.firmaImza.value }).subscribe(val => {
      this.isLoading = false;
      Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
    }, (err) => {
      this.isLoading = false;
      Swal.fire(responseKodlari.unSuccessProgress as SweetAlertOptions);
    });
  }
}
