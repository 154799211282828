import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap, concatMap, switchMap } from 'rxjs/operators';
import * as cariActions from './cari.actions';
import * as fromCari from './cari.connector';
import { HttpErrorResponse } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { cariHareketTypes } from '../../../constants';
import { addOrUpdate } from '../../../global-functions';
import { CariModel } from '../../models/cari.model';
import { CariHareketModel } from '../../models/cari_hakeret.model';
import { MuhasebeService } from '../../muhasebe-service/muhasebe.service';
import { calculateCariBakiye, clearCariHareket } from '../../muhasebe-main/cari-hareket-detay/clear-cari-hareket';
import { ApasCrypto } from '../../../../shared/ApasEncryptor/apas-encryptor';


@Injectable()
export class CariEffects {

    loaCari$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(cariActions.loadCari),
            mergeMap((props) => this.muhasebeService.getCariList(props)
                .pipe(
                    map((cariler: CariModel[]) => {
                        let isEnd: boolean = false;
                        if (cariler.length < 20) isEnd = true;
                        return (cariActions.loadCariSuccess({ cariItem: cariler, isEnd: isEnd }));
                    }),
                    catchError((err: HttpErrorResponse) => {
                        let isEnd: boolean = false;
                        if (err.status === 404) isEnd = true;
                        return of(cariActions.loadCariFail({ error: 'Cari yüklenemedi.', isEnd: isEnd }));
                    }),
                )),
        );
    });

    cariUpdateS$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(cariActions.updateCari),
            mergeMap((props) => this.muhasebeService.getCariDetay(props.id).pipe(
                map((cariItem: CariModel) => {
                    return cariActions.updateCariSuccess({ cari: { id: props.id, changes: cariItem } },
                    );
                }),
            )),
            mergeMap((props: any) => {
                this.store.dispatch(cariActions.loadCariHareket({ cariId: Number(props.cari.id) }))
                return of(props);
            }),

        );
    });

    loadHareketList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(cariActions.loadCariHareket),
            tap((props) => cariActions.updateCari({ id: props.cariId })),
            concatMap((props) => this.muhasebeService
                .getCariHareketList({ instance_type: 'cari', instance_id: props.cariId }).pipe(
                    map((hareketList: CariHareketModel[]) => {
                        const data = { ...props, ...{ items: hareketList } };
                        return data;
                    }),
                    catchError((err) => of(cariActions.loadCariHareketFail({ cariId: props.cariId, err: err }))),
                ),
            ),
            concatMap(async (data: any) => {
                const cleanItems = await this.clearAllData(data.items);
                data.items = cleanItems;
                return cariActions.loadCariHareketSuccess(data);
            }),
        );
    });

    async clearAllData(data) {
        let newData = [];
        if (!data) {
            return newData;
        }
        const decryptor = new ApasCrypto();

        for await (const elm of data) {
            const clearItem = await clearCariHareket(elm, decryptor);
            newData = addOrUpdate(newData, clearItem);
        }
        return newData;
    }


    addHesapHareket$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(cariActions.addCariHareket),
            mergeMap((props) => this.muhasebeService.getCariHareketDetailForHesap(props.cariId, props.hareketId).pipe(
                map((hareket: CariHareketModel) => {
                    return cariActions.addCariHareketSuccess({ cariId: props.cariId, hareket: hareket });
                }),
                catchError((err) => of(cariActions.loadCariHareket({ cariId: props.cariId }))),
            ),
            ),

        );
    });

    addHesapHareketSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(cariActions.addCariHareketSuccess),
            mergeMap(async (data: any) => {
                return cariActions.loadCariHareket({ cariId: data.cariId });
            }),
        );
    });

    removeHareket$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(cariActions.removeCariHareket),
            mergeMap((props) => this.muhasebeService.deleteCariHareket(props.hareketId).pipe(
                map((hareket: any) => {
                    return cariActions.updateCari({ id: props.cariId });
                }),
                catchError((err) => of(cariActions.loadCariHareket({ cariId: props.cariId }))),
            ),
            ),
        );
    });

    removeHareketSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(cariActions.removeCariHareketSuccess),
            mergeMap(async (data: any) => {
                return cariActions.loadCariHareket({ cariId: data.cariId });
            }),
        );
    });

    loadHesapHareketSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(cariActions.loadCariHareketSuccess),
            mergeMap(async (props) => {
                try {
                    let selected;
                    this.store.pipe(select(fromCari.selectCariById(props.cariId))).subscribe(resp => {
                        selected = resp;
                    }).unsubscribe();
                    let newList = [...props.items];

                    newList = newList.sort((x, y) => y.timestamp - x.timestamp);

                    newList = newList.map((item) => Object.assign({}, item, { selected: false }));

                    await calculateCariBakiye(selected.current_value, newList).then(val => newList = val);
                    newList = newList.sort((x, y) => y.timestamp - x.timestamp);
                    const cari: Update<CariModel> = { id: props.cariId, changes: { cari_harekets: newList, isLoading: false } };
                    return cariActions.updateCariSuccess({ cari: cari });
                } catch (error) {
                    return cariActions.updateCariFail('could not load cari');
                }
            }),
        );
    });

    loadCariHareketFail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(cariActions.loadCariHareketFail),
            map((props) => {
                cariActions.updateCari({ id: props.cariId });
                return props;
            }),
            mergeMap(async (props) => {
                let selected;
                this.store.pipe(select(fromCari.selectCariById(props.cariId))).subscribe(resp => {
                    selected = resp;
                }).unsubscribe();
                // tslint:disable-next-line: max-line-length
                const cari: Update<CariModel> = { id: props.cariId, changes: { cari_harekets: [], isLoading: false } };
                return cariActions.updateCariSuccess({ cari: cari });
            }),
        );
    });

    cariSelect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(cariActions.selectCariItem),
            map(() => {
                return cariActions.setCariFilter(null);
            }),
        );
    });
    cariHareketTypes = cariHareketTypes;
    constructor(
        private actions$: Actions,
        private store: Store<{}>,
        private muhasebeService: MuhasebeService,
    ) {

    }

}
