import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { prefixBase64, responseKodlari, telefonKodu } from '../../../pages/constants';
import { addOrUpdate, removeAllNull } from '../../../pages/global-functions';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { PersonelDetayComponent } from '../personel-detay/personel-detay.component';
import { PersonelEkleFirmaComponent } from '../personel-ekle-firma/personel-ekle-firma.component';
import { PersonelGrubuOlusturComponent } from '../personel-grubu-olustur/personel-grubu-olustur.component';

@Component({
  selector: 'personel-main',
  templateUrl: './personel-main.component.html',
  styleUrls: ['./personel-main.component.scss'],
  providers: [OrganizasyonService],
})
export class PersonelMainComponent implements OnInit {

  @Input() gizle: boolean = true;
  telefonKodu = telefonKodu[0].maskList;
  form: FormGroup;
  rows = [];
  isLoading: boolean = false;
  prefixBase64 = prefixBase64;
  gruplar: any[];
  durdur = false;
  amIOwner: boolean = false;
  get filterFormGroup(): FormGroup { return this.form.get('filter') as FormGroup; }
  constructor(private fb: FormBuilder,
    private router: Router,
    private organizationService: OrganizasyonService,
    private nbDialogService: NbDialogService) {
    this.initForm();
    this.amIOwner = JSON.parse(localStorage.getItem('org')).is_owner;
  }

  ngOnInit(): void {
    this.getPersonelList();
    this.orgSubscription();
  }

  initForm() {
    this.form = this.fb.group({
      page: [0],
      count: [20],
      search: [],
      order_by: [],
      filter: this.fb.group({
        aktif: [true],
        pasif: [false],
        yetkili: [false],
        yetkisiz: [false],
      }),
    });
    this.form.get('search').valueChanges.subscribe(() => {
      this.filtrele();
    });
    this.form.get('order_by').valueChanges.subscribe(() => {
      this.rows = [];
      this.isLoading = true;
      this.getPersonelList();
    });

    this.filterFormGroup.get('aktif').valueChanges.subscribe(value => {
      if (value) {
        this.filterFormGroup.get('pasif').setValue(false, { emitEvent: false });
        this.filterFormGroup.get('yetkili').setValue(false, { emitEvent: false });
        this.filterFormGroup.get('yetkisiz').setValue(false, { emitEvent: false });
      } else {
        this.filterFormGroup.get('aktif').setValue(true);
      }
      this.filtrele();

    });

    this.filterFormGroup.get('pasif').valueChanges.subscribe(value => {
      if (value) {
        this.filterFormGroup.get('aktif').setValue(false, { emitEvent: false });
        this.filterFormGroup.get('yetkili').setValue(false, { emitEvent: false });
        this.filterFormGroup.get('yetkisiz').setValue(false, { emitEvent: false });

      } else {
        this.filterFormGroup.get('aktif').setValue(true);
      }
      this.filtrele();

    });

    this.filterFormGroup.get('yetkili').valueChanges.subscribe(value => {
      if (value) {
        this.filterFormGroup.get('aktif').setValue(false, { emitEvent: false });
        this.filterFormGroup.get('pasif').setValue(false, { emitEvent: false });
        this.filterFormGroup.get('yetkisiz').setValue(false, { emitEvent: false });

      } else {
        this.filterFormGroup.get('aktif').setValue(true);
      }
      this.filtrele();

    });

    this.filterFormGroup.get('yetkisiz').valueChanges.subscribe(value => {
      if (value) {
        this.filterFormGroup.get('aktif').setValue(false, { emitEvent: false });
        this.filterFormGroup.get('pasif').setValue(false, { emitEvent: false });
        this.filterFormGroup.get('yetkili').setValue(false, { emitEvent: false });
      } else {
        this.filterFormGroup.get('aktif').setValue(true);
      }
      this.filtrele();
    });
  }

  request;
  getPersonelList() {
    if (this.request) {
      this.request.unsubscribe();
    }
    this.isLoading = true;
    const formVal = removeAllNull(this.form.value);
    if (formVal.search) formVal.search = formVal.search.toLowerCase();
    this.request = this.organizationService.personelList(formVal).subscribe(data => {
      data.forEach(elm => {
        this.rows = addOrUpdate(this.rows, elm);
      },
      );

      this.rows = [...this.rows];
      this.isLoading = false;

    }, (err) => {
      this.isLoading = false;
      this.durdur = true;
    });

  }
  filtrele() {
    this.rows = [];
    this.form.get('page').setValue(0);
    this.durdur = false;
    this.getPersonelList();
  }

  goToNextPage() {
    if (!this.isLoading && this.durdur === false) {
      this.form.get('page').setValue(Number(this.form.get('page').value) + 1);
      this.getPersonelList();
    }
  }


  personelArea(event) {

    const val = event.target.value.toLowerCase();

    if (val.length < 2) {
      if (!this.form.get('search').value) return;
    }
    this.isLoading = true;
    this.form.get('page').setValue(0);
    // filter our data
    this.form.get('search').setValue(val);

    this.rows = [];

    setTimeout(() => {
      this.getPersonelList();
    }, 300);
  }

  viewDetail(item) {

    this.nbDialogService.open(
      PersonelDetayComponent,
      { context: { kullaniciBilgileri: item }, closeOnBackdropClick: false },
    ).onClose.subscribe(resp => {
      if (resp) {
        this.filtrele();
      }
    });
  }

  grupOlsutur() {
    this.nbDialogService.open(PersonelGrubuOlusturComponent, { closeOnBackdropClick: false });
  }
  yetkiliKullanici(item) {
    // 

      // 

      let kalan_kullanim = 0;
      this.isLoading = true;

      this.organizationService.get_personel_remaining().subscribe(data => {
        kalan_kullanim = data['kalan'];
        this.isLoading = false;


        if (kalan_kullanim > 0) {

          Swal.fire({
            ...responseKodlari.userCount, html: `<b style='color:#01579b'>
            Mevcut Paketinizde Kalan Kullanıcı Hakkınız: <b  style='color:#dd3333'>${kalan_kullanim} </b></b><br> <b>
            ${item.name} </b>
            kullanıcı olarak artık veri girişi yapıp yetki verdiğiniz modulleri kullanabilecektir.`,
          } as SweetAlertOptions).then((result) => {
            if (result.value) {
              this.add_as_yetkili(item);
            }
          });
        } else {
          Swal.fire(responseKodlari.userAccessEnd as SweetAlertOptions).then((result) => {
            if (result.value) {
              this.router.navigate(['profil/firma-profil']);
            }
          });
        }
      }, () => {
        this.isLoading = false;

      });
  
  }

  add_as_yetkili(item) {

    this.isLoading = true;

    this.organizationService.add_yetkili_user({ 'apas_id': item.apas_id }).subscribe(data => {
      this.isLoading = false;

      if (data.SC01) {
        item.is_my_user = true;
        Swal.fire(responseKodlari.successProgress as SweetAlertOptions);
        this.filtrele();
      } else if (data.SC02) {
        Swal.fire(responseKodlari.personel.personelDavetEdildi as SweetAlertOptions);
      }

      // 
    }, () => {
      this.isLoading = false;

    });

  }

  orderStatuses = {
    unvan: true,
  };

  organizationSubscription;
  hasAuthorization = false;
  orgSubscription() {
    this.organizationSubscription = this.organizationService.org$.subscribe(StateOrgInfo => {
      if (StateOrgInfo.moduls) {
        const modules = StateOrgInfo.moduls;
        const yetki = modules.find(x => x.slug === 'yetki');
        if (yetki.value) {
          this.hasAuthorization = true; // İşlem yapan personelein yetki verme yetkisi varmı
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.organizationSubscription) this.organizationSubscription.unsubscribe();
  }
  openPersonelAdd() {
    this.nbDialogService.open(PersonelEkleFirmaComponent).onClose.subscribe(resp => {
      if (resp) { 
        this.rows = addOrUpdate(this.rows, resp);

        if (this.hasAuthorization && this.amIOwner) {
          const swallToFire: SweetAlertOptions = {
            title: 'Yetkili Kullanıcı Olarak Eklemek İster Misiniz?',
            text: `${resp.name} personelinizi Yetkili Kullanıcı olarak tanımlamak ister misiniz?`,
            showCancelButton: true,
            cancelButtonText: 'Hayır',
            showConfirmButton: true,
            confirmButtonText: 'Evet',
          };

          Swal.fire(swallToFire).then(({ value }) => {
            if (value) {
              this.yetkiliKullanici(resp);
            }
          });
        }

      }
    });
  }

  removeUserAuth(kullanici) {
    Swal.fire({
      title: 'Onaylıyormusunuz ?',
      text: `${kullanici.name} kullanıcınızın yetkilerini alırsanız bu kullanıcı veri girişi yapamaz.`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'İptal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Evet',
    }).then((result) => {
      if (result.value) {
        this.organizationService.remove_yetkili_user(kullanici.apas_id).subscribe(success => {

          Swal.fire(
            'Kullanıcının yetkileri Kapatıldı.',
            ' ',
            'success',
          );
          this.filtrele();

          kullanici.is_my_user = false;
        });
      }
    });
  }

  orderBy(orderKey, status) {

    Object.keys(this.orderStatuses).forEach(key => {
      this.orderStatuses[key] = null;
    });

    // İkinci aşamada işlemi yapan orderKey'in statusu true ise false,
    // false ise true'ya atandı ki daha sonra tam tersi işlem yapılabilsin.
    this.orderStatuses[orderKey] = !status;
    this.orderStatuses = { ...this.orderStatuses };

    // 
    if (status) {
      orderKey = `-${orderKey}`;
    }
    // son olarak formun içindeki orderby anahtarı atandı.
    // Formda orderby değişmlerine (valueChanges) subscribe olunduğu için otomatik olarak yeni liste getirilecek.
    this.form.get('order_by').setValue(orderKey);
  }

}
