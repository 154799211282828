import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HizmetTeklifModel } from '../models/teklifler.model';

@Injectable({ providedIn: 'root' })
export class TeklifService {

  private readonly API_URL = `${environment.backendurl}api/gelenteklif/`;

  constructor(private httpClient: HttpClient) { }

  getHizmetTeklifileri(): Observable<any[]> {
    const url = this.API_URL;
    return this.httpClient.get<any[]>(url);
  }
  postHizmetTeklifi(data: HizmetTeklifModel, teklifId?: number): Observable<HizmetTeklifModel> {
    let url = this.API_URL;
    if (teklifId) {
      url = `${this.API_URL}${teklifId}/`;
    }
    return this.httpClient.post<HizmetTeklifModel>(url, data);
  }

  getTeklifForTalep(identifier: string): Observable<any[]> {
    const url = `${this.API_URL}talepdetay/${identifier}/`;
    return this.httpClient.get<any>(url);
  }

  getTeklifDetay(teklifId): Observable<any> {
    const url: string = `${this.API_URL}${teklifId}/`;
    return this.httpClient.get<any>(url);
  }

  deleteTeklifItem(data): Observable<any> {
    const url: string = `${this.API_URL}delete/`;
    return this.httpClient.patch<any>(url, data);
  }

  getItemHistory(item_id): Observable<any[]> {
    const url: string = `${this.API_URL}itemhistory/${item_id}/`;

    return this.httpClient.get<any[]>(url);
  }

  onaylaTeklifItem(data: any): Observable<any> {
    const url: string = `${this.API_URL}onayla/`;
    return this.httpClient.post<any>(url, data);
  }

  getNewTeklifList(data?: any): Observable<any[]> {
    const url: string = `${this.API_URL}list/`;
    return this.httpClient.post<any[]>(url, data);
  }

  getExcelFile(groupId) {
    const url: string = `${this.API_URL}excel/${groupId}/`;
    return this.httpClient.get<any>(url);
  }

  getTeklifFile(instanceId): Observable<any> {
    const url: string = `${this.API_URL}getfile/${instanceId}/`;
    return this.httpClient.get<any>(url);
  }

  getTeklifDetailForFirm(formVal): Observable<any[]> {
    const url: string = `${this.API_URL}detailforfirm/`;
    return this.httpClient.post<any[]>(url, formVal);
  }

  deleteTeklifGroup(instanceId) {
    const url: string = `${this.API_URL}deletegroup/${instanceId}/`;
    return this.httpClient.delete<any>(url);
  }

  updateGroup(groupId: number, data: any) {
    const url: string = `${this.API_URL}update/${groupId}/`;
    return this.httpClient.patch(url, data);
  }

}
