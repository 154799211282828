import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { HatirlatmaEkleComponent } from '../hatirlatma-ekle/hatirlatma-ekle.component';
import { HatirlatmaDetayComponent } from '../hatirlatma-detay/hatirlatma-detay.component';
import { ApasInfiniteScrollComponent } from '../../Apas-Modules/infinite-scroll/infinite-scroll.component';
import { HatirlatmaService } from '../../../services/hatirlatma.service';
import { tarih_format } from '../../../pages/constants';
import { HatirlatmaInfinitComponent } from './hatirlatma-infinit/hatirlatma-infinit.component';


@Component({
  selector: 'hatirlatma-list',
  templateUrl: './hatirlatma-list.component.html',
  styleUrls: ['./hatirlatma-list.component.css'],
  providers: [NbDialogService, HatirlatmaService],
})
export class HatirlatmaListComponent implements OnInit {
  @ViewChildren(ApasInfiniteScrollComponent) Infinit: QueryList<ApasInfiniteScrollComponent>;
  @ViewChildren(HatirlatmaInfinitComponent) hatirlatmaInfinitComponent: QueryList<HatirlatmaInfinitComponent>;



  buyukGetir;
  tarih_format = tarih_format;
  hatirlatmalar: any[] = [];
  pageRowCount: number = 20;

  constructor(private nbDialog: NbDialogService,
    private _hatirlatmaService: HatirlatmaService,
    private ref: NbDialogRef<HatirlatmaListComponent>) {

    //this.onScrool(this.Infinit.first);
  }

  ngOnInit() {

  }

  hatirlatmaEkle() {
    this.nbDialog.open(HatirlatmaEkleComponent);

  }
  onScrool(event: ApasInfiniteScrollComponent) {
    let time;
    if (this.hatirlatmalar.length > 0) {
      time = this.hatirlatmalar[this.hatirlatmalar.length - 1].reminder_time;
    }

    this._hatirlatmaService.getHatirlatma(time).subscribe(data => {
      this.hatirlatmalar = [...this.hatirlatmalar, ...data];
      if (data.length < this.pageRowCount) {
        event.isLoaiding = false;
      }
    });
  }
  sGizleGoster: boolean = false;
  mIcon: string = 'search';
  searchGizleGoster() {
    if (this.sGizleGoster === false) {
      this.sGizleGoster = true;
      this.mIcon = 'close';
    } else {
      this.sGizleGoster = false;
      this.mIcon = 'search';
    }
  }
  hatirlatmaModal() {
    const context: {} = {
      'buyukGetir': true,
    };
    this.nbDialog.open(HatirlatmaListComponent, { context });
  }

  closed(){
    this.ref.close();
  }



}

