import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { responseKodlari } from '../../../constants';
import { FirmaBilgiGuncelleComponent } from '../../../../profil/firma-profil-main/firma-bilgi-guncelle/firma-bilgi-guncelle.component';
import { EntegrasyonService } from '../../../../services/entegrasyon.service';
import { OrganizasyonService } from '../../../../services/organizasyon.service';
import { FormControl, Validators } from '@angular/forms';
export enum islemTypes {
  'basvuru' = 'basvuru',
  'bilgiDuzenle' = 'bilgiDuzenle',
  'iptal' = 'iptal',
}
@Component({
  selector: 'ngx-e-fatura-basvuru-yap',
  templateUrl: './e-fatura-basvuru-yap.component.html',
  styleUrls: ['./e-fatura-basvuru-yap.component.scss']
})
export class EFaturaBasvuruYapComponent implements OnInit {
  islemType = islemTypes;
  organization_info;
  kontorKontrol = new FormControl(1000, Validators.required)
  constructor(private ref: NbDialogRef<EFaturaBasvuruYapComponent>,
    private entegrasyonService: EntegrasyonService,
    private nbDialogService: NbDialogService,
    private organizationService: OrganizasyonService) {
    this.organizationService.org$.subscribe(org => {

      this.organization_info = org;
    });
  }

  ngOnInit(): void {
  }

  close(success?) {
    this.ref.close(success);
  }

  islemYap(secimTip: islemTypes) {
    if (!this.kontorKontrol.valid) {
      this.kontorKontrol.markAsTouched();
    }
    if (secimTip === this.islemType.basvuru) {
      this.entegrasyonService.addLogoAppeal({ kontor: this.kontorKontrol.value }).subscribe(() => {
        Swal.fire({ ...responseKodlari.successProgress, ...{ text: 'Başvuru Yapıldı' } } as SweetAlertOptions);
        this.close(true);
      });
    } else if (secimTip === this.islemType.bilgiDuzenle) {
      this.nbDialogService.open(FirmaBilgiGuncelleComponent);
    } else if (secimTip === this.islemType.iptal) {
      this.close(false);
    }
  }
}
