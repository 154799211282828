import * as hesapActions from './hesap.actions';
import {
    createReducer,
    on,
} from '@ngrx/store';

import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { HesapModel } from '../../muhasebe-main/muhasebe-hesaplar/hesap.model';
import { addOrUpdate } from '../../../global-functions';


interface HesapState extends EntityState<HesapModel> {
    selectedHesapId: number | null;
    selectedHareketId: number | null;
    end: boolean;
    loading: boolean;
    loaded: boolean;
    error: String;
    active: boolean;
}

const defaultHesapState: HesapState = {
    ids: [],
    entities: {},
    selectedHesapId: null,
    selectedHareketId: null,
    end: false,
    loading: false,
    loaded: false,
    active: true,
    error: '',
};
const hesapAdapter: EntityAdapter<HesapModel> = createEntityAdapter<HesapModel>();

const initialState = hesapAdapter.getInitialState(defaultHesapState);

const hesapReducer = createReducer(
    initialState,

    on(hesapActions.loadHesap, (state, props) => {
        return { ...state, loading: true };
    }),

    on(hesapActions.loadHesapSuccess, (state, props) => {
        return hesapAdapter.upsertMany(props.hesapItem, {
            ...state,
            loading: false,
            loaded: true,
            end: props.isEnd,
        });
    }),

    on(hesapActions.loadHesapFail, (state, props) => {
        return { ...state, loading: false, end: true, error: props };
    }),

    on(hesapActions.addHesapItem, (state, props) => {
        return hesapAdapter.upsertOne(props.hesapItem, {
            ...state,
            selectedCustomerId: props.hesapItem.id,
        });
    }),

    on(hesapActions.setActive, (state, props) => {
        return { ...state, active: props.active };
    }),

    on(hesapActions.updateHesapSuccess, (state, { hesap }) => {
        return hesapAdapter.updateOne(hesap,
            { ...state });
    }),

    on(hesapActions.deleteHesapItem, (state, { id }) => {
        return hesapAdapter.removeOne(id, { ...state });
    }),

    on(hesapActions.loadHesapHareketSuccess, (state, props) => {
        const hesap: Update<HesapModel> = { id: props.hesapId, changes: { cari_harekets: props.items } };
        return hesapAdapter.updateOne(hesap, { ...state });
    }),

    on(hesapActions.addHesapHareketSuccess, (state, props) => {
        return { ...state };
    }),

    on(hesapActions.loadHesapHareket, (state, props) => {
        const hesapUpdate: Update<HesapModel> = { id: props.hesapId, changes: { isLoading: true } };
        return hesapAdapter.updateOne(hesapUpdate, { ...state });
    }),

    on(hesapActions.removeHesapHareket, (state, props) => {
        const hesapUpdate: Update<HesapModel> = { id: props.hesapId, changes: { isLoading: true } };
        return hesapAdapter.updateOne(hesapUpdate, { ...state });
    }),

    on(hesapActions.setSelectedHesapId, (state, props) => {
        return { ...state, selectedHesapId: props.id };
    }),

    on(hesapActions.setSelectedHareketId, (state, props) => {
        return { ...state, selectedHareketId: props.hareketId };
    }),

    on(hesapActions.updateHareketItem, (state, props) => {
        const currentHesap = state.entities[state.selectedHesapId];
        const currentHareket = currentHesap.cari_harekets.find(x => x.id === props.hareket.id);
        const updateHareket = {...currentHareket, ...props.hareket}
        const hareket = addOrUpdate(currentHesap.cari_harekets, updateHareket);
        const hesap: Update<HesapModel> = { id: state.selectedHesapId, changes: { cari_harekets: hareket } };
        return hesapAdapter.updateOne(hesap, { ...state });
    }),
);

function HesapReducer(action, state) {
    return hesapReducer(action, state);
}


export {
    HesapState,
    hesapAdapter,
    defaultHesapState,
    HesapReducer,
};
