import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AplikasyonState } from './aplikasyon.reducer';

const getAplikasyonFeatureState = createFeatureSelector<AplikasyonState>('Aplikasyon');


const selectLocation = createSelector(
        getAplikasyonFeatureState,
        (state: AplikasyonState) => state.location ,
);
const selectBackendUrl = createSelector(
        getAplikasyonFeatureState,
        (state: AplikasyonState) => state.url,
);

const selectLanguage = createSelector(
        getAplikasyonFeatureState,
        (state: AplikasyonState) => state.lang,
);

const selectAygit = createSelector(
        getAplikasyonFeatureState,
        (state: AplikasyonState) => state.aygit,
);


export {
        selectLocation,
        selectBackendUrl,
        selectLanguage,
        selectAygit
};
