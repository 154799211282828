import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { BildirimModel } from '../../models/bildirim.model';


// Customers
// Load Actions
export const loadBildirim = createAction('[Bildirim] Load Bildirim', props<{ page?: number }>());
export const loadBildirimSuccess =
    createAction('[Bildirim] Load Bildirim Success', props<{ bildirimItem: BildirimModel[], isEnd?: boolean }>());
export const loadBildirimFail = createAction('[Bildirim] Load Bildirim Fail', props<String>());


//#region AddItem

export const addItem = createAction('[Bildirim] Add BildirimItem', props<{ bildirimItem: BildirimModel }>());

//#endregion


//#region  SetReaded
export const setBildirimReaded = createAction('[Bildirim] Set Bildirim Readed',
    props<{ id: number }>());

export const setAllBildirimShowed = createAction('[Bildirim] Set All Showed');

export const setBildirimReadedSuccess = createAction('[Bildirim] Set Bildirim Readed Success',
    props<{ bildirim: Update<BildirimModel> }>());

export const setBildirimReadedFail = createAction('[Bildirim] Set Bildirim Readed Fail',
    props<{ err: string }>());
//#endregion


//#region SetShowed
export const setBildirimShowed = createAction('[Bildirim] Set Bildirim Showed',
    props<{ id: number }>());

export const setBildirimShowedSuccess = createAction('[Bildirim] Set Bildirim Showed Success',
    props<{ bildirim: Update<BildirimModel> }>());

export const setBildirimShowedFail = createAction('[Bildirim] Set Bildirim Showed Fail',
    props<{ err: string }>());

export const getCount = createAction('[Bildirim] Get Count');
export const updateCount = createAction('[Bildirim] Update Count', props<{ count: number }>());
//#endregion


export const setConnection = createAction('[Bildirim] SetConnection', props<{ connection: boolean }>());
