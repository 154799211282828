import { Component, Input, OnInit } from '@angular/core';
import { TagItemModel } from '../../../models/tag.model';

@Component({
  selector: 'aps-tag-item',
  templateUrl: './tag-item.component.html',
  styleUrls: ['./tag-item.component.scss']
})
export class TagItemComponent implements OnInit {
  @Input() tagItems: TagItemModel[];
  constructor() { }

  ngOnInit() {
  }

}
