import { Component, OnInit, Output, ElementRef, Input, OnDestroy, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: '[(infinite-scroll)]',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.css'],
})
export class ApasInfiniteScrollComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * Özelleştirmelre vermenizi sağlar.
   * @param message Veri bulunamadı mesajını düzenlemenizi sağlar.
   */
  @Input() options = { message: null };
  /** Veri gelme durumunu temsileder.Eğer sunucundan veri gelmeyecekse false olmalıdır */
  @Input() isLoaiding: boolean = true;
  /** Bir sayfada ki satır sayısını ifade eder */
  @Input() pagerowCount: number;
  // Bir sayfada ki satır sayısını ifade eder
  @Input() rows: any[] = [];
  /**  Satır sonuna geldiğinde tetiklenen event */
  @Output() scrolled = new EventEmitter<any>();
  @ViewChild('anchor', { static: true }) anchor!: ElementRef<HTMLElement>;
  @Input() isEnd: boolean = false;

  private observer: IntersectionObserver;
  /** Kullanıcının işini yaraya bilecek bilgiler içerir.
   * @param page Eğer  rows ve  pagerowCount değerleri veril miş ise sayfa index'ini verir.
   */
  readonly target: any = { page: 0 };
  private _pageRowCount: number = 10;
  /** Host sayfa sonunu belirten div'in sayfanın en tepesine olan uzaklığını verir.
   * Infinit alanı genişledikçe bu değer artmaktadır.
   */
  private currentOffset = 0;
  constructor(private host: ElementRef) { }
  ngOnChanges(changes: SimpleChanges): void {
  }

  get element() {
    return this.host.nativeElement;
  }

  ngOnInit() {
    this.intValues();
    const options = {
      root: this.isHostScrollable() ? this.host.nativeElement : null,
      ...this.options,
    };
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) { this.scroll(); }
    }, options);
    this.observer.observe(this.anchor.nativeElement);
  }
  /**
   * Satır sonuna gelindiğinde tetiklenen event
   */
  scroll() {

    if (!this.isLoaiding) { return; }

    if (this.rows) {
      this.target.page = Math.floor(this.rows.length / this._pageRowCount);
      this.target.rowCount = this.rows.length;
    }
    if (this.anchor.nativeElement.offsetTop > this.currentOffset + 20) {
      this.scrolled.emit(this);
    }
    this.currentOffset = ((this.anchor.nativeElement.offsetTop > this.currentOffset + 20) || this.currentOffset <= 10)
      ? this.anchor.nativeElement.offsetTop : this.currentOffset;
  }
  private isHostScrollable() {
    const style = window.getComputedStyle(this.element);
    const drm = style.getPropertyValue('overflow') === 'auto' || style.getPropertyValue('overflow-y') === 'scroll';
    return drm;
  }
  /** Gerekli ilk atamalar yapılmaktadır. */
  intValues() {
    if (this.pagerowCount) {
      this._pageRowCount = this.pagerowCount;
    }
  }
  ngOnDestroy() {
    if (this.observer)
      this.observer.disconnect();
  }

  public setReset() {
    this.currentOffset = 0;
    this.target.page = 0;
    this.target.rowCount = 0;
    this.isLoaiding = true;
    const options = {
      root: this.isHostScrollable() ? this.host.nativeElement : null,
      ...this.options,
    };
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) { this.scroll(); }
    }, options);
    this.observer.observe(this.anchor.nativeElement);
  }
}


