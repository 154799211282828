<div class="aps-splt" [formGroup]="form" [nbSpinner]="isLoading">
    <h6 class="pageHeader" *ngIf="headerShow">{{'MENU.ANLASMALAR'|translate}}</h6>
    <nb-card>
        <!--Filter Template-->
        <apas-filter [parentComponent]="this" [bodyTemplate]="body" [islemlerTemplate]="islemler"
            [araText]="'Anlaşma Ara'" [formValue]="form" formControlName="search" [showFilterButton]="filterShow">
        </apas-filter>

        <ng-template #body>

            <div class="row">

                <div class="col-12">
                    <label class="label"><b> {{ 'MUTABAKATLISTE.FIRMA' | translate }}</b></label>
                    <organization-select formControlName="mutabakat_taraf_id"></organization-select>
                </div>


                <div class="col-12">
                    <label class="label"><b> {{ 'BASLANGICTARIHI' | translate }}</b></label>
                    <input nbInput fullWidth dateOnly [placeholder]="'BASLANGICTARIHI' | translate"
                        [nbDatepicker]="startDatePicker" formControlName="start_date" />
                    <nb-datepicker #startDatePicker></nb-datepicker>
                </div>

                <div class="col-12">
                    <label class="label"><b> {{ 'BITISTARIHI' | translate }}</b></label>
                    <input nbInput fullWidth [placeholder]="'BITISTARIHI' | translate" [nbDatepicker]="endDatePicker"
                        dateOnly formControlName="end_date" />
                    <nb-datepicker #endDatePicker></nb-datepicker>
                </div>

                <div class="col-12">
                    <label class="label"><b> {{ 'ETIKETLER' | translate }}</b></label>
                    <aps-tag-input style="width: 100% !important;" formControlName="tags">
                    </aps-tag-input>
                </div>

            </div>



        </ng-template>
        <ng-template #islemler>

            <button nbPopoverPlacement="bottom" size="small" status="warning" nbButton class="ekleIcon"
                [routerLink]="'/pages/mutabakat/mutabakat-ekle'">
                <nb-icon icon='plus-outline'> </nb-icon> {{'YENIEKLE'|translate}}
            </button>
        </ng-template>
        <!--// Filter Template-->
    </nb-card>
    <div class="row py-2 d-flex justify-content-end pr-4">
        <nb-toggle formControlName="is_active">{{form.get('is_active').value ? 'Arşivlenmemiş' :'Arşivdeki'}}
            Anlaşmaları Göster</nb-toggle>
    </div>
    <nb-card class="anaTable">
        <nb-card-header>
            <div class="row m-0 p-0 tableHeader">
                <div class="col-2 tableHeaderFont3 text-left">
                    <span>İlk Anlaşma Tarihi
                        <nb-icon icon="code-outline" class=" linked-div siralaIcon siralaIconRotate"
                            *ngIf="orderStatuses['start_date'] == null" (click)="orderBy('start_date', true)">
                        </nb-icon>
                        <nb-icon icon="arrow-ios-upward-outline" class=" linked-div siralaIcon"
                            *ngIf="orderStatuses['start_date'] == true" (click)="orderBy('start_date', true)">
                        </nb-icon>
                        <nb-icon icon="arrow-ios-downward-outline" class=" linked-div siralaIcon"
                            *ngIf="orderStatuses['start_date'] == false" (click)="orderBy('start_date', false)">
                        </nb-icon>
                    </span>
                </div>

                <div class="col-4 tableHeaderFont1 d-flex justify-content-start">
                    <span class="cursorP">
                        {{'MUTABAKATLISTE.MUTUBAKATBASLIGI'|translate}}
                        <nb-icon icon="code-outline" class=" linked-div siralaIcon siralaIconRotate"
                            *ngIf="orderStatuses['mutabakat_title'] == null" (click)="orderBy('mutabakat_title', true)">
                        </nb-icon>
                        <nb-icon icon="arrow-ios-upward-outline" class=" linked-div siralaIcon"
                            *ngIf="orderStatuses['mutabakat_title'] == true" (click)="orderBy('mutabakat_title', true)">
                        </nb-icon>
                        <nb-icon icon="arrow-ios-downward-outline" class=" linked-div siralaIcon"
                            *ngIf="orderStatuses['mutabakat_title'] == false"
                            (click)="orderBy('mutabakat_title', false)"></nb-icon>

                    </span>
                </div>

                <div class="col-2 tableHeaderFont1 d-flex justify-content-start">
                    <span>{{'MUTABAKATLISTE.FIRMA'|translate}}
                        <nb-icon icon="code-outline" class=" linked-div siralaIcon siralaIconRotate"
                            *ngIf="orderStatuses['karsi_firma'] == null" (click)="orderBy('karsi_firma', true)">
                        </nb-icon>
                        <nb-icon icon="arrow-ios-upward-outline" class=" linked-div siralaIcon"
                            *ngIf="orderStatuses['karsi_firma'] == true" (click)="orderBy('karsi_firma', true)">
                        </nb-icon>
                        <nb-icon icon="arrow-ios-downward-outline" class=" linked-div siralaIcon"
                            *ngIf="orderStatuses['karsi_firma'] == false" (click)="orderBy('karsi_firma', false)">
                        </nb-icon>

                    </span>
                </div>
                <div class="col-2 tableHeaderFont2 d-flex justify-content-start">
                    <span>{{'MUTABAKATLISTE.FIRMASORUMLUSU'|translate}}
                    </span>
                </div>


            </div>
        </nb-card-header>
        <nb-card-body>
            <nb-list *ngIf="rows && rows.length > 0" style="overflow-x: hidden;">
                <nb-list-item *ngFor="let row of rows; let i = index" class="p-0 muhasebeNbListItem" nbInfiniteList
                    listenWindowScroll [threshold]="200" (bottomThreshold)="goToNextPage()">
                    <!--[ngStyle]="{'background-color': i % 2 === 0 ? '#eeeeee' : '#c1ccd1' }"-->

                    <nb-accordion class="w-100" style="box-shadow: none; ">
                        <!-- Akordiyon, Tablo Başlangıcı  -->

                        <nb-accordion-item style="background: none;">
                            <nb-accordion-item-header class="mouseoverSari p-0 m-0" style="font-size: small;">
                                <span class="col-2 tableHeaderFont3">
                                    <tarih-col class="w-100" [index]="i" [tarih]="row?.start_date"></tarih-col>
                                </span>
                                <span class="col-4" nbTooltipPlacement="top">
                                    <span class="oneLine"> {{row?.mutabakat_title}} </span>
                                    <aps-tag-item [tagItems]="row?.tags"></aps-tag-item>
                                </span>


                                <span class="col-2 tableHeaderFont1">
                                    <span *ngIf="row?.mutabakat_taraf_org_name">
                                        {{row?.mutabakat_taraf_org_name |
                                        slice:0:30}}{{row?.mutabakat_taraf_org_name?.length > 30 ? '...' : null }}
                                    </span>
                                </span>
                                <span class="col-2 tableHeaderFont2">
                                    <span *ngIf="row?.mutabakat_taraf_personel">
                                        {{row?.mutabakat_taraf_personel}}
                                    </span>
                                </span>

                                <span class="col-4 tableHeaderFont2">
                                    <span *ngIf="row?.is_approved" class="text-success">
                                        <b>Onaylandı</b>
                                    </span>
                                    <span *ngIf="!row?.is_approved" class="text-warning">
                                        <b>Onay Bekleniyor</b>
                                    </span>

                                </span>

                            </nb-accordion-item-header>
                            <nb-accordion-item-body class="pl-0 pr-0">

                                <div class="row">
                                    <div class="col d-flex justify-content-end align-items-center">
                                        <div style="margin-top: 0;margin-bottom: 1rem;">
                                            <span *ngIf="row?.sub_mutabakat.length > 0"><b>{{
                                                    'MUTABAKATLISTE.TOPLAMTUTAR' | translate }}</b></span>
                                            <span *ngFor="let item of row?.total_prices;let i = index;">
                                                <button nbButton size="small" [style]="'background:'+colorSet[i+1]">
                                                    <apas-para-item [currency]="item?.currency" [value]="item?.total"
                                                        [decrypt]="false"></apas-para-item>
                                                </button>
                                            </span>
                                        </div>

                                        <!--İşlemler-->

                                        <ul class="islemler">
                                            <li>
                                                <div [nbTooltip]="'MUTABAKATLISTE.ANLASMAONAYLA'|translate">
                                                    <button *ngIf="!row?.is_approved && row?.onay" nbButton
                                                        style="width: 34px; " (click)="aproveMutabakat(row)"
                                                        status="info" size="small">
                                                        <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
                                                    </button>
                                                </div>
                                                <div [nbTooltip]="'MUTABAKATLISTE.ONAYBEKLENIYOR'|translate">
                                                    <button *ngIf="!row?.is_approved && !row?.onay" nbButton
                                                        status="warning" style="width: 34px; "
                                                        style="background-color: #FF7043 !important; color: white;"
                                                        size="small" disabled>
                                                        <nb-icon icon="checkmark-circle-outline"></nb-icon>
                                                    </button>
                                                </div>
                                                <div [nbTooltip]="'MUTABAKATLISTE.ONAYLANDI'|translate">
                                                    <button *ngIf="row?.is_approved" nbButton status="success"
                                                        size="small" disabled style="width: 34px; ">
                                                        <nb-icon icon="checkmark-circle-outline"></nb-icon>
                                                    </button>
                                                </div>
                                            </li>

                                            <li>
                                                <div [nbTooltip]="'MUTABAKATLISTE.ANLASMADUZENLE'|translate">
                                                    <button nbButton (click)="editMutabakat(row)" status="info"
                                                        size="small" style="width: 34px; "
                                                        *ngIf="!row?.is_approved && !row.onay">
                                                        <nb-icon icon="edit-outline"></nb-icon>
                                                    </button>
                                                </div>
                                                <div [nbTooltip]="'MUTABAKATLISTE.ANLASMADUZENLENEMEZ'|translate">
                                                    <button nbButton status="basic" size="small" style="width: 34px; "
                                                        *ngIf="!row?.is_approved && row?.onay">
                                                        <nb-icon icon="edit-outline"></nb-icon>
                                                    </button>
                                                </div>
                                                <div [nbTooltip]="'MUTABAKATLISTE.ONAYLIANLASMADUZEN'|translate">
                                                    <button nbButton status="basic"
                                                        [nbTooltip]="'MUTABAKATLISTE.ANLASMADUZENLE'|translate"
                                                        size="small" style="width: 34px; " *ngIf="row?.is_approved"
                                                        disabled>
                                                        <nb-icon icon="edit-outline"></nb-icon>
                                                    </button>
                                                </div>
                                            </li>

                                            <li>
                                                <button *ngIf="!row?.can_delete" disabled nbButton [nbTooltip]="'Sil'"
                                                    size="small" status="basic" style="width: 34px; ">
                                                    <nb-icon icon='trash-outline'></nb-icon>

                                                </button>

                                                <button *ngIf="row?.can_delete" nbButton [nbTooltip]="'Sil'"
                                                    size="small" status="basic" style="width: 34px; "
                                                    (click)="deleteMutabakat(row)" class="silButon">
                                                    <nb-icon icon='trash-outline'></nb-icon>
                                                </button>
                                            </li>

                                            <li>
                                                <button nbButton [disabled]="row?.isDownloading"
                                                    [nbTooltip]="row?.has_dosya ? 'Belge Görüntüle' : 'Belge Bulunamadı'"
                                                    size="small" style=" width: 34px; "
                                                    [ngStyle]="{'background': (row?.has_dosya ? '#FFC107 !important' : '#FFC10750 !important')}"
                                                    status="warning" (click)="downLoadFile(row)">
                                                    <nb-icon icon='file-add-outline' *ngIf="!row?.isDownloading">
                                                    </nb-icon>
                                                    <span *ngIf="row?.isDownloading">{{'%'+row.percentDone}}</span>

                                                </button>
                                                <button nbButton [status]="row?.is_active ? 'danger':'warning'"
                                                    size="small" (click)="pasifYap(row)">
                                                    <nb-icon icon="archive-outline"></nb-icon>
                                                    {{row?.is_active ? 'Arşive Kaldır':'Arşivden Çıkar'}}
                                                </button>
                                                <button nbButton status="primary" size="small"
                                                    (click)="yeniUrunEkle(row)">Yeni Ürün Ekle</button>

                                            </li>
                                        </ul>

                                        <!--/işlemler-->
                                    </div>
                                </div>


                                <div class="row p-0 m-0" *ngIf="row?.sub_mutabakat.length > 0">



                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" width="250">
                                                    {{'MUTABAKATLISTE.ANLASMATARIHI'|translate}}</th>
                                                <th scope="col" width="250"> {{ 'MUTABAKATLISTE.TIME' | translate}}
                                                </th>
                                                <th scope="col" width="250"> {{ 'MUTABAKATLISTE.BIRIM1' |
                                                    translate}}
                                                </th>
                                                <th scope="col" width="150"> {{ 'MUTABAKATLISTE.BIRIM2' |
                                                    translate}}
                                                </th>
                                                <th scope="col" width="150"> {{ 'MUTABAKATLISTE.BIRIM3' |
                                                    translate}}
                                                </th>
                                                <th scope="col" width="150"> {{ 'MUTABAKATLISTE.BIRIM4' |
                                                    translate}}
                                                </th>
                                                <th scope="col"> {{ 'MUTABAKATLISTE.BIRIM5' |
                                                    translate}}
                                                </th>
                                                <th scope="col"></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of row?.sub_mutabakat">
                                                <td>{{item?.start_date | date: tarih_format }}</td>
                                                <td>{{item?.finish_date | date: tarih_format }}</td>
                                                <td width="250">{{item?.description}}</td>
                                                <td>
                                                    <apas-para-item [value]="item?.price ? item?.price : 0"
                                                        [alacak]="false" [negative]="false" [decrypt]="false"
                                                        [setColor]="false"
                                                        [currency]="convert.getParaBirim(item?.price_currency)"
                                                        [animate]="false"></apas-para-item>
                                                </td>
                                                <td>
                                                    <apas-para-item
                                                    [value]="item.amount"
                                                    [alacak]="false" [negative]="false" [decrypt]="false"
                                                    [setColor]="false"
                                                    [currency]="''"
                                                    [animate]="false"></apas-para-item>
                                                    {{convert.getBirim(item.unit)}}
                                                </td>
                                                <td>
                                                    <apas-para-item [value]="item?.total_price ? item?.total_price : 0"
                                                        [alacak]="false" [negative]="false" [decrypt]="false"
                                                        [setColor]="false"
                                                        [currency]="convert.getParaBirim(item?.price_currency)"
                                                        [animate]="false"></apas-para-item>
                                                </td>
                                                <td>{{item.note}}</td>
                                                <td>
                                                    <button nbButton size="tiny" status="info"
                                                        (click)="urunGuncelle(item,row?.id)">
                                                        <nb-icon icon="edit-outline"></nb-icon>
                                                    </button>
                                                    <button nbButton size="tiny" status="basic" class="silButon"
                                                        (click)="urunDelete(row?.id,item)">
                                                        <nb-icon icon='trash-outline'></nb-icon>
                                                    </button>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <p class="w-100" style="padding: 5px;color: #838383; margin-bottom: 0px;">
                                        {{ 'MUTABAKATLISTE.PERSONEL' | translate }}
                                        <b style="padding-right: 10px;">{{row?.created_by_name}} </b>
                                    </p>


                                </div>
                            </nb-accordion-item-body>
                        </nb-accordion-item>
                    </nb-accordion>
                    <div>
                    </div>
                </nb-list-item>



            </nb-list>


            <aps-emptylist *ngIf="!isLoading && !(rows && rows?.length > 0)">

            </aps-emptylist>
        </nb-card-body>
    </nb-card>





</div>