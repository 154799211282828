<nb-card class="modalType animated fadeInRight" style="border-top: 0; background-color: #FFF9C4; width:800px" *ngIf="!reveal">
    <nb-card-header >
        <div class="row">
            <div class="col-md-6 d-flex align-items-center">
                {{instance?.title}} {{title}} {{'NOTESMODAL.NOTLA'|translate}}
            </div>
            
         <div class="col-md-6 text-right">
            <button nbButton (click)="addNewNote()" style="background: rgb(251, 192, 45);  color: #fff;">{{'NOTESMODAL.YENINOTEKLE'|translate}}</button>
            <button nbButton status="basic"  (click)="closeNotes()">
            <nb-icon icon="close-outline"></nb-icon>

            </button>
         </div>
        </div>
    </nb-card-header>
    <nb-card-body [nbSpinner]="isLoading" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="200" [scrollWindow]="false" (scrolled)="loadNext()" class="notBodyy">

        <div class="row p-0 m-0 h-100 justify-content-center"  >


            <div *ngIf="showNewForm && !detayNote" class="p-0 m-2 col-md-5 col-sm-12 myCart card" style="max-height: 350px;height: fit-content;">

                <div class="row p-0 m-0" [formGroup]="noteForm" [nbSpinner]="loading || uploading" nbSpinnerStatus="success">

                    <div class="col-12 p-0 m-0 linked-div">


                        <div class="default-img linked-div" *ngIf="!ImageUrl" (click)="openFile(fileInput)"
                            style="
                                object-fit: cover;
                                height: 80px;
                                width: 100%;">
                            <nb-icon icon="camera-outline" style="color: #443939;">
                                <span>{{'NOTESMODAL.NOTRESMIEKLE'|translate}}</span>
                            </nb-icon>
                        </div>
                        <input #fileInput type="file" accept="image/*" style="display:none"
                            (change)="openFileDialog($event)">
                        <img (click)="openFile(fileInput)" #img *ngIf="ImageUrl && ImageUrl !==''"
                            [src]="ImageUrl" style="
                                object-fit: cover;
                                height: 80px;
                                width: 100%;" [class.img-opacity]="isSuccess" />
                    </div>

                    <div class="w-100 p-2">
                        <div class="row p-0 m-0">
                            <div class="col-12 p-0 m-0  w-100">
                                <textarea formControlName="note" [placeholder]="'APASIMAGEUPLOADER.ACIKLAMA' |translate"  nbInput fullWidth></textarea>

                            </div>
                            <div class="col-12 w-100 uyari" style="text-align: center;" *ngIf="noteForm.get('note').touched && noteForm.get('note').hasError('required')">
                                {{'NOTESMODAL.LUTFENACIKLAMA'|translate}}
                            </div>

                            <div class="col-12 uyari w-100" style="text-align: center;" *ngIf="noteForm.get('note').touched && noteForm.get('note').hasError('maxlength')">
                                {{'NOTESMODAL.ACIKLAMAMETNI'|translate}}
                            </div>
                        </div>

                    </div>
                    <div class="col-12 pb-2 d-flex justify-content-center">
                        <nb-select  fullWidth size="small" placeholder="Bu Notu Kimler Görebilir" (selectedChange)="setStatus($event)" [(selected)]="selectedStatus">
                            <nb-option *ngFor="let choice of choices" 
                                [value]="choice.value" [nbTooltip]="choice.detail">{{choice.label}}</nb-option>
                        </nb-select>
                    </div>
                    <div class="col-12 pb-2 d-flex justify-content-center">
                        <nb-select 
                            #pinSelect 
                            size="small"
                            placeholder="Renk" 
                            (selectedChange)="setColor($event)"
                            [(selected)]="selectedPinColor"
                            >
                            <nb-select-label class="p-0 m-0" >
                                <div [ngStyle]="{
                                    'background-color':selectedPinColor,
                                    'padding':'0px', 'margin': '0px',
                                    'height':'20px'
                                    }">
                                </div>
                            </nb-select-label>
                            <nb-option *ngFor="let color of pinColors"
                                [value]="color"
                                class="p-0 m-0">
                                <div [ngStyle]="{
                                    'background-color':color,
                                    'width':'100%',
                                    'height':'20px',
                                    'padding':'0px', 'margin': '0px'}">
                                </div>
                            </nb-option>
                        </nb-select>
                    </div>
                    <div class="col-12 pb-2 d-flex justify-content-center">
                        <button nbButton status="primary" size="tiny" (click)="upload()">
                            <nb-icon style="margin-right: 5px;" icon="cloud-upload-outline"></nb-icon>
                            {{'GOREVLIGRUBU.KAYDET'|translate}}
                        </button>

                        <button *ngIf="isSuccess" nbButton status="warning" size="tiny">
                            <nb-icon style="margin-right: 5px;" icon="checkmark-circle-2-outline"></nb-icon>
                            {{ 'APASIMAGEUPLOADER.YUKLENDI' |translate}}
                        </button>
                        <button *ngIf="!isSuccess" nbButton status="danger" size="tiny" (click)="remove()">
                            <nb-icon style="margin-right: 5px;" icon="close-outline"></nb-icon>
                            {{'TOPLANTITUTANAGIOLUSTUR.IPTAL'|translate}}
                        </button>
                    </div>
                </div>
            </div>
            

            <aside 
            class="note-wrap"    
            *ngFor="let not of rows let i=index" 
            [ngStyle]="{'background': not.pin,'transform': i % 2 === 0 ? 'rotate(-2deg)' : 'rotate(2deg)' }"
            (click)="openDeatil(not)"  >
            <img *ngIf="not.image" src="{{not.image}}" width="100%" height="110" style="object-fit:cover;">
                <p> {{not.note | slice:0:100}}{{not.note.length > 100 ? '...' : null }}</p>
                <i hidden>{{i+1}} </i>
                <div class="row d-flex p-1 m-1 w-100 note-app">
                    <div class="col-6 d-flex justify-content-start p-0 pl-1 m-0 note-detay1">
                        {{not.created_by_name}}
                    </div>
                    <div class="col-5 d-flex justify-content-end p-0 m-0 note-detay2" >
                        {{not.tarih}}
                    </div>
                    <div class="col-12 d-flex justify-content-center p-0 pl-1 note-detay3"  [nbTooltip]="not.note_status.detail">
                        {{not.note_status.label}}
                    </div>
                </div>
            </aside>
            <aps-emptylist  *ngIf="!isLoading && !showNewForm && rows.length==0" message="Not Bulunamadı." text="">

            </aps-emptylist>
    </div>

    </nb-card-body>
</nb-card>


<nb-card class="modalType animated fadeInRight" [ngStyle]="{'background-color':detayNote?.pin}" style="width: 800px;" *ngIf="reveal">
    <nb-card-header class="bant" >
        <div class="row">
            <div class="col-8 d-flex d-flex align-items-center">
                <nb-user style="float: left; width: auto;"
                        [picture]="detayNote?.user_image"
                        [showTitle]="false"
                        [showName]="false">
                </nb-user>
                <span style="color: #fff; float: left; font-weight: 700;">{{detayNote?.created_by_name}}</span>

            </div>
            <div class="col-4 d-flex justify-content-end text-white">
                {{detayNote?.tarih}}
                <nb-icon icon="close-outline" (click)="closeRev()" class="notDetayKapat"></nb-icon>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body class="h-100" *ngIf="detayNote">
        <div *ngIf="showNewForm" class="p-0 m-0 w-100 h-100">

            <div class="row" [formGroup]="noteForm" [nbSpinner]="loading || uploading" nbSpinnerStatus="success">
                <div class="col-12 linked-div">


                    <div class="default-img" *ngIf="!ImageUrl" (click)="openFile(fileInput)"
                        style="
                            object-fit: cover;
                            height: auto;
                            width: 100%;">
                        <nb-icon icon="plus-circle-outline" style="color: #443939;">
                        </nb-icon>
                    </div>
                    <input #fileInput type="file" accept="image/*" style="display:none"
                        (change)="openFileDialog($event)">
                    <img (click)="openFile(fileInput)" #img *ngIf="ImageUrl && ImageUrl !==''"
                        [src]="ImageUrl" style="
                    object-fit: cover;
                    height: auto;
                    width: 100%;" [class.img-opacity]="isSuccess" />
                </div>

                <div class="col-12 pt-2 pb-2 d-flex justify-content-center">
                    <textarea class="w-100" fullWidth formControlName="note"
                        [placeholder]="'APASIMAGEUPLOADER.ACIKLAMA' |translate" nbInput
                        style="height: 100px;"></textarea>
                    <div class="uyari w-100" style="text-align: center;"
                        *ngIf="noteForm.get('note').touched && noteForm.get('note').hasError('required')">
                        {{'NOTESMODAL.LUTFENACIKLAMA'|translate}}
                    </div>

                    <div class="uyari w-100" style="text-align: center;"
                        *ngIf="noteForm.get('note').touched && noteForm.get('note').hasError('maxlength')">
                        {{'NOTESMODAL.ACIKLAMAMETNI'|translate}}
                    </div>
                </div>
                <div class="col-12 pb-2 d-flex justify-content-center">
                    <nb-select  fullWidth size="small" placeholder="Bu Notu Kimler Görebilir" (selectedChange)="setStatus($event)" [(selected)]="selectedStatus">
                        <nb-option *ngFor="let choice of choices" 
                            [value]="choice.value" [nbTooltip]="choice.detail">{{choice.label}}</nb-option>
                    </nb-select>
                </div>
                <div class="col-12 pb-2 d-flex justify-content-center">
                    <nb-select 
                        #pinSelect 
                        size="small"
                        placeholder="Renk" 
                        (selectedChange)="setColor($event)"
                        [(selected)]="selectedPinColor"
                        >
                        <nb-select-label class="p-0 m-0" >
                            <div [ngStyle]="{
                                'background-color':noteForm.get('pin').value,
                                'padding':'0px', 'margin': '0px',
                                'height':'20px'
                                }">
                            </div>
                        </nb-select-label>
                        <nb-option *ngFor="let color of pinColors"
                            [value]="color"
                            class="p-0 m-0">
                            <div [ngStyle]="{
                                'background-color':color,
                                'width':'100%',
                                'height':'20px',
                                'padding':'0px', 'margin': '0px'}">
                            </div>
                        </nb-option>
                    </nb-select>
                </div>
            </div>


        </div>
        <div *ngIf="!showNewForm && detayNote" class="row">

            <div class="col-12 pb-2 text-white">
                {{detayNote?.note}}
            </div>

            <div class="col-12" *ngIf="detayNote && detayNote.image">
                <img src="{{detayNote.image}}" width="100%" height="auto" style="object-fit:cover;">
            </div>
        </div>
    </nb-card-body>

    <nb-card-footer *ngIf="detayNote" style="background-color: #fff;" class="text-center">
                <button nbButton [nbTooltip]="'Düzenle'" class="editNoteBoton"
                (click)="editNote()"  *ngIf="detayNote && detayNote?.can_edit && !showNewForm">
                <nb-icon icon='edit-outline'></nb-icon>  Düzenle
            </button>
            <button nbButton [nbTooltip]="'Sil'" class="silButon"
                (click)="deleteNote()"  *ngIf="detayNote && detayNote?.can_edit && !showNewForm">
                <nb-icon icon='trash-outline'></nb-icon> Sil
            </button>
         
        <div class="row" *ngIf="showNewForm">

            <div class="col-12 pb-2 d-flex justify-content-center">
                <button nbButton status="primary" (click)="upload()">
                    <nb-icon style="margin-right: 5px;" icon="cloud-upload-outline"></nb-icon>
                    {{'GOREVLIGRUBU.KAYDET'|translate}}
                </button>

                <button *ngIf="isSuccess" nbButton status="warning">
                    <nb-icon style="margin-right: 5px;" icon="checkmark-circle-2-outline"></nb-icon>
                    {{ 'APASIMAGEUPLOADER.YUKLENDI' |translate}}
                </button>

                <!-- <button *ngIf="!isSuccess" nbButton status="danger" (click)="remove()">
                    <nb-icon style="margin-right: 5px;" icon="close-outline"></nb-icon>
                    {{'TOPLANTITUTANAGIOLUSTUR.IPTAL'|translate}}
                </button> -->

            </div>

        </div>
    </nb-card-footer>
</nb-card>
















