import {
  AfterViewInit,
  Component, OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  NbDialogService, NbMediaBreakpointsService,
  NbMenuService,
  NbPopoverDirective,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LayoutService } from '../../../@core/utils';
import { OnerComponent } from '../../../components/apas-components/arkadasina-oner/oner/oner.component';
import { KullaniciModel } from '../../../models/kullanici_model';
import OrgInfoModel from '../../../models/orgInfo.model';
import { ApasInfo } from '../../../pages/constants';
import { KullaniciService } from '../../../services/kullanici.service';
import * as bildirimActions from '../../../store/bildirim/bildirim.actions';
import * as fromUser from '../../../store/user/user.connector';
import * as fromApp from '../../../store/aplikasyon/aplikasyon.connector';
import { YardimListComponent } from '../../../components/yardim/yardim-list/yardim-list.component';
import { OrganizasyonService } from '../../../services/organizasyon.service';
import { ClearOrgInfo } from '../../../store/organization/organization.actions';
import { selectUnShowedBildirim } from '../../../store/bildirim/bildirim.connector';
import { AyarlarComponent } from '../../../pages/muhasebe/ayarlar/ayarlar.component';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  providers: [
    KullaniciService,
    OrganizasyonService,
  ],
})


export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChildren(NbPopoverDirective) menuPopOver: QueryList<NbPopoverDirective>;

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: KullaniciModel;
  currentTheme = 'default';
  pencere: any;
  images: string;
  org$: Observable<OrgInfoModel>;
  org_info: OrgInfoModel;
  auth: string;
  _org: string;
  org_name: string;
  org_id: string;
  apasInfo = ApasInfo;
  lang$: Observable<string>;
  userMenu = [
    {
      title: 'ApasID ',
    },
    {
      title: 'Profil',
    },
    {
      title: 'Firma Profili',
    },
    {
      title: 'Firma Değiştir',
    },
    {
      title: 'Çıkış Yap',
    },

  ];
  date = Date.now();
  /**
   * Bildirimleri BildirimModele göre tutan değişken
   */
  // bildirimData: BildirimModel[] = [];
  unShowedBildrimCount: number = 0;
  /**
   * Okunmamış Mesaj Sayısını tutan değişken
   */
  unReadedCount = 0;

  unReadedDuyuruCount: number = 5;

  tag: string = 'HeaderComponent --->';
  aygit;

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private store: Store<{}>,
    private nbDialogService: NbDialogService,
    private organizasyonService: OrganizasyonService,
  ) {

    this.store.pipe(select(fromUser.selectUser)).subscribe(user => this.user = user);
    this.store.pipe(select(selectUnShowedBildirim)).subscribe(count => this.unShowedBildrimCount = count);
    this.store.pipe(select(fromApp.selectAygit)).subscribe(aygit => this.aygit = aygit);
    this.pencere = window;

    this.subscribeOrg();
  }
  ngAfterViewInit(): void {
    if(this.aygit.deviceType ==='desktop'){// Mobilden giriş yapmıyorsa menüyü elle kapatıyorum
      this.toggleSidebar();  
    }
    
  }

  subscribeOrg() {
    this.org$ = this.organizasyonService.org$;
    this.org$.subscribe(data => {
      this.org_info = data;
      if (data['id'] === undefined) {
        const local_org = localStorage.getItem('org');
        if (local_org === null || local_org === undefined) {
          this.router.navigateByUrl('/profil');
        } else {
          return;
        }
      } else {
        this.org_info = data;
        this.userMenu[0].title = 'ApasID : ';
      }
    });

  }

  menuUser(git: string) {
    this.menuPopOver.toArray().forEach(el => {
      el.hide();
    });
    if (git === 'Çıkış Yap') {
      this.org$.subscribe().unsubscribe();
      this.router.navigateByUrl('/logout');

    } else if (git === 'Profil') {
      this.router.navigateByUrl('/profil/kullanici-profil');



    } else if (git === 'Firma Profili') {
      this.router.navigateByUrl('/profil/firma-profil', {
        state: {
          hesapGoster: false,
        },
      });
    } else if (git === 'Hesabim') {
      this.router.navigateByUrl('/profil/firma-profil', {
        state: {
          hesapGoster: true,
        },
      });

    } else if (git === 'Firma Değiştir') {
      this.org$.subscribe().unsubscribe();
      localStorage.removeItem('org');
      this.store.dispatch(ClearOrgInfo());
      this.router.navigateByUrl('/karsilama');
    } else if (git === 'userMenu') {
    }
  }

  ngOnInit() {

    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();

    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);


  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  goHome() {
    this.router.navigateByUrl('pages/muhasebe/main');
  }
  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean { 
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }


  arkadasinaOner() {
    this.nbDialogService.open(OnerComponent);
  }

  openYardim() {
    this.nbDialogService.open(YardimListComponent);
  }


  setAllNotificationAsShowed() {
    this.store.dispatch(bildirimActions.setAllBildirimShowed());
  }

  openAyarlar() {
    this.nbDialogService.open(AyarlarComponent);
  }

}
