<!-- <div class="row  animated zoomIn" style="width: 1000px;" [nbSpinner]="isLoading"> -->

<!-- <div class="col-md-2 text-center" style=" margin:auto;">
    <nb-icon icon="arrow-ios-back-outline" class="left-buton" (click)="previous()" [ngClass]="{hide:!canPrevius}">
    </nb-icon>
  </div> -->

<!--Resim Hala Yükleniyorssa Küçük Halini Göster-->
<!-- <div *ngIf="isLoading" class="col-md-8 text-center" style="min-width:600px;max-height: 600px;"> -->
<!-- <nb-icon icon="close-outline" class="kapat-buton" (click)="closed()"></nb-icon> -->
<!-- <img [src]="img" style="-webkit-filter: blur(5px); filter: blur(5px);">
  </div> -->
<!--// Resim Hala Yükleniyorssa Küçük Halini Göster-->

<!--Yüklenen Resimi Göster Burda-->
<nb-card style="min-width:700px;max-height: 600px;overflow:hidden" status="primary" accent="primary"
  class="animated zoomIn" [nbSpinner]="isLoading">
  <nb-card-header>
    <div class="row" style="max-height: 400px;">
      <div class="col">
        <!-- <div class="row" for="created"> {{info?(info?.created_time * 1000 | date:tarih_format):''}}</div> -->
        <nb-user *ngIf="!editMod" [name]="info?info?.created_by_name:null"
          [title]="info?(info?.created_time * 1000 | date:tarih_format):''"
          [picture]="info?info?.created_by_image:null"></nb-user>
        <div *ngIf="editMod">
          <input nbInput>
        </div>
      </div>
      <div class="col">
        <!-- <nb-icon icon="close-outline" class="kapat-buton" (click)="closed()"></nb-icon> -->
        <button nbButton (click)="closed()" size="tiny" style="background: none !important;
        color: #fff !important;
        float: right;
        font-size: 18px;">X</button>
      </div>
    </div>

  </nb-card-header>

  <nb-card-body style="overflow:hidden;">
    <div class="row mr-0">
      <div class="col-1 text-center" style=" margin:auto;">
        <nb-icon icon="arrow-ios-back-outline" class="left-buton" (click)="previous()" [ngClass]="{hide:!canPrevius}">
        </nb-icon>
      </div>

      <div class="col-10 justify-content-center d-flex " style="height: 100% !important;">
        <img class="img-fluid" style="height: 400px;" [ngClass]="{'img-opening':isLoading}" [src]="img">
      </div>

      <div class="col-1 text-center p-0" style=" margin:auto;">
        <nb-icon icon="arrow-ios-forward-outline" class="right-buton" (click)="next()" [ngClass]="{hide:!canNext}">
        </nb-icon>
      </div>
    </div>

  </nb-card-body>
  <nb-card-footer>
    <div class="row">
      <div class="w-100 d-flex" *ngIf="info">
        <label style="margin-left: 16px;"><b>{{'APASIMAGELIGHTBOX.ACIKLAMA' | translate }} </b></label>

        <nb-toggle style="margin-left: auto; margin-right: 16px;" [checked]="info.is_public" disabled status="danger">{{'APASIMAGELIGHTBOX.ISVERENGORUNTULEYEBILIR' | translate }} </nb-toggle>
    </div>
      <div class="col-12" style="color:black;  max-width: 650px;">
        {{info?(info?.title):''}}
      </div>
    </div>
  </nb-card-footer>
</nb-card>
