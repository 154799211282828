import * as tagActions from './tags.actions';
import {
  createReducer,
  on,
} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { TagGroupModel } from '../../models/tag.model';

interface TagState extends EntityState<TagGroupModel> {
  loading: boolean;
  loaded: boolean;
  error: String;
}

const tagAdapter: EntityAdapter<TagGroupModel> = createEntityAdapter<TagGroupModel>();
const defaultTagState: TagState = {
  ids: [],
  entities: {},
  loading: false,
  loaded: false,
  error: '',
};

const initialState = tagAdapter.getInitialState(defaultTagState);

const tagReducer = createReducer(
  initialState,
  on(tagActions.loadTag, (state) => {
    return { ...state, loading: true };
  }),
  on(tagActions.loadTagSuccess, (state, props) => {
    return tagAdapter.upsertMany(props.TagItem, {
      ...state,
      loading: false,
      loaded: true,
    });
  }),
  on(tagActions.loadTagFail, (state, props) => {
    return { ...state, loading: false, error: props };
  }),
  on(tagActions.addTagItem, (state, props) => {
    return tagAdapter.upsertOne(props.tagGroupItem, {
        ...state,
        });
    }),
  on(tagActions.deleteTagGroup, (state, props) => {
    const found = state[props.tagGroupId];

    

    return tagAdapter.removeOne(found, {
      ...state,
    });

  }),
);

function TagReducer(action, state) {
  return tagReducer(action, state);
}

export {
  TagState,
  defaultTagState,
  TagReducer,
  tagAdapter,
};
