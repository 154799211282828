<nb-card  class="modalType animated fadeInRight" size="giant" style="width:600px;">
  <!---->

  <nb-card-header>
    <div class="row">
      <div class="col-md-3">
        <nb-icon icon="clock-outline"></nb-icon>
        <span> {{ 'TAKVIM.HATIRLATMA' | translate }}</span>
      </div>
      <div class="col-md text-right">
        <button nbButton size="tiny" (click)="hatirlatmaEkle()" class="p-1"
          [nbTooltip]="'HATIRLATMALAR.HATIRLATMAEKLE'|translate" status="primary">
          <nb-icon icon="plus-outline" style="color:#fff;width: 22px;  height: 22px;"></nb-icon>
        </button>

    


        <button nbButton style="background: none !important; padding: 0px; color: black !important;" (click)="closed()">
          <nb-icon icon="close-outline"></nb-icon>
        </button>
      </div>
    </div>

  </nb-card-header>

  <!-- //Card Header -->
  <!-- - <b  style="color:#ff7043">{{reminder.clock}}</b> -->


  <nb-card-body class="p-2" style="overflow-x: hidden;">

    <hatirlatma-infinit></hatirlatma-infinit>

  </nb-card-body>

</nb-card>