import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[numberOnly]',
})
export class NumberOnlyDirective {
    private allowedList = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowRight', 'ArrowLeft', 'Delete', 'Tab'];

    element: ElementRef;

    constructor(private elementRef: ElementRef) {
        this.element = elementRef;
        if (this.element) {
            this.element.nativeElement.addEventListener('keydown', (event) => {
                this.keyDown(event);
            });
        }
    }

    keyDown(event: KeyboardEvent) {
        if (!this.allowedList.includes(event.key)) {
            event.preventDefault();
            return;
        }
    }
}
