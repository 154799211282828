import { createSelector } from '@ngrx/store';
import { HesapState } from './hesap.reducer';
import * as fromRoot from '../../../../app-state';
import { addOrUpdate } from '../../../global-functions';


const getHesapFeatureState = createSelector(
    fromRoot.getOrganizationSelector, (state: fromRoot.OrganizationState) => state?.Module.Muhasebe.Hesap,
);

const selectHesap = (filter?) => createSelector(
    getHesapFeatureState, (state: HesapState) => {

        let liste = [];
        Object.values(state.entities).forEach(elm => {
            liste.push(elm);
        });

        if (!state.active) {

        } else {
            liste = liste.filter(x => x.is_active === true);
        }

        if (filter) {
            if (filter.search) {
                const newList = [];
                liste.map(item => {
                    const st = item.unvan.toLowerCase().includes(filter.search.toLowerCase());
                    if (st) newList.push(item);
                });
                liste = newList;
            }



            if (filter.currency) {
                liste = liste.filter(x => x.currency === filter.currency);
            }

            if (filter.restrict && filter.restrict.length > 0) {
                let new2List = [];
                filter.restrict.forEach(hesap_turu => {
                    const found = liste.filter(x => x.account_type === hesap_turu);
                    found.forEach(x => {
                        new2List = addOrUpdate(new2List, x);
                    });
                });

                liste = new2List;
            }
        }
        liste = liste.sort((x, y) => x.id - y.id);
        return liste;
    },
);

const selectHesapLoading = createSelector(
    getHesapFeatureState,
    (state: HesapState) => state.loading,
);

const selectHesapLoaded = createSelector(
    getHesapFeatureState,
    (state: HesapState) => state.loaded,
);

const selectHesapEnd = createSelector(
    getHesapFeatureState,
    (state: HesapState) => state.end,
);

const selectHesapError = createSelector(
    getHesapFeatureState,
    (state: HesapState) => state.error,
);

const getCurrentHesap = createSelector(
    getHesapFeatureState,
    (state: HesapState) => state.entities[state.selectedHesapId],
);

const getCurrentHesapId = createSelector(
    getHesapFeatureState,
    (state: HesapState) => state.selectedHesapId,
);

const getSelectedHesapLoading = createSelector(
    getHesapFeatureState,
    (state: HesapState) => {
        return state.entities[state.selectedHesapId].isLoading;
    }
);

const selecthesapItem = (id) => createSelector(
    getHesapFeatureState,
    getCurrentHesapId,
    state => state.entities[id],
);

const selectHesapActive = createSelector(
    getHesapFeatureState,
    (state: HesapState) => state.active,
);

const getHesapHareket = (id) => createSelector(
    getHesapFeatureState,
    getCurrentHesapId,
    state => state.entities[id],
);

const getSelectedHareket = createSelector(
    getHesapFeatureState, (state) => {
        const currentHesap = state.entities[state.selectedHesapId];
        const hareket = currentHesap.cari_harekets.find(x => x.id === state.selectedHareketId);
        return hareket;
    }
);

export {
    selectHesap,
    selectHesapLoading,
    getSelectedHesapLoading,
    selectHesapLoaded,
    selectHesapEnd,
    selectHesapError,
    getCurrentHesapId,
    selecthesapItem,
    selectHesapActive,
    getHesapHareket,
    getSelectedHareket,
    getCurrentHesap,
};
