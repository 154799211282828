import { Injectable } from '@angular/core';
import { INaviagete } from '../../models/apas-naviagator.model';
import { NbDialogService } from '@nebular/theme';
import { HatirlatmaModulType } from './naviagtor-types';
import { HatirlatmaDetayComponent } from '../../components/hatirlatmalar/hatirlatma-detay/hatirlatma-detay.component';

@Injectable({
  providedIn: 'root',
})
export class HatirlatmaNaigatorService implements INaviagete {

  constructor(private nbDialog: NbDialogService) { }

  naviagate({ module, payload }) {
    this.convertToModule({ module: module, id: payload.content.id });
  }
  convertToModule({ module, id }) {
    switch (module) {
      case HatirlatmaModulType.Detay:
        this.nbDialog.open(HatirlatmaDetayComponent, {
          context: {
            hatirlatmaID: id,
          },
        });
        break;

    }
  }

}
